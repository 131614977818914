import React, { useEffect, useState } from 'react';
import useFetch from '../../../../helpers/useFetch';
import { GroupId, GroupToRelationRequest, RelationContext } from '../../../../config/api/models';
import useUser from '../../../security/hooks/useUser';
import SectionTitle from '../../../../components/SectionTitle';
import BlurDialog from '../../../shared/components/BlurDialog';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import ProfilePicture from '../../../../components/ProfilePicture';
import DefaultProfilePicture from '../../../../components/DefaultProfilePicture';
import { getUserDisplayName } from '../../../../helpers/getUserDisplayName';
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ExpandingButton from '../../../../components/ExpandingButton';
import { ApiConfig } from '../../api';
import useFetchGroups from '../../hooks/useFetchGroups';
import FormError from '../../../../components/forms/FormError';
import useRelations from '../../hooks/useRelations';
import uniqBy from 'lodash/uniqBy';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1rem',
  },
  dialogPaper: {
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.breakpoints.values.sm,
    },
  },
  list: {
    margin: '0 -3rem',
  },
  avatar: {
    '& svg': {
      width: '4.6rem',
      height: '4.6rem',
    },
    '& > *': {
      margin: 0,
    },
  },
  listItemTextPrimary: {
    fontSize: '1.4rem',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  listItemTextSecondary: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
  listItemSecondaryAction: {
    right: 8,
  },
  tooltipButton: {
    '& svg': {
      color: theme.palette.grey['400'],
    },
  },
  iconButton: {
    background: theme.palette.primary.medium,
  },
  listItemClickable: {
    cursor: 'pointer',
  },
}));

type Props = {
  onToggle: () => void;
  groupId: GroupId;
  groupName: string;
  groupRelationIds: number[];
};

const addGroupToRelationApiConfig = ApiConfig.addGroupToRelation();

const AddCoacheesDialog = ({ onToggle, groupName, groupId, groupRelationIds }: Props) => {
  const { t } = useTranslation();
  const [hoverActive, setHoverActive] = useState();
  const currentUser = useUser();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

  // Relations
  const { coachees: allCoachees } = useRelations();
  const coacheesInGroup = allCoachees
    ? allCoachees
        .filter(coachee => groupRelationIds.includes(Number(coachee.relationId)))
        .map(coachee => coachee.initiatorId)
    : [];

  const coachees = allCoachees
    ? uniqBy(
        allCoachees.filter(coachee => !coacheesInGroup.includes(coachee.initiatorId)),
        'initiatorId'
      )
    : [];
  // Groups
  const [addGroupToRelationResponse, addGroupToRelation] = useFetch(addGroupToRelationApiConfig);
  const [, fetchGroups] = useFetchGroups();
  const isSuccess = addGroupToRelationResponse && addGroupToRelationResponse.fulfilled;
  useEffect(() => {
    if (isSuccess) {
      fetchGroups();
    }
  }, [isSuccess, fetchGroups]);

  const addCoacheeToGroup = (coachee: RelationContext) => () => {
    const addGroupToRelationDTO: GroupToRelationRequest = {
      groupId,
      relationId: coachee.relationId,
    };
    addGroupToRelation(addGroupToRelationDTO);
  };

  const actions = (
    <div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
      <Button onClick={onToggle} color="secondary" icon={Icons.CANCEL}>
        {t('forms.buttons.close')}
      </Button>
    </div>
  );

  return (
    <BlurDialog
      open={true}
      actions={actions}
      onClose={onToggle}
      maxWidth="xl"
      classes={{ paper: classes.dialogPaper }}
    >
      <SectionTitle label={t('myCoach.myGroups.addCoacheesTitle')} omitMarginLeftOnMobile />
      <p style={{ marginBottom: '3rem' }}>{t('myCoach.myGroups.addCoacheesText')}</p>
      <List disablePadding className={classes.list}>
        <Divider />
        <>
          {coachees &&
            coachees.map(coachee => {
              const user =
                coachee.initiatorId === currentUser.entityId ? coachee.receiver : coachee.initiator;

              return (
                <ListItem
                  divider
                  key={coachee.relationId}
                  onMouseEnter={() => setHoverActive(coachee.relationId)}
                  onMouseLeave={() => setHoverActive(undefined)}
                  onClick={addCoacheeToGroup(coachee)}
                  className={classes.listItemClickable}
                >
                  <ListItemAvatar className={classes.avatar}>
                    {user.media && user.media.profile ? (
                      <ProfilePicture profilePicture={user.media.profile} />
                    ) : (
                      <DefaultProfilePicture />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={getUserDisplayName(user, true)}
                    secondary={user.department ? user.department.name : user.function}
                    classes={{
                      primary: classes.listItemTextPrimary,
                      secondary: classes.listItemTextSecondary,
                    }}
                  />
                  <div>
                    <ExpandingButton
                      text={t('myCoach.myGroups.addCoacheeButton', { group: groupName })}
                      onClick={() => null}
                      expandButton={!isMobile && hoverActive === coachee.relationId}
                    />
                  </div>
                </ListItem>
              );
            })}

          {addGroupToRelationResponse &&
            addGroupToRelationResponse.rejected &&
            addGroupToRelationResponse.reason &&
            addGroupToRelationResponse.reason.cause.errors.map((item: any, index: number) => (
              <FormError key={index}>{item.detail}</FormError>
            ))}
        </>
      </List>
    </BlurDialog>
  );
};

export default AddCoacheesDialog;
