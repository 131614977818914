import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import StickyUserMenu from './StickyUserMenu';
import { Theme, useMediaQuery } from '@material-ui/core';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import { CompassState } from '../../myCompass/model';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { useSelector } from 'react-redux';
import { getCompassState } from '../../myCompass/selectors';
import { useCompassUser } from '../../shared/hooks';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  content?: React.ReactNode;
  profilePictureEditable: boolean;
  show: boolean;
  children: React.ReactNode;
  navigation?: {
    navItems: React.ReactElement[];
    activeMenuItemIndex?: number;
  };
  color: string;
  showcaseDreamsVisible?: boolean;
  onShowcaseDreamsClick?: () => void;
};

type StyleProps = {
  show: boolean;
  isMobile: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  myDreamsWrapper: {
    display: 'block',
    margin: '1.25rem 0 3.5rem 0',
    textDecoration: 'none',
  },
  withStickyUserMenuRoot: {
    '&:after': {
      content: '""',
      clear: 'both',
      display: 'table',
    },
  },
  stickyUserMenuWrapper: (props: StyleProps) => ({
    display: props.show ? 'block' : 'none',
    marginBottom: 0,
    background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)',
    marginLeft: props.isMobile ? '-2rem' : 0,
    marginRight: props.isMobile ? '-2rem' : 0,
    [theme.breakpoints.up('md')]: {
      float: 'left',
      position: 'sticky',
      top: '2rem',
      left: 0,
      zIndex: 999,
      width: '25rem',
    },
  }),
  content: (props: StyleProps) => ({
    float: props.show ? (props.isMobile ? 'none' : 'right') : 'none',
    width: props.show ? (props.isMobile ? '100%' : 'calc(100% - 29rem)') : '100%',
  }),
}));

const WithStickyUserMenu = ({
  content,
  profilePictureEditable,
  show,
  children,
  navigation,
  color,
  showcaseDreamsVisible,
  onShowcaseDreamsClick,
}: Props) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ show, isMobile });
  const { dreamId, userId } = useParams();
  const { getPath } = useI18nPath();
  const compassState = useSelector(getCompassState);
  const isCompassUser = useCompassUser();
  const { t } = useTranslation();

  return (
    <div className={classes.withStickyUserMenuRoot}>
      <div className={classes.stickyUserMenuWrapper}>
        {compassState !== CompassState.SHOWCASE && !!dreamId && (
          <Link
            id="to-dreams-overview-button"
            to={
              compassState === CompassState.MYSHOWCASE
                ? isCompassUser
                  ? getPath('showcasePage').replace(':userId', userId)
                  : getPath('myShowcase')
                : isCompassUser
                ? getPath('compassPage').replace(':userId', userId)
                : getPath('myCompass')
            }
            className={classes.myDreamsWrapper}
          >
            <Button icon={Icons.DREAM}>{t('dream.backToDreams')}</Button>
          </Link>
        )}
        {compassState === CompassState.SHOWCASE && showcaseDreamsVisible && (
          <div className={classes.myDreamsWrapper}>
            <Button icon={Icons.DREAM} onClick={onShowcaseDreamsClick}>
              {t('dream.backToDreams')}
            </Button>
          </div>
        )}
        <StickyUserMenu
          profilePictureEditable={profilePictureEditable}
          navigation={navigation}
          color={color}
          showcaseDreamsVisible={showcaseDreamsVisible}
          onShowcaseDreamsClick={onShowcaseDreamsClick}
        >
          {content && typeof content !== 'boolean' && content}
        </StickyUserMenu>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default WithStickyUserMenu;
