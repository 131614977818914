import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from './SvgIcon';
import theme from '../config/theme';
import Html from './Html';

type Props = {
  num: number;
  question: string;
  children: React.ReactNode;
  expanded?: boolean;
};

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  qARoot: {},
  qAWrapper: {
    border: 'none',
    background: 'none',
  },
  expanded: {
    color: theme.palette.myCompass.green,

    '& > $number': {
      backgroundColor: theme.palette.myCompass.green,
    },
  },
  focused: {
    backgroundColor: 'red',
  },
  heading: (props: StyleProps) => ({
    fontSize: '2rem',
    width: props.isMobile ? '65%' : '90%',
  }),
  content: {
    alignItems: 'center',
  },
  number: {
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '4rem',
    color: theme.palette.background.paper,
    borderRadius: '50%',
    height: '6rem',
    width: '6rem',
    marginRight: '3rem',
  },
  root: {
    '& :hover': {
      color: theme.palette.myCompass.green,

      '& > $number': {
        backgroundColor: theme.palette.myCompass.green,
        color: theme.palette.background.paper,
      },

      '& > $expandIcon': {
        color: theme.palette.myCompass.green,
      },
    },
  },
  body: {
    flexWrap: 'wrap',
  },
  expandIcon: {},
}));

const QA = ({ num, question, children, expanded }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.qARoot}>
      <ExpansionPanel className={classes.qAWrapper} defaultExpanded={expanded}>
        <ExpansionPanelSummary
          href="#"
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<SvgIcon icon={Icons.CHEVRON_DOWN} size={2} />}
          classes={{
            expanded: classes.expanded,
            content: classes.content,
            root: classes.root,
            expandIcon: classes.expandIcon,
          }}
        >
          <div className={classes.number}>{num}</div>
          <Typography className={classes.heading}>
            <Html>{question}</Html>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.body}>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default QA;
