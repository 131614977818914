import React, { useEffect } from 'react';
import PanelWithSidebar from '../../../components/PanelWithSidebar';
import ContentBlock from '../../myCompass/components/ContentBlock';
import {
  Dream,
  Focus,
  Learn,
  LookBack,
  Comment,
  Talent,
  FilesResponse,
  FileDTO,
} from '../../../config/api/models';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import ShowcaseComments from './ShowcaseComments';
import FileList from '../../shared/components/FileList';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../../myCompass/api';
import { Box } from '@material-ui/core';
import DateTimeMessage from '../../shared/components/DateTimeMessage';

type Props = {
  context: Dream | Focus | Learn | LookBack | Talent;
  comments: Comment[] | null;
  closed?: boolean;
  closedAt?: string;
  modelType?: 'dream' | 'focus' | 'learn' | 'lookback';
};

const useStyles = makeStyles((theme: Theme) => ({
  showcaseBlockRoot: {
    marginBottom: '3rem',
  },
  quoteIcon: {
    position: 'absolute',
    top: '11rem',
    left: '2rem',
    color: theme.palette.highlight.main,
  },
}));

const ShowcaseBlock = ({ context, comments, closed, closedAt, modelType }: Props) => {
  const classes = useStyles();

  const [filesResponse, fetchFile] = useFetch<FilesResponse>(
    ApiConfig.loadFile(modelType || '', context.entityId)
  );

  useEffect(() => {
    if (!filesResponse && modelType != null && context.includeShowcaseFiles) {
      fetchFile();
    }
  }, [fetchFile, filesResponse, modelType, context]);

  const allFiles: FileDTO[] =
    (filesResponse && filesResponse.value && filesResponse.value.data.map(d => d.attributes)) || [];

  const CommentComponent =
    comments && comments.length ? <ShowcaseComments comments={comments} /> : undefined;

  return (
    <div className={classes.showcaseBlockRoot}>
      <PanelWithSidebar suffixComponent={CommentComponent}>
        <>
          <div className={classes.quoteIcon}>
            <SvgIcon icon={Icons.QUOTE} />
          </div>
          <ContentBlock
            content={{
              title: context.title,
              body: context.body,
              isClosed: closed,
              closedAt,
            }}
          />
          <FileList files={allFiles} loading={filesResponse && filesResponse.pending} />
          <Box display="flex" justifyContent="flex-end">
            <DateTimeMessage createdAt={context.createdAt} updatedAt={context.updatedAt} />
          </Box>
        </>
      </PanelWithSidebar>
    </div>
  );
};

export default ShowcaseBlock;
