import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCompassUserInfo } from '../../myCompass/selectors';
import { CompassUser } from '../../../config/api/models';
import SvgIcon, { Icons } from '../../../components/SvgIcon';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    backgroundColor: theme.palette.highlight.light,
    borderBottom: `solid 1px ${theme.palette.highlight.main}`,
    color: theme.palette.highlight.dark,
  },
  bannerContent: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '140rem',
    padding: '1rem 2rem',
    margin: 'auto',
  },
  compassUserName: {
    fontWeight: 700,
  },
  icon: {
    marginRight: '1rem',
  },
}));

const CompassInfoBanner: FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const compassUser: CompassUser = useSelector(getCompassUserInfo);

  return compassUser ? (
    <div className={classes.banner}>
      <div className={classes.bannerContent}>
        <SvgIcon
          icon={Icons.COMPASS}
          color={theme.palette.highlight.dark}
          className={classes.icon}
        />
        <span>{t(`common.compassInfoBanner`)}</span>
        &nbsp;
        <span
          className={classes.compassUserName}
        >{`${compassUser.firstName} ${compassUser.lastName}`}</span>
      </div>
    </div>
  ) : null;
};

export default CompassInfoBanner;
