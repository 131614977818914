import Blur from './container/Blur';
import * as actionTypes from './actionTypes';
import sharedReducer from './reducer';
import LoggedInPageContentWrapper from './components/LoggedInPageContentWrapper';
import LoggedOutPageContentWrapper from './components/LoggedOutPageContentWrapper';
import BlurDialog from './components/BlurDialog';
import BasicPage from './scenes/BasicPage';

const shared = {
  LoggedInPageContentWrapper,
  LoggedOutPageContentWrapper,
  reducer: sharedReducer,
  actionTypes,
  BlurDialog,
  Blur,
  BasicPage,
};

export default shared;
