import React from 'react';
import FormControl from '../../../components/FormControl';
import { Field } from 'react-final-form';
import { TextFieldAdapter } from '../../../components/forms';
import { useTranslation } from 'react-i18next';
import EditorAdapter from '../../../components/forms/Editor/EditorAdapter';

type Props = {
  onPreventSubmit: (value: boolean) => void;
  titlePlaceholder?: string;
  textPlaceholder?: string;
};

const FormFields = ({ onPreventSubmit, titlePlaceholder, textPlaceholder }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl label={t('forms.labels.title')} id="title">
        <Field name="title" component={TextFieldAdapter} autoFocus placeholder={titlePlaceholder} />
      </FormControl>
      <FormControl label={t('forms.labels.body')} id="body">
        <Field
          name="body"
          component={EditorAdapter}
          onUploadingFile={onPreventSubmit}
          placeholder={textPlaceholder}
        />
      </FormControl>
    </>
  );
};

export default FormFields;
