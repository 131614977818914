import { RootState } from '../../config/store/types';

export const getBlur = (state: RootState) => state.shared.blur;
export const getPersistingFiles = (state: RootState) => state.shared.persistingFiles;
export const getNotifications = (state: RootState) => state.shared.notifications;
export const getHelp = (state: RootState) => state.shared.help;
export const getMilaUserId = (state: RootState) => state.shared.milaUserId;
export const getMilaFirstDreamId = (state: RootState) => state.shared.milaFirstDreamId;
export const getMilaFirstLearnId = (state: RootState) => state.shared.milaFirstLearnId;
export const getDreamTourOpenFocusForm = (state: RootState) => state.shared.dreamTourOpenFocusForm;
export const getMilaCoachTourSwitchToCoachees = (state: RootState) =>
  state.shared.milaCoachTourSwitchToCoachees;
export const getMilaNetworkTourSwitchToComments = (state: RootState) =>
  state.shared.milaNetworkTourSwitchToComments;
export const getUploadingVideos = (state: RootState) => state.shared.videosUploading;
export const getIsChangingProfilePicture = (state: RootState) =>
  state.shared.isChangingProfilePicture;
