import { User } from '../config/api/models';

export function getUserDisplayName(user: User, withLastName?: boolean) {
  if (!user) return '';

  let result = user.email;
  if (user.firstName) {
    result = user.firstName;
    if (withLastName && user.lastName) {
      result += ` ${user.lastName}`;
    }
  }
  return result;
}
