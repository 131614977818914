import React from 'react';
import { LookBack, LookBackResponse, FileDTO } from '../../../../config/api/models';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import LookBackForm from './LookBackForm';
import { LookBackSubmitValues } from '../../model';
import useFilesPersist from '../../../shared/hooks/useFilesPersist';
import { useSelector } from 'react-redux';
import { getPersistingFiles } from '../../../shared/selectors';

type Props = {
  onClose?: () => void;
  lookBack: LookBack;
  lookBackId: string;
  files?: FileDTO[];
};

const updateLookBackConfig = ApiConfig.updateLookBack();

const UpdateLookBackForm = ({ onClose, lookBack, lookBackId, files = [] }: Props) => {
  const [updateLookBackResponse, updateLookBack] = useFetch<LookBackResponse>(updateLookBackConfig);
  const persistFiles = useFilesPersist('lookback', lookBackId, onClose);
  const persistingFiles = useSelector(getPersistingFiles);

  const handleSave = (values: LookBackSubmitValues) => {
    updateLookBack(lookBackId, values.request);
    if (values.filesToAdd.length || values.fileIdsToDelete.length) {
      persistFiles({
        filesToAdd: values.filesToAdd,
        fileIdsToDelete: values.fileIdsToDelete,
      });
    } else if (onClose && !persistingFiles) {
      onClose();
    }
  };

  return (
    <LookBackForm
      onSave={handleSave}
      onClose={onClose}
      lookBackResponse={updateLookBackResponse}
      initialValues={{
        files,
        title: lookBack.title,
        body: lookBack.body,
      }}
      lookback={lookBack}
      visibility={lookBack.visibility}
    />
  );
};

export default UpdateLookBackForm;
