import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandingButton from '../../../components/ExpandingButton';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import CancelRelation from './CancelRelation';
import { Dream, Focus } from '../../../config/api/models';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import GreyStripes from '../../../assets/grey-stripes.png';
import YellowStripes from '../../../assets/yellow-stripes.png';
import clsx from 'clsx';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { useSelector } from 'react-redux';
import { getHelp } from '../../shared/selectors';

type Props = {
  isSelected: boolean;
  canStop?: boolean;
  unreadMessages: number;
  type: 'dream' | 'focus';
  entity: Dream | Focus;
  archived?: boolean;
  notAccepted?: boolean;
  onSelect: () => void;
  onCancel: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    fontSize: '1.3rem',
    fontWeight: 300,
    lineHeight: '1.5rem',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.default,
    minHeight: '4rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noChat: {
    '&:hover': {
      cursor: 'initial',
    },
  },
  dreamContainer: {},
  focusContainer: {
    borderLeft: '10px solid #ededed',
  },
  selectedContainer: {
    borderRight: '3px solid',
    borderRightColor: theme.palette.secondary.main,
  },
  typeContainer: {
    fontSize: '.9em',
    color: '#888',
    display: 'flex',
    width: '7rem',
    padding: '0 1rem 0 0.25rem',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  dreamTitleContainer: {
    fontWeight: 'bold',
  },
  rowContainer: {
    backgroundColor: '#fff',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #ededed',
    padding: '.5rem 1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  relationContainer: {
    width: '3rem',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  closedIcon: {
    '& > svg': {
      width: '2rem',
      height: '2rem',
      color: theme.palette.tertiary.main,
    },
  },
  conversationSelected: {
    fontSize: '.9em',
    color: '#888',
  },
  notAccepted: {
    backgroundImage: `url(${GreyStripes})`,
    backgroundRepeat: 'repeat',
  },
  archived: {
    backgroundImage: `url(${YellowStripes})`,
    backgroundRepeat: 'repeat',
  },
  unreadMessages: {
    background: theme.palette.highlight.main,
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '.75rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.common.white,
    textShadow: '0 0 .5rem rgba(0,0,0,.2)',
    textAlign: 'center',
    lineHeight: '1.4rem',
    marginRight: '1rem',
  },
}));

const RelationRow = ({
  isSelected,
  canStop = false,
  type,
  entity,
  unreadMessages,
  onSelect,
  onCancel,
  archived,
  notAccepted,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [hoverActive, setHoverActive] = useState<boolean>(false);
  const [ismodalOpen, setModalOpen] = useState<boolean>(false);
  const isHelpActive = useSelector(getHelp);

  const title = entity.title;
  const noChat = (type === 'dream' && !canStop && !archived) || notAccepted;

  const cancelTooltip = notAccepted
    ? t('myCoach.relationship.cancelTooltip')
    : t('myCoach.relationship.endTooltip');
  const modalTitle = notAccepted
    ? t('myCoach.relationship.cancelConfirm')
    : t('myCoach.relationship.endConfirm');
  const modalText = notAccepted
    ? t('myCoach.relationship.cancelModalText')
    : t('myCoach.relationship.endModalText');

  const handleStopRelation = () => {
    setModalOpen(false);
    onCancel();
  };

  let label = '';
  if (type === 'dream') {
    label = t('dream.title');
  } else {
    label = t('focus.title');
  }
  return (
    <div
      className={clsx(
        classes.container,
        type === 'focus' ? classes.focusContainer : classes.dreamContainer,
        noChat ? classes.noChat : ''
      )}
    >
      <div
        className={clsx(
          classes.rowContainer,
          isSelected ? classes.selectedContainer : '',
          archived ? classes.archived : '',
          notAccepted ? classes.notAccepted : ''
        )}
        onClick={() => (noChat ? null : onSelect())}
        onMouseOver={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
      >
        <div className={classes.flex}>
          <div className={classes.typeContainer}>{label}</div>
          <div
            className={clsx(
              classes.titleContainer,
              type === 'dream' ? classes.dreamTitleContainer : ''
            )}
          >
            {title}
            {entity.isClosed ? (
              <span className={classes.closedIcon}>
                <SvgIcon icon={Icons.CHECK_LARGE} />
              </span>
            ) : null}
          </div>
        </div>

        {!noChat && (
          <div className={classes.flex}>
            {!isSelected ? (
              <div className={classes.flex}>
                {unreadMessages ? (
                  <div className={classes.unreadMessages}>{unreadMessages}</div>
                ) : null}
                <ExpandingButton
                  id="to-conversation-button"
                  text={t('myCoach.conversations.view')}
                  onClick={() => null}
                  expandButton={hoverActive}
                />
              </div>
            ) : (
              <div className={classes.conversationSelected}>
                {t('myCoach.conversations.selected')}
              </div>
            )}
          </div>
        )}
      </div>
      <div id="cancel-relation-button" className={classes.relationContainer}>
        {canStop && <CancelRelation tooltip={cancelTooltip} onClick={() => setModalOpen(true)} />}
        {isHelpActive && (
          <CancelRelation disabled tooltip={cancelTooltip} onClick={() => setModalOpen(true)} />
        )}
      </div>
      <ConfirmationModal
        title={modalTitle}
        open={ismodalOpen}
        confirmButtonText={t('common.yes')}
        confirmButtonIcon={Icons.SAVE}
        onConfirm={() => handleStopRelation()}
        cancelButtonText={t('common.no')}
        cancelButtonIcon={Icons.CANCEL}
        onCancel={() => setModalOpen(false)}
        text={modalText}
      />
    </div>
  );
};

export default RelationRow;
