import React from 'react';
import DateRangePicker, { Props as DateRangePickerProps } from 'react-daterange-picker';
import { dateStyles } from './DateStyles';

type Props = DateRangePickerProps & {};

const DateRange = (props: Props) => {
  const classes = dateStyles();

  return (
    <div className={classes.dateRangePicker}>
      <DateRangePicker numberOfCalendars={2} firstOfWeek={1} singleDateRange={true} {...props} />
    </div>
  );
};

export default DateRange;
