import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import theme from '../config/theme';

type Props = {
  image: string;
  name: string;
  func: string;
  alt: string;
};

const useStyles = makeStyles(() => ({
  quotedPersonRoot: {
    display: 'flex',
    marginTop: '4rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  left: {
    marginRight: '2rem',
  },
  right: {},
  name: {
    color: theme.palette.myCompass.blue,
  },
  avatar: {
    width: '7rem',
    height: '7rem',
  },
}));

const QuotedPerson = ({ image, name, func, alt }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.quotedPersonRoot}>
      <div className={classes.left}>
        <Avatar src={image} alt={alt} className={classes.avatar} />
      </div>
      <div className={classes.right}>
        <Typography variant="h6" className={classes.name}>
          {name}
        </Typography>
        <Typography variant="body2">{func}</Typography>
      </div>
    </div>
  );
};

export default QuotedPerson;
