import Authentication from './containers/Authentication';
import securityReducer from './reducer';
import * as actionTypes from './actionTypes';
import useLogout from './hooks/useLogout';
import logoutOnInvalidToken from './middleware/logoutOnInvalidToken';
import useUser from './hooks/useUser';
import { ApiConfig, RESOURCE } from './api';
import * as selectors from './selectors';

const security = {
  Authentication,
  reducer: securityReducer,
  actionTypes,
  hooks: {
    useLogout,
    useUser,
  },
  api: {
    RESOURCE,
    ApiConfig,
  },
  selectors,
  middleware: [logoutOnInvalidToken],
};

export default security;
