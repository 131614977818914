import React, { useCallback, useState } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as SharedApiConfig } from '../api';
import useUser from '../../security/hooks/useUser';
import { useSelector } from 'react-redux';
import { getSelectedFocusId } from '../../myCompass/selectors';

let userPatched: boolean = false;

const DreamDetailTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const currentUser = useUser();
  const [showTour, setShowTour] = useState(true);
  const [showLastStep, setShowLastStep] = useState(false);
  const [, patchUser] = useFetch<UserResponse>(SharedApiConfig.updateTourDone());
  const selectedFocus = useSelector(getSelectedFocusId);

  const dreamDetailTourSteps = [
    {
      target: '#add-coach-button',
      content: t('tour.steps.dreamDetail.first'),
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#edit-dream-button',
      content: t('tour.steps.dreamDetail.second'),
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const lastStep = [
    {
      target: '#add-first-focus-button',
      content: t('tour.steps.dreamDetail.third'),
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const updateUser = useCallback(() => {
    if (!userPatched) {
      const userUpdate = {
        dreamdetailTourDone: 1,
      };
      patchUser(currentUser.entityId, userUpdate);
      userPatched = true;
    }
  }, [patchUser, currentUser]);

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.SKIPPED].includes(status)
      ) {
        setShowTour(false);
      }
      if (status === STATUS.FINISHED) {
        setShowTour(false);
        const focusElement = document.querySelector('#add-first-focus-button');
        if (focusElement) {
          focusElement.scrollIntoView({
            behavior: 'smooth',
          });
        }
        setTimeout(() => setShowLastStep(true), 500);
      }
      if (action === ACTIONS.CLOSE) {
        updateUser();
      }
    },
    [setShowTour, updateUser, setShowLastStep]
  );

  const handleLastStepCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
      ) {
        updateUser();
        setShowLastStep(false);
      }
    },
    [setShowLastStep, updateUser]
  );

  return (
    <>
      <Joyride
        steps={dreamDetailTourSteps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: selectedFocus ? t('tour.locale.close') : t('tour.locale.next'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      <Joyride
        steps={lastStep}
        run={showLastStep}
        callback={handleLastStepCallback}
        continuous
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.close'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
    </>
  );
};

export default DreamDetailTour;
