import memoizeOne from 'memoize-one';
import apiRoutes from '../../config/api/routes';
import { Showcase } from '../../config/api/models';

export const RESOURCE = {
  SHOWCASES: 'showcases',
  MILA_SHOWCASES: 'mila_showcases',
  SHOWCASE: 'showcase',
  PUBLIC_SHOWCASE: 'publicShowcase',
};

export const ApiConfig = {
  loadShowcases: memoizeOne(() => ({
    resource: RESOURCE.SHOWCASES,
    method: 'GET',
    request: () => ({
      url: apiRoutes.showcases(undefined, false),
    }),
  })),
  loadMilaShowcases: memoizeOne(() => ({
    resource: RESOURCE.MILA_SHOWCASES,
    method: 'GET',
    request: () => ({
      url: apiRoutes.showcases(undefined, true),
    }),
  })),
  loadShowcase: memoizeOne((slug: string) => ({
    resource: RESOURCE.PUBLIC_SHOWCASE,
    method: 'GET',
    request: () => ({
      url: apiRoutes.showcase(slug),
    }),
  })),
  createShowcase: memoizeOne(() => ({
    resource: RESOURCE.SHOWCASE,
    method: 'POST',
    request: (showcase: Partial<Showcase>) => ({
      url: apiRoutes.showcases(),
      body: {
        data: {
          type: 'showcases',
          attributes: showcase,
        },
      },
    }),
  })),
  patchShowcase: memoizeOne((slug: string) => ({
    resource: RESOURCE.SHOWCASE,
    method: 'PATCH',
    request: (showcase: Partial<Showcase>) => ({
      url: apiRoutes.showcases(slug),
      body: {
        data: {
          id: slug,
          type: 'showcases',
          attributes: showcase,
        },
      },
    }),
  })),
  deleteShowcase: memoizeOne(() => ({
    resource: RESOURCE.SHOWCASE,
    method: 'DELETE',
    request: (slug: string) => ({
      url: apiRoutes.showcases(slug),
    }),
  })),
};
