import React, { useState, useEffect } from 'react';
import useFetch from '../../../../helpers/useFetch';
import { FocusResponse } from '../../../../config/api/models';
import { ApiConfig } from '../../api';
import FocusForm from './FocusForm';
import { FocusSubmitValues } from '../../model';
import useFilesPersist from '../../../shared/hooks/useFilesPersist';
import { useSelector } from 'react-redux';
import { getPersistingFiles } from '../../../shared/selectors';

type Props = {
  onCancel?: () => void;
  onSubmit: () => void;
  visibility: boolean;
};

const addFocusConfig = ApiConfig.addFocus();

const NewFocusForm = ({ onCancel, onSubmit, visibility }: Props) => {
  const [focusId, setFocusId] = useState<string>('');
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [addFocusResponse, addFocus] = useFetch<FocusResponse>(addFocusConfig);
  const persistFiles = useFilesPersist('focus', focusId, onSubmit);
  const persistingFiles = useSelector(getPersistingFiles);

  useEffect(() => {
    if (addFocusResponse && addFocusResponse.fulfilled && addFocusResponse.value && submitted) {
      setFocusId(addFocusResponse.value.data.id);
      if (focusId) {
        if (filesToAdd.length) {
          setFilesToAdd([]);
          persistFiles({
            filesToAdd,
            fileIdsToDelete: [],
          });
          setSubmitted(false);
        } else if (!persistingFiles) {
          onSubmit();
          setSubmitted(false);
        }
      }
    }
  }, [addFocusResponse, filesToAdd, focusId, persistFiles, onSubmit, persistingFiles, submitted]);

  const handleSave = (values: FocusSubmitValues) => {
    setSubmitted(true);
    setFilesToAdd(values.filesToAdd);
    addFocus(values.request);
  };

  return (
    <div id="new-focus-form">
      <FocusForm
        onSave={handleSave}
        onClose={onCancel}
        focusResponse={addFocusResponse}
        visibility={visibility}
      />
    </div>
  );
};

export default NewFocusForm;
