import { RouteConfig } from '../../../config/clientRoutes/types';
import getRoutes from '../../../helpers/getRoutes';
import LoginPage from '../../security/scenes/LoginPage';
import ForgotPasswordPage from '../../security/scenes/ForgotPasswordPage';
import ResetPasswordPage from '../../security/scenes/ResetPasswordPage';
import CompleteRegistrationPage from '../../security/scenes/CompleteRegistrationPage';

const publicRouteConfig: RouteConfig = {
  login: {
    component: LoginPage,
  },
  forgotPassword: {
    component: ForgotPasswordPage,
  },
  resetPassword: {
    component: ResetPasswordPage,
  },
  completeRegistration: {
    component: CompleteRegistrationPage,
  },
};

const getPublicRoutes = getRoutes(publicRouteConfig);

export default getPublicRoutes;
