import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Talent, TalentRequest } from '../../../config/api/models';
import FormFields from '../components/FormFields';
import ButtonGroup from '../../../components/ButtonGroup';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import FormError from '../../../components/forms/FormError';
import { useTranslation } from 'react-i18next';
import { PromiseState } from 'react-redux-fetch';
import { useSelector } from 'react-redux';
import security from '../../security';
type TalentFormValues = {
  title: string;
  body: string;
};

type Props = {
  onSave: (learnTalentRequest: TalentRequest) => void;
  onClose?: () => void;
  onSubmit?: () => void;
  talent?: PromiseState<Talent>;
  initialValues?: TalentFormValues;
  visibility: boolean;
};

const TalentForm = ({ onSave, onClose, talent, onSubmit, initialValues, visibility }: Props) => {
  const { t } = useTranslation();
  const userId = useSelector(security.selectors.getUserId);
  const [submitting, setSubmitting] = useState(false);
  const [disableSubmit, preventSubmit] = useState(false);

  useEffect(() => {
    if (talent && talent.fulfilled && submitting && onSubmit) {
      onSubmit();
      setSubmitting(false);
    }
  }, [submitting, talent, onClose, onSubmit]);

  return (
    <Form
      onSubmit={(values: TalentFormValues) => {
        if (!userId) {
          return;
        }

        const request: TalentRequest = {
          title: values.title,
          body: values.body,
          visibility,
          userId,
        };

        setSubmitting(true);
        onSave(request);
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <FormFields
            onPreventSubmit={preventSubmit}
            titlePlaceholder={t('talents.titlePlaceholder')}
            textPlaceholder={t('talents.textPlaceholder')}
          />
          <ButtonGroup>
            <Button
              type="submit"
              disabled={(talent && talent.pending) || pristine || disableSubmit}
              icon={Icons.SAVE}
            >
              {t('forms.buttons.save')}
            </Button>
            <Button type="button" onClick={onClose} color="secondary" icon={Icons.CANCEL}>
              {t('forms.buttons.cancel')}
            </Button>
          </ButtonGroup>
          {talent &&
            talent.rejected &&
            talent.reason &&
            talent.reason.cause.errors.map((item: any, index: number) => (
              <FormError key={index}>{item.detail}</FormError>
            ))}
        </form>
      )}
    />
  );
};

export default TalentForm;
