import React, { useEffect, useState } from 'react';
import inspiration from '../../../inspiration';
import Sidebar from '../../../../components/Sidebar';
import EditablePanel from '../../../../components/EditablePanel';
import {
  DreamId,
  FilesResponse,
  InspirationContext,
  Learn,
  LearnId,
  LearnRequest,
  Post,
  PostsResponse,
} from '../../../../config/api/models';
import UpdateLearnForm from './UpdateLearnForm';
import ContentBlock from '../../components/ContentBlock';
import ContentVisibilityButton from '../../../../components/ContentVisibilityButton';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import { ApiConfig as SharedApiConfig } from '../../../shared/api';
import FileList from '../../../shared/components/FileList';
import { useSelector } from 'react-redux';
import {
  getCompassState,
  getCompassUserId,
  getDreamByDreamId,
  getSelectedFocusResponse,
} from '../../selectors';
import SidebarSharing from '../../../shared/components/SidebarSharing';
import useUser from '../../../security/hooks/useUser';
import SharingDialog, { SharingDialogState } from '../../../shared/components/SharingDialog';
import CommunicationWrapper from '../../components/CommunicationWrapper';
import { CompassState } from '../../model';
import ShowcaseSidebarOptions, {
  ShowcaseSidebarValues,
} from '../../../myShowcase/components/ShowcaseSidebarOptions';
import { useParams } from 'react-router-dom';
import { getMilaUserId } from '../../../shared/selectors';
import { Box } from '@material-ui/core';
import DateTimeMessage from '../../../shared/components/DateTimeMessage';

type Props = {
  learn: Learn;
  learnId: LearnId;
};

const UpdateLearn = ({ learn, learnId }: Props) => {
  const modelType = 'learn';
  const { dreamId } = useParams();
  const currentUser = useUser();
  const focusResponse = useSelector(getSelectedFocusResponse);
  const dreamResponse = useSelector(getDreamByDreamId(dreamId as DreamId));
  const compassState = useSelector(getCompassState);
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);

  const [, patchLearn] = useFetch(ApiConfig.updateLearn());
  const [postRequest] = useFetch<PostsResponse>(SharedApiConfig.posts());
  const [, removePost] = useFetch<PostsResponse>(SharedApiConfig.removePost());
  const [filesResponse, fetchFile] = useFetch<FilesResponse>(
    ApiConfig.loadFile(modelType, learnId)
  );

  const [currentPost, setCurrentPost] = useState<Post>();
  const [sharingDialog, setSharingDialogState] = useState<undefined | SharingDialogState>();

  useEffect(() => {
    if (postRequest && postRequest.value) {
      const posts = postRequest.value.data.map(d => d.attributes);
      const current = posts.find(
        p => p.postId === learnId && p.postType === 'learn' && p.authorId === currentUser.entityId
      );
      setCurrentPost(current);
    }
  }, [postRequest, setCurrentPost, currentUser, learnId]);

  if (!filesResponse) {
    fetchFile();
  }

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const learnDTO: Partial<LearnRequest> = {
      visibility: !checked,
    };
    patchLearn(learnId, learnDTO);
    if (currentPost && checked) {
      removePost(currentPost.entityId);
    }
  };

  const hashValue = window.location.hash.substr(1);
  if (hashValue) {
    const anchor = document.getElementById(hashValue);

    if (anchor) {
      anchor.scrollIntoView();
    }
  }

  const handleShowcaseChange = (values: ShowcaseSidebarValues) => {
    patchLearn(learnId, { ...learn, ...values });
  };

  const allFiles =
    (filesResponse && filesResponse.value && filesResponse.value.data.map(d => d.attributes)) || [];
  const disableVisibilitySwitch = Boolean(
    focusResponse && !focusResponse.data.attributes.visibility
  );
  const disableShowcaseToggles = Boolean(
    !(
      focusResponse &&
      focusResponse.data.attributes.includeShowcase &&
      dreamResponse &&
      dreamResponse.data.attributes.includeShowcase &&
      learn.visibility
    ) || compassUserId === milaUserId
  );

  const getSidebar = () => {
    switch (compassState) {
      case CompassState.MYCOMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton
              value={!learn.visibility}
              onChange={handleVisibilityChange}
              disabled={disableVisibilitySwitch}
            />
            <inspiration.InspirationSidebarButton />
            <SidebarSharing
              currentPost={currentPost}
              onClick={setSharingDialogState}
              disabled={!learn.visibility || disableVisibilitySwitch}
            />
          </Sidebar>
        );
      case CompassState.COMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton
              id="learn-visibility-button"
              value={!learn.visibility}
              onChange={handleVisibilityChange}
              disabled={compassUserId === milaUserId}
            />
            <inspiration.InspirationSidebarButton
              id="learn-inspiration-button"
              disabled={compassUserId === milaUserId}
            />
            <SidebarSharing
              id="learn-sharing-button"
              currentPost={currentPost}
              onClick={setSharingDialogState}
              disabled={compassUserId === milaUserId}
            />
          </Sidebar>
        );
      case CompassState.MYSHOWCASE:
        return (
          <Sidebar>
            <ShowcaseSidebarOptions
              disabled={disableShowcaseToggles}
              onChange={values => handleShowcaseChange(values)}
              values={{
                includeShowcase: learn.includeShowcase,
                includeShowcaseComments: learn.includeShowcaseComments,
                includeShowcaseFiles: learn.includeShowcaseFiles,
              }}
            />
          </Sidebar>
        );
      default:
        return undefined;
    }
  };

  return (
    <inspiration.InspirationDialogProvider>
      <EditablePanel
        showToggleButton={compassState === CompassState.MYCOMPASS}
        view={
          <div id={`learn-${learnId}`}>
            <ContentBlock content={learn} inspirationKey={InspirationContext.COACHEE_LEARN} />
            <FileList files={allFiles} loading={filesResponse && filesResponse.pending} />
            <Box display="flex" justifyContent="flex-end">
              <DateTimeMessage createdAt={learn.createdAt} updatedAt={learn.updatedAt} />
            </Box>
          </div>
        }
        form={<UpdateLearnForm learn={learn} learnId={learnId} files={allFiles} />}
        sidebar={getSidebar()}
        suffixComponent={
          <CommunicationWrapper
            id="learn-communications"
            context={learn}
            contextType="learn"
            disabled={
              !learn.visibility ||
              compassState === CompassState.SHOWCASE ||
              compassState === CompassState.MYSHOWCASE
            }
          />
        }
      />
      <SharingDialog
        currentPost={currentPost}
        dialogState={sharingDialog}
        postId={learnId}
        postType="learn"
        onClose={setSharingDialogState}
      />
    </inspiration.InspirationDialogProvider>
  );
};

export default UpdateLearn;
