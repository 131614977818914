import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from './SvgIcon';

type SVGPath = Icons;

type Props = {
  id?: string;
  icon: SVGPath;
  primary: string;
  onClick: () => void;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  listItemButton: {
    padding: '.25rem 1.6rem',
    '&:hover': {
      background: theme.palette.grey['100'],
    },
  },
  listItemIconRoot: {
    minWidth: theme.spacing(4),
    transform: 'scale(1.25)',
    color: theme.palette.grey['400'],
    padding: '.5rem',
  },
  listItemTextPrimary: {
    fontSize: '1.4rem',
  },
  ripple: {
    color: theme.palette.grey['300'],
  },
}));

const SidebarButton = (props: Props) => {
  const { icon, primary, onClick, disabled = false } = props;
  const classes = useStyles();

  return (
    <ListItem
      id={props.id}
      onClick={onClick}
      disabled={disabled}
      button
      TouchRippleProps={{ classes: { ripple: classes.ripple } }}
      classes={{ button: classes.listItemButton }}
    >
      <ListItemIcon className={classes.listItemIconRoot}>
        <SvgIcon icon={icon} />
      </ListItemIcon>
      <ListItemText primary={primary} classes={{ primary: classes.listItemTextPrimary }} />
    </ListItem>
  );
};

export default SidebarButton;
