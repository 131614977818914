import React from 'react';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { ForgotPasswordRequest } from '../../../config/api/models';
import { useTranslation } from 'react-i18next';
import FormControl from '../../../components/FormControl';
import { Field, Form } from 'react-final-form';
import { TextFieldAdapter, validations } from '../../../components/forms';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import Message from '../../../components/Message';
import FormError from '../../../components/forms/FormError';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const [forgotPasswordRequest, forgotPassword] = useFetch<ForgotPasswordRequest>(
    ApiConfig.forgotPassword()
  );

  if (forgotPasswordRequest && forgotPasswordRequest.fulfilled) {
    return <Message type="info">{t('forgotPassword.success')}</Message>;
  }

  return (
    <Form
      onSubmit={(values: ForgotPasswordRequest) => {
        forgotPassword(values);
      }}
      initialValues={{ email: '' }}
      render={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <FormControl label={t('forms.labels.email')} id="email" required>
            <Field name="email" component={TextFieldAdapter} validate={validations(t).isEmail()} />
          </FormControl>
          <div>
            <Button
              type="submit"
              disabled={(forgotPasswordRequest && forgotPasswordRequest.pending) || pristine}
              icon={Icons.SAVE}
            >
              {t('forms.buttons.send')}
            </Button>
          </div>
          {forgotPasswordRequest && forgotPasswordRequest.rejected && (
            <FormError>{t('forgotPassword.error')}</FormError>
          )}
        </form>
      )}
    />
  );
};

export default ForgotPasswordForm;
