import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import NewTalentForm from './NewTalentForm';
import EditablePanel from '../../../components/EditablePanel';
import AddPostBlock from '../../myCompass/components/AddPostBlock';
import Sidebar from '../../../components/Sidebar';
import ContentVisibilityButton from '../../../components/ContentVisibilityButton';

type Props = {
  onSubmit: () => void;
};

const useStyles = makeStyles(() => ({
  newLearnRoot: {},
}));

const NewTalent = ({ onSubmit }: Props) => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const [visibility, setVisibility] = useState(true);

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setVisibility(!checked);
  };

  return (
    <div className={classes.newLearnRoot}>
      <EditablePanel
        view={
          <AddPostBlock onClick={() => setEditable(true)} buttonText="forms.buttons.addTalent" />
        }
        form={
          <NewTalentForm
            onCancel={() => setEditable(false)}
            onSubmit={onSubmit}
            visibility={visibility}
          />
        }
        sidebar={
          editable ? (
            <Sidebar>
              <ContentVisibilityButton value={false} onChange={handleVisibilityChange} />
            </Sidebar>
          ) : (
            undefined
          )
        }
        component={editable ? undefined : 'div'}
        editable={editable}
        onToggle={() => setEditable(!editable)}
        showToggleButton={editable}
      />
    </div>
  );
};

export default NewTalent;
