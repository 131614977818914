import React, { useEffect } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import useFetch from '../../../helpers/useFetch';
import { BasicPage, BasicPagesResponse } from '../../../config/api/models';
import { ApiConfig } from '../api';
import Title from '../../../components/Title';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';

type Props = {
  pageId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  basicHtmlRoot: {
    paddingTop: '3rem',
  },
  htmlContent: theme.config.ckEditor.htmlStyles,
}));

const BasicHtml = ({ pageId }: Props) => {
  const classes = useStyles();
  const currentLanguage = useCurrentLanguage();
  const [basicPagesRequest, basicPages] = useFetch<BasicPagesResponse>(
    ApiConfig.basicPages(pageId, currentLanguage)
  );

  useEffect(() => {
    if (!basicPagesRequest && pageId) {
      basicPages();
    }
  }, [pageId, basicPagesRequest, basicPages]);

  const content: BasicPage | undefined =
    basicPagesRequest &&
    basicPagesRequest.fulfilled &&
    basicPagesRequest.value &&
    basicPagesRequest.value.data &&
    basicPagesRequest.value.data.attributes
      ? basicPagesRequest.value.data.attributes
      : undefined;

  return (
    <>
      {content && (
        <div className={classes.basicHtmlRoot}>
          <Title>{content.title}</Title>
          <div className={classes.htmlContent}>{ReactHtmlParser(content.body)}</div>
        </div>
      )}
    </>
  );
};

export default BasicHtml;
