import * as React from 'react';
import clsx from 'clsx';
import { TrayNotification } from '../../../config/api/models';
import { makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NotificationTrayItem from './NotificationTrayItem';
import Button from '../../../components/Button';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import { notificationSort } from '../container/NotificationTray';

type Props = {
  open: boolean;
  notifications: TrayNotification[];
  onUpdate: () => void;
  className?: string;
  loadMore?: () => void;
  hasMoreNotifications?: string;
};

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: '0 0 2rem 0 rgba(0,0,0,.25)',
    borderRadius: '.75rem',
    padding: '0',
    width: '37rem',
    zIndex: 9999,
  },
  arrow: ({ isMobile }: StyleProps) => ({
    position: 'absolute',
    top: '-.75rem',
    left: isMobile ? 'auto' : '50%',
    right: isMobile ? '3.25rem' : 'auto',
    transform: 'translateX(-50%)',
    '&::after': {
      display: 'block',
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 1rem .75rem 1rem',
      borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
    },
  }),
  loadMore: {
    margin: '2rem auto',
    display: 'block',
  },
  notificationWrapper: {
    maxHeight: '49rem',
    overflowY: 'auto',
  },
  noNotifications: {
    position: 'relative',
    padding: '0 2rem 0 6rem',
    fontSize: '1.2rem',
    height: '7rem',
    lineHeight: '7rem',
    background: theme.palette.common.white,
    borderRadius: '.75rem',
  },
  noNotificationsIconWrapper: {
    position: 'absolute',
    top: '50%',
    left: '2rem',
    transform: 'translateY(-50%)',
    height: '6rem',
  },
  unreadMessagesWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(.25rem, -1.5rem)',
  },
  unreadMessages: {
    background: theme.palette.highlight.main,
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '.75rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.common.white,
    textShadow: '0 0 .5rem rgba(0,0,0,.2)',
    textAlign: 'center',
    lineHeight: '1.5rem',
  },
}));

const NotificationTrayPopup = React.forwardRef(
  (
    { open, notifications, onUpdate, className, loadMore, hasMoreNotifications }: Props,
    ref: React.LegacyRef<HTMLDivElement>
  ) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles({ isMobile });

    return open ? (
      <div className={clsx(classes.root, className)} ref={ref}>
        <div className={classes.notificationWrapper}>
          {notifications.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.noNotificationsIconWrapper}>
                <SvgIcon icon={Icons.BELL} color={theme.palette.text.primary} size={2} />
                <div className={classes.unreadMessagesWrapper}>
                  <div className={classes.unreadMessages}>0</div>
                </div>
              </div>
              {t('notificationTray.noNotifications')}
            </div>
          ) : (
            <>
              {notifications.sort(notificationSort).map((item: TrayNotification) => (
                <NotificationTrayItem key={item.entityId} item={item} onUpdate={onUpdate} />
              ))}
              {hasMoreNotifications && (
                <Button key="loadMoreButton" onClick={loadMore} className={classes.loadMore}>
                  {t('notificationTray.moreNotifications')}
                </Button>
              )}
            </>
          )}
        </div>
        <div className={classes.arrow} />
      </div>
    ) : null;
  }
);

export default NotificationTrayPopup;
