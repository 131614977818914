import React from 'react';
import SectionOne from '../components/SectionOne';
import SectionTwo from '../components/SectionTwo';
import SectionThree from '../components/SectionThree';
import SectionFour from '../components/SectionFour';
import SectionFive from '../components/SectionFive';
import SectionSix from '../components/SectionSix';
import SectionEight from '../components/SectionEight';
import SectionSeven from '../components/SectionSeven';
import LanguageSwitcher from '../../shared/container/LanguageSwitcher';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';

const useStyles = makeStyles(() => ({
  languageSwitcher: {
    position: 'absolute',
    top: '2rem',
    right: 0,
  },
}));

const HomePagePage = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  return (
    <>
      <div className={classes.languageSwitcher}>
        <LanguageSwitcher homepage />
      </div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
    </>
  );
};

export default HomePagePage;
