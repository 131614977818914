import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItem, Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SectionTitle from '../../../components/SectionTitle';
import ModalBodyAlt from '../../../components/ModalBodyAlt';
import BlurDialog from '../../shared/components/BlurDialog';
import Button from '../../../components/Button';

import { useTranslation } from 'react-i18next';
import ButtonGroup from '../../../components/ButtonGroup';
import { Icons } from '../../../components/SvgIcon';
import { ContextType, RelationCreateRequest, Showcase } from '../../../config/api/models';
import FormControl from '../../../components/FormControl';
import { Field, Form } from 'react-final-form';
import { TextFieldAdapter, validations } from '../../../components/forms';
import FormError from '../../../components/forms/FormError';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../../shared/api';
import { ApiConfig as ShowcaseApiConfig } from '../../myShowcase/api';
import { useSelector } from 'react-redux';
import security from '../../security';
import theme from '../../../config/theme';
import Html from '../../../components/Html';
import { isExpired } from '../helpers/isExpired';

interface DialogProps<Type = DialogType> {
  onClose: () => void;
  open: boolean;
  dialogType: Type;
}

interface CoachDialogProps extends DialogProps<'coach'> {
  contextType: ContextType;
  contextId: string;
}

interface PeerDialogProps extends DialogProps<'peer'> {}

type DialogType = 'coach' | 'peer';

type Translations = {
  title: string;
  body: string;
  confirmation: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  createRelationDialogPaper: {
    width: '100%',
    maxWidth: '60rem',
  },
  buttonGroup: {
    margin: '1rem -3.2rem -1.7rem -3.2rem',
    padding: '2rem 3rem',
    background: theme.palette.common.white,
    borderTop: theme.config.defaultBorder,
    textAlign: 'center',
  },
  body: {
    margin: '2rem 0',
  },
  confirmationMessage: {
    padding: '1rem 1rem 2rem 1rem',
  },
}));

const CreateRelationDialog = (props: CoachDialogProps | PeerDialogProps) => {
  const { onClose, open, dialogType } = props;
  const userId = useSelector(security.selectors.getUserId);
  const classes = useStyles();
  const { t } = useTranslation();
  const [relationCreateRequest, relationCreate] = useFetch<RelationCreateRequest>(
    ApiConfig.relationCreate(dialogType)
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const contextId = useMemo(() => (props.dialogType === 'coach' ? props.contextId : undefined), [
    props,
  ]);
  const contextType = useMemo(
    () => (props.dialogType === 'coach' ? props.contextType : undefined),
    [props]
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showCasesResponse, fetchShowcases] = useFetch(ShowcaseApiConfig.loadShowcases());

  const translations = getTranslations(dialogType, contextType);

  useEffect(fetchShowcases, [fetchShowcases]);

  const showcaseList: Showcase[] = useMemo(() => {
    return showCasesResponse && showCasesResponse.value
      ? showCasesResponse.value.data
          .map((d: any) => d.attributes)
          .filter((d: any) => !isExpired(d.expiresAt))
      : [];
  }, [showCasesResponse]);

  useEffect(() => {
    if (relationCreateRequest && relationCreateRequest.fulfilled) {
      setShowConfirmation(true);
    } else if (relationCreateRequest && relationCreateRequest.rejected) {
      setErrorMessage(
        t(
          dialogType === 'coach'
            ? 'forms.feedback.coachAlreadyAdded'
            : 'forms.feedback.peerAlreadyAdded'
        )
      );
    }
  }, [relationCreateRequest, t, dialogType]);

  // clear error message when there is a rejected relationCreateRequest during initial mount
  useEffect(() => setErrorMessage(''), []);

  useEffect(() => {
    if (open) {
      setShowConfirmation(false);
    }
  }, [open]);

  const handleClose = useCallback(() => {
    setShowConfirmation(false);
    setErrorMessage('');
    onClose();
  }, [onClose]);

  const handleFormSubmit = useCallback(
    (values: any) => {
      const relationDTO: RelationCreateRequest = {
        email: values.email,
        userId,
        context: contextType,
        contextId,
        relationType: dialogType === 'peer' ? dialogType : 'coachee',
        description: values.optionalMessage,
        showcaseId: parseInt(values.showcaseLink),
      };
      setErrorMessage('');
      relationCreate(relationDTO);
    },
    [userId, contextType, contextId, dialogType, relationCreate]
  );

  if (!open) {
    return null;
  }

  return (
    <BlurDialog
      open={open}
      onClose={handleClose}
      alignActions="center"
      classes={{ paper: classes.createRelationDialogPaper }}
      fullScreen={isMobile}
    >
      <SectionTitle label={t(translations.title)} />
      {showConfirmation ? (
        <ModalBodyAlt fullWidth>
          <div className={classes.confirmationMessage}>{t(translations.confirmation)}</div>
          <ButtonGroup className={classes.buttonGroup}>
            <Button type="button" icon={Icons.SAVE} onClick={handleClose}>
              {t('forms.buttons.ok')}
            </Button>
          </ButtonGroup>
        </ModalBodyAlt>
      ) : (
        <ModalBodyAlt fullWidth>
          <div className={classes.body}>
            <Html>{t(translations.body)}</Html>
          </div>
          <Form
            onSubmit={handleFormSubmit}
            render={({ handleSubmit, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <FormControl label={t('forms.labels.email')} id="email" required>
                  <Field
                    name="email"
                    component={TextFieldAdapter}
                    validate={validations(t).isEmail()}
                    parse={value => (value ? value.trim() : value)}
                    autoFocus
                  />
                </FormControl>
                <FormControl label={t('forms.labels.optionalMessage')} id="optional-message">
                  <Field
                    name="optionalMessage"
                    component={TextFieldAdapter}
                    multiline
                    rows={3}
                    rowsMax={3}
                  />
                </FormControl>
                <FormControl label={t('forms.labels.showcaseLink')} id="showcase-link">
                  <Field name="showcaseLink" component={TextFieldAdapter} select>
                    {showcaseList.map(({ slug, title, entityId }) => (
                      <MenuItem value={entityId} key={slug}>
                        {title}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <ButtonGroup className={classes.buttonGroup}>
                  <Button
                    type="submit"
                    disabled={
                      (relationCreateRequest && relationCreateRequest.pending) ||
                      pristine ||
                      !values.email
                    }
                    icon={Icons.SAVE}
                  >
                    {t('forms.buttons.sendInvite')}
                  </Button>
                  <Button type="button" onClick={handleClose} color="secondary" icon={Icons.CANCEL}>
                    {t('forms.buttons.cancel')}
                  </Button>

                  {errorMessage && <FormError>{errorMessage}</FormError>}
                </ButtonGroup>
              </form>
            )}
          />
        </ModalBodyAlt>
      )}
    </BlurDialog>
  );
};

export default CreateRelationDialog;

const getTranslations = (dialogType: DialogType, contextType?: ContextType): Translations => {
  switch (dialogType) {
    case 'coach':
      return {
        title: `myCoach.invite.${contextType}DialogTitle`,
        body: `myCoach.invite.${contextType}DialogIntro`,
        confirmation: 'myCoach.invite.dialogConfirmText',
      };
    case 'peer':
      return {
        title: 'peers.invite.dialogTitle',
        body: 'peers.invite.dialogIntro',
        confirmation: 'peers.invite.dialogConfirmText',
      };
  }
};
