import React from 'react';
import { makeStyles } from '@material-ui/styles';
import HomePageSection from '../../../components/HomePageSection';
import { useTranslation } from 'react-i18next';
import theme from '../../../config/theme';
import Title from '../../../components/Title';
import PaperWithBackdrop from '../../../components/PaperWithBackdrop';
import RegisterForm from '../../security/containers/RegisterForm';
import Register from '../../../assets/register.png';
import { useMediaQuery } from '@material-ui/core';

type StyleProps = {
  isMobile: boolean;
  isSmall: boolean;
};

const useStyles = makeStyles(() => ({
  sectionEightRoot: (props: StyleProps) => ({
    marginLeft: '-3000px',
    paddingLeft: '3000px',
    marginRight: '-3000px',
    paddingRight: '3000px',
    paddingTop: props.isMobile ? '45rem' : '30rem',
    paddingBottom: props.isMobile ? '5rem' : '10rem',
    marginTop: props.isSmall ? '-45rem' : props.isMobile ? '-35rem' : '-30rem',
    clipPath: 'polygon(0 38%, 100% 0, 100% 100%, 0 100%)',
    backgroundColor: theme.palette.myCompass.green,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  }),
  left: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '45%',
  }),
  right: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '45%',
    marginTop: props.isMobile ? '2rem' : '',
  }),
  image: (props: StyleProps) => ({
    marginTop: '4rem',
    width: props.isMobile ? '50%' : '',
  }),
}));

const SectionEight = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile, isSmall });
  const { t } = useTranslation();

  return (
    <div className={classes.sectionEightRoot}>
      <div className={classes.left} id="sectionEight">
        <HomePageSection
          title={t('home.sectionEight.title')}
          subtitle={t('home.sectionEight.subtitle')}
          bodyText={t('home.sectionEight.text')}
        />
        <img src={Register} alt={t('home.sectionEight.alt')} className={classes.image} />
      </div>
      <div className={classes.right}>
        <PaperWithBackdrop>
          <Title login>{t('register.title')}</Title>
          <RegisterForm />
        </PaperWithBackdrop>
      </div>
    </div>
  );
};

export default SectionEight;
