import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { default as themeConfig } from '../../../config/theme';
import { AppBar } from '@material-ui/core';
import { Link, RouteComponentProps } from 'react-router-dom';
import Logo from '../../../components/Logo';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import PanelWithSidebar from '../../../components/PanelWithSidebar';
import Spinner from '../../../components/Spinner';
import ShowcaseTalents from '../containers/ShowcaseTalents';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import { ShowcaseResponse } from '../../../config/api/models';
import { ApiConfig } from '../api';
import PageContentWrapperWithStickyUserMenu from '../../profile/components/PageContentWrapperWithStickyUserMenu';
import { useDispatch } from 'react-redux';
import { Actions } from '../../myCompass/actions';
import { CompassState } from '../../myCompass/model';
import FormError from '../../../components/forms/FormError';
import LanguageSwitcher from '../../shared/container/LanguageSwitcher';

type Props = RouteComponentProps<{ slug: string }> & {};

const useStyles = makeStyles((theme: Theme) => ({
  appBarRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.config.defaultBorder,
    borderRadius: 0,
    boxShadow: 'none',
    padding: 0,
    height: '7rem',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  logo: {
    marginLeft: '2rem',
    display: 'flex',
    maxHeight: '100%',
    overflow: 'hidden',
    alignItems: 'center',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rightContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1.5rem',
    },
  },
}));

const ShowcaseTalentsPage = (props: Props) => {
  const classes = useStyles();
  const { getPath } = useI18nPath();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const slug = props.match.params.slug;

  const [showcaseResponse, fetchShowcase] = useFetch<ShowcaseResponse>(
    ApiConfig.loadShowcase(slug)
  );

  const showcase =
    showcaseResponse && showcaseResponse.value ? showcaseResponse.value.data.attributes : undefined;
  const error =
    showcaseResponse &&
    !showcaseResponse.pending &&
    !showcaseResponse.value &&
    showcaseResponse.rejected;

  useEffect(() => {
    if (!showcaseResponse || (showcase && showcase.slug !== slug)) {
      fetchShowcase();
    }
  }, [showcaseResponse, slug, showcase, fetchShowcase]);

  useEffect(() => {
    dispatch(Actions.setCompassState(CompassState.SHOWCASE));
  }, [dispatch]);

  return (
    <>
      <AppBar
        classes={{
          root: classes.appBarRoot,
        }}
      >
        <div className={classes.logo}>
          <Link to={getPath('login')}>
            <Logo />
          </Link>
        </div>
        <div className={classes.rightContent}>
          <LanguageSwitcher />
        </div>
      </AppBar>

      <PageContentWrapperWithStickyUserMenu color={themeConfig.palette.myCompass.red}>
        {error ? (
          <PanelWithSidebar>
            <FormError>{t('showcase.public.error')}</FormError>
          </PanelWithSidebar>
        ) : showcase ? (
          <ShowcaseTalents showcase={showcase} />
        ) : (
          <PanelWithSidebar>
            <div className={classes.loading}>
              <Spinner />
              {t('common.loading')}
            </div>
          </PanelWithSidebar>
        )}
      </PageContentWrapperWithStickyUserMenu>
    </>
  );
};

export default ShowcaseTalentsPage;
