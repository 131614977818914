import React, { useState, useEffect } from 'react';
import theme from '../../../../config/theme';
import PanelWithSidebar from '../../../../components/PanelWithSidebar';
import NewTask from './NewTask';
import PlanPaper from '../../components/Plan/PlanPaper';
import Sidebar from '../../../../components/Sidebar';
import { InspirationContext, PlanResponse } from '../../../../config/api/models';
import inspiration from '../../../inspiration';
import PlanListWrapper from './PlanListWrapper';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import ContentVisibilityButton from '../../../../components/ContentVisibilityButton';
import { useSelector } from 'react-redux';
import { getSelectedFocusResponse, getCompassState } from '../../selectors';
import { CompassState } from '../../model';

const useStyles = makeStyles(() => ({
  inspirationIcon: {
    cursor: 'pointer',
    marginLeft: '1rem',
    position: 'relative',
    color: theme.palette.grey['400'],
    transitionProperty: 'color',
    transitionDuration: theme.transitions.duration.standard + 'ms',
    transitionTimingFunction: theme.transitions.easing.sharp,
    '&:hover': {
      color: theme.palette.grey['600'],
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '2rem',
    top: '2rem',
  },
}));

const Plan = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const focusResponse = useSelector(getSelectedFocusResponse);
  const compassState = useSelector(getCompassState);

  const [visibility, setVisibility] = useState<boolean>(true);
  const [isSidebar, setSidebar] = useState(false);

  const [plansResponse] = useFetch<PlanResponse>(ApiConfig.loadPlans());
  const [, updatePlanVisibility] = useFetch(ApiConfig.setPlanVisibility());

  const hasPlans = Boolean(plansResponse && plansResponse.value && plansResponse.value.data.length);
  const showMobileIcon = isMobile && hasPlans;
  const disableVisibilitySwitch = Boolean(
    focusResponse && !focusResponse.data.attributes.visibility
  );

  useEffect(() => {
    if (hasPlans) {
      setVisibility(plansResponse!.value!.data[0].attributes.visibility);
    }
  }, [plansResponse, setVisibility, hasPlans]);

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (focusResponse) {
      setVisibility(!checked);
      updatePlanVisibility(focusResponse.data.id, !checked);
    }
  };

  const getSidebar = () => {
    if (!hasPlans) {
      return undefined;
    }
    switch (compassState) {
      case CompassState.MYCOMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton
              onChange={handleVisibilityChange}
              value={!visibility}
              disabled={disableVisibilitySwitch}
            />
            <inspiration.InspirationSidebarButton />
          </Sidebar>
        );
      case CompassState.COMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton
              onChange={handleVisibilityChange}
              value={!visibility}
              disabled={true}
            />
            <inspiration.InspirationSidebarButton />
          </Sidebar>
        );
      default:
        return undefined;
    }
  };

  return (
    <inspiration.InspirationDialogProvider>
      <PanelWithSidebar sidebar={getSidebar()} component={PlanPaper}>
        <>
          <div className={classes.flex}>
            {showMobileIcon && (
              <div className={classes.inspirationIcon} onClick={() => setSidebar(!isSidebar)}>
                <SvgIcon icon={Icons.GEAR} />
                {isSidebar && getSidebar()}
              </div>
            )}
          </div>
          <inspiration.InspirationFormDialog inspirationKey={InspirationContext.COACHEE_PLAN}>
            <PlanListWrapper />
            <NewTask />
          </inspiration.InspirationFormDialog>
        </>
      </PanelWithSidebar>
    </inspiration.InspirationDialogProvider>
  );
};

export default Plan;
