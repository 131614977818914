import React from 'react';
import { Redirect } from 'react-router';
import { fallbackLng } from '../../../config/i18n/init';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';
import { I18nRoutes } from '../../../config/clientRoutes/types';

type Props = {
  routes: I18nRoutes;
};

const LoggedOutFallBackPage = ({ routes }: Props) => {
  const currentLanguage = useCurrentLanguage();
  const route = routes.login[currentLanguage] || routes.login[fallbackLng];

  return <Redirect to={route} />;
};

export default LoggedOutFallBackPage;
