import React, { useEffect } from 'react';
import { IconButton as MuiIconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Tooltip from '../../../../components/Tooltip';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { useDisclosure } from '../../../../helpers/useDisclosure';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import { GroupId } from '../../../../config/api/models';
import useFetchGroups from '../../hooks/useFetchGroups';

type Props = {
  disabled?: boolean;
  groupId: GroupId;
};

const useStyles = makeStyles((theme: Theme) => ({
  tooltipButton: {
    '& svg': {
      color: theme.palette.grey['400'],
    },
  },
}));

const removeGroupConfig = ApiConfig.removeGroup();
const DeleteGroupButton = ({ disabled, groupId }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [removeGroupFetch, removeGroup] = useFetch(removeGroupConfig);
  const [, fetchGroups] = useFetchGroups();

  useEffect(() => {
    if (removeGroupFetch && removeGroupFetch.fulfilled) {
      onClose();
      fetchGroups();
    }
  }, [removeGroupFetch, onClose, fetchGroups]);

  return (
    <>
      <Tooltip title={t('myCoach.myGroups.deleteGroupButton')} placement="top">
        <MuiIconButton
          className={clsx(classes.tooltipButton)}
          onClick={disabled ? undefined : onOpen}
        >
          <SvgIcon icon={Icons.CANCEL} />
        </MuiIconButton>
      </Tooltip>

      <ConfirmationModal
        title={t('myCoach.myGroups.deleteGroupTitle')}
        open={isOpen}
        confirmButtonText={t('common.yes')}
        confirmButtonIcon={Icons.SAVE}
        onConfirm={() => {
          removeGroup(groupId);
        }}
        cancelButtonText={t('common.no')}
        cancelButtonIcon={Icons.CANCEL}
        onCancel={onClose}
        text={t('myCoach.myGroups.deleteGroupText')}
      />
    </>
  );
};

export default DeleteGroupButton;
