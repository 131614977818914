import React, { useState } from 'react';
import EditablePanel from '../../../../components/EditablePanel';
import Sidebar from '../../../../components/Sidebar';
import AddPostBlock from '../../components/AddPostBlock';
import NewLookBackForm from './NewLookBackForm';
import inspiration from '../../../inspiration';
import { useSelector } from 'react-redux';
import myCompass from '../../index';
import { Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { getUserDisplayName } from '../../../../helpers/getUserDisplayName';
import { getCompassState } from '../../selectors';
import { CompassState } from '../../model';
import ContentVisibilityButton from '../../../../components/ContentVisibilityButton';

type Props = {
  onSubmit: () => void;
};

const useStyles = makeStyles({
  lookBackPaper: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const InitialLookBack = ({ onSubmit }: Props) => {
  const [editable, setEditable] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const compassUser = useSelector(myCompass.selectors.getCompassUserInfo);
  const compassState = useSelector(getCompassState);
  const [visibility, setVisibility] = useState(true);

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setVisibility(!checked);
  };

  const getView = () => {
    switch (compassState) {
      case CompassState.MYCOMPASS:
        return (
          <AddPostBlock onClick={() => setEditable(true)} buttonText="forms.buttons.addLookback" />
        );
      default:
        return compassUser ? (
          <Paper className={classes.lookBackPaper}>
            <p>
              {t('compassPage.noLookBack', {
                name: getUserDisplayName(compassUser, true),
              })}
            </p>
          </Paper>
        ) : null;
    }
  };

  return (
    <inspiration.InspirationDialogProvider>
      <EditablePanel
        view={getView()}
        form={
          <NewLookBackForm
            onCancel={() => setEditable(false)}
            onSubmit={onSubmit}
            visibility={visibility}
          />
        }
        sidebar={
          editable ? (
            <Sidebar>
              <inspiration.InspirationSidebarButton />
              <ContentVisibilityButton value={false} onChange={handleVisibilityChange} />
            </Sidebar>
          ) : (
            undefined
          )
        }
        component={editable ? undefined : 'div'}
        editable={editable}
        onToggle={() => setEditable(!editable)}
        showToggleButton={editable}
      />
    </inspiration.InspirationDialogProvider>
  );
};

export default InitialLookBack;
