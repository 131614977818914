import { FocusId, PlanRequest, UserId } from './models';
import { Moment } from 'moment';

type PlanForm = {
  title: string;
  focusId: FocusId;
  body?: string;
  date?: {
    start?: Moment;
    end?: Moment;
  };
  coachId: UserId | 'noCoach';
};

const toPlanDTO = (planForm: PlanForm): PlanRequest => {
  return {
    title: planForm.title.trim(),
    startDate: planForm.date && planForm.date.start,
    endDate: planForm.date && planForm.date.end,
    focusId: planForm.focusId,
    body: planForm.body ? planForm.body.trim() : planForm.body,
    coachId: planForm.coachId === 'noCoach' ? null : planForm.coachId,
  };
};

const mapper = {
  toPlanDTO,
};

export default mapper;
