import React from 'react';
import { Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PaperTitle from './PaperTitle';
import theme from '../config/theme';
import clsx from 'clsx';
import Html from './Html';

type Props = {
  color: string;
  title: string;
  body: string;
  alignRight?: boolean;
  first?: boolean;
};

type StyleProps = {
  isMobile: boolean;
  isFull: boolean;
};

const useStyles = makeStyles(() => ({
  homePageSectionPaperRoot: {},
  paperLeft: (props: StyleProps) => ({
    position: 'absolute',
    top: props.isMobile ? '-9rem' : '-7rem',
    padding: '6rem 3rem 3rem 3rem',
    background: theme.palette.background.default,
    width: '30rem',
  }),
  alignRight: {
    right: 0,
  },
  first: {
    top: '-10rem',
  },
  body: {
    fontSize: '1.5rem',
  },
}));

const HomePageSectionPaper = (props: Props) => {
  const { color, title, body, alignRight, first } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isFull = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles({ isMobile, isFull });

  return (
    <>
      <Paper
        className={clsx(
          classes.paperLeft,
          alignRight ? classes.alignRight : '',
          first ? classes.first : ''
        )}
      >
        <PaperTitle color={color}>{title}</PaperTitle>
        <div className={classes.body}>
          <Html>{body}</Html>
        </div>
      </Paper>
    </>
  );
};

export default HomePageSectionPaper;
