import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { TrayNotification, TrayNotificationResponse } from '../../../config/api/models';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '../../../components/Button';
import NotificationTrayPopup from '../components/NotificationTrayPopup';
import clsx from 'clsx';
import { unionBy } from 'lodash';
import odiseeTheme from '../../../config/theme';
import moment from 'moment';
import { useClickOutside } from '../hooks/useClickOutside';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    height: '100%',
  },
  button: {
    position: 'relative',
    height: '100%',
    width: '6rem',
    borderLeft: theme.config.defaultBorder,
    background: theme.palette.common.white + ' !important',
    borderRadius: 0,
    '&:hover': {
      background: theme.palette.grey['100'],
    },
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  unreadMessagesWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(.25rem, -1.5rem)',
  },
  unreadMessages: {
    background: theme.palette.highlight.main,
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '.75rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.common.white,
    textShadow: '0 0 .5rem rgba(0,0,0,.2)',
    textAlign: 'center',
    lineHeight: '1.5rem',
  },
  popup: ({ isMobile }: StyleProps) => ({
    position: 'absolute',
    top: 'calc(100% + .5rem)',
    left: isMobile ? 'auto' : '50%',
    right: isMobile ? '-2rem' : 'auto',
    transform: isMobile ? 'none' : 'translateX(-50%)',
    maxWidth: 'calc(100vw - 7rem)',
  }),
}));

const POLL_INTERVAL: number = 10000;
let pollingInterval: any;

const NotificationTray = () => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(odiseeTheme.breakpoints.down('sm'));
  const theme = useTheme();
  const [getTrayNotificationsResponse, getTrayNotifications] = useFetch<TrayNotificationResponse>(
    ApiConfig.getTrayNotifications()
  );
  const [allNotifications, setAllNotifications] = useState<TrayNotification[]>([]);
  const classes = useStyles({ isMobile });
  const [numUnreadNotifications, setNumUnreadNotifications] = useState(0);

  const popupRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const toggleBtnRef: React.RefObject<HTMLButtonElement> = React.useRef(null);

  const closeTray = () => {
    setOpen(false);
    getTrayNotifications();
    setAllNotifications([]);
    startPolling();
  };

  useClickOutside(popupRef, closeTray, [toggleBtnRef]);

  const startPolling = useCallback(() => {
    if (!pollingInterval) {
      pollingInterval = setInterval(() => {
        getTrayNotifications();
      }, POLL_INTERVAL);
    }
  }, [getTrayNotifications]);

  const stopPolling = useCallback(() => {
    clearInterval(pollingInterval);
    pollingInterval = undefined;
  }, []);

  const openTray = () => {
    stopPolling();
    setOpen(true);
  };

  const toggleTray = () => {
    if (open) {
      closeTray();
    } else {
      openTray();
    }
  };

  useEffect(() => {
    getTrayNotifications();
    startPolling();
    return stopPolling;
  }, [getTrayNotifications, startPolling, stopPolling]);

  useEffect(() => {
    if (
      !getTrayNotificationsResponse ||
      !getTrayNotificationsResponse.fulfilled ||
      !getTrayNotificationsResponse.value
    ) {
      return;
    }
    if (
      getTrayNotificationsResponse &&
      getTrayNotificationsResponse.value &&
      !getTrayNotificationsResponse.pending
    ) {
      // merge the newNotifications fetched with load more button with previous values based on entityId
      const newNotifications = getTrayNotificationsResponse.value.data.map(d => d.attributes);
      setAllNotifications(c => unionBy(c, newNotifications, v => [v.entityId].join()));
    } else if (getTrayNotificationsResponse && getTrayNotificationsResponse.value) {
      const newNotifications = getTrayNotificationsResponse.value.data.map(d => d.attributes);
      setAllNotifications(prev => (!prev.length ? [...newNotifications] : prev));
    }
    const result: TrayNotification[] = getTrayNotificationsResponse.value.data.map(
      d => d.attributes
    );

    if (!!result && !!result[0]) {
      setNumUnreadNotifications(result[0].unreadNotifications);
    }
  }, [getTrayNotificationsResponse, setAllNotifications]);
  const notifications: TrayNotification[] = useMemo(() => allNotifications, [allNotifications]);

  const handleUpdate = () => {
    getTrayNotifications();
    closeTray();
  };

  const hasMoreNotifications =
    getTrayNotificationsResponse &&
    getTrayNotificationsResponse.value &&
    getTrayNotificationsResponse.value.links &&
    getTrayNotificationsResponse.value.links.next;

  // If the result contains a links object with a next url, that means we can get more notifications from the backend.
  const handleLoadMoreNotifications = () => {
    if (hasMoreNotifications) {
      const url = getTrayNotificationsResponse!.value!.links!.next;
      if (url) {
        const newUrl = url.replace(/%5B/g, '[').replace(/%5D/g, ']');
        getTrayNotifications(newUrl);
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <Button
        disabled={!getTrayNotificationsResponse || !getTrayNotificationsResponse.fulfilled}
        onClick={toggleTray}
        className={classes.button}
        ref={toggleBtnRef}
      >
        <SvgIcon
          className={clsx('icon', classes.icon)}
          color={theme.palette.text.primary}
          size={2}
          icon={Icons.BELL}
        />
        <div className={classes.unreadMessagesWrapper}>
          {numUnreadNotifications > 0 ? (
            <div className={classes.unreadMessages}>{numUnreadNotifications}</div>
          ) : null}
        </div>
      </Button>

      <NotificationTrayPopup
        open={open}
        notifications={notifications}
        onUpdate={handleUpdate}
        className={classes.popup}
        loadMore={handleLoadMoreNotifications}
        hasMoreNotifications={hasMoreNotifications}
        ref={popupRef}
      />
    </div>
  );
};

export default NotificationTray;

export function notificationSort(a: TrayNotification, b: TrayNotification) {
  const momentA = moment(a.createdAt);
  const momentB = moment(b.createdAt);
  if (momentA.isSame(momentB)) {
    const updatedMomentA = moment(a.updatedAt);
    const updatedMomentB = moment(b.updatedAt);
    if (updatedMomentA.isSame(updatedMomentB)) {
      return a.entityId < b.entityId ? 1 : -1;
    }
    return updatedMomentA.isBefore(updatedMomentB) ? 1 : -1;
  }
  return momentA.isBefore(momentB) ? 1 : -1;
}
