import { createAction } from '../../helpers/createAction';
import {
  ENABLE_BLUR,
  DISABLE_BLUR,
  SET_PERSIST_FILES,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATIONS,
  ENABLE_HELP,
  DISABLE_HELP,
  ENABLE_DREAM_TOUR_OPEN_FOCUS_FORM,
  DISABLE_DREAM_TOUR_OPEN_FOCUS_FORM,
  ENABLE_MILA_COACH_TOUR_SWITCH_TO_COACHEES,
  DISABLE_MILA_COACH_TOUR_SWITCH_TO_COACHEES,
  ENABLE_MILA_NETWORK_TOUR_SWITCH_TO_COMMENTS,
  DISABLE_MILA_NETWORK_TOUR_SWITCH_TO_COMMENTS,
  UPDATE_PROGRESS_VIDEO,
  SET_IS_CHANGING_PROFILE_PICTURE,
} from './actionTypes';
import { ChatSocketNotification } from '../../config/api/models';
import { Video } from './model';

export const Actions = {
  enableBlur: () => createAction(ENABLE_BLUR),
  disableBlur: () => createAction(DISABLE_BLUR),
  setPersistFiles: (value?: 'dream' | 'focus' | 'learn' | 'lookback') =>
    createAction(SET_PERSIST_FILES, value),
  addNotification: (notification: ChatSocketNotification) =>
    createAction(ADD_NOTIFICATION, notification),
  removeNotifications: (ids: string[]) => createAction(REMOVE_NOTIFICATIONS, ids),
  enableHelp: () => createAction(ENABLE_HELP),
  disableHelp: () => createAction(DISABLE_HELP),
  enableDreamTourOpenFocusForm: () => createAction(ENABLE_DREAM_TOUR_OPEN_FOCUS_FORM),
  disableDreamTourOpenFocusForm: () => createAction(DISABLE_DREAM_TOUR_OPEN_FOCUS_FORM),
  enableMilaCoachTourSwitchToCoachees: () =>
    createAction(ENABLE_MILA_COACH_TOUR_SWITCH_TO_COACHEES),
  disableMilaCoachTourSwitchToCoachees: () =>
    createAction(DISABLE_MILA_COACH_TOUR_SWITCH_TO_COACHEES),
  enableMilaNetworkTourSwitchToComments: () =>
    createAction(ENABLE_MILA_NETWORK_TOUR_SWITCH_TO_COMMENTS),
  disableMilaNetworkTourSwitchToComments: () =>
    createAction(DISABLE_MILA_NETWORK_TOUR_SWITCH_TO_COMMENTS),
  updateProgressVideo: (video: Video) => createAction(UPDATE_PROGRESS_VIDEO, video),
  setIsChangingProfilePicture: (isChanging: boolean) =>
    createAction(SET_IS_CHANGING_PROFILE_PICTURE, isChanging),
};
