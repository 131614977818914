import React from 'react';
import { Redirect } from 'react-router';
import { fallbackLng } from '../../../config/i18n/init';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';
import { I18nRoutes } from '../../../config/clientRoutes/types';
import security from '../../security';

type Props = {
  routes: I18nRoutes;
};

const LoggedInFallBackPage = ({ routes }: Props) => {
  const currentLanguage = useCurrentLanguage();
  const currentUser = security.hooks.useUser();
  const extern = currentUser.isExtern;
  const getRoute = () => {
    if (extern) {
      if (routes.myNetwork) {
        return routes.myNetwork[currentLanguage] || routes.myNetwork[fallbackLng];
      } else if (routes.myCoach) {
        return routes.myCoach[currentLanguage] || routes.myCoach[fallbackLng];
      }
      return routes.aboutDreams[currentLanguage] || routes.aboutDreams[fallbackLng];
    }
    return routes.myCompass
      ? routes.myCompass[currentLanguage] || routes.myCompass[fallbackLng]
      : routes.aboutDreams[currentLanguage] || routes.aboutDreams[fallbackLng];
  };

  return <Redirect to={getRoute()} />;
};

export default LoggedInFallBackPage;
