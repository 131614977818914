import React from 'react';
import useRelations from '../../hooks/useRelations';
import { GroupRelation, GroupsResponse } from '../../../../config/api/models';
import MyGroupCoachee from './MyGroupCoachee';
import { Paper, List } from '@material-ui/core';

type Props = {
  group?: GroupsResponse['data'][number];
};
const MyGroupCoachees = ({ group }: Props) => {
  const { coachees: allCoachees } = useRelations();

  if (!group || !allCoachees) {
    return null;
  }

  const relationIdsInGroup = group.relationships.relations.data.map((relation: GroupRelation) =>
    parseInt(relation.id, 10)
  );

  const coachees = allCoachees.filter(coachee => relationIdsInGroup.includes(coachee.relationId));

  return (
    <Paper>
      <List disablePadding>
        {coachees &&
          coachees.map(coachee => {
            return <MyGroupCoachee key={coachee.relationId} coachee={coachee} groupId={group.id} />;
          })}
      </List>
    </Paper>
  );
};

export default MyGroupCoachees;
