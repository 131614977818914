import React from 'react';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  centeredPaperRoot: {
    width: 'calc(100% - 4rem)',
    maxWidth: '75rem',
    height: 'auto',
    padding: '4rem',
    margin: '4rem auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
      padding: '4rem 2rem',
    },
  },
}));

const CenteredPaper = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <Paper
      classes={{
        root: classes.centeredPaperRoot,
      }}
    >
      {children}
    </Paper>
  );
};

export default CenteredPaper;
