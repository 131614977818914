import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import Button from '../../../../components/Button';
import clsx from 'clsx';

type Props = {
  isSuccessful: boolean;
  undoFinalize: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  focusFinalizedMessageRoot: {
    padding: '0 0.5rem',
    margin: 'auto',
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    '& > svg': {
      verticalAlign: 'middle',
      marginRight: '0.5rem',
    },
  },
  achieved: {
    lineHeight: '6rem',
    width: '100%',
    color: theme.palette.tertiary.main,
    backgroundColor: theme.palette.tertiary.light,
    border: `1px solid ${theme.palette.tertiary.medium}`,
    '& > svg': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  finalized: {
    lineHeight: '4rem',
    width: 'fit-content',
    color: theme.palette.grey['500'],
    backgroundColor: theme.palette.grey['100'],
    border: `1px solid ${theme.palette.grey['300']}`,
    '& > svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}));

const FocusFinalizedMessage = ({ undoFinalize, isSuccessful }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        classes.focusFinalizedMessageRoot,
        isSuccessful ? classes.achieved : classes.finalized
      )}
    >
      <SvgIcon icon={isSuccessful ? Icons.CHECK_LARGE : Icons.CANCEL} />
      {t(isSuccessful ? 'focus.achieved' : 'focus.finalized')}
      <Button variant="text" onClick={undoFinalize}>
        ({t('focus.undoFinalize')})
      </Button>
    </div>
  );
};

export default FocusFinalizedMessage;
