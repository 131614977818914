import * as React from 'react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { FileReaderResult } from '../../../../config/api/models';

type Props = {
  file: FileReaderResult;
  onSelectFile: (file: FileReaderResult) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '&:after': {
      content: '""',
      clear: 'both',
      display: 'table',
    },
  },
  dialogBodyInput: {
    width: '.1px',
    height: '.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
    '& + label': {
      position: 'relative',
      fontSize: '1.3rem',
      fontWeight: 300,
      lineHeight: '2rem',
      textTransform: 'none',
      boxShadow: 'none',
      borderRadius: '1.5rem',
      padding: '.2rem 1.5rem .3rem 1.5rem',
      '&:active': {
        boxShadow: 'none',
      },
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.medium,
      },
      cursor: 'pointer',
      display: 'inline-block',
      height: '2.5rem',
    },
  },
  fileUploadForm: {
    position: 'relative',
    textAlign: 'center',
  },
  coverImagePreviewer: {
    float: 'left',
    maxWidth: '20rem',
    marginRight: '1rem',
    marginBottom: '1rem',

    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
  },
}));

const DreamCoverImageFormField = ({ file, onSelectFile }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent) => {
        onSelectFile(e.target && (e.target as FileReader).result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div id="dream-cover-image-form-field" className={classes.wrapper}>
      <h3>{t('dream.coverImage')}</h3>
      <div>
        {!!file && typeof file === 'string' && (
          <div className={classes.coverImagePreviewer}>
            <img src={file} alt="" />
          </div>
        )}
        <input
          className={classes.dialogBodyInput}
          accept="image/*"
          id="contained-button-file"
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="contained-button-file">
          {!!file ? t('forms.buttons.selectOtherCoverImage') : t('forms.buttons.selectCoverImage')}
        </label>
      </div>
    </div>
  );
};

export default DreamCoverImageFormField;
