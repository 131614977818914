import React, { useState } from 'react';
import { IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ProfilePicture from '../ProfilePicture';
import SvgIcon, { Icons } from '../SvgIcon';
import TextField from '../TextField';
import clsx from 'clsx';

type Props = {
  avatar: string;
  onSend: (message: string) => void;
  position: 'top' | 'bottom';
};

const useStyles = makeStyles((theme: Theme) => ({
  chatActionsRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '2rem',
    '& div, & button': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  positionTop: {
    borderBottom: theme.config.defaultBorder,
  },
  positionBottom: {
    borderTop: theme.config.defaultBorder,
  },
  picture: {
    width: '4.6rem',
    margin: 0,
  },
  input: {
    width: '100%',
    margin: '0 1rem',
  },
  send: {
    background: theme.palette.primary.main,
    color: '#FFFFFF',
  },
}));

const ChatActions = ({ avatar, onSend, position }: Props) => {
  const classes = useStyles();
  const [message, setMessage] = useState<string>('');

  const handleSend = (chatMessage: string) => {
    if (chatMessage.trim()) {
      onSend(chatMessage);
      setMessage('');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSend(message);
    }
  };

  return (
    <div
      className={clsx(
        classes.chatActionsRoot,
        position === 'top' ? classes.positionTop : classes.positionBottom
      )}
    >
      <div className={classes.picture}>
        <ProfilePicture profilePicture={avatar} />
      </div>
      <TextField
        className={classes.input}
        value={message}
        onChange={event => setMessage(event.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />
      <IconButton className={classes.send} onClick={() => handleSend(message)}>
        <SvgIcon icon={Icons.SEND_ARROW} />
      </IconButton>
    </div>
  );
};

export default ChatActions;
