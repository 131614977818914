import { FileDTO } from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import apiRoutes from '../../../config/api/routes';
import { getJWt } from '../../security/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../actions';

let filesToDeleteCount: number = 0;
let filesToAddCount: number = 0;

export interface UseFilePersistValues {
  fileIdsToDelete: string[];
  filesToAdd: File[];
}

enum PersistType {
  BOTH,
  ADD,
  DELETE,
}

export function useFilesPersist(
  modelType: 'dream' | 'focus' | 'learn' | 'lookback',
  modelId: string,
  callback?: () => void
) {
  const [, fetchFiles] = useFetch(ApiConfig.loadFile(modelType, modelId));
  const [deleteReady, setDeleteReady] = useState<boolean>(false);
  const [addReady, setAddReady] = useState<boolean>(false);
  const [persistType, setPersistType] = useState<PersistType | undefined>();

  const token = useSelector(getJWt);

  const dispatch = useDispatch();

  const headers: RequestInit['headers'] = useMemo(
    () => ({
      Authorization: `Bearer ${token}`,
      Accept: 'application/vnd.api+json',
      'Content-type': 'application/json',
    }),
    [token]
  );

  useEffect(() => {
    if (
      (persistType === PersistType.BOTH && addReady && deleteReady) ||
      (addReady && persistType === PersistType.ADD) ||
      (deleteReady && persistType === PersistType.DELETE)
    ) {
      fetchFiles();
      setPersistType(undefined);
      dispatch(Actions.setPersistFiles(undefined));
      if (callback) {
        callback();
      }
    }
  }, [addReady, deleteReady, fetchFiles, persistType, callback, dispatch]);

  return useCallback(
    (values: UseFilePersistValues) => {
      dispatch(Actions.setPersistFiles(modelType));

      if (values.fileIdsToDelete.length && values.filesToAdd.length) {
        setPersistType(PersistType.BOTH);
      } else if (values.fileIdsToDelete.length) {
        setPersistType(PersistType.DELETE);
      } else if (values.filesToAdd.length) {
        setPersistType(PersistType.ADD);
      }

      filesToAddCount = values.filesToAdd.length ? values.filesToAdd.length : 0;
      filesToDeleteCount = values.fileIdsToDelete.length ? values.fileIdsToDelete.length : 0;

      // Deleting files
      if (values.fileIdsToDelete.length) {
        values.fileIdsToDelete.forEach(async fileId => {
          const result = await fetch(apiRoutes.files(fileId), {
            headers,
            method: 'DELETE',
          });

          if (result && result.status >= 200 && result.status < 300) {
            filesToDeleteCount--;
            if (!filesToDeleteCount) {
              setDeleteReady(true);
            }
          }
        });
      }

      // Adding files
      if (values.filesToAdd.length) {
        values.filesToAdd.forEach(file => {
          const fileReader = new FileReader();
          fileReader.onload = async () => {
            if (fileReader.result) {
              const newFile: FileDTO = {
                modelId,
                modelType,
                content: fileReader.result.toString(),
                filename: file.name,
              };

              const result = await fetch(apiRoutes.postFiles(), {
                headers,
                method: 'POST',
                body: JSON.stringify(newFile),
              });

              if (result) {
                filesToAddCount--;
                if (!filesToAddCount) {
                  setAddReady(true);
                }
              }
            }
          };
          fileReader.readAsDataURL(file);
        });
      }
    },
    [dispatch, setPersistType, modelType, headers, modelId]
  );
}

export default useFilesPersist;
