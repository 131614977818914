import React, { useCallback, useState } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { Redirect } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMilaFirstDreamId, getMilaUserId } from '../selectors';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { Actions } from '../actions';
import { Actions as CompassActions } from '../../myCompass/actions';

const InitialFocusTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const [showTour, setShowTour] = useState(true);
  const [tourDone, setTourDone] = useState(false);
  const milaUserId = useSelector(getMilaUserId);
  const milaFirstDreamId = useSelector(getMilaFirstDreamId);
  const dispatch = useDispatch();

  const steps = [
    {
      target: '#help-button',
      content: t('tour.steps.focus.first'),
      disableBeacon: true,
      isFixed: true,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.SKIPPED].includes(status)
      ) {
        setShowTour(false);
        dispatch(CompassActions.disableInitialFocusCreated());
      }
      if ([STATUS.FINISHED].includes(status)) {
        dispatch(Actions.enableHelp());
        setShowTour(false);
        setTourDone(true);
      }
    },
    [setShowTour, dispatch]
  );

  return (
    <>
      <Joyride
        steps={steps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        showProgress
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.milaFocus'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      {tourDone && (
        <Redirect
          to={getPath('dreamDetail')
            .replace(':userId', milaUserId)
            .replace(':dreamId', milaFirstDreamId)}
        />
      )}
    </>
  );
};

export default InitialFocusTour;
