import React from 'react';

import { FieldRenderProps } from 'react-final-form';
import { Moment } from 'moment';
import DatePicker from './DatePicker';
import moment from 'moment';

type Props = FieldRenderProps<Moment, any> & {};

const DatePickerAdapter = ({ input: { onChange, value } }: Props) => {
  const date = value ? (value.isValid() ? value : moment()) : value;
  return <DatePicker onSelect={onChange} value={date} />;
};

export default DatePickerAdapter;
