import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Focus, InspirationContext } from '../../../../config/api/models';
import Html from '../../../../components/Html';
import inspiration from '../../../inspiration';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type Props = {
  focus: Focus;
};

const useStyles = makeStyles((theme: Theme) => ({
  showDreamRoot: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '0 -2rem',
    },
  },
  showDreamPaper: {
    padding: '4rem',
  },
  editButton: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    color: theme.palette.grey['400'],
    cursor: 'pointer',
    transitionProperty: 'color',
    transitionDuration: theme.transitions.duration.standard + 'ms',
    transitionTimingFunction: theme.transitions.easing.sharp,
    '&:hover': {
      color: theme.palette.grey['600'],
    },
  },
  focusTitle: {
    marginTop: '0',
    marginBottom: '1rem',
    fontSize: '3rem',
    fontWeight: 800,
    position: 'relative',
    wordBreak: 'break-word',
    '& > svg': {
      color: theme.palette.tertiary.main,
      width: '3.6rem',
      height: '3.6rem',
      [theme.breakpoints.down('xs')]: {
        top: '0.5rem',
        position: 'absolute',
        left: '-4.4rem',
      },
    },
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  achievedAtContainer: {
    display: 'inline-block',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  achievedAt: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['400'],
    margin: '0',
  },
}));

const ShowFocus = ({ focus }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <inspiration.InspirationFormDialog inspirationKey={InspirationContext.COACHEE_FOCUS}>
      <h2 className={classes.focusTitle}>
        {focus.title}
        {focus.isClosed && focus.isSuccessful ? (
          <>
            <SvgIcon icon={Icons.CHECK_LARGE} />
            {focus.closedAt && (
              <div className={classes.achievedAtContainer}>
                <p className={classes.achievedAt}>
                  {t('milestone.achieved_at').replace('%DATE%', moment(focus.closedAt).format('L'))}
                </p>
              </div>
            )}
          </>
        ) : null}
      </h2>
      <Html>{focus.body}</Html>
    </inspiration.InspirationFormDialog>
  );
};

export default ShowFocus;
