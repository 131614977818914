import React, { useEffect } from 'react';
import Dream from '../containers/Dream/Dream';
import FocusWrapper from '../containers/Focus/FocusWrapper';
import LearnWrapper from '../containers/Learn/LearnWrapper';
import FocusSection from '../containers/Focus/FocusSection';
import LookBack from '../containers/LookBack/LookBack';
import Plan from '../containers/Plan/Plan';
import FinalizeFocus from '../containers/Focus/FinalizeFocus';
import PageContentWrapper from '../containers/PageContentWrapper';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@material-ui/core';
import MilestoneWrapper from '../containers/Milestone/MilestoneWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getCompassUserId, getCompassUserInfo } from '../selectors';
import { useParams } from 'react-router-dom';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig } from '../api';
import { useCompassUser } from '../../shared/hooks';
import { actions } from 'react-redux-fetch';
import { CompassState } from '../model';
import { Actions } from '../actions';
import useUser from '../../security/hooks/useUser';
import { DreamDetailTour } from '../../shared/tours';
import { MilaDreamTour } from '../../shared/tours';
import { getMilaFirstDreamId, getMilaUserId } from '../../shared/selectors';

const DreamDetailPage = () => {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const { dreamId, userId } = useParams();
  const isCompassUser = useCompassUser();
  const dispatch = useDispatch();
  const currentUser = useUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const milaFirstDreamId = useSelector(getMilaFirstDreamId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const compassUser = useSelector(getCompassUserInfo);
  const [, getCompassUser] = useFetch<UserResponse>(ApiConfig.loadCompassUser(userId));

  useEffect(() => {
    if (isCompassUser && !compassUserId) {
      getCompassUser();
    }
    if (!isCompassUser && compassUserId) {
      dispatch(actions.clear('compassUser'));
    }
    dispatch(
      Actions.setCompassState(isCompassUser ? CompassState.COMPASS : CompassState.MYCOMPASS)
    );
  }, [isCompassUser, getCompassUser, compassUserId, dispatch]);

  if (isCompassUser && !compassUserId) {
    return null;
  }

  return (
    <>
      <PageContentWrapper color={theme.palette.myCompass.green}>
        <div id={`dream-${dreamId}`}>
          <Dream>
            <FocusWrapper dreamId={dreamId}>
              <FocusSection id="i-plan" title="iPlan" tooltip={t('plan.inspiration')}>
                <Plan />
              </FocusSection>
              <FocusSection id="i-learn" title="iLearn" tooltip={t('learn.inspiration')}>
                <LearnWrapper />
              </FocusSection>
              <FocusSection id="i-lookback" title="iLookBack" tooltip={t('lookBack.inspiration')}>
                <LookBack />
              </FocusSection>
              <MilestoneWrapper user={isCompassUser ? compassUser : undefined} />
              <FinalizeFocus />
            </FocusWrapper>
          </Dream>
        </div>
      </PageContentWrapper>
      {!currentUser.dreamdetailTourDone && <DreamDetailTour />}
      {isMila && dreamId === milaFirstDreamId && <MilaDreamTour />}
    </>
  );
};

export default DreamDetailPage;
