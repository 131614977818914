import React from 'react';
import { makeStyles } from '@material-ui/styles';
import UpdateTalent from './UpdateTalent';
import NewTalent from './NewTalent';
import { Talent as TalentModel, TalentId, Maybe } from '../../../config/api/models';

type Props = {
  talent: Maybe<TalentModel>;
  talentId: Maybe<TalentId>;
  refetch: () => void;
  onDelete?: () => void;
};

const useStyles = makeStyles(() => ({
  learnRoot: {
    marginBottom: '3rem',
  },
}));

const Talent = ({ refetch, talent, talentId, onDelete }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.learnRoot}>
      {talent && talentId ? (
        <UpdateTalent talent={talent} talentId={talentId} onDelete={onDelete} />
      ) : (
        <NewTalent onSubmit={refetch} />
      )}
    </div>
  );
};

export default Talent;
