import React, { useEffect, useState, useRef } from 'react';
import { ChatsResponse, User, ChatMessage, RelationContext } from '../../../config/api/models';
import Chat from '../../../components/chat/Chat';
import useFetch from '../../../helpers/useFetch';
import useUser from '../../security/hooks/useUser';
import inpiration from '../../inspiration';
import useInspirationDialogContext from '../../inspiration/hooks/useInspirationDialogContext';
import { ContextDialogState } from '../../../components/chat/ChatContext';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../actions';
import { getKey, isCoachOrCoachee } from '../helpers/helperFunctions';
import { ApiConfig } from '../../shared/api';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';
import ChatMobile from '../../../components/chat/ChatMobile';
import { unionBy } from 'lodash';
import { getCompassUserId } from '../../myCompass/selectors';
import { useCompassUser } from '../../shared/hooks';
import { getMilaUserId } from '../../shared/selectors';

type Props = {
  selectedChat: RelationContext;
  setSelectedChat: (realation?: RelationContext) => void;
  refreshMessages: string[];
  setRefreshMessages: (value: string[]) => void;
};

const CoachChat = ({
  selectedChat,
  refreshMessages,
  setRefreshMessages,
  setSelectedChat,
}: Props) => {
  const contextId = selectedChat.context ? selectedChat.context.entityId : '';

  const [addMessageRequest, addMessage] = useFetch(ApiConfig.addMessage());
  const [, putMessagesSeen] = useFetch(ApiConfig.putMessagesSeen());
  const [chatRequest, fetchChat] = useFetch<ChatsResponse>(
    ApiConfig.chat(selectedChat.contextType, contextId)
  );
  const [chatsByIdRequest, fetchChatsById] = useFetch<ChatsResponse>(ApiConfig.messagesById());

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [contextDialogState, setContextDialogState] = useState<ContextDialogState>();
  const [unseenMessages, setUnseenMessages] = useState<boolean>(false);

  const user: User = useUser();
  const dispatch = useDispatch();
  const messageScrollContainer = useRef<HTMLDivElement>(null);

  const { onToggle } = useInspirationDialogContext('InspirationChatDialog');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentUserId, otherUserId] =
    selectedChat.receiverId === user.entityId
      ? [selectedChat.receiverId, selectedChat.initiatorId]
      : [selectedChat.initiatorId, selectedChat.receiverId];

  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;

  // Messages state
  useEffect(() => {
    if (chatRequest && chatRequest.value && !chatRequest.pending) {
      setMessages(chatRequest.value.data.map(d => d.attributes));
      setUnseenMessages(true);
    }
  }, [chatRequest]);

  useEffect(() => {
    if (chatsByIdRequest && chatsByIdRequest.value && !chatsByIdRequest.pending) {
      const newChats = chatsByIdRequest.value.data.map(d => d.attributes);
      setMessages(c => unionBy(c, newChats, v => [v.entityId, v.createdAt, v.body].join()));
    }
  }, [chatsByIdRequest]);

  // Fetch messages when selectedChat changes
  useEffect(() => {
    setMessages([]);
    fetchChat(currentUserId, otherUserId);
  }, [selectedChat, setMessages, currentUserId, otherUserId, fetchChat]);

  // Refetch messages
  useEffect(() => {
    if (refreshMessages.length) {
      fetchChatsById(refreshMessages);
      setRefreshMessages([]);
    }
  }, [refreshMessages, fetchChatsById, setRefreshMessages]);

  // Scrollbehaviour mobile
  useEffect(() => {
    if (messageScrollContainer.current) {
      messageScrollContainer.current.scrollTop = messageScrollContainer.current.scrollHeight;
    }
  }, [messages.length]);

  // handle unseen messages
  useEffect(() => {
    if (unseenMessages && messages.length) {
      const unreadMessages = messages.filter(
        message => !message.seenAt && message.senderId === otherUserId
      );

      if (unreadMessages.length) {
        const m = unreadMessages[0];
        const relationKey = getKey('relation', selectedChat.entityId);
        const contextKey = getKey(
          m.contextType,
          m.context.entityId,
          selectedChat.initiatorId,
          selectedChat.receiverId
        );
        dispatch(Actions.setMessagesSeen(relationKey, contextKey));
        putMessagesSeen(unreadMessages.map(message => message.entityId));
      }
      setUnseenMessages(false);
    }
  }, [unseenMessages, messages, selectedChat, dispatch, otherUserId, putMessagesSeen]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    // Adding a message, handling errors
    if (
      addMessageRequest &&
      addMessageRequest.rejected &&
      messages.length &&
      !messages[messages.length - 1].failed
    ) {
      const failedMessage = {
        ...lastMessage,
        failed: true,
      };
      const enhancedMessages = messages.slice();
      enhancedMessages[messages.length - 1] = failedMessage;
      setMessages(enhancedMessages);
    }
  }, [addMessageRequest, messages.length, messages]);

  // replace new chat message with the returned POST value
  useEffect(() => {
    if (addMessageRequest && addMessageRequest.fulfilled && addMessageRequest.value) {
      const addedMessage = addMessageRequest.value.data.attributes;
      setMessages(ms =>
        ms.map(m => (!m.entityId && m.body === addedMessage.body ? addedMessage : m))
      );
    }
  }, [addMessageRequest]);

  const otherUser =
    selectedChat.receiverId === user.entityId ? selectedChat.initiator : selectedChat.receiver;
  const loadingChat = !chatRequest || (chatRequest.pending && !chatRequest.value);

  const handleSendMessage = (message: string) => {
    const messageDto = {
      senderId: user.entityId,
      receiverId: otherUser.entityId,
      contextType: selectedChat.contextType,
      body: message,
      createdAt: new Date().toISOString(),
    };
    const newMessage = {
      ...messageDto,
      sender: user,
      receiver: otherUser,
      context: selectedChat.context,
    } as ChatMessage;
    addMessage({ ...newMessage, contextId: selectedChat.context.entityId });
    setMessages([...messages, newMessage]);
    setUnseenMessages(true);
  };

  const handleContextDialogStateChange = (newState: ContextDialogState) => {
    if (!!newState !== !!contextDialogState) {
      onToggle();
    }
    setContextDialogState(newState);
  };

  const isCoach =
    isCoachOrCoachee(
      selectedChat,
      selectedChat.receiverId === currentUserId ? selectedChat.receiver : selectedChat.initiator
    ) === 'coach';

  if (isMobile) {
    if (contextDialogState) {
      handleContextDialogStateChange(undefined);
    }
    return (
      <ChatMobile
        withInspiration={!isCoach}
        relationType={isCoach ? 'coach' : 'coachee'}
        contextId={contextId}
        contextType={selectedChat.contextType}
        messages={messages}
        handleSendMessage={handleSendMessage}
        loadingChat={loadingChat}
        open={Boolean(selectedChat)}
        onToggle={() => setSelectedChat(undefined)}
        otherUser={otherUser}
        ref={messageScrollContainer}
        disabled={Boolean(selectedChat.stoppedAt) || isMila}
        setMessages={setMessages}
      />
    );
  }

  return (
    <>
      <inpiration.InspirationChatDialog
        relationType={isCoach ? 'coach' : 'coachee'}
        onClose={() => handleContextDialogStateChange(undefined)}
        contextType={selectedChat.contextType}
        contextId={contextId}
        content={contextDialogState}
      >
        <Chat
          context={selectedChat.context}
          messages={messages}
          currentUser={user}
          otherUser={otherUser}
          onSend={handleSendMessage}
          onDialogStateChange={handleContextDialogStateChange}
          dialogState={contextDialogState}
          contextType={selectedChat.contextType}
          disabled={Boolean(selectedChat.stoppedAt) || isMila}
          loading={loadingChat}
          isCoachee={isCoachOrCoachee(selectedChat, user) === 'coachee'}
          setMessages={setMessages}
        />
      </inpiration.InspirationChatDialog>
    </>
  );
};

export default CoachChat;
