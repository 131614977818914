import React from 'react';
import { Showcase, TalentId } from '../../../config/api/models';
import PanelWithSidebar from '../../../components/PanelWithSidebar';
import { getUserDisplayName } from '../../../helpers/getUserDisplayName';
import ShowcaseBlock from '../components/ShowcaseBlock';
import Section from '../../../components/Section';
import { useTranslation } from 'react-i18next';

type Props = {
  showcase: Showcase;
};

const ShowcaseTalents = ({ showcase }: Props) => {
  const { t } = useTranslation();

  const getCommentList = (id: TalentId) => {
    const talent = showcase.map.talents.find(tm => tm.talentId === id);

    if (talent) {
      return showcase.comments.filter(comment =>
        talent.comments.map(c => c.toString()).includes(comment.entityId)
      );
    }

    return [];
  };

  return (
    <Section id="talents" title={t('talents.title')} removeTopMargin>
      {!showcase.talents.length ? (
        <PanelWithSidebar>
          <p>{getUserDisplayName(showcase.user, true)} heeft geen informatie gedeeld.</p>
        </PanelWithSidebar>
      ) : (
        showcase.talents.map(talent => (
          <ShowcaseBlock
            key={talent.entityId}
            context={talent}
            comments={[...getCommentList(talent.entityId)]}
          />
        ))
      )}
    </Section>
  );
};

export default ShowcaseTalents;
