import React, { useEffect, useState } from 'react';
import ShowFocus from '../../components/Focus/ShowFocus';
import UpdateFocusForm from './UpdateFocusForm';
import inspiration from '../../../inspiration';
import Sidebar from '../../../../components/Sidebar';
import { ApiConfig } from '../../api';
import { ApiConfig as SharedApiConfig } from '../../../shared/api';
import EditablePanel from '../../../../components/EditablePanel';
import useFetch from '../../../../helpers/useFetch';
import {
  CONTEXT_TYPE_FOCUS,
  FilesResponse,
  FocusId,
  FocusRequest,
  FocusResponse,
  Maybe,
} from '../../../../config/api/models';
import ReactContentLoader from '../../../../components/Loader';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme } from '@material-ui/core';
import FileList from '../../../shared/components/FileList';
import SidebarButton from '../../../../components/SidebarButton';
import { Icons } from '../../../../components/SvgIcon';
import CreateRelationDialog from '../../../shared/components/CreateRelationDialog';
import { useTranslation } from 'react-i18next';
import ContentVisibilityButton from '../../../../components/ContentVisibilityButton';
import CommunicationWrapper from '../../components/CommunicationWrapper';
import FormError from '../../../../components/forms/FormError';
import { CompassState } from '../../model';
import { useSelector } from 'react-redux';
import {
  getCompassState,
  getCompassUserId,
  getPostsForFocus,
  getSelectedDreamResponse,
} from '../../selectors';
import ShowcaseSidebarOptions, {
  ShowcaseSidebarValues,
} from '../../../myShowcase/components/ShowcaseSidebarOptions';
import { useLocation } from 'react-router-dom';
import { getMilaUserId } from '../../../shared/selectors';
import DateTimeMessage from '../../../shared/components/DateTimeMessage';

type Props = {
  focusId: Maybe<FocusId>;
  children: React.ReactChild | React.ReactChild[];
};

const useStyles = makeStyles((theme: Theme) => ({
  focusContent: {
    position: 'relative',
    paddingLeft: '3rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
    },

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-2.5rem',
      left: '1.25rem',
      width: '.1rem',
      height: '2rem',
      background: theme.palette.grey['200'],
    },
    '& > section': {
      position: 'relative',

      '&:not(:last-of-type)::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '4rem',
        left: '-1.75rem',
        width: '.1rem',
        height: 'calc(100% - .5rem)',
        background: theme.palette.grey['200'],
      },
    },
  },
}));

const focusConfig = (focusId: FocusId) => ApiConfig.loadFocus(focusId);

const UpdateFocus = ({ focusId, children }: Props) => {
  const classes = useStyles();
  const modelType = 'focus';
  const compassState = useSelector(getCompassState);
  const dreamResponse = useSelector(getSelectedDreamResponse);
  const postIds = useSelector(getPostsForFocus);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const location = useLocation();

  const [focusResponse, fetchFocus] = useFetch<FocusResponse>(
    focusConfig(focusId || ('' as FocusId))
  );
  const [, patchFocus] = useFetch(ApiConfig.updateFocus(focusId || ('' as FocusId)));
  const [filesResponse, fetchFile] = useFetch<FilesResponse>(
    ApiConfig.loadFile(modelType, focusId ? focusId : '')
  );
  const [, deletePost] = useFetch(SharedApiConfig.removePost());

  const value = focusResponse && focusResponse.value && focusResponse.value;
  const currentFocusId = value && value.data.id;
  const disableShowcaseToggles = Boolean(
    (dreamResponse && !dreamResponse.data.attributes.includeShowcase) ||
      !(value && value.data.attributes.visibility) ||
      compassUserId === milaUserId
  );
  const hashValue = location.hash;
  if (hashValue) {
    const anchor = document.getElementById(hashValue);

    if (anchor) {
      anchor.scrollIntoView();
    }
  }

  // eslint-disable-next-line
  useEffect(fetchFocus, [focusId]);

  useEffect(() => {
    if (!filesResponse) {
      fetchFile();
    }
  }, [filesResponse, fetchFile]);

  const allFiles =
    (filesResponse && filesResponse.value && filesResponse.value.data.map(d => d.attributes)) || [];

  const isOtherFocus = focusId !== currentFocusId;

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const focusDTO: Partial<FocusRequest> = {
      visibility: !checked,
    };
    patchFocus(focusDTO);
    if (focusId) {
      [...postIds].forEach(id => deletePost(id));
    }
  };

  const handleShowcaseChange = (values: ShowcaseSidebarValues) => {
    patchFocus(values);
  };

  const isPrivate = Boolean(
    focusResponse && focusResponse.value && !focusResponse.value.data.attributes.visibility
  );

  const getSidebar = () => {
    switch (compassState) {
      case CompassState.MYCOMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton
              value={isPrivate}
              onChange={handleVisibilityChange}
              tooltip={t('focus.visibilityTooltip')}
            />
            <inspiration.InspirationSidebarButton />
            <SidebarButton
              icon={Icons.COACH}
              primary={t('myCoach.invite.button')}
              onClick={() => setOpen(true)}
            />
          </Sidebar>
        );
      case CompassState.COMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton
              id="focus-visibility-button"
              value={isPrivate}
              onChange={handleVisibilityChange}
              tooltip={t('focus.visibilityTooltip')}
              disabled={compassUserId === milaUserId}
            />
            <inspiration.InspirationSidebarButton
              id="focus-inspiration-button"
              disabled={compassUserId === milaUserId}
            />
            <SidebarButton
              id="focus-add-coach-button"
              icon={Icons.COACH}
              primary={t('myCoach.invite.button')}
              onClick={() => setOpen(true)}
              disabled={compassUserId === milaUserId}
            />
          </Sidebar>
        );
      case CompassState.MYSHOWCASE:
        return (
          <Sidebar>
            <ShowcaseSidebarOptions
              disabled={disableShowcaseToggles}
              onChange={values => handleShowcaseChange(values)}
              values={{
                includeShowcase: value ? value.data.attributes.includeShowcase : false,
                includeShowcaseComments: value
                  ? value.data.attributes.includeShowcaseComments
                  : false,
                includeShowcaseFiles: value ? value.data.attributes.includeShowcaseFiles : false,
              }}
            />
          </Sidebar>
        );
      default:
        return undefined;
    }
  };

  return (
    <>
      <inspiration.InspirationDialogProvider>
        <EditablePanel
          showToggleButton={compassState === CompassState.MYCOMPASS}
          view={
            isOtherFocus || !value ? (
              <ReactContentLoader />
            ) : focusResponse && focusResponse.rejected ? (
              <FormError>{t('error.private')}</FormError>
            ) : (
              <div id={`focus-${focusId}`}>
                <ShowFocus focus={value.data.attributes} />
                <FileList files={allFiles} loading={filesResponse && filesResponse.pending} />
                <Box display="flex" justifyContent="flex-end">
                  <DateTimeMessage
                    createdAt={value.data.attributes.createdAt}
                    updatedAt={value.data.attributes.updatedAt}
                  />
                </Box>
              </div>
            )
          }
          form={
            isOtherFocus || !value ? (
              <ReactContentLoader />
            ) : (
              <UpdateFocusForm
                focus={value!.data.attributes}
                focusId={value.data.id}
                files={allFiles}
              />
            )
          }
          sidebar={getSidebar()}
          suffixComponent={
            value ? (
              <CommunicationWrapper
                context={value.data.attributes}
                contextType="focus"
                disabled={
                  isPrivate ||
                  compassState === CompassState.SHOWCASE ||
                  compassState === CompassState.MYSHOWCASE
                }
              />
            ) : (
              undefined
            )
          }
        />
        {compassState === CompassState.MYCOMPASS && (
          <CreateRelationDialog
            contextType={CONTEXT_TYPE_FOCUS}
            contextId={focusId as string}
            open={open}
            onClose={() => setOpen(false)}
            dialogType="coach"
          />
        )}
      </inspiration.InspirationDialogProvider>

      {!isOtherFocus && <div className={classes.focusContent}>{children}</div>}
    </>
  );
};

export default UpdateFocus;
