import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InferRequest } from 'react-redux-fetch';
import { DreamsResponse, UserId } from '../../../../config/api/models';
import DreamCard from '../../components/Dream/DreamCard';
import useFetch from '../../../../helpers/useFetch';
import security from '../../../security';
import { ApiConfig } from '../../api';
import sortByDateUpdated from '../../../../helpers/sortByDateUpdated';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddDreamCard from './AddDreamCard';
import NewDream from './NewDream';
import { useCompassUser } from '../../../shared/hooks';
import { getCompassUserId } from '../../selectors';
import { getMilaUserId } from '../../../shared/selectors';
import useUser from '../../../security/hooks/useUser';
import { DreamsOverviewTour } from '../../../shared/tours';

type Props = {
  onAddDream: () => void;
  userId: UserId;
  id?: string;
};

const apiConfig = ApiConfig.loadAllDreams();

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingRight: '2rem',
  },
}));

const DreamsOverview = ({ userId, onAddDream, id }: Props) => {
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const dreamLink = useSelector(security.selectors.getDreamLink(userId));
  const [dreamsResponse, fetchDreams] = useFetch<DreamsResponse, InferRequest<typeof apiConfig>>(
    apiConfig
  );
  const currentUser = useUser();

  const classes = useStyles();

  const isLoading = !dreamsResponse || !dreamsResponse.value;
  const dreams = (dreamsResponse && dreamsResponse.value && dreamsResponse.value.data) || [];

  useEffect(() => {
    if (dreamLink) {
      fetchDreams(dreamLink);
    }
  }, [dreamLink, fetchDreams]);

  const handleSubmitNewDream = useCallback(() => {
    if (dreamLink) {
      fetchDreams(dreamLink);
    }
  }, [dreamLink, fetchDreams]);

  if (isLoading) {
    return null;
  }

  return dreams.length === 0 ? (
    <div id={id}>
      <NewDream onSubmit={handleSubmitNewDream} />
    </div>
  ) : (
    <div className={classes.wrapper} id={id}>
      <Grid container spacing={2}>
        {dreams &&
          sortByDateUpdated(dreams, isMila).map(dream => (
            <React.Fragment key={dream.attributes.entityId}>
              <Grid item xs={12} sm={6} lg={4}>
                <DreamCard dream={dream.attributes} />
              </Grid>
            </React.Fragment>
          ))}
        {(!isCompassUser || isMila) && (
          <Grid item xs={12} sm={6} lg={4}>
            <AddDreamCard disabled={isMila} onAddDream={onAddDream} />
          </Grid>
        )}
      </Grid>
      {(isMila || (!isCompassUser && !currentUser.tourDone)) && <DreamsOverviewTour />}
    </div>
  );
};

export default DreamsOverview;
