import React, { useState } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const NewDreamTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showTour, setShowTour] = useState(true);

  const steps = [
    {
      target: '#new-dream-form',
      content: t('tour.steps.dream.first'),
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#dream-inspiration-button',
      content: t('tour.steps.dream.second'),
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#dream-cover-image-form-field',
      content: t('tour.steps.dream.third'),
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = (data: any) => {
    const { status, action } = data;
    if (
      [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
    ) {
      setShowTour(false);
    }
  };

  return (
    <Joyride
      steps={steps}
      run={showTour}
      callback={handleTourCallback}
      continuous
      showSkipButton
      showProgress
      locale={{
        back: t('tour.locale.back'),
        close: t('tour.locale.close'),
        last: t('tour.locale.close'),
        next: t('tour.locale.next'),
        skip: t('tour.locale.skip'),
      }}
      styles={theme.config.joyRideStyles.tour}
    />
  );
};

export default NewDreamTour;
