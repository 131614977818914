import apiRoutes from '../../config/api/routes';
import memoizeOne from 'memoize-one';
import {
  RelationId,
  RelationContext,
  FileDTO,
  RelationType,
  RelationCreateRequest,
  ContextType,
  UserId,
  ChatMessageRequest,
  Post,
  PostId,
  User,
  ChatMessage,
  RelationUsersIds,
} from '../../config/api/models';
import moment from 'moment';
import { Language } from '../../config/i18n/types';

export const RESOURCE = {
  PRIVATE_ROUTES: 'privateRoutes',
  PUBLIC_ROUTES: 'publicRoutes',
  COACH_RELATIONS: 'coachRelations',
  COACH_RELATION: 'coachRelation',
  MY_COACH_RELATION: 'myCoachRelation',
  MY_COACH_RELATIONS: 'myCoachRelations',
  PEER_RELATIONS: 'peerRelations',
  PEER_RELATION: 'peerRelation',
  FILES: 'file',
  ADD_FILE: 'addFile',
  REMOVE_FILE: 'removeFile',
  CHAT_MESSAGES: 'chatMessages',
  CHAT_CREATE: 'chatCreate',
  CHAT_SEEN: 'chatSeen',
  POSTS: 'posts',
  POST: 'post',
  USER: 'user',
  TRAY_NOTIFICATIONS: 'trayNotifications',
  TRAY_NOTIFICATION: 'trayNotification',
};

export const ApiConfig = {
  getPrivateRoutes: memoizeOne(() => ({
    resource: RESOURCE.PRIVATE_ROUTES,
    method: 'GET',
    request: () => ({
      url: apiRoutes.privateClientRoutes(),
    }),
  })),
  getPublicRoutes: memoizeOne(() => ({
    resource: RESOURCE.PUBLIC_ROUTES,
    method: 'GET',
    request: () => ({
      url: apiRoutes.publicClientRoutes(),
    }),
  })),
  basicPages: (pageId: string, language: Language) => ({
    resource: language + '/' + pageId,
    request: () => ({
      url: apiRoutes.basicPages(pageId, language),
    }),
  }),
  loadFiles: memoizeOne(() => ({
    resource: RESOURCE.FILES,
    request: {
      url: apiRoutes.files(),
    },
  })),
  loadFile: memoizeOne((modelType: string, modelId: string) => ({
    resource: `Files_for_${modelType}_${modelId}`,
    request: () => ({
      url: apiRoutes.file(modelType, modelId),
    }),
  })),
  addFile: memoizeOne(() => ({
    resource: RESOURCE.ADD_FILE,
    method: 'POST',
    request: (file: FileDTO) => ({
      url: apiRoutes.postFiles(),
      body: {
        ...file,
      },
    }),
  })),
  deleteFile: memoizeOne(() => ({
    resource: RESOURCE.REMOVE_FILE,
    method: 'DELETE',
    request: (fileId: string) => ({
      url: apiRoutes.files(fileId),
    }),
  })),
  relations: memoizeOne(
    (relationType?: RelationType, isMila?: boolean, relationUsersIds?: RelationUsersIds) => ({
      resource: relationType === 'peer' ? RESOURCE.PEER_RELATIONS : RESOURCE.COACH_RELATIONS,
      method: 'GET',
      request: () => ({
        url: apiRoutes.relations(relationType, isMila, relationUsersIds),
      }),
    })
  ),
  myCoaches: memoizeOne((isMila?: boolean) => ({
    resource: RESOURCE.MY_COACH_RELATIONS,
    method: 'GET',
    request: () => ({
      url: apiRoutes.myCoaches(isMila),
    }),
  })),
  myCoachesRelations: memoizeOne((relationType?: RelationType, isMila?: boolean) => ({
    resource: relationType === 'peer' ? RESOURCE.PEER_RELATIONS : RESOURCE.COACH_RELATIONS,
    method: 'GET',
    request: (relationUsersIds: RelationUsersIds) => ({
      url: apiRoutes.relations(relationType, isMila, relationUsersIds),
    }),
  })),
  initialUnreadMessages: memoizeOne((isMila?: boolean) => ({
    resource: 'initialUnreadMessages',
    method: 'GET',
    request: () => ({
      url: apiRoutes.initialUnreadMessages(isMila),
    }),
  })),
  relationCreate: memoizeOne((relationType?: RelationType) => ({
    resource: relationType === 'peer' ? RESOURCE.PEER_RELATION : RESOURCE.COACH_RELATION,
    method: 'post',
    request: (relationDTO: RelationCreateRequest) => ({
      url: apiRoutes.relationCreate(),
      body: {
        data: {
          type: 'relations',
          attributes: relationDTO,
        },
      },
    }),
  })),
  relationAccept: memoizeOne((relationType?: RelationType) => ({
    resource: relationType ? relationType : RESOURCE.COACH_RELATION,
    method: 'PATCH',
    request: (relationId: RelationId, relationAcceptDTO: Partial<RelationContext>) => ({
      url: apiRoutes.relationAccept(relationId),
      body: {
        data: {
          type: 'relations',
          id: relationId.toString(),
          attributes: relationAcceptDTO,
        },
      },
    }),
  })),
  relationDecline: memoizeOne((relationType?: RelationType) => ({
    resource: relationType ? relationType : RESOURCE.COACH_RELATION,
    method: 'PATCH',
    request: (relationId: RelationId, relationDeclineDTO: Partial<RelationContext>) => ({
      url: apiRoutes.relationDecline(relationId),
      body: {
        data: {
          type: 'relations',
          id: relationId.toString(),
          attributes: relationDeclineDTO,
        },
      },
    }),
  })),
  relationStop: memoizeOne((relationType?: RelationType) => ({
    resource: relationType === 'peer' ? RESOURCE.PEER_RELATION : RESOURCE.COACH_RELATION,
    method: 'PATCH',
    request: (relationId: RelationId, relationStopDTO: Partial<RelationContext>) => ({
      url: apiRoutes.relationStop(relationId),
      body: {
        data: {
          type: 'relations',
          id: relationId.toString(),
          attributes: relationStopDTO,
        },
      },
    }),
  })),
  cancelInvitation: memoizeOne((relationType?: RelationType) => ({
    resource: relationType === 'peer' ? RESOURCE.PEER_RELATION : RESOURCE.COACH_RELATION,
    method: 'DELETE',
    request: (relationId: RelationId) => ({
      url: apiRoutes.relationStop(relationId),
    }),
  })),
  chat: memoizeOne((contextType: ContextType, contextId: string) => ({
    resource: `chat_for_${contextType}_${contextId}`,
    method: 'GET',
    request: (currentUserId: UserId, otherUserId: UserId) => ({
      url: apiRoutes.chat(currentUserId, otherUserId, contextType, contextId),
    }),
  })),
  messagesById: memoizeOne(() => ({
    resource: RESOURCE.CHAT_MESSAGES,
    method: 'GET',
    request: (messages?: string[]) => ({
      url: apiRoutes.chats(messages),
    }),
  })),
  addMessage: memoizeOne(() => ({
    resource: RESOURCE.CHAT_CREATE,
    method: 'POST',
    request: (addMessageDTO: ChatMessageRequest) => ({
      url: apiRoutes.chats(),
      body: {
        data: {
          type: 'chats',
          attributes: addMessageDTO,
        },
      },
    }),
  })),
  editMessage: memoizeOne(() => ({
    resource: RESOURCE.CHAT_CREATE,
    method: 'PATCH',
    request: (message: ChatMessage) => ({
      url: apiRoutes.updateChat(message.entityId),
      body: {
        data: {
          type: 'chats',
          id: message.entityId.toString(),
          attributes: {
            body: message.body,
          },
        },
      },
    }),
  })),
  deleteMessage: memoizeOne(() => ({
    resource: RESOURCE.CHAT_CREATE,
    method: 'PATCH',
    request: (message: ChatMessage) => ({
      url: apiRoutes.updateChat(message.entityId),
      body: {
        data: {
          type: 'chats',
          id: message.entityId.toString(),
          attributes: {
            deletedAt: moment(),
          },
        },
      },
    }),
  })),
  putMessagesSeen: memoizeOne(() => ({
    resource: RESOURCE.CHAT_SEEN,
    method: 'POST',
    request: (messageIds: string[]) => ({
      url: apiRoutes.chatSetSeen(),
      body: {
        data: {
          type: 'chats',
          attributes: messageIds,
        },
      },
    }),
  })),
  posts: memoizeOne(() => ({
    resource: RESOURCE.POSTS,
    method: 'GET',
    request: (isMila?: boolean) => ({
      url: apiRoutes.posts(undefined, isMila),
    }),
  })),
  createPost: memoizeOne(() => ({
    resource: RESOURCE.POST,
    method: 'POST',
    request: (post: Partial<Post>) => ({
      url: apiRoutes.posts(),
      body: {
        data: {
          type: 'posts',
          attributes: post,
        },
      },
    }),
  })),
  updatePost: memoizeOne(() => ({
    resource: RESOURCE.POST,
    method: 'PATCH',
    request: (id: PostId, post: Partial<Post>) => ({
      url: apiRoutes.posts(id),
      body: {
        data: {
          id,
          type: 'posts',
          attributes: post,
        },
      },
    }),
  })),
  removePost: memoizeOne(() => ({
    resource: RESOURCE.POST,
    method: 'DELETE',
    request: (id: PostId) => ({
      url: apiRoutes.posts(id),
    }),
  })),
  updateTourDone: () => ({
    resource: RESOURCE.USER,
    method: 'PATCH',
    request: (userId: UserId, user: Partial<User>) => ({
      url: apiRoutes.user(userId),
      body: {
        data: {
          type: 'users',
          id: userId,
          attributes: user,
        },
      },
    }),
  }),
  getTrayNotifications: memoizeOne(() => ({
    resource: RESOURCE.TRAY_NOTIFICATIONS,
    method: 'GET',
    request: (url?: string) => ({
      url: url || apiRoutes.trayNotifications(),
    }),
  })),
  patchTrayNotification: memoizeOne((itemId: string) => ({
    resource: itemId,
    method: 'PATCH',
    request: (notificationId: string) => ({
      url: apiRoutes.patchTrayNotification(notificationId),
      body: {
        data: {
          type: 'trayNotifications',
          id: notificationId,
          attributes: {
            read_at: moment().format(),
          },
        },
      },
    }),
  })),
};
