import * as React from 'react';
import MilestoneBackground from '../../containers/Milestone/MilestoneBackground';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(#40d8f4, #26a8e6)',
    zIndex: 0,
  },
});

const DefaultDreamCoverImage = () => {
  const classes = useStyles();

  return <MilestoneBackground className={classes.root} />;
};

export default DefaultDreamCoverImage;
