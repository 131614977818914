import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { User } from '../config/api/models';
import ProfilePicture from './ProfilePicture';
import UserFlyout from './UserFlyout';
import DefaultProfilePicture from './DefaultProfilePicture';
import GreyStripes from '../assets/grey-stripes.png';
import YellowStripes from '../assets/yellow-stripes.png';
import clsx from 'clsx';
import { getUserDisplayName } from '../helpers/getUserDisplayName';
import { useTranslation } from 'react-i18next';

type Props = {
  user: User;
  type?: 'coach' | 'coachee';
  selected?: boolean;
  unreadMessages?: number;
  onClick?: () => void;
  archived?: boolean;
  notAccepted?: boolean;
};

type StyleProps = {
  clickable: boolean;
  selected: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  userCardRoot: (props: StyleProps) => ({
    padding: '1rem 1rem 1.5rem 1rem',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    cursor: props.clickable ? 'pointer' : 'default',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    minHeight: '6rem',
    borderRight: '0.3rem solid',
    borderRightColor: props.selected ? theme.palette.secondary.main : 'transparent',
    '&:hover': {
      backgroundColor: props.clickable ? theme.palette.grey['100'] : theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'visible',
    },
  }),
  userCardNotAccepted: {
    backgroundImage: `url(${GreyStripes})`,
    backgroundRepeat: 'repeat',
  },
  userCardArchived: {
    backgroundImage: `url(${YellowStripes})`,
    backgroundRepeat: 'repeat',
  },
  profilePicture: {
    position: 'absolute',
    left: '1rem',
    width: '4.6rem',
  },
  name: {
    fontSize: '1.4rem',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  function: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
  unreadMessages: {
    background: theme.palette.highlight.main,
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '.75rem',
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.common.white,
    textShadow: '0 0 .5rem rgba(0,0,0,.2)',
    textAlign: 'center',
    lineHeight: '1.5rem',
  },
  pill: {
    borderRadius: theme.shape.borderRadius * 4,
    border: theme.config.defaultBorder,
    backgroundColor: theme.palette.common.white,
    padding: '.5rem 1rem',
    margin: '.75rem 0 .25rem 0',
    display: 'inline-block',
    fontSize: '.95rem',
    color: theme.palette.text.primary,
    height: 'fit-content',
  },
  infobox: {
    marginLeft: '6rem',
    width: '13rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '8rem',
    },
  },
  unreadMessagesWrapper: {
    marginTop: '1.5rem',
  },
}));

const UserCard = (props: Props) => {
  const {
    user,
    type = 'coachee',
    unreadMessages,
    selected = false,
    onClick,
    archived,
    notAccepted,
  } = props;
  const classes = useStyles({ clickable: onClick !== undefined, selected });
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <div
      className={clsx(
        classes.userCardRoot,
        archived ? classes.userCardArchived : null,
        notAccepted ? classes.userCardNotAccepted : null
      )}
      onClick={onClick}
    >
      <div className={classes.profilePicture}>
        <UserFlyout user={user}>
          {user.media && user.media.profile ? (
            <ProfilePicture profilePicture={user.media.profile} />
          ) : (
            <DefaultProfilePicture />
          )}
        </UserFlyout>
      </div>
      <div className={classes.infobox}>
        <div className={classes.name}>{getUserDisplayName(user, true)}</div>
        <div className={classes.function}>
          {type === 'coachee'
            ? user.department
              ? user.department.name
              : user.function
            : user.function}
        </div>
        {archived ? <span className={classes.pill}>{t('peers.archived')}</span> : null}
        {notAccepted ? <span className={classes.pill}>{t('peers.notAccepted')}</span> : null}
      </div>
      <div className={classes.unreadMessagesWrapper}>
        {unreadMessages ? <div className={classes.unreadMessages}>{unreadMessages}</div> : null}
      </div>
      {/* {selected && <div className={classes.selectedIndicator} />} */}
    </div>
  );
};

export default UserCard;
