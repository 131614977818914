import { InspirationContext, RelationType, InspirationType } from '../../../config/api/models';

export function getInspirationContext(
  value: InspirationType,
  relationType: RelationType
): InspirationContext {
  switch (value) {
    case 'dream':
      return relationType === 'coach'
        ? InspirationContext.COACHEE_DREAM
        : InspirationContext.COACH_DREAM;
    case 'focus':
      return relationType === 'coach'
        ? InspirationContext.COACHEE_FOCUS
        : InspirationContext.COACH_FOCUS;
    case 'plan':
      return relationType === 'coach'
        ? InspirationContext.COACHEE_PLAN
        : InspirationContext.COACH_PLAN;
    case 'lookBack':
      return relationType === 'coach'
        ? InspirationContext.COACHEE_LOOK_BACK
        : InspirationContext.COACH_LOOK_BACK;
    case 'learn':
      return relationType === 'coach'
        ? InspirationContext.COACHEE_LEARN
        : InspirationContext.COACH_LEARN;
    default:
      return InspirationContext.COACH;
  }
}
