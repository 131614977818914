import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from '../config/theme';

type Props = {
  children: React.ReactNode;
  login?: boolean;
  home?: boolean;
};

const useStyles = makeStyles(() => ({
  titleRoot: (props: Props) => ({
    fontWeight: props.home ? 500 : 800,
    fontSize: props.login ? '1.5rem' : '3rem',
    borderBottom: props.login ? `3px solid ${theme.palette.myCompass.red}` : '',
    marginBottom: '3rem',
    display: props.login ? 'inline-block' : 'block',
    paddingBottom: props.login ? '1rem' : '',
  }),
}));

const Title = (props: Props) => {
  const { children, login = false, home = false } = props;
  const classes = useStyles({ login, children, home });

  return (
    <Typography variant="h2" className={classes.titleRoot}>
      {children}
    </Typography>
  );
};

export default Title;
