import React, { useState, useEffect } from 'react';
import { Talent, TalentId, TalentRequest, PostsResponse, Post } from '../../../config/api/models';
import UpdateTalentForm from './UpdateTalentForm';
import ContentBlock from '../../myCompass/components/ContentBlock';
import EditablePanel from '../../../components/EditablePanel';
import ContentVisibilityButton from '../../../components/ContentVisibilityButton';
import Sidebar from '../../../components/Sidebar';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { ApiConfig as SharedApiConfig } from '../../shared/api';
import SidebarSharing from '../../shared/components/SidebarSharing';
import useUser from '../../security/hooks/useUser';
import SharingDialog, { SharingDialogState } from '../../shared/components/SharingDialog';
import CommunicationWrapper from '../../myCompass/components/CommunicationWrapper';
import { useSelector } from 'react-redux';
import { getCompassState, getCompassUserId } from '../../myCompass/selectors';
import { CompassState } from '../../myCompass/model';
import ShowcaseSidebarOptions, {
  ShowcaseSidebarValues,
} from '../../myShowcase/components/ShowcaseSidebarOptions';
import { useCompassUser } from '../../shared/hooks';
import { getMilaUserId } from '../../shared/selectors';

type Props = {
  talent: Talent;
  talentId: TalentId;
  onDelete?: () => void;
};

const UpdateTalent = ({ talent, talentId, onDelete }: Props) => {
  const [, patchTalent] = useFetch(ApiConfig.updateTalent());
  const [postRequest] = useFetch<PostsResponse>(SharedApiConfig.posts());
  const [, removePost] = useFetch<PostsResponse>(SharedApiConfig.removePost());

  const currentUser = useUser();
  const [currentPost, setCurrentPost] = useState<Post>();
  const [sharingDialog, setSharingDialogState] = useState<undefined | SharingDialogState>();
  const compassState = useSelector(getCompassState);
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const talentDTO: Partial<TalentRequest> = {
      visibility: !checked,
    };
    patchTalent(talentId, talentDTO);
    if (currentPost && checked) {
      removePost(currentPost.entityId);
    }
  };

  const handleShowcaseChange = (values: ShowcaseSidebarValues) => {
    patchTalent(talentId, { ...talent, ...values });
  };

  useEffect(() => {
    if (postRequest && postRequest.value) {
      const posts = postRequest.value.data.map(d => d.attributes);
      const current = posts.find(
        p => p.postId === talentId && p.postType === 'talent' && p.authorId === currentUser.entityId
      );
      setCurrentPost(current);
    }
  }, [postRequest, setCurrentPost, currentUser, talentId]);

  const getSidebar = () => {
    switch (compassState) {
      case CompassState.MYCOMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton value={!talent.visibility} onChange={handleVisibilityChange} />
            <SidebarSharing
              onClick={setSharingDialogState}
              currentPost={currentPost}
              disabled={!talent.visibility}
            />
          </Sidebar>
        );
      case CompassState.MYSHOWCASE:
        return (
          <Sidebar>
            <ShowcaseSidebarOptions
              disabled={!talent.visibility || isMila}
              onChange={values => handleShowcaseChange(values)}
              values={{
                includeShowcase: talent.includeShowcase,
                includeShowcaseComments: talent.includeShowcaseComments,
                includeShowcaseFiles: talent.includeShowcaseFiles,
              }}
            />
          </Sidebar>
        );
      default:
        return undefined;
    }
  };

  return (
    <>
      <EditablePanel
        view={
          <ContentBlock
            content={talent}
            onDelete={compassState === CompassState.MYCOMPASS ? onDelete : undefined}
          />
        }
        form={<UpdateTalentForm talent={talent} talentId={talentId} />}
        sidebar={getSidebar()}
        showToggleButton={compassState === CompassState.MYCOMPASS}
        suffixComponent={
          <CommunicationWrapper
            context={talent}
            contextType="talent"
            disabled={
              !talent.visibility ||
              compassState === CompassState.SHOWCASE ||
              compassState === CompassState.MYSHOWCASE
            }
          />
        }
      />
      <SharingDialog
        dialogState={sharingDialog}
        onClose={setSharingDialogState}
        currentPost={currentPost}
        postId={talentId}
        postType="talent"
      />
    </>
  );
};

export default UpdateTalent;
