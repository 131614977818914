import React from 'react';
import TalentForm from './TalentForm';
import { ApiConfig } from '../api';
import useFetch from '../../../helpers/useFetch';

type Props = {
  onCancel?: () => void;
  onSubmit: () => void;
  visibility: boolean;
};

const NewTalentForm = ({ onSubmit, onCancel, visibility }: Props) => {
  const [addTalentResponse, addTalent] = useFetch(ApiConfig.addTalent());

  return (
    <TalentForm
      onSave={addTalent}
      onClose={onCancel}
      onSubmit={onSubmit}
      talent={addTalentResponse}
      visibility={visibility}
    />
  );
};

export default NewTalentForm;
