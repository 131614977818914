import React from 'react';
import Title from '../../../components/Title';
import Paragraph from '../../../components/Paragraph';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import ResetPasswordForm from '../containers/ResetPasswordForm';
import { UserId } from '../../../config/api/models';
import ContentBackground from '../../../components/ContentBackground';
import { makeStyles } from '@material-ui/styles';
import Logo from '../../../components/Logo';
import CenteredPaper from '../../../components/CenteredPaper';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';

type Props = RouteComponentProps<{ userId: UserId; token: string }>;

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  resetPasswordRoot: (props: StyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: props.isMobile ? '5rem' : '',
  }),
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    overflow: 'hidden',
  },
}));

const ResetPasswordPage = ({ match: { params } }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.resetPasswordRoot}>
      <div className={classes.contentBackground}>
        <ContentBackground />
      </div>
      <Logo large />
      <CenteredPaper>
        <Title>{t('resetPassword.title')}</Title>
        <Paragraph>{t('resetPassword.intro')}</Paragraph>
        <ResetPasswordForm userId={params.userId} token={params.token} />
      </CenteredPaper>
    </div>
  );
};

export default ResetPasswordPage;
