import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import ButtonGroup from '../../../components/ButtonGroup';
import { IconButton } from '@material-ui/core';

type Props = {
  context?: React.ReactElement;
  inspiration?: React.ReactElement;
};

enum TabOptions {
  CONTEXT = 'context',
  INSPIRATION = 'inspiration',
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDialogRoot: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },
  buttonGroup: {
    borderBottom: theme.config.defaultBorder,
    backgroundColor: theme.palette.grey['100'],
    textAlign: 'center',
    margin: '-3rem -3rem 0',
    padding: '3rem 3rem 1rem',
  },
  iconButton: {
    border: theme.config.defaultBorder,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonFocus: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderColor: theme.palette.primary.main,
  },
  content: {
    padding: '2rem',
    margin: '0 -3rem',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}));

const MobileDialogFormTabs = ({ context, inspiration }: Props) => {
  const classes = useStyles();
  const [tab, selectTab] = useState<TabOptions>(TabOptions.INSPIRATION);

  useEffect(() => {
    if (
      (tab === TabOptions.CONTEXT && !context) ||
      (tab === TabOptions.INSPIRATION && !inspiration)
    ) {
      selectTab(
        inspiration ? TabOptions.INSPIRATION : context ? TabOptions.CONTEXT : TabOptions.INSPIRATION
      );
    }
  }, [inspiration, context, tab]);

  return (
    <div className={classes.mobileDialogRoot}>
      <ButtonGroup className={classes.buttonGroup}>
        {context && (
          <IconButton
            className={clsx(
              classes.iconButton,
              tab === TabOptions.CONTEXT ? classes.buttonFocus : null
            )}
            onClick={() => selectTab(TabOptions.CONTEXT)}
          >
            <SvgIcon icon={Icons.DETAIL} size={2} />
          </IconButton>
        )}
        {inspiration && (
          <IconButton
            className={clsx(
              classes.iconButton,
              tab === TabOptions.INSPIRATION ? classes.buttonFocus : null
            )}
            onClick={() => selectTab(TabOptions.INSPIRATION)}
          >
            <SvgIcon icon={Icons.LIGHT_BULB} size={2} />
          </IconButton>
        )}
      </ButtonGroup>

      <div className={classes.content}>
        {tab === TabOptions.CONTEXT && context}
        {tab === TabOptions.INSPIRATION && inspiration}
      </div>
    </div>
  );
};

export default MobileDialogFormTabs;
