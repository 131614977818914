import { makeStyles, Theme } from '@material-ui/core/styles';

export const dateStyles = makeStyles((theme: Theme) => ({
  dateRangePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .DateRangePicker': {
      display: 'inline-block',
      marginBottom: 10,
      padding: '0',
      position: 'relative',
      userSelect: 'none',
    },
    '& .DateRangePicker__Legend': {
      color: '#52575e',
      fontSize: 14,
      lineHeight: '16px',
      listStyleType: 'none',
      margin: '20px 0',
      padding: '0',
    },
    '& .DateRangePicker__LegendItem': {
      display: 'inline-block',
      margin: '0 20px',
    },
    '& .DateRangePicker__LegendItemColor': {
      borderRadius: '50%',
      display: 'inline-block',
      height: 14,
      marginRight: 6,
      verticalAlign: 'text-bottom',
      width: 14,
      border: '1px solid rgba(0, 0, 0, 0.25)',
    },
    '& .DateRangePicker__LegendItemColor--selection': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .DateRangePicker__PaginationArrow': {
      border: '0',
      cursor: 'pointer',
      display: 'block',
      height: 35,
      outline: 'none',
      overflow: 'hidden',
      padding: '0',
      position: 'absolute',
      textAlign: 'center',
      top: '2rem',
      whiteSpace: 'nowrap',
      width: 35,
      zIndex: 1,
    },
    '& .DateRangePicker__PaginationArrow--previous': {
      left: 20,
    },
    '& .DateRangePicker__PaginationArrow--next': {
      right: 20,
    },
    '& .DateRangePicker__PaginationArrow:hover': {
      backgroundColor: '#ccc',
    },
    '& .DateRangePicker__PaginationArrowIcon': {
      borderBottom: '8px solid transparent',
      borderTop: '8px solid transparent',
      height: '0',
      position: 'absolute',
      top: 10,
      width: '0',
    },
    '& .DateRangePicker__PaginationArrowIcon--is-disabled': {
      opacity: 0.25,
    },
    '& .DateRangePicker__PaginationArrowIcon--previous': {
      borderLeft: '8px solid transparent',
      borderRight: '8px solid #aaa',
      right: 11,
    },
    '& .DateRangePicker__PaginationArrowIcon--next': {
      borderLeft: '8px solid #aaa',
      borderRight: '8px solid transparent',
      left: 11,
    },
    '& .DateRangePicker__Month': {
      color: theme.palette.text.primary,
      display: 'inline-block',
      margin: '0 0.5rem',
      padding: '2rem',
      position: 'relative',
      userSelect: 'none',
      width: 307,
      background: '#FFF',
    },
    '& .DateRangePicker__MonthHeader': {
      color: '#000',
      fontSize: 14,
      fontWeight: 'bold',
      height: 35,
      lineHeight: '35px',
      position: 'relative',
      textAlign: 'center',
    },
    '& .DateRangePicker__MonthHeaderLabel': {
      display: 'inline-block',
      position: 'relative',
    },
    '& .DateRangePicker__MonthHeaderSelect': {
      background: '#e4e4e4',
      border: '0',
      cursor: 'pointer',
      display: 'inline-block',
      height: '100%',
      left: '0',
      margin: '0',
      opacity: 0,
      position: 'absolute',
      top: '0',
      width: '100%',
      zIndex: 5,
    },
    '& .DateRangePicker__MonthDates': {
      borderBottom: '1px solid #f4f5f6',
      borderCollapse: 'separate',
      borderSpacing: '0 1px',
      margin: '0',
      width: '100%',
    },
    '& .DateRangePicker__WeekdayHeading, .DateRangePicker__Date': {
      fontSize: 12,
      lineHeight: '1',
      padding: '10px 0',
      textAlign: 'center',
      width: '14.285714285714286%',
    },
    '& .DateRangePicker__WeekdayHeading': {
      borderBottom: '1px solid #f4f5f6',
      color: '#000',
      fontWeight: 'bold',
    },
    '& .DateRangePicker__WeekdayHeading abbr[title]': {
      borderBottomWidth: '0',
      color: '#000',
      cursor: 'pointer',
      fontSize: 'inherit',
      textDecoration: 'none',
    },
    '& .DateRangePicker__Date': {
      border: '0 solid #f4f5f6',
      borderRightWidth: 1,
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
    },
    '& .DateRangePicker__Date:first-child': {
      borderLeftWidth: 1,
    },
    '& .DateRangePicker__Date--weekend': {
      backgroundColor: theme.palette.background.default,
    },
    '& .DateRangePicker__Date--otherMonth': {
      opacity: 0.25,
    },
    '& .DateRangePicker__Date--is-disabled': {
      color: '#cdcdd1',
      cursor: 'default',
    },
    '& .DateRangePicker__Date--is-selected': {
      color: '#fff',
    },
    '& .DateRangePicker__Date--is-highlighted': {
      color: '#333',
    },
    '& .DateRangePicker__CalendarDatePeriod': {
      bottom: '0',
      position: 'absolute',
      top: '0',
    },
    '& .DateRangePicker__CalendarDatePeriod--am': {
      left: '0',
      right: '50%',
    },
    '& .DateRangePicker__CalendarDatePeriod--pm': {
      left: '50%',
      right: '0',
    },
    '& .DateRangePicker__CalendarSelection': {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.dark}`,
      bottom: 5,
      left: '0',
      position: 'absolute',
      right: '0',
      top: 5,
    },
    '& .DateRangePicker__CalendarSelection--inOtherMonth': {
      opacity: 0.5,
    },
    '& .DateRangePicker__CalendarSelection--start': {
      borderBottomLeftRadius: 5,
      borderRightWidth: '0',
      borderTopLeftRadius: 5,
      left: 5,
    },
    '& .DateRangePicker__CalendarSelection--end': {
      borderBottomRightRadius: 5,
      borderLeftWidth: '0',
      borderTopRightRadius: 5,
      right: 5,
    },
    '& .DateRangePicker__CalendarSelection--segment': {
      borderLeftWidth: '0',
      borderRightWidth: '0',
    },
    '& .DateRangePicker__CalendarSelection--single': {
      borderRadius: 5,
      left: 5,
      right: 5,
    },
    '& .DateRangePicker__CalendarSelection--is-pending': {
      backgroundColor: theme.palette.secondary.medium,
      borderWidth: '0',
    },
    '& .DateRangePicker__CalendarHighlight': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
      border: '1px solid rgba(0, 0, 0, 0.25)',
      bottom: 5,
      left: '0',
      position: 'absolute',
      right: '0',
      top: 5,
    },
    '& .DateRangePicker__CalendarHighlight--inOtherMonth': {
      opacity: 0.5,
    },
    '& .DateRangePicker__CalendarHighlight--start': {
      borderBottomLeftRadius: 5,
      borderRightWidth: '0',
      borderTopLeftRadius: 5,
      left: 5,
    },
    '& .DateRangePicker__CalendarHighlight--end': {
      borderBottomRightRadius: 5,
      borderLeftWidth: '0',
      borderTopRightRadius: 5,
      right: 5,
    },
    '& .DateRangePicker__CalendarHighlight--segment': {
      borderLeftWidth: '0',
      borderRightWidth: '0',
    },
    '& .DateRangePicker__CalendarHighlight--single': {
      backgroundColor: '#fff',
      border: '1px solid #eb401d',
      borderRadius: 5,
      left: 5,
      right: 5,
    },
    '& .DateRangePicker__HalfDateStates': {
      bottom: -50,
      left: -50,
      position: 'absolute',
      right: -50,
      top: -50,
      transform: 'rotate(30deg)',
    },
    '& .DateRangePicker__FullDateStates': {
      bottom: '0',
      left: '0',
      position: 'absolute',
      right: '0',
      top: '0',
    },
    '& .DateRangePicker__DateLabel': {
      display: 'block',
      position: 'relative',
      textAlign: 'center',
      width: '100%',
      zIndex: 1,
    },
  },
}));
