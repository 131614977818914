import React, { useContext } from 'react';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { Tab, Tabs, Theme } from '@material-ui/core';
import navigationData, { NavigationItem } from '../config/navigationData';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import NavTabTriangle from './NavTabTriangle';
import security from '../../security';
import ClientRoutesContext from '../../../config/clientRoutes/ClientRoutesContext';
import { RouteName } from '../../../config/clientRoutes/routeNames';
import { useDispatch, useSelector } from 'react-redux';
import { getCompassUserId } from '../../myCompass/selectors';
import { Actions } from '../../shared/actions';
import { actions } from 'react-redux-fetch';

const useStyles = makeStyles((theme: Theme) => ({
  tabsRoot: {
    height: '100%',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    overflow: 'visible',
    zIndex: 1,
  },
  tabsIndicator: {
    display: 'none',
  },
  tabsFlexContainer: {
    height: '100%',
    '& .MuiTabs-scroller': {
      overflow: 'visible !important',
    },
  },
  tab: {
    position: 'relative',
    height: '100%',
    paddingBottom: '1rem',
    overflow: 'visible',
    opacity: 1,
    color: theme.palette.grey['700'],
    '&.Mui-selected': {
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
      },
      '& svg, &:hover svg': {
        transform: 'translate3d(.5rem, -3.5rem, 0) rotate(100deg)',
      },
    },
    '&:hover': {
      color: theme.palette.grey['900'],
      '& svg': {
        transform: 'translate3d(0, 0, 0) rotate(60deg)',
      },
    },
  },
  tabLabel: {
    position: 'relative',
    zIndex: 1,
  },
  tabLabelIcon: {
    minHeight: 'auto',
  },
  myCompassTriangle: {
    position: 'absolute',
    transform: 'translate3d(0, 0, 0) rotate(0)',
    transition: 'all .25s ease-out',
    zIndex: 0,
  },
}));

const NavTabs = ({ location: { pathname } }: RouteComponentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getPath, getRouteName } = useI18nPath();
  const { routes } = useContext(ClientRoutesContext);
  const activeTab = getRouteName(pathname);
  const currentUserId = useSelector(security.selectors.getUserId);
  const compassUserId = useSelector(getCompassUserId);
  const currentUser = security.hooks.useUser();
  const extern = !!currentUser.isExtern;
  const isCompassUser = !!compassUserId && compassUserId !== currentUserId;
  const dispatch = useDispatch();

  const handleTabClick = () => {
    dispatch(actions.clear('compassUser'));
    dispatch(Actions.disableHelp());
  };

  return (
    <Tabs
      indicatorColor="secondary"
      value={
        activeTab === 'dreamDetail' && !isCompassUser
          ? 'myCompass'
          : activeTab === 'showcaseDreamDetail' && !isCompassUser
          ? 'myShowcase'
          : activeTab
      }
      classes={{
        root: classes.tabsRoot,
        indicator: classes.tabsIndicator,
        flexContainer: classes.tabsFlexContainer,
      }}
    >
      {/* Hidden tab to prevent MUI error "invalid value provided to Tabs component",
              when current path doesn't match one of our tabs */}
      <Tab value={activeTab} style={{ display: 'none' }} />

      {navigationData(routes, extern).map((item: NavigationItem) => {
        const navItemIsSelected: boolean =
          activeTab === 'dreamDetail' || activeTab === 'showcaseDreamDetail'
            ? isCompassUser
              ? false
              : item.selectedOn.includes(activeTab as RouteName)
            : item.selectedOn.includes(activeTab as RouteName);

        return (
          <Tab
            key={item.routeName}
            label={<div className={classes.tabLabel}>{t(item.label)}</div>}
            component={Link}
            to={getPath(item.routeName)}
            value={item.routeName}
            disableRipple
            classes={{
              root: classes.tab,
              labelIcon: classes.tabLabelIcon,
            }}
            icon={
              <NavTabTriangle
                className={classes.myCompassTriangle}
                color={item.color}
                size={item.triangleSize}
                top={item.triangleTop}
                selected={navItemIsSelected}
              />
            }
            onClick={handleTabClick}
          />
        );
      })}
    </Tabs>
  );
};

export default withRouter(NavTabs);
