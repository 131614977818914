import * as React from 'react';
import MaskedInput from 'react-text-mask';
import moment, { Moment } from 'moment';
import createAutoCorrectedDatePipe from '../../helpers/createAutoCorrectedDatePipe';

type TextMaskCustomProps = {
  value?: string | Moment;
  onChange: (newValue: string | Moment) => void;
  onBlur: (event: React.ChangeEvent) => void;
  inputRef: (ref: HTMLInputElement | null) => void;
};

const MaskedDateInput = (props: TextMaskCustomProps) => {
  const { inputRef, value, onChange, onBlur, ...other } = props;

  return (
    <MaskedInput
      {...other}
      value={!value || typeof value === 'string' ? value : value.format('DD/MM/YYYY')}
      onBlur={e => {
        const newValue = e.currentTarget.value;
        onChange(moment(newValue, 'DD/MM/YYYY'));
        return onBlur(e);
      }}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'-'}
      showMask
      guide
      pipe={createAutoCorrectedDatePipe('dd mm yyyy')}
      style={{ width: '9rem' }}
      keepCharPositions={true}
    />
  );
};

export default MaskedDateInput;
