import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Title from './Title';
import Html from './Html';

type Props = {
  title: string;
  subtitle?: string;
  bodyText?: string;
};

const useStyles = makeStyles(() => ({
  homePageSectionRoot: {},
  subtitle: {
    fontWeight: 700,
    margin: '5rem 0 2rem 0',
    fontSize: '1.5rem',
  },
}));

const HomePageSection = ({ title, subtitle, bodyText }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.homePageSectionRoot}>
      <Title home>
        <Html home>{title}</Html>
      </Title>
      {subtitle && (
        <Typography variant="subtitle2" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
      {bodyText && <Html>{bodyText}</Html>}
    </div>
  );
};

export default HomePageSection;
