import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RelationContact, RelationContext, RelationType, User } from '../../../config/api/models';
import TabPanel from '../../../components/TabPanel';
import { useTranslation } from 'react-i18next';
import { RelationOverviewProps } from '../models';
import ContactList from './ContactList';
import { useSelector } from 'react-redux';
import { getMilaCoachTourSwitchToCoachees } from '../../shared/selectors';
import { SearchInput } from '../../shared/components/SearchInput';

type Props = {
  coaches: RelationContact[];
  coachees: RelationContact[];
  selectedRelation?: RelationOverviewProps;
  onLoadRelation: (user: User, role: RelationType, relation: RelationContext) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  coachCoacheeListTabs: {
    borderBottom: theme.config.defaultBorder,
  },
  userCard: {
    borderBottom: theme.config.defaultBorder,
  },
  emptyMessage: {
    padding: '2rem',
    fontSize: '1.2rem',
  },
  panelScroll: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40vh',
      overflow: 'auto',
    },
  },
}));

const CoachCoacheeList = ({ coaches, coachees, selectedRelation, onLoadRelation }: Props) => {
  const classes = useStyles();
  const showCoaches = coaches.length > 0 || coachees.length === 0;
  const [selectedTab, setSelectedTab] = useState<RelationType>(showCoaches ? 'coach' : 'coachee');
  const { t } = useTranslation();
  const milaCoachTourSwitchToCoachees = useSelector(getMilaCoachTourSwitchToCoachees);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<{}> | null, newValue: RelationType) => {
    setSelectedTab(newValue);
  };

  if (selectedTab === 'coach' && !coaches.length && coachees.length) {
    handleChange(null, 'coachee');
  } else if (selectedTab === 'coachee' && !coachees.length && coaches.length) {
    handleChange(null, 'coach');
  }

  useEffect(() => {
    if (milaCoachTourSwitchToCoachees) {
      setSelectedTab('coachee');
      handleChange(null, 'coachee');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milaCoachTourSwitchToCoachees]);

  return (
    <div>
      <Tabs
        variant="fullWidth"
        onChange={handleChange}
        value={selectedTab}
        className={classes.coachCoacheeListTabs}
      >
        {showCoaches && <Tab label={t('myCoach.coaches')} value="coach" />}
        {coachees.length > 0 ? <Tab label={t('myCoach.coachees')} value="coachee" /> : null}
      </Tabs>
      <SearchInput onSearch={setSearchValue} />
      {showCoaches && (
        <TabPanel value={selectedTab} index="coach">
          <div className={classes.panelScroll}>
            {coaches.length > 0 ? (
              <ContactList
                contacts={coaches}
                selectedRelation={selectedRelation}
                onLoadRelation={(user, relation) => onLoadRelation(user, 'coach', relation)}
                searchValue={searchValue}
              />
            ) : (
              <div className={classes.emptyMessage}>{t('myCoach.noCoaches')}</div>
            )}
          </div>
        </TabPanel>
      )}
      {coachees.length > 0 ? (
        <TabPanel value={selectedTab} index="coachee">
          <div className={classes.panelScroll}>
            <ContactList
              contacts={coachees}
              selectedRelation={selectedRelation}
              onLoadRelation={(user, relation) => onLoadRelation(user, 'coachee', relation)}
              hasGroupsFilter
              searchValue={searchValue}
            />
          </div>
        </TabPanel>
      ) : null}
    </div>
  );
};

export default CoachCoacheeList;
