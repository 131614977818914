import { RelationContext, Focus, Dream, ContextType } from '../../../config/api/models';

export function getAcceptedContactsForContext(
  relations: RelationContext[],
  context: Dream | Focus,
  contextType: ContextType
) {
  return relations.filter(rel => {
    if (!rel.acceptedAt) {
      return false;
    }
    if (rel.contextType === contextType) {
      return rel.context.entityId === context.entityId;
    } else if (contextType === 'focus') {
      const relationFocus = rel.context.entityId === (context as Focus).dream.entityId;
      return Boolean(relationFocus);
    }
    return false;
  });
}
