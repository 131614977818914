import React from 'react';
import shared from '../../shared';
import WithStickyUserMenu from './WithStickyUserMenu';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Navigation from './Navigation';
import { useSelector } from 'react-redux';
import { getSelectedFocusId } from '../../myCompass/selectors';

type Props = {
  profilePictureEditable?: boolean;
  content?: React.ReactNode;
  children: React.ReactChild | React.ReactChild[];
  navigation?: {
    navItems: React.ReactElement[];
    activeMenuItemIndex?: number;
  };
  color?: string;
  showcaseDreamsVisible?: boolean;
  onShowcaseDreamsClick?: () => void;
  id?: string;
};

const PageContentWrapperWithStickyUserMenu = ({
  profilePictureEditable,
  content,
  children,
  navigation,
  color,
  showcaseDreamsVisible,
  onShowcaseDreamsClick,
  id,
}: Props) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedFocusId = useSelector(getSelectedFocusId);

  return (
    <shared.LoggedInPageContentWrapper id={id} color={color}>
      <WithStickyUserMenu
        profilePictureEditable={profilePictureEditable || false}
        content={content}
        navigation={navigation}
        color={color ? color : theme.palette.myCompass.green}
        show
        showcaseDreamsVisible={showcaseDreamsVisible}
        onShowcaseDreamsClick={onShowcaseDreamsClick}
      >
        {isMobile && !!selectedFocusId && navigation && (
          <Navigation
            activeMenuItemIndex={navigation.activeMenuItemIndex}
            navItems={navigation.navItems}
            color={color ? color : theme.palette.myCompass.green}
            showcaseDreamsVisible={showcaseDreamsVisible}
            onShowcaseDreamsClick={onShowcaseDreamsClick}
          />
        )}
        <>{children} </>
      </WithStickyUserMenu>
    </shared.LoggedInPageContentWrapper>
  );
};

export default PageContentWrapperWithStickyUserMenu;
