import React, { useEffect, useState } from 'react';
import MyCoach from '../containers/MyCoach';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCompassUser } from '../../shared/hooks';
import { getCompassUserId, getCompassUserInfo } from '../../myCompass/selectors';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as DreamApiConfig } from '../../myCompass/api';
import useUser from '../../security/hooks/useUser';
import { getMilaUserId } from '../../shared/selectors';
import { Tab, Theme } from '@material-ui/core';
import PageTabs, { usePageTabsStyles } from '../../../components/PageTabs';
import shared from '../../shared';
import { useTheme } from '@material-ui/core/styles';
import MyGroups from '../containers/myGroups/MyGroups';

const MyCoachPage = () => {
  const theme: Theme = useTheme();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const pageTabClasses = usePageTabsStyles();
  const { userId } = useParams();
  const currentUser = useUser();

  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const compassUser = useSelector(getCompassUserInfo);
  const milaUserId = useSelector(getMilaUserId);

  const userToUse =
    isCompassUser && compassUser && compassUserId === milaUserId ? compassUser : currentUser;

  const [, getCompassUser] = useFetch<UserResponse>(DreamApiConfig.loadCompassUser(userId));

  useEffect(() => {
    if (isCompassUser && !compassUserId) {
      getCompassUser();
    }
  }, [isCompassUser, getCompassUser, compassUserId]);

  const content = selectedTab === 0 ? <MyCoach userToUse={userToUse} /> : <MyGroups />;

  return (
    <div className={pageTabClasses.root}>
      <PageTabs selectedTab={selectedTab} onSelectTab={setSelectedTab}>
        <Tab label="myCoach" id="my-coach-tab" />
        <Tab label="myGroups" id="my-groups-tab" />
      </PageTabs>
      <shared.LoggedInPageContentWrapper
        id="myCoach"
        color={theme.palette.myCompass.blue}
        className={pageTabClasses.wrapper}
      >
        {content}
      </shared.LoggedInPageContentWrapper>
    </div>
  );
};

export default MyCoachPage;
