import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Groeipad from '../../../assets/groeipad.png';
import Html from '../../../components/Html';
import HomePageSection from '../../../components/HomePageSection';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';

type StyleProps = {
  isMobile: boolean;
  isSmall: boolean;
};

const useStyles = makeStyles(() => ({
  sectionFourRoot: (props: StyleProps) => ({
    marginTop: props.isSmall ? '-10rem' : '',
  }),
  flex: {
    display: 'flex',
    marginTop: '5rem',
    flexWrap: 'wrap',
  },
  left: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '35%',
  }),
  right: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: props.isMobile ? '' : 'space-evenly',
  }),
  text: (props: StyleProps) => ({
    marginTop: props.isMobile ? '1rem' : '5rem',
  }),
}));

const SectionFour = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile, isSmall });
  const { t } = useTranslation();

  return (
    <div className={classes.sectionFourRoot}>
      <HomePageSection
        title={t('home.sectionFour.title')}
        subtitle={t('home.sectionFour.subtitle')}
      />
      <div className={classes.flex}>
        <div className={classes.left}>
          <img src={Groeipad} alt={t('home.sectionFour.alt')} />
        </div>
        <div className={classes.right}>
          <div className={classes.text}>
            <Html>{t('home.sectionFour.dream')}</Html>
          </div>
          <div className={classes.text}>
            <Html>{t('home.sectionFour.focus')}</Html>
          </div>
          <div className={classes.text}>
            <Html>{t('home.sectionFour.plan')}</Html>
          </div>
          <div className={classes.text}>
            <Html>{t('home.sectionFour.learn')}</Html>
          </div>
          <div className={classes.text}>
            <Html>{t('home.sectionFour.lookback')}</Html>
          </div>
          <div className={classes.text}>
            <Html>{t('home.sectionFour.slot')}</Html>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
