import { middleware as fetchMiddleware } from 'react-redux-fetch';
import { applyMiddleware, compose, createStore, Middleware, Reducer, Store } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Persistor } from 'redux-persist/es/types';
import { RootState } from './types';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const persistConfig = {
  key: 'odisee_mtc',
  storage,
  whitelist: ['auth'],
};

type ConfigureStore = (
  rootReducer: Reducer<RootState>,
  middleware: Middleware[],
  initialState?: RootState
) => [Store, Persistor];

const configureStoreDev: ConfigureStore = (rootReducer, middleware, initialState) => {
  const finalMiddleware = [fetchMiddleware, ...middleware];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...finalMiddleware))
  );

  const persistor = persistStore(store);

  return [store, persistor];
};

const configureStoreProd: ConfigureStore = (rootReducer, middleware, initialState) => {
  const finalMiddleware = [fetchMiddleware, ...middleware];
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...finalMiddleware))
  );

  const persistor = persistStore(store);

  return [store, persistor];
};

const configureStore: ConfigureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
