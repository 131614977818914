import React from 'react';
import { PostOption } from '../../../config/api/models';
import { Paper, makeStyles, Theme } from '@material-ui/core';
import PostHeader from './PostHeader';
import ContentBlock from '../../myCompass/components/ContentBlock';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import NetworkBreadCrumb from './NetworkBreadCrumb';
import CommunicationWrapper from '../../myCompass/components/CommunicationWrapper';
import Milestone from '../../myCompass/containers/Milestone/Milestone';

type Props = {
  post: PostOption;
  openCommentSection?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  networkPostRoot: {
    width: '100%',
    margin: '2rem 0',
    [theme.breakpoints.down('xs')]: {
      marginRight: '-2rem',
      marginLeft: '-2rem',
      width: 'auto',
      borderRadius: 0,
    },
  },
  milestonePostContainer: {
    margin: '2rem 0',
  },
  content: {
    padding: '2rem 4rem',
    position: 'relative',
  },
  quoteIcon: {
    position: 'absolute',
    top: '10.4rem',
    left: '1.5rem',
    color: theme.palette.highlight.main,
  },
}));

const NetworkPost = ({ post, openCommentSection }: Props) => {
  const classes = useStyles();

  if (!post || !post.data || !post.author) {
    return null;
  }

  if (post.postType === 'milestone') {
    return (
      <div className={classes.milestonePostContainer}>
        <Milestone
          milestone={post.data}
          user={post.author}
          withContext={Boolean(post.includeContext)}
          commentable
        />
      </div>
    );
  }

  return (
    <Paper className={classes.networkPostRoot}>
      <PostHeader
        user={post.author}
        includeContext={Boolean(post.includeContext)}
        publishedAt={post.createdAt}
        dream={post.dream}
        focus={post.focus}
      />
      <div className={classes.content}>
        <div className={classes.quoteIcon}>
          <SvgIcon icon={Icons.QUOTE} />
        </div>
        <NetworkBreadCrumb post={post} />
        <ContentBlock
          content={{
            title: post.data.title,
            body: post.data.body,
          }}
        />
      </div>
      <CommunicationWrapper
        openCommentSection={openCommentSection}
        context={post.data}
        contextType={post.postType}
      />
    </Paper>
  );
};

export default NetworkPost;
