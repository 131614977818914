import { styled } from '@material-ui/styles';

const IconButton = styled('button')(({ theme }) => ({
  color: theme.palette.grey['400'],
  cursor: 'pointer',
  transitionProperty: 'color',
  transitionDuration: theme.transitions.duration.standard + 'ms',
  transitionTimingFunction: theme.transitions.easing.sharp,
  display: 'inline-block',
  background: 'none',
  border: 'none',
  outline: 'none',
  padding: 0,
  '&:hover': {
    color: theme.palette.grey['600'],
  },
}));

export default IconButton;
