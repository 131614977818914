import React, { useEffect, useState } from 'react';
import shared from '../../shared';
import { Tab, Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import MyNetwork from '../containers/MyNetwork';
import MyPeers from '../containers/MyPeers';
import useUser from '../../security/hooks/useUser';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { useCompassUser } from '../../shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../../myCompass/selectors';
import { ApiConfig as DreamApiConfig } from '../../myCompass/api';
import { useParams } from 'react-router-dom';
import { getHelp } from '../../shared/selectors';
import { MyNetworkTour } from '../../shared/tours';
import { MilaNetworkTour } from '../../shared/tours';
import PageTabs, { usePageTabsStyles } from '../../../components/PageTabs';

const MyNetworkPage = () => {
  const theme: Theme = useTheme();
  const pageTabClasses = usePageTabsStyles();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { userId } = useParams();
  const currentUser = useUser();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const isHelpActive = useSelector(getHelp);

  const [, getCompassUser] = useFetch<UserResponse>(DreamApiConfig.loadCompassUser(userId));

  useEffect(() => {
    if (isCompassUser && !compassUserId) {
      getCompassUser();
    }
  }, [isCompassUser, getCompassUser, compassUserId]);

  const content = () => {
    switch (selectedTab) {
      case 0:
        return <MyNetwork />;
      case 1:
        return <MyPeers />;
    }
  };

  return (
    <div className={pageTabClasses.root}>
      <PageTabs selectedTab={selectedTab} onSelectTab={setSelectedTab}>
        <Tab label="myNetwork" id="my-network-tab" />
        <Tab label="myPeers" id="my-peers-tab" />
      </PageTabs>
      <shared.LoggedInPageContentWrapper
        color={theme.palette.myCompass.yellow}
        className={pageTabClasses.wrapper}
      >
        <div id="my-network">{content()}</div>
      </shared.LoggedInPageContentWrapper>
      {!currentUser.networkTourDone && <MyNetworkTour />}
      {isHelpActive && <MilaNetworkTour />}
    </div>
  );
};

export default MyNetworkPage;
