import React, { FC } from 'react';
import NavBar from './NavBar';

const LoggedInWrapper: FC = ({ children }) => {
  const currentPath = window.location.pathname;
  return (
    <>
      {currentPath !== '/nl/coachingsluik' && <NavBar />}
      {children}
    </>
  );
};

export default LoggedInWrapper;
