import React, { FC, ReactElement, useEffect, useState } from 'react';
import { I18nRoutes } from './types';
import ClientRoutesContext from './ClientRoutesContext';
import useFetch from '../../helpers/useFetch';
import { ApiConfig } from '../../modules/shared/api';

type Props = {
  children: ReactElement;
};

const PUBLIC_ROUTES_MAX_RETRIES = 2;

const ClientRoutesProvider: FC<Props> = ({ children }) => {
  const [publicRoutes, setPublicRoutes] = useState<null | I18nRoutes>(null);
  const [privateRoutes, setPrivateRoutes] = useState<null | I18nRoutes>(null);
  const [publicRoutesRequest, fetchPublicRoutes] = useFetch(ApiConfig.getPublicRoutes());
  const [privateRoutesRequest] = useFetch(ApiConfig.getPrivateRoutes());
  const [publicRoutesRetryCount, setPublicRoutesRetryCount] = useState(0);

  // eslint-disable-next-line
  useEffect(fetchPublicRoutes, []);

  useEffect(() => {
    if (publicRoutesRequest && publicRoutesRequest.value) {
      setPublicRoutes(publicRoutesRequest.value);
    }

    // Because of a bug with exclusive-locks causing a 500-error, we retry the public-routes-fetch (temporary fix)
    if (publicRoutesRequest && publicRoutesRequest.rejected) {
      if (publicRoutesRetryCount < PUBLIC_ROUTES_MAX_RETRIES) {
        fetchPublicRoutes();
        setPublicRoutesRetryCount(publicRoutesRetryCount + 1);
      }
    }
  }, [publicRoutesRequest, fetchPublicRoutes, publicRoutesRetryCount, setPublicRoutesRetryCount]);

  useEffect(() => {
    if (privateRoutesRequest && privateRoutesRequest.value) {
      setPrivateRoutes(privateRoutesRequest.value);
    }
  }, [privateRoutesRequest]);

  const routes =
    privateRoutes && publicRoutes
      ? { ...privateRoutes, ...publicRoutes }
      : publicRoutes
      ? publicRoutes
      : null;

  return (
    <ClientRoutesContext.Provider value={{ privateRoutes, publicRoutes, routes }}>
      {children}
    </ClientRoutesContext.Provider>
  );
};

export default ClientRoutesProvider;
