import React from 'react';
import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormControlProps } from '@material-ui/core/FormControl';
import ReactHtmlParser from 'react-html-parser';

type Props = FormControlProps & {
  label?: string;
  helperText?: React.ReactNode;
  id: string;
  children: React.ReactElement;
  required?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  formControlRoot: {
    marginBottom: '2rem',
  },
  inputLabelRoot: {
    position: 'relative',
    transform: 'none',
    marginBottom: '1rem',
    fontSize: '1.4rem',
    fontWeight: 600,
    color: theme.palette.text.primary,
    '& span': {
      fontWeight: 300,
    },
  },
  helperTextRoot: {
    marginLeft: '.25rem',
    fontSize: '1.1rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
  required: {
    marginLeft: '.5rem',
    color: theme.palette.secondary.main,
  },
}));

const FormControl = ({
  id,
  label,
  helperText,
  children,
  required = false,
  fullWidth = true,
}: Props) => {
  const classes = useStyles();

  return (
    <MuiFormControl className={classes.formControlRoot} fullWidth={fullWidth}>
      {label && (
        <MuiInputLabel htmlFor={id} className={classes.inputLabelRoot}>
          {ReactHtmlParser(label)}
          {required && <span className={classes.required}>*</span>}
        </MuiInputLabel>
      )}
      {React.cloneElement(children, { id })}
      {helperText && (
        <MuiFormHelperText className={classes.helperTextRoot}>{helperText}</MuiFormHelperText>
      )}
    </MuiFormControl>
  );
};

export default FormControl;
