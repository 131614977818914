import {
  User,
  RelationsResponse,
  RelationContext,
  DreamRelation,
  FocusRelation,
  Focus,
  RelationContact,
  ContextType,
  RelationType,
  FocusId,
} from '../../../config/api/models';
import { findLastIndex } from 'lodash';

export function groupRelationsByRelationType(
  data: RelationContext[],
  currentUser: User
): { coaches: RelationContext[]; coachees: RelationContext[]; invitations: RelationContext[] } {
  const relations = data;
  const coaches: RelationContext[] = [];
  const coachees: RelationContext[] = [];
  const invitations: RelationContext[] = [];
  relations
    .filter(relation => !relation.declinedAt)
    .forEach(relation => {
      if (!relation.acceptedAt && relation.initiatorId !== currentUser.entityId) {
        invitations.push(relation);
      } else if (isCoachOrCoachee(relation, currentUser) === 'coach') {
        coaches.push(relation);
      } else {
        coachees.push(relation);
      }
    });

  return { coaches, coachees, invitations };
}

export function isCoachOrCoachee(relation: RelationContext, currentUser: User): RelationType {
  if (relation.relationType === 'coachee') {
    return relation.initiatorId === currentUser.entityId ? 'coach' : 'coachee';
  }
  return relation.initiatorId === currentUser.entityId ? 'coachee' : 'coach';
}

export function getContactList(relations: RelationContext[], currentUser: User): RelationContact[] {
  const result: RelationContact[] = [];
  relations.forEach(relation => {
    const duplicate = result.findIndex(contact =>
      contact.relation.initiatorId === currentUser.entityId
        ? relation.receiverId === contact.relation.receiverId
        : relation.initiatorId === contact.relation.initiatorId
    );

    if (duplicate >= 0) {
      if (result[duplicate].notAccepted) {
        result[duplicate].notAccepted = !relation.acceptedAt;
      }
      if (result[duplicate].archived) {
        result[duplicate].archived = !!relation.stoppedAt;
      }
    } else {
      result.push({
        relation,
        archived: !!relation.stoppedAt,
        notAccepted: !relation.acceptedAt,
      });
    }
  });
  return result;
}

export function createRelationTree(relations: RelationContext[]) {
  const result: RelationContext[] = [];
  const dreams = relations.filter(rel => rel.contextType === 'dream') as DreamRelation[];
  const focusses = relations.filter(rel => rel.contextType === 'focus') as FocusRelation[];

  // First add dreams
  dreams.forEach(dream => {
    const dreamRows = getRowsForDream(dream);
    result.push(...dreamRows);
  });

  focusses.forEach(focus => {
    // Check if the focus is already added as child of a dream
    const cloneIndex = result.findIndex(
      relation =>
        relation.contextType === 'focus' && relation.context.entityId === focus.context.entityId
    );
    if (cloneIndex >= 0) {
      result.splice(cloneIndex, 1, focus);
    } else {
      // Check if the dream this focus belongs to has already been added
      const addedDreamIndex = findLastIndex(result, rel => {
        return Boolean(
          rel.contextType === 'focus' &&
            rel.context &&
            focus.context &&
            rel.context.dream.entityId === focus.context.dream.entityId
        );
      });
      if (addedDreamIndex >= 0) {
        result.splice(addedDreamIndex + 1, 0, focus);
      } else {
        const focusRows = getRowsForFocus(focus);
        result.push(...focusRows);
      }
    }
  });

  return result;
}

export function getRowsForDream(relation: DreamRelation): RelationContext[] {
  const rows: RelationContext[] = [relation];
  relation.additionalData.focus.forEach(focus => {
    rows.push({
      receiver: relation.receiver,
      receiverId: relation.receiverId,
      initiator: relation.initiator,
      initiatorId: relation.initiatorId,
      acceptedAt: relation.acceptedAt,
      stoppedAt: relation.stoppedAt,
      contextType: 'focus',
      entityId: relation.entityId,
      relationType: relation.relationType,
      context: ({
        entityId: focus.focusId,
        dream: relation.context,
        title: focus.focusTitle,
        isClosed: focus.isClosed,
        relationType: relation.relationType,
      } as unknown) as Focus,
    } as FocusRelation);
  });
  return rows;
}

export function getRowsForFocus(relation: FocusRelation): RelationContext[] {
  const rows: RelationContext[] = [relation];
  if (relation.context) {
    const dream = {
      receiver: relation.receiver,
      receiverId: relation.receiverId,
      initiator: relation.initiator,
      initiatorId: relation.initiatorId,
      contextType: 'dream',
      context: relation.context.dream,
      entityId: relation.entityId,
      relationType: relation.relationType,
    } as DreamRelation;
    rows.unshift(dream);
  }
  return rows;
}

export function getRelationsWithUser(user: User, data: RelationContext[]) {
  return data.filter(
    relation =>
      relation.initiator.entityId === user.entityId || relation.receiver.entityId === user.entityId
  );
}

export const getKey = (
  contextType: ContextType | 'relation',
  id: string,
  initiatorId?: string,
  receiverId?: string
) => {
  return `${contextType}-${id}${initiatorId && receiverId ? `-${initiatorId}-${receiverId}` : ''}`;
};

export const getUnreadMessagesMap = (relations: RelationContext[]): Record<string, number> => {
  const unreadMessagesMap: Record<string, number> = {};

  relations.forEach(rel => {
    // add relation messagesCount
    const relationKey = getKey('relation', rel.entityId);
    unreadMessagesMap[relationKey] = 0;

    // add context messagesCounts
    if (rel.context) {
      unreadMessagesMap[
        getKey(rel.contextType, rel.context.entityId, rel.initiatorId, rel.receiverId)
      ] = rel.additionalData.unreadMessages;
      unreadMessagesMap[relationKey] += rel.additionalData.unreadMessages;

      if (rel.contextType === 'dream') {
        rel.additionalData.focus.forEach(focus => {
          unreadMessagesMap[getKey('focus', focus.focusId, rel.initiatorId, rel.receiverId)] =
            focus.unreadMessages;
          unreadMessagesMap[relationKey] += focus.unreadMessages;
        });
      }
    }
  });

  return unreadMessagesMap;
};
