import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InspirationContext } from '../../../config/api/models';
import Html from '../../../components/Html';
import inspiration from '../../inspiration';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import IconButton from '../../../components/IconButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  id?: string;
  content: {
    title: string;
    body: string;
    isClosed?: boolean;
    closedAt?: string;
  };
  inspirationKey?: InspirationContext;
  onDelete?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  showDreamRoot: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '0 -2rem',
    },
  },
  showDreamPaper: {
    padding: '4rem',
  },
  editButton: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    color: theme.palette.grey['400'],
    cursor: 'pointer',
    transitionProperty: 'color',
    transitionDuration: theme.transitions.duration.standard + 'ms',
    transitionTimingFunction: theme.transitions.easing.sharp,
    '&:hover': {
      color: theme.palette.grey['600'],
    },
  },
  dreamTitle: {
    marginTop: '0',
    marginBottom: '1rem',
    fontSize: '3rem',
    fontWeight: 800,
    wordBreak: 'break-word',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  closedIcon: {
    '& > svg': {
      width: '4rem',
      height: '4rem',
      color: theme.palette.tertiary.main,
    },
  },
  deleteButton: {
    position: 'absolute',
    top: '3.8rem',
    right: '1rem',
  },
  achievedAtContainer: {
    display: 'inline-block',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  achievedAt: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['400'],
    margin: '0',
  },
}));

const ContentBlock = ({ id, content, inspirationKey, onDelete }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const allContent = {
    data: (
      <div id={id}>
        <h2 className={classes.dreamTitle}>
          {content.title}
          {content.isClosed ? (
            <>
              <span className={classes.closedIcon}>
                <SvgIcon icon={Icons.CHECK_LARGE} />
              </span>
              {content.closedAt && (
                <div className={classes.achievedAtContainer}>
                  <p className={classes.achievedAt}>
                    {t('milestone.achieved_at').replace(
                      '%DATE%',
                      moment(content.closedAt).format('L')
                    )}
                  </p>
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </h2>
        <Html>{content.body}</Html>
        {onDelete && (
          <div className={classes.deleteButton}>
            <IconButton onClick={onDelete}>
              <SvgIcon icon={Icons.TRASH} />
            </IconButton>
          </div>
        )}
      </div>
    ),
  };

  return (
    <>
      {inspirationKey ? (
        <inspiration.InspirationFormDialog inspirationKey={inspirationKey}>
          {allContent.data}
        </inspiration.InspirationFormDialog>
      ) : (
        <div>{allContent.data}</div>
      )}
    </>
  );
};

export default ContentBlock;
