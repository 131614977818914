import * as React from 'react';
import { ClickAwayListener, makeStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import LanguageList from './LanguageList';

type Props = RouteComponentProps & {
  open: boolean;
  onClose: () => void;
  className?: string;
};

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: '0 0 2rem 0 rgba(0,0,0,.25)',
    borderRadius: '.75rem',
    padding: '0',
    width: 'auto',
    zIndex: 9999,
  },
  arrow: ({ isMobile }: StyleProps) => ({
    position: 'absolute',
    top: '-.75rem',
    left: isMobile ? 'auto' : 'auto',
    right: isMobile ? '0.75rem' : '0.75rem',
    transform: 'translateX(-50%)',
    '&::after': {
      display: 'block',
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 1rem .75rem 1rem',
      borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
    },
  }),
  loadMore: {
    margin: '2rem auto',
    display: 'block',
  },
  notificationWrapper: {
    maxHeight: '49rem',
    overflowY: 'auto',
  },
  popup: ({ isMobile }: StyleProps) => ({
    position: 'absolute',
    top: 'calc(100% + .5rem)',
    left: isMobile ? 'auto' : 'auto',
    right: isMobile ? 0 : 'calc(-100% + 0.5rem)',
    transform: isMobile ? 'none' : 'translateX(-50%)',
    maxWidth: 'calc(100vw - 7rem)',
  }),
  languageButton: {
    padding: '1.5rem 3rem',
    cursor: 'pointer',
    textAlign: 'center',
    borderBottom: '1px solid ' + theme.palette.divider,
  },
}));

const LanguagePopup = ({ open, onClose, location, history }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  return open ? (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.popup}>
        <div className={classes.root}>
          <div className={classes.notificationWrapper}>
            <LanguageList
              className={classes.languageButton}
              history={history}
              langStyle="label"
              location={location}
              onChange={onClose}
            />
          </div>
          <div className={classes.arrow} />
        </div>
      </div>
    </ClickAwayListener>
  ) : null;
};

export default withRouter<Props>(LanguagePopup);
