import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { GroupsResponse } from '../../../../config/api/models';
import DeleteGroupButton from '../../containers/myGroups/DeleteGroupButton';
import { useTranslation } from 'react-i18next';
import SvgIcon from '../../../../components/SvgIcon';
import { Icons } from '../../../../components/SvgIcon';

type StyleProps = {
  isSelected: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: StyleProps) => ({
    padding: '1rem',
    borderRight: props.isSelected ? '0.3rem solid' : 'none',
    borderRightColor: props.isSelected ? theme.palette.secondary.main : 'transparent',
  }),
  avatar: (props: StyleProps) => ({
    width: '4.6rem',
    height: '4.6rem',
    background: props.isSelected
      ? (theme.palette.coaching.blue as string)
      : theme.palette.background.default,
    color: props.isSelected ? theme.palette.common.white : theme.palette.text.secondary,
  }),
  avatarIcon: {
    width: '2rem',
    height: '2rem',
  },
  listItemTextPrimary: {
    fontSize: '1.4rem',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  listItemTextSecondary: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
  listItemSecondaryAction: {
    right: 8,
  },
}));

type Props = {
  group: GroupsResponse['data'][number];
  isSelected: boolean;
  onClick: () => void;
};
const MyGroupsGroupListItem = ({ group, isSelected, onClick }: Props) => {
  const classes = useStyles({ isSelected });
  const { t } = useTranslation();

  return (
    <ListItem onClick={onClick} button divider className={classes.root}>
      <ListItemAvatar>
        <Avatar classes={{ root: classes.avatar }}>
          <SvgIcon icon={Icons.GROUP} className={classes.avatarIcon} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={group.attributes.name}
        secondary={t('myCoach.myGroups.numCoachees', {
          count: group.relationships.relations.data.length,
        })}
        classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
      />
      <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
        <DeleteGroupButton groupId={group.id} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default MyGroupsGroupListItem;
