import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import PageContentWrapper from '../../myCompass/containers/PageContentWrapper';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { ShowcaseResponse } from '../../../config/api/models';
import { AppBar, Grid, makeStyles, Theme } from '@material-ui/core';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import Logo from '../../../components/Logo';
import { default as themeConfig } from '../../../config/theme';
import { useDispatch } from 'react-redux';
import { Actions } from '../../myCompass/actions';
import { CompassState } from '../../myCompass/model';
import ShowcaseDream from '../containers/ShowcaseDream';
import { Link } from 'react-router-dom';
import PanelWithSidebar from '../../../components/PanelWithSidebar';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/Spinner';
import FormError from '../../../components/forms/FormError';
import orderBy from 'lodash/fp/orderBy';
import moment from 'moment';
import PublicShowcaseDreamCard from '../../myCompass/components/Dream/PublicShowcaseDreamCard';
import Section from '../../../components/Section';
import LanguageSwitcher from '../../shared/container/LanguageSwitcher';

const useStyles = makeStyles((theme: Theme) => ({
  appBarRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.config.defaultBorder,
    borderRadius: 0,
    boxShadow: 'none',
    padding: 0,
    height: '7rem',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  logo: {
    marginLeft: '2rem',
    display: 'flex',
    maxHeight: '100%',
    overflow: 'hidden',
    alignItems: 'center',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  wrapper: {
    paddingRight: '2rem',
  },
  rightContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1.5rem',
    },
  },
}));

type Props = RouteComponentProps<{ slug: string }>;

const sortDreams = orderBy((item: any) => moment(item.createdAt))(['desc']);

const PublicShowcasePage = (props: Props) => {
  const slug = props.match.params.slug;
  const { getPath } = useI18nPath();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDream, setSelectedDream] = useState<string | undefined>(undefined);

  const [showcaseResponse, fetchShowcase] = useFetch<ShowcaseResponse>(
    ApiConfig.loadShowcase(slug)
  );

  const showcase =
    showcaseResponse && showcaseResponse.value ? showcaseResponse.value.data.attributes : undefined;
  const error =
    showcaseResponse &&
    !showcaseResponse.pending &&
    !showcaseResponse.value &&
    showcaseResponse.rejected;

  // eslint-disable-next-line
  useEffect(fetchShowcase, []);

  useEffect(() => {
    dispatch(Actions.setCompassState(CompassState.SHOWCASE));
  }, [dispatch]);

  const handleShowcaseDreamsClick = () => {
    setSelectedDream(undefined);
    dispatch(Actions.selectFocus());
  };

  return (
    <>
      <AppBar
        classes={{
          root: classes.appBarRoot,
        }}
      >
        <div className={classes.logo}>
          <Link to={getPath('login')}>
            <Logo />
          </Link>
        </div>
        <div className={classes.rightContent}>
          <LanguageSwitcher />
        </div>
      </AppBar>

      <PageContentWrapper
        color={themeConfig.palette.myCompass.red}
        showcaseDreamsVisible={!!selectedDream}
        onShowcaseDreamsClick={handleShowcaseDreamsClick}
      >
        {error ? (
          <PanelWithSidebar>
            <FormError>{t('showcase.public.error')}</FormError>
          </PanelWithSidebar>
        ) : showcase ? (
          <>
            {!selectedDream ? (
              <>
                {showcase.dreams.length === 0 ? (
                  <PanelWithSidebar>
                    <p>{t('showcase.public.noDream')}</p>
                  </PanelWithSidebar>
                ) : (
                  <Section id="dreams" title={t('dream.dreams')} removeTopMargin>
                    <div className={classes.wrapper}>
                      <Grid container spacing={2}>
                        {showcase.dreams &&
                          showcase.dreams.length > 0 &&
                          sortDreams(showcase.dreams).map(dream => (
                            <React.Fragment key={dream.entityId}>
                              <Grid item xs={12} sm={6} lg={4}>
                                <PublicShowcaseDreamCard
                                  dream={dream}
                                  onDreamSelected={(dreamId: string) => setSelectedDream(dreamId)}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                      </Grid>
                    </div>
                  </Section>
                )}
              </>
            ) : (
              <ShowcaseDream showcase={showcase} selectedDreamId={selectedDream} />
            )}
          </>
        ) : (
          <PanelWithSidebar>
            <div className={classes.loading}>
              <Spinner />
              {t('common.loading')}
            </div>
          </PanelWithSidebar>
        )}
      </PageContentWrapper>
    </>
  );
};

export default PublicShowcasePage;
