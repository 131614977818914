import { RelationContact } from '../../../config/api/models';
import moment from 'moment';

export default function contactSort(a: RelationContact, b: RelationContact) {
  // Check if archived
  if (a.archived) {
    return 1;
  } else if (b.archived) {
    return -1;
  }

  // Check if pending
  if (a.notAccepted) {
    return 1;
  } else if (b.notAccepted) {
    return -1;
  }

  // Check if last message timestamp is present
  if (!b.relation.lastMessageTime && !a.relation.lastMessageTime) {
    return b.relation.entityId > a.relation.entityId ? 1 : -1;
  } else if (!b.relation.lastMessageTime) {
    return -1;
  } else if (!a.relation.lastMessageTime) {
    return 1;
  }

  // Check last message timestamp
  const dateA = moment(a.relation.lastMessageTime);
  const dateB = moment(b.relation.lastMessageTime);
  return dateA.isBefore(dateB) ? 1 : -1;
}
