import { RootState } from '../../config/store/types';
import {
  DreamId,
  DreamResponse,
  FocusId,
  FocusResponse,
  LearningsResponse,
  LookBackResponse,
  Maybe,
  MilestonesResponse,
  PostId,
  PostsResponse,
  UserId,
  UserResponse,
} from '../../config/api/models';
import security from '../security';
import { CompassState } from './model';
import { createSelector } from 'reselect';

export const getCompassState = (state: RootState): CompassState => {
  return state.myCompass.compassState;
};

export const getInitialFocusCreated = (state: RootState) => state.myCompass.initialFocusCreated;

// DREAM

export const getDreams = (state: RootState) => state.repository.getIn(['dreams', 'value', 'data']);

export const getDreamByDreamId = (dreamId?: DreamId) => (
  state: RootState
): Maybe<DreamResponse> => {
  if (!dreamId) {
    return null;
  }

  return state.repository.getIn([`dream-${dreamId}`, 'value']) || null;
};

export const getDreamIdForUserId = (userId?: UserId) => (
  state: RootState
): Maybe<DreamId> | false => {
  return getCompassUserId(state) === userId
    ? getDreamIdForCompassUser(state)
    : security.selectors.getUserId(state) === userId || !userId
    ? getDreamIdForLoggedInUser(state)
    : false;
};

export const getSelectedDreamResponse = (state: RootState): Maybe<DreamResponse> => {
  const dreamId =
    state.myCompass.compassState === CompassState.COMPASS ||
    state.myCompass.compassState === CompassState.SHOWCASE
      ? getDreamIdForCompassUser(state)
      : getDreamIdForLoggedInUser(state);
  const fetchedDream = (state.repository.getIn([`dream-${dreamId}`, 'value']) ||
    state.repository.getIn([`dream`, 'value'])) as DreamResponse;

  return fetchedDream || null;
};

export const getSelectedDreamId = (state: RootState): Maybe<DreamId> => {
  const compassState = getCompassState(state);
  return compassState === CompassState.COMPASS
    ? getDreamIdForCompassUser(state)
    : getDreamIdForLoggedInUser(state) || getDreamIdFromFetchedDream(state);
};

export const getDreamIdForLoggedInUser = (state: RootState): Maybe<DreamId> => {
  const dreamId = security.selectors.getFirstDreamId(state);

  return dreamId || null;
};

export const getDreamIdForCompassUser = (state: RootState): Maybe<DreamId> => {
  const dreamId = state.repository.getIn(
    ['compassUser', 'value', 'data', 'relationships', 'dreams', 'data', '0', 'id'],
    null
  );

  return dreamId || null;
};

export const getCompassUserId = (state: RootState): Maybe<UserId> => {
  return state.repository.getIn(['compassUser', 'value', 'data', 'id'], null);
};

export const getCompassUserInfo = (state: RootState) => {
  return state.repository.getIn(['compassUser', 'value', 'data', 'attributes'], null);
};

export const getCompassUserResponse = (state: RootState): Maybe<UserResponse> => {
  return state.repository.getIn(['compassUser', 'value']);
};

const getDreamIdFromFetchedDream = (state: RootState): Maybe<DreamId> => {
  const dream = getSelectedDreamResponse(state);
  return dream ? dream.data.id : null;
};

export const getSelectedDreamTitle = (state: RootState): Maybe<string> => {
  const dream = getSelectedDreamResponse(state);

  return dream ? dream.data.attributes.title : null;
};

export const getDreamTitleByDreamId = (dreamId?: DreamId) => (state: RootState): Maybe<string> => {
  if (!dreamId) {
    return null;
  }
  const dream = state.repository.getIn([`dream-${dreamId}`, 'value'], null) as DreamResponse;
  return dream ? dream.data.attributes.title : null;
};

// FOCUS

export const getSelectedFocusId = (state: RootState): Maybe<FocusId> =>
  state.myCompass.selectedFocusId || null;

export const getSelectedFocusResponse = (state: RootState): Maybe<FocusResponse> => {
  const focusId = getSelectedFocusId(state);
  const fetchedFocus = state.repository.getIn([`focus-${focusId}`, 'value']) as FocusResponse;

  return fetchedFocus && fetchedFocus.data.id === getSelectedFocusId(state) ? fetchedFocus : null;
};

export const getSelectedFocusTitle = (state: RootState): Maybe<string> => {
  const focus = getSelectedFocusResponse(state);

  return focus ? focus.data.attributes.title : null;
};

const getPosts = (state: RootState) =>
  (state.repository.getIn(['posts', 'value', 'data']) as PostsResponse['data']) || [];

const getLearnings = (state: RootState) =>
  state.repository.getIn(['learnings', 'value', 'data']) as LearningsResponse['data'];

const getLookBack = (state: RootState) =>
  state.repository.getIn(['lookBack', 'value', 'data']) as LookBackResponse['data'];

const getMilestones = (state: RootState) =>
  state.repository.getIn(['milestones', 'value', 'data']) as MilestonesResponse['data'];

export const getPostsForFocus = createSelector(
  getPosts,
  getSelectedFocusId,
  getLearnings,
  getLookBack,
  getMilestones,
  (posts, focusId, learnings, lookback, milestones): PostId[] => {
    const result: PostId[] = [];
    if (focusId) {
      posts.forEach(post => {
        switch (post.attributes.postType) {
          case 'learn':
            if (learnings) {
              const learn = learnings.find(
                l =>
                  l.relationships &&
                  l.relationships.focus &&
                  l.relationships.focus.data &&
                  l.relationships.focus.data.id === focusId &&
                  post.attributes.postId === l.attributes.entityId
              );
              if (learn) {
                result.push(post.attributes.entityId);
              }
            }
            break;
          case 'lookback':
            if (
              lookback &&
              lookback.relationships &&
              lookback.relationships.focus &&
              lookback.relationships.focus.data &&
              lookback.relationships.focus.data.id === post.attributes.postId
            ) {
              result.push(post.attributes.entityId);
            }
            break;
          case 'milestone':
            if (milestones) {
              const milestone = milestones.find(
                m =>
                  m.relationships &&
                  m.relationships.focus &&
                  m.relationships.focus.data &&
                  m.relationships.focus.data.id === focusId &&
                  post.attributes.postId === m.attributes.entityId
              );
              if (milestone) {
                result.push(post.attributes.entityId);
              }
            }
            break;
        }
      });
    }
    return result;
  }
);

// PLAN

export const getSelectedFocusPlansApiLink = (state: RootState): Maybe<string> => {
  const focus = getSelectedFocusResponse(state);

  return focus ? focus.data.relationships.plans.links.related : null;
};

// LEARN

export const getSelectedFocusLearningsApiLink = (state: RootState): Maybe<string> => {
  const focus = getSelectedFocusResponse(state);

  return focus ? focus.data.relationships.learnings.links.related : null;
};

// LOOK BACK

export const getSelectedFocusLookBackApiLink = (state: RootState): Maybe<string> => {
  const focus = getSelectedFocusResponse(state);

  return focus ? focus.data.relationships.lookback.links.related : null;
};
