import InspirationDialogProvider from './containers/InspirationDialogProvider';
import InspirationFormDialog from './containers/InspirationFormDialog';
import InspirationSimpleDialog from './containers/InspirationSimpleDialog';
import InspirationSidebarButton from './containers/InspirationSidebarButton';
import InspirationChatDialog from './containers/InspirationChatDialog';
import InspirationButton from './containers/InspirationButton';
import InspirationCollapsible from './containers/InspirationCollapsible';

const inspiration = {
  InspirationDialogProvider,
  InspirationSimpleDialog,
  InspirationFormDialog,
  InspirationSidebarButton,
  InspirationChatDialog,
  InspirationButton,
  InspirationCollapsible,
};

export default inspiration;
