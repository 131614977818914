class MaxMap<K = string, V = any> {
  private map: Map<K, V>;
  private readonly limit: number;
  private keys: K[] = [];

  constructor(limit: number) {
    this.map = new Map();
    this.limit = limit;
  }

  has(key: K) {
    return this.map.has(key);
  }

  get(key: K) {
    return this.map.get(key);
  }

  set(key: K, value: V) {
    this.setKeyAsFirstItem(key);
    this.removeOldestItem();

    return this.map.set(key, value);
  }

  delete(key: K) {
    return this.map.delete(key);
  }

  private setKeyAsFirstItem(key: K) {
    const index = this.keys.findIndex(k => k === key);
    if (index > -1) {
      this.keys.splice(index, 1);
    }
    this.keys.unshift(key);
  }

  private removeOldestItem() {
    if (this.keys.length < this.limit) {
      return;
    }

    const itemsToRemove = this.keys.splice(this.limit, this.keys.length - this.limit);
    itemsToRemove.forEach(k => this.map.delete(k));
  }
}

export default MaxMap;
