import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from './Button';
import { Icons } from './SvgIcon';
import SectionTitle from './SectionTitle';
import BlurDialog from '../modules/shared/components/BlurDialog';
import { default as themeConfig } from '../config/theme';

type Props = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  text?: string;
  confirmButtonText: string;
  confirmButtonIcon?: Icons;
  cancelButtonText: string;
  cancelButtonIcon?: Icons;
};

const useStyles = makeStyles({
  text: {
    fontSize: '14px',
    textAlign: 'center',
    margin: '3rem 0',
  },
  group: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ConfirmationModal = ({
  open,
  onConfirm,
  onCancel,
  title,
  text,
  confirmButtonText,
  confirmButtonIcon,
  cancelButtonText,
  cancelButtonIcon,
}: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(themeConfig.breakpoints.down('xs'));
  const actions = [
    <Button type="button" icon={confirmButtonIcon} onClick={onConfirm} key={confirmButtonText}>
      {confirmButtonText}
    </Button>,
    <Button
      type="button"
      color="secondary"
      icon={cancelButtonIcon}
      onClick={onCancel}
      key={cancelButtonText}
    >
      {cancelButtonText}
    </Button>,
  ];

  return (
    <BlurDialog
      open={open}
      onClose={onCancel}
      actions={actions}
      alignActions="center"
      fullScreen={isMobile}
    >
      <SectionTitle label={title} />
      <p className={classes.text}>{text}</p>
    </BlurDialog>
  );
};

export default ConfirmationModal;
