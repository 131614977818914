import React, { useCallback, useEffect } from 'react';
import { DreamId, Showcase, FocusId } from '../../../config/api/models';
import {
  getShowcaseFocussesForDream,
  focusTabsMapper,
  getShowcaseFocusMapForSelectedDream,
} from '../helpers/helperFunctions';
import FocusTabsBar from '../../myCompass/containers/Focus/FocusTabsBar';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedFocusId } from '../../myCompass/selectors';
import { Actions } from '../../myCompass/actions';
import ShowcaseLookBackWrapper from './ShowcaseLookBackWrapper';
import ShowcaseLearnWrapper from './ShowcaseLearnWrapper';
import ShowcaseMilestoneWrapper from './ShowcaseMilestoneWrapper';
import ShowcaseBlock from '../components/ShowcaseBlock';
import Section from '../../../components/Section';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  dreamId: DreamId;
  showcase: Showcase;
};

const useStyles = makeStyles(() => ({
  focusTabs: {
    marginBottom: '4rem',
  },
}));

const ShowcaseFocusWrapper = ({ dreamId, showcase }: Props) => {
  const focusses = getShowcaseFocussesForDream(showcase, dreamId);
  const focusTabs = focusTabsMapper(focusses);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const selectedFocusId = useSelector(getSelectedFocusId);
  const setSelectedTab = useCallback((focusId: FocusId) => dispatch(Actions.selectFocus(focusId)), [
    dispatch,
  ]);
  const selectedFocus = showcase.focusses.find(f => f.entityId === selectedFocusId);

  useEffect(() => {
    if (!selectedFocus && focusses.length) {
      setSelectedTab(focusses[0].entityId);
    }
  }, [setSelectedTab, focusses, selectedFocus]);

  if (!showcase.focusses.length || !selectedFocus || !selectedFocusId) {
    return null;
  }

  const focusMap = getShowcaseFocusMapForSelectedDream(showcase, dreamId, selectedFocusId);
  const getComments = () => {
    if (focusMap) {
      return showcase.comments.filter(c =>
        focusMap.comments.map((fc: any) => fc.toString()).includes(c.entityId)
      );
    }
    return [];
  };

  return (
    <Section id="i-focus" title={t('focus.title')} context={showcase.dreams[0].title}>
      <div className={classes.focusTabs}>
        <FocusTabsBar
          tabs={focusTabs}
          selectedTab={selectedFocusId}
          onChange={value => setSelectedTab(value)}
        />
      </div>
      {selectedFocus && (
        <ShowcaseBlock
          context={selectedFocus}
          closed={Boolean(selectedFocus.isClosed && selectedFocus.isSuccessful)}
          closedAt={selectedFocus.closedAt}
          comments={[...getComments()]}
          key={selectedFocus.entityId}
          modelType="focus"
        />
      )}
      <ShowcaseLearnWrapper dreamId={dreamId} />
      <ShowcaseLookBackWrapper dreamId={dreamId} />
      <ShowcaseMilestoneWrapper dreamId={dreamId} />
    </Section>
  );
};

export default ShowcaseFocusWrapper;
