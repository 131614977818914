import React, { useEffect, useState } from 'react';
import { InferRequest } from 'react-redux-fetch';
import { useSelector } from 'react-redux';
import useFetch from '../../../../helpers/useFetch';
import { LearningsResponse } from '../../../../config/api/models';
import { ApiConfig } from '../../api';
import Learn from './Learn';
import { getSelectedFocusLearningsApiLink, getCompassState } from '../../selectors';
import sortByDateUpdated from '../../../../helpers/sortByDateUpdated';
import { CompassState } from '../../model';

const apiConfig = ApiConfig.loadLearnings();

const LearnWrapper = () => {
  const learnApiLink = useSelector(getSelectedFocusLearningsApiLink);
  const compassState = useSelector(getCompassState);
  const [needLoading, setNeedLoading] = useState<boolean>(true);

  const [learningsResponse, fetchLearnings] = useFetch<
    LearningsResponse,
    InferRequest<typeof apiConfig>
  >(apiConfig);

  const isLoading = !learningsResponse || !learningsResponse.value;
  const learns =
    (learningsResponse && learningsResponse.value && learningsResponse.value.data) || [];

  useEffect(() => {
    if (learnApiLink) {
      fetchLearnings(learnApiLink);
    }
  }, [learnApiLink, fetchLearnings]);

  if (isLoading) {
    return null;
  }

  const handleRefetch = () => {
    if (learnApiLink) {
      fetchLearnings(learnApiLink);
      setNeedLoading(false);
    }
  };

  return (
    <>
      {(compassState === CompassState.MYCOMPASS || !learns.length) && (
        <Learn refetch={handleRefetch} learn={null} learnId={null} key={learns.length} />
      )}

      {learns.length > 0 &&
        !(learningsResponse!.pending && needLoading) &&
        sortByDateUpdated(learns).map(learn => (
          <Learn
            refetch={handleRefetch}
            learn={learn.attributes}
            learnId={learn.id}
            key={learn.id}
          />
        ))}
    </>
  );
};

export default React.memo(LearnWrapper);
