import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Html from '../../../components/Html';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import theme from '../../../config/theme';
import { HashLink } from 'react-router-hash-link';

const useStyles = makeStyles(() => ({
  sectionTwoRoot: {
    margin: '5rem auto',
    width: '80%',
  },
  sectionTwoTitle: {
    textAlign: 'center',
    fontSize: '3rem',
  },
  icon: {
    marginTop: '2rem',
  },
  link: {
    color: theme.palette.myCompass.green,
  },
}));

const SectionTwo = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.sectionTwoRoot}>
      <div className={classes.sectionTwoTitle}>
        <Html>{t('home.sectionTwo.title')}</Html>
        <div className={classes.icon}>
          <HashLink smooth to="#sectionThree" className={classes.link}>
            <SvgIcon icon={Icons.CHEVRON_DOWN} size={5} />
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
