import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import { ClientRoute, I18nRoutes, RouteConfig } from '../config/clientRoutes/types';
import { RouteName } from '../config/clientRoutes/routeNames';

const getRoutes = (routeConfig: RouteConfig) => (i18nRoutes: I18nRoutes): ClientRoute[] =>
  flatMap(routeConfig, (config, routeId) =>
    map(i18nRoutes[routeId as RouteName], path => ({
      path,
      routeId,
      ...config,
    }))
  );

export default getRoutes;
