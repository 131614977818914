import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Theme, useMediaQuery } from '@material-ui/core';
import theme from '../config/theme';
import { useCompassUser } from '../modules/shared/hooks';
import { useSelector } from 'react-redux';
import { getHelp } from '../modules/shared/selectors';

type Props = {
  children: React.ReactChild;
  sidebar?: React.ReactElement;
  suffixComponent?: React.ReactElement;
  component?: React.ElementType;
  noPadding?: boolean;
  fullWidth?: boolean;
};

type StyleProps = {
  withSidebar: boolean;
  isMobile: boolean;
  isPaper: boolean;
  fullWidth: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  panelRoot: {
    position: 'relative',
    display: 'flex',
  },
  sidebar: {
    width: theme.config.sidebarWidth,
    [theme.breakpoints.down('xs')]: {
      width: 0,
    },
  },
  container: (props: StyleProps) => ({
    flex: 1,
    position: 'relative',
    width: '100%',
    maxWidth:
      props.isMobile || props.fullWidth ? 'none' : `calc(100% - ${theme.config.sidebarWidth})`,
    borderTopRightRadius: props.isMobile
      ? theme.shape.borderRadius
      : props.withSidebar
      ? 0
      : theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-2rem',
      marginRight: '-2rem',
      borderRadius: 0,
      borderLeft: 'none',
      borderRight: 'none',
    },
  }),
  content: (props: StyleProps) => ({
    padding: props.isPaper ? '5rem 5.5rem' : 0,
  }),
}));

const PanelWithSidebar = (props: Props) => {
  const {
    children,
    sidebar,
    component = Paper,
    suffixComponent,
    noPadding = false,
    fullWidth = false,
  } = props;
  const isPaper = component === Paper && !noPadding;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [seeSidebar, setSeeSidebar] = useState(true);
  const compassUser = useCompassUser();
  const isHelpActive = useSelector(getHelp);

  useEffect(() => {
    if (compassUser) {
      setSeeSidebar(true); //isHelpActive);
    } else {
      setSeeSidebar(true);
    }
  }, [compassUser, setSeeSidebar, isHelpActive]);

  const classes = useStyles({
    withSidebar: Boolean(sidebar),
    isMobile,
    isPaper,
    fullWidth: fullWidth && !sidebar,
  });

  const ContentComponent = component;

  return (
    <div className={classes.panelRoot}>
      <ContentComponent className={classes.container}>
        <div className={classes.content}>{children}</div>
        {suffixComponent ? suffixComponent : null}
      </ContentComponent>
      {sidebar && seeSidebar && !isMobile && <aside className={classes.sidebar}>{sidebar}</aside>}
    </div>
  );
};

export default PanelWithSidebar;
