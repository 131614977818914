import SettingsView from './components/SettingsView';
import SettingsForm from './containers/SettingsForm';
import EditProfilePictureDialog from './components/EditProfilePictureDialog';
import PageContentWrapperWithStickyUserMenu from './components/PageContentWrapperWithStickyUserMenu';

const profile = {
  EditProfilePictureDialog,
  PageContentWrapperWithStickyUserMenu,
  settings: {
    View: SettingsView,
    Form: SettingsForm,
  },
};

export default profile;
