import { RouteConfig } from '../../../config/clientRoutes/types';
import getRoutes from '../../../helpers/getRoutes';
import myTalents from '../../myTalents';
import myCompass from '../../myCompass';
import myCoach from '../../myCoach';
import myNetwork from '../../myNetwork';
import settings from '../../settings';
import shared from '../../shared';
import myShowcase from '../../myShowcase';
import coachingsLuik from '../../coachingsLuik';

const privateRouteConfig: RouteConfig = {
  settings: {
    component: settings.SettingsPage,
  },
  compassPage: {
    component: myCompass.DreamsOverviewPage,
    exact: true,
  },
  dreamDetail: {
    component: myCompass.DreamDetailPage,
  },
  myCompass: {
    component: myCompass.DreamsOverviewPage,
    exact: true,
  },
  myNetwork: {
    component: myNetwork.MyNetworkPage,
    exact: true,
  },
  networkPage: {
    component: myNetwork.MyNetworkPage,
    exact: true,
  },
  myCoach: {
    component: myCoach.MyCoachPage,
    exact: true,
  },
  coachingsPage: {
    component: myCoach.MyCoachPage,
    exact: true,
  },
  talentsPage: {
    component: myTalents.MyTalentsPage,
  },
  myTalents: {
    component: myTalents.MyTalentsPage,
  },
  myShowcase: {
    component: myShowcase.MyShowcasePage,
    exact: true,
  },
  showcasePage: {
    component: myShowcase.MyShowcasePage,
    exact: true,
  },
  showcaseDreamDetail: {
    component: myShowcase.ShowcaseDreamDetailPage,
  },
  aboutDreams: {
    component: shared.BasicPage,
  },
  coaching: {
    component: coachingsLuik.CoachingsLuikPage,
  },
};

const getPrivateRoutes = getRoutes(privateRouteConfig);

export default getPrivateRoutes;
