import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ProfilePicture from '../ProfilePicture';

type Props = {
  children: React.ReactNode;
  avatar: string;
  isOwn?: boolean;
  withoutAuthorInfo?: boolean;
};

type StyleProps = {
  showOnlyFirstInfo?: boolean;
  isOwn?: boolean;
};

const useStyles = makeStyles({
  chatMessageRoot: (props: StyleProps) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: props.isOwn ? 'row' : 'row-reverse',
  }),
  picture: {
    width: '4.6rem',
  },
  messages: (props: StyleProps) => ({
    width: '55%',
    margin: props.isOwn ? '0 0 0 1.5rem' : '0 1.5rem 0 0',
  }),
});

const ChatMessageWrapper = (props: Props) => {
  const { children, avatar, isOwn, withoutAuthorInfo } = props;
  const classes = useStyles({ isOwn });

  return (
    <div className={classes.chatMessageRoot}>
      <div className={classes.picture}>
        {withoutAuthorInfo ? null : <ProfilePicture profilePicture={avatar} />}
      </div>
      <div className={classes.messages}>{children}</div>
    </div>
  );
};

export default ChatMessageWrapper;
