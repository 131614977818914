import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Message, { MessageProps } from '../Message';

const useStyles = makeStyles(() => ({
  messageWrapper: {
    marginTop: '2rem',
  },
}));

const FormError = (props: MessageProps) => {
  const classes = useStyles();

  return (
    <div className={classes.messageWrapper}>
      <Message type="error" {...props} />
    </div>
  );
};

export default FormError;
