import { RouteName } from '../../../config/clientRoutes/routeNames';
import { MyCompassColor } from '../../../config/theme';
import { Icons } from '../../../components/SvgIcon';
import { I18nRoutes } from '../../../config/clientRoutes/types';

export type NavigationItem = {
  label: string;
  routeName: RouteName;
  selectedOn: RouteName[];
  color: MyCompassColor;
  triangleSize: number;
  triangleTop: number;
  icon: Icons;
};
export type NavigationData = NavigationItem[];

const MyCompassRoute: NavigationItem = {
  label: 'navigation.myCompass',
  routeName: 'myCompass',
  selectedOn: ['myCompass', 'dreamDetail'],
  color: 'green',
  triangleSize: 13,
  triangleTop: 0.6,
  icon: Icons.COMPASS,
};

const MyCoachRoute: NavigationItem = {
  label: 'navigation.myCoach',
  routeName: 'myCoach',
  selectedOn: ['myCoach'],
  color: 'blue',
  triangleSize: 11,
  triangleTop: 1.6,
  icon: Icons.COACH,
};

const MyNetworkRoute: NavigationItem = {
  label: 'navigation.myNetwork',
  routeName: 'myNetwork',
  selectedOn: ['myNetwork'],
  color: 'yellow',
  triangleSize: 11,
  triangleTop: 1.6,
  icon: Icons.NETWORK,
};

const MyShowcaseRoute: NavigationItem = {
  label: 'navigation.myShowcase',
  routeName: 'myShowcase',
  selectedOn: ['myShowcase', 'showcaseDreamDetail'],
  color: 'red',
  triangleSize: 11,
  triangleTop: 1.6,
  icon: Icons.EYE,
};

const navigationData = (routes: I18nRoutes | null, isExtern: boolean): NavigationData => {
  if (isExtern) {
    const result: NavigationData = [];
    if (routes && routes.myNetwork) {
      result.push(MyNetworkRoute);
    }
    if (routes && routes.myCoach) {
      result.push(MyCoachRoute);
    }
    return result;
  }
  return [MyCompassRoute, MyNetworkRoute, MyCoachRoute, MyShowcaseRoute];
};

export default navigationData;
