import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { MyCompassColor } from '../../../config/theme';
import clsx from 'clsx';

type Props = {
  className: string;
  color: MyCompassColor;
  size: number;
  top: number;
  selected: boolean;
};

type StyleProps = {
  size: number;
  top: number;
  selected: boolean;
};

const useStyles = makeStyles({
  navTabTriangleRoot: (props: StyleProps) => ({
    width: props.selected ? props.size + 'rem' : '2rem',
    top: props.selected ? props.top + 'rem' : '6rem',
  }),
});

const NavTabTriangle = ({ className, color, size, top, selected }: Props) => {
  const classes = useStyles({ size, top, selected });
  const theme: Theme = useTheme();

  return (
    <svg
      className={clsx(classes.navTabTriangleRoot, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.2 11.8"
    >
      <path
        fill={theme.palette.myCompass[color]}
        d="M6 .3L.1 10.8c-.3.4.1 1 .6 1h11.9c.5 0 .8-.6.6-1L7.2.3c-.3-.4-.9-.4-1.2 0z"
      />
    </svg>
  );
};

export default NavTabTriangle;
