import React from 'react';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const ProgressBar = (props: LinearProgressProps & { label: string; status: string }) => {
  const { t } = useTranslation();
  return (
    <Box mb={1}>
      <Typography
        variant="body2"
        color="primary"
        style={{ marginBottom: '-1.5rem', fontSize: '1rem' }}
      >
        {props.label}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textPrimary" style={{ margin: 0 }}>{`${Math.round(
            props.value || 0
          )}%`}</Typography>
        </Box>
      </Box>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ marginTop: '-1.5rem', fontSize: '1rem' }}
      >
        {props.status === 'uploading'
          ? t('forms.labels.ckeditor.videoUpload.uploading')
          : t('forms.labels.ckeditor.videoUpload.processing')}
      </Typography>
    </Box>
  );
};

export default ProgressBar;
