import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { CompleteRegistrationRequestData, UserId, UserResponse } from '../../../config/api/models';
import { ApiConfig } from '../api';
import { Field, Form } from 'react-final-form';
import { validations, TextFieldAdapter } from '../../../components/forms';
import FormControl from '../../../components/FormControl';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import Message from '../../../components/Message';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import useAutoLogin from '../hooks/useAutoLogin';
import FormError from '../../../components/forms/FormError';

type Props = {
  userId: UserId;
  token: string;
};

const CompleteRegistrationForm = ({ userId, token }: Props) => {
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const [userResponse, fetchUser] = useFetch<UserResponse>(ApiConfig.getUser(userId, token));
  const [completeRegistrationResponse, completeRegistration] = useFetch(
    ApiConfig.completeRegistration(userId, token)
  );

  const refreshUser = Boolean(
    completeRegistrationResponse &&
      completeRegistrationResponse.fulfilled &&
      userResponse &&
      !userResponse.pending &&
      userResponse.value &&
      !userResponse.value.data.attributes.firstName
  );

  useEffect(() => {
    if (!userResponse || refreshUser) {
      fetchUser();
    }
  }, [userResponse, fetchUser, refreshUser]);

  const { setLoginData, loginResponse } = useAutoLogin(completeRegistrationResponse);

  const completeRegistrationPending =
    completeRegistrationResponse && completeRegistrationResponse.pending;
  const loginPending = loginResponse && loginResponse.pending;

  return (
    <>
      {loginResponse && loginResponse.rejected && <Redirect to={getPath('login')} />}
      {userResponse && userResponse.rejected && (
        <Message type="error">{t('error.expiredLink')}</Message>
      )}
      {userResponse && userResponse.value && (
        <Form
          onSubmit={(values: CompleteRegistrationRequestData) => {
            setLoginData({
              email: values.email,
              password: values.password,
            });
            completeRegistration(values);
          }}
          initialValues={{
            lastName: userResponse.value.data.attributes.lastName,
            firstName: userResponse.value.data.attributes.firstName,
            email: userResponse.value.data.attributes.email,
            secondaryEmail: '',
            password: '',
            passwordConfirm: '',
          }}
          render={({ handleSubmit, values, valid }) => (
            <form onSubmit={handleSubmit}>
              <FormControl label={t('forms.labels.firstName')} id="firstname" required>
                <Field
                  name="firstName"
                  component={TextFieldAdapter}
                  validate={validations(t).required}
                />
              </FormControl>
              <FormControl label={t('forms.labels.lastName')} id="lastname" required>
                <Field
                  name="lastName"
                  component={TextFieldAdapter}
                  validate={validations(t).required}
                />
              </FormControl>
              <FormControl label={t('forms.labels.email')} id="email" required>
                <Field name="email" component={TextFieldAdapter} disabled />
              </FormControl>
              <FormControl
                label={t('forms.labels.alternateEmail')}
                id="alternateEmail"
                helperText={t('completeRegistration.alternateEmail')}
              >
                <Field
                  name="secondaryEmail"
                  component={TextFieldAdapter}
                  validate={validations(t).isValidAndUniqueAlternateEmail(values.email)}
                />
              </FormControl>
              <FormControl label={t('forms.labels.password')} id="password" required>
                <Field
                  name="password"
                  component={TextFieldAdapter}
                  validate={validations(t).isValidPassWord}
                  type="password"
                />
              </FormControl>
              <FormControl label={t('forms.labels.passwordConfirm')} id="passwordConfirm" required>
                <Field
                  name="passwordConfirm"
                  component={TextFieldAdapter}
                  validate={validations(t).match(values.password)}
                  type="password"
                />
              </FormControl>
              <div>
                <Button
                  type="submit"
                  disabled={!valid || completeRegistrationPending || loginPending}
                  icon={Icons.SAVE}
                >
                  {t('forms.buttons.completeRegistration')}
                </Button>
              </div>
            </form>
          )}
        />
      )}
      {completeRegistrationResponse && completeRegistrationResponse.rejected && (
        <FormError>{t('error.completeRegistration')}</FormError>
      )}
    </>
  );
};

export default CompleteRegistrationForm;
