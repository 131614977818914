import Button from '../../../../components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '../../../../helpers/useDisclosure';
import GroupDialog from '../../containers/myGroups/GroupDialog';
import { GroupId } from '../../../../config/api/models';
import { Icons } from '../../../../components/SvgIcon';

type Props = {
  groupId?: GroupId;
  groupName?: string;
  showCreateIcon?: boolean;
};

const CreateOrUpdateGroupButton = ({ groupId, groupName, showCreateIcon }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onToggle } = useDisclosure();

  return (
    <>
      <Button icon={showCreateIcon ? Icons.ADD : Icons.PENCIL_OUTLINE} onClick={onOpen}>
        {t(groupId ? 'myCoach.myGroups.editGroupButton' : 'myCoach.myGroups.addGroupButton')}
      </Button>
      {isOpen && (
        <GroupDialog
          saveBtnText={t('myCoach.myGroups.saveButton')}
          onToggle={onToggle}
          groupId={groupId}
          groupName={groupName}
        />
      )}
    </>
  );
};

export default CreateOrUpdateGroupButton;
