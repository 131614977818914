import { Form } from 'react-final-form';
import {
  DreamRequest,
  InspirationContext,
  DreamResponse,
  FileDTO,
  FileReaderResult,
} from '../../../../config/api/models';
import FormFields from '../../components/FormFields';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import FormError from '../../../../components/forms/FormError';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import security from '../../../security';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../../../../components/ButtonGroup';
import inspiration from '../../../inspiration';
import { DreamSubmitValues } from '../../model';
import FileList from '../../../shared/components/FileList';
import { getPersistingFiles } from '../../../shared/selectors';
import { PromiseState } from 'react-redux-fetch';
import { makeStyles } from '@material-ui/core';
import DreamCoverImageFormField from './DreamCoverImageFormField';

type DreamFormValues = {
  title: string;
  body: string;
  files: FileDTO[];
};

type Props = {
  onSave: (values: DreamSubmitValues) => void;
  coverImage: FileReaderResult;
  onSelectCoverImage: (file: FileReaderResult) => void;
  onClose?: () => void;
  dreamResponse?: PromiseState<DreamResponse>;
  initialValues?: DreamFormValues;
  inModal?: boolean;
};

const useStyles = makeStyles(() => ({
  inModalWrapper: {
    width: '90rem',
  },
}));

const DreamForm = ({
  onSave,
  coverImage,
  onSelectCoverImage,
  onClose,
  dreamResponse,
  initialValues,
  inModal = false,
}: Props) => {
  const { t } = useTranslation();
  const [disableSubmit, preventSubmit] = useState(false);
  const [files] = useState(initialValues ? initialValues.files : []);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);
  const [filesChanged, setFilesChanged] = useState<boolean>(false);
  const [coverImageChanged, setCoverImageChanged] = useState(false);
  const userId = useSelector(security.selectors.getUserId);
  const persistingFiles = useSelector(getPersistingFiles);
  const classes = useStyles();

  const handleFilesChange = (newFilesList: File[], fileIdsToDeleteList: string[]) => {
    setNewFiles(newFilesList);
    setFileIdsToDelete(fileIdsToDeleteList);
    setFilesChanged(!!(newFilesList.length || fileIdsToDeleteList.length));
  };

  const handleSelectCoverImage = (file: FileReaderResult) => {
    setCoverImageChanged(true);
    onSelectCoverImage(file);
  };

  return (
    <div id="new-dream-form" className={inModal ? classes.inModalWrapper : undefined}>
      <Form
        onSubmit={(values: DreamFormValues) => {
          const request: DreamRequest = {
            title: values.title.trim(),
            body: values.body.trim(),
            ownerId: userId,
            visibility: true,
          };
          onSave({ request, filesToAdd: newFiles, fileIdsToDelete });
        }}
        initialValues={initialValues ? { ...initialValues, files } : undefined}
        render={({ handleSubmit, pristine, form, values }) => (
          <form onSubmit={handleSubmit}>
            <inspiration.InspirationFormDialog
              inspirationKey={InspirationContext.COACHEE_DREAM}
              onSubmit={form.submit}
              editing
            >
              <FormFields
                onPreventSubmit={preventSubmit}
                titlePlaceholder={t('dream.titlePlaceholder')}
                textPlaceholder={t('dream.textPlaceholder')}
              />
              <DreamCoverImageFormField file={coverImage} onSelectFile={handleSelectCoverImage} />
              <FileList
                files={files}
                editable={true}
                onFilesChange={handleFilesChange}
                loading={persistingFiles === 'dream'}
              />
            </inspiration.InspirationFormDialog>
            <ButtonGroup>
              <Button
                type="submit"
                disabled={
                  (dreamResponse && dreamResponse.pending) ||
                  (pristine && (!filesChanged && !coverImageChanged)) ||
                  persistingFiles === 'dream' ||
                  disableSubmit ||
                  !values.title ||
                  !values.body
                }
                icon={Icons.SAVE}
              >
                {t('forms.buttons.save')}
              </Button>
              <Button type="button" onClick={onClose} color="secondary" icon={Icons.CANCEL}>
                {t('forms.buttons.cancel')}
              </Button>
            </ButtonGroup>
            {dreamResponse &&
              dreamResponse.rejected &&
              dreamResponse.reason &&
              dreamResponse.reason.cause.errors.map((item: any, index: number) => (
                <FormError key={index}>{item.detail}</FormError>
              ))}
          </form>
        )}
      />
    </div>
  );
};

export default DreamForm;
