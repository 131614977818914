import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dialog, { DialogProps } from '../../../components/Dialog';
import { Actions } from '../actions';

type Props = DialogProps;

const BlurDialog = (props: Props) => {
  const { open } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(Actions.enableBlur());
    } else {
      dispatch(Actions.disableBlur());
    }

    return () => {
      dispatch(Actions.disableBlur());
    };
  }, [open, dispatch]);

  return <Dialog {...props} />;
};

export default BlurDialog;
