import React, { FC, useContext, ComponentType } from 'react';
import { RouteProps, Switch } from 'react-router-dom';
import { Route } from 'react-router';
import { ClientRoute } from '../../../config/clientRoutes/types';
import getNeutralRoutes from '../config/neutralRoutes';
import ClientRoutesContext from '../../../config/clientRoutes/ClientRoutesContext';

type Props = {
  routes: ClientRoute[];
  wrapper?: ComponentType;
  fallBackPage: RouteProps['component'];
};

const Routes: FC<Props> = ({ routes, fallBackPage, wrapper }) => {
  const { routes: allRoutes } = useContext(ClientRoutesContext);
  const neutralRoutes = allRoutes ? getNeutralRoutes(allRoutes) : [];
  const Wrapper = wrapper;

  const routeList = [
    ...routes.map(route => (
      <Route {...route} key={Array.isArray(route.path) ? route.path.join() : route.path} />
    )),
    <Route component={fallBackPage} key="fallbackPage" />,
  ];

  return (
    <Switch>
      {neutralRoutes.map(route => (
        <Route {...route} key={Array.isArray(route.path) ? route.path.join() : route.path} />
      ))}
      {Wrapper ? (
        <Wrapper>
          <Switch>{routeList}</Switch>
        </Wrapper>
      ) : (
        routeList
      )}
    </Switch>
  );
};

export default Routes;
