import React, { useState } from 'react';
import { makeStyles, Theme, Tabs, Tab } from '@material-ui/core';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import { Comment as CommentType } from '../../../config/api/models';
import Comment from '../../myCompass/components/Comment';
import { commentSort } from '../../myCompass/components/CommentWrapper';

type Props = {
  comments: CommentType[];
};

const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    borderTop: theme.config.defaultBorder,
  },
  tabFlexContainer: {
    '& > button:last-child, & > div:last-child': {
      borderRight: 'none',
    },
  },
  focusTabsIndicator: {
    display: 'none',
  },
  labelText: {
    margin: '0 1rem',
  },
  commentsLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  commentsIcon: {
    color: theme.palette.highlight.main,
    marginTop: '0.6rem',
  },
  commentWrapper: {
    padding: '1rem',
    borderTop: theme.config.defaultBorder,
  },
  commentFeed: {
    maxHeight: '30rem',
    overflow: 'auto',
    scrollBehavior: 'smooth',
  },
}));

const ShowcaseComments = ({ comments }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string | false>(false);

  const commentLabel = (
    <div className={classes.commentsLabel}>
      <div className={classes.commentsIcon}>
        <SvgIcon icon={Icons.COMMENTS} />
      </div>
      <span className={classes.labelText}>{`${comments.length} ${t('comments.title')}`}</span>
      <SvgIcon icon={Icons.CHEVRON_DOWN} size={1} />
    </div>
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, value: string) => {
    if (value === selectedTab) {
      setSelectedTab(false);
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={handleTabChange}
        classes={{
          root: classes.tabContainer,
          indicator: classes.focusTabsIndicator,
          flexContainer: classes.tabFlexContainer,
        }}
      >
        <Tab value="comments" label={commentLabel} />
      </Tabs>

      {selectedTab === 'comments' && (
        <div className={classes.commentWrapper}>
          <div className={classes.commentFeed}>
            {comments.sort(commentSort).map((comment, index) => (
              <Comment
                key={comment.entityId || `index-${index}`}
                comment={comment}
                userLabel={comment.comment_by_coach ? 'Coach' : undefined}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ShowcaseComments;
