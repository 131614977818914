import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../config/theme';
import { useTranslation } from 'react-i18next';
import BaselineLogo from './BaselineLogo';
import Button from './Button';

const useStyles = makeStyles(() => ({
  sectionRoot: {
    borderTop: theme.config.defaultBorder,
    padding: '2rem',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.grey['400'],
    justifyContent: 'space-between',
    height: '8rem',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      flexDirection: 'column',
      minHeight: '12rem',
      padding: '2rem 1rem',
      height: 'auto',
    },
  },
  footerCopy: {
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0',
    },
  },
  footerMenu: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: '1rem',
    },
    '& > a': {
      margin: '0 1rem',
      '&:first-of-type': {
        marginLeft: 0,
      },
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  footerLogo: {},
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.sectionRoot}>
      <div className={classes.footerMenu}>
        <Button link="privacyPolicy" variant="text">
          {t('footer.links.privacy')}
        </Button>
        {' - '}
        <Button link="cookieCompliance" variant="text">
          {t('footer.links.cookie')}
        </Button>
      </div>
      <div className={classes.footerCopy}>
        &copy; MyCompass {new Date().getFullYear()} - {t('footer.allRights')}
      </div>
      <div className={classes.footerLogo}>
        <BaselineLogo />
      </div>
    </div>
  );
};

export default Footer;
