import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { downloadImage } from '../helpers/downloadImage';
import MaxMap from '../helpers/MaxMap';
import DefaultProfilePicture from './DefaultProfilePicture';
import { useDispatch, useSelector } from 'react-redux';
import { getIsChangingProfilePicture } from '../modules/shared/selectors';
import { Actions } from '../modules/shared/actions';

type Props = HTMLProps<HTMLDivElement> & {
  profilePicture: string;
  retryInterval?: number;
  label?: string;
};

type StyledProps = {
  picture?: string;
};

const maxItemsInMap = 20;
const imageMap = new MaxMap(maxItemsInMap);
const defaultRetryInterval = 1000;
const startReRenderTimeout = 100;
const maxReRenderTimeout = 30000;
const reRenderRatio = 1.4;
// const showLoaderAfter = 500;
const showLoaderAfter = Math.round(500 / startReRenderTimeout / reRenderRatio);

const useStyles = makeStyles<Theme, StyledProps>((theme: Theme) => ({
  profilePictureRoot: (props: StyledProps) => ({
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '100%',
    backgroundImage: 'url(' + props.picture + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: '100%',
    marginBottom: '1.5rem',
  }),
  profileLabelRoot: {
    position: 'relative',
  },
  profileLabel: {
    backgroundColor: theme.palette.highlight.main,
    color: theme.palette.common.white,
    position: 'absolute',
    bottom: '-0.2rem',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    textAlign: 'center',
    fontSize: '1rem',
  },
}));

const ProfilePicture = ({
  profilePicture,
  retryInterval = defaultRetryInterval,
  label,
  ...rest
}: Props) => {
  const reRenderTimeoutRef = useRef(startReRenderTimeout);
  const [retryCount, setRetryCount] = useState({ count: 0, refetch: false });
  const [picture, setPicture] = useState<string | undefined>();
  const dispatch = useDispatch();

  const isChangingProfilePicture = useSelector(getIsChangingProfilePicture);

  const classes = useStyles({
    picture: picture ? picture : '',
  });

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    let isCancelled: boolean = false;

    const retry = (refetch: boolean) => setRetryCount({ count: retryCount.count + 1, refetch });
    const resetRetry = () => setRetryCount({ count: 0, refetch: false });

    const storeImage = (newPicture?: string) => {
      if (!isCancelled) {
        imageMap.set(profilePicture, newPicture);
        setPicture(newPicture);
        reRenderTimeoutRef.current = startReRenderTimeout;
        resetRetry();
      }
    };

    // If the user does not have a profile picture, it is in the static folder.
    // Don't try to download that from the api, because it will not exist there.
    if (typeof profilePicture === 'undefined' || profilePicture.startsWith('/static/')) {
      setPicture(undefined);
      return;
    }

    if (!imageMap.has(profilePicture) || retryCount.refetch) {
      // When the picture is not downloaded yet, or explicit refetch is needed
      // Already set the picture in the image map, this is needed for other components that request the same image.
      imageMap.set(profilePicture, false);
      // Download the image, when done, save it in the image map, on error, retry every $retryInterval ms.
      downloadImage(
        profilePicture,
        base64Image => {
          dispatch(Actions.setIsChangingProfilePicture(false));
          setRetryCount({ count: 0, refetch: false });
          storeImage(base64Image);
        },
        () => {
          if (!isChangingProfilePicture) {
            return;
          }
          reRenderTimeoutRef.current = Math.min(
            Math.max(reRenderTimeoutRef.current * reRenderRatio, retryInterval),
            maxReRenderTimeout
          );
          timeoutId = setTimeout(() => retry(true), reRenderTimeoutRef.current);
        },
        isCancelled
      );
    } else {
      // An image is found, or another component is already fetching the image
      const base64Image = imageMap.get(profilePicture);

      if (base64Image) {
        // When the image is found, store & use it.
        if (picture !== base64Image) {
          storeImage(base64Image);
        }
      } else {
        // Another component is processing the same picture, retry to render in a couple of milliseconds
        reRenderTimeoutRef.current = Math.min(
          reRenderTimeoutRef.current * reRenderRatio,
          maxReRenderTimeout
        );
        timeoutId = setTimeout(() => retry(false), reRenderTimeoutRef.current);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      isCancelled = true;
    };
  }, [profilePicture, retryCount, picture, retryInterval, isChangingProfilePicture, dispatch]);

  return (
    <div className={classes.profileLabelRoot}>
      {picture && retryCount.count < showLoaderAfter ? (
        <div className={classes.profilePictureRoot} {...rest} />
      ) : (
        <DefaultProfilePicture {...rest} />
      )}
      {label && <div className={classes.profileLabel}>{label}</div>}
    </div>
  );
};

export default React.memo(ProfilePicture);
