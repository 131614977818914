import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InferRequest } from 'react-redux-fetch';
import { getSelectedFocusLookBackApiLink } from '../../selectors';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import { LookBackResponse } from '../../../../config/api/models';
import InitialLookBack from './InitialLookBack';
import UpdateLookBack from './UpdateLookBack';

const apiConfig = ApiConfig.loadLookBack();

const LookBack = () => {
  const lookBackApiLink = useSelector(getSelectedFocusLookBackApiLink);
  const [lookBackResponse, fetchLookBack] = useFetch<
    LookBackResponse,
    InferRequest<typeof apiConfig>
  >(apiConfig);

  useEffect(() => {
    if (lookBackApiLink) {
      fetchLookBack(lookBackApiLink);
    }
  }, [lookBackApiLink, fetchLookBack]);

  const handleSubmit = useCallback(() => {
    if (lookBackApiLink) {
      fetchLookBack(lookBackApiLink);
    }
  }, [lookBackApiLink, fetchLookBack]);

  if (!lookBackResponse) {
    return null;
  }

  if (lookBackResponse.value && lookBackResponse.value.data === null) {
    return <InitialLookBack onSubmit={handleSubmit} />;
  }

  return (
    <UpdateLookBack
      lookBack={lookBackResponse.value && lookBackResponse.value.data.attributes}
      lookBackId={lookBackResponse.value && lookBackResponse.value.data.id}
      isLoading={lookBackResponse.pending}
    />
  );
};

export default LookBack;
