import React from 'react';
import UpdateDream from './UpdateDream';
import Section from '../../../../components/Section';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type Props = {
  selectedDream?: string;
  children: React.ReactElement;
};

const Dream = ({ children, selectedDream }: Props) => {
  const { t } = useTranslation();
  const { dreamId } = useParams();
  const dreamIdToUse = !!selectedDream ? selectedDream : dreamId;

  if (!dreamIdToUse) {
    return null;
  }

  return (
    <Section id="i-dream" title={t('dream.title')} removeTopMargin tooltip={t('dream.inspiration')}>
      <UpdateDream dreamId={dreamIdToUse} children={children} />
    </Section>
  );
};

export default Dream;
