import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Theme } from '@material-ui/core';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import MilestoneBackground from '../Milestone/MilestoneBackground';
import theme from '../../../../config/theme';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled?: boolean;
  onAddDream: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'relative',
    width: '100%',
    height: '25rem',
    textDecoration: 'none',
    display: 'block',
    borderRadius: theme.shape.borderRadius,
    border: theme.config.defaultBorder,
    '&:hover': {
      boxShadow: '0 0 15px 0 rgba(0,0,0,.25)',
      border: 'none',
    },
    cursor: 'pointer',
  },
  background: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(#d6d6d6, #c2c3c3)',
    borderRadius: theme.shape.borderRadius,
    zIndex: 0,
  },
  title: {
    position: 'absolute',
    top: 'calc(50% + 5.5rem)',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '1.6rem',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '10rem',
    height: '10rem',
    background: theme.palette.common.white,
    border: `.3rem solid ${theme.palette.grey[200]}`,
    borderRadius: '50%',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const AddDreamCard = ({ disabled = false, onAddDream }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div id="add-dream-card">
      <Paper onClick={disabled ? undefined : onAddDream} className={classes.paper}>
        <MilestoneBackground className={classes.background} />
        <div className={classes.iconWrapper}>
          <SvgIcon
            size={3.2}
            color={theme.palette.grey[500]}
            className={classes.icon}
            icon={Icons.ADD_DREAM}
          />
        </div>
        <div className={classes.title}>{t('dream.addDream')}</div>
      </Paper>
    </div>
  );
};

export default AddDreamCard;
