import { styled } from '@material-ui/styles';

const DisableInteraction = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255,255,255,0.4)',
});

export default DisableInteraction;
