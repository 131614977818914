import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ContentLoader from 'react-content-loader';

const useStyles = makeStyles({
  loaderRoot: {
    marginTop: '-1rem',
  },
});

const ReactContentLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loaderRoot}>
      <ContentLoader
        height={160}
        width={400}
        speed={4}
        primaryColor="#ededed"
        secondaryColor="#c0c0c0"
      >
        <rect x="-2" y="16" rx="4" ry="4" width="188" height="16" />
        <rect x="-6" y="61" rx="3" ry="3" width="350" height="6" />
        <rect x="-5" y="80" rx="3" ry="3" width="327" height="6" />
        <rect x="-5" y="114" rx="3" ry="3" width="201" height="6" />
        <circle cx="714" cy="109" r="30" />
        <rect x="66" y="49" rx="0" ry="0" width="0" height="0" />
        <rect x="50" y="41" rx="0" ry="0" width="0" height="0" />
        <rect x="-9" y="97" rx="3" ry="3" width="350" height="6" />
      </ContentLoader>
    </div>
  );
};

export default ReactContentLoader;
