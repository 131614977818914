import immutable from 'seamless-immutable';
import { Action } from 'redux';
import { FETCH, FetchAction } from 'react-redux-fetch';
import * as ActionTypes from './actionTypes';
import { State } from './models';
import { Actions } from './actions';
import { RelationContext } from '../../config/api/models';
import { getUnreadMessagesMap } from './helpers/helperFunctions';
import { RESOURCE } from '../shared/api';
import { merge } from 'lodash';

type Reducer = (state: State | undefined, action: Actions | FetchAction) => State;

const INITIAL_STATE: State = {
  unreadMessages: {},
  myCoachRelations: [],
};

const reducer: Reducer = (state = immutable(INITIAL_STATE), action: Action) => {
  switch (action.type) {
    case FETCH.for('get').FULFILL:
      if ((action as FetchAction).resource.name === 'coachRelations') {
        const relations: RelationContext[] = (action as FetchAction).value.data.map(
          (v: any) => v.attributes
        );
        const unreadMessagesMap = { ...state.unreadMessages };
        const unreadMessages = getUnreadMessagesMap(relations);
        return { ...state, unreadMessages: merge(unreadMessages, unreadMessagesMap) };
      }
      if ((action as FetchAction).resource.name === RESOURCE.MY_COACH_RELATIONS) {
        const myCoachRelations: RelationContext[] = (action as FetchAction).value.data.map(
          (v: any) => v.attributes
        );
        return { ...state, myCoachRelations };
      }
      if ((action as FetchAction).resource.name === 'initialUnreadMessages') {
        const unreadMessagesMap = { ...state.unreadMessages };
        const initialUm: Record<string, number> = (action as FetchAction).value.data;
        return { ...state, unreadMessages: merge(unreadMessagesMap, initialUm) };
      }
      return state;
    case FETCH.for('patch').FULFILL:
      if ((action as FetchAction).resource.name === 'coachRelation') {
        if (state.myCoachRelations && state.myCoachRelations.length > 0) {
          const relation: RelationContext = (action as FetchAction).value.data.attributes;
          const myCoachRelations = state.myCoachRelations.map(rel => {
            if (rel.entityId !== relation.entityId) {
              return rel;
            }
            return {
              ...rel,
              ...relation,
            };
          });
          return { ...state, myCoachRelations };
        }
      }
      return state;
    case ActionTypes.SET_UNREAD_MESSAGES:
      const { relationKey, contextKey, contextAmount } = (action as any).payload;
      const um = { ...state.unreadMessages };
      if (contextAmount) {
        um[relationKey] += contextAmount;
        um[contextKey] += contextAmount;
      } else {
        um[relationKey] -= state.unreadMessages[contextKey];
        um[contextKey] = contextAmount;
      }
      return { ...state, unreadMessages: um };
    default:
      return state;
  }
};

export default reducer;
