import React from 'react';
import { makeStyles } from '@material-ui/styles';
import FormControl from '../../../components/FormControl';
import { Field } from 'react-final-form';
import { TextFieldAdapter } from '../../../components/forms';
import { useTranslation } from 'react-i18next';
import EditorAdapter from '../../../components/forms/Editor/EditorAdapter';

type Props = {
  onPreventSubmit: (value: boolean) => void;
  titlePlaceholder?: string;
  textPlaceholder?: string;
};

const useStyles = makeStyles(() => ({
  addDreamElementsRoot: {},
}));

const FormFields = ({ onPreventSubmit, titlePlaceholder, textPlaceholder }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.addDreamElementsRoot}>
      <div id="title-text">
        <FormControl label={t('forms.labels.title')} id="title">
          <Field
            name="title"
            component={TextFieldAdapter}
            autoFocus
            autoComplete="off"
            placeholder={titlePlaceholder}
          />
        </FormControl>
      </div>
      <div id="body-text">
        <FormControl label={t('forms.labels.body')} id="body">
          <Field
            name="body"
            component={EditorAdapter}
            onUploadingFile={onPreventSubmit}
            placeholder={textPlaceholder}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default FormFields;
