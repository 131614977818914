import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import configureStore from './config/store/storeConfig';
import theme from './config/theme';
import app from './modules/app';
import { PersistGate } from 'redux-persist/integration/react';
import security from './modules/security';
import I18nGate from './config/i18n/I18nGate';
import ClientRoutesProvider from './config/clientRoutes/ClientRoutesProvider';
import initReduxFetch from './config/store/initReduxFetch';
import AppInfo from './modules/app/components/AppInfo';
import shared from './modules/shared';
import * as Sentry from '@sentry/browser';
import Debug from './modules/app/components/ResetUserTours';
import { QueryParamProvider } from 'use-query-params';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://ba06d6a49a674636b4e710c1f305ef4f@sentry.io/1723156' });
}

const [store, persistor] = configureStore(app.rootReducer, [
  ...app.middleware,
  ...security.middleware,
]);

initReduxFetch();

ReactDOM.render(
  <ReduxProvider store={store}>
    <ClientRoutesProvider>
      <Suspense fallback={null}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ThemeProvider theme={theme}>
              <StylesProvider injectFirst>
                <CssBaseline />
                <I18nGate loading={null}>
                  <PersistGate loading={null} persistor={persistor}>
                    <shared.Blur>
                      <security.Authentication
                        loggedIn={<app.LoggedInApp />}
                        loggedOut={<app.LoggedOutApp />}
                        loading={null}
                      />
                    </shared.Blur>
                  </PersistGate>
                </I18nGate>
                <AppInfo />
                {process.env.NODE_ENV === 'development' && <Debug />}
              </StylesProvider>
            </ThemeProvider>
          </QueryParamProvider>
        </Router>
      </Suspense>
    </ClientRoutesProvider>
  </ReduxProvider>,
  document.getElementById('root')
);
