import React, { useState } from 'react';
import { Drawer as MuiDrawer, IconButton as MuiIconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from './SvgIcon';

type DrawerSide = 'top' | 'left' | 'bottom' | 'right';

type Props = {
  children: React.ReactNode;
  drawerSide: DrawerSide;
};

const useStyles = makeStyles({
  drawerRoot: {},
  drawerOpen: {
    minWidth: '20rem',
  },
  iconButton: {
    backgroundColor: '#EFEDEE',
  },
  closeButton: {
    backgroundColor: '#EFEDEE',
    margin: '2rem 1rem 0 0',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  childrenWrapper: {
    marginTop: '1rem',
    width: '100%',
  },
});

const Drawer = ({ children, drawerSide }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const fullList = () => (
    <div
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      className={classes.drawerOpen}
    >
      <div className={classes.buttonWrapper}>
        <MuiIconButton onClick={toggleDrawer} className={classes.closeButton}>
          <SvgIcon icon={Icons.HAMBURGER_CLOSE} />
        </MuiIconButton>
      </div>
      <div className={classes.childrenWrapper}>{children}</div>
    </div>
  );

  return (
    <div className={classes.drawerRoot}>
      <MuiIconButton onClick={toggleDrawer} className={classes.iconButton}>
        <SvgIcon icon={Icons.HAMBURGER} />
      </MuiIconButton>
      <MuiDrawer anchor={drawerSide} open={open} onClose={toggleDrawer}>
        {fullList()}
      </MuiDrawer>
    </div>
  );
};

export default Drawer;
