import React, { forwardRef, useContext } from 'react';
import { Theme, Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import SvgIcon, { Icons } from './SvgIcon';
import useCurrentLanguage from '../config/i18n/useCurrentLanguage';
import { RouteName } from '../config/clientRoutes/routeNames';
import ClientRoutesContext from '../config/clientRoutes/ClientRoutesContext';
import { darken } from '@material-ui/core/styles';

type SVGPath = Icons;

type Props = ButtonProps & {
  id?: string;
  icon?: SVGPath;
  link?: RouteName;
  confirm?: boolean;
  highlight?: boolean;
  superBtn?: boolean;
  home?: boolean;
  blue?: boolean;
  component?: React.ElementType;
};

type StyleProps = {
  confirm?: boolean;
  highlight?: boolean;
};

const createContainedStyle = (theme: Theme, color: 'primary' | 'secondary', confirm?: boolean) => {
  return {
    backgroundColor: confirm ? theme.palette.tertiary.light : theme.palette[color].light,
    color: confirm ? theme.palette.tertiary.main : theme.palette[color].main,
    '&:hover': {
      backgroundColor: confirm ? theme.palette.tertiary.medium : theme.palette[color].medium,
    },
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  contained: {
    fontSize: '1.3rem',
    fontWeight: 300,
    lineHeight: '2rem',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: '1.5rem',
    padding: '.2rem 1rem .3rem 1.5rem',
    '&:active': {
      boxShadow: 'none',
    },
  },
  containedPrimary: createContainedStyle(theme, 'primary'),
  containedSecondary: createContainedStyle(theme, 'secondary'),
  disabled: {
    backgroundColor: theme.palette.grey['100'] + ' !important',
    color: theme.palette.grey['300'] + ' !important',
  },
  icon: (props: StyleProps) => ({
    display: 'inherit',
    marginLeft: '1rem',
    filter: props.highlight ? 'drop-shadow(0 0 .5rem rgba(0,0,0,.2))' : 'none',
  }),
  text: {
    textTransform: 'none',
    textDecoration: 'underline',
    fontSize: '1.3rem',
    fontWeight: 300,
    lineHeight: '2rem',
    padding: '.2rem 1rem .3rem .5rem',
    color: theme.palette.grey['500'],
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.grey['700'],
      textDecoration: 'underline',
      background: 'none',
    },
  },
  linkRootText: {
    textTransform: 'none',
    textDecoration: 'underline',
    fontSize: '1.3rem',
    fontWeight: 300,
    lineHeight: '2rem',
    padding: '.2rem 1rem .3rem .5rem',
    color: theme.palette.grey['400'],
    '&:hover': {
      color: theme.palette.grey['600'],
      textDecoration: 'underline',
    },
  },
  linkRoot: {},
  buttonConfirm: {
    backgroundColor: theme.palette.tertiary.light,
    color: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.medium,
    },
  },
  buttonHighlight: {
    backgroundColor: theme.palette.highlight.medium,
    color: 'white',
    fontWeight: 700,
    textShadow: '0 0 .8rem rgba(0,0,0,.2)',
    '&:hover': {
      backgroundColor: theme.palette.highlight.main,
    },
    height: '3.2rem',
    borderRadius: '1.6rem',
  },
  buttonSuper: {
    height: '6rem',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.medium}`,
  },
  homeButton: {
    backgroundColor: theme.palette.myCompass.red,
    color: theme.palette.background.paper,
    fontSize: '1.7rem',
    padding: '0.7rem 1.3rem',
    '&:hover': {
      backgroundColor: darken(theme.palette.myCompass.red, 0.15),
    },
  },
  blueButton: {
    backgroundColor: theme.palette.myCompass.blue,
    color: theme.palette.background.paper,
    fontSize: '1.7rem',
    padding: '0.7rem 1.3rem',
    margin: '1.5rem 0 2.5rem 0',
    '&:hover': {
      backgroundColor: darken(theme.palette.myCompass.blue, 0.15),
    },
  },
}));

const Button = forwardRef((props: Props, ref) => {
  const {
    id,
    children,
    icon,
    link,
    fullWidth,
    superBtn,
    component: c,
    highlight,
    confirm,
    className,
    home,
    blue,
    ...restProps
  } = props;
  const classes = useStyles({ highlight: props.highlight, confirm: !!props.confirm });
  const currentLanguage = useCurrentLanguage();
  const { routes } = useContext(ClientRoutesContext);

  const BtnLink =
    link && routes && routes[link]
      ? React.forwardRef((linkProps: ButtonProps, ref) => (
          <Link
            id={id}
            innerRef={ref as any}
            to={routes[link][currentLanguage]}
            children={linkProps.children}
            className={clsx(
              linkProps.className,
              restProps.variant === 'text' ? classes.linkRootText : classes.linkRoot
            )}
          />
        ))
      : null;

  return (
    <MuiButton
      id={id}
      color="primary"
      variant="contained"
      classes={{
        contained: classes.contained,
        containedPrimary: classes.containedPrimary,
        containedSecondary: classes.containedSecondary,
        text: classes.text,
        disabled: classes.disabled,
      }}
      className={clsx(
        className,
        props.highlight ? classes.buttonHighlight : '',
        props.confirm ? classes.buttonConfirm : '',
        superBtn ? classes.buttonSuper : '',
        home ? classes.homeButton : '',
        blue ? classes.blueButton : ''
      )}
      disableRipple={link !== undefined}
      component={BtnLink || c || 'button'}
      fullWidth={superBtn || fullWidth}
      ref={ref}
      {...restProps}
    >
      {children}
      {icon && (
        <span className={classes.icon}>
          <SvgIcon icon={icon} />
        </span>
      )}
    </MuiButton>
  );
});

export default Button;
