import React from 'react';
import { AppBar, Link, Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NotificationTray from '../../shared/container/NotificationTray';
import LanguageSwitcher from '../../shared/container/LanguageSwitcher';
import Logo from '../../../components/Logo';
import UserMenu from './UserMenu';
import NavTabs from './NavTabs';
import AppBarDrawer from './AppBarDrawer';
import odiseeTheme from '../../../config/theme';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';

const useStyles = makeStyles((theme: Theme) => ({
  appBarRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.config.defaultBorder,
    borderRadius: 0,
    boxShadow: 'none',
    padding: 0,
    height: '7rem',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  logo: {
    marginLeft: '2rem',
    display: 'flex',
    maxHeight: '100%',
    overflow: 'hidden',
    alignItems: 'center',
  },
  rightContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1.5rem',
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(odiseeTheme.breakpoints.down('sm'));
  const { getPath } = useI18nPath();

  return (
    <AppBar
      classes={{
        root: classes.appBarRoot,
      }}
    >
      <div className={classes.logo}>
        <Link href={getPath('aboutDreams')}>
          <Logo />
        </Link>
      </div>
      <div className={classes.rightContent}>
        <NavTabs />
        <NotificationTray />
        {isMobile ? <AppBarDrawer /> : <UserMenu />}
        {!isMobile && <LanguageSwitcher />}
      </div>
    </AppBar>
  );
};

export default NavBar;
