import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getJWt } from '../selectors';
import { container } from 'react-redux-fetch';
import UserProvider from './UserProvider';

type Props = {
  loggedIn: ReactElement;
  loggedOut: ReactElement;
  loading: ReactElement | null;
};

const Authentication: FC<Props> = ({ loggedIn, loggedOut, loading }) => {
  const [isAuthHeaderSet, setAuthHeaderSet] = useState(false);
  const token = useSelector(getJWt);
  const authenticated = Boolean(token);

  useEffect(() => {
    if (authenticated) {
      container.registerRequestHeader('Authorization', `bearer ${token}`);
      setAuthHeaderSet(true);
    } else {
      container.registerRequestHeader('Authorization', '');
      setAuthHeaderSet(false);
    }
  }, [token, authenticated]);

  const settingAuthHeader = authenticated && !isAuthHeaderSet;
  const clearingAuthHeader = !authenticated && isAuthHeaderSet;

  if (settingAuthHeader || clearingAuthHeader) {
    return loading;
  }

  if (!authenticated) {
    return loggedOut;
  }

  return <UserProvider loading={loading}>{loggedIn}</UserProvider>;
};

export default Authentication;
