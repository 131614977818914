import React, { useEffect, useContext } from 'react';
import Routes from './Routes';
import LoggedInFallBackPage from '../components/LoggedInFallBackPage';
import getPrivateRoutes from '../config/privateRoutes';
import LoggedInWrapper from '../components/LoggedInWrapper';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../../shared/api';
import ClientRoutesContext from '../../../config/clientRoutes/ClientRoutesContext';

const LoggedInApp = () => {
  const [, fetchPrivateRoutes] = useFetch(ApiConfig.getPrivateRoutes());
  const { privateRoutes, routes } = useContext(ClientRoutesContext);

  useEffect(fetchPrivateRoutes, [fetchPrivateRoutes]);

  if (!privateRoutes) {
    return null;
  }

  return (
    routes && (
      <Routes
        routes={getPrivateRoutes(routes)}
        wrapper={LoggedInWrapper}
        fallBackPage={() => <LoggedInFallBackPage routes={routes} />}
      />
    )
  );
};

export default LoggedInApp;
