import React from 'react';
import { IconButton as MuiIconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from './SvgIcon';
import { TooltipProps } from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import Tooltip from '../components/Tooltip';

type Props = {
  anchorId?: string;
  label: string;
  tooltip?: TooltipProps['title'];
  context?: string;
  headerButton?: React.ReactNode;
  omitMarginLeftOnMobile?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitleRoot: {
    marginBottom: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  titleLeft: {
    position: 'relative',
    width: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-2rem',
      marginRight: '-2rem',
      paddingLeft: '1rem',
    },
  },
  flex: {
    alignItems: 'flex-start',
  },
  hashTag: {
    position: 'absolute',
    left: 0,
    margin: '0.2rem 1rem 0 0',
    color: theme.palette.grey['400'],
    [theme.breakpoints.up('md')]: {
      left: '-2.5rem',
    },
  },
  noContextAnchorPoint: {
    top: 0,
  },
  anchorPoint: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      top: '-5rem',
    },
  },
  label: {
    fontSize: '3.6rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.8rem',
    },
  },
  context: {
    color: theme.palette.grey['500'],
    fontSize: '1.2rem',
    fontWeight: 300,
    fontStyle: 'italic',
    lineHeight: '2.2rem',
    width: '100%',
    marginLeft: '2rem',
    wordBreak: 'break-word',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  tooltipInfo: {
    marginLeft: '.5rem',
  },
  tooltipContainer: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '2rem',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  omitMarginLeftOnMobile: {
    marginLeft: 0,
  },
  tooltipButton: {
    '& svg': {
      color: theme.palette.grey['400'],
    },
  },
}));

const SectionTitle = ({
  anchorId,
  label,
  tooltip,
  context,
  headerButton,
  omitMarginLeftOnMobile = false,
}: Props) => {
  const classes = useStyles();
  const noContext = !!(anchorId && !context);

  return (
    <div id={anchorId ? `${anchorId}-title` : undefined} className={classes.sectionTitleRoot}>
      <div className={clsx(classes.titleLeft, context && classes.flex)}>
        {anchorId && (
          <>
            <div
              id={anchorId}
              className={clsx(classes.anchorPoint, noContext ? classes.noContextAnchorPoint : '')}
            />
            <div className={classes.hashTag}>
              <SvgIcon icon={Icons.HASH_TAG} />
            </div>
          </>
        )}
        {context && <Typography className={classes.context}>{context}</Typography>}
        <div
          className={clsx(
            classes.tooltipContainer,
            omitMarginLeftOnMobile ? classes.omitMarginLeftOnMobile : ''
          )}
        >
          <Typography
            variant={'h3'}
            className={classes.label}
            id={noContext ? anchorId : undefined}
          >
            {label}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip} placement="right">
              <MuiIconButton className={classes.tooltipButton}>
                <SvgIcon icon={Icons.INFO} />
              </MuiIconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {headerButton}
    </div>
  );
};

export default SectionTitle;
