import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Switch, ListItemText } from '@material-ui/core';

type Props = {
  onClick?: (event: React.MouseEvent) => void;
  onChange: (values: ShowcaseSidebarValues) => void;
  values: ShowcaseSidebarValues;
  disabled?: boolean;
};

export type ShowcaseSidebarValues = {
  includeShowcase: boolean;
  includeShowcaseComments: boolean;
  includeShowcaseFiles: boolean;
};

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    margin: '0.5rem 1.6rem',
  },
  listItem: {
    display: 'flex',
    padding: '0rem 1.6rem',
  },
  listItemTextPrimary: {
    fontSize: '1.4rem',
    marginLeft: '0.5rem',
  },
}));

const ShowcaseSidebarOptions = ({ onClick, values, onChange, disabled }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [includeInShowcase, setIncludeInShowcase] = useState<boolean>(values.includeShowcase);
  const [showComments, setShowComments] = useState<boolean>(values.includeShowcaseComments);
  const [showFiles, setShowFiles] = useState<boolean>(values.includeShowcaseFiles);

  const toggleIncludeInShowcase = () => {
    setIncludeInShowcase(!includeInShowcase);
    onChange({
      includeShowcase: !includeInShowcase,
      includeShowcaseComments: showComments,
      includeShowcaseFiles: showFiles,
    });
  };
  const toggleComments = () => {
    setShowComments(!showComments);
    onChange({
      includeShowcase: includeInShowcase,
      includeShowcaseComments: !showComments,
      includeShowcaseFiles: showFiles,
    });
  };

  const toggleShowFiles = () => {
    setShowFiles(!showFiles);
    onChange({
      includeShowcase: includeInShowcase,
      includeShowcaseComments: showComments,
      includeShowcaseFiles: !showFiles,
    });
  };

  useEffect(() => {
    setIncludeInShowcase(values.includeShowcase);
  }, [values.includeShowcase, setIncludeInShowcase]);

  useEffect(() => {
    setShowFiles(values.includeShowcaseFiles);
  }, [values.includeShowcaseFiles]);

  useEffect(() => {
    setShowComments(values.includeShowcaseComments);
  }, [values.includeShowcaseComments, setShowComments]);

  return (
    <>
      <div className={classes.title}>{t('sidebar.options')}</div>

      <ListItem className={classes.listItem}>
        <Switch
          color="primary"
          onChange={toggleIncludeInShowcase}
          checked={includeInShowcase}
          onClick={event => (onClick ? onClick(event) : event.stopPropagation())}
          disabled={disabled}
        />
        <ListItemText classes={{ primary: classes.listItemTextPrimary }}>
          {t('showcase.mySettings.includeInShowcase')}
        </ListItemText>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Switch
          color="primary"
          onChange={toggleComments}
          checked={showComments}
          onClick={event => (onClick ? onClick(event) : event.stopPropagation())}
          disabled={disabled || !includeInShowcase}
        />
        <ListItemText classes={{ primary: classes.listItemTextPrimary }}>
          {t('showcase.mySettings.includeComments')}
        </ListItemText>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Switch
          color="primary"
          onChange={toggleShowFiles}
          checked={showFiles}
          onClick={event => (onClick ? onClick(event) : event.stopPropagation())}
          disabled={disabled || !includeInShowcase}
        />
        <ListItemText classes={{ primary: classes.listItemTextPrimary }}>
          {t('showcase.mySettings.includeFiles')}
        </ListItemText>
      </ListItem>
    </>
  );
};

export default ShowcaseSidebarOptions;
