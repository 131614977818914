import { Moment } from 'moment';

const checkIfEmail = (input: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(input.toLowerCase());
};

const checkIfNumber = (input: string) => {
  const re = /^[\d\s]*$/;
  return re.test(input);
};

const checkIfValidPassword = (input: string) => {
  return input.length >= 10;
};

const validations = (t: any) => {
  return {
    required: (value: string) => (value ? undefined : t('forms.validations.required')),
    requiredDate: (value?: Moment) =>
      value && value.isValid() ? undefined : t('forms.validations.required'),
    isEmail: (required: boolean = true) => (value: string) =>
      value
        ? checkIfEmail(value)
          ? undefined
          : t('forms.validations.email')
        : required
        ? t('forms.validations.required')
        : undefined,
    isNumber: (value: string) => (checkIfNumber(value) ? undefined : t('forms.validations.number')),
    isValidAndUniqueAlternateEmail: (primaryEmail: string) => (value: string) =>
      value
        ? value !== primaryEmail
          ? checkIfEmail(value)
            ? undefined
            : t('forms.validations.email')
          : t('forms.validations.alternateEmailShouldBeUnique')
        : undefined,
    isValidPassWord: (value: string) =>
      value
        ? checkIfValidPassword(value)
          ? undefined
          : t('forms.validations.invalidPassword')
        : t('forms.validations.required'),
    match: (match: string) => (value: string) =>
      value && value === match ? undefined : t('forms.validations.passwordMatch'),
  };
};

export default validations;
