import React, { useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import { User } from '../../../config/api/models';
import BlurDialog from '../../shared/components/BlurDialog';
import ProfilePicture from '../../../components/ProfilePicture';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import security from '../../security';
import EditProfilePictureDialog from './EditProfilePictureDialog';
import useUser from '../../security/hooks/useUser';
import { Actions } from '../../shared/actions';
import { useDispatch } from 'react-redux';

type StyleProps = Props & {
  currentProfilePicture: string;
};

type Props = {
  currentProfilePicture: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  editableProfilePictureRoot: {},
  profilePicture: (props: StyleProps) => ({
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '100%',
    backgroundImage: 'url(' + props.currentProfilePicture + ')',
    backgroundSize: 'cover',
    borderRadius: '100%',
    marginBottom: '1.5rem',
  }),
  editButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: theme.palette.common.white,
    cursor: 'pointer',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 75%,rgba(0,0,0,.5) 75%)',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '1.25rem',
    transitionProperty: 'all',
    transitionDuration: theme.transitions.duration.standard + 'ms',
    transitionTimingFunction: theme.transitions.easing.sharp,
    '&:hover': {
      color: theme.palette.common.white,
      boxShadow: 'inset 0 0 2rem 0 rgba(0,0,0,.65)',
    },
  },
}));

const EditableProfilePicture = ({ currentProfilePicture }: Props) => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const classes = useStyles({ currentProfilePicture });
  const [submitting, setSubmitting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadImageResponse, uploadImage] = useFetch<User>(security.api.ApiConfig.images());
  const [, fetchUser] = useFetch<User>(security.api.ApiConfig.getUser(currentUser.entityId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadImageResponse && uploadImageResponse.fulfilled && submitting) {
      setDialogOpen(false);
      fetchUser();
      setSubmitting(false);
    }
  }, [uploadImageResponse, submitting, fetchUser]);

  const openEditProfilePictureDialog = () => {
    setDialogOpen(true);
  };

  const handleSubmitEditProfilePictureDialog = (file: string) => {
    dispatch(Actions.setIsChangingProfilePicture(true));
    uploadImage({
      photo: file,
    });
    setSubmitting(true);
  };

  const handleCloseEditProfilePictureDialog = () => {
    setDialogOpen(false);
  };

  return (
    <ProfilePicture profilePicture={currentProfilePicture}>
      <div className={classes.editButton} onClick={openEditProfilePictureDialog}>
        <SvgIcon icon={Icons.EDIT} />
      </div>
      <BlurDialog
        title={t('profile.editProfilePictureDialogTitle')}
        open={dialogOpen}
        onClose={handleCloseEditProfilePictureDialog}
      >
        <EditProfilePictureDialog
          onSubmit={handleSubmitEditProfilePictureDialog}
          onClose={handleCloseEditProfilePictureDialog}
          submitting={submitting}
        />
      </BlurDialog>
    </ProfilePicture>
  );
};

export default EditableProfilePicture;
