import React from 'react';
import { Icons } from '../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import useInspirationDialogContext from '../hooks/useInspirationDialogContext';
import Button from '../../../components/Button';

type Props = {
  context: string;
  label?: string;
};

const InspirationSidebarButton = ({ context, label }: Props) => {
  const { t } = useTranslation();
  const { onToggle } = useInspirationDialogContext(context);

  return (
    <div id="inspiration-button">
      <Button onClick={onToggle} icon={Icons.LIGHT_BULB}>
        {label || t('sidebar.inspiration')}
      </Button>
    </div>
  );
};

export default InspirationSidebarButton;
