import React from 'react';
import { Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PaperTopButton from '../../../components/PaperTopButton';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import theme from '../../../config/theme';
import Html from '../../../components/Html';

type Props = {
  onClose: () => void;
  text: string;
  end?: boolean;
  image?: string | undefined;
};

type StyleProps = {
  end?: boolean;
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  textBoxRoot: (props: StyleProps) => ({
    display: 'flex',
    justifyContent: props.end ? (props.isMobile ? '' : 'flex-end') : '',
  }),
  textBox: (props: StyleProps) => ({
    marginTop: props.isMobile ? '5rem' : '',
    width: props.isMobile ? '30rem' : '45rem',
    position: 'relative',
    padding: '2rem',
  }),
  padding: {
    padding: '1rem',
  },
  image: {
    width: '10%',

    '& > img': {
      width: '100%',
    },
  },
}));

const TextBox = ({ onClose, text, end, image }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ end, isMobile });

  return (
    <div className={classes.textBoxRoot}>
      <Paper className={classes.textBox}>
        <PaperTopButton onClick={onClose}>
          <SvgIcon icon={Icons.CANCEL} />
        </PaperTopButton>
        <div className={classes.padding}>
          {image && (
            <div className={classes.image}>
              <img src={image} alt="fdfd" />
            </div>
          )}
          <Html>{text}</Html>
        </div>
      </Paper>
    </div>
  );
};

export default TextBox;
