import React from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import Html from '../../../components/Html';
import { Reflection } from '../../../config/api/models';

type Props = {
  inspiration: Reflection;
};

const useStyles = makeStyles(() => ({
  heading: {
    margin: 0,
    fontSize: '14px',
  },
}));

const InspirationCollapsible = ({ inspiration }: Props) => {
  const classes = useStyles();
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        href="#"
        aria-controls="inspiration-panel"
        id={`header-${inspiration.entityId}`}
        expandIcon={<SvgIcon icon={Icons.CHEVRON_DOWN} />}
      >
        <h2 className={classes.heading}>{inspiration.title}</h2>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Html>{inspiration.body}</Html>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default InspirationCollapsible;
