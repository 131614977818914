import React from 'react';
import { MenuItem, MenuList, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import StickyUserMenuTriangle from './StickyUserMenuTriangle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCompassState, getSelectedFocusId } from '../../myCompass/selectors';
import { CompassState } from '../../myCompass/model';
import { useParams } from 'react-router-dom';

type Props = {
  navItems: React.ReactElement[];
  activeMenuItemIndex?: number;
  color: string;
  showcaseDreamsVisible?: boolean;
  onShowcaseDreamsClick?: () => void;
};

type StyleProps = {
  color: string;
  noBorder?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  userMenuNavigationRoot: {
    marginTop: '3rem',
    borderTop: theme.config.defaultBorder,
    [theme.breakpoints.up('md')]: {
      margin: '3rem -2rem -2rem -2rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% + 4rem)',
      marginLeft: '-2rem',
      marginTop: 0,
    },
    [theme.breakpoints.down('md')]: {
      top: 0,
      zIndex: 999,
      backgroundColor: 'white',
      position: 'sticky',
    },
  },
  list: {
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      borderBottom: theme.config.defaultBorder,
    },
  },
  item: (props: StyleProps) => ({
    padding: '0 2.7rem 0 3rem',
    borderRight: '3px solid transparent',
    '&:hover': {
      backgroundColor: '#FFF',
      borderRight: `3px solid ${theme.palette.grey['200']}`,
      '& .triangle': {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
          display: 'none !important',
          borderRight: theme.config.defaultBorder,
        },
      },
    },
    '&:last-child a': {
      borderBottom: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      padding: 0,
      borderLeft: 'none',
      borderBottom: `3px solid transparent`,
      '&:hover': {
        backgroundColor: '#FFF',
        borderLeft: 'none',
        borderBottom: `3px solid ${props.color}`,
        borderRight: `${theme.config.defaultBorder} !important`,
      },
      '&:not(:last-child)': {
        borderRight: theme.config.defaultBorder,
      },
      '&:last-child:hover': {
        borderRight: theme.config.defaultBorder,
      },
      '&:last-child': {
        borderRight: theme.config.defaultBorder,
      },
      '& > a': {
        display: 'flex',
        width: '100%',
        height: '100%',
      },
    },
  }),
  itemLink: {
    width: '100%',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    minHeight: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 0',
    borderBottom: theme.config.defaultBorder,
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
      justifyContent: 'center',
      padding: 0,
      fontSize: '1.4rem',
    },
  },
  activeItemLink: (props: StyleProps) => ({
    borderRight: `3px solid ${props.color}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: theme.config.defaultBorder,
      '& .triangle': {
        display: 'none !important',
      },
    },
    '&:hover': {
      borderRight: `3px solid ${props.color}`,
      [theme.breakpoints.down('sm')]: {
        borderRight: theme.config.defaultBorder,
      },
    },
  }),
  divider: {
    width: '70%',
    margin: '0 auto',
  },
  stickyUserMenuTriangle: {
    display: 'none',
    position: 'absolute',
    left: '1rem',
    width: '1.25rem',
    transform: 'rotate(90deg)',
  },
  level0: {},
  level1: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '1rem',
    },
  },
  level2: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2rem',
    },
  },
}));

const Navigation = ({ navItems, activeMenuItemIndex, color }: Props) => {
  const classes = useStyles({ color });
  const { t } = useTranslation();
  const selectedFocusId = useSelector(getSelectedFocusId);
  const compassState = useSelector(getCompassState);
  const { dreamId } = useParams();

  const getNavLevel = (label: string): 'level0' | 'level1' | 'level2' => {
    if (label === t('dream.menuItem')) {
      return 'level0';
    } else if (label === t('focus.menuItem')) {
      return 'level1';
    }
    return 'level2';
  };

  const navigationMenu = (
    <>
      {navItems.map((navItem, index) => {
        return (
          <React.Fragment key={index}>
            <MenuItem
              className={clsx(
                classes.item,
                activeMenuItemIndex === index && classes.activeItemLink
              )}
            >
              <StickyUserMenuTriangle
                className={clsx('triangle', classes.stickyUserMenuTriangle)}
                color={color}
                isActive={activeMenuItemIndex === index}
              />
              {React.cloneElement(navItem, {
                className: clsx(classes.itemLink, classes[getNavLevel(navItem.props.children)]),
              })}
            </MenuItem>
          </React.Fragment>
        );
      })}
    </>
  );

  return (
    <div className={classes.userMenuNavigationRoot}>
      <MenuList className={classes.list}>
        {compassState === CompassState.SHOWCASE ? (
          <>{!!selectedFocusId && <>{navigationMenu}</>}</>
        ) : (
          <>{!!dreamId && !!selectedFocusId && <>{navigationMenu}</>}</>
        )}
      </MenuList>
    </div>
  );
};

export default Navigation;
