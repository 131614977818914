import React from 'react';
import { Link, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Logo from '../../../components/Logo';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';

const useStyles = makeStyles((theme: Theme) => ({
  sectionOneRoot: {
    marginTop: '-2rem',
    paddingTop: '6rem',
    paddingBottom: '6rem',
    marginLeft: '-3000px',
    paddingLeft: '3000px',
    marginRight: '-3000px',
    paddingRight: '3000px',
    backgroundColor: theme.palette.background.paper,
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 65%)',
  },
  sectionOneText: {
    marginLeft: '1.5rem',
  },
}));

const SectionOne = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getPath } = useI18nPath();

  return (
    <div className={classes.sectionOneRoot}>
      <Typography variant="body2" className={classes.sectionOneText}>
        {t('coaching.sectionOne.text')}
      </Typography>
      <Link href={getPath('myCompass')}>
        <Logo />
      </Link>
    </div>
  );
};

export default SectionOne;
