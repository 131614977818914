import React from 'react';
import BasicHtml from '../container/BasicHtml';
import { RouteComponentProps } from 'react-router';
import ContentBackground from '../../../components/ContentBackground';
import { makeStyles } from '@material-ui/styles';
import { AppBar, useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';
import Footer from '../../../components/Footer';
import { Link } from 'react-router-dom';
import Logo from '../../../components/Logo';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import LanguageSwitcher from '../container/LanguageSwitcher';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  test: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  basicPageRoot: (props: StyleProps) => ({
    position: 'relative',
    maxWidth: props.isMobile ? '100%' : '120rem',
    width: '100%',
    minHeight: props.isMobile ? 'calc(100% - 12rem)' : 'calc(100% - 8rem)',
    margin: '0 auto',
    padding: props.isMobile ? '15rem 2rem 2rem 2rem' : '15rem',
  }),
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    overflow: 'hidden',
  },
  appBarRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.config.defaultBorder,
    borderRadius: 0,
    boxShadow: 'none',
    padding: 0,
    height: '7rem',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  logo: {
    marginLeft: '2rem',
    display: 'flex',
    maxHeight: '100%',
    overflow: 'hidden',
    alignItems: 'center',
  },
  rightContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: '1.5rem',
    },
  },
}));

const BasicPage = (props: RouteComponentProps) => {
  const splitURL: string[] = props.match.url.split('/');
  const pageId: string = splitURL[splitURL.length - 1];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const { getPath } = useI18nPath();
  const currentPath = window.location.pathname;

  return (
    <>
      {currentPath !== '/nl/over-dromen' && (
        <AppBar
          classes={{
            root: classes.appBarRoot,
          }}
        >
          <div className={classes.logo}>
            <Link to={getPath('login')}>
              <Logo />
            </Link>
          </div>
          <div className={classes.rightContent}>
            <LanguageSwitcher />
          </div>
        </AppBar>
      )}
      <div className={classes.test}>
        <div className={classes.basicPageRoot}>
          <div className={classes.contentBackground}>
            <ContentBackground />
          </div>
          <BasicHtml pageId={pageId} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BasicPage;
