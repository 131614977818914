import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import { Icons } from '../../../components/SvgIcon';
import Button from '../../../components/Button';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import SectionTitle from '../../../components/SectionTitle';
import Paragraph from '../../../components/Paragraph';
import Dialog from '../../../components/Dialog';
import { InspirationContext, ReflectionsResponse } from '../../../config/api/models';
import ModalBodyAlt from '../../../components/ModalBodyAlt';
import useInspirationDialogContext from '../hooks/useInspirationDialogContext';
import { makeStyles } from '@material-ui/styles';
import InspirationCollapsible from './InspirationCollapsible';
import Html from '../../../components/Html';
import { default as themeConfig } from '../../../config/theme';
import MobileDialogFormTabs from '../components/MobileDialogFormTabs';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';

type Props = {
  inspirationKey: InspirationContext;
  children: React.ReactChild | React.ReactChild[];
  showInspiration?: boolean;
  editing?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  scrollSeperate: {
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      overflowY: 'initial',
    },
  },
  inspirationWrapper: {
    width: '100%',
    height: '100%',
    margin: 0,
  },
  inspirationTitle: {
    '& > div:first-child': {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
  },
  heading: {
    margin: 0,
    fontSize: '14px',
  },
}));

const InspirationFormDialog = ({
  children,
  onClose,
  onSubmit,
  showInspiration,
  inspirationKey,
  editing,
}: Props) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const { isOpen, onToggle } = useInspirationDialogContext('InspirationFormDialog');
  const [inspirationFetch, fetchInspiration] = useFetch<ReflectionsResponse>(
    ApiConfig.getInspiration(inspirationKey, currentLanguage)
  );
  const isMobile = useMediaQuery(themeConfig.breakpoints.down('sm'));

  const isPending = inspirationFetch && inspirationFetch.pending;
  const value = inspirationFetch && inspirationFetch.value;
  const classes = useStyles();

  useEffect(() => {
    if (isOpen && !isPending && !value) {
      fetchInspiration();
    }
  }, [isPending, value, fetchInspiration, isOpen]);

  const finalIsOpen = typeof showInspiration !== 'undefined' ? showInspiration : isOpen;
  const finalOnToggle = typeof onClose !== 'undefined' ? onClose : onToggle;
  const finalOnSubmit = onSubmit ? onSubmit : onToggle;

  const handleSubmit = () => {
    finalOnToggle();
    finalOnSubmit();
  };

  const dialogActions = (
    <>
      {editing && (
        <Button onClick={handleSubmit} type="submit" icon={Icons.SAVE}>
          {t('forms.buttons.save')}
        </Button>
      )}
      <Button onClick={finalOnToggle} color="secondary" icon={Icons.CANCEL}>
        {t('forms.buttons.close')}
      </Button>
    </>
  );

  const contextComponent = (
    <Grid item md={6} className={classes.scrollSeperate}>
      {children}
    </Grid>
  );

  const inspirationComponent = (
    <Grid item md={6} className={classes.scrollSeperate}>
      <div className={classes.inspirationTitle}>
        <SectionTitle label={t('inspiration.title')} />
      </div>
      <Paragraph>{t('inspiration.body')}</Paragraph>
      {value && value.data.length > 0 ? (
        <ModalBodyAlt>
          {value.data.length === 1 ? (
            <Html>{value.data[0].attributes.body}</Html>
          ) : (
            <>
              {value.data.map(it => (
                <InspirationCollapsible inspiration={it.attributes} key={it.id} />
              ))}
            </>
          )}
        </ModalBodyAlt>
      ) : null}
    </Grid>
  );

  return (
    <>
      {children}

      {finalIsOpen && (
        <Dialog open={true} actions={dialogActions} fullScreen onClose={finalOnToggle}>
          {isMobile ? (
            <MobileDialogFormTabs inspiration={inspirationComponent} context={contextComponent} />
          ) : (
            <Grid container spacing={4} className={classes.inspirationWrapper}>
              {contextComponent}
              {inspirationComponent}
            </Grid>
          )}
        </Dialog>
      )}
    </>
  );
};

export default InspirationFormDialog;
