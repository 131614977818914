import apiRoutes from '../../config/api/routes';
import security from '../security';
import { UpdateUserRequest, UserId } from '../../config/api/models';
import memoizeOne from 'memoize-one';

export const ApiConfig = {
  updateUser: memoizeOne((userId: UserId) => ({
    resource: security.api.RESOURCE.USER,
    method: 'PATCH',
    request: (updateUserDTO: UpdateUserRequest) => ({
      url: apiRoutes.user(userId),
      body: {
        data: {
          type: 'users',
          id: userId.toString(),
          attributes: updateUserDTO,
        },
      },
    }),
  })),
};
