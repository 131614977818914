import React, { useState } from 'react';
import { Theme, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { User } from '../config/api/models';
import GreyStripes from '../assets/grey-stripes.png';
import ProfilePicture from './ProfilePicture';
import DefaultProfilePicture from './DefaultProfilePicture';
import { getUserDisplayName } from '../helpers/getUserDisplayName';
import clsx from 'clsx';
import SvgIcon, { Icons } from './SvgIcon';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './ConfirmationModal';
import useI18nPath from '../config/clientRoutes/useI18nPath';
import UserFlyout from './UserFlyout';
import { useCompassUser } from '../modules/shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../modules/myCompass/selectors';
import { getMilaUserId } from '../modules/shared/selectors';

type Props = {
  peer: User;
  showCompass: boolean;
  notAccepted: boolean;
  onRemove: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  peerCardRoot: {
    width: '25rem',
    border: theme.config.defaultBorder,
    color: theme.palette.grey['500'],
    textAlign: 'center',
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: '2rem',
    paddingBottom: '1.5rem',
  },
  pictureContainer: {
    width: '60%',
    margin: 'auto',
  },
  peerCardNotAccepted: {
    backgroundImage: `url(${GreyStripes})`,
    backgroundRepeat: 'repeat',
  },
  infobox: {
    '& > p': {
      margin: '0.5rem 0',
      wordBreak: 'break-word',
    },
  },
  pill: {
    borderRadius: theme.shape.borderRadius * 4,
    border: theme.config.defaultBorder,
    backgroundColor: theme.palette.common.white,
    padding: '.5rem 1rem',
    margin: '.75rem 0 .25rem 0',
    display: 'inline-block',
    fontSize: '.95rem',
    color: theme.palette.text.primary,
    height: 'fit-content',
  },
  name: {
    fontSize: '1.4rem',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  function: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
  actionButtons: {
    borderTop: theme.config.defaultBorder,
    display: 'flex',
    height: '4rem',
    justifyContent: 'space-evenly',
  },
  link: {
    textAlign: 'center',
    lineHeight: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey['100'],
    },
  },
  compassLink: {
    borderLeft: theme.config.defaultBorder,
    color: 'inherit',
    textDecoration: 'none',
  },
  buttonText: {
    marginRight: '0.5rem',
  },
}));

const PeerCard = ({ peer, showCompass, notAccepted, onRemove }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { getPath } = useI18nPath();

  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;

  const occupation =
    peer.department && peer.function
      ? `${peer.department.name} - ${peer.function}`
      : peer.department
      ? peer.department.name
      : peer.function
      ? peer.function
      : '';

  const modalTitle = notAccepted
    ? t('peers.relationship.cancelConfirm')
    : t('peers.relationship.endConfirm');
  const modalText = notAccepted
    ? t('peers.relationship.cancelModalText')
    : t('peers.relationship.endModalText');

  const handleRemove = () => {
    setIsModalOpen(false);
    onRemove();
  };

  return (
    <Paper className={classes.peerCardRoot}>
      <div className={clsx(classes.content, notAccepted ? classes.peerCardNotAccepted : null)}>
        <div className={classes.pictureContainer}>
          <UserFlyout user={peer}>
            {peer.media && peer.media.profile ? (
              <ProfilePicture profilePicture={peer.media.profile} />
            ) : (
              <DefaultProfilePicture />
            )}
          </UserFlyout>
        </div>
        <div className={classes.infobox}>
          <p className={classes.name}>{getUserDisplayName(peer, true)}</p>
          {!!occupation && <p className={classes.function}>{occupation}</p>}
          {notAccepted ? <span className={classes.pill}>{t('peers.notAccepted')}</span> : null}
        </div>
      </div>
      <div className={classes.actionButtons}>
        {(!isCompassUser || isMila) && (
          <span className={classes.link} onClick={isMila ? undefined : () => setIsModalOpen(true)}>
            <p className={classes.buttonText}>{t('peers.remove')}</p>
            <SvgIcon icon={Icons.CANCEL} />
          </span>
        )}
        {isMila ? (
          <span className={clsx(classes.link, classes.compassLink)}>
            <p className={classes.buttonText}>{t('peers.compass')}</p>
            <SvgIcon icon={Icons.COMPASS} />
          </span>
        ) : (
          <>
            {showCompass && !notAccepted && (
              <a
                href={getPath('compassPage', peer.entityId).replace('/:userId', '')}
                target="_blank"
                rel="noopener noreferrer"
                className={clsx(classes.link, classes.compassLink)}
              >
                <p className={classes.buttonText}>{t('peers.compass')}</p>
                <SvgIcon icon={Icons.COMPASS} />
              </a>
            )}
          </>
        )}
      </div>

      <ConfirmationModal
        open={isModalOpen}
        title={modalTitle}
        text={modalText}
        confirmButtonText={t('common.yes')}
        confirmButtonIcon={Icons.SAVE}
        onConfirm={handleRemove}
        cancelButtonText={t('common.no')}
        cancelButtonIcon={Icons.CANCEL}
        onCancel={() => setIsModalOpen(false)}
      />
    </Paper>
  );
};

export default PeerCard;
