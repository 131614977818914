import { Middleware } from 'redux';
import Echo from 'laravel-echo';
import apiRoutes from '../../../config/api/routes';
import { sleep } from '../../../helpers/sleep';
import { ChatSocketNotification } from '../../../config/api/models';
import { Actions } from '../actions';
// @ts-ignore
window.io = require('socket.io-client');

const socketKey = process.env.REACT_APP_SOCKET_KEY;

const echo = new Echo({
  host: apiRoutes.socket(),
  broadcaster: 'socket.io',
  key: socketKey,
  auth: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
  debug: true,
});

const handleSocket: Middleware = store => next => async action => {
  next(action);

  if (
    action.type === 'persist/REHYDRATE' ||
    (action.type === 'react-redux-fetch/POST_FULFIL' &&
      action.resource &&
      action.resource.name === 'login')
  ) {
    if (action.payload || action.value) {
      const token = action.payload ? action.payload.auth.token : action.value.access_token;
      const userId = action.payload ? action.payload.auth.userId : action.value.id;

      if (userId && token) {
        echo.connector.options.auth.headers.Authorization = `Bearer ${token}`;
        echo.connector.options.auth.headers.userId = +userId;
        while (!echo.connector.socket.connected) {
          await sleep(500);
        }
        echo
          .private(`App.User.${userId}`)
          .notification((value: ChatSocketNotification) =>
            store.dispatch(Actions.addNotification(value))
          );
      }
    }
  }
};

export default handleSocket;
