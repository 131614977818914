import { container } from 'react-redux-fetch';
import apiRoutes from '../../../../config/api/routes';
import { downloadImage } from '../../../../helpers/downloadImage';
import resizeImage from '../../../../helpers/resizeImage';
import FileUploader from './FileUploader';

type ImageUploadValue = {
  [size: string]: string;
  default: string;
};
type ResponseHandler = (response: Response) => ImageUploadValue;

const MAX_WIDTH = 1200;
const MAX_HEIGHT = 1200;
const QUALITY = 0.8;
const requestBuilder = container.getDefinition('requestBuilder').getArgument('build');
const handleResponse: ResponseHandler = container
  .getDefinition('utils')
  .getArgument('handleResponse');
let uploading = false;

const stallTime = 1050;

class ImageUploader extends FileUploader {
  private readonly initialRetryTimeout: number = 100;
  private retryTimeout: number = this.initialRetryTimeout;
  private retryRatio: number = 1.5;
  private maxRetryTimeout: number = 5000;

  upload(onUploaded: () => void) {
    this.retryTimeout = this.initialRetryTimeout;
    const reader = new FileReader();

    reader.onload = async () => {
      const photo = reader.result as string;

      while (uploading) {
        await this.stallLoading();
      }
      uploading = true;
      resizeImage(
        photo,
        { maxWidth: MAX_WIDTH, maxHeight: MAX_HEIGHT, quality: QUALITY },
        resizedPhoto => {
          fetch(
            requestBuilder(apiRoutes.inlineImages(), {
              method: 'post',
              body: { photo: resizedPhoto },
            })
          )
            .then(handleResponse)
            .then(this.waitForImageProcessing(this.resolve, onUploaded), this.reject)
            .catch(this.reject);
        }
      );
    };
    reader.readAsDataURL(this.file);
  }

  waitForImageProcessing(resolve: (value?: unknown) => void, onUploaded: () => void) {
    uploading = false;
    // TODO: max tries?
    const tryDownload = (value: ImageUploadValue) => {
      setTimeout(
        () =>
          downloadImage(
            value.default,
            () => {
              onUploaded();
              resolve(value);
            },
            () => {
              this.retryTimeout = Math.min(
                this.retryTimeout * this.retryRatio,
                this.maxRetryTimeout
              );
              tryDownload(value);
            },
            false,
            { convertToBase64: false }
          ),
        this.retryTimeout
      );
    };

    return tryDownload;
  }

  stallLoading() {
    return new Promise(resolve => setTimeout(resolve, stallTime));
  }
}
export default ImageUploader;
