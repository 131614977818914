// When inserting media (e.g. youtube link or image), the media element is selected.
// This causes the media element to be removed when the user immediately starts typing.
// To fix this, the following function triggers an "Enter" keyboard event, effectively adding a paragraph after the media file.

function triggerEnterEvent(el: Element | null) {
  if (!el) {
    return;
  }

  el.dispatchEvent(
    new KeyboardEvent('keydown', {
      view: window,
      // @ts-ignore
      keyCode: 13,
      bubbles: true,
      cancelable: true,
    })
  );
}

const insertEnterAfterMedia = (e: any, d: any) => {
  try {
    const operation = d.operations[d.operations.length - 1];
    if (operation.nodes) {
      const selector =
        operation.nodes.getNode(0).name === 'imageBlock' ||
        operation.nodes.getNode(0).name === 'caption'
          ? '.image.ck-widget_selected'
          : null;

      if (selector) {
        setTimeout(() => {
          const el = document.querySelector(selector);
          triggerEnterEvent(el);
        }, 500);
      }
    }
  } catch (e) {
    //
  }
};

export default insertEnterAfterMedia;
