import React from 'react';
import ProfilePicture from '../../../components/ProfilePicture';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Comment as CommentType, UserId } from '../../../config/api/models';
import Html from '../../../components/Html';
import { getUserDisplayName } from '../../../helpers/getUserDisplayName';
import Tooltip from '../../../components/Tooltip';
import Moment from 'moment';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import PaperTopButton from '../../../components/PaperTopButton';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  comment: CommentType;
  userLabel?: string;
  currentUserEntityId?: UserId;
  onClickEditComment?: (comment: CommentType) => void;
  onClickDeleteComment?: (comment: CommentType) => void;
};

type StyleProps = {
  canEdit: boolean;
  deletedAt: string | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  commentRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '1rem',
  },
  profilePicture: {
    width: '4rem',
    flexShrink: 0,
    '& div': {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  commentBody: {
    flexGrow: 1,
  },
  userName: {
    fontWeight: 'bold',
    paddingLeft: '1rem',
  },
  commentText: ({ canEdit, deletedAt }: StyleProps) => ({
    borderRadius: theme.shape.borderRadius,
    padding: '1.5rem',
    paddingRight: canEdit && deletedAt == null ? '5.5rem' : '1.5rem',
    marginLeft: '1rem',
    backgroundColor: deletedAt == null ? theme.palette.background.default : '#fff',
    border: deletedAt == null ? 'none' : `0.1rem dashed ${theme.palette.grey[300]}`,
    position: 'relative',
    wordBreak: 'break-word',
    display: 'flex',
    flexDirection: 'column',
    '&:before': {
      width: '0',
      height: '0',
      borderTop: '.8rem solid transparent',
      borderBottom: '.8rem solid transparent',
      borderRight: `0.6rem solid ${theme.palette.background.default}`,
      content: deletedAt == null ? '""' : 'none',
      position: 'absolute',
      top: '0.6rem',
      left: '-0.6rem',
    },
  }),
  updatedAt: ({ canEdit, deletedAt }: StyleProps) => ({
    alignSelf: 'flex-end',
    fontSize: '1.2rem',
    color: theme.palette.grey[400],
    marginTop: '1rem',
    marginBottom: '-1rem',
    marginRight: deletedAt == null && canEdit ? '-4rem' : '0',
  }),
}));

const Comment = ({
  comment,
  userLabel,
  currentUserEntityId,
  onClickEditComment,
  onClickDeleteComment,
}: Props) => {
  const canEdit = comment.author.entityId === currentUserEntityId;

  const classes = useStyles({ canEdit, deletedAt: comment.deletedAt });
  const lang = useCurrentLanguage();
  const { t } = useTranslation();

  Moment.locale(lang);
  const now = Moment();
  const then = comment.createdAt;

  const ms = Moment(now).diff(Moment(then));
  const d = Moment.duration(ms);

  return (
    <div className={classes.commentRoot}>
      <div className={classes.profilePicture}>
        <ProfilePicture profilePicture={comment.author.media.profile} label={userLabel} />
      </div>
      <div className={classes.commentBody}>
        <div className={classes.userName}>{getUserDisplayName(comment.author, true)}</div>
        <Tooltip
          title={
            comment.deletedAt == null
              ? d.days() >= 1
                ? Moment(comment.createdAt).format('DD MMMM YYYY HH:mm:ss')
                : Moment(comment.createdAt).fromNow()
              : t('comments.deletedAt').replace('%DATE%', moment(comment.deletedAt).format('L'))
          }
          placement="top"
        >
          <div className={classes.commentText}>
            <Html>
              {comment.deletedAt == null ? comment.body : t('comments.deletedCommentBody')}
            </Html>
            {canEdit && comment.deletedAt == null && (
              <>
                <PaperTopButton
                  onClick={() => onClickEditComment && onClickEditComment(comment)}
                  style={{ right: '4rem' }}
                >
                  <SvgIcon icon={Icons.EDIT} />
                </PaperTopButton>
                <PaperTopButton
                  onClick={() => onClickDeleteComment && onClickDeleteComment(comment)}
                >
                  <SvgIcon icon={Icons.TRASH} />
                </PaperTopButton>
              </>
            )}
            {comment.deletedAt == null ? (
              comment.createdAt !== comment.updatedAt && (
                <Typography className={classes.updatedAt}>
                  {t('comments.updatedAt').replace('%DATE%', moment(comment.updatedAt).format('L'))}
                </Typography>
              )
            ) : (
              <Typography className={classes.updatedAt}>
                {t('comments.deletedAt').replace('%DATE%', moment(comment.deletedAt).format('L'))}
              </Typography>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Comment;
