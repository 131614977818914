import * as React from 'react';
import { useState } from 'react';
import InspirationDialogContext from '../context/InspirationDialogContext';

type Props = {
  children: React.ReactNode;
};

const InspirationDialogProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <InspirationDialogContext.Provider value={{ isOpen, onToggle: handleToggle }}>
      {children}
    </InspirationDialogContext.Provider>
  );
};

export default InspirationDialogProvider;
