import { container, reducer as fetchReducer } from 'react-redux-fetch';
import { AnyAction, combineReducers } from 'redux';
import createListReducer from '../../helpers/listReducer';
import { RootState } from '../../config/store/types';
import security from '../security';
import shared from '../shared';
import myCompass from '../myCompass';
import { RESOURCE } from '../myCompass/api';
import { RESOURCE as TALENT_RESOURCE } from '../myTalents/api';
import { RESOURCE as SHARED_RESOURCE } from '../shared/api';
import { RESOURCE as COACH_RESOURCE } from '../myCoach/api';
import { RESOURCE as SHOWCASE_RESOURCE } from '../myShowcase/api';
import myCoach from '../myCoach';
import myShowcase from '../myShowcase';

// prettier-ignore
container.registerReducer(RESOURCE.LEARNINGS, createListReducer({ itemResourceName: RESOURCE.LEARN }));
// prettier-ignore
container.registerReducer(RESOURCE.PLANS, createListReducer({ itemResourceName: RESOURCE.PLAN }));
// prettier-ignore
container.registerReducer(TALENT_RESOURCE.TALENTS, createListReducer({ itemResourceName: TALENT_RESOURCE.TALENT }));
// prettier-ignore
container.registerReducer(SHARED_RESOURCE.COACH_RELATIONS, createListReducer({ itemResourceName: SHARED_RESOURCE.COACH_RELATION }));
// prettier-ignore
container.registerReducer(SHARED_RESOURCE.PEER_RELATIONS, createListReducer({ itemResourceName: SHARED_RESOURCE.PEER_RELATION }));
// prettier-ignore
container.registerReducer(COACH_RESOURCE.NOTES, createListReducer({itemResourceName: COACH_RESOURCE.NOTE}));
// prettier-ignore
container.registerReducer(SHARED_RESOURCE.POSTS, createListReducer({ itemResourceName: SHARED_RESOURCE.POST }));
// prettier-ignore
container.registerReducer(RESOURCE.MILESTONES, createListReducer({ itemResourceName: RESOURCE.MILESTONE }));
// prettier-ignore
container.registerReducer(SHOWCASE_RESOURCE.SHOWCASES, createListReducer({itemResourceName: SHOWCASE_RESOURCE.SHOWCASE}));

const combinedReducers = combineReducers<RootState>({
  repository: fetchReducer,
  auth: security.reducer,
  shared: shared.reducer,
  myCompass: myCompass.reducer,
  myCoach: myCoach.reducer,
  myShowcase: myShowcase.reducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === security.actionTypes.LOGGED_OUT) {
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export default rootReducer;
