import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icons } from './SvgIcon';

type Props = {
  children: string;
  home?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  htmlRoot: (props: Props) => ({
    display: 'block',
    clear: 'both',
    overflow: props.home ? '' : 'hidden',
    '&': theme.config.ckEditor.htmlStyles,
    wordBreak: 'break-word',
    width: '100%',
  }),
}));

const Html = (props: Props) => {
  const { children, home = false } = props;
  const classes = useStyles({ children, home });

  const childrenWithIcons = setIcons(children);

  return (
    <span className={classes.htmlRoot} dangerouslySetInnerHTML={{ __html: childrenWithIcons }} />
  );
};

export default Html;

const setIcons = (originalString: string) => {
  const iconRegex = /%%(.*?)%%/g;
  let newString = originalString;
  const match = originalString.match(iconRegex);
  if (match) {
    match.forEach(m => {
      const icon = m.replace(/%/g, '');
      const iconElement = `<svg width="1.6rem" height="1.6rem"><path d="${
        Icons[icon as any]
      }"/></svg>`;
      newString = newString.replace(m, iconElement);
    });
  }
  return newString;
};
