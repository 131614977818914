import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import apiRoutes from '../api/routes';
import { Language } from './types';

import 'moment/locale/nl';
import 'moment/locale/fr';

export const fallbackLng: Language = 'nl';

const initI18n = () => {
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(XHR)
    .init({
      debug: false,
      lng: window.location.pathname.startsWith('/fr')
        ? 'fr'
        : window.location.pathname.startsWith('/en')
        ? 'en'
        : 'nl',
      fallbackLng: 'nl',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: apiRoutes.translations,
      },
    });
};

export default initI18n;
