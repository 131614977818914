import React from 'react';
import TalentForm from './TalentForm';
import { ApiConfig } from '../api';
import useFetch from '../../../helpers/useFetch';
import { Talent, TalentId } from '../../../config/api/models';

type Props = {
  onClose?: () => void;
  talent: Talent;
  talentId: TalentId;
};

const UpdateTalentForm = ({ onClose, talent, talentId }: Props) => {
  const [updateTalentResponse, updateTalent] = useFetch(ApiConfig.updateTalent());

  return (
    <TalentForm
      onSave={talentRequest => updateTalent(talentId, talentRequest)}
      onClose={onClose}
      onSubmit={onClose}
      talent={updateTalentResponse}
      initialValues={{ title: talent.title, body: talent.body }}
      visibility={talent.visibility}
    />
  );
};

export default UpdateTalentForm;
