import React, { SetStateAction } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Theme, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const usePageTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  wrapper: {
    top: '11.9rem',
    height: 'calc(100vh - 11.9rem)',
  },
  tabHeader: {
    backgroundColor: 'white',
    borderBottom: theme.config.defaultBorder,
    width: '100%',
    position: 'absolute',
    top: '7rem',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: 0,
    },
  },
  tabs: {
    padding: '0 2rem',
    maxWidth: '140rem',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

type Props<TValue> = {
  selectedTab: TValue;
  onSelectTab: (tab: SetStateAction<TValue>) => void;
  children: React.ReactChild | React.ReactChild[];
};
const PageTabs = <TValue extends string | number>({
  selectedTab,
  onSelectTab,
  children,
}: Props<TValue>) => {
  const theme: Theme = useTheme();
  const classes = usePageTabsStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.tabHeader}>
      <Tabs
        onChange={(ev, val: TValue) => onSelectTab(val)}
        value={selectedTab}
        className={classes.tabs}
        variant={isMobile ? 'fullWidth' : 'standard'}
      >
        {children}
      </Tabs>
    </div>
  );
};

export default PageTabs;
