import React, { useState } from 'react';
import { Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as SharedApiConfig } from '../api';
import useUser from '../../security/hooks/useUser';
import Joyride, { ACTIONS, Placement, STATUS } from 'react-joyride';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getMilaUserId } from '../selectors';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';

let userPatched: boolean = false;

const MyTalentsTour = () => {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const [showTour, setShowTour] = useState(true);
  const [tourDone, setTourDone] = useState(false);
  const [, patchUser] = useFetch<UserResponse>(SharedApiConfig.updateTourDone());
  const currentUser = useUser();
  const milaUserId = useSelector(getMilaUserId);

  const steps = [
    {
      target: '#talents-section',
      content: t('tour.steps.myTalents.first'),
      disableBeacon: true,
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#add-talent',
      content: t('tour.steps.myTalents.second'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = (data: any) => {
    const { status, action } = data;
    if (
      [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
    ) {
      if (!userPatched) {
        const userUpdate = {
          talentsTourDone: 1,
        };
        patchUser(currentUser.entityId, userUpdate);
        userPatched = true;
      }
      setShowTour(false);
    }
    if (status === STATUS.FINISHED) {
      setTourDone(true);
    }
  };

  return (
    <>
      <Joyride
        steps={steps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        showProgress
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.milaTalents'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      {tourDone && <Redirect to={getPath('talentsPage').replace(':userId', milaUserId)} />}
    </>
  );
};

export default MyTalentsTour;
