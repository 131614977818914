import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import HomePageSection from '../../../components/HomePageSection';
import QA from '../../../components/QA';
import AnswerContent from './AnswerContent';
import Simon from '../../../assets/Simon.jpg';
import Brecht from '../../../assets/Brecht.jpg';
import Carlin from '../../../assets/Carlin.jpg';
import Ellen from '../../../assets/Ellen.jpg';
import Tom from '../../../assets/Tom.jpg';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  sectionSixRoot: (props: StyleProps) => ({
    marginTop: props.isMobile ? '-27rem' : '-10rem',
  }),
}));

const SectionSix = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  return (
    <div className={classes.sectionSixRoot}>
      <HomePageSection title={t('home.sectionSix.title')} />
      <QA num={1} question={t('home.sectionSix.question1.question')} expanded>
        <AnswerContent
          text={t('home.sectionSix.question1.answer')}
          quotedText={t('home.sectionSix.question1.quote')}
          name={t('home.sectionSix.question1.person')}
          func={t('home.sectionSix.question1.function')}
          alt={t('home.sectionSix.question1.person')}
          image={Brecht}
        />
      </QA>
      <QA num={2} question={t('home.sectionSix.question2.question')}>
        <AnswerContent
          text={t('home.sectionSix.question2.answer')}
          quotedText={t('home.sectionSix.question2.quote')}
          name={t('home.sectionSix.question2.person')}
          func={t('home.sectionSix.question2.function')}
          alt={t('home.sectionSix.question2.person')}
          image={Ellen}
        />
      </QA>
      <QA num={3} question={t('home.sectionSix.question3.question')}>
        <AnswerContent
          text={t('home.sectionSix.question3.answer')}
          quotedText={t('home.sectionSix.question3.quote')}
          name={t('home.sectionSix.question3.person')}
          func={t('home.sectionSix.question3.function')}
          alt={t('home.sectionSix.question3.person')}
          image={Carlin}
        />
      </QA>
      <QA num={4} question={t('home.sectionSix.question4.question')}>
        <AnswerContent
          text={t('home.sectionSix.question4.answer')}
          quotedText={t('home.sectionSix.question4.quote')}
          name={t('home.sectionSix.question4.person')}
          func={t('home.sectionSix.question4.function')}
          alt={t('home.sectionSix.question4.person')}
          image={Tom}
        />
      </QA>
      <QA num={5} question={t('home.sectionSix.question5.question')}>
        <AnswerContent
          text={t('home.sectionSix.question5.answer')}
          quotedText={t('home.sectionSix.question5.quote')}
          name={t('home.sectionSix.question5.person')}
          func={t('home.sectionSix.question5.function')}
          alt={t('home.sectionSix.question5.person')}
          image={Simon}
        />
      </QA>
    </div>
  );
};

export default SectionSix;
