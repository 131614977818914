import React, { useEffect, useState } from 'react';
import inspiration from '../../../inspiration';
import Sidebar from '../../../../components/Sidebar';
import EditablePanel from '../../../../components/EditablePanel';
import {
  DreamId,
  FilesResponse,
  InspirationContext,
  LookBack,
  LookBackId,
  LookBackRequest,
  Post,
  PostsResponse,
} from '../../../../config/api/models';
import ContentBlock from '../../components/ContentBlock';
import UpdateLookBackForm from './UpdateLookBackForm';
import ReactContentLoader from '../../../../components/Loader';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import { ApiConfig as SharedApiConfig } from '../../../shared/api';
import FileList from '../../../shared/components/FileList';
import ContentVisibilityButton from '../../../../components/ContentVisibilityButton';
import SidebarSharing from '../../../shared/components/SidebarSharing';
import useUser from '../../../security/hooks/useUser';
import SharingDialog, { SharingDialogState } from '../../../shared/components/SharingDialog';
import CommunicationWrapper from '../../components/CommunicationWrapper';
import { useSelector } from 'react-redux';
import {
  getCompassState,
  getCompassUserId,
  getDreamByDreamId,
  getSelectedFocusResponse,
} from '../../selectors';
import { CompassState } from '../../model';
import ShowcaseSidebarOptions, {
  ShowcaseSidebarValues,
} from '../../../myShowcase/components/ShowcaseSidebarOptions';
import { useParams } from 'react-router-dom';
import { getMilaUserId } from '../../../shared/selectors';
import { Box } from '@material-ui/core';
import DateTimeMessage from '../../../shared/components/DateTimeMessage';

type Props = {
  lookBack?: LookBack;
  lookBackId?: LookBackId;
  isLoading: boolean;
};

const UpdateLookBack = ({ lookBack, lookBackId, isLoading }: Props) => {
  const modelType = 'lookback';
  const { dreamId } = useParams();

  const [, patchLookback] = useFetch(ApiConfig.updateLookBack());
  const [postRequest] = useFetch<PostsResponse>(SharedApiConfig.posts());
  const [, removePost] = useFetch<PostsResponse>(SharedApiConfig.removePost());
  const [filesResponse, fetchFile] = useFetch<FilesResponse>(
    ApiConfig.loadFile(modelType, lookBackId ? lookBackId : '')
  );
  const focusResponse = useSelector(getSelectedFocusResponse);
  const dreamResponse = useSelector(getDreamByDreamId(dreamId as DreamId));
  const compassState = useSelector(getCompassState);
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);

  const currentUser = useUser();
  const [currentPost, setCurrentPost] = useState<Post>();
  const [sharingDialog, setSharingDialogState] = useState<undefined | SharingDialogState>();

  useEffect(() => {
    if (!filesResponse) {
      fetchFile();
    }
  }, [filesResponse, fetchFile]);

  const hashValue = window.location.hash.substr(1);
  if (hashValue) {
    const anchor = document.getElementById(hashValue);

    if (anchor) {
      anchor.scrollIntoView();
    }
  }

  useEffect(() => {
    if (postRequest && postRequest.value) {
      const posts = postRequest.value.data.map(d => d.attributes);
      const current = posts.find(
        p =>
          p.postId === lookBackId &&
          p.postType === 'lookback' &&
          p.authorId === currentUser.entityId
      );
      setCurrentPost(current);
    }
  }, [postRequest, setCurrentPost, currentUser, lookBackId]);

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const lookbackDTO: Partial<LookBackRequest> = {
      visibility: !checked,
    };
    patchLookback(lookBackId, lookbackDTO);
    if (currentPost && checked) {
      removePost(currentPost.entityId);
    }
  };

  const handleShowcaseChange = (values: ShowcaseSidebarValues) => {
    patchLookback(lookBackId, { ...lookBack, ...values });
  };

  const allFiles =
    (filesResponse && filesResponse.value && filesResponse.value.data.map(d => d.attributes)) || [];
  const disableVisibilitySwitch = Boolean(
    focusResponse && !focusResponse.data.attributes.visibility
  );
  const disableShowcaseToggles = Boolean(
    !(
      focusResponse &&
      focusResponse.data.attributes.includeShowcase &&
      dreamResponse &&
      dreamResponse.data.attributes.includeShowcase &&
      lookBack &&
      lookBack.visibility
    ) || compassUserId === milaUserId
  );

  const getSidebar = () => {
    switch (compassState) {
      case CompassState.MYCOMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton
              value={Boolean(lookBack && !lookBack.visibility)}
              onChange={handleVisibilityChange}
              disabled={disableVisibilitySwitch}
            />
            <inspiration.InspirationSidebarButton />
            <SidebarSharing
              currentPost={currentPost}
              onClick={setSharingDialogState}
              disabled={!lookBack || !lookBack.visibility || disableVisibilitySwitch}
            />
          </Sidebar>
        );
      case CompassState.COMPASS:
        return (
          <Sidebar>
            <ContentVisibilityButton
              value={Boolean(lookBack && !lookBack.visibility)}
              onChange={handleVisibilityChange}
              disabled={compassUserId === milaUserId}
            />
            <inspiration.InspirationSidebarButton disabled={compassUserId === milaUserId} />
            <SidebarSharing
              currentPost={currentPost}
              onClick={setSharingDialogState}
              disabled={compassUserId === milaUserId}
            />
          </Sidebar>
        );
      case CompassState.MYSHOWCASE:
        return (
          <Sidebar>
            <ShowcaseSidebarOptions
              disabled={disableShowcaseToggles}
              onChange={values => handleShowcaseChange(values)}
              values={{
                includeShowcase: lookBack ? lookBack.includeShowcase : false,
                includeShowcaseComments: lookBack ? lookBack.includeShowcaseComments : false,
                includeShowcaseFiles: lookBack ? lookBack.includeShowcaseFiles : false,
              }}
            />
          </Sidebar>
        );
      default:
        return undefined;
    }
  };

  return (
    <inspiration.InspirationDialogProvider>
      <EditablePanel
        showToggleButton={compassState === CompassState.MYCOMPASS}
        view={
          isLoading || !lookBack ? (
            <ReactContentLoader />
          ) : (
            <div id={`lookBack-${lookBackId}`}>
              <ContentBlock
                content={lookBack}
                inspirationKey={InspirationContext.COACHEE_LOOK_BACK}
              />
              <FileList files={allFiles} loading={filesResponse && filesResponse.pending} />
              <Box display="flex" justifyContent="flex-end">
                <DateTimeMessage createdAt={lookBack.createdAt} updatedAt={lookBack.updatedAt} />
              </Box>
            </div>
          )
        }
        form={
          !lookBack || !lookBackId ? (
            <ReactContentLoader />
          ) : (
            <UpdateLookBackForm lookBack={lookBack} lookBackId={lookBackId} files={allFiles} />
          )
        }
        sidebar={getSidebar()}
        suffixComponent={
          lookBack && (
            <CommunicationWrapper
              context={lookBack}
              contextType="lookback"
              disabled={
                !lookBack.visibility ||
                compassState === CompassState.SHOWCASE ||
                compassState === CompassState.MYSHOWCASE
              }
            />
          )
        }
      />
      <SharingDialog
        currentPost={currentPost}
        postType="lookback"
        postId={lookBackId || ('' as LookBackId)}
        onClose={setSharingDialogState}
        dialogState={sharingDialog}
      />
    </inspiration.InspirationDialogProvider>
  );
};

export default UpdateLookBack;
