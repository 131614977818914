import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../components/SvgIcon';
import Button from '../../../components/Button';

type Props = {
  onClick: () => void;
  buttonText: string;
};

const useStyles = makeStyles({
  introLearnPaper: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const AddPostBlock = ({ onClick, buttonText }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.introLearnPaper}>
      <Button icon={Icons.PLUS} onClick={onClick}>
        {t(buttonText)}
      </Button>
    </Paper>
  );
};

export default AddPostBlock;
