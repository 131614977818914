import * as React from 'react';
import { useState } from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import Button from '../../../components/Button';
import odiseeTheme from '../../../config/theme';
import LanguagePopup from '../components/LanguagePopup';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';
import SvgIcon, { Icons } from '../../../components/SvgIcon';

type Props = {
  homepage?: boolean;
};

type StyleProps = {
  isMobile: boolean;
  homepage: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    height: '100%',
  },
  button: ({ homepage }: StyleProps) => ({
    position: 'relative',
    height: '100%',
    width: '6rem',
    borderLeft: homepage ? 'none' : theme.config.defaultBorder,
    background: homepage ? 'transparent' : theme.palette.common.white + ' !important',
    borderRadius: 0,
    '&:hover': {
      background: theme.palette.grey['100'],
    },
    fontSize: '10pt',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
  }),
  buttonInner: {
    paddingRight: '0.5rem',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  unreadMessagesWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(.25rem, -1.5rem)',
  },
  popup: ({ isMobile }: StyleProps) => ({
    position: 'absolute',
    top: 'calc(100% + .5rem)',
    left: isMobile ? 'auto' : '50%',
    right: isMobile ? '-2rem' : 'auto',
    transform: isMobile ? 'none' : 'translateX(-50%)',
    maxWidth: 'calc(100vw - 7rem)',
  }),
}));

const LanguageSwitcher = ({ homepage = false }: Props) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(odiseeTheme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile, homepage });
  const currentLang = useCurrentLanguage();

  return (
    <div className={classes.wrapper}>
      <Button
        onClick={() => {
          setOpen(!open);
        }}
        className={classes.button}
      >
        <div className={classes.buttonInner}>{currentLang.toUpperCase()}</div>
        <div
          style={{ transform: 'rotate(' + (open ? '180deg' : '0deg') + ')', transition: '250ms' }}
        >
          <SvgIcon icon={Icons.CHEVRON_DOWN} size={1} />
        </div>
      </Button>
      <LanguagePopup
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      />
    </div>
  );
};

export default LanguageSwitcher;
