import React from 'react';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type Props = {
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
};

const useStyles = makeStyles((theme: Theme) => ({
  sidebarPaper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderLeft: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    [theme.breakpoints.down('sm')]: {
      borderLeft: theme.config.defaultBorder,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      position: 'absolute',
      right: 0,
      zIndex: 1,
      boxShadow: '0 0 .5rem 0 rgba(0,0,0,.1)',
      minWidth: '16rem',
    },
  },
}));

const Sidebar = ({ id, children }: Props) => {
  const classes = useStyles();
  return (
    <Paper id={id} className={classes.sidebarPaper}>
      {children}
    </Paper>
  );
};

export default Sidebar;
