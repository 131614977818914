import { ActionsUnion, createAction } from '../../helpers/createAction';
import { SET_UNREAD_MESSAGES } from './actionTypes';

export const Actions = {
  setMessagesSeen: (relationKey: string, contextKey: string) =>
    createAction(SET_UNREAD_MESSAGES, { relationKey, contextKey, contextAmount: 0 }),
  setUnreadMessages: (relationKey: string, contextKey: string, contextAmount: number) =>
    createAction(SET_UNREAD_MESSAGES, { relationKey, contextKey, contextAmount }),
};

export type Actions = ActionsUnion<typeof Actions>;
