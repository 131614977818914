import React, { useState } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DropzoneArea } from 'material-ui-dropzone/dist';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../../../components/ButtonGroup';
import { Icons } from '../../../components/SvgIcon';
import BlurDialog from './BlurDialog';
import { union } from 'lodash';

type Props = {
  handleSave: (files: File[]) => void;
  fileList?: File[];
};

const fileSizeLimit = 5000000;

const useStyles = makeStyles((theme: Theme) => ({
  fileUploadRoot: {},
  fileList: {
    paddingLeft: 0,
  },
  file: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    marginRight: '1rem',
  },
  error: {
    color: theme.palette.secondary.main,
  },
}));

const FileUpload = ({ handleSave }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const toggleDialog = () => {
    setOpen(!open);
  };

  const resetState = () => {
    setOpen(false);
    setDirty(false);
    setFiles([]);
    setError('');
  };

  const save = () => {
    resetState();
    handleSave(files);
  };

  const handleError = (files: File[]) => {
    if (files.length) {
      const file = files[0];
      let errorMessage = 'files.notSupported';
      if (file.size >= fileSizeLimit) {
        errorMessage = 'files.tooLarge';
      }
      setError(t(errorMessage, { name: file.name }));
    }
  };

  const dialogActions = (
    <ButtonGroup>
      <Button onClick={save} type="button" icon={Icons.SAVE} disabled={!dirty}>
        {t('forms.buttons.save')}
      </Button>
      <Button onClick={resetState} type="button" color="secondary" icon={Icons.CANCEL}>
        {t('forms.buttons.cancel')}
      </Button>
    </ButtonGroup>
  );

  return (
    <div className={classes.fileUploadRoot}>
      <Button onClick={toggleDialog}>{t('forms.buttons.fileButton')}</Button>
      {open && (
        <BlurDialog open={open} actions={dialogActions}>
          <h2>{t('forms.buttons.fileButton')}</h2>
          <DropzoneArea
            onChange={(newFiles: File[]) => {
              setError('');
              setFiles(union(files, newFiles));
              setDirty(true);
            }}
            acceptedFiles={[
              'application/pdf',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document,',
              'application/msword',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-powerpoint',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              'text/plain',
            ]}
            maxFileSize={fileSizeLimit}
            filesLimit={5}
            showAlerts={false}
            showPreviews={false}
            showPreviewsInDropzone={false}
            dropzoneText={t('forms.labels.uploadText')}
            onDropRejected={handleError}
          />

          <ul className={classes.fileList}>
            {files.map((file, index) => (
              <li className={classes.file} key={index}>
                {file.name}
              </li>
            ))}
          </ul>

          <p className={classes.error}>{error}</p>
        </BlurDialog>
      )}
    </div>
  );
};

export default FileUpload;
