import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { GroupRequest, GroupId } from '../../../../config/api/models';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import SectionTitle from '../../../../components/SectionTitle';
import ModalBodyAlt from '../../../../components/ModalBodyAlt';
import FormControl from '../../../../components/FormControl';
import FormError from '../../../../components/forms/FormError';
import { makeStyles } from '@material-ui/styles';
import { Theme, useMediaQuery } from '@material-ui/core';
import themeConfig from '../../../../config/theme';
import useUser from '../../../security/hooks/useUser';
import BlurDialog from '../../../shared/components/BlurDialog';
import { TextFieldAdapter } from '../../../../components/forms';
import useFetchGroups from '../../hooks/useFetchGroups';

type FormValues = {
  name: string;
};

type Props = {
  saveBtnText: string;
  onToggle: () => void;
  groupId?: GroupId;
  groupName?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      minHeight: 'auto',
    },
  },
}));

const addGroupConfig = ApiConfig.addGroup();
const updateGroupConfig = ApiConfig.updateGroup();

const GroupDialog = ({ onToggle, saveBtnText, groupId, groupName }: Props) => {
  const user = useUser();
  const classes = useStyles();
  const fullScreen = useMediaQuery(themeConfig.breakpoints.down('sm'));
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveGroupResponse, saveGroup] = useFetch(groupId ? updateGroupConfig : addGroupConfig);
  const [, fetchGroups] = useFetchGroups();

  const isFulfilled = saveGroupResponse && saveGroupResponse.fulfilled;
  const isRejected = saveGroupResponse && saveGroupResponse.rejected;

  useEffect(() => {
    if (isSubmitting) {
      if (isFulfilled) {
        onToggle();
        fetchGroups();
        setIsSubmitting(false);
      } else if (isRejected) {
        setIsSubmitting(false);
      }
    }
  }, [isSubmitting, onToggle, isFulfilled, isRejected, fetchGroups]);

  const actions = (submit: () => void) => (
    <>
      <Button
        type="submit"
        onClick={submit}
        icon={Icons.SAVE}
        disabled={saveGroupResponse && saveGroupResponse.pending}
      >
        {t(saveBtnText)}
      </Button>
      <Button onClick={onToggle} color="secondary" icon={Icons.CANCEL}>
        {t('forms.buttons.cancel')}
      </Button>
    </>
  );

  const initialValues: FormValues = { name: groupName || '' };

  return (
    <Form
      onSubmit={(formValues: FormValues) => {
        const request: GroupRequest = {
          name: formValues.name,
          ownerId: user.entityId,
        };

        saveGroup(request, groupId);
        setIsSubmitting(true);
      }}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    >
      {({ form }) => (
        <BlurDialog
          open={true}
          actions={actions(form.submit)}
          onClose={onToggle}
          maxWidth="lg"
          fullScreen={fullScreen}
          classes={{ paper: classes.dialog }}
        >
          <SectionTitle
            label={t(groupId ? 'myCoach.myGroups.editGroupButton' : 'myCoach.myGroups.dialogTitle')}
          />
          <ModalBodyAlt fullWidth fullHeight>
            <>
              <FormControl label={t('forms.labels.name')} id="name">
                <Field
                  name="name"
                  component={TextFieldAdapter}
                  placeholder={t('myCoach.myGroups.textPlaceholder')}
                />
              </FormControl>

              {saveGroupResponse &&
                saveGroupResponse.rejected &&
                saveGroupResponse.reason &&
                saveGroupResponse.reason.cause.errors.map((item: any, index: number) => (
                  <FormError key={index}>{item.detail}</FormError>
                ))}
            </>
          </ModalBodyAlt>
        </BlurDialog>
      )}
    </Form>
  );
};

export default GroupDialog;
