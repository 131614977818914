import MyCoachPage from './scenes/MyCoachPage';
import reducer from './reducer';
import * as selectors from './selectors';

const myCoach = {
  MyCoachPage,
  reducer,
  selectors,
};

export default myCoach;
