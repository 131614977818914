import React, { useCallback, useState } from 'react';
import Joyride, { ACTIONS, Placement, STATUS } from 'react-joyride';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const MilaShowcaseOverviewTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showTour, setShowTour] = useState(true);

  const steps = [
    {
      target: '#myShowcase-content',
      content: t('tour.steps.milaShowcaseOverview.first'),
      disableBeacon: true,
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#myShowcase_tab',
      content: t('tour.steps.milaShowcaseOverview.second'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#myLinks_tab',
      content: t('tour.steps.milaShowcaseOverview.third'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#my-talents-button',
      content: t('tour.steps.milaShowcaseOverview.fourth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#create_showcase_button',
      content: t('tour.steps.milaShowcaseOverview.fifth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.SKIPPED, STATUS.FINISHED].includes(status)
      ) {
        setShowTour(false);
      }
    },
    [setShowTour]
  );

  return (
    <Joyride
      steps={steps}
      run={showTour}
      callback={handleTourCallback}
      continuous
      showProgress
      locale={{
        back: t('tour.locale.back'),
        close: t('tour.locale.close'),
        last: t('tour.locale.close'),
        next: t('tour.locale.next'),
        skip: t('tour.locale.skip'),
      }}
      styles={theme.config.joyRideStyles.tour}
    />
  );
};

export default MilaShowcaseOverviewTour;
