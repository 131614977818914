import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, Placement, STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../actions';
import { getMilaNetworkTourSwitchToComments } from '../selectors';

const MilaNetworkTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showTour, setShowTour] = useState(true);
  const [showLastStep, setShowLastStep] = useState(false);
  const dispatch = useDispatch();
  const [switchToCommentsFlagEnabled, setSwitchToCommentsFlagEnabled] = useState(false);
  const switchToComments = useSelector(getMilaNetworkTourSwitchToComments);

  const steps = [
    {
      target: '#my-network-tab',
      content: t('tour.steps.milaNetwork.first'),
      disableBeacon: true,
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#my-peers-tab',
      content: t('tour.steps.milaNetwork.second'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#compass-link-button',
      content: t('tour.steps.milaNetwork.third'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const lastStep = [
    {
      target: '#commentWrapper',
      content: t('tour.steps.milaNetwork.fourth'),
      placement: 'auto' as Placement,
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.SKIPPED, STATUS.FINISHED].includes(status)
      ) {
        setShowTour(false);
      }
      if ([STATUS.FINISHED].includes(status as any)) {
        dispatch(Actions.enableMilaNetworkTourSwitchToComments());
        setSwitchToCommentsFlagEnabled(true);
      }
    },
    [setShowTour, dispatch]
  );

  const handleLastStepCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.SKIPPED, STATUS.FINISHED].includes(status)
      ) {
        setShowLastStep(false);
      }
    },
    [setShowLastStep]
  );

  useEffect(() => {
    if (switchToCommentsFlagEnabled && !switchToComments) {
      setTimeout(() => setShowLastStep(true), 500);
    }
  }, [switchToComments, setShowLastStep, switchToCommentsFlagEnabled]);

  return (
    <>
      <Joyride
        steps={steps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.next'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      <Joyride
        steps={lastStep}
        run={showLastStep}
        callback={handleLastStepCallback}
        continuous
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.close'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
    </>
  );
};

export default MilaNetworkTour;
