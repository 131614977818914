import React, { useState, useEffect } from 'react';
import useFetch from '../../../helpers/useFetch';
import {
  PostsResponse,
  Post,
  LearnId,
  TalentId,
  LookBackId,
  MilestoneId,
} from '../../../config/api/models';
import { ApiConfig } from '../api';
import ButtonGroup from '../../../components/ButtonGroup';
import Button from '../../../components/Button';
import BlurDialog from './BlurDialog';
import { Checkbox, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useUser from '../../security/hooks/useUser';
import { Icons } from '../../../components/SvgIcon';

type Props = {
  postType: 'learn' | 'talent' | 'lookback' | 'milestone';
  postId: LearnId | TalentId | LookBackId | MilestoneId;
  currentPost?: Post;
  dialogState?: SharingDialogState;
  onClose: (value?: SharingDialogState) => void;
};

export type SharingDialogState = 'add' | 'edit' | 'delete';

type SharingDialogProps = {
  title?: string;
  body?: string;
  confirmText?: string;
  onConfirm?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    marginTop: 0,
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      margin: '0.5rem',
    },
  },
}));

const SharingDialog = ({ currentPost, postId, postType, dialogState, onClose }: Props) => {
  const [includeContext, setIncludeContext] = useState<boolean>(false);
  const currentUser = useUser();
  const classes = useStyles();
  const { t } = useTranslation();

  const [, createPost] = useFetch<PostsResponse>(ApiConfig.createPost());
  const [, patchPost] = useFetch<PostsResponse>(ApiConfig.updatePost());
  const [, removePost] = useFetch<PostsResponse>(ApiConfig.removePost());

  useEffect(() => {
    if (currentPost) {
      setIncludeContext(currentPost.includeContext);
    }
  }, [currentPost]);

  const closeDialog = () => {
    onClose();
    setIncludeContext(currentPost ? currentPost.includeContext : false);
  };

  const handleSave = () => {
    const newPost: Partial<Post> = {
      postType,
      postId,
      authorId: currentUser.entityId,
      includeContext,
    };

    createPost(newPost);
    closeDialog();
  };

  const handleRemove = () => {
    if (currentPost) {
      removePost(currentPost.entityId);
    }
    closeDialog();
  };

  const handleReshare = () => {
    if (currentPost) {
      const postUpdate = {
        updatedAt: new Date(),
        includeContext,
      };

      patchPost(currentPost.entityId, postUpdate);
    }
    closeDialog();
  };

  const getDialogProps = (): SharingDialogProps => {
    switch (dialogState) {
      case 'add':
        return {
          confirmText: t('forms.buttons.addPost'),
          onConfirm: handleSave,
          title: t('posts.createDialogTitle'),
          body: t('posts.createDialogBody', { postType: t(`posts.postType.${postType}`) }),
        };
      case 'edit':
        return {
          confirmText: t('forms.buttons.addPost'),
          onConfirm: handleReshare,
          title: t('posts.updateDialogTitle'),
          body: t('posts.updateDialogBody'),
        };
      case 'delete':
        return {
          confirmText: t('forms.buttons.removePost'),
          onConfirm: handleRemove,
          title: t('posts.removeDialogTitle'),
          body: t('posts.removeDialogBody'),
        };
      default:
        return {};
    }
  };

  const dialogProps = getDialogProps();
  const withContextForm = postType !== 'talent' && dialogState !== 'delete';

  const actionButtons = (
    <ButtonGroup>
      <Button
        className={classes.button}
        type="button"
        onClick={dialogProps.onConfirm}
        icon={Icons.SAVE}
      >
        {dialogProps.confirmText}
      </Button>
      <Button
        className={classes.button}
        type="button"
        onClick={closeDialog}
        color="secondary"
        icon={Icons.CANCEL}
      >
        {t('forms.buttons.cancel')}
      </Button>
    </ButtonGroup>
  );

  return (
    <BlurDialog
      open={Boolean(dialogState)}
      onClose={closeDialog}
      title={dialogProps.title}
      actions={actionButtons}
    >
      <>
        <p>{dialogProps.body}</p>
        {withContextForm && (
          <>
            <Checkbox
              color="primary"
              onChange={ev => setIncludeContext((ev.target as HTMLInputElement).checked)}
              checked={includeContext}
              className={classes.checkbox}
            />
            {t('posts.showContext', { postType: t(`posts.postType.${postType}`) })}
          </>
        )}
      </>
    </BlurDialog>
  );
};

export default SharingDialog;
