import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { SettingsViewComponent } from '../../settings/types';
import security from '../../security';
import InfoLine from './InfoLine';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Actions } from '../../myCompass/actions';
import { CompassState } from '../../myCompass/model';

const useStyles = makeStyles(() => ({
  settingsViewRoot: {
    margin: '-2rem 0',
  },
}));

const SettingsView: SettingsViewComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentUser = security.hooks.useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.setCompassState(CompassState.MYCOMPASS));
  }, [dispatch]);

  return (
    <div className={classes.settingsViewRoot}>
      <InfoLine
        label={t('forms.labels.fullName')}
        value={currentUser.firstName + ' ' + currentUser.lastName}
      />
      <InfoLine label={t('forms.labels.email')} value={currentUser.email} />
      <InfoLine label={t('forms.labels.alternateEmail')} value={currentUser.secondaryEmail} />
      <InfoLine label={t('forms.labels.education')} value={currentUser.education} />
      <InfoLine label={t('forms.labels.function')} value={currentUser.function} />
      {currentUser.organisation && (
        <InfoLine label={t('forms.labels.organisation')} value={currentUser.organisation.name} />
      )}
      <InfoLine
        label={t('forms.labels.department')}
        value={currentUser.department ? currentUser.department.name : ''}
      />
    </div>
  );
};

export default SettingsView;
