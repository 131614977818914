import React, { useEffect, useState } from 'react';
import inspiration from '../../inspiration';
import Section from '../../../components/Section';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CoachInvitation from '../components/CoachInvitation';
import {
  ContextType,
  Focus,
  InspirationContext,
  NotesResponse,
  RelationContext,
  RelationsResponse,
  RelationType,
  User,
} from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import CoachCoacheeList from '../components/CoachCoacheeList';
import { ApiConfig as SharedApiConfig } from '../../shared/api';
import { ApiConfig } from '../api';
import CoachRelationOverview from './CoachRelationOverview';
import { getContactList, groupRelationsByRelationType } from '../helpers/helperFunctions';
import { RelationOverviewProps } from '../models';
import moment from 'moment';
import ChatWrapper from './ChatWrapper';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import NoteDialog from './NoteDialog';
import { useCompassUser } from '../../shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../../myCompass/selectors';
import useUser from '../../security/hooks/useUser';
import { getHelp, getMilaUserId } from '../../shared/selectors';
import { MilaCoachTour, MyCoachTour } from '../../shared/tours';
import {
  MyCoachMain,
  MyCoachLayout,
  MyCoachASide,
  SectionButtonContainer,
  SectionButton,
} from '../components/Atoms';
import { getMyCoachRelations } from '../selectors';

type Props = {
  userToUse: User;
};

export type RelationUserRole = 'initiator' | 'receiver';

const useStyles = makeStyles((theme: Theme) => ({
  invitationsWrapperMultiple: {
    '& $invitationItem': {
      borderRadius: 0,
      borderBottom: 'none',
      '&:first-child': {
        borderTopRightRadius: theme.shape.borderRadius + 'px',
        borderTopLeftRadius: theme.shape.borderRadius + 'px',
      },
      '&:last-child': {
        borderBottomRightRadius: theme.shape.borderRadius + 'px',
        borderBottomLeftRadius: theme.shape.borderRadius + 'px',
        borderBottom: theme.config.defaultBorder,
      },
    },
  },
  invitationItem: {},
}));

const MyCoach = ({ userToUse }: Props) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const classes = useStyles({ isMobile });
  const [dialogIsOpen, openDialog] = useState(false);
  const toggleDialog = () => openDialog(!dialogIsOpen);
  const currentUser = useUser();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const isHelpActive = useSelector(getHelp);
  const myCoachRelations = useSelector(getMyCoachRelations);

  const [myCoachesRequest, fetchMyCoaches] = useFetch<RelationsResponse>(
    SharedApiConfig.myCoaches(isMila)
  );

  const [, fetchNotes] = useFetch<NotesResponse>(ApiConfig.loadNote());
  const [, fetchUnreadMessages] = useFetch(SharedApiConfig.initialUnreadMessages());

  const [, stopRelation] = useFetch<RelationsResponse>(SharedApiConfig.relationStop());
  const [, cancelInvite] = useFetch<RelationsResponse>(SharedApiConfig.cancelInvitation());

  const [selectedRelationProps, setSelectedRelationProps] = useState<
    RelationOverviewProps | undefined
  >(undefined);
  const [selectedRelation, setSelectedRelation] = useState<RelationContext | undefined>(undefined);
  const [selectedChat, setSelectedChat] = useState<RelationContext | undefined>(undefined);

  const handleStopRelation = (relation: RelationContext) => {
    if (relation.acceptedAt) {
      stopRelation(relation.entityId, {
        stoppedAt: moment()
          .toISOString()
          .split('.')[0],
      });
    } else {
      cancelInvite(relation.entityId);
    }
  };

  // eslint-disable-next-line
  useEffect(fetchMyCoaches, [userToUse, isMila]);
  useEffect(() => {
    if (myCoachesRequest && !myCoachesRequest.pending && myCoachesRequest.value) {
      const myCoaches = myCoachesRequest.value.data;
      const result = myCoaches.find(
        relation =>
          relation.attributes.initiatorId !== userToUse.entityId &&
          relation.attributes.receiverId !== userToUse.entityId
      );
      if (result) {
        fetchMyCoaches();
      }
    }
  }, [fetchMyCoaches, myCoachesRequest, userToUse]);
  useEffect(() => {
    if (myCoachesRequest && myCoachesRequest.pending) {
      setSelectedRelationProps(undefined);
    }
  }, [myCoachesRequest]);

  // eslint-disable-next-line
  useEffect(fetchNotes, []);
  // eslint-disable-next-line
  useEffect(fetchUnreadMessages, []);

  if (!myCoachesRequest || !myCoachesRequest.value) {
    return null;
  }

  const { coaches, coachees, invitations } = myCoachRelations
    ? groupRelationsByRelationType(myCoachRelations, userToUse)
    : { coaches: [], coachees: [], invitations: [] };

  return (
    <inspiration.InspirationDialogProvider>
      <>
        {invitations && invitations.length > 0 && (
          <Section id="invitations" title={t('myCoach.invitations.title')}>
            <div className={invitations.length > 1 ? classes.invitationsWrapperMultiple : ''}>
              {invitations.map((item: RelationContext, index: number) => (
                <CoachInvitation
                  key={index}
                  relationId={item.relationId}
                  initiator={item.initiator}
                  contextType={item.contextType as ContextType}
                  context={item.context as Focus}
                  className={classes.invitationItem}
                  description={item.description}
                  showcaseSlug={item.showcaseSlug}
                />
              ))}
            </div>
          </Section>
        )}

        {!myCoachesRequest || !myCoachesRequest.value ? null : coaches.length || coachees.length ? (
          <MyCoachLayout>
            <MyCoachASide id="coach-coachee-list">
              <CoachCoacheeList
                coaches={getContactList(coaches, userToUse)}
                coachees={getContactList(coachees, userToUse)}
                selectedRelation={selectedRelationProps}
                onLoadRelation={(user, role: RelationType, relation: RelationContext) => {
                  setSelectedRelationProps({
                    user,
                    role,
                  });
                  setSelectedRelation(relation);
                  setSelectedChat(undefined);
                }}
              />
            </MyCoachASide>

            <MyCoachMain>
              <Section
                id="coachRelationship"
                title={t('myCoach.relationship.title')}
                headerButton={
                  !isCompassUser ? (
                    <SectionButtonContainer>
                      {selectedRelationProps && selectedRelationProps.role === 'coachee' && (
                        <>
                          <SectionButton>
                            <Button icon={Icons.LIGHT_BULB} link="coaching">
                              {t('myCoach.inspirationButton')}
                            </Button>
                          </SectionButton>
                          <SectionButton>
                            <Button icon={Icons.NOTES} onClick={toggleDialog}>
                              {t('myCoach.notes.openDialog')}
                            </Button>
                          </SectionButton>
                        </>
                      )}
                      {selectedRelationProps && selectedRelationProps.role === 'coach' && (
                        <SectionButton>
                          <Button icon={Icons.LIGHT_BULB} link="coaching">
                            {t('myCoach.inspirationButton')}
                          </Button>
                        </SectionButton>
                      )}
                    </SectionButtonContainer>
                  ) : isMila ? (
                    <SectionButtonContainer>
                      <SectionButton>
                        <Button
                          id="what-is-coaching-button"
                          icon={Icons.LIGHT_BULB}
                          link="coaching"
                        >
                          {t('myCoach.inspirationButton')}
                        </Button>
                      </SectionButton>
                      <SectionButton>
                        <Button id="notes-button" icon={Icons.NOTES}>
                          {t('myCoach.notes.openDialog')}
                        </Button>
                      </SectionButton>
                    </SectionButtonContainer>
                  ) : null
                }
              >
                {dialogIsOpen && selectedRelationProps && (
                  <NoteDialog
                    saveBtnText={t('myCoach.notes.add')}
                    onToggle={toggleDialog}
                    otherUser={selectedRelationProps.user}
                  />
                )}
                {!isCompassUser && (
                  <inspiration.InspirationSimpleDialog inspirationKey={InspirationContext.COACH} />
                )}
                {selectedRelationProps && selectedRelation && (
                  <CoachRelationOverview
                    user={selectedRelationProps.user}
                    currentUser={currentUser}
                    role={selectedRelationProps.role}
                    selectedRelation={selectedRelation}
                    onSelectChat={(relation: RelationContext) => setSelectedChat(relation)}
                    selectedChat={selectedChat}
                    onStopRelation={handleStopRelation}
                  />
                )}
              </Section>

              {myCoachRelations && (
                <ChatWrapper
                  selectedChat={selectedChat}
                  setSelectedChat={setSelectedChat}
                  relations={myCoachRelations}
                />
              )}
            </MyCoachMain>
          </MyCoachLayout>
        ) : (
          <>
            <div id="no-relations">{t('myCoach.noRelations')}</div>
            {!isHelpActive && !currentUser.coachTourDone && <MyCoachTour />}
          </>
        )}

        {isMila && isHelpActive && <MilaCoachTour />}
      </>
    </inspiration.InspirationDialogProvider>
  );
};

export default MyCoach;
