import React, { useState } from 'react';
import { Theme, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from './SvgIcon';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import CreateRelationDialog from '../modules/shared/components/CreateRelationDialog';

type Props = {
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  addPeerCardRoot: {
    width: '25rem',
    border: theme.config.defaultBorder,
    color: theme.palette.grey['500'],
    textAlign: 'center',
    margin: '1rem',
    padding: '2rem',
    '&:hover': {
      backgroundColor: theme.palette.grey['100'],
      cursor: 'pointer',
    },
  },
  addIcon: {
    border: theme.config.defaultBorder,
    borderRadius: 100,
    width: '60%',
    height: 'calc(21rem * 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginBottom: '1.5rem',
  },
}));

const AddPeerCard = ({ disabled = false }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  return (
    <div id="add-peer-card">
      <Paper
        className={classes.addPeerCardRoot}
        onClick={disabled ? undefined : () => setOpenDialog(true)}
      >
        <div className={classes.addIcon}>
          <SvgIcon icon={Icons.ADD_PEER} size={3} />
        </div>
        <Button icon={Icons.PLUS} onClick={disabled ? undefined : () => setOpenDialog(true)}>
          {t('peers.add')}
        </Button>
      </Paper>
      <CreateRelationDialog
        dialogType="peer"
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      />
    </div>
  );
};

export default AddPeerCard;
