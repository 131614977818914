import React, { useState } from 'react';
import { Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import theme from '../../../config/theme';
import ContractingCoach from '../../../assets/contracting-coach.png';
import ContractingCoachee from '../../../assets/contracting-coachee.png';
import Contacting from '../../../assets/contacting.png';
import Roles from '../../../assets/roles.png';
import Verdeler from '../../../assets/VERDELER.png';
import Verkenner from '../../../assets/VERKENNER.png';
import Uitdager from '../../../assets/UITDAGER.png';
import Inspirator from '../../../assets/INSPIRATOR.png';
import Motivator from '../../../assets/MOTIVATOR.png';
import Klankbord from '../../../assets/KLANKBORD.png';
import { useTranslation } from 'react-i18next';
import PaperTitle from '../../../components/PaperTitle';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import TextBox from './TextBox';
import Html from '../../../components/Html';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  sectionThreeRoot: (props: StyleProps) => ({
    marginLeft: '-3000px',
    paddingLeft: '3000px',
    marginRight: '-3000px',
    paddingRight: '3000px',
    paddingTop: props.isMobile ? '20rem' : '14rem',
    paddingBottom: props.isMobile ? '10rem' : '0',
    minHeight: props.isMobile ? '' : '55rem',
  }),
  left: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '15%',
  }),
  right: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '85%',
    marginTop: props.isMobile ? '45rem' : '',

    '& > img': {
      width: '100%',
    },
  }),
  reverse: {
    flexDirection: 'row-reverse',
  },
  relate: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
  },
  sectionThreeContractingCoach: (props: StyleProps) => ({
    clipPath: 'polygon(0 38%, 100% 0, 100% 100%, 0 100%)',
    backgroundColor: theme.palette.coaching.pink,
    paddingTop: props.isMobile ? '25rem' : '',
    marginTop: props.isMobile ? '-10rem' : '',
  }),
  sectionThreeContractingCoachee: (props: StyleProps) => ({
    clipPath: 'polygon(0 0, 100% 38%, 100% 100%, 0 100%)',
    backgroundColor: theme.palette.coaching.orange,
    paddingTop: props.isMobile ? '25rem' : '',
    marginTop: props.isMobile ? '-29rem' : '-16rem',
  }),
  sectionThreeContacting: (props: StyleProps) => ({
    clipPath: 'polygon(0 38%, 100% 0, 100% 100%, 0 100%)',
    background: 'linear-gradient(0deg, rgba(78,124,191,1) 0%, rgba(67,105,161,1) 100%)',
    marginTop: props.isMobile ? '-32rem' : '-16rem',
    paddingBottom: props.isMobile ? '20rem' : '40rem !important',
    paddingTop: props.isMobile ? '27rem' : '2',
  }),
  sectionThreeRoles: (props: StyleProps) => ({
    clipPath: 'polygon(0 0, 100% 38%, 100% 100%, 0 100%)',
    background: 'linear-gradient(0deg, rgba(66,166,129,1) 0%, rgba(51,128,99,1) 100%)',
    marginTop: props.isMobile ? '-30rem' : '-17rem',
    paddingTop: props.isMobile ? '27rem' : '19rem',
    paddingBottom: props.isMobile ? '10rem' : '15rem',
    minHeight: props.isMobile ? '' : '65rem',
  }),
  cloud: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '60% !important',
      right: '-26rem',
    },
  },
  roles: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '50% !important',
  }),
  paperLeft: (props: StyleProps) => ({
    position: 'absolute',
    top: props.isMobile ? '-9rem' : '-5rem',
    padding: '6rem 3rem 3rem 3rem',
    background: theme.palette.background.default,
    width: props.isMobile ? '30rem' : '45rem',
  }),
  alignRight: (props: StyleProps) => ({
    right: props.isMobile ? '' : 0,
  }),
  list: {
    fontWeight: 'bold',
    listStyle: 'none',
    paddingLeft: '0',
    marginTop: '0.5rem',
  },
  listItem: {
    marginBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.7rem',
  },
  listItemText: {
    margin: '0 1rem 0 0',
    cursor: 'pointer',
  },
  orange: {
    color: theme.palette.coaching.orange,
  },
  pink: {
    color: theme.palette.coaching.pink,
  },
  blue: {
    color: theme.palette.coaching.blue,
  },
  green: {
    color: theme.palette.coaching.green,
  },
  contactingTextbox: {
    position: 'absolute',
    zIndex: 2,
    right: '0',
  },
  underline: {
    textDecoration: 'underline',
  },
  intro: {
    fontWeight: 'bold',
    margin: '1rem 0 0 0',
  },
}));

const SectionThree = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  const [liNumber, setLiNumber] = useState<number>(0);
  const [liNumberCCoach, setLiNumberCCoach] = useState<number>(0);
  const [liNumberContacting, setLiNumberContacting] = useState<number>(0);
  const [liNumberRoles, setLiNumberRoles] = useState<number>(0);

  const handleClose = () => {
    setLiNumber(0);
  };
  const handleCCoachClose = () => {
    setLiNumberCCoach(0);
  };
  const handleContractingClose = () => {
    setLiNumberContacting(0);
  };
  const handleRolesClose = () => {
    setLiNumberRoles(0);
  };

  const listItemsContractingCoach = [
    t('coaching.sectionThree.contractingCoach.listItems.item1'),
    t('coaching.sectionThree.contractingCoach.listItems.item2'),
    t('coaching.sectionThree.contractingCoach.listItems.item3'),
    t('coaching.sectionThree.contractingCoach.listItems.item4'),
  ];
  const listItemsContractingCoachee = [
    t('coaching.sectionThree.contractingCoachee.listItems.item1'),
    t('coaching.sectionThree.contractingCoachee.listItems.item2'),
    t('coaching.sectionThree.contractingCoachee.listItems.item3'),
    t('coaching.sectionThree.contractingCoachee.listItems.item4'),
  ];
  const listItemsContacting = [
    t('coaching.sectionThree.contacting.listItems.item1'),
    t('coaching.sectionThree.contacting.listItems.item2'),
    t('coaching.sectionThree.contacting.listItems.item3'),
    t('coaching.sectionThree.contacting.listItems.item4'),
  ];
  const listItemsRoles = [
    t('coaching.sectionThree.roles.listItems.item1'),
    t('coaching.sectionThree.roles.listItems.item2'),
    t('coaching.sectionThree.roles.listItems.item3'),
    t('coaching.sectionThree.roles.listItems.item4'),
    t('coaching.sectionThree.roles.listItems.item5'),
    t('coaching.sectionThree.roles.listItems.item6'),
  ];

  return (
    <div id="sectionThree">
      <div className={clsx(classes.sectionThreeRoot, classes.sectionThreeContractingCoach)}>
        <div className={classes.relate}>
          <div className={classes.left}>
            <Paper className={classes.paperLeft}>
              <PaperTitle color={theme.palette.myCompass.red}>
                {t('coaching.sectionThree.contractingCoach.title')}
              </PaperTitle>
              <Html>{t('coaching.sectionThree.contractingCoach.text')}</Html>
              <p className={clsx(classes.pink, classes.intro)}>
                {t('coaching.sectionThree.contractingCoach.intro')}
              </p>
              <ul className={classes.list}>
                {listItemsContractingCoach &&
                  listItemsContractingCoach.map((item: string, index: number) => (
                    <li className={classes.listItem} key={index}>
                      <p
                        className={clsx(
                          classes.listItemText,
                          classes.pink,
                          index + 1 === liNumberCCoach && classes.underline
                        )}
                        onClick={() => setLiNumberCCoach(index + 1)}
                      >
                        {item}
                      </p>
                      <SvgIcon icon={Icons.SAVE} color="#D3D3D3" />
                    </li>
                  ))}
              </ul>
            </Paper>
          </div>
          <div className={classes.right}>
            {liNumberCCoach === 1 && (
              <TextBox
                onClose={handleCCoachClose}
                text={t('coaching.sectionThree.contractingCoach.listItems.text1')}
                end
              />
            )}
            {liNumberCCoach === 2 && (
              <TextBox
                onClose={handleCCoachClose}
                text={t('coaching.sectionThree.contractingCoach.listItems.text2')}
                end
              />
            )}
            {liNumberCCoach === 3 && (
              <TextBox
                onClose={handleCCoachClose}
                text={t('coaching.sectionThree.contractingCoach.listItems.text3')}
                end
              />
            )}
            {liNumberCCoach === 4 && (
              <TextBox
                onClose={handleCCoachClose}
                text={t('coaching.sectionThree.contractingCoach.listItems.text4')}
                end
              />
            )}
            {liNumberCCoach === 0 && (
              <img src={ContractingCoach} alt={t('coaching.sectionThree.contractingCoach.title')} />
            )}
          </div>
        </div>
      </div>
      <div className={clsx(classes.sectionThreeRoot, classes.sectionThreeContractingCoachee)}>
        <div className={clsx(classes.relate, classes.reverse)}>
          <div className={classes.left}>
            <Paper className={clsx(classes.paperLeft, classes.alignRight)}>
              <PaperTitle color={theme.palette.myCompass.red}>
                {t('coaching.sectionThree.contractingCoachee.title')}
              </PaperTitle>
              <Html>{t('coaching.sectionThree.contractingCoachee.text')}</Html>
              <p className={clsx(classes.orange, classes.intro)}>
                {t('coaching.sectionThree.contractingCoachee.intro')}
              </p>
              <ul className={classes.list}>
                {listItemsContractingCoachee &&
                  listItemsContractingCoachee.map((item: string, index: number) => (
                    <li className={classes.listItem} key={index}>
                      <p
                        className={clsx(
                          classes.listItemText,
                          classes.orange,
                          index + 1 === liNumber && classes.underline
                        )}
                        onClick={() => setLiNumber(index + 1)}
                      >
                        {item}
                      </p>
                      <SvgIcon icon={Icons.SAVE} color="#D3D3D3" />
                    </li>
                  ))}
              </ul>
            </Paper>
          </div>
          <div className={classes.right}>
            {liNumber === 1 && (
              <TextBox
                onClose={handleClose}
                text={t('coaching.sectionThree.contractingCoachee.listItems.text1')}
              />
            )}
            {liNumber === 2 && (
              <TextBox
                onClose={handleClose}
                text={t('coaching.sectionThree.contractingCoachee.listItems.text2')}
              />
            )}
            {liNumber === 3 && (
              <TextBox
                onClose={handleClose}
                text={t('coaching.sectionThree.contractingCoachee.listItems.text3')}
              />
            )}
            {liNumber === 4 && (
              <TextBox
                onClose={handleClose}
                text={t('coaching.sectionThree.contractingCoachee.listItems.text4')}
              />
            )}
            {liNumber === 0 && (
              <img
                src={ContractingCoachee}
                alt={t('coaching.sectionThree.contractingCoachee.title')}
              />
            )}
          </div>
        </div>
      </div>
      <div className={clsx(classes.sectionThreeRoot, classes.sectionThreeContacting)}>
        <div className={classes.relate}>
          <div className={classes.left}>
            <Paper className={classes.paperLeft}>
              <PaperTitle color={theme.palette.myCompass.blue}>
                {t('coaching.sectionThree.contacting.title')}
              </PaperTitle>
              <Html>{t('coaching.sectionThree.contacting.text')}</Html>
              <ul className={classes.list}>
                {listItemsContacting &&
                  listItemsContacting.map((item: string, index: number) => (
                    <li className={classes.listItem} key={index}>
                      <p
                        className={clsx(
                          classes.listItemText,
                          classes.blue,
                          index + 1 === liNumberContacting && classes.underline
                        )}
                        onClick={() => setLiNumberContacting(index + 1)}
                      >
                        {item}
                      </p>
                      <SvgIcon icon={Icons.SAVE} color="#D3D3D3" />
                    </li>
                  ))}
              </ul>
            </Paper>
          </div>
          <div className={classes.right}>
            <div className={classes.contactingTextbox}>
              {liNumberContacting === 1 && (
                <TextBox
                  onClose={handleContractingClose}
                  text={t('coaching.sectionThree.contacting.listItems.text1')}
                  end
                />
              )}
            </div>
            <div className={classes.contactingTextbox}>
              {liNumberContacting === 2 && (
                <TextBox
                  onClose={handleContractingClose}
                  text={t('coaching.sectionThree.contacting.listItems.text2')}
                  end
                />
              )}
            </div>
            <div className={classes.contactingTextbox}>
              {liNumberContacting === 3 && (
                <TextBox
                  onClose={handleContractingClose}
                  text={t('coaching.sectionThree.contacting.listItems.text3')}
                  end
                />
              )}
            </div>
            <div className={classes.contactingTextbox}>
              {liNumberContacting === 4 && (
                <TextBox
                  onClose={handleContractingClose}
                  text={t('coaching.sectionThree.contacting.listItems.text4')}
                  end
                />
              )}
            </div>

            <img
              src={Contacting}
              alt={t('coaching.sectionThree.contacting.title')}
              className={classes.cloud}
            />
          </div>
        </div>
      </div>
      <div className={clsx(classes.sectionThreeRoot, classes.sectionThreeRoles)}>
        <div className={clsx(classes.relate, classes.reverse)}>
          <div className={classes.left}>
            <Paper className={clsx(classes.paperLeft, classes.alignRight)}>
              <PaperTitle color={theme.palette.coaching.green}>
                {t('coaching.sectionThree.roles.title')}
              </PaperTitle>
              <Html>{t('coaching.sectionThree.roles.text')}</Html>
              <ul className={classes.list}>
                {listItemsRoles &&
                  listItemsRoles.map((item: string, index: number) => (
                    <li className={classes.listItem} key={index}>
                      <p
                        className={clsx(
                          classes.listItemText,
                          classes.green,
                          index + 1 === liNumberRoles && classes.underline
                        )}
                        onClick={() => setLiNumberRoles(index + 1)}
                      >
                        {item}
                      </p>
                      <SvgIcon icon={Icons.SAVE} color="#D3D3D3" />
                    </li>
                  ))}
              </ul>
            </Paper>
          </div>
          <div className={classes.right}>
            {liNumberRoles === 1 && (
              <TextBox
                onClose={handleRolesClose}
                text={t('coaching.sectionThree.roles.listItems.text1')}
                image={Verkenner}
              />
            )}
            {liNumberRoles === 2 && (
              <TextBox
                onClose={handleRolesClose}
                text={t('coaching.sectionThree.roles.listItems.text2')}
                image={Verdeler}
              />
            )}
            {liNumberRoles === 3 && (
              <TextBox
                onClose={handleRolesClose}
                text={t('coaching.sectionThree.roles.listItems.text3')}
                image={Uitdager}
              />
            )}
            {liNumberRoles === 4 && (
              <TextBox
                onClose={handleRolesClose}
                text={t('coaching.sectionThree.roles.listItems.text4')}
                image={Inspirator}
              />
            )}
            {liNumberRoles === 5 && (
              <TextBox
                onClose={handleRolesClose}
                text={t('coaching.sectionThree.roles.listItems.text5')}
                image={Motivator}
              />
            )}
            {liNumberRoles === 6 && (
              <TextBox
                onClose={handleRolesClose}
                text={t('coaching.sectionThree.roles.listItems.text6')}
                image={Klankbord}
              />
            )}
            {liNumberRoles === 0 && (
              <img
                src={Roles}
                alt={t('coaching.sectionThree.roles.title')}
                className={classes.roles}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
