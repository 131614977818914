import React, { useEffect } from 'react';
import PaperTitle from '../../../../components/PaperTitle';
import PlanList from '../../components/Plan/PlanList';
import PlanListItem from '../../components/Plan/PlanListItem';
import DisableInteraction from '../../../../components/DisableInteraction';
import { useSelector } from 'react-redux';
import { getSelectedFocusPlansApiLink, getCompassUserInfo } from '../../selectors';
import { ClosedStatus, PlanResponse, TaskId, TaskResponse } from '../../../../config/api/models';
import { ApiConfig } from '../../api';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/fp/sortBy';
import useFetch from '../../../../helpers/useFetch';
import { getUserDisplayName } from '../../../../helpers/getUserDisplayName';
import { makeStyles } from '@material-ui/core/styles';
import { useCompassUser } from '../../../shared/hooks';

const useStyles = makeStyles(() => ({
  planPaper: {
    textAlign: 'center',
  },
}));

const sortByStartDate = sortBy((task: TaskResponse['data']) => {
  return task.attributes.startDate
    ? new Date(task.attributes.startDate)
    : new Date().setDate(new Date().getDate() + 36500);
});

const sortByUpdatedDate = sortBy((task: TaskResponse['data']) => {
  return -new Date(task.attributes.updatedAt);
});

const PlanListWrapper = () => {
  const { t } = useTranslation();
  const selectedFocusPlansApiLink = useSelector(getSelectedFocusPlansApiLink);
  const isCompassUserPage = useCompassUser();
  const compassUser = useSelector(getCompassUserInfo);
  const classes = useStyles();

  const [plansResponse, fetchPlans] = useFetch<PlanResponse>(ApiConfig.loadPlans());
  const [updatePlanResponse, updatePlan] = useFetch<TaskResponse>(ApiConfig.updatePlan());
  const [deletePlanResponse, deletePlan] = useFetch<TaskResponse>(ApiConfig.deletePlan());

  useEffect(() => {
    if (selectedFocusPlansApiLink) {
      fetchPlans(selectedFocusPlansApiLink);
    }
  }, [selectedFocusPlansApiLink, fetchPlans]);

  const reqPending =
    (deletePlanResponse && deletePlanResponse.pending) ||
    (updatePlanResponse && updatePlanResponse.pending);

  const completeTask = (planId: TaskId) => () =>
    updatePlan({ isClosed: ClosedStatus.CLOSED }, planId);
  const openTask = (planId: TaskId) => () => updatePlan({ isClosed: ClosedStatus.OPEN }, planId);
  const deleteTask = (planId: TaskId) => () => deletePlan(planId);
  const getTasks = () => {
    if (
      !plansResponse ||
      !plansResponse.value ||
      !plansResponse.value.data ||
      plansResponse.pending ||
      plansResponse.meta.url !== selectedFocusPlansApiLink
    ) {
      return [[], []];
    }

    return [
      sortByStartDate(plansResponse.value.data.filter(task => !task.attributes.isClosed)),
      sortByUpdatedDate(plansResponse.value.data.filter(task => task.attributes.isClosed === 1)),
    ];
  };
  const [openTasks, closedTasks] = getTasks();

  if (
    isCompassUserPage &&
    !!compassUser &&
    ((!openTasks && !closedTasks) ||
      (openTasks && !openTasks.length && closedTasks && !closedTasks.length))
  ) {
    return (
      <p className={classes.planPaper}>
        {t('compassPage.noPlan', {
          name: getUserDisplayName(compassUser, true),
        })}
      </p>
    );
  }

  return (
    <>
      {openTasks && openTasks.length > 0 && (
        <div id="plan-open-tasks-list">
          <PaperTitle>{t('plan.title.planned')}</PaperTitle>
          <PlanList>
            {openTasks.map(task => (
              <PlanListItem
                task={task}
                key={task.links.self}
                onToggle={completeTask(task.id)}
                onDelete={deleteTask(task.id)}
              />
            ))}
          </PlanList>
        </div>
      )}
      {closedTasks && closedTasks.length > 0 && (
        <div id="plan-closed-tasks-list">
          <PaperTitle>{t('plan.title.finished')}</PaperTitle>
          <PlanList>
            {closedTasks.map(task => (
              <PlanListItem
                task={task}
                key={task.links.self}
                onToggle={openTask(task.id)}
                onDelete={deleteTask(task.id)}
              />
            ))}
          </PlanList>
        </div>
      )}

      {reqPending && <DisableInteraction />}
    </>
  );
};

export default PlanListWrapper;
