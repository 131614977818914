import React, { ChangeEvent, useState } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import DefaultProfilePicture from '../../../assets/defaultProfilePicture-1258445428.jpg';
import { User } from '../../../config/api/models';
import security from '../../security';
import { useTranslation } from 'react-i18next';
import ProfilePicture from '../../../components/ProfilePicture';

type FileReaderResult = string | ArrayBuffer | null;

type StyleProps = {
  file: FileReaderResult;
  currentProfilePicture: string;
};

type Props = {
  onSubmit: (file: string) => void;
  onClose: () => void;
  submitting: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  editProfilePictureDialogRoot: {},
  dialogBodyInput: {
    width: '.1px',
    height: '.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
    '& + label': {
      position: 'relative',
      fontSize: '1.3rem',
      fontWeight: 300,
      lineHeight: '2rem',
      textTransform: 'none',
      boxShadow: 'none',
      borderRadius: '1.5rem',
      padding: '.2rem 1.5rem .3rem 1.5rem',
      '&:active': {
        boxShadow: 'none',
      },
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.medium,
      },
      cursor: 'pointer',
      display: 'inline-block',
      height: '2.5rem',
    },
  },
  fileUploadForm: {
    position: 'relative',
    textAlign: 'center',
  },
  dialogActions: {
    borderTop: theme.config.defaultBorder,
    margin: '2rem 0 -1rem -3rem',
    padding: '2rem 3rem 0 3rem',
    width: 'calc(100% + 6rem)',
  },
  uploadButton: {
    marginRight: '1rem',
  },
  profilePicture: (props: StyleProps) => ({
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '100%',
    backgroundImage: props.file
      ? 'url("' + props.file + '")'
      : 'url(' + props.currentProfilePicture + ')',
    backgroundSize: 'cover',
    borderRadius: '100%',
    marginBottom: '1.5rem',
  }),
}));

const EditProfilePictureDialog = ({ onSubmit, onClose, submitting }: Props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<FileReaderResult>(null);
  const currentUser: User = security.hooks.useUser();
  const currentProfilePicture =
    currentUser.media && currentUser.media.profile
      ? currentUser.media.profile
      : DefaultProfilePicture;
  const classes = useStyles({ file, currentProfilePicture });

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent) => {
        setFile(e.target && (e.target as FileReader).result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSubmit = () => {
    if (file !== null) {
      onSubmit(file as string);
    } else {
      onClose();
    }
  };

  return (
    <div className={classes.editProfilePictureDialogRoot}>
      <div className={classes.fileUploadForm}>
        <ProfilePicture profilePicture={typeof file === 'string' ? file : currentProfilePicture} />
        <input
          className={classes.dialogBodyInput}
          accept="image/*"
          id="contained-button-file"
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="contained-button-file">{t('forms.buttons.selectProfilePicture')}</label>
      </div>
      <div className={classes.dialogActions}>
        <Button
          disabled={!(file !== null && typeof file === 'string') || submitting}
          icon={Icons.SAVE}
          className={classes.uploadButton}
          onClick={handleSubmit}
        >
          {t('forms.buttons.save')}
        </Button>
        <Button color="secondary" icon={Icons.CANCEL} onClick={onClose}>
          {t('forms.buttons.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default EditProfilePictureDialog;
