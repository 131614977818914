import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { User } from '../config/api/models';
import ProfilePicture from './ProfilePicture';
import { Link } from '@material-ui/core';
import { getUserDisplayName } from '../helpers/getUserDisplayName';
import DefaultProfilePicture from './DefaultProfilePicture';

type Props = {
  user: User;
};

const useStyles = makeStyles((theme: Theme) => ({
  flyoutWrapper: {
    background: theme.palette.common.white,
    padding: '1rem 2rem 1rem 1rem',
    zIndex: 9,
    display: 'flex',
    alignItems: 'flex-start',
    boxShadow: '0 0 .5rem 0 rgba(0,0,0,.1)',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  picture: {
    width: '9rem',
    paddingRight: '2rem',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      width: '7rem',
      '& div': {
        margin: 0,
      },
    },
  },
  function: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
    marginBottom: '1rem',
  },
  userData: {
    padding: '1rem 0',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

const Flyout = ({ user }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.flyoutWrapper}>
      <div className={classes.picture}>
        {user.media && user.media.profile ? (
          <ProfilePicture profilePicture={user.media.profile} />
        ) : (
          <DefaultProfilePicture />
        )}
      </div>
      <div className={classes.userData}>
        <div>{getUserDisplayName(user, true)}</div>
        {user.function && <div className={classes.function}>{user.function}</div>}
        {user.organisation && <div>{user.organisation.name}</div>}
        {user.firstName && <Link href={'mailto:' + user.email}>{user.email}</Link>}
      </div>
    </div>
  );
};

export default Flyout;
