import React from 'react';
import { I18nRoutes } from './types';

const ClientRoutesContext = React.createContext<{
  privateRoutes: I18nRoutes | null;
  publicRoutes: I18nRoutes | null;
  routes: I18nRoutes | null;
}>({ privateRoutes: null, publicRoutes: null, routes: null });

export default ClientRoutesContext;
