// function getBase64Image(img: HTMLImageElement) {
//   const canvas = document.createElement('canvas');
//   canvas.width = img.width;
//   canvas.height = img.height;
//   const ctx = canvas.getContext('2d');
//   if (!ctx) {
//     return;
//   }
//   ctx.drawImage(img, 0, 0);
//   return canvas.toDataURL('image/jpg');
// }

function isBase64(imgUrl: string) {
  return imgUrl.startsWith('data:image');
}

export const downloadImage = (
  imageUrl: string,
  onSuccess: (base64Image?: string) => void,
  onError: () => void,
  isCancelled: boolean,
  options = { convertToBase64: true }
) => {
  if (isBase64(imageUrl)) {
    if (!isCancelled) {
      onSuccess(imageUrl);
    }
    return;
  }

  const img = new Image();
  if (options.convertToBase64) {
    // img.crossOrigin = 'anonymous'; // TODO cors issue
  }

  img.onerror = () => {
    if (!isCancelled) {
      onError();
    }
  };
  img.onload = () => {
    if (!isCancelled) {
      if (options.convertToBase64) {
        // TODO cors issue
        // const base64Image = getBase64Image(img);
        // onSuccess(base64Image);
        onSuccess(imageUrl);
      } else {
        onSuccess();
      }
    }
  };
  img.src = imageUrl;
};
