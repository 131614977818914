import MyShowcasePage from './scenes/MyShowcasePage';
import PublicShowcasePage from './scenes/PublicShowcasePage';
import ShowcaseTalentsPage from './scenes/ShowcaseTalentsPage';
import ShowcaseDreamDetailPage from './scenes/ShowcaseDreamDetailPage';
import showcaseReducer from './reducer';

const myShowcase = {
  MyShowcasePage,
  PublicShowcasePage,
  ShowcaseTalentsPage,
  ShowcaseDreamDetailPage,
  reducer: showcaseReducer,
};

export default myShowcase;
