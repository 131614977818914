import {
  makeFocusId,
  FocusId,
  DreamRequest,
  FocusRequest,
  LearnRequest,
  LookBackRequest,
} from '../../config/api/models';

export type State = {
  selectedFocusId?: FocusId;
  focusIds?: FocusId[];
  compassState: CompassState;
  initialFocusCreated: boolean;
};

export enum CompassState {
  COMPASS = 'compass',
  MYCOMPASS = 'myCompass',
  SHOWCASE = 'showcase',
  MYSHOWCASE = 'myShowcase',
}

interface SubmitValues<RequestType = DreamRequest | FocusRequest | LearnRequest> {
  request: RequestType;
  filesToAdd: File[];
  fileIdsToDelete: string[];
}

export type DreamSubmitValues = SubmitValues<DreamRequest>;

export type FocusSubmitValues = SubmitValues<FocusRequest>;

export type LearnSubmitValues = SubmitValues<LearnRequest>;

export type LookBackSubmitValues = SubmitValues<LookBackRequest>;

export const NEW_FOCUS_ID = makeFocusId('-1');
