import React from 'react';
import ForgotPasswordForm from '../containers/ForgotPasswordForm';
import Title from '../../../components/Title';
import Paragraph from '../../../components/Paragraph';
import { useTranslation } from 'react-i18next';
import ContentBackground from '../../../components/ContentBackground';
import Logo from '../../../components/Logo';
import { makeStyles } from '@material-ui/styles';
import CenteredPaper from '../../../components/CenteredPaper';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  forgotPasswordRoot: (props: StyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: props.isMobile ? '5rem' : '',
  }),
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    overflow: 'hidden',
  },
}));

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.forgotPasswordRoot}>
      <div className={classes.contentBackground}>
        <ContentBackground />
      </div>
      <Logo large />
      <CenteredPaper>
        <Title>{t('forgotPassword.title')}</Title>
        <Paragraph>{t('forgotPassword.intro')}</Paragraph>
        <ForgotPasswordForm />
      </CenteredPaper>
    </div>
  );
};

export default ForgotPasswordPage;
