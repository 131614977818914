import * as React from 'react';
import DefaultDreamCoverImage from './DefaultDreamCoverImage';
import { makeStyles, Theme } from '@material-ui/core';

type Props = {
  image?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '20rem',
    overflow: 'hidden',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  coverImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
  },
}));

const DreamCoverImage = ({ image }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!!image ? (
        <div className={classes.coverImage} style={{ backgroundImage: `url(${image})` }} />
      ) : (
        <DefaultDreamCoverImage />
      )}
    </div>
  );
};

export default DreamCoverImage;
