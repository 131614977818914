import React, { FC } from 'react';
import { IconButton as MuiIconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import clsx from 'clsx';
import Tooltip from '../../../components/Tooltip';

type Props = {
  disabled?: boolean;
  onClick: () => void;
  tooltip?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  cancelRelationRoot: {},
  tooltipInfo: {
    marginLeft: '0.5rem',
  },
  tooltipContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  tooltipButton: {
    '& svg': {
      color: theme.palette.grey['400'],
    },
  },
  tooltipButtonWithContext: {
    top: '2rem',
  },
}));

const CancelRelation: FC<Props> = ({ disabled, onClick, tooltip }) => {
  const classes = useStyles();

  const context = '';
  return (
    <Tooltip title={tooltip} placement="left">
      <MuiIconButton
        className={clsx(classes.tooltipButton, context && classes.tooltipButtonWithContext)}
        onClick={disabled ? undefined : onClick}
      >
        <SvgIcon icon={Icons.CANCEL} />
      </MuiIconButton>
    </Tooltip>
  );
};

export default CancelRelation;
