import { useContext } from 'react';
import InspirationDialogContext from '../context/InspirationDialogContext';

const useInspirationDialogContext = (componentName: string) => {
  const { isOpen, onToggle } = useContext(InspirationDialogContext);

  if (process.env.NODE_ENV === 'development' && typeof isOpen === 'undefined') {
    console.error(
      `It looks like you're using <${componentName} /> outside the context of the <InspirationDialogProvider />. You should wrap your component tree in a <InspirationDialogProvider />`
    );
  }

  return {
    isOpen,
    onToggle,
  };
};

export default useInspirationDialogContext;
