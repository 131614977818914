import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Quote from '../../../components/Quote';
import { Typography } from '@material-ui/core';
import Html from '../../../components/Html';

type Props = {
  text: string;
  quotedText: string;
  image: string;
  name: string;
  func: string;
  alt: string;
};

const useStyles = makeStyles(() => ({
  answerContentRoot: {
    marginBottom: '4rem',
  },
}));

const AnswerContent = ({ text, quotedText, image, name, func, alt }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.answerContentRoot}>
      <Typography variant="body2">
        <Html>{text}</Html>
      </Typography>
      <Quote quotedText={quotedText} name={name} func={func} alt={alt} image={image} />
    </div>
  );
};

export default AnswerContent;
