import { styled } from '@material-ui/styles';

const PlanList = styled('div')(({ theme }) => ({
  marginBottom: '3rem',
  '& > *:nth-child(2n+1)': {
    background: theme.palette.background.default,
  },
}));

export default PlanList;
