import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import EditablePanel from '../../../../components/EditablePanel';
import Sidebar from '../../../../components/Sidebar';
import IntroFocus from '../../components/Focus/IntroFocus';
import NewFocusForm from './NewFocusForm';
import inspiration from '../../../inspiration';
import ContentVisibilityButton from '../../../../components/ContentVisibilityButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDreamTourOpenFocusForm } from '../../../shared/selectors';
import { Actions } from '../../../shared/actions';

type Props = {
  onSubmit: () => void;
};

const useStyles = makeStyles({
  newFocusRoot: {},
});

const InitialFocus = ({ onSubmit }: Props) => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState(true);
  const dreamTourOpenFocusForm = useSelector(getDreamTourOpenFocusForm);
  const dispatch = useDispatch();

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setVisibility(!checked);
  };

  useEffect(() => {
    if (dreamTourOpenFocusForm) {
      setEditable(true);
      dispatch(Actions.disableDreamTourOpenFocusForm());
    }
  }, [dreamTourOpenFocusForm, setEditable, dispatch]);

  return (
    <div className={classes.newFocusRoot}>
      <inspiration.InspirationDialogProvider>
        <EditablePanel
          view={<IntroFocus onCreateFocus={() => setEditable(true)} />}
          form={
            <NewFocusForm
              onCancel={() => setEditable(false)}
              onSubmit={onSubmit}
              visibility={visibility}
            />
          }
          sidebar={
            editable ? (
              <Sidebar>
                <inspiration.InspirationSidebarButton />
                <ContentVisibilityButton
                  value={false}
                  onChange={handleVisibilityChange}
                  tooltip={t('focus.visibilityTooltip')}
                />
              </Sidebar>
            ) : (
              undefined
            )
          }
          component={editable ? undefined : 'div'}
          editable={editable}
          onToggle={() => setEditable(!editable)}
          showToggleButton={editable}
        />
      </inspiration.InspirationDialogProvider>
    </div>
  );
};

export default InitialFocus;
