import React from 'react';
import DateRangePicker, { Props as DateRangePickerProps } from 'react-daterange-picker';
import { dateStyles } from './DateStyles';
import moment from 'moment';

type Props = DateRangePickerProps & {};

const DateRange = (props: Props) => {
  const classes = dateStyles();
  let { value } = props;
  if (value && value.isUTC()) {
    value = moment(value.toISOString());
  }
  return (
    <div className={classes.dateRangePicker}>
      <DateRangePicker
        numberOfCalendars={1}
        firstOfWeek={1}
        singleDateRange={true}
        selectionType="single"
        {...props}
        value={value}
      />
    </div>
  );
};

export default DateRange;
