import { container } from 'react-redux-fetch';
import patchReducer from './patchReducer';
// @ts-ignore
import fetchRequest from 'react-redux-fetch/lib/middleware/fetchRequest';

const initReduxFetch = () => {
  container.registerRequestHeader('Accept', 'application/vnd.api+json');
  container.registerRequestHeader('Content-Type', 'application/vnd.api+json');
  container.registerRequestMethod('patch', {
    method: 'patch',
    middleware: fetchRequest,
    reducer: patchReducer,
  });
};

export default initReduxFetch;
