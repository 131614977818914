import { useContext } from 'react';
import ClientRoutesContext from './ClientRoutesContext';
import useCurrentLanguage from '../i18n/useCurrentLanguage';
import { RouteName } from './routeNames';
import { I18nRoutes } from './types';
import findKey from 'lodash/findKey';
import { Language } from '../i18n/types';

export const getRouteNameFromPath = (i18nRoutes: I18nRoutes | null, language: Language) => (
  path: string
): RouteName | null => {
  const pathSplit = path.split('/');
  for (let i = 0; i < pathSplit.length; i++) {
    if (Number(pathSplit[i])) {
      pathSplit[i] = '%%';
    }
  }

  const pathWithoutIds = pathSplit.join('/');

  return (
    (findKey(i18nRoutes, route => {
      const routeValue = route[language]
        .replace(':slug', '%%')
        .replace(':dreamId', '%%')
        .replace(':userId', '%%');

      return routeValue === pathWithoutIds;
    }) as RouteName) || null
  );
};

const useI18nPath = (routeName?: RouteName) => {
  const { publicRoutes, privateRoutes } = useContext(ClientRoutesContext);
  const currentLanguage = useCurrentLanguage();

  const routes = privateRoutes ? { ...publicRoutes, ...privateRoutes } : publicRoutes;

  return {
    path: routeName && routes ? routes[routeName][currentLanguage] : false,
    getPath: (value: RouteName, suffix?: string, language?: Language): string =>
      routes && routes[value]
        ? routes[value][language || currentLanguage] + (suffix ? `/${suffix}` : '')
        : '', // tslint:disable-line
    getRouteName: getRouteNameFromPath(routes, currentLanguage),
  };
};

export default useI18nPath;
