import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '../../../../helpers/useDisclosure';
import Button from '../../../../components/Button';
import AddCoacheesDialog from '../../containers/myGroups/AddCoacheesDialog';
import { GroupId } from '../../../../config/api/models';
import { Icons } from '../../../../components/SvgIcon';

type Props = {
  groupName: string;
  groupId: GroupId;
  groupRelationIds: number[];
};
const AddCoacheesButton = ({ groupName, groupId, groupRelationIds }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onToggle } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} icon={Icons.ADD}>
        {t('myCoach.myGroups.addCoacheesButton')}
      </Button>
      {isOpen && (
        <AddCoacheesDialog
          onToggle={onToggle}
          groupName={groupName}
          groupId={groupId}
          groupRelationIds={groupRelationIds}
        />
      )}
    </>
  );
};

export default AddCoacheesButton;
