import React, { useCallback, useState } from 'react';
import Joyride, { ACTIONS, Placement, STATUS } from 'react-joyride';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Actions } from '../actions';

const MilaCoachTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showTour, setShowTour] = useState(true);
  const dispatch = useDispatch();

  const steps = [
    {
      target: '#myCoach-content',
      content: t('tour.steps.milaCoach.first'),
      disableBeacon: true,
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#what-is-coaching-button',
      content: t('tour.steps.milaCoach.second'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#coach-coachee-list',
      content: t('tour.steps.milaCoach.third'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#coach-relation-overview',
      content: t('tour.steps.milaCoach.fourth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#to-conversation-button',
      content: t('tour.steps.milaCoach.fifth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#chat-context-button',
      content: t('tour.steps.milaCoach.sixth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#cancel-relation-button',
      content: t('tour.steps.milaCoach.seventh'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#coach-coachee-list',
      content: t('tour.steps.milaCoach.eight'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#notes-button',
      content: t('tour.steps.milaCoach.ninth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#compass-link-button',
      content: t('tour.steps.milaCoach.tenth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#chat-context-button',
      content: t('tour.steps.milaCoach.eleventh'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#chat-inspiration-button',
      content: t('tour.steps.milaCoach.twelfth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action, index } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.SKIPPED, STATUS.FINISHED].includes(status)
      ) {
        setShowTour(false);
      }

      if (action === ACTIONS.UPDATE && index === 7) {
        dispatch(Actions.enableMilaCoachTourSwitchToCoachees());
      }
    },
    [setShowTour, dispatch]
  );

  return (
    <>
      <Joyride
        steps={steps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        showProgress
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.close'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
    </>
  );
};

export default MilaCoachTour;
