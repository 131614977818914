import { ReactElement, useEffect, useState } from 'react';
import initI18n from './init';

type Props = {
  children: ReactElement | null;
  loading: ReactElement | null;
};

const I18nGate = ({ children, loading }: Props) => {
  const [i18nReady, setI18nReady] = useState(false);

  useEffect(() => {
    if (!i18nReady) {
      initI18n().then(() => setI18nReady(true));
    }
  }, [i18nReady]);

  if (!i18nReady) {
    return loading;
  }

  return children;
};

export default I18nGate;
