import React from 'react';
import CompleteRegistrationForm from '../containers/CompleteRegistrationForm';
import { RouteComponentProps } from 'react-router';
import Title from '../../../components/Title';
import Paragraph from '../../../components/Paragraph';
import { useTranslation } from 'react-i18next';
import { UserId } from '../../../config/api/models';

type Props = RouteComponentProps<{ userId: UserId; token: string }>;

const CompleteRegistrationPage = ({ match: { params } }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('completeRegistration.title')}</Title>
      <Paragraph>{t('completeRegistration.intro')}</Paragraph>
      <CompleteRegistrationForm userId={params.userId} token={params.token} />
    </>
  );
};

export default CompleteRegistrationPage;
