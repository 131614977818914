import React, { useState } from 'react';
import IntroDream from '../../components/Dream/IntroDream';
import EditablePanel from '../../../../components/EditablePanel';
import NewDreamForm from './NewDreamForm';
import Sidebar from '../../../../components/Sidebar';
import inspiration from '../../../inspiration';
import { NewDreamTour } from '../../../shared/tours';

type Props = {
  onSubmit: (dreamId?: string) => void;
};

const NewDream = ({ onSubmit }: Props) => {
  const [editable, setEditable] = useState(false);

  return (
    <>
      <inspiration.InspirationDialogProvider>
        <EditablePanel
          id="new-dream"
          view={
            <IntroDream
              onCreateDream={() => {
                setEditable(true);
              }}
            />
          }
          form={<NewDreamForm onCancel={() => setEditable(false)} onSubmit={onSubmit} />}
          sidebar={
            editable ? (
              <Sidebar>
                <inspiration.InspirationSidebarButton id="dream-inspiration-button" />
              </Sidebar>
            ) : (
              undefined
            )
          }
          component={editable ? undefined : 'div'}
          editable={editable}
          onToggle={() => setEditable(!editable)}
          showToggleButton={editable}
        />
      </inspiration.InspirationDialogProvider>
      {editable && <NewDreamTour />}
    </>
  );
};

export default NewDream;
