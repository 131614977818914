import React, { useState, useEffect } from 'react';
import { Switch, makeStyles, Theme, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Tooltip from './Tooltip';
import SvgIcon, { Icons } from './SvgIcon';

type Props = {
  id?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  value: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  tooltip?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  visibilityButtonRoot: {
    display: 'flex',
    padding: '0rem 1.6rem',
  },
  listItemTextPrimary: {
    fontSize: '1.4rem',
    marginLeft: '0.5rem',
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  infoButton: {
    color: theme.palette.grey['400'],
  },
}));

const ContentVisibilityButton = ({ id, onChange, value, onClick, disabled, tooltip }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [checkedState, setCheckedState] = useState<boolean>(value);

  useEffect(() => {
    setCheckedState(value);
  }, [value, setCheckedState]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event.preventDefault();
    setCheckedState(checked);
    onChange(event, checked);
  };

  return (
    <ListItem id={id} className={classes.visibilityButtonRoot}>
      <Tooltip title={tooltip} placement="top">
        <div className={classes.tooltipContainer}>
          <Switch
            color="primary"
            onChange={handleChange}
            checked={checkedState}
            onClick={event => (onClick ? onClick(event) : event.stopPropagation())}
            disabled={disabled}
          />
          <ListItemText classes={{ primary: classes.listItemTextPrimary }}>
            {t('sidebar.privateSwitch')}
          </ListItemText>
          {tooltip && (
            <IconButton className={classes.infoButton}>
              <SvgIcon icon={Icons.INFO} />
            </IconButton>
          )}
        </div>
      </Tooltip>
    </ListItem>
  );
};

export default ContentVisibilityButton;
