import React from 'react';
import LoginForm from '../../security/containers/LoginForm';
import { makeStyles } from '@material-ui/styles';
import LogoWithBaseLine from '../../../components/LogoWithBaseLine';
import { useTranslation } from 'react-i18next';
import PaperWithBackdrop from '../../../components/PaperWithBackdrop';
import { Typography, Link, useMediaQuery } from '@material-ui/core';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import theme from '../../../config/theme';
import { HashLink } from 'react-router-hash-link';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  sectionOne: (props: StyleProps) => ({
    display: 'flex',
    marginBottom: '10rem',
    flexWrap: 'wrap',
    maxHeight: props.isMobile ? '' : '100vh',
  }),
  sectionOneLeft: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '50%',
  }),
  sectionOneRight: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '50%',
    marginTop: props.isMobile ? '2rem' : '',
  }),
  subtitle: {
    fontWeight: 700,
    marginRight: '1.5rem',
  },
  flex: {
    display: 'flex',
  },
  centered: (props: StyleProps) => ({
    display: props.isMobile ? 'flex' : '',
    justifyContent: props.isMobile ? 'center' : '',
  }),
  mobile: {
    marginTop: '4rem',
  },
  link: {
    textDecoration: 'none',
  },
}));

const SectionOne = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  return (
    <div className={classes.sectionOne}>
      <div className={classes.sectionOneLeft}>
        <div className={classes.centered}>
          <LogoWithBaseLine />
        </div>
        {!isMobile && (
          <>
            <Typography variant="subtitle2" className={classes.subtitle}>
              {t('home.sectionOne.questionOne')}
            </Typography>
            <HashLink smooth to="#sectionEight" className={classes.link}>
              <Button icon={Icons.SAVE} blue>
                {t('home.sectionOne.button')}
              </Button>
            </HashLink>
            <div className={classes.flex}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                {t('home.sectionOne.questionTwo')}
              </Typography>
              <Link href={'mailto:dreambig@mycompass.be'}>{t('forms.buttons.contact')}</Link>
            </div>
          </>
        )}
      </div>
      <div className={classes.sectionOneRight}>
        <PaperWithBackdrop backdrop>
          <LoginForm />
        </PaperWithBackdrop>
        {isMobile && (
          <div className={classes.mobile}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              {t('home.sectionOne.questionOne')}
            </Typography>
            <HashLink smooth to="#sectionEight" className={classes.link}>
              <Button icon={Icons.SAVE} blue>
                {t('home.sectionOne.button')}
              </Button>
            </HashLink>
            <div className={classes.flex}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                {t('home.sectionOne.questionTwo')}
              </Typography>
              <Link href={'mailto:dreambig@mycompass.be'}>{t('forms.buttons.contact')}</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionOne;
