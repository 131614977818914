import React, { useEffect, useState } from 'react';
import Section from '../../../components/Section';
import profile from '../../profile';
import { useTranslation } from 'react-i18next';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import EditablePanel from '../../../components/EditablePanel';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import Html from '../../../components/Html';
import { useSelector } from 'react-redux';
import security from '../../security';
import useFetch from '../../../helpers/useFetch';
import { UpdateUserRequest, User } from '../../../config/api/models';
import { ApiConfig } from '../../profile/api';
import { Field, Form } from 'react-final-form';
import FormControl from '../../../components/FormControl';
import ButtonGroup from '../../../components/ButtonGroup';
import Button from '../../../components/Button';
import FormError from '../../../components/forms/FormError';
import Message from '../../../components/Message';

type UpdateUserFormValues = {
  hasPublicVisibleComments: number;
  allowReceivingWeeklyMail: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 700,
    },
    commentsForm: {
      width: '100%',
      marginTop: '2rem',

      '& > $formControlRoot': {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
      },
    },
    privacyWrapper: {
      padding: 0,
      maxWidth: `calc(100% - ${theme.config.sidebarWidth})`,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
      },
    },
    privacyContent: {
      flexWrap: 'wrap',
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
    },
    consent: {
      margin: '0 0 0 0.5rem',
    },
    submit: {
      marginBottom: '1rem',
    },
  })
);

const Settings = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [fulfilled, setFulfilled] = useState(false);
  const userId = useSelector(security.selectors.getUserId);
  const currentUser = security.hooks.useUser();
  const [updateUserResponse, updateUser] = useFetch<User>(ApiConfig.updateUser(userId));
  const initialPublicVisibleComments =
    currentUser.hasPublicVisibleComments !== null ? currentUser.hasPublicVisibleComments : 0;

  const initialAllowReceivingMail =
    currentUser.allowReceivingWeeklyMail !== null ? currentUser.allowReceivingWeeklyMail : 0;

  useEffect(() => {
    if (updateUserResponse && updateUserResponse.fulfilled && submitting) {
      setFulfilled(true);
    }
  }, [submitting, updateUserResponse, fulfilled]);

  return (
    <>
      <Section id="profile" title={t('profile.sectionTitle')}>
        <EditablePanel
          view={<profile.settings.View />}
          form={<profile.settings.Form />}
          showQuoteIcon={false}
        />
      </Section>
      <ExpansionPanel className={classes.privacyWrapper}>
        <ExpansionPanelSummary
          href="#"
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<SvgIcon icon={Icons.CHEVRON_DOWN} />}
        >
          <Typography className={classes.heading}>{t('profile.privacyTitle')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.privacyContent}>
          <Html>{t('privacy')}</Html>
          <div className={classes.commentsForm}>
            <Form
              onSubmit={(values: UpdateUserFormValues) => {
                const request: UpdateUserRequest = {
                  lastName: currentUser.lastName,
                  firstName: currentUser.firstName,
                  secondaryEmail: currentUser.secondaryEmail,
                  education: currentUser.education,
                  function: currentUser.function,
                  tourDone: currentUser.tourDone,
                  talentsTourDone: currentUser.talentsTourDone,
                  showcaseTourDone: currentUser.showcaseTourDone,
                  coachTourDone: currentUser.coachTourDone,
                  hasPublicVisibleComments: values.hasPublicVisibleComments,
                  allowReceivingWeeklyMail: values.allowReceivingWeeklyMail,
                };

                setSubmitting(true);
                updateUser(request);
              }}
              initialValues={{
                hasPublicVisibleComments: initialPublicVisibleComments,
                allowReceivingWeeklyMail: initialAllowReceivingMail,
              }}
              render={({ handleSubmit, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    label={t('publicCommentsVisible.consent')}
                    id="hasPublicVisibleComments"
                  >
                    <div className={classes.checkbox}>
                      <Field name="hasPublicVisibleComments" component="input" type="checkbox" />
                      <p className={classes.consent}>{t('publicCommentsVisible.title')}</p>
                    </div>
                  </FormControl>
                  <FormControl
                    label={t('AllowReceivingWeeklyMail.consent')}
                    id="allowReceivingWeeklyMail"
                  >
                    <div className={classes.checkbox}>
                      <Field name="allowReceivingWeeklyMail" component="input" type="checkbox" />
                      <p className={classes.consent}>{t('AllowReceivingWeeklyMail.title')}</p>
                    </div>
                  </FormControl>
                  <ButtonGroup>
                    <Button
                      type="submit"
                      disabled={(updateUserResponse && updateUserResponse.pending) || pristine}
                      icon={Icons.SAVE}
                      className={classes.submit}
                    >
                      {t('forms.buttons.save')}
                    </Button>
                  </ButtonGroup>
                  {updateUserResponse && updateUserResponse.rejected && (
                    <FormError>{t('forms.feedback.generic')}</FormError>
                  )}
                  {updateUserResponse && fulfilled && (
                    <Message type="success">{t('publicCommentsVisible.success')}</Message>
                  )}
                </form>
              )}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};

export default Settings;
