import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import TaskDialogForm from './TaskDialogForm';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getCompassState, getCompassUserId } from '../../selectors';
import { CompassState } from '../../model';
import { useCompassUser } from '../../../shared/hooks';
import { getMilaUserId } from '../../../shared/selectors';

const NewTask = () => {
  const [dialogIsOpen, openDialog] = useState(false);
  const { t } = useTranslation();
  const compassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = compassUser && compassUserId === milaUserId;
  const compassState = useSelector(getCompassState);

  const toggleDialog = () => openDialog(!dialogIsOpen);
  return (
    <Box display="flex" justifyContent="center" alignItems="flex-end">
      {compassState === CompassState.MYCOMPASS && (
        <Button onClick={toggleDialog} icon={Icons.PLUS}>
          {t('forms.buttons.addTask')}
        </Button>
      )}
      {compassState === CompassState.COMPASS && isMila && (
        <Button id="add-activity-button" icon={Icons.PLUS}>
          {t('forms.buttons.addTask')}
        </Button>
      )}

      {dialogIsOpen && (
        <TaskDialogForm
          onToggle={toggleDialog}
          titleText="plan.addTitle"
          saveBtnText="forms.buttons.addTask"
        />
      )}
    </Box>
  );
};

export default NewTask;
