export const ENABLE_BLUR = '[shared] enable blur';
export const DISABLE_BLUR = '[shared] disable blur';
export const SET_PERSIST_FILES = '[shared] set persist files';
export const ADD_NOTIFICATION = '[shared] add notification';
export const REMOVE_NOTIFICATIONS = '[shared] remove notifications';
export const ENABLE_HELP = '[shared] enable help';
export const DISABLE_HELP = '[shared] disable help';
export const ENABLE_DREAM_TOUR_OPEN_FOCUS_FORM = '[shared] enable dream tour open focus form';
export const DISABLE_DREAM_TOUR_OPEN_FOCUS_FORM = '[shared] disable dream tour open focus form';
export const ENABLE_MILA_COACH_TOUR_SWITCH_TO_COACHEES =
  '[shared] enable mila coach tour switch to coachees';
export const DISABLE_MILA_COACH_TOUR_SWITCH_TO_COACHEES =
  '[shared] disable mila coach tour switch to coachees';
export const ENABLE_MILA_NETWORK_TOUR_SWITCH_TO_COMMENTS =
  '[shared] enable mila network tour switch to comments';
export const DISABLE_MILA_NETWORK_TOUR_SWITCH_TO_COMMENTS =
  '[shared] disable mila network tour switch to comments';
export const UPDATE_PROGRESS_VIDEO = '[shared] update progress for video upload inside ckeditor';
export const SET_IS_CHANGING_PROFILE_PICTURE =
  '[shared] enable refetching the profile picture until processed and ready to be downloaded';
