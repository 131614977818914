import React, { useState, useEffect } from 'react';
import useFetch from '../../../../helpers/useFetch';
import {
  Dream,
  DreamId,
  DreamResponse,
  FileDTO,
  FileReaderResult,
} from '../../../../config/api/models';
import { ApiConfig } from '../../api';
import DreamForm from './DreamForm';
import { DreamSubmitValues } from '../../model';
import useFilesPersist from '../../../shared/hooks/useFilesPersist';
import { useSelector } from 'react-redux';
import { getPersistingFiles } from '../../../shared/selectors';

type Props = {
  onClose?: () => void;
  dream: Dream;
  dreamId: DreamId;
  files?: FileDTO[];
};

type UpdateState = null | 'submitting' | 'submitted';

// const updateDreamConfig = ApiConfig.updateDream();

const UpdateDreamForm = ({ onClose, dream, dreamId, files = [] }: Props) => {
  const [updateDreamResponse, updateDream] = useFetch(ApiConfig.updateDream(dreamId));
  const [, addDreamCoverImage] = useFetch<DreamResponse>(ApiConfig.addDreamCoverImage(dreamId));
  const [coverImage, setCoverImage] = useState<FileReaderResult>(
    !!dream.media.cover_photo ? dream.media.cover_photo : null
  );
  const persistFiles = useFilesPersist('dream', dreamId, onClose);
  const persistingFiles = useSelector(getPersistingFiles);
  const [updateState, setUpdateState] = useState<UpdateState>(null);

  const handleSave = (values: DreamSubmitValues) => {
    setUpdateState('submitting');
    updateDream(values.request);
    if (values.filesToAdd.length || values.fileIdsToDelete.length) {
      persistFiles({
        filesToAdd: values.filesToAdd,
        fileIdsToDelete: values.fileIdsToDelete,
      });
    }

    if (coverImage && dream.media.cover_photo !== coverImage) {
      addDreamCoverImage({
        photo: coverImage,
      });
    }
  };

  useEffect(() => {
    if (updateDreamResponse) {
      if (updateState === 'submitting' && updateDreamResponse.pending) {
        setUpdateState('submitted');
      }
      if (
        updateState === 'submitted' &&
        updateDreamResponse.fulfilled &&
        !updateDreamResponse.rejected &&
        !persistingFiles &&
        onClose
      ) {
        setUpdateState(null);
        onClose();
      }
    }
  }, [updateState, updateDreamResponse, persistingFiles, onClose]);

  return (
    <DreamForm
      onSave={handleSave}
      coverImage={coverImage}
      onSelectCoverImage={(file: FileReaderResult) => setCoverImage(file)}
      onClose={onClose}
      dreamResponse={updateDreamResponse}
      initialValues={{
        files,
        title: dream.title,
        body: dream.body,
      }}
    />
  );
};

export default UpdateDreamForm;
