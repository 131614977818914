import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EditablePanel from '../../../../components/EditablePanel';
import Sidebar from '../../../../components/Sidebar';
import NewLearnForm from './NewLearnForm';
import AddPostBlock from '../../components/AddPostBlock';
import inspiration from '../../../inspiration';
import { useSelector } from 'react-redux';
import { getCompassUserInfo } from '../../selectors';
import { useTranslation } from 'react-i18next';
import { getUserDisplayName } from '../../../../helpers/getUserDisplayName';
import { useCompassUser } from '../../../shared/hooks';
import ContentVisibilityButton from '../../../../components/ContentVisibilityButton';

type Props = {
  onSubmit: () => void;
};

const useStyles = makeStyles(() => ({
  newLearnRoot: {},
  learnPaper: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const NewLearn = ({ onSubmit }: Props) => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const { t } = useTranslation();

  const isCompassUserPage = useCompassUser();
  const compassUser = useSelector(getCompassUserInfo);

  const handleSubmit = () => {
    onSubmit();
  };

  const [visibility, setVisibility] = useState(true);

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setVisibility(!checked);
  };

  return (
    <div className={classes.newLearnRoot}>
      <inspiration.InspirationDialogProvider>
        <EditablePanel
          view={
            !isCompassUserPage ? (
              <AddPostBlock onClick={() => setEditable(true)} buttonText="forms.buttons.addLearn" />
            ) : (
              <Paper className={classes.learnPaper}>
                <p>
                  {t('compassPage.noLearn', {
                    name: getUserDisplayName(compassUser, true),
                  })}
                </p>
              </Paper>
            )
          }
          form={
            <NewLearnForm
              onCancel={() => setEditable(false)}
              onSubmit={handleSubmit}
              visibility={visibility}
            />
          }
          sidebar={
            editable ? (
              <Sidebar>
                <inspiration.InspirationSidebarButton />
                <ContentVisibilityButton value={false} onChange={handleVisibilityChange} />
              </Sidebar>
            ) : (
              undefined
            )
          }
          component={editable ? undefined : 'div'}
          editable={editable}
          onToggle={() => setEditable(!editable)}
          showToggleButton={editable}
        />
      </inspiration.InspirationDialogProvider>
    </div>
  );
};

export default NewLearn;
