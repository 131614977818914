import React, { useState, useEffect } from 'react';
import LearnForm from './LearnForm';
import useFetch from '../../../../helpers/useFetch';
import { LearnResponse } from '../../../../config/api/models';
import { ApiConfig } from '../../api';
import { LearnSubmitValues } from '../../model';
import useFilesPersist from '../../../shared/hooks/useFilesPersist';
import { getPersistingFiles } from '../../../shared/selectors';
import { useSelector } from 'react-redux';

type Props = {
  onCancel?: () => void;
  onSubmit: () => void;
  visibility: boolean;
};

const addLearnConfig = ApiConfig.addLearn();

const NewLearnForm = ({ onSubmit, onCancel, visibility }: Props) => {
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  const [learnId, setLearnId] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [addLearnResponse, addLearn] = useFetch<LearnResponse>(addLearnConfig);
  const persistFiles = useFilesPersist('learn', learnId, onSubmit);
  const persistingFiles = useSelector(getPersistingFiles);

  useEffect(() => {
    if (addLearnResponse && addLearnResponse.value && !addLearnResponse.pending && submitted) {
      if (filesToAdd.length) {
        setLearnId(addLearnResponse.value.data.id);
        if (learnId) {
          setFilesToAdd([]);
          persistFiles({
            filesToAdd,
            fileIdsToDelete: [],
          });
        }
      } else if (!persistingFiles) {
        onSubmit();
        setSubmitted(false);
      }
    }
  }, [addLearnResponse, filesToAdd, learnId, persistFiles, onSubmit, persistingFiles, submitted]);

  const handleSave = (values: LearnSubmitValues) => {
    setFilesToAdd(values.filesToAdd);
    addLearn(values.request);
    setSubmitted(true);
  };

  return (
    <LearnForm
      onSave={handleSave}
      onClose={onCancel}
      learnResponse={addLearnResponse}
      visibility={visibility}
    />
  );
};

export default NewLearnForm;
