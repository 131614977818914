import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FocusTabsBar from './FocusTabsBar';
import Focus from './Focus';
import Section from '../../../../components/Section';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import {
  ClosedStatus,
  DreamId,
  FocusId,
  FocusResponse,
  FocusTabs,
  Maybe,
} from '../../../../config/api/models';
import { makeStyles } from '@material-ui/styles';
import { IconButton as MuiIconButton, Theme } from '@material-ui/core';
import Button from '../../../../components/Button';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useCompassUser } from '../../../shared/hooks';
import {
  getCompassState,
  getCompassUserId,
  getInitialFocusCreated,
  getSelectedDreamTitle,
  getSelectedFocusId,
} from '../../selectors';
import { Actions } from '../../actions';
import { CompassState, NEW_FOCUS_ID } from '../../model';
import { useLocation } from 'react-router-dom';
import { InitialFocusTour } from '../../../shared/tours';
import { getMilaUserId } from '../../../shared/selectors';
import { StringParam, useQueryParam } from 'use-query-params';

type Props = {
  dreamId?: DreamId | null;
  children: React.ReactChild | React.ReactChild[];
};

const useStyles = makeStyles((theme: Theme) => ({
  focusTabsBarWrapper: {
    position: 'relative',
  },
  focusTabsBar: {
    width: 'calc(100% - ' + theme.config.sidebarWidth + ')',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 6rem)',
    },
  },
  addFocusButton: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  addFocusButtonSmall: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    '& .MuiButtonBase-root': {
      marginTop: 0,
    },
  },
  addFocusButtonSmallContent: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.medium,
    },
  },
}));

const loadFocusConfig = (focusId: FocusId) => ApiConfig.loadFocus(focusId);

const FocusWrapper = ({ dreamId, children }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [focusId] = useQueryParam('focus', StringParam);
  const focusIdFromSelector = useSelector(getSelectedFocusId);
  const selectedFocusId = (focusId as FocusId) || focusIdFromSelector;
  const selectedDreamTitle = useSelector(getSelectedDreamTitle);
  const selectedDreamId = dreamId;
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const compassState = useSelector(getCompassState);
  const dispatch = useDispatch();
  const location = useLocation();
  const initialFocusCreated = useSelector(getInitialFocusCreated);

  const [focusTabsFetch, loadFocusTabs] = useFetch<FocusTabs>(
    ApiConfig.loadFocusTabs(selectedDreamId || ('' as DreamId))
  );
  const [focusResponse] = useFetch<FocusResponse>(
    loadFocusConfig(selectedFocusId || ('' as FocusId))
  );

  const isLoading = !focusTabsFetch || !focusTabsFetch.value;
  const focusTabsData = useMemo(() => (focusTabsFetch && focusTabsFetch.value) || [], [
    focusTabsFetch,
  ]);
  const [previousFocusId, setPreviousFocusId] = useState<Maybe<FocusId>>(null);

  const setSelectedFocus = useCallback(
    (focusId: Maybe<FocusId>) => dispatch(Actions.selectFocus(focusId || undefined)),
    [dispatch]
  );

  const focusTabs = useMemo(
    () =>
      [
        ...focusTabsData,
        ...(selectedFocusId === NEW_FOCUS_ID
          ? [
              {
                title: t('focus.focus') + ' ' + (focusTabsData.length + 1),
                id: NEW_FOCUS_ID,
                isClosed: ClosedStatus.OPEN,
                isSuccessful: false,
              },
            ]
          : []),
      ].map((data, index) => ({
        tabName: data.title,
        tabIndex: index,
        tabValue: data.id,
        isClosed: data.isClosed === ClosedStatus.CLOSED,
        isSuccessful: data.isSuccessful,
      })),
    [focusTabsData, selectedFocusId, t]
  );

  const selectNewFocus = useMemo(
    () =>
      (selectedFocusId === NEW_FOCUS_ID && compassState !== CompassState.MYCOMPASS) ||
      (selectedFocusId !== NEW_FOCUS_ID &&
        focusTabsFetch &&
        focusTabsFetch.value &&
        focusTabsFetch.value.length &&
        focusTabsFetch.value.findIndex(f => f.id === selectedFocusId) === -1),
    [focusTabsFetch, selectedFocusId, compassState]
  );

  useEffect(() => {
    if (selectedDreamId) {
      loadFocusTabs(selectedDreamId);
    }
  }, [selectedDreamId, loadFocusTabs]);

  useEffect(() => {
    if (
      selectedDreamId &&
      focusResponse &&
      focusResponse.fulfilled &&
      ['PATCH'].includes(focusResponse.meta.method)
    ) {
      loadFocusTabs(selectedDreamId);
    }
  }, [selectedDreamId, loadFocusTabs, focusResponse]);

  useEffect(() => {
    if (selectNewFocus && focusTabsFetch && focusTabsFetch.value && focusTabsFetch.value[0]) {
      setSelectedFocus(focusTabsFetch.value[0].id);
    }
  }, [focusTabsFetch, selectNewFocus, setSelectedFocus, selectedFocusId]);

  useEffect(() => {
    if (
      compassState === CompassState.MYCOMPASS &&
      selectedFocusId &&
      focusTabsFetch &&
      focusTabsFetch.value &&
      !focusTabsFetch.value.length
    ) {
      setSelectedFocus(null);
    }
  });

  useEffect(() => {
    if (location.state) {
      if (location.state.focusId) {
        setSelectedFocus(location.state.focusId);
      }
    }
  }, [location, setSelectedFocus]);

  const isFirstFocus = useMemo(() => !selectedFocusId && focusTabs.length === 0, [
    selectedFocusId,
    focusTabs,
  ]);

  const handleAddFocus = useCallback(() => {
    setPreviousFocusId(selectedFocusId);
    setSelectedFocus(NEW_FOCUS_ID);
  }, [selectedFocusId, setSelectedFocus]);

  const handleRefetch = useCallback(
    (isInitialFocusCreated: boolean = false) => {
      if (isInitialFocusCreated) {
        dispatch(Actions.enableInitialFocusCreated());
      }

      setPreviousFocusId(null);
      if (selectedDreamId) {
        loadFocusTabs(selectedDreamId);
      }
    },
    [loadFocusTabs, selectedDreamId, dispatch]
  );

  if (
    isLoading ||
    (compassState !== CompassState.MYCOMPASS &&
      focusTabsFetch &&
      focusTabsFetch.value &&
      !focusTabsFetch.value.length) ||
    selectNewFocus
  ) {
    return null;
  }

  return (
    <Section
      id="i-focus"
      title={t('focus.title')}
      context={selectedDreamTitle || ''}
      tooltip={selectedFocusId && t('focus.inspiration')}
    >
      {focusTabs.length > 0 && (
        <div className={classes.focusTabsBarWrapper}>
          <div className={classes.focusTabsBar}>
            <FocusTabsBar
              selectedTab={selectedFocusId}
              tabs={focusTabs}
              onChange={setSelectedFocus}
            />
          </div>
          {compassState === CompassState.MYCOMPASS && (
            <div id="add-focus-button" className={classes.addFocusButton}>
              <Button icon={Icons.PLUS} onClick={() => handleAddFocus()}>
                {t('focus.add')}
              </Button>
            </div>
          )}
          {compassState === CompassState.COMPASS && isMila && (
            <div id="add-focus-button" className={classes.addFocusButton}>
              <Button icon={Icons.PLUS}>{t('focus.add')}</Button>
            </div>
          )}
          {!isCompassUser && (
            <div id="add-focus-button" className={classes.addFocusButtonSmall}>
              <MuiIconButton
                onClick={() => handleAddFocus()}
                className={classes.addFocusButtonSmallContent}
              >
                <SvgIcon icon={Icons.PLUS} />
              </MuiIconButton>
            </div>
          )}
        </div>
      )}
      <Focus
        isFirstFocus={isFirstFocus}
        focusId={selectedFocusId}
        refetch={handleRefetch}
        onCancel={() => {
          setSelectedFocus(previousFocusId);
          setPreviousFocusId(null);
        }}
        children={children}
      />
      {initialFocusCreated && <InitialFocusTour />}
    </Section>
  );
};

export default React.memo(FocusWrapper);
