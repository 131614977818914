import { RootState } from '../../config/store/types';
import { DreamId, Maybe, User, UserResponse, UserId } from '../../config/api/models';
import { RESOURCE } from './api';
import { getCompassUserResponse } from '../myCompass/selectors';
import { useCompassUser } from '../shared/hooks';

export const getJWt = (state: RootState) => state.auth.token;
export const getUserId = (state: RootState) => state.auth.userId;
export const getUser = (state: RootState): Maybe<User> =>
  state.repository.getIn(['user', 'value', 'data', 'attributes'], null);

export const getUserResponse = (state: RootState): Maybe<UserResponse> => {
  const userResponse = state.repository.getIn([RESOURCE.USER, 'value']) as UserResponse;

  return userResponse || null;
};

export const getFirstDreamId = (state: RootState): Maybe<DreamId> => {
  const dreamId = state.repository.getIn(
    ['user', 'value', 'data', 'relationships', 'dreams', 'data', '0', 'id'],
    null
  );

  return dreamId ? dreamId : state.repository.getIn(['dream', 'value', 'data', 'id']);
};

export const getRelationLink = (compassUserId?: UserId) => (state: RootState): Maybe<string> => {
  const userResponse = compassUserId ? getCompassUserResponse(state) : getUserResponse(state);

  if (!userResponse || (compassUserId && userResponse.data.id !== compassUserId)) {
    return null;
  }
  return userResponse.data.relationships.talents
    ? userResponse.data.relationships.talents.links.related
    : null;
};

export const getDreamLink = (userId?: UserId) => (state: RootState): Maybe<string> => {
  const isCompassUser = useCompassUser();
  const userResponse = isCompassUser ? getCompassUserResponse(state) : getUserResponse(state);

  if (!userResponse || (userId && userResponse.data.id !== userId)) {
    return null;
  }
  return userResponse.data.relationships.dreams
    ? userResponse.data.relationships.dreams.links.related
    : null;
};
