import React from 'react';
import FocusSection from '../../myCompass/containers/Focus/FocusSection';
import { useSelector } from 'react-redux';
import { getShowcase } from '../selectors';
import { getSelectedFocusId } from '../../myCompass/selectors';
import {
  getSelectedContext,
  getShowcaseFocusMapForSelectedDream,
} from '../helpers/helperFunctions';
import ShowcaseBlock from '../components/ShowcaseBlock';
import { DreamId, LearnId } from '../../../config/api/models';

type Props = {
  dreamId: DreamId;
};

const ShowcaseLearnWrapper = ({ dreamId }: Props) => {
  const showcase = useSelector(getShowcase());
  const selectedFocusId = useSelector(getSelectedFocusId);

  if (!showcase || !selectedFocusId || !showcase.learnings.length) {
    return null;
  }

  const focusMap = getShowcaseFocusMapForSelectedDream(showcase, dreamId, selectedFocusId);
  const learningMap = focusMap ? focusMap.learnings : [];
  const learnings = showcase.learnings.filter(learn =>
    learningMap.map(l => l.id).includes(learn.entityId)
  );

  if (!learnings.length || !learningMap.length) {
    return null;
  }

  const getCommentsList = (id: LearnId) => {
    const learn = learningMap.find(l => l.id === id);
    if (learn) {
      return showcase.comments.filter(c =>
        learn!.comments.map(lc => lc.toString()).includes(c.entityId)
      );
    }
    return [];
  };
  const context = getSelectedContext(showcase, selectedFocusId);

  return (
    <FocusSection id="i-learn" title="iLearn" context={context}>
      {learnings.map(learn => (
        <ShowcaseBlock
          key={learn.entityId}
          context={learn}
          comments={[...getCommentsList(learn.entityId)]}
          modelType="learn"
        />
      ))}
    </FocusSection>
  );
};

export default ShowcaseLearnWrapper;
