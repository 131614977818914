import React from 'react';
import profile from '../../profile';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCompassState } from '../selectors';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserId } from '../../../config/api/models';
import { CompassState } from '../model';

type Props = RouteComponentProps<{ userId: UserId }> & {
  children: React.ReactChild | React.ReactChild[];
  color?: string;
  showcaseDreamsVisible?: boolean;
  onShowcaseDreamsClick?: () => void;
};

const PageContentWrapper = ({
  children,
  color,
  showcaseDreamsVisible,
  onShowcaseDreamsClick,
}: Props) => {
  const { t } = useTranslation();
  const hash = window.location.hash.replace('#i-', '');
  const compassState = useSelector(getCompassState);
  const nav =
    compassState === CompassState.MYCOMPASS || compassState === CompassState.COMPASS
      ? ['dream', 'focus', 'plan', 'learn', 'lookBack']
      : ['dream', 'focus', 'learn', 'lookBack', 'milestone'];
  const activeMenuItemIndex = nav.findIndex(navItem => hash === navItem.toLowerCase());

  const navItems = nav.map(navItem => (
    <Link smooth to={`#i-${navItem.toLowerCase()}`} key={navItem}>
      {t(`${navItem}.menuItem`)}
    </Link>
  ));

  return (
    <profile.PageContentWrapperWithStickyUserMenu
      navigation={{ navItems, activeMenuItemIndex }}
      color={color}
      showcaseDreamsVisible={showcaseDreamsVisible}
      onShowcaseDreamsClick={onShowcaseDreamsClick}
    >
      {children}
    </profile.PageContentWrapperWithStickyUserMenu>
  );
};

export default withRouter(PageContentWrapper);
