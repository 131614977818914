import React, { useCallback, useState } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { Redirect } from 'react-router';
import { useCompassUser } from '../hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../../myCompass/selectors';
import { getMilaUserId } from '../selectors';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as SharedApiConfig } from '../api';
import { getFirstDreamId } from '../../security/selectors';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import useUser from '../../security/hooks/useUser';

let userPatched: boolean = false;

const DreamsOverviewTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const currentUser = useUser();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const [, patchUser] = useFetch<UserResponse>(SharedApiConfig.updateTourDone());
  const [showTour, setShowTour] = useState(true);
  const [tourDone, setTourDone] = useState(false);
  const userFirstDreamId = useSelector(getFirstDreamId);

  const milaSteps = [
    {
      target: '#dreams-section',
      content: t('tour.steps.dreamsOverview.first'),
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#add-dream-card',
      content: t('tour.steps.dreamsOverview.second'),
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const steps = [
    ...milaSteps,
    {
      target: '#dream-card',
      content: t('tour.steps.dreamsOverview.third'),
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if ([ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action)) {
        setShowTour(false);
      } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        setShowTour(false);
        setTourDone(true);
      }

      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
      ) {
        if (!userPatched) {
          const userUpdate = {
            tourDone: 1,
          };
          patchUser(currentUser.entityId, userUpdate);
          userPatched = true;
        }
      }
    },
    [setShowTour, setTourDone, currentUser, patchUser]
  );

  return (
    <>
      <Joyride
        steps={isMila ? milaSteps : steps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        showProgress
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: isMila ? t('tour.locale.close') : t('tour.locale.acceptChallenge'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      {!isMila && tourDone && userFirstDreamId && (
        <Redirect
          to={getPath('dreamDetail')
            .replace(':userId', currentUser.entityId)
            .replace(':dreamId', userFirstDreamId)}
        />
      )}
    </>
  );
};

export default DreamsOverviewTour;
