import * as React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';
import Tooltip from '../../../components/Tooltip';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../actions';
import { useMemo } from 'react';
import { useCompassUser } from '../hooks';
import { getCompassUserId } from '../../myCompass/selectors';
import { getHelp, getMilaUserId } from '../selectors';
import { useLocation } from 'react-router-dom';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';

type Props = {
  color?: string;
  onClick?: () => void;
};

type StyleProps = {
  isMobile?: boolean;
};

const useStyles = makeStyles(() => ({
  wrapper: ({ isMobile }: StyleProps) => ({
    position: 'fixed',
    bottom: isMobile ? '2rem' : '10rem',
    right: 0,
    width: isMobile ? '7rem' : '10rem',
    zIndex: 99,
    cursor: 'pointer',

    '& .mainTriangle': {
      transformOrigin: 'center center',
      transition: 'all .25s ease-out',
    },

    '&:hover': {
      '& .mainTriangle': {
        transform: 'translate3d(0, 0, 0) rotate(-30deg)',
      },
    },
  }),
}));

const HelpButton = ({ color, onClick }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const buttonColor = color ? color : '#67bea4';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const isHelpActive = useSelector(getHelp);
  const location = useLocation();
  const { getPath } = useI18nPath();

  const handleClick = () => {
    if (!!onClick) {
      dispatch(Actions.enableHelp());
      onClick();
    }
  };

  const shouldDisplay = useMemo(() => {
    return !(isMila || isHelpActive || isCompassUser || location.pathname === getPath('settings'));
  }, [isMila, isHelpActive, isCompassUser, location, getPath]);

  if (!shouldDisplay) return null;

  return (
    <div id="help-button" className={classes.wrapper}>
      <Tooltip title={t('tour.tooltip')} placement="top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 68.6 76.7"
          onClick={handleClick}
        >
          <path
            fill={buttonColor}
            d="M30.6 67.7l65.6 8.6c1.3.2 2.6-.3 3.4-1.4.8-1 1-2.2.6-3.3-.1-.2-.1-.4-.2-.6L75.8 10.3c-.2-.7-.6-1.3-1.2-1.8-1.6-1.3-3.9-1-5.2.6L28 61.8c-1.3 1.6-1 3.9.6 5.2.6.4 1.3.7 2 .7z"
          />
          <path
            fill="#fff"
            d="M30.6 67.7l65.6 8.6c1.3.2 2.6-.3 3.4-1.4.8-1 1-2.2.6-3.3-.1-.2-.1-.4-.2-.6L75.8 10.3c-.2-.7-.6-1.3-1.2-1.8-1.6-1.3-3.9-1-5.2.6L28 61.8c-1.3 1.6-1 3.9.6 5.2.6.4 1.3.7 2 .7z"
            opacity=".5"
          />
          <path
            className="mainTriangle"
            fill={buttonColor}
            d="M26.2 3.2L1.6 56c-.5 1-.4 2.2.2 3.2.6.9 1.6 1.4 2.6 1.4H5l57.4-4.2c.6 0 1.2-.2 1.8-.6 1.5-1 1.9-3 .9-4.5L31.9 2.6c-1-1.5-3-1.9-4.5-.9-.6.4-1 .9-1.2 1.5z"
          />
          <path
            fill="#464545"
            d="M31.5 22.7c-8.1 0-14.7 6.6-14.7 14.7 0 4.5 2.1 8.6 5.3 11.3-2.4 3.7-7.1 4.8-7.1 4.8 4.5 2 8.3.4 11.3-2.4 1.6.6 3.4 1 5.2 1 8.1 0 14.7-6.6 14.7-14.7 0-8.2-6.6-14.7-14.7-14.7z"
          />
          <path
            fill="#fff"
            d="M37.8 33.9c0 1.1-.7 2.2-2 3.2-.4.3-1.6 1-3.6 2.2-1.3.7-2 1.4-2 1.8 0 .1.1.4.3.7.2.3.3.6.3.9v.2c-.2.1-.4.1-.6.1-.5 0-1-.2-1.6-.6-.6-.4-.9-.9-.9-1.4 0-.9.6-1.7 1.9-2.5 1.2-.7 2.4-1.3 3.6-2 1.3-.8 1.9-1.6 1.9-2.5 0-.6-.3-1.2-.8-1.5-.5-.3-1-.5-1.7-.5-.6 0-1.5.3-2.5.8-1.1.5-1.8 1-2.3 1.5.6.2 1 .4 1 .8 0 .3-.2.6-.5.9-.4.3-.7.4-1 .4-.4 0-.9-.2-1.4-.6-.5-.4-.8-.8-.8-1.3 0-.7.6-1.5 1.7-2.4 1-.8 1.9-1.4 2.7-1.6 1.2-.4 2.1-.7 2.8-.7 1.2 0 2.4.4 3.6 1.2 1.2.8 1.9 1.8 1.9 2.9zm-6.6 11.4c0 .3-.2.5-.7.8-.4.2-.8.4-1.1.4-.3 0-.7-.1-1-.4-.4-.3-.6-.5-.6-.9 0-.3.2-.6.5-.8.3-.2.6-.3.9-.3.4 0 .8.1 1.3.4.4.2.7.5.7.8z"
          />
        </svg>
      </Tooltip>
    </div>
  );
};

export default HelpButton;
