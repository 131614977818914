import React from 'react';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Dream } from '../../../../config/api/models';
import useI18nPath from '../../../../config/clientRoutes/useI18nPath';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import security from '../../../security';
import DreamCoverImage from './DreamCoverImage';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import { getCompassState } from '../../selectors';
import { CompassState } from '../../model';
import { useCompassUser } from '../../../shared/hooks';

type Props = {
  dream: Dream;
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    display: 'block',
    borderRadius: theme.shape.borderRadius,
    border: theme.config.defaultBorder,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 15px 0 rgba(0,0,0,.25)',
      border: 'none',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    height: '25rem',
    border: 'none',
  },
  info: {
    position: 'relative',
    height: '5rem',
    lineHeight: '5rem',
    padding: '0 1.5rem 0 7.5rem',
  },
  iDreamLabel: {
    position: 'absolute',
    top: '50%',
    left: '1.5rem',
    transform: 'translateY(-50%)',
    fontSize: '1.4rem',
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: '1.6rem',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconWrapper: {
    position: 'absolute',
    top: '5rem',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '10rem',
    height: '10rem',
    background: theme.palette.common.white,
    border: `.3rem solid ${theme.palette.grey[200]}`,
    borderRadius: '50%',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const DreamCard = ({ dream }: Props) => {
  const classes = useStyles();
  const { getPath } = useI18nPath();
  const compassState = useSelector(getCompassState);
  const { userId } = useParams();
  const isCompassUser = useCompassUser();
  const currentUserId = useSelector(security.selectors.getUserId);
  const userToUse = isCompassUser ? userId : currentUserId;

  const link =
    compassState === CompassState.MYSHOWCASE
      ? getPath('showcaseDreamDetail')
          .replace(':userId', userToUse)
          .replace(':dreamId', dream.entityId)
      : getPath('dreamDetail')
          .replace(':userId', userToUse)
          .replace(':dreamId', dream.entityId);

  return (
    <Link to={link} className={classes.link} id="dream-card">
      <Paper className={classes.paper}>
        <DreamCoverImage image={dream.media.cover_photo} />
        <div className={classes.info}>
          <div className={classes.iDreamLabel}>iDream</div>
          <div className={classes.title}>{dream.title}</div>
        </div>
        {!dream.media.cover_photo && (
          <div className={classes.iconWrapper}>
            <SvgIcon size={3.2} color="#2aafe8" className={classes.icon} icon={Icons.DREAM} />
          </div>
        )}
      </Paper>
    </Link>
  );
};

export default DreamCard;
