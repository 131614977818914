import React from 'react';

import { FieldRenderProps } from 'react-final-form';
import moment, { Moment } from 'moment';
import { extendMoment, MomentRange } from 'moment-range';
import DateRange from './DateRange';

// @ts-ignore
extendMoment(moment);

type StartEnd = {
  start: Moment;
  end: Moment;
};

type Props = FieldRenderProps<StartEnd | MomentRange | undefined, any> & {
  helperText?: string;
};

const isMomentRange = (range: StartEnd | MomentRange): range is MomentRange => {
  // @ts-ignore
  return typeof range.isRange !== 'undefined' && range.isRange();
};

const convertToMomentRange = (range: StartEnd) => {
  const startIsValid = range.start && range.start.isValid();
  const endIsValid = range.end && range.end.isValid();

  return ((moment as unknown) as MomentRange).range(
    startIsValid ? range.start : moment(),
    endIsValid ? range.end : startIsValid ? range.start : moment()
  );
};

const DateRangeAdapter = ({ input: { onChange, value } }: Props) => {
  const range =
    typeof value !== 'undefined'
      ? isMomentRange(value)
        ? value
        : convertToMomentRange(value)
      : value;

  return <DateRange onSelect={onChange} value={range} />;
};

export default DateRangeAdapter;
