import React, { useState, useEffect, ReactElement } from 'react';
import PanelWithSidebar from '../../../../components/PanelWithSidebar';
import Sidebar from '../../../../components/Sidebar';
import SidebarSharing from '../../../shared/components/SidebarSharing';
import SharingDialog, { SharingDialogState } from '../../../shared/components/SharingDialog';
import {
  MilestoneId,
  Milestone as MilestoneType,
  User,
  PostsResponse,
  Post,
  MilestonesResponse,
} from '../../../../config/api/models';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MilestoneBackground from './MilestoneBackground';
import clsx from 'clsx';
import ProfilePicture from '../../../../components/ProfilePicture';
import useUser from '../../../security/hooks/useUser';
import { getUserDisplayName } from '../../../../helpers/getUserDisplayName';
import CommunicationWrapper from '../../components/CommunicationWrapper';
import ButtonGroup from '../../../../components/ButtonGroup';
import { useMediaQuery } from '@material-ui/core';
import { default as themeConfig } from '../../../../config/theme';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig as SharedApiConfig } from '../../../shared/api';
import { useSelector } from 'react-redux';
import {
  getSelectedFocusResponse,
  getCompassState,
  getSelectedDreamResponse,
  getCompassUserId,
} from '../../selectors';
import { CompassState } from '../../model';
import ShowcaseSidebarOptions, {
  ShowcaseSidebarValues,
} from '../../../myShowcase/components/ShowcaseSidebarOptions';
import { ApiConfig } from '../../api';
import CompassLinkButton from '../../../../components/CompassLinkButton';
import { getMilaUserId } from '../../../shared/selectors';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type Props = {
  withSidebar?: boolean;
  commentable?: boolean;
  milestone: MilestoneType;
  user?: User;
  withContext?: boolean;
  suffixComponent?: ReactElement;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '35rem',
    position: 'relative',
    color: 'white',
    borderRadius: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  rootMobile: {
    minHeight: '25rem',
  },
  background: {
    background: 'linear-gradient(#33edc4, #29e8b3)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
    borderRadius: theme.shape.borderRadius,
  },
  backgroundWithSidebar: {
    borderTopRightRadius: 0,
  },
  backgroundWithComments: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  backgroundMobile: {
    borderRadius: 0,
  },
  optionButtons: {
    position: 'absolute',
    width: '100%',
    padding: '1rem',
    top: 0,
    display: 'flex',
    flexDirection: 'row-reverse',
    zIndex: 2,
  },
  content: {
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '1rem 2rem',
    wordBreak: 'break-word',
  },
  profilePicture: {
    width: '12rem',
    height: '12rem',
    border: '0.5rem solid white',
    borderRadius: 100,
  },
  username: {
    fontSize: '3rem',
    margin: '2rem 0 1rem',
    fontWeight: 'bold',
  },
  text: {
    fontSize: '1.6rem',
  },
  achievedAt: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginTop: '1.5rem',
  },
}));

const Milestone = ({
  withSidebar,
  commentable,
  milestone,
  user,
  withContext,
  suffixComponent,
}: Props) => {
  const [sharingDialog, setSharingDialogState] = useState<undefined | SharingDialogState>();
  const [currentPost, setCurrentPost] = useState<Post>();

  const [postRequest] = useFetch<PostsResponse>(SharedApiConfig.posts());
  const [, updateMilestone] = useFetch<MilestonesResponse>(ApiConfig.updateMilestone());

  const classes = useStyles();
  const currentUser = useUser();
  const isMobile = useMediaQuery(themeConfig.breakpoints.down('xs'));
  const focusResponse = useSelector(getSelectedFocusResponse);
  const dreamResponse = useSelector(getSelectedDreamResponse);
  const compassState = useSelector(getCompassState);
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);

  const { t } = useTranslation();

  useEffect(() => {
    if (postRequest && postRequest.value) {
      const posts = postRequest.value.data.map(d => d.attributes);
      const current = posts.find(
        p =>
          p.postId === milestone.entityId &&
          p.postType === 'milestone' &&
          p.authorId === currentUser.entityId
      );
      setCurrentPost(current);
    }
  }, [postRequest, setCurrentPost, currentUser, milestone]);

  if (!milestone) {
    return null;
  }

  const finalUser = user || currentUser;
  const canShare = focusResponse && focusResponse.data.attributes.visibility;
  const disableShowcaseToggles = Boolean(
    !(
      focusResponse &&
      focusResponse.data.attributes.includeShowcase &&
      dreamResponse &&
      dreamResponse.data.attributes.includeShowcase &&
      milestone.visibility
    ) || compassUserId === milaUserId
  );

  const handleShowcaseChange = (values: ShowcaseSidebarValues) => {
    updateMilestone(milestone.entityId, values);
  };

  const getSidebar = () => {
    if (!withSidebar) {
      return undefined;
    }
    switch (compassState) {
      case CompassState.MYCOMPASS:
        return (
          <Sidebar>
            <SidebarSharing
              onClick={setSharingDialogState}
              currentPost={currentPost}
              disabled={!canShare}
            />
          </Sidebar>
        );
      case CompassState.COMPASS:
        return (
          <Sidebar>
            <SidebarSharing
              onClick={setSharingDialogState}
              currentPost={currentPost}
              disabled={compassUserId === milaUserId}
            />
          </Sidebar>
        );
      case CompassState.MYSHOWCASE:
        return (
          <Sidebar>
            <ShowcaseSidebarOptions
              disabled={disableShowcaseToggles}
              onChange={handleShowcaseChange}
              values={{
                includeShowcase: milestone.includeShowcase,
                includeShowcaseComments: milestone.includeShowcaseComments,
                includeShowcaseFiles: milestone.includeShowcaseFiles,
              }}
            />
          </Sidebar>
        );
      default:
        return undefined;
    }
  };

  return (
    <>
      <PanelWithSidebar
        noPadding
        fullWidth={!withSidebar}
        sidebar={getSidebar()}
        suffixComponent={
          suffixComponent ? (
            suffixComponent
          ) : commentable ? (
            <CommunicationWrapper
              context={milestone}
              contextType="milestone"
              disabled={
                compassState === CompassState.SHOWCASE || compassState === CompassState.MYSHOWCASE
              }
            />
          ) : (
            undefined
          )
        }
      >
        <div className={clsx(classes.root, isMobile ? classes.rootMobile : null)}>
          <MilestoneBackground
            className={clsx(
              classes.background,
              withSidebar && getSidebar() ? classes.backgroundWithSidebar : null,
              commentable ? classes.backgroundWithComments : null,
              isMobile ? classes.backgroundMobile : null
            )}
          />
          <ButtonGroup className={classes.optionButtons}>
            {withContext && <CompassLinkButton userId={finalUser.entityId} variant="green" />}
          </ButtonGroup>
          <div className={classes.content}>
            <div className={classes.profilePicture}>
              <ProfilePicture profilePicture={finalUser.media.profile} />
            </div>
            <div className={classes.username}>{getUserDisplayName(finalUser, true)}</div>
            <div className={classes.text}>
              {t('milestone.message').replace('%TITLE%', milestone.focus.title)}
            </div>
            <div className={classes.achievedAt}>
              {t('milestone.achieved_at').replace(
                '%DATE%',
                moment(milestone.createdAt).format('L')
              )}
            </div>
          </div>
        </div>
      </PanelWithSidebar>
      <SharingDialog
        dialogState={sharingDialog}
        onClose={() => setSharingDialogState(undefined)}
        postType="milestone"
        postId={milestone.entityId || ('' as MilestoneId)}
        currentPost={currentPost}
      />
    </>
  );
};

export default Milestone;
