import { Middleware } from 'redux';
import { FETCH } from 'react-redux-fetch';
import { Actions } from '../actions';
import get from 'lodash/fp/get';
import { getJWt } from '../selectors';

const getErrorStatus = get('reason.cause.errors[0].status');
const UNAUTHORIZED = '401';

const logoutOnInvalidToken: Middleware = store => next => action => {
  next(action);

  if (
    action.type === FETCH.for('GET').REJECT &&
    getErrorStatus(action) === UNAUTHORIZED &&
    getJWt(store.getState())
  ) {
    store.dispatch(Actions.logout());
  }
};

export default logoutOnInvalidToken;
