import React, { useMemo, useState } from 'react';
import { CKEditor, CKEditorProps } from '@ckeditor/ckeditor5-react';
import '@ckeditor/ckeditor5-build-classic-with-video/build/translations/nl';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic-with-video';
import fileUploadAdapterPlugin from './ckeditorAdapters/fileUploadAdapterPlugin';
import { makeStyles } from '@material-ui/styles';
import { Box, Snackbar, Theme } from '@material-ui/core';
import insertEnterAfterMedia from './util/insertEnterAfterMedia';
import ProgressBar from './ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_REJECTED, UPDATE_PROGRESS_VIDEO } from './ckeditorAdapters/events';
import { Actions } from '../../../modules/shared/actions';
import { Video } from '../../../modules/shared/model';
import { getUploadingVideos } from '../../../modules/shared/selectors';
import FormError from '../FormError';
import { useTranslation } from 'react-i18next';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';

type Props = Partial<CKEditorProps> & { placeholder?: string };

const useStyles = makeStyles((theme: Theme) => ({
  ckEditorWrapper: theme.config.ckEditor.htmlStyles,
}));

const Editor = (props: Props) => {
  const classes = useStyles();
  const { placeholder } = props;
  const dispatch = useDispatch();
  const uploadingVideos = useSelector(getUploadingVideos);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const wproofReaderLanguage = useMemo(() => {
    switch (language) {
      case 'nl':
        return 'nl_NL';
      case 'fr':
        return 'fr_FR';
      case 'en':
        return 'en_GB';
      default:
        return 'nl_NL';
    }
  }, [language]);

  return (
    <div className={classes.ckEditorWrapper}>
      <CKEditor
        editor={ClassicEditor}
        config={{
          image: {
            // You need to configure the image toolbar, too, so it uses the new style buttons.
            toolbar: [
              'imageTextAlternative',
              '|',
              'imageStyle:alignLeft',
              'imageStyle:block',
              'imageStyle:alignRight',
            ],
            styles: [
              // This option is equal to a situation where no style is applied.
              'block',

              // This represents an image aligned to the left.
              'alignLeft',

              // This represents an image aligned to the right.
              'alignRight',
            ],
          },
          video: {
            upload: {
              types: ['mp4', 'webm', 'ogg'],
              allowMultipleFiles: false,
            },
            styles: ['block', 'alignLeft', 'alignCenter', 'alignRight'],

            // You need to configure the video toolbar, too, so it shows the new style
            // buttons as well as the resize buttons.
            toolbar: [
              'videoStyle:block',
              'videoStyle:alignLeft',
              'videoStyle:alignCenter',
              'videoStyle:alignRight',
            ],
          },
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'numberedList',
            'bulletedList',
            'alignment',
            '|',
            'mediaEmbed',
            'imageUpload',
            'videoUpload',
            'imageStyle:alignLeft',
            'imageStyle:block',
            'imageStyle:alignRight',
            '|',
            'undo',
            'redo',
            'wproofreader',
          ],
          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h3', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h4', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h5', title: 'Heading 3', class: 'ck-heading_heading3' },
            ],
          },
          mediaEmbed: {
            previewsInData: true,
            removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
            extraProviders: [
              {
                name: 'Kaltura',
                url: /https:\/\/kuleuven\.mediaspace\.kaltura\.com\/media\/(.*)\/(.*)/,
                html: (match: any) => {
                  const id = match[2];

                  return (
                    '<div style="position: relative; padding-bottom: 100%; height: 0; ">' +
                    `<iframe id="kaltura_player" src="https://cdnapisec.kaltura.com/p/2375821/sp/237582100/embedIframeJs/uiconf_id/43066731/partner_id/2375821?iframeembed=true&playerId=kaltura_player&entry_id=${id}&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_xajpvlsb" ` +
                    'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
                    'frameborder="0" width="480" height="270" allowfullscreen allow="autoplay">' +
                    '</iframe>' +
                    '</div>'
                  );
                },
              },
              {
                name: 'Cloudflare',
                url: /https:\/\/iframe\.videodelivery\.net\/(.*)/,
                html: (match: any) => {
                  const id = match[1];
                  return (
                    '<div style="position: relative; padding-bottom: 100%; height: 0; ">' +
                    `<iframe id="cloudflare_player" src="https://iframe.videodelivery.net/${id}" ` +
                    'frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
                    'frameborder="0" width="480" height="270" allowfullscreen allow="autoplay">' +
                    '</iframe>' +
                    '</div>'
                  );
                },
              },
            ],
          },
          placeholder,
          language: language,
          wproofreader: {
            serviceId: process.env.REACT_APP_WPROOFREADER_SERVICE_ID || '',
            srcUrl:
              process.env.REACT_APP_WPROOFREADER_SRC_URL ||
              'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
            enableGrammar: true,
            autocorrect: false,
            lang: wproofReaderLanguage,
            settingsSections: ['dictionaries', 'options'],
            disableOptionsStorage: ['autocorrect', 'autocomplete', 'lang'],
            minWordLength: 2,
          },
        }}
        {...props}
        onReady={editor => {
          editor.model.document.on('change:data', insertEnterAfterMedia);
          fileUploadAdapterPlugin(editor);

          editor.on(UPDATE_PROGRESS_VIDEO, (eventInfo, videoData: Video) => {
            dispatch(Actions.updateProgressVideo(videoData));
          });
          editor.on(FILE_REJECTED, () => {
            setSnackbarOpen(true);
          });

          if (props.onReady) {
            props.onReady(editor);
          }
        }}
      />
      {uploadingVideos.map(uploadingVideo => (
        <ProgressBar
          value={uploadingVideo.progress}
          label={uploadingVideo.id}
          key={uploadingVideo.id}
          status={uploadingVideo.status}
        />
      ))}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Box pb={5}>
          <FormError>{t('forms.labels.ckeditor.imageUpload.tooLarge')}</FormError>
        </Box>
      </Snackbar>
    </div>
  );
};

export default Editor;
