import React from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import ContentBackground from '../../../components/ContentBackground';
import Footer from '../../../components/Footer';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFirstDreamId } from '../../security/selectors';
import HelpButton from './HelpButton';
import { useCompassUser } from '../hooks';
import { getMilaFirstDreamId, getMilaUserId } from '../selectors';
import { WelcomeTour } from '../tours';
import useUser from '../../security/hooks/useUser';
import CompassInfoBanner from './CompassInfoBanner';
import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
  color?: string;
  id?: string;
  className?: string;
};

type StyleProps = {
  isMobile: boolean;
  isShowcase: boolean;
};

const useStyles = makeStyles(() => ({
  loggedInPageContentWrapperRoot: (props: StyleProps) => ({
    position: 'absolute',
    top: props.isShowcase ? (props.isMobile ? '11.9rem' : '16.5rem') : '7rem',
    width: '100%',
    height: props.isShowcase
      ? props.isMobile
        ? 'calc(100vh - 11.9rem)'
        : 'calc(100vh - 16.5rem)'
      : 'calc(100vh - 7rem)',
    'overflow-y': 'auto !important',
    'overflow-x': 'hidden !important',
    '-webkit-overflow-scrolling': 'touch',
    scrollBehavior: 'smooth',
  }),
  innerContentWrapper: (props: StyleProps) => ({
    position: 'relative',
    maxWidth: props.isMobile ? '100%' : '140rem',
    width: '100%',
    minHeight: props.isMobile ? 'calc(100% - 12rem)' : 'calc(100% - 8rem)',
    margin: '0 auto',
    padding: props.isMobile ? '0 2rem 5rem 2rem' : '2rem 2rem 5rem 2rem',
  }),
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '130%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 0,
  },
  innerContent: {
    position: 'relative',
    zIndex: 1,
  },
}));

const LoggedInPageContentWrapper = ({ children, color, id, className }: Props) => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getPath } = useI18nPath();
  const { userId, dreamId } = useParams();
  const userHasDreams = !!useSelector(getFirstDreamId);
  const isCompassUser = useCompassUser();
  const currentUser = useUser();
  const milaUserId = useSelector(getMilaUserId);
  const milaFirstDreamId = useSelector(getMilaFirstDreamId);
  const history = useHistory();

  let isShowcase = false;
  if (
    window.location.pathname === getPath('myShowcase') ||
    window.location.pathname === getPath('showcasePage').replace(':userId', milaUserId) ||
    window.location.pathname ===
      getPath('showcaseDreamDetail')
        .replace(':userId', currentUser.entityId)
        .replace(':dreamId', dreamId) ||
    window.location.pathname ===
      getPath('showcaseDreamDetail')
        .replace(':userId', milaUserId)
        .replace(':dreamId', dreamId)
  ) {
    isShowcase = true;
  }
  const classes = useStyles({ isMobile, isShowcase });

  const currentPath = window.location.pathname;

  const handleGuidoClick = () => {
    if (!isCompassUser) {
      if (currentPath === getPath('myCompass')) {
        history.push(getPath('compassPage').replace(':userId', milaUserId));
      }
      if (
        currentPath ===
        getPath('dreamDetail')
          .replace(':userId', userId)
          .replace(':dreamId', dreamId)
      ) {
        history.push(
          getPath('dreamDetail')
            .replace(':userId', milaUserId)
            .replace(':dreamId', milaFirstDreamId)
        );
      }
      if (currentPath === getPath('myNetwork')) {
        history.push(getPath('networkPage').replace(':userId', milaUserId));
      }
      if (currentPath === getPath('myCoach')) {
        history.push(getPath('coachingsPage').replace(':userId', milaUserId));
      }
      if (currentPath === getPath('myShowcase')) {
        history.push(getPath('showcasePage').replace(':userId', milaUserId));
      }
      if (
        currentPath ===
        getPath('showcaseDreamDetail')
          .replace(':userId', userId)
          .replace(':dreamId', dreamId)
      ) {
        history.push(
          getPath('showcaseDreamDetail')
            .replace(':userId', milaUserId)
            .replace(':dreamId', milaFirstDreamId)
        );
      }
    }
  };

  return (
    <div id={id} className={clsx(classes.loggedInPageContentWrapperRoot, className)}>
      {isCompassUser && <CompassInfoBanner />}
      <div className={classes.innerContentWrapper}>
        <div className={classes.contentBackground}>
          <ContentBackground color={color} />
        </div>
        <div className={classes.innerContent} id={id ? `${id}-content` : undefined}>
          {children}
        </div>
        {!currentUser.welcomeTourDone && !userHasDreams && currentPath === getPath('myCompass') && (
          <WelcomeTour />
        )}
      </div>
      <Footer />
      <HelpButton color={color} onClick={handleGuidoClick} />
    </div>
  );
};

export default LoggedInPageContentWrapper;
