import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { GroupsResponse } from '../../../config/api/models';

const getGroupConfig = ApiConfig.getGroups();
const useFetchGroups = () => {
  return useFetch<GroupsResponse>(getGroupConfig);
};

export default useFetchGroups;
