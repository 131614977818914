import React from 'react';
import { Learn, LearnId, FileDTO, LearnResponse } from '../../../../config/api/models';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import LearnForm from './LearnForm';
import { LearnSubmitValues } from '../../model';
import useFilesPersist from '../../../shared/hooks/useFilesPersist';
import { useSelector } from 'react-redux';
import { getPersistingFiles } from '../../../shared/selectors';

type Props = {
  onClose?: () => void;
  learn: Learn;
  learnId: LearnId;
  files?: FileDTO[];
};

const updateLearnConfig = ApiConfig.updateLearn();

const UpdateLearnForm = ({ onClose, learn, learnId, files = [] }: Props) => {
  const [updateLearnResponse, updateLearn] = useFetch<LearnResponse>(updateLearnConfig);
  const persistFiles = useFilesPersist('learn', learnId, onClose);
  const persistingFiles = useSelector(getPersistingFiles);

  const handleSave = (values: LearnSubmitValues) => {
    updateLearn(learnId, {
      ...values.request,
      visibility: learn.visibility,
    });
    if (values.filesToAdd.length || values.fileIdsToDelete.length) {
      persistFiles({
        filesToAdd: values.filesToAdd,
        fileIdsToDelete: values.fileIdsToDelete,
      });
    } else if (!persistingFiles && onClose) {
      onClose();
    }
  };

  return (
    <LearnForm
      onSave={handleSave}
      onClose={onClose}
      learnResponse={updateLearnResponse}
      visibility={learn.visibility}
      initialValues={{
        files,
        title: learn.title,
        body: learn.body,
        focusId: learn.focusId,
      }}
    />
  );
};

export default UpdateLearnForm;
