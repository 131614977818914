import React, { useEffect, useRef } from 'react';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  ChatMessage,
  ChatMessage as ChatMessageType,
  ContextType,
  Dream,
  Focus,
  User,
} from '../../config/api/models';
import { useTranslation } from 'react-i18next';
import ChatActions from './ChatActions';
import ChatContext, { ContextDialogState } from './ChatContext';
import ChatMessageFeed from './ChatMessageFeed';
import CompassLinkButton from '../CompassLinkButton';
import { useCompassUser } from '../../modules/shared/hooks';

type Props = {
  children?: React.ReactNode;
  context: Focus | Dream;
  messages: ChatMessageType[];
  currentUser: User;
  otherUser: User;
  onSend: (message: string) => void;
  onDialogStateChange: (newState: ContextDialogState) => void;
  dialogState?: ContextDialogState;
  contextType: ContextType;
  disabled?: boolean;
  loading?: boolean;
  isCoachee?: boolean;
  setMessages: (value: React.SetStateAction<ChatMessage[]>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  chatRoot: {},
  chatHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: theme.config.defaultBorder,
    padding: '2rem',
    lineHeight: '2rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
  },
  title: {
    fontWeight: 'bold',
    marginLeft: '2rem',
  },
  category: {
    fontSize: '.9em',
    color: '#888',
  },
  chatContext: {
    display: 'flex',
  },
  chatBody: {
    flexGrow: 1,
  },
  messageContainer: {
    height: '45rem',
  },
}));

const Chat = ({
  context,
  messages,
  currentUser,
  otherUser,
  onSend,
  onDialogStateChange,
  dialogState,
  contextType,
  disabled,
  loading,
  isCoachee,
  setMessages,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const messageScrollContainer = useRef<HTMLDivElement>(null);
  const isCompassUser = useCompassUser();

  const handleSend = (message: string) => {
    onSend(message);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messageScrollContainer.current) {
      messageScrollContainer.current.scrollTop = messageScrollContainer.current.scrollHeight;
    }
  };

  return (
    <div className={classes.chatRoot}>
      <Paper>
        <div className={classes.chatHeader}>
          <div className={classes.left}>
            <div className={classes.category}>{t(contextType + '.title')}</div>
            <div className={classes.title}>{context.title}</div>
          </div>
          {isCoachee && !disabled && <CompassLinkButton userId={otherUser.entityId} />}
        </div>

        <div className={classes.chatContext}>
          <div className={classes.chatBody}>
            <div className={classes.messageContainer}>
              <ChatMessageFeed
                messages={messages}
                loading={loading}
                ref={messageScrollContainer}
                currentUser={currentUser}
                setMessages={setMessages}
              />
            </div>

            {(!disabled || !isCompassUser) && (
              <ChatActions
                avatar={currentUser.media.profile}
                onSend={handleSend}
                position="bottom"
              />
            )}
          </div>

          <ChatContext
            state={dialogState}
            onStateChange={onDialogStateChange}
            withInspiration={isCoachee}
          />
        </div>
      </Paper>
    </div>
  );
};

export default Chat;
