import {
  AnyArray,
  buildActionsFromMappings,
  FetchConfig,
  PromiseState,
  selectors,
} from 'react-redux-fetch';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

//const MAX_RETRIES = 2;

const getResourceName = (fetchConfig: FetchConfig<any>) => {
  if (typeof fetchConfig.resource === 'string') {
    return fetchConfig.resource;
  }
  return fetchConfig.resource.name;
};

const actionCache: Record<
  string,
  { action: (...args: any[]) => void; fetchConfig: FetchConfig<any> }
> = {};

const getAction = <TReqParams extends AnyArray>(fetchConfig: FetchConfig<TReqParams>) => {
  const resourceName =
    typeof fetchConfig.resource === 'string' ? fetchConfig.resource : fetchConfig.resource.name;
  const cacheKey = resourceName + (fetchConfig.method || 'GET');

  if (!actionCache[cacheKey] || fetchConfig !== actionCache[cacheKey].fetchConfig) {
    const actions = buildActionsFromMappings([fetchConfig]);
    actionCache[cacheKey] = {
      fetchConfig,
      action: actions[Object.keys(actions)[0]],
    };
  }

  return actionCache[cacheKey].action;
};

export function useFetch<TResponse = any, TReqParams extends AnyArray = any>(
  fetchConfig: FetchConfig<TReqParams>
): [PromiseState<TResponse> | undefined, (...args: TReqParams) => void] {
  /*const [retryCount, setRetryCount] = useState(0);
  const [actionWithArgs, setActionWithArgs] = useState<any>(undefined);*/
  const action = getAction(fetchConfig);
  const dispatch = useDispatch();

  const promiseState = useSelector(
    selectors.getPromise(getResourceName(fetchConfig)).fromState
  ) as PromiseState<TResponse>;

  const makeRequest = useCallback(
    (...args: TReqParams) => {
      // setActionWithArgs(action(...args));
      dispatch(action(...args));
    },
    [action, dispatch]
  );

  /*useEffect(() => {
    if (promiseState && promiseState.rejected && !!actionWithArgs) {
      if (retryCount < MAX_RETRIES) {
        dispatch(actionWithArgs);
        setRetryCount(retryCount + 1);
      }
    }
  }, [promiseState, retryCount, setRetryCount, dispatch, actionWithArgs]);*/

  return [promiseState, makeRequest];
}

export default useFetch;
