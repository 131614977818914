import React, { useEffect } from 'react';
import { InspirationContext, ReflectionsResponse } from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import shared from '../../shared';
import SectionTitle from '../../../components/SectionTitle';
import Paragraph from '../../../components/Paragraph';
import ModalBodyAlt from '../../../components/ModalBodyAlt';
import Html from '../../../components/Html';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import useInspirationDialogContext from '../hooks/useInspirationDialogContext';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  useMediaQuery,
} from '@material-ui/core';
import theme from '../../../config/theme';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';

const BlurDialog = shared.BlurDialog;

type Props = {
  inspirationKey: InspirationContext;
};

const useStyles = makeStyles(() => ({
  heading: {
    margin: 0,
    fontSize: '14px',
  },
}));

const InspirationSimpleDialog = ({ inspirationKey }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentLanguage = useCurrentLanguage();
  const { isOpen, onToggle } = useInspirationDialogContext('InspirationSimpleDialog');
  const [inspirationFetch, fetchInspiration] = useFetch<ReflectionsResponse>(
    ApiConfig.getInspiration(inspirationKey, currentLanguage)
  );

  const isPending = inspirationFetch && inspirationFetch.pending;
  const value = inspirationFetch && inspirationFetch.value;

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isOpen && !isPending && !value) {
      fetchInspiration();
    }
  }, [isPending, value, fetchInspiration, isOpen]);

  if (!isOpen) {
    return null;
  }

  const actions = (
    <Button onClick={onToggle} highlight>
      {t('forms.buttons.close')}
    </Button>
  );

  return (
    <BlurDialog
      open={true}
      onClose={onToggle}
      actions={actions}
      alignActions="center"
      fullScreen={fullScreen}
    >
      <SectionTitle label={t('inspiration.title')} />
      <Paragraph>{t('inspiration.body')}</Paragraph>
      {value && value.data.length > 0 && (
        <ModalBodyAlt fullWidth>
          <>
            {value.data.map(it => (
              <div key={it.id}>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    href="#"
                    aria-controls="inspiration-simple-panel"
                    id={`header-${it.id}`}
                    expandIcon={<SvgIcon icon={Icons.CHEVRON_DOWN} />}
                  >
                    <h2 className={classes.heading}>{it.attributes.title}</h2>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Html>{it.attributes.body}</Html>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            ))}
          </>
        </ModalBodyAlt>
      )}
    </BlurDialog>
  );
};

export default InspirationSimpleDialog;
