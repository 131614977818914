import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Slider from 'react-slick';
import theme from '../../../config/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomePageSection from '../../../components/HomePageSection';
import { useTranslation } from 'react-i18next';
import SliderContent from './SliderContent';
import { useMediaQuery } from '@material-ui/core';

type StyleProps = {
  isMobile: boolean;
  isSmall: boolean;
};

const useStyles = makeStyles(() => ({
  sectionSevenRoot: (props: StyleProps) => ({
    marginLeft: '-3000px',
    paddingLeft: '3000px',
    marginRight: '-3000px',
    paddingRight: '3000px',
    paddingTop: props.isMobile ? '25rem' : '20rem',
    paddingBottom: props.isMobile ? '20rem' : '15rem',
    clipPath: 'polygon(0 0, 100% 38%, 100% 100%, 0 100%)',
    backgroundColor: theme.palette.myCompass.blue,
    textAlign: 'center',
    color: theme.palette.background.paper,
    marginTop: props.isMobile ? '-10rem' : '-5rem',
  }),
  slider: (props: StyleProps) => ({
    width: props.isMobile ? '80%' : '',
    margin: props.isMobile ? '10rem auto 0 auto' : '10rem 0 0 0',
  }),
}));

const SectionSeven = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile, isSmall });
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
  };

  return (
    <div className={classes.sectionSevenRoot}>
      <HomePageSection title={t('home.sectionSeven.title')} />
      <Slider {...settings} className={classes.slider}>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating1.quote')}
            person={t('home.sectionSeven.rating1.person')}
            name={t('home.sectionSeven.rating1.person')}
            value={5}
          />
        </div>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating2.quote')}
            person={t('home.sectionSeven.rating2.person')}
            name={t('home.sectionSeven.rating2.person')}
            value={5}
          />
        </div>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating3.quote')}
            person={t('home.sectionSeven.rating3.person')}
            name={t('home.sectionSeven.rating3.person')}
            value={5}
          />
        </div>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating4.quote')}
            person={t('home.sectionSeven.rating4.person')}
            name={t('home.sectionSeven.rating4.person')}
            value={5}
          />
        </div>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating5.quote')}
            person={t('home.sectionSeven.rating5.person')}
            name={t('home.sectionSeven.rating5.person')}
            value={5}
          />
        </div>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating6.quote')}
            person={t('home.sectionSeven.rating6.person')}
            name={t('home.sectionSeven.rating6.person')}
            value={5}
          />
        </div>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating7.quote')}
            person={t('home.sectionSeven.rating7.person')}
            name={t('home.sectionSeven.rating7.person')}
            value={5}
          />
        </div>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating8.quote')}
            person={t('home.sectionSeven.rating8.person')}
            name={t('home.sectionSeven.rating8.person')}
            value={5}
          />
        </div>
        <div>
          <SliderContent
            quote={t('home.sectionSeven.rating9.quote')}
            person={t('home.sectionSeven.rating9.person')}
            name={t('home.sectionSeven.rating9.person')}
            value={5}
          />
        </div>
      </Slider>
    </div>
  );
};

export default SectionSeven;
