import React from 'react';
import { Focus, FileDTO, FocusResponse, FocusId } from '../../../../config/api/models';
import FocusForm from './FocusForm';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import { FocusSubmitValues } from '../../model';
import useFilesPersist from '../../../shared/hooks/useFilesPersist';
import { useSelector } from 'react-redux';
import { getPersistingFiles } from '../../../shared/selectors';

type Props = {
  onClose?: () => void;
  focus: Focus;
  focusId: FocusId;
  files?: FileDTO[];
};

const updateFocusConfig = (focusId: FocusId) => ApiConfig.updateFocus(focusId);

const UpdateFocusForm = ({ onClose, focus, focusId, files = [] }: Props) => {
  const [updateFocusResponse, updateFocus] = useFetch<FocusResponse>(updateFocusConfig(focusId));
  const persistFiles = useFilesPersist('focus', focusId, onClose);
  const persistingFiles = useSelector(getPersistingFiles);

  const handleSave = (values: FocusSubmitValues) => {
    updateFocus(values.request);
    if (values.filesToAdd.length || values.fileIdsToDelete.length) {
      persistFiles({
        filesToAdd: values.filesToAdd,
        fileIdsToDelete: values.fileIdsToDelete,
      });
    } else if (onClose && !persistingFiles) {
      onClose();
    }
  };

  return (
    <FocusForm
      onSave={handleSave}
      onClose={onClose}
      focusResponse={updateFocusResponse}
      initialValues={{
        files,
        title: focus.title,
        body: focus.body,
      }}
      visibility={focus.visibility}
    />
  );
};

export default UpdateFocusForm;
