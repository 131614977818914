import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import Editor from './Editor';
import { FILE_UPLOADED, FILE_UPLOADING } from './ckeditorAdapters/events';

type Props = FieldRenderProps<any, any> & {
  helperText?: string;
  onUploadingFile?: (isUploading: boolean) => void;
  placeholder?: string;
};

const EditorAdapter = (props: Props) => {
  const {
    input: { /*name,*/ onChange, value /*, ...restInput*/ },
    onUploadingFile,
    placeholder,
    // meta,
    // helperText,
    // ...rest
  } = props;

  return (
    <Editor
      onChange={(event, editor) => {
        if (event.name === 'change:data') {
          onChange(editor.getData());
        }
      }}
      onReady={editor => {
        editor.on(FILE_UPLOADING, () => {
          if (onUploadingFile) {
            onUploadingFile(true);
          }
        });

        editor.on(FILE_UPLOADED, () => {
          if (onUploadingFile) {
            onUploadingFile(false);
          }
        });
      }}
      data={value}
      placeholder={placeholder}
    />
  );
};

export default EditorAdapter;
