import React from 'react';
import FocusSection from '../../myCompass/containers/Focus/FocusSection';
import { useSelector } from 'react-redux';
import { getShowcase } from '../selectors';
import { getSelectedFocusId } from '../../myCompass/selectors';
import {
  getSelectedContext,
  getShowcaseFocusMapForSelectedDream,
} from '../helpers/helperFunctions';
import Milestone from '../../myCompass/containers/Milestone/Milestone';
import { DreamId, MilestoneId } from '../../../config/api/models';
import ShowcaseComments from '../components/ShowcaseComments';

type Props = {
  dreamId: DreamId;
};

const ShowcaseMilestoneWrapper = ({ dreamId }: Props) => {
  const showcase = useSelector(getShowcase());
  const selectedFocusId = useSelector(getSelectedFocusId);

  if (!showcase || !selectedFocusId || !showcase.milestones.length) {
    return null;
  }

  const focusMap = getShowcaseFocusMapForSelectedDream(showcase, dreamId, selectedFocusId);
  const milestoneMap = focusMap ? focusMap.milestones : [];
  const milestones = showcase.milestones.filter(milestone =>
    milestoneMap.map(m => m.id).includes(milestone.entityId)
  );

  if (!milestones.length || !milestoneMap.length) {
    return null;
  }

  const getCommentsList = (id: MilestoneId) => {
    const milestone = milestoneMap.find(m => m.id === id);
    if (milestone) {
      return showcase.comments.filter(c =>
        milestone!.comments.map(lc => lc.toString()).includes(c.entityId)
      );
    }
    return [];
  };

  const commentComponent = (id: MilestoneId) => {
    const comments = [...getCommentsList(id)];
    return comments.length ? <ShowcaseComments comments={comments} /> : undefined;
  };
  const context = getSelectedContext(showcase, selectedFocusId);

  return (
    <FocusSection id="i-milestone" title="iMilestone" context={context}>
      {milestones.map(m => (
        <Milestone
          key={m.entityId}
          withSidebar
          milestone={m}
          user={showcase.user}
          suffixComponent={commentComponent(m.entityId)}
        />
      ))}
    </FocusSection>
  );
};

export default ShowcaseMilestoneWrapper;
