import React from 'react';
import { Theme, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from '../SvgIcon';
import clsx from 'clsx';

type Props = {
  state: ContextDialogState;
  onStateChange: (newState: ContextDialogState) => void;
  withInspiration?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  chatContextRoot: {
    minWidth: '4rem',
    minHeight: '100%',
    borderLeft: theme.config.defaultBorder,
    position: 'relative',
  },
  buttonGroup: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 100,
    left: -23,
    marginTop: '0.5rem',
  },
  iconButton: {
    border: theme.config.defaultBorder,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
  },
  state: {
    color: theme.palette.primary.main,
  },
}));

export type ContextDialogState = 'inspiration' | 'context' | undefined;

const ChatContext = (props: Props) => {
  const { state, onStateChange, withInspiration } = props;
  const classes = useStyles();

  const handleButtonClick = (button: ContextDialogState) => {
    onStateChange(button === state ? undefined : button);
  };

  return (
    <div className={classes.chatContextRoot}>
      <div className={classes.buttonGroup}>
        <IconButton
          id="chat-context-button"
          className={clsx(classes.iconButton, state === 'context' ? classes.state : undefined)}
          onClick={() => handleButtonClick('context')}
        >
          <SvgIcon icon={Icons.DETAIL} size={2} />
        </IconButton>

        {withInspiration && (
          <IconButton
            id="chat-inspiration-button"
            className={clsx(
              classes.iconButton,
              state === 'inspiration' ? classes.state : undefined
            )}
            onClick={() => handleButtonClick('inspiration')}
          >
            <SvgIcon icon={Icons.LIGHT_BULB} size={2} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ChatContext;
