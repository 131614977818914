import React from 'react';
import ShowcaseBlock from '../components/ShowcaseBlock';
import { Showcase } from '../../../config/api/models';
import ShowcaseFocusWrapper from './ShowcaseFocusWrapper';
import Section from '../../../components/Section';
import PanelWithSidebar from '../../../components/PanelWithSidebar';
import { getUserDisplayName } from '../../../helpers/getUserDisplayName';
import { useTranslation } from 'react-i18next';

type Props = {
  showcase: Showcase;
  selectedDreamId: string;
};

const ShowcaseDream = ({ showcase, selectedDreamId }: Props) => {
  const dreamMap = showcase.map.dreams.length
    ? showcase.map.dreams.find(item => item.dreamId === selectedDreamId)
    : undefined;
  const { t } = useTranslation();

  if (!dreamMap) {
    return (
      <PanelWithSidebar>
        <p>{t('showcase.public.noDream')}</p>
      </PanelWithSidebar>
    );
  }

  const dream = showcase.dreams.find(d => d.entityId === selectedDreamId);
  const commentList =
    dreamMap && dreamMap.dreamComments
      ? showcase.comments.filter(c =>
          dreamMap.dreamComments!.map(dc => dc.toString()).includes(c.entityId)
        )
      : [];

  return dreamMap && dream ? (
    <Section title="iDream" id="i-dream" removeTopMargin>
      <ShowcaseBlock context={dream} comments={[...commentList]} modelType="dream" />
      <ShowcaseFocusWrapper dreamId={dream.entityId} showcase={showcase} />
    </Section>
  ) : (
    <PanelWithSidebar>
      <p>{`${getUserDisplayName(showcase.user, true)} ${t(
        'showcase.public.sharedNoInformation'
      )}.`}</p>
    </PanelWithSidebar>
  );
};

export default ShowcaseDream;
