import React from 'react';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';
import i18next from 'i18next';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { Language } from '../../../config/i18n/types';
import * as H from 'history';

type MobileProps = {
  className: string;
  onChange?: () => void;
  langStyle: 'value' | 'label';
  history: H.History;
  location: H.Location;
};

const LanguageList = ({ className, history, location, onChange, langStyle }: MobileProps) => {
  const currentLang = useCurrentLanguage();
  const { getRouteName, getPath } = useI18nPath();

  return (
    <>
      {[
        { value: 'nl', label: 'Nederlands' },
        { value: 'en', label: 'English' },
        { value: 'fr', label: 'Français' },
      ].map(language => (
        <div
          className={className}
          style={{ fontWeight: language.value === currentLang ? 800 : 400 }}
          key={language.value}
          onClick={() => {
            i18next.changeLanguage(language.value);

            const route = getRouteName(location.pathname);
            if (route) {
              const oldURL = location.pathname.split('/');
              const nwURL = getPath(route, undefined, language.value as Language)
                .split('/')
                .map((section, i) => (section.startsWith(':') ? oldURL[i] : section))
                .join('/');

              history.push(nwURL);
            }
            onChange && onChange();
          }}
        >
          {langStyle === 'label' ? language.label : language.value.toUpperCase()}
        </div>
      ))}
    </>
  );
};

export default LanguageList;
