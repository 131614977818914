import React, { HTMLProps } from 'react';
import { styled } from '@material-ui/styles';

type Props = HTMLProps<HTMLButtonElement> & {
  checked?: boolean;
};

const ToggleBtn = styled(({ checked, ...other }: Omit<Props, 'type'>) => <button {...other} />)(
  ({ theme, checked }) => {
    return {
      width: '2.7rem',
      height: '2.7rem',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '100%',
      outline: 'none',
      cursor: 'pointer',
      '& > span': {
        opacity: checked ? 1 : 0,
        transition: 'opacity 0.2s ease',
      },
      '&:focus > span': {
        opacity: checked ? 0 : 1,
      },
      '&:hover > span': {
        opacity: 0.4,
      },
    };
  }
);

const Check = styled('span')(({ theme }) => ({
  display: 'inline-block',
  transform: 'rotate(45deg)',
  height: 12,
  width: 7,
  borderBottom: `2px solid ${theme.palette.tertiary.main}`,
  borderRight: `2px solid ${theme.palette.tertiary.main}`,
}));

const Toggle = (props: Props) => {
  return (
    <ToggleBtn {...props}>
      <Check />
    </ToggleBtn>
  );
};

export default Toggle;
