import React, { useEffect, useState } from 'react';
import Button from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import {
  ClosedStatus,
  DreamId,
  FocusId,
  FocusResponse,
  Milestone,
  MilestonesResponse,
} from '../../../../config/api/models';
import { useSelector } from 'react-redux';
import { getSelectedFocusId } from '../../selectors';
import { Icons } from '../../../../components/SvgIcon';
import { Box, useMediaQuery } from '@material-ui/core';
import PanelWithSidebar from '../../../../components/PanelWithSidebar';
import FocusFinalizedMessage from '../../components/Focus/FocusFinalizedMessage';
import { makeStyles } from '@material-ui/styles';
import BlurDialog from '../../../shared/components/BlurDialog';
import theme from '../../../../config/theme';
import Html from '../../../../components/Html';
import { useParams } from 'react-router-dom';
import { useCompassUser } from '../../../shared/hooks';

const updateFocusConfig = (focusId: FocusId) => ApiConfig.updateFocus(focusId);
const addMilestoneConfig = ApiConfig.addMilestone();
const loadMilestonesConfig = ApiConfig.loadMilestones();
const removeMilestonseConfig = ApiConfig.removeMilestone();

const useStyles = makeStyles(() => ({
  finalizeFocus: {
    margin: '3rem 2rem 0 2rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '10rem',
    justifyContent: 'space-between',
  },
  finalizeButton: {
    backgroundColor: theme.palette.grey['100'],
    color: theme.palette.grey['500'],
    border: `1px solid ${theme.palette.grey['300']}`,
    '&:hover': {
      backgroundColor: theme.palette.grey['300'],
    },
  },
}));

const FinalizeFocus = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isCompassUser = useCompassUser();
  const { dreamId } = useParams();
  const selectedDreamId = dreamId;
  const focusId = useSelector(getSelectedFocusId);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [openDialog, setOpenDialog] = useState<'achieve' | 'finalize' | undefined>();

  const [, loadFocusTabs] = useFetch(ApiConfig.loadFocusTabs(selectedDreamId || ('' as DreamId)));
  const [, addMilestone] = useFetch(addMilestoneConfig);
  const [milestoneRequest] = useFetch<MilestonesResponse>(loadMilestonesConfig);
  const [, removeMilestone] = useFetch(removeMilestonseConfig);
  const [focusResponse, updateFocus] = useFetch<FocusResponse>(
    updateFocusConfig(focusId || ('' as FocusId))
  );

  const focus = focusResponse && focusResponse.value && focusResponse.value.data.attributes;
  const milestone =
    milestoneRequest &&
    milestoneRequest.value &&
    milestoneRequest.value.data.map(d => d.attributes).find(m => m.focusId === focusId);

  useEffect(() => {
    if (
      focusResponse &&
      focusResponse.fulfilled &&
      focusResponse.value &&
      focusResponse.value.data.attributes.isClosed === ClosedStatus.CLOSED
    ) {
      loadFocusTabs();
    }
  }, [focusResponse, loadFocusTabs, selectedDreamId]);

  const finalizeFocus = (success: boolean) => {
    if (focusId) {
      updateFocus({ isClosed: ClosedStatus.CLOSED, isSuccessful: success });
      const newMilestone: Partial<Milestone> = {
        focusId,
      };
      if (success) {
        addMilestone(newMilestone);
      }
    }
    setOpenDialog(undefined);
  };
  const undoFinalizeFocus = () => {
    if (focusId) {
      updateFocus({ isClosed: ClosedStatus.OPEN });
      if (milestone) {
        removeMilestone(milestone.entityId);
      }
    }
  };

  if (!focus || isCompassUser) {
    return null;
  }

  const actionButtons = [
    <Button
      key="achieve"
      onClick={() => finalizeFocus(openDialog === 'achieve')}
      icon={Icons.CHECK_CIRCLE}
    >
      {t(`focus.${openDialog}DialogTitle`)}
    </Button>,
    <Button
      key="close"
      onClick={() => setOpenDialog(undefined)}
      icon={Icons.CANCEL}
      color="secondary"
    >
      {t('forms.buttons.cancel')}
    </Button>,
  ];

  return (
    <Box className={classes.finalizeFocus}>
      <PanelWithSidebar sidebar={<div />} component="div">
        {focus.isClosed ? (
          <FocusFinalizedMessage
            undoFinalize={undoFinalizeFocus}
            isSuccessful={focus.isSuccessful}
          />
        ) : (
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => setOpenDialog('achieve')}
              icon={Icons.CHECK_CIRCLE}
              disabled={focusResponse && focusResponse.pending}
              superBtn
            >
              {t('focus.achieve')}
            </Button>
            <Button
              onClick={() => setOpenDialog('finalize')}
              icon={Icons.CANCEL}
              disabled={focusResponse && focusResponse.pending}
              className={classes.finalizeButton}
            >
              {t('focus.finalize')}
            </Button>

            {openDialog && (
              <BlurDialog
                open={Boolean(openDialog)}
                onClose={() => setOpenDialog(undefined)}
                actions={actionButtons}
                title={t(`focus.${openDialog}DialogTitle`)}
                fullScreen={isMobile}
              >
                <Html>{t(`focus.${openDialog}DialogBody`)}</Html>
              </BlurDialog>
            )}
          </div>
        )}
      </PanelWithSidebar>
    </Box>
  );
};

export default FinalizeFocus;
