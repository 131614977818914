import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Html from '../Html';
import Moment from 'moment';
import Tooltip from '../Tooltip';
import useCurrentLanguage from '../../config/i18n/useCurrentLanguage';
import PaperTopButton from '../PaperTopButton';
import SvgIcon, { Icons } from '../SvgIcon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ChatMessage as ChatMessageType } from '../../config/api/models';

type Props = {
  authorName: string;
  messageText: string;
  withoutAuthorInfo?: boolean;
  isOwn?: boolean;
  opened?: boolean;
  deliveryStatus?: string;
  onClick?: () => void;
  onSeen?: () => void;
  radiusType?: number;
  failed?: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  onClickEditMessage?: (message: ChatMessageType) => void;
  onClickDeleteMessage?: (message: ChatMessageType) => void;
  message: ChatMessageType;
};

type StyleProps = {
  isOwn?: boolean;
  deletedAt: string | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  chatMessageRoot: {
    marginTop: '1.5rem',
  },
  messageText: ({ isOwn, deletedAt }: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    padding: '1.5rem',
    paddingRight: isOwn && deletedAt == null ? '5.5rem' : '1.5rem',
    backgroundColor:
      deletedAt == null
        ? isOwn
          ? theme.palette.primary.light
          : theme.palette.background.default
        : '#fff',
    border: deletedAt == null ? 'none' : `0.1rem dashed ${theme.palette.grey[300]}`,
    marginTop: '1rem',
    ' & > p': {
      margin: 0,
    },
    '&:before': {
      width: '0',
      height: '0',
      borderLeft: '.8rem solid transparent',
      borderRight: '.8rem solid transparent',
      borderBottom: isOwn
        ? `0.6rem solid ${theme.palette.primary.light}`
        : `0.6rem solid ${theme.palette.background.default}`,
      content: deletedAt == null ? '""' : 'none',
      position: 'absolute',
      top: '-0.4rem',
      left: isOwn ? '1rem' : '',
      right: isOwn ? '' : '1rem',
    },
  }),
  authorName: (props: StyleProps) => ({
    textAlign: props.isOwn ? 'left' : 'right',
  }),
  errorMessage: {
    color: theme.palette.secondary.main,
    fontSize: '1rem',
  },
  updatedAt: ({ isOwn, deletedAt }: StyleProps) => ({
    alignSelf: 'flex-end',
    fontSize: '1.2rem',
    color: theme.palette.grey[400],
    marginBottom: '-1rem !important',
    marginRight: deletedAt == null && isOwn ? '-4rem !important' : '0',
  }),
}));

const ChatMessage = (props: Props) => {
  const {
    authorName,
    messageText,
    isOwn,
    failed,
    withoutAuthorInfo,
    createdAt,
    updatedAt,
    deletedAt,
    onClickEditMessage,
    onClickDeleteMessage,
    message,
  } = props;
  const classes = useStyles({ isOwn, deletedAt: message.deletedAt });
  const lang = useCurrentLanguage();
  const { t } = useTranslation();

  // Replace things that look like links with actual links.
  const messageBody = messageText.replace(
    /(http(s?):\/\/[^\s]+)/gi,
    '<a href="$1" target="_blank">$1</a>'
  );

  Moment.locale(lang);
  const now = Moment();

  const ms = Moment(now).diff(Moment(createdAt));
  const d = Moment.duration(ms);

  return (
    <div className={classes.chatMessageRoot}>
      {withoutAuthorInfo ? null : (
        <Typography variant="h4" className={classes.authorName}>
          {authorName}
        </Typography>
      )}
      <Tooltip
        title={
          deletedAt == null
            ? d.days() >= 1
              ? Moment(createdAt).format('DD MMMM YYYY HH:mm:ss')
              : Moment(createdAt).fromNow()
            : t('chat.deletedAt').replace('%DATE%', moment(deletedAt).format('L'))
        }
        placement="top"
      >
        <div className={classes.messageText}>
          <Html>{deletedAt == null ? messageBody : t('chat.deletedMessageBody')}</Html>
          {isOwn && deletedAt == null && (
            <>
              <PaperTopButton
                onClick={() => onClickEditMessage && onClickEditMessage(message)}
                style={{ right: '4rem' }}
              >
                <SvgIcon icon={Icons.EDIT} />
              </PaperTopButton>
              <PaperTopButton onClick={() => onClickDeleteMessage && onClickDeleteMessage(message)}>
                <SvgIcon icon={Icons.TRASH} />
              </PaperTopButton>
            </>
          )}
          {deletedAt == null ? (
            createdAt !== updatedAt &&
            updatedAt != null && (
              <Typography className={classes.updatedAt}>
                {t('chat.updatedAt').replace('%DATE%', moment(updatedAt).format('L'))}
              </Typography>
            )
          ) : (
            <Typography className={classes.updatedAt}>
              {t('chat.deletedAt').replace('%DATE%', moment(deletedAt).format('L'))}
            </Typography>
          )}
        </div>
      </Tooltip>
      {failed && <div className={classes.errorMessage}>Something went wrong</div>}
    </div>
  );
};

export default ChatMessage;
