import React from 'react';
import { makeStyles } from '@material-ui/styles';
import SectionTitle from './SectionTitle';
import theme from '../config/theme';

export type SectionProps = {
  id: string;
  title: string;
  context?: string;
  tooltip?: React.ReactNode;
  children: React.ReactNode;
  removeTopMargin?: boolean;
  headerButton?: React.ReactNode;
};

type StyleProps = {
  removeTopMargin: boolean;
};

const useStyles = makeStyles(() => ({
  sectionRoot: (props: StyleProps) => ({
    margin: props.removeTopMargin ? '0 0 4rem 0' : '4rem 0',
    [theme.breakpoints.down('xs')]: {
      marginTop: '4rem',
    },
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Section = ({
  id,
  title,
  context,
  tooltip,
  children,
  removeTopMargin = false,
  headerButton,
}: SectionProps) => {
  const classes = useStyles({ removeTopMargin });

  return (
    <section id={`${id}-section`} className={classes.sectionRoot}>
      <SectionTitle
        anchorId={id}
        label={title}
        tooltip={tooltip}
        context={context}
        headerButton={headerButton}
      />
      {children}
    </section>
  );
};

export default Section;
