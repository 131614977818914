import React, { useState } from 'react';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import BlurDialog from '../../shared/components/BlurDialog';
import ButtonGroup from '../../../components/ButtonGroup';
import { Form, Field } from 'react-final-form';
import FormControl from '../../../components/FormControl';
import { TextFieldAdapter, validations } from '../../../components/forms';
import Paragraph from '../../../components/Paragraph';
import SectionTitle from '../../../components/SectionTitle';
import ModalBodyAlt from '../../../components/ModalBodyAlt';
import MaskedDateInput from '../../../components/forms/MaskedDateInput';
import { Showcase, ShowcaseRequest } from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import DatePickerAdapter from '../../../components/forms/Dates/DatePickerAdapter';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../config/theme';

type Props = {
  open: boolean;
  showcase?: Showcase;
  onClose: () => void;
};

type LinkFormValues = {
  title: string;
  expiresAt?: Moment;
};

enum FORMS {
  MAIN,
  DATE,
}

const useStyles = makeStyles(() => ({
  actionButtons: {
    margin: '3rem 0',
  },
}));

const LinkDialogForm = ({ showcase, open, onClose }: Props) => {
  const [showForm, setShowForm] = useState<FORMS>(FORMS.MAIN);
  const [, addShowcase] = useFetch(ApiConfig.createShowcase());
  const [, patchShowcase] = useFetch(ApiConfig.patchShowcase(showcase ? showcase.slug : ''));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const { t } = useTranslation();

  const submitShowcase = (values: LinkFormValues) => {
    const showCaseDTO: ShowcaseRequest = {
      ...values,
      expiresAt: values.expiresAt ? values.expiresAt.toISOString(true) : '',
      deletedAt: null,
    };
    if (showcase) {
      showCaseDTO.updatedAt = moment().toISOString();
      patchShowcase(showCaseDTO);
    } else {
      addShowcase(showCaseDTO);
    }
    onClose();
  };

  return (
    <BlurDialog open={open} onClose={onClose} fullScreen={isMobile}>
      <>
        <SectionTitle
          label={t(showcase ? 'showcase.myLinks.editLink' : 'showcase.myLinks.addLink')}
        />
        <Paragraph>{t('showcase.myLinks.dialogBody')}</Paragraph>
        <Form
          onSubmit={(values: LinkFormValues) => submitShowcase(values)}
          initialValues={{
            title: showcase ? showcase.title : '',
            expiresAt: showcase ? moment.utc(showcase.expiresAt) : undefined,
          }}
          keepDirtyOnReinitialize
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBodyAlt fullWidth>
                <>
                  {showForm === FORMS.MAIN && (
                    <>
                      <FormControl label={t('showcase.myLinks.linkName')} id="title" required>
                        <Field
                          name="title"
                          component={TextFieldAdapter}
                          validate={validations(t).required}
                          disabled={Boolean(showcase)}
                        />
                      </FormControl>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <FormControl
                            label={t('showcase.myLinks.expiresAt')}
                            id="expiresAt"
                            required
                          >
                            <Field
                              name="expiresAt"
                              validate={validations(t).requiredDate}
                              component={TextFieldAdapter}
                              InputProps={{
                                inputComponent: MaskedDateInput,
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <Button icon={Icons.CALENDAR} onClick={() => setShowForm(FORMS.DATE)}>
                            {t('forms.buttons.selectDate')}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showForm === FORMS.DATE && (
                    <>
                      <Field name="expiresAt" component={DatePickerAdapter} />

                      <Box display="flex" justifyContent="center">
                        <Button icon={Icons.CHECK_CIRCLE} onClick={() => setShowForm(FORMS.MAIN)}>
                          {t('forms.buttons.selectDate')}
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              </ModalBodyAlt>
              {showForm === FORMS.MAIN && (
                <ButtonGroup className={classes.actionButtons}>
                  <Button icon={Icons.PLUS} type="submit">
                    {t(showcase ? 'showcase.myLinks.editLink' : 'showcase.myLinks.addLink')}
                  </Button>
                  <Button icon={Icons.CANCEL} color="secondary" onClick={onClose}>
                    {t('forms.buttons.cancel')}
                  </Button>
                </ButtonGroup>
              )}
            </form>
          )}
        />
      </>
    </BlurDialog>
  );
};

export default LinkDialogForm;
