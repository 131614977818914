import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { SvgIcon as MuiSvgIcon } from '@material-ui/core';
import clsx from 'clsx';

export enum Icons {
  SAVE = 'M11.2 8.2c0 .1-.1.1-.1.2L7.5 12c-.1.1-.2.1-.3.1s-.3 0-.3-.1c-.2-.2-.2-.5 0-.7l2.8-2.8H.5C.2 8.5 0 8.3 0 8s.2-.5.5-.5h9.1L6.8 4.7c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l3.6 3.6s.1.1.1.2c.1.1.1.3 0 .4zM16 8C16 3.7 12.5.3 8.3.3 5 .3 2 2.4 1 5.5l.9.3c.9-2.7 3.5-4.6 6.4-4.6C12 1.3 15 4.3 15 8s-3 6.7-6.7 6.7c-2.9 0-5.5-1.8-6.4-4.6l-.9.4c1.1 3.2 4 5.3 7.3 5.3 4.2-.1 7.7-3.5 7.7-7.8z',
  CANCEL = 'M8 16c-2 0-4.1-.8-5.7-2.3-3.1-3.1-3.1-8.2 0-11.3 3.1-3.1 8.2-3.1 11.3 0 3.1 3.1 3.1 8.2 0 11.3C12.1 15.2 10 16 8 16zM8 1c-1.8 0-3.6.7-4.9 2-2.7 2.7-2.7 7.2 0 9.9s7.2 2.7 9.9 0 2.7-7.2 0-9.9c-1.4-1.3-3.2-2-5-2zm.7 7l2.5-2.5c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0L8 7.3 5.5 4.7c-.2-.2-.5-.2-.7 0s-.2.5 0 .7L7.3 8l-2.5 2.5c-.2.2-.2.5 0 .7.1.1.2.1.4.1s.3 0 .4-.1L8 8.7l2.5 2.5c.1.1.2.1.4.1.1 0 .3 0 .4-.1.2-.2.2-.5 0-.7L8.7 8z',
  INFO = 'M10.8 11.8c0 .4-.3.6-.6.6H6.8c-.4 0-.6-.3-.6-.6 0-.4.3-.6.6-.6h1.1V7.4H6.8c-.4 0-.6-.3-.6-.6 0-.4.3-.6.6-.6h1.7c.4 0 .6.3.6.6v4.5h1.1c.3-.1.6.2.6.5zM7 4.2c0-.6.5-1 1-1s1.1.5 1.1 1.1-.5 1-1.1 1-1-.5-1-1.1zM16 8c0-4.4-3.6-8-8-8S0 3.6 0 8s3.6 8 8 8 8-3.6 8-8zm-1.3 0A6.7 6.7 0 1 1 1.3 8a6.7 6.7 0 0 1 13.4 0z',
  ERROR = 'M7.9 16C3.5 16 .1 12.6 0 8.1 0 6 .8 4 2.3 2.4 3.8.9 5.8 0 8 0c2.1 0 4.1.8 5.6 2.2C15.1 3.7 16 5.7 16 7.9c.1 4.4-3.5 8.1-8 8.1h-.1zm.2-14.6H8c-1.8 0-3.5.8-4.7 2-1.3 1.3-2 3-1.9 4.7.1 3.7 3 6.6 6.6 6.5 3.7-.1 6.7-3.1 6.6-6.7 0-1.8-.7-3.4-2-4.7C11.5 2 9.8 1.4 8.1 1.4zm.6 7.3V4.1c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v4.7c0 .4.3.7.7.7.4-.1.7-.4.7-.8zM8 12.3c-.6 0-1-.5-1-1 0-.6.5-1 1-1s1 .5 1 1c0 .6-.4 1-1 1z',
  HASH_TAG = 'M12.6 5.2H11l.8-2.9H11l-.8 2.9H7.4l.8-2.9h-.9l-.8 2.9H4.2l-.1.8h2.2L5.2 9.9H3.5l-.1.8H5l-.8 3H5l.8-3h2.8l-.8 3h.9l.8-3h2.3l.1-.8H9.7L10.8 6h1.7l.1-.8zM6.1 9.9l1-3.9H10L8.9 9.9H6.1z',
  EDIT = 'M.1 15.4l1.3-1.3-.3-.3c-.1-.1-.1-.4 0-.5L2.4 12l-.3-.3c0 .1-.1 0-.1-.1s0-.2.1-.3l6.8-6.8L11.4 7l-6.8 6.8c0 .1-.1.2-.2.2s-.2 0-.3-.1l-.3-.3-1.3 1.3c-.1.1-.4.1-.5 0l-.3-.3-1.3 1.3c-.1.1-.4.1-.5 0 .1-.1.1-.4.2-.5zM12 6.6l3.9-3.9c.1-.1.1-.2.1-.3 0-.1 0-.2-.1-.3l-2-2c-.1-.1-.4-.1-.5 0l-.3.3c-.3-.2-.6-.3-1-.3-.5 0-.9.2-1.3.5L6.7 4.7c-.1.1-.1.4 0 .5.1.1.4.1.5 0l4.1-4.1c.3-.3.8-.4 1.2-.2L9.4 4 12 6.6z',
  PLUS = 'M8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM8 1C4.2 1 1 4.2 1 8s3.1 7 7 7 7-3.1 7-7-3.2-7-7-7zm4.1 6.5H8.5V3.9c0-.3-.2-.5-.5-.5s-.5.2-.5.5v3.6H3.9c-.3 0-.5.2-.5.5s.2.5.5.5h3.6v3.6c0 .3.2.5.5.5s.5-.2.5-.5V8.5h3.6c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z',
  GLOBE = 'M11.7 16c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3S16 9.3 16 11.7 14.1 16 11.7 16zm0-8C9.6 8 8 9.6 8 11.7s1.6 3.7 3.7 3.7 3.7-1.6 3.7-3.7S13.7 8 11.7 8zm-.4 5.7c.1 0 .2 0 .2-.1l2.5-2.8c.1-.1.1-.3 0-.5-.1-.1-.3-.1-.5 0l-2.3 2.5-1.6-1.6c-.1-.1-.3-.1-.5 0-.1.1-.1.3 0 .5l1.8 1.8c.2.2.3.2.4.2zm2.6-11c0-.1 0-.1 0 0-1-1.1-2.2-1.9-3.6-2.3C9.6.1 8.8 0 8 0 3.6 0 0 3.6 0 8s3.6 8 8 8c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3C4 15.3.7 12 .7 8S4 .7 8 .7c.5 0 .9 0 1.3.1L7.2 2c-.1.1-.2.2-.2.3 0 .1.1.2.1.3l.9.6v1.5H4.5c-.1 0-.2.1-.3.2L2.7 7.2c-.1.1 0 .3.1.4L6 10.2v1.5c0 .2.1.3.3.3.2 0 .3-.1.3-.3V10c0-.1 0-.2-.1-.3L3.4 7.3l1.2-1.9h3.7c.2 0 .3-.1.3-.3V3c0-.1-.1-.2-.1-.3L8 2.4 10.3 1c1.1.4 2.2 1 3 1.9l-1 1.8h-1c-.1 0-.2 0-.2.1L9.9 5.9c-.1.1-.1.3 0 .5.1.1.2.1.2.1s.2 0 .2-.1l1.1-1.1h1c.1 0 .2-.1.3-.2l.9-1.7c1 1.3 1.6 2.8 1.6 4.6 0 .2.1.3.3.3.4 0 .5-.1.5-.3 0-2-.8-3.9-2.1-5.3z',
  NETWORK = 'M4 7.3h3c.2 0 .3-.1.3-.3v-.3c0-.5-.4-1-.9-1.2L5 5.1v-.5c.6-.5 1-1.2 1-2.1C6 1.1 5 0 3.7 0S1.3 1.1 1.3 2.5c0 .9.4 1.6 1 2.1v.5l-1.4.4c-.5.1-.9.7-.9 1.2V7c0 .2.1.3.3.3H4zM3.7.7C4.4.7 5 1.2 5.3 2h-.4c-.2 0-.5-.1-.6-.4 0-.1-.1-.2-.2-.2s-.2 0-.3.1c-.5.5-1.2.6-1.7.4.2-.7.8-1.2 1.6-1.2zM2 2.6c.6.2 1.3.1 1.9-.3.2.2.5.4.8.4h.5c-.1 1-.8 1.7-1.7 1.7-.7 0-1.5-.8-1.5-1.8zM.7 6.7c0-.2.2-.5.4-.5l1.7-.5c.1-.1.2-.2.2-.4v-.4c.2.1.4.1.7.1.2 0 .5 0 .7-.1v.4c0 .1.1.3.2.3l1.7.5c.2.1.4.3.4.5h-6zm14.4-1.2l-1.5-.4v-.5c.6-.5 1-1.2 1-2.1 0-1.4-1-2.5-2.3-2.5S10 1.1 10 2.5c0 .9.4 1.6 1 2.1v.5l-1.5.4c-.4.1-.8.7-.8 1.2V7c0 .2.1.3.3.3h6.7c.2 0 .3-.1.3-.3v-.3c0-.5-.4-1.1-.9-1.2zM12.3.7c.7 0 1.4.6 1.6 1.3-.1 0-.2.1-.4 0-.2 0-.4-.1-.6-.4 0-.1-.1-.2-.2-.2s-.2 0-.3.1c-.5.5-1.2.5-1.7.4.3-.7.9-1.2 1.6-1.2zm-1.6 1.9c.6.2 1.3.1 1.9-.3.2.2.5.4.8.4h.5c-.1 1-.8 1.7-1.7 1.7-.8 0-1.5-.8-1.5-1.8zm2 4.1H9.3c0-.2.2-.5.4-.5l1.7-.5c.1 0 .2-.2.2-.3v-.5c.3.1.5.1.7.1.2 0 .5 0 .7-.1v.4c0 .1.1.3.2.3l1.7.5c.2.1.4.3.4.5h-2.6zm-1.9 7.5l-1.5-.4v-.5c.6-.5 1-1.2 1-2.1 0-1.4-1-2.5-2.3-2.5s-2.3 1.1-2.3 2.5c0 .9.4 1.6 1 2.1v.5l-1.5.4c-.5.1-.9.7-.9 1.2v.3c0 .2.1.3.3.3h6.7c.2 0 .3-.1.3-.3v-.3c.1-.6-.3-1.1-.8-1.2zM8 9.3c.7 0 1.4.5 1.6 1.3-.1 0-.2.1-.4 0-.1 0-.4-.1-.6-.4 0-.1-.1-.2-.2-.2s-.2 0-.3.1c-.5.5-1.2.5-1.7.4.3-.7.9-1.2 1.6-1.2zm-1.7 1.9c.6.2 1.3.1 1.9-.3.2.2.5.3.8.4h.5c.1 1-.6 1.7-1.5 1.7s-1.6-.8-1.7-1.8zm2 4.1H5c0-.2.2-.5.4-.5l1.7-.5c.1 0 .2-.2.2-.3v-.4c.2.1.4.1.7.1.2 0 .5 0 .7-.1v.4c0 .1.1.3.2.3l1.7.5c.2.1.4.3.4.5H8.3zm-4-5c-.1 0-.2 0-.2-.1L2.4 8.6c-.1-.1-.1-.3 0-.5.2-.1.4-.1.5 0l1.7 1.7c.1.1.1.3 0 .5h-.3zm9.3-2.2c.1.1.1.3 0 .5l-1.7 1.7c-.1.1-.2.1-.2.1-.1 0-.2 0-.2-.1-.1-.1-.1-.3 0-.5l1.7-1.7c0-.1.2-.1.4 0z',
  COACH = 'M10.3 9.4h-.2c-.1-.1-.2-.2-.2-.4V6.8h-.6v-.9h1c.2 0 .4.2.4.4V8l2.2-2c.1 0 .2-.1.3-.1h2V.8H6.1V3h-.8V.4c0-.2.2-.4.4-.4h9.9c.2 0 .4.2.4.4v5.9c0 .2-.2.4-.4.4h-2.3l-2.7 2.5c-.1.2-.2.2-.3.2zm3-6h-.7V4h.7v-.6zm-2 0h-.7V4h.7v-.6zm-2 0h-.6V4h.7v-.6h-.1zm1.4 12.2V14c0-.6-1.9-1.6-3.6-2.3v-.8c.2-.2.5-.7.6-1.6.3-.2.4-.6.4-1 0-.3-.1-.6-.3-.8.2-.4.3-1 .2-1.6-.3-.9-1.4-1.3-2.4-1.3-.8 0-1.8.3-2.2 1-.3 0-.5.1-.7.3-.3.4-.1 1.1.1 1.5-.2.2-.3.6-.3.9 0 .4.2.8.5 1 .1.9.4 1.4.6 1.6v.8c-1.7.7-3.6 1.6-3.6 2.2v1.6c0 .3.2.5.4.5h9.9c.2 0 .4-.2.4-.4zM.8 14c.3-.3 1.7-1 3.4-1.7.2-.1.3-.2.3-.4v-1.3c0-.2-.1-.3-.3-.4 0 0-.4-.2-.4-1.3 0-.2-.2-.4-.4-.4 0 0-.1-.1-.1-.2s.1-.2.1-.2c.2 0 .4-.2.4-.4 0-.1 0-.2-.1-.5-.2-.4-.3-.7-.3-.8h.2c.2 0 .4-.1.5-.3.1-.4.8-.7 1.6-.7s1.5.3 1.6.7c0 .5-.2.9-.3 1.2-.1.2-.1.2-.1.4s.2.4.4.4c.1.1 0 .4 0 .5-.3 0-.4.2-.4.4 0 1-.3 1.3-.3 1.3-.2.1-.3.2-.3.4V12c0 .2.1.3.3.4 1.6.6 3 1.3 3.3 1.6v1.1H.8V14z',
  PRIVATE = 'M13.7 13.3c0 .2-.1.4-.3.6v.4c0 .2-.1.3-.3.3s-.3-.1-.3-.3v-.4c-.2-.1-.3-.3-.3-.6 0-.4.3-.7.7-.7s.5.4.5.7zM8.7 8v1.7H8V8.4c-.4.2-.9.3-1.3.3-.5 0-.9-.1-1.3-.3v1.3c0 .1-.1.3-.2.3l-3.3 1.2c-.7.2-1.1.9-1.1 1.6V14h7.7v.7h-8c-.2 0-.3-.1-.3-.3v-1.6c0-1 .6-1.9 1.5-2.2l3.1-1.1V8C3.7 7.2 3 5.9 3 4.3 3 1.9 4.6 0 6.7 0s3.7 1.9 3.7 4.3c-.1 1.5-.7 2.9-1.7 3.7zm-5-4.5c.3.1.6.2 1 .2.9 0 1.9-.5 2.2-1.1 0-.1.1-.2.3-.2.1 0 .2.1.3.2.6 1 1.2 1.3 2.1 1.1C9.3 2 8.1.7 6.7.7c-1.5 0-2.6 1.2-3 2.8zm3 4.5c1.7 0 3-1.6 3-3.7-.2 0-.4.1-.6.1-.8 0-1.4-.4-2-1.1-.6.6-1.6 1-2.5 1-.4 0-.7-.1-1-.2v.2C3.7 6.4 5 8 6.7 8zm9.3 3.7v4c0 .2-.1.3-.3.3h-5.3c-.2 0-.3-.1-.3-.3v-4c0-.2.1-.3.3-.3h.3V11c0-1.3 1-2.3 2.3-2.3s2.3 1 2.3 2.3v.3h.3c.3 0 .4.2.4.4zm-4.7-.4h3.3V11c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v.3zm4 .7h-4.7v3.3h4.7V12z',
  LIGHT_BULB = 'M9.1 13.7H6.9c-.2 0-.4-.2-.4-.4v-.6C4.4 12.1 3 10.2 3 8c0-2.7 2.2-5 5-5s5 2.2 5 5c0 2.2-1.4 4.1-3.4 4.7v.6c-.1.2-.2.4-.5.4zM7.2 13h1.5v-.5c0-.2.1-.3.3-.4 1.9-.5 3.1-2.1 3.1-4.1 0-2.3-1.9-4.2-4.2-4.2S3.8 5.7 3.8 8c0 1.9 1.3 3.6 3.1 4.1.2 0 .3.2.3.4v.5zm1.6 3.4c0-.2-.2-.4-.4-.4h-.8c-.2 0-.4.2-.4.4s.2.4.4.4h.8c.2 0 .4-.2.4-.4zm.7-1.5c0-.2-.2-.4-.4-.4H6.9c-.2 0-.4.2-.4.4s.2.4.4.4h2.3c.2-.1.3-.2.3-.4zm-1.1-13V.4C8.4.2 8.2 0 8 0s-.4.2-.4.4v1.5c0 .2.2.4.4.4s.4-.2.4-.4zM16 8c0-.2-.2-.4-.4-.4h-1.5c-.2 0-.4.2-.4.4s.2.4.4.4h1.5c.2 0 .4-.2.4-.4zM2.3 8c0-.2-.2-.4-.4-.4H.4c-.2 0-.4.2-.4.4s.2.4.4.4h1.5c.2 0 .4-.2.4-.4zM4 4c.1-.1.1-.4 0-.5L2.3 1.8c-.1-.1-.3-.1-.5 0s-.1.4 0 .5L3.4 4c.1 0 .2.1.3.1S3.9 4 4 4zm8.6 0l1.6-1.6c.1-.1.1-.4 0-.5s-.4-.1-.5 0L12 3.4c-.1.1-.1.4 0 .5.1.1.2.1.3.1h.3z',
  QUOTE = 'M3.2 8.3c.2-1.7 1.1-3.4 2.6-5L3.6 1.5C2.3 2.8 1.4 4.1.8 5.5.3 7 0 8.5 0 10.2v4.3h6.1V8.3H3.2zm10 0c.2-1.7 1.1-3.4 2.6-5l-2.2-1.8c-1.3 1.3-2.2 2.6-2.8 4-.6 1.5-.9 3-.9 4.7v4.3H16V8.3h-2.8z',
  HAMBURGER = 'M2 3c0-.6.4-1 .9-1h10.3c.4 0 .8.4.8 1s-.4 1-.9 1H2.9C2.4 4 2 3.6 2 3zm11.1 4H2.9c-.5 0-.9.4-.9 1s.4 1 .9 1h10.3c.4 0 .8-.4.8-1s-.4-1-.9-1zm0 5H2.9c-.5 0-.9.4-.9 1s.4 1 .9 1h10.3c.5 0 .9-.4.9-1-.1-.6-.5-1-1-1z',
  HAMBURGER_CLOSE = 'M9.4 8l4.3-4.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L8 6.6 3.7 2.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L6.6 8l-4.3 4.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3L8 9.4l4.3 4.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L9.4 8z',
  COMPASS = 'M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 15.3C4 15.3.7 12 .7 8S4 .7 8 .7 15.3 4 15.3 8 12 15.3 8 15.3zm3.9-11.6L6.2 6h-.1v.1l-2.3 5.7c-.1.1 0 .3.1.4.1.1.1.1.2.1h.1L9.8 10h.1v-.1l2.3-5.7c.1-.1 0-.3-.1-.4 0-.1-.1-.2-.2-.1zm-7.3 7.7l1.8-4.5L9 9.5l-4.4 1.9zm4.9-2.3L6.9 6.5l4.5-1.8-1.9 4.4z',
  GEAR = 'M15.7 6.7h-1.9c-.1-.5-.3-1.1-.5-1.6l1.4-1.4c.1-.1.1-.1.1-.2s0-.2-.1-.2l-1.9-1.9c-.1-.1-.3-.1-.5 0l-1.4 1.4c-.4-.2-1-.4-1.6-.5v-2C9.3.1 9.2 0 9 0H7c-.2 0-.3.1-.3.3v1.9c-.6.2-1.1.3-1.6.6L3.8 1.4c-.1-.1-.3-.1-.5 0L1.4 3.3c-.1 0-.1.1-.1.2s0 .2.1.2l1.4 1.4c-.2.4-.4 1-.5 1.6h-2c-.2 0-.3.1-.3.3v2c0 .2.1.3.3.3h1.9c.1.5.3 1.1.5 1.6l-1.4 1.4c-.1.1-.1.1-.1.2s0 .2.1.2l1.9 1.9c.1.1.3.1.5 0l1.4-1.4c.4.2 1 .4 1.6.5v1.9c0 .3.1.4.3.4h2c.2 0 .3-.1.3-.3v-1.9c.5-.1 1.1-.3 1.6-.5l1.4 1.4c.1.1.3.1.5 0l1.9-1.9c.1-.1.1-.3 0-.5l-1.4-1.4c.2-.4.4-1 .5-1.6h1.9c.2 0 .3-.1.3-.3V7c0-.2-.1-.3-.3-.3zm-.4 2h-1.8c-.2 0-.3.1-.3.3-.1.4-.3 1.4-.6 1.9-.1.1-.1.3 0 .4l1.3 1.3-1.4 1.4-1.3-1.3c-.1-.1-.3-.1-.4 0-.5.3-1.5.5-1.9.6-.1 0-.3.2-.3.3v1.8H7.3v-1.8c0-.2-.1-.3-.3-.3-.4-.1-1.4-.3-1.9-.6-.1-.1-.3-.1-.4 0L3.4 14 2 12.6l1.3-1.3c.1-.1.1-.3 0-.4-.3-.5-.5-1.5-.6-1.9 0-.1-.2-.3-.3-.3H.7V7.3h1.8c.2 0 .3-.1.3-.3.1-.4.3-1.4.6-1.9.1-.1.1-.3 0-.4L2.1 3.5l1.4-1.4 1.3 1.3c.1.1.3.1.4 0 .5-.3 1.5-.5 1.9-.6.1 0 .3-.2.3-.3V.7h1.3v1.8c0 .2.1.3.3.3.6.1 1.4.4 1.9.6.1.1.3.1.4 0l1.3-1.3L14 3.5l-1.3 1.3c-.1.1-.1.3 0 .4.3.5.5 1.5.6 1.9 0 .1.2.3.3.3h1.8v1.3zM8 4.7C6.2 4.7 4.7 6.2 4.7 8s1.5 3.3 3.3 3.3 3.3-1.5 3.3-3.3S9.8 4.7 8 4.7zm0 6c-1.5 0-2.7-1.2-2.7-2.7S6.5 5.3 8 5.3s2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7z',
  LOGOUT = 'M10.2 11.6v2.9c0 .2-.2.4-.4.4H.4c-.2 0-.4-.2-.4-.4v-13c0-.2.2-.4.4-.4h9.5c.2 0 .4.2.4.4v2.9c0 .2-.2.4-.4.4s-.4-.2-.4-.4V1.8H.7v12.4h8.7v-2.5c0-.2.2-.4.4-.4s.4.1.4.3zm5.7-3.9l-3.6-3.6c-.1-.1-.4-.1-.5 0-.1.1-.1.4 0 .5l3 3H3.3c-.2 0-.4.2-.4.4s.2.4.4.4h11.5l-3 3c-.1.1-.1.4 0 .5 0 .1.1.1.2.1s.2 0 .3-.1l3.6-3.6c.1-.1.1-.2.1-.3 0-.1 0-.2-.1-.3z',
  TALENTS = 'M14.5 16c-.1 0-.2 0-.3-.1l-2-1.4-2 1.4c-.2.1-.4.1-.6 0-.2-.1-.2-.4-.2-.6l.9-2.3-1.7-1.4c-.2-.1-.2-.3-.1-.5s.3-.3.5-.3h2l.9-2c.1-.2.3-.3.4-.3.2 0 .4.1.4.3l.8 2h2c.2 0 .4.1.5.3.1.2 0 .4-.1.5L14.1 13l.9 2.3c.1.2 0 .4-.2.6-.1 0-.2.1-.3.1zm-2.3-2.6c.1 0 .2 0 .3.1l1 .7-.4-1.2c-.1-.2 0-.4.1-.5l.9-.8h-.9c-.2 0-.4-.1-.5-.3l-.5-1.2-.5 1.2c-.1.2-.3.3-.4.3h-.9l.9.8c.2.1.2.4.1.5l-.4 1.2 1-.7c0-.1.1-.1.2-.1zm-4.9 0H.5c-.1 0-.3-.1-.4-.2-.1-.1-.1-.2-.1-.3 0-.1.1-1.8.4-2.8.3-.8 1.5-1.2 2.9-1.7.4-.1.9-.3 1.3-.4V7c-.4-.3-.9-.8-1-2-.1-.2-.3-.5-.3-.9 0-.2 0-.4.1-.6 0-.1.1-.2.1-.2-.1-.5-.4-1.5.1-2 .1-.2.4-.3.8-.3C4.9.3 6 0 6.9 0 8 0 9.3.4 9.5 1.3c.2.7 0 1.4-.2 1.8.2.2.3.6.3.9 0 .3-.1.5-.2.7 0 .2-.2.3-.3.4-.1 1-.6 1.6-.9 1.8v1l.6.3c.2.1.4.1.6.2l-.3.9c-.2 0-.4-.1-.6-.2-.3-.1-.6-.2-1-.3-.2-.1-.3-.3-.3-.5V6.7c0-.2.1-.4.3-.5 0 0 .7-.3.7-1.5 0-.3.2-.5.5-.5v-.4c-.1 0-.2 0-.3-.1-.2-.1-.2-.2-.2-.3 0-.1.1-.3.1-.4.1-.4.4-1 .3-1.4-.1-.3-.8-.6-1.7-.6s-1.6.3-1.7.6c0 .2-.3.4-.5.3h-.3s-.1.3.1 1.1c0 .2.1.3.1.4 0 .2-.1.3-.2.4v.5c.1.1.2.2.2.4 0 1.2.7 1.5.7 1.5.2.1.3.3.3.4v1.6c0 .2-.1.4-.3.5-.6.3-1.1.5-1.7.6-.9.3-2.2.7-2.3 1.1-.2.6-.3 1.4-.3 2h6.3v1zM4.1 4.2zm0-.3z',
  COMMENTS = 'M6.3 15.9v-5H0V.1h16v10.8h-4.3l-5.4 5zM1.1 9.8h6.3v3.6l3.9-3.6h3.6V1.2H1.1v8.6zm10.6-4.6h-1.1v1.1h1.1V5.2zm-3.2 0H7.4v1.1h1.1V5.2zm-3.3 0h-1v1.1h1.1V5.2h-.1z',
  TRASH = 'M15.2 2h-4.4V.5c0-.3-.2-.5-.5-.5H5.1c-.3 0-.5.2-.5.5V2H.8c-.3 0-.5.2-.5.4s.2.5.5.5H2v12.6c0 .3.2.5.4.5h10.4c.3 0 .5-.2.5-.5V2.9h1.8c.3 0 .5-.2.5-.5s-.1-.4-.4-.4zM5.6 1h4.2v1H5.6V1zm6.8 14H2.9V2.9h9.4V15zM5.6 4.7v7.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5V4.7c0-.3.2-.5.5-.5s.5.3.5.5zm2.6 0v7.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5V4.7c0-.3.2-.5.5-.5s.5.3.5.5zm2.1-.5c.3 0 .5.2.5.5v7.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5V4.7c0-.2.2-.5.5-.5z',
  CHEVRON_UP = 'M8 3.1c.3 0 .5.1.7.3l7.1 8c.3.4.3 1-.1 1.3-.4.3-1 .3-1.3-.1L8 5.4l-6.4 7.2c-.3.4-.9.4-1.3.1-.4-.3-.4-.9-.1-1.3l7.1-8c.2-.2.4-.3.7-.3z',
  CHEVRON_DOWN = 'M8 12.9c-.3 0-.5-.1-.7-.3l-7.1-8c-.3-.4-.3-1 .1-1.3.4-.3 1-.3 1.3.1L8 10.6l6.4-7.2c.3-.4.9-.4 1.3-.1.4.3.4.9.1 1.3l-7.1 8c-.2.2-.4.3-.7.3z',
  CHEVRON_LEFT = 'M3.1 8c0-.3.1-.5.3-.7l8-7.1c.4-.3 1-.3 1.3.1.3.4.3 1-.1 1.3L5.4 8l7.2 6.4c.4.3.4.9.1 1.3-.3.4-.9.4-1.3.1l-8-7.1c-.2-.2-.3-.4-.3-.7z',
  CHEVRON_RIGHT = 'M12.9 8c0 .3-.1.5-.3.7l-8 7.1c-.4.3-1 .3-1.3-.1-.3-.4-.3-1 .1-1.3L10.6 8 3.4 1.6C3 1.3 3 .7 3.3.3c.3-.4.9-.4 1.3-.1l8 7.1c.2.2.3.4.3.7z',
  CHECK = 'M5.2 13.8c-.4 0-.8-.1-1-.4L.4 9.7c-.6-.6-.6-1.5 0-2.1s1.5-.6 2.1 0l2.7 2.7 8.3-7.7c.6-.6 1.5-.5 2.1.1s.5 1.5-.1 2.1l-9.4 8.6c-.2.3-.6.4-.9.4z',
  CHECK_CIRCLE = 'M6.4 11.2c-.2 0-.5-.1-.7-.3L4.1 9.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l.9.9L10.5 5c.4-.4 1-.3 1.4 0 .4.4.3 1 0 1.4l-4.8 4.5c-.2.2-.5.3-.7.3zM16 8c0-4.4-3.6-8-8-8S0 3.6 0 8s3.6 8 8 8 8-3.6 8-8zm-1.3 0c0 3.7-3 6.7-6.7 6.7s-6.7-3-6.7-6.7 3-6.7 6.7-6.7 6.7 3 6.7 6.7z',
  CHECK_LARGE = 'M10.7 16c-.1 0-.2 0-.2-.1L8 13.5l-2.4 2.4c-.1.1-.3.1-.4.1-.1-.1-.2-.2-.2-.3v-4.3h.7v3.5l2.1-2.1c.1-.1.3-.1.5 0l2.1 2.1v-3.5h.6v4.3c0 .1-.1.3-.2.3h-.1zm3-10.3C13.7 2.5 11.1 0 8 0S2.3 2.5 2.3 5.7c0 3.1 2.5 5.7 5.7 5.7s5.7-2.6 5.7-5.7zm-.7 0c0 2.8-2.2 5-5 5s-5-2.2-5-5 2.2-5 5-5 5 2.2 5 5zm-1 0c0-2.2-1.8-4-4-4s-4 1.8-4 4 1.8 4 4 4 4-1.8 4-4zm-.7 0C11.3 7.5 9.8 9 8 9S4.7 7.5 4.7 5.7 6.2 2.3 8 2.3s3.3 1.5 3.3 3.4zM7.8 7.6c.1 0 .2-.1.2-.2l2-2.8c.1-.1.1-.4-.1-.5-.1 0-.3 0-.4.2L7.7 6.7l-1-1c-.1-.1-.3-.1-.5 0s-.1.3 0 .5l1.3 1.3c.1 0 .2.1.3.1-.1 0 0 0 0 0z',
  SEND_ARROW = 'M16 8c0-.2-.1-.3-.3-.3L.5 2.1c-.2 0-.3 0-.4.2-.1.1-.1.3 0 .4L3 8 .1 13.3c-.1.1-.1.3 0 .5l.1.1c.1.1.2.1.4.1l15.3-5.6c0-.1.1-.2.1-.4z',
  EYE = 'M8 12.7C3.7 12.7.2 8.4.1 8.2c-.1-.1-.1-.3 0-.4.1-.2 3.6-4.5 7.9-4.5s7.8 4.3 7.9 4.5c.1.1.1.3 0 .4-.1.2-3.6 4.5-7.9 4.5zM.8 8c.7.8 3.7 4 7.2 4s6.5-3.2 7.2-4c-.7-.8-3.7-4-7.2-4S1.5 7.2.8 8zM8 11c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm0-5.3c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3 2.3-1 2.3-2.3-1-2.3-2.3-2.3zM9.7 8c0-.9-.7-1.7-1.7-1.7-.2 0-.3.1-.3.3 0 .3.1.4.3.4.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1c0-.2-.1-.3-.3-.3-.2 0-.4.1-.4.3 0 .9.7 1.7 1.7 1.7S9.7 8.9 9.7 8z',
  CALENDAR = 'M15.7 1.3h-2.3v-1c-.1-.2-.2-.3-.4-.3h-2c-.2 0-.3.1-.3.3v1H5.3v-1C5.3.1 5.2 0 5 0H3c-.2 0-.3.1-.3.3v1H.3c-.2 0-.3.2-.3.4v14c0 .2.1.3.3.3h15.3c.2 0 .3-.1.3-.3v-14c.1-.2 0-.4-.2-.4zM11.3.7h1.3v2h-1.3v-2zm-8 0h1.3v2H3.3v-2zM2.7 2v1c0 .2.1.3.3.3h2c.2 0 .3-.1.3-.3V2h5.3v1c0 .2.1.3.3.3h2c.2 0 .3-.1.3-.3V2h2v2.7H.7V2h2zm-2 13.3v-10h14.7v10H.7zM14.3 8c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3h-3v-1c0-.3-.1-.4-.3-.4s-.3.1-.3.3v1H8v-1c0-.2-.1-.3-.3-.3-.2 0-.4.1-.4.3v1H4.7v-1c0-.2-.2-.3-.4-.3s-.3.1-.3.3v1H1.7c-.2 0-.3.1-.3.3-.1.3.1.4.3.4H4v2H1.7c-.2 0-.3.1-.3.3 0 .2.1.3.3.3H4v2H1.7c-.2 0-.3.1-.3.3s.1.3.3.3H4v1c0 .2.1.3.3.3.2 0 .3-.1.3-.3v-1h2.7v1c0 .2.1.3.3.3.2 0 .3-.1.3-.3v-1h2.7v1c0 .2.1.3.3.3s.3-.1.3-.3v-1h3c.2 0 .3-.1.3-.3s-.1-.3-.3-.3h-3v-2h3c.2 0 .3-.1.3-.3 0-.2-.1-.3-.3-.3h-3V8h3.1zM4.7 8h2.7v2H4.7V8zm0 4.7v-2h2.7v2H4.7zm6 0H8v-2h2.7v2zm0-2.7H8V8h2.7v2z',
  CONVERSATION = 'M2.3 12h-.1c-.1-.1-.2-.2-.2-.3V9.3H.3C.1 9.3 0 9.2 0 9V1C0 .8.1.7.3.7H13c.2 0 .3.1.3.3v4.3c0 .2-.1.3-.3.3s-.3-.1-.3-.3v-4H.7v7.3h1.7c.2 0 .3.1.3.3v1.9l2.1-2.1H5.7c.2 0 .3.1.3.3s-.1.3-.3.3h-.6l-2.6 2.6c0 .1-.1.1-.2.1zm12.2 3.3c.1-.1.2-.2.2-.3v-2.3h1c.2 0 .3-.1.3-.3V7c0-.2-.1-.3-.3-.3h-8c-.2 0-.4.1-.4.3v5.3c0 .2.1.3.3.3h3.5l2.9 2.6c.1.1.1.1.2.1h.3zM8 7.3h7.3V12h-1c-.2 0-.3.1-.3.3v1.9L11.6 12H8V7.3z',
  DETAIL = 'M9.9 10.7c.4-.5.6-1.1.6-1.8C10.5 7.3 9.2 6 7.6 6 6 6 4.7 7.3 4.7 8.9s1.3 2.9 2.9 2.9c.7 0 1.3-.2 1.8-.6l2.1 2.1c.1.1.2.1.2.1.1 0 .2 0 .2-.1.1-.1.1-.3 0-.5l-2-2.1zM5.4 8.9c0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2s-2.2-.9-2.2-2.2zM14 4.2s0-.1-.1-.1l-4-4-.1-.1H2.3c-.2 0-.3.1-.3.3v15.3c0 .3.1.4.3.4h11.3c.2 0 .3-.1.3-.3V4.3c.1 0 .1-.1.1-.1zm-4-3.1L12.9 4H10V1.1zM2.7 15.3V.7h6.7v3.7c0 .2.1.3.3.3h3.7v10.7H2.7z',
  COLLAPSE_DETAIL = 'M14 4.2s0-.1-.1-.1l-4-4-.1-.1H2.3c-.2 0-.3.1-.3.3v15.3c0 .3.1.4.3.4h11.3c.2 0 .3-.1.3-.3V4.3c.1 0 .1-.1.1-.1zm-4-3.1L12.9 4H10V1.1zM2.7 15.3V.7h6.7v3.7c0 .2.1.3.3.3h3.7v10.7H2.7zm3.9-2.8c-.1 0-.2 0-.3-.1-.1-.1-.1-.4 0-.5l2.8-2.4-2.8-2.6c-.1-.1-.2-.4 0-.5.1-.1.4-.2.5 0l3.1 2.7c.1.1.1.2.1.3 0 .1 0 .2-.1.3l-3.1 2.7c-.1 0-.1.1-.2.1z',
  BELL = 'M14.9 12.7c-.7 0-1.3-.6-1.3-1.3V7.6c0-2.3-1.3-4.4-3.4-5.4C10 1 8.9 0 7.6 0S5.2 1 5.1 2.3c-2.1.9-3.4 3-3.4 5.3v3.8c0 .7-.6 1.3-1.3 1.3-.2 0-.4.2-.4.4s.2.4.4.4h5.1c.2 1.8 1.2 2.5 2.1 2.5.5 0 1.9-.2 2.1-2.4h5.1c.2 0 .4-.2.4-.4s-.1-.5-.3-.5zm-7.3 2.5c-.7 0-1.2-.6-1.3-1.6h2.5c0 .7-.2 1.6-1.2 1.6zm-5.5-2.5c.3-.4.4-.8.4-1.3V7.6c0-2.1 1.2-3.9 3.1-4.7.2-.1.3-.2.3-.4 0-.9.8-1.7 1.7-1.7s1.7.8 1.7 1.7c0 .2.1.3.3.4 1.9.8 3.1 2.6 3.1 4.7v3.8c0 .5.2.9.4 1.3h-11z',
  NOTES = 'M12.8 9.2v6.4c0 .2-.2.4-.4.4H.4c-.2 0-.4-.2-.4-.4V2c0-.2.2-.4.4-.4h8c.2 0 .4.2.4.4s-.2.4-.4.4H.8v12.8H12v-6c0-.2.2-.4.4-.4s.4.2.4.4zM15.9 3l-1.5 1.5-7.1 7.1-.1.1-4 1.7H3c-.1 0-.2 0-.3-.1-.1-.1-.1-.3-.1-.4l1.7-4s0-.1.1-.1l7.1-7.1L13 .2c.2-.2.4-.2.6 0l2.3 2.3c.1 0 .1.3 0 .5zm-2.3 1.1l-1.7-1.7L5.4 9l1.7 1.7 6.5-6.6zm-9.7 8L6.4 11 4.9 9.7l-1 2.4zM15 2.7L13.3 1l-.9.9 1.7 1.7.9-.9z',
  FILE_GENERIC = 'M13.9 4.1l-4-4C9.8 0 9.8 0 9.7 0H2.3c-.2 0-.3.1-.3.3v15.3c0 .3.1.4.3.4h11.3c.2 0 .3-.1.3-.3V4.3c.1-.1.1-.1 0-.2zm-3.9-3L12.9 4H10V1.1zM2.7 15.3V.7h6.7v3.7c0 .2.1.3.3.3h3.7v10.7H2.7zM4.7 5c0-.2.1-.3.3-.3h3c.2 0 .3.1.3.3 0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3zm6.6 2c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h6c.2 0 .3.1.3.3zm0 2c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h6c.2 0 .3.1.3.3zm0 2c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h6c.2 0 .3.1.3.3zm0 2c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h6c.2 0 .3.1.3.3z',
  FILE_PDF = 'M14.6 4.1l-4-4c-.1-.1-.2-.1-.3-.1H1.7c-.2 0-.4.1-.4.3v15.3c0 .2.1.3.3.3h12.7c.2 0 .3-.1.3-.3V4.3c.1-.1 0-.1 0-.2zm-3.9-3L13.5 4h-2.9V1.1zM2 15.3V.7h8v3.7c0 .2.1.3.3.3H14v10.7H2zm9.1-7c-1 0-1.9.1-2.7.4-.5-.4-1.1-.8-1.7-1.3-.1-1-.3-1.9-.6-2.5-.1-.4-.3-.6-.6-.6s-.7.1-.9.5c-.2.3-.2.7 0 1.1.5.7.9 1.4 1.5 1.9.1.7 0 1.4-.1 2.1-.9.6-1.5 1.2-1.9 1.7-.2.3-.3.6-.2.9.1.3.4.5.8.5h.1c.3 0 .6-.2.8-.4.5-.7.9-1.6 1-2.4.5-.3 1.1-.6 1.8-.8 1.1.5 2 .7 2.7.8.3 0 .6-.1.8-.3.2-.3.2-.7.1-1-.1-.4-.5-.6-.9-.6zM5.2 5.5c-.1-.2-.1-.3 0-.4 0-.1.1-.1.2-.1 0 0 .1 0 .1.1.1.4.3.9.4 1.4-.2-.3-.5-.6-.7-1zm-.1 6.7c-.1.1-.2.2-.3.2-.1 0-.2-.1-.2-.2s0-.2.1-.3l1.2-1.2c-.3.6-.5 1.1-.8 1.5zm1.7-2.8v-1l.9.6c-.4.1-.7.2-.9.4zm4.6.1s-.1.1-.2.1c-.5-.1-1.1-.2-1.8-.4.6-.2 1.1-.3 1.7-.2.2 0 .3.1.3.2.1 0 .1.2 0 .3z',
  FILE_WORD = 'M14.6 4.1l-4-4c-.1-.1-.2-.1-.3-.1H1.7c-.2 0-.4.1-.4.3v15.3c0 .2.1.3.3.3h12.7c.2 0 .3-.1.3-.3V4.3c.1-.1 0-.1 0-.2zm-3.9-3L13.5 4h-2.9V1.1zM2 15.3V.7h8v3.7c0 .2.1.3.3.3H14v10.7H2zM4.7 5c0-.2.1-.3.3-.3h3c.2 0 .3.1.3.3 0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3zm6.6 2c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h6c.2 0 .3.1.3.3zm-4 2c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h2c.2 0 .3.1.3.3zm0 2c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h2c.2 0 .3.1.3.3zm0 2c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h2c.2 0 .3.1.3.3zm4-4v.7c0 .2-.1.3-.3.3s-.3-.1-.3-.3v-.4H10v3.3h.7c.2 0 .3.1.3.3 0 .2-.1.3-.3.3h-2c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h.7V9.3h-.7v.3c0 .2-.1.3-.3.3-.3.1-.4 0-.4-.2V9c0-.2.1-.3.3-.3H11c.2 0 .3.1.3.3z',
  FILE_EXCEL = 'M13.9 4.1l-4-4C9.8 0 9.8 0 9.7 0H2.3c-.2 0-.3.1-.3.3v15.3c0 .3.1.4.3.4h11.3c.2 0 .3-.1.3-.3V4.3c.1-.1.1-.1 0-.2zm-3.9-3L12.9 4H10V1.1zM2.7 15.3V.7h6.7v3.7c0 .2.1.3.3.3h3.7v10.7H2.7zm9-10H4.3c-.2 0-.3.2-.3.4v8c0 .2.1.3.3.3h7.3c.2 0 .3-.1.3-.3v-8c.1-.2 0-.4-.2-.4zm-5 4h-2V8h2v1.3zM7.3 8h4v1.3h-4V8zm-2.6 2h2v1.3h-2V10zm2.6 0h4v1.3h-4V10zm4-2.7h-4V6h4v1.3zM6.7 6v1.3h-2V6h2zm-2 6h2v1.3h-2V12zm2.6 1.3V12h4v1.3h-4z',
  FILE_POWERPOINT = 'M10.3 6.4c-.7-.5-1.5-.7-2.3-.7-2.2 0-4 1.8-4 4s1.8 4 4 4c1 0 1.8-.3 2.5-.9h.1c.9-.7 1.4-1.8 1.4-3 0-1.5-.7-2.7-1.7-3.4zM8 6.3c.6 0 1.1.2 1.6.4L7.8 9.3H4.7c.2-1.6 1.6-3 3.3-3zM4.7 10h3.1l2 2.4c-.5.4-1.1.6-1.8.6-1.7 0-3.1-1.3-3.3-3zm5.7 2l-2-2.3 1.7-2.5c.7.6 1.2 1.5 1.2 2.5 0 .9-.3 1.7-.9 2.3zm4.2-7.9l-4-4c-.1-.1-.2-.1-.3-.1H1.7c-.2 0-.4.1-.4.3v15.3c0 .2.1.3.3.3h12.7c.2 0 .3-.1.3-.3V4.3c.1-.1 0-.1 0-.2zm-3.9-3L13.5 4h-2.9V1.1zM2 15.3V.7h8v3.7c0 .2.1.3.3.3H14v10.7H2z',
  FILE_NEW = 'M2.8 4.5c0-.2.2-.3.3-.3h3.1c.2 0 .3.2.3.3 0 .2-.2.3-.3.3H3.1c-.2.1-.3-.1-.3-.3zm4.8 2.1c0-.2-.2-.3-.3-.3H3.1c-.2 0-.3.2-.3.3 0 .2.1.4.3.4h4.1c.2 0 .4-.2.4-.4zM5.9 8.7c0-.2-.2-.3-.3-.3H3.1c-.2 0-.3.2-.3.3s.2.3.3.3h2.4c.2.1.4-.1.4-.3zm-2.8 1.8c-.2 0-.3.2-.3.3 0 .2.2.3.3.3h1.7c.2 0 .3-.2.3-.3 0-.2-.2-.3-.3-.3H3.1zm12.2 1c0 2.5-2 4.5-4.5 4.5s-4.5-2-4.5-4.5S8.3 7 10.8 7s4.5 2 4.5 4.5zm-.7 0c0-2.1-1.7-3.8-3.8-3.8S7 9.4 7 11.5s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8zm-3.6-2l-.1-.1h-.3s-.1 0-.1.1l-1.4 1.4c-.1.1-.1.3 0 .5.1.1.4.1.5 0l.8-.8v2.6c0 .2.2.3.3.3s.3-.2.3-.3v-2.6l.8.8c.1.1.2.1.2.1s.2 0 .2-.1c.1-.1.1-.4 0-.5L11 9.5zm-9.6 5.1h5.2v.7H1c-.2 0-.3-.2-.3-.3V.3C.7.2.8 0 1 0h7.8s.1 0 .1.1l3.5 3.5.1.1v2.5h-.7v-2H8.7c-.2 0-.4-.2-.4-.4V.7h-7v13.9zM9 3.5h2.3L9 1.2v2.3z',
  UNDO = 'M13.8 2.9C12.4 1.6 10.7 1 8.8 1c-1.9.1-3.6.9-4.9 2.3-1.1 1.3-1.7 2.8-1.8 4.6L.6 5.7c-.1-.1-.3-.2-.4-.1-.2.1-.2.3-.1.5l2.1 3.1.1.1h.2c.1 0 .1-.1.1-.1L5 6.4c.1-.1.1-.4 0-.5 0 0-.3 0-.4.1L2.8 8.1c.1-1.7.6-3.2 1.6-4.3 1.1-1.3 2.7-2 4.4-2.1 1.7-.1 3.3.5 4.5 1.7 1.2 1.1 1.9 2.7 2 4.4.1 1.7-.5 3.3-1.7 4.5-1.1 1.2-2.7 1.9-4.4 2-.2 0-.3.2-.3.4s.2.3.3.3c1.9-.1 3.6-.9 4.8-2.2s1.9-3.1 1.9-5-.8-3.6-2.1-4.9z',
  PUBLISH = 'M5.1 3.4c-.2-.2-.2-.5 0-.7L7.7.1s.1-.1.2-.1h.4c.1 0 .1.1.2.1l2.6 2.6c.2.2.2.5 0 .7-.1.1-.2.1-.3.1s-.2 0-.3-.1l-2-1.8v4.6c0 .3-.2.5-.5.5s-.5-.2-.5-.5V1.6L5.8 3.4c-.2.2-.5.2-.7 0zm9.1 3.8V13c0 .8-.6 1.4-1.4 1.4H8.5v.6h1.4c.3 0 .5.2.5.5s-.2.5-.5.5H6.1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1.4v-.6H3.2c-.8 0-1.4-.6-1.4-1.4V7.2c0-.8.6-1.4 1.4-1.4h1c.3 0 .5.2.5.5s-.3.4-.5.4h-1c-.3 0-.5.2-.5.5v4.3h10.6V7.2c0-.3-.2-.5-.5-.5h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.8.1 1.4.7 1.4 1.5zm-.9 5.8v-.5H2.7v.5c0 .3.2.5.5.5h9.6c.3-.1.5-.3.5-.5z',
  REPUBLISH = 'M14.2 2.9c-.3 0-.6.3-.6.6s.3.6.6.6.6.3.6.6v5.5H1.2V4.7c0-.3.3-.6.6-.6s.6-.3.6-.6-.2-.6-.6-.6c-1 0-1.8.8-1.8 1.8v7.4c0 1 .8 1.8 1.8 1.8h5.5v.8H5.5c-.3 0-.6.3-.6.6s.3.7.6.7h4.9c.3 0 .6-.3.6-.6s-.3-.6-.6-.6H8.6V14h5.5c1 0 1.8-.8 1.8-1.8V4.7c.1-1-.7-1.8-1.7-1.8zm0 9.8H1.8c-.3 0-.6-.3-.6-.6v-.6h13.5v.6c.1.3-.2.6-.5.6zM3.8 2.6c-.2-.3-.1-.7.2-.9.3-.2.7-.1.9.1l.5.7C5.8 1.1 7.1.1 8.6 0c.9 0 1.9.3 2.6.9.7.6 1.1 1.5 1.1 2.5 0 .9-.3 1.9-.9 2.6-.6.7-1.5 1.1-2.5 1.1-.3 0-.6-.3-.6-.6s.3-.6.6-.6c.6 0 1.2-.3 1.6-.7.4-.5.6-1 .6-1.7 0-1.3-1.1-2.3-2.4-2.2-1.1 0-1.9.7-2.2 1.7l.8-.3c.3-.2.7 0 .8.3.1.3 0 .7-.3.8l-2 .8h-.2c-.2 0-.4-.1-.5-.3L3.8 2.6z',
  UNPUBLISH = 'M5.1 4c-.2-.2-.2-.5 0-.7s.5-.2.7 0L7.5 5V.5c0-.3.2-.5.5-.5s.5.2.5.5v4.6l1.7-1.7c.2-.2.5-.2.7 0s.2.5 0 .7L8.3 6.6s-.1.1-.2.1h-.4c-.1 0-.1-.1-.2-.1L5.1 4zm9.1 3.2V13c0 .8-.6 1.4-1.4 1.4H8.5v.6h1.4c.3 0 .5.2.5.5s-.2.5-.5.5H6.1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1.4v-.6H3.2c-.8 0-1.4-.6-1.4-1.4V7.2c0-.8.6-1.4 1.4-1.4h1c.3 0 .5.2.5.5s-.2.5-.5.5h-1c-.3 0-.5.2-.5.5v4.3h10.6V7.2c0-.3-.2-.5-.5-.5h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c.8.1 1.4.7 1.4 1.5zm-.9 5.8v-.5H2.7v.5c0 .3.2.5.5.5h9.6c.3-.1.5-.3.5-.5z',
  ADD_PEER = 'M8.8 9.6V8c1-.8 1.6-2.1 1.6-3.6C10.4 2 8.8 0 6.7 0S2.9 2 2.9 4.4c0 1.5.7 2.8 1.6 3.6v1.2l-2.9 1c-1 .3-1.6 1.3-1.6 2.3v1.6c0 .3.2.5.5.5h7.2v-1H1v-1.1c0-.6.4-1.2 1-1.4l3.3-1.2c.1.1.3-.1.3-.3v-1c.4.1.7.2 1.1.2.4 0 .8-.1 1.1-.2v1h1zM6.7 1C8 1 9.1 2.1 9.4 3.6c-.8.1-1.3-.2-1.7-1-.2-.2-.3-.3-.5-.3s-.4.1-.5.3c-.3.5-1.2 1-2 1-.3 0-.5 0-.7-.1C4.4 2 5.4 1 6.7 1zM3.9 4.5c.3.1.5.1.8.1.9 0 1.8-.4 2.4-.9.5.7 1.2 1 2 1h.3c-.1 1.7-1.2 3.1-2.7 3.1-1.5 0-2.7-1.5-2.8-3.3zm8.3 4c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8zm0 6.5c-1.5 0-2.8-1.2-2.8-2.8s1.2-2.8 2.8-2.8 2.8 1.2 2.8 2.8-1.2 2.8-2.8 2.8zm2.2-2.8c0 .3-.2.5-.5.5h-1.1v1.1c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1.1h-1.1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1.1v-1.1c0-.3.2-.5.5-.5s.5.2.5.5v1.1h1.1c.2 0 .5.3.5.5z',
  DREAM = 'M16 3.8c0 .9-.3 1.8-.9 2.4-.6.7-1.5 1.1-2.6 1.1-.5.6-1.2 1-1.8 1-.8 0-1.6-.5-2-1.2l.6-.3c.3.5.8.8 1.4.8.6 0 1.1-.4 1.4-.9.1-.1.2-.1.3-.1 1 0 1.7-.3 2.3-.9.5-.5.7-1.2.7-2 0-1.6-1.1-3.1-3.1-3.1-.8 0-1.4.3-2 .9-.2.2-.3.2-.5.1-.7-.4-1.5-.4-2.3 0-.7.3-1.3 1-1.5 1.8l-.6-.2c.2-1 .9-1.8 1.9-2.2C8.1.6 9.1.6 9.9.9c.7-.6 1.5-.9 2.4-.9C14.7 0 16 2 16 3.8zM10.7 14v1.7c0 .2-.1.3-.3.3H.4c-.3 0-.4-.1-.4-.3V14c0-.6 2.4-1.7 3.7-2.2v-.9c-.3-.2-.6-.7-.7-1.7-.3-.2-.4-.6-.4-1 0-.2.1-.4.2-.6 0-.1.1-.1.2-.2-.3-.4-.6-1.1-.2-1.5.1-.2.4-.3.7-.3.4-.7 1.4-1 2.2-1 .9.1 2.1.4 2.3 1.3.2.6-.1 1.2-.2 1.6.1 0 .1.1.2.2 0 .1.1.3.1.5 0 .4-.2.8-.4 1 0 1-.4 1.4-.7 1.6v.9c1.3.6 3.7 1.7 3.7 2.3zm-.7.1c-.3-.3-1.7-1.1-3.5-1.8-.1-.1-.2-.2-.2-.3v-1.3c0-.1.1-.3.2-.3 0 0 .4-.2.4-1.4 0-.2.1-.3.3-.3 0 0 .1-.2.1-.4 0-.1 0-.2-.1-.2 0-.1-.2-.2-.2-.4 0-.1 0-.2.1-.3.2-.4.4-.8.2-1.3-.1-.5-.8-.8-1.6-.8s-1.6.3-1.7.8c0 .2-.2.3-.4.2-.2-.1-.3 0-.3 0 0 .1 0 .3.3.9.1.2.1.3.1.4 0 .1 0 .2-.1.2s-.2.2-.3.2l-.1.1c-.1.2 0 .5.1.6.2 0 .3.1.3.3 0 1.1.5 1.4.5 1.4.1.1.2.2.2.3V12c0 .1-.1.3-.2.3-1.7.7-3.2 1.5-3.5 1.8v1.3H10v-1.3zm2.3-4.1c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zm-.6 0c0-.2-.1-.3-.3-.3-.3 0-.4.1-.4.3s.1.3.3.3c.2 0 .4-.1.4-.3zM8.3 4c0-.4.3-.7.7-.7s.7.3.7.7-.3.7-.7.7-.7-.3-.7-.7zm0 7c0-.4.3-.7.7-.7s.7.3.7.7-.3.7-.7.7-.7-.3-.7-.7zM11 4.7c-.4 0-.7-.3-.7-.7s.3-.7.7-.7.7.3.7.7-.3.7-.7.7zm2 0c-.4 0-.7-.3-.7-.7s.3-.7.7-.7.7.3.7.7-.3.7-.7.7z',
  ADD_DREAM = 'M6.6 2.8l-.7-.2c.2-.8.8-1.5 1.5-1.8C8.1.5 8.8.5 9.5.7c.5-.5 1.1-.7 1.9-.7 2 0 3 1.6 3 3.1 0 .8-.3 1.5-.7 2-.5.5-1.2.9-2.1.9-.4.5-.9.8-1.5.8-.7 0-1.3-.4-1.6-1l.6-.3c.2.3.6.5 1 .5s.8-.3 1-.6c.1-.1.2-.2.3-.2.7 0 1.3-.2 1.7-.7.4-.4.6-.9.6-1.5 0-1.2-.8-2.4-2.3-2.4-.6 0-1.1.2-1.5.7-.2.2-.4.2-.5.1-.5-.3-1.2-.3-1.7 0-.6.3-1 .8-1.1 1.4zm.7 6.5v-.6c.2-.2.5-.5.5-1.3.2-.2.4-.5.4-.8 0-.2 0-.4-.1-.5l-.2-.2c.1-.3.3-.7.1-1.2-.1-.8-1.1-1-1.8-1s-1.5.2-1.8.8c-.2 0-.4.1-.6.2-.3.3-.1.8.1 1.2l-.1.1c-.1.2-.2.4-.2.6 0 .3.1.7.3.8 0 .7.3 1.1.5 1.3v.6c-1.9.8-2.9 1.4-2.9 1.8v1.3c0 .2.2.3.3.3h4.4V12H2.3v-.9c.3-.1 1.3-.7 2.7-1.2.1-.1.2-.2.2-.3v-1c0-.1-.1-.3-.2-.3 0 0-.3-.2-.3-1 0-.2-.1-.3-.3-.3-.1-.3-.1-.5-.1-.5.2 0 .3-.2.3-.3 0-.1 0-.2-.1-.4-.1-.4-.2-.6-.2-.7h.2c.2 0 .4-.1.4-.3.1-.2.6-.4 1.3-.4s1.2.2 1.2.5c.1.4-.1.7-.2.9 0 .1-.1.2-.1.3 0 .2.1.3.3.3v.4c-.2.1-.3.2-.3.4 0 .8-.3 1-.3 1-.1 0-.2.2-.2.3v1c0 .1.1.3.2.3s.4.2.4.2l.3-.6s-.1-.1-.2-.1zM11.5 8c0 .5-.4.9-.9.9s-.9-.4-.9-.9.4-.9.9-.9.9.4.9.9zm-.7 0c0-.1-.1-.2-.2-.2s-.2.1-.2.2.4.1.4 0zM8.2 3.2c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6-.6-.2-.6-.6zm0 5.5c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6c-.3.1-.6-.2-.6-.6zm2.2-4.9c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6zm.9-.6c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6-.6-.2-.6-.6zm3 9.3c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5S8.9 9 10.8 9s3.5 1.6 3.5 3.5zm-.7 0c0-1.5-1.3-2.8-2.8-2.8S8 11 8 12.5s1.3 2.8 2.8 2.8 2.8-1.3 2.8-2.8zm-1.5-.3h-1v-1c0-.2-.2-.3-.3-.3s-.3.2-.3.3v1h-1c-.2 0-.3.2-.3.3 0 .2.2.3.3.3h1v1c0 .2.2.3.3.3s.3-.2.3-.3v-1h1c.2 0 .3-.2.3-.3 0-.2-.1-.3-.3-.3z',
  GROUP = 'M8 4.9c1.17 0 2.12-.95 2.12-2.12S9.17.65 8 .65c-1.17 0-2.12.95-2.12 2.12S6.83 4.9 8 4.9zm0-3.27c.63 0 1.14.51 1.14 1.14S8.63 3.92 8 3.92c-.63 0-1.14-.51-1.14-1.14S7.37 1.63 8 1.63zm4.57 3.27c.81 0 1.47-.66 1.47-1.47s-.66-1.47-1.47-1.47-1.47.66-1.47 1.47.66 1.47 1.47 1.47zm0-1.96a.49.49 0 11-.002.982.49.49 0 01.002-.982zM3.43 4.9c.81 0 1.47-.66 1.47-1.47s-.66-1.47-1.47-1.47-1.47.66-1.47 1.47.66 1.47 1.47 1.47zm0-1.96a.49.49 0 11-.002.982.49.49 0 01.002-.982zM16 13.55c0 1.67-6.12 1.8-8 1.8s-8-.13-8-1.8c0-.35 0-1.27 4.67-1.66a.492.492 0 11.08.98c-2.19.19-3.22.49-3.62.69.69.33 3.02.82 6.87.82s6.18-.48 6.87-.82c-.4-.19-1.44-.5-3.64-.69a.493.493 0 01-.45-.53c.02-.27.27-.47.53-.45C16 12.28 16 13.2 16 13.55zm-9.63-1.47h3.27c.27 0 .49-.22.49-.49V7.35a2.121 2.121 0 00-4.24 0v4.25c-.01.26.21.48.48.48zm.49-4.73c0-.63.51-1.15 1.14-1.15.63 0 1.14.51 1.14 1.14v3.76H6.86V7.35zm4.73 3.43h1.96c.27 0 .49-.22.49-.49v-3.6c0-.81-.66-1.47-1.47-1.47s-1.47.66-1.47 1.47v3.59c0 .28.22.5.49.5zm.49-4.09a.49.49 0 01.98 0v3.1h-.98v-3.1zm-9.63 4.09h1.96c.27 0 .49-.22.49-.49v-3.6c0-.81-.66-1.47-1.47-1.47s-1.47.66-1.47 1.47v3.59c0 .28.22.5.49.5zm.49-4.09a.49.49 0 01.98 0v3.1h-.98v-3.1z',
  PENCIL_OUTLINE = 'M15.84 2.22L13.78.16a.55.55 0 00-.77 0l-.19.19c-.76-.42-1.74-.32-2.38.33L6.33 4.79a.544.544 0 00.39.93c.14 0 .27-.06.38-.16l4.11-4.11c.21-.21.49-.28.76-.24L8.9 4.27l-7.2 7.2a.552.552 0 000 .78l2.06 2.06c.1.09.24.15.38.15s.28-.05.39-.16l7.2-7.2 4.11-4.11c.1-.11.16-.24.16-.39 0-.14-.06-.28-.16-.38zM4.14 13.14l-1.29-1.29 6.43-6.43.64.64.64.64-6.42 6.44zm7.2-7.2l-.64-.64-.64-.64 3.34-3.34 1.29 1.28-3.35 3.34zM1.96 13.01c-.21-.21-.56-.21-.77 0s-.21.56 0 .77l.13.13-1.16 1.16a.544.544 0 00.39.93c.14 0 .28-.05.39-.16l1.16-1.16.13.13c.11.11.25.16.39.16a.544.544 0 00.39-.93l-1.05-1.03z',
  ADD = 'M8 0C3.59 0 0 3.59 0 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm0 14.67c-3.68 0-6.67-2.99-6.67-6.67S4.32 1.33 8 1.33 14.67 4.32 14.67 8 11.68 14.67 8 14.67zM12.67 8c0 .37-.3.67-.67.67H8.67V12a.67.67 0 01-1.34 0V8.67H4a.67.67 0 010-1.34h3.33V4a.67.67 0 011.34 0v3.33H12c.37 0 .67.3.67.67z',
}

type Props = {
  icon: Icons;
  size?: number;
  color?: string;
  className?: string;
};

type StyleProps = {
  size?: number;
  color?: string;
};

const useStyles = makeStyles({
  svgIconRoot: (props: StyleProps) => ({
    width: props.size ? `${props.size}rem` : '1.4rem',
    height: props.size ? `${props.size}rem` : '1.4rem',
    color: props.color ? props.color : '',
  }),
});

const SvgIcon = (props: Props) => {
  const { icon, size, color } = props;
  const classes = useStyles({ size, color });

  return (
    <MuiSvgIcon
      viewBox="0 0 16 16"
      className={props.className ? clsx(classes.svgIconRoot, props.className) : classes.svgIconRoot}
    >
      <path d={icon} />
    </MuiSvgIcon>
  );
};

export default SvgIcon;
