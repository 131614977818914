import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type Props = {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  focusTabsPanel: {
    backgroundColor: theme.palette.background.paper,
    '@media (min-width: 960px)': {
      minHeight: '35rem',
      maxHeight: '80rem',
      overflowY: 'auto',
    },
  },
}));

const TabPanel = (props: Props) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={classes.focusTabsPanel}
      {...other}
    >
      {children}
    </Typography>
  );
};

export default TabPanel;
