import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import {
  InspirationContext,
  LookBackRequest,
  LookBackResponse,
  FileDTO,
  LookBack,
} from '../../../../config/api/models';
import FormFields from '../../components/FormFields';
import ButtonGroup from '../../../../components/ButtonGroup';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import FormError from '../../../../components/forms/FormError';
import { useTranslation } from 'react-i18next';
import inspiration from '../../../inspiration';
import { PromiseState } from 'react-redux-fetch';
import { useSelector } from 'react-redux';
import { getSelectedFocusId } from '../../selectors';
import Message from '../../../../components/Message';
import { Box } from '@material-ui/core';
import FileList from '../../../shared/components/FileList';
import { LearnSubmitValues } from '../../model';
import { getPersistingFiles } from '../../../shared/selectors';

let submitting: boolean = false;

type LookBackFormValues = {
  title: string;
  body: string;
  files: FileDTO[];
};

type Props = {
  onSave: (values: LearnSubmitValues) => void;
  onClose?: () => void;
  onSubmit?: () => void;
  lookBackResponse?: PromiseState<LookBackResponse>;
  initialValues?: LookBackFormValues;
  lookback?: LookBack;
  visibility: boolean;
};

const LookBackForm = ({
  onSave,
  onClose,
  lookBackResponse,
  onSubmit,
  initialValues,
  visibility,
}: Props) => {
  const { t } = useTranslation();
  const [disableSubmit, preventSubmit] = useState(false);
  const selectedFocusId = useSelector(getSelectedFocusId);
  const [files] = useState(initialValues ? initialValues.files : []);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);
  const [filesChanged, setFilesChanged] = useState<boolean>(false);
  const persistingFiles = useSelector(getPersistingFiles);

  useEffect(() => {
    if (lookBackResponse && lookBackResponse.fulfilled && submitting && onSubmit) {
      submitting = false;
      onSubmit();
    }
  }, [lookBackResponse, onSubmit]);

  const handleFilesChange = (newFilesList: File[], fileIdsToDeleteList: string[]) => {
    setNewFiles(newFilesList);
    setFileIdsToDelete(fileIdsToDeleteList);
    setFilesChanged(!!(newFilesList.length || fileIdsToDeleteList.length));
  };

  return (
    <>
      <Form
        onSubmit={(values: LookBackFormValues) => {
          const request: LookBackRequest = {
            title: values.title.trim(),
            body: values.body.trim(),
            focusId: selectedFocusId,
            visibility,
          };
          submitting = true;
          onSave({ request, filesToAdd: newFiles, fileIdsToDelete });
        }}
        initialValues={initialValues}
        render={({ handleSubmit, pristine, form, values }) => (
          <form onSubmit={handleSubmit}>
            <inspiration.InspirationFormDialog
              inspirationKey={InspirationContext.COACHEE_LOOK_BACK}
              onSubmit={form.submit}
            >
              <FormFields
                onPreventSubmit={preventSubmit}
                titlePlaceholder={t('lookBack.titlePlaceholder')}
                textPlaceholder={t('lookBack.textPlaceholder')}
              />
              <FileList
                files={files}
                editable
                onFilesChange={handleFilesChange}
                loading={persistingFiles === 'lookback'}
              />
            </inspiration.InspirationFormDialog>
            <ButtonGroup>
              <Button
                type="submit"
                disabled={
                  (lookBackResponse && lookBackResponse.pending) ||
                  (pristine && !filesChanged) ||
                  persistingFiles === 'lookback' ||
                  disableSubmit ||
                  !values.title ||
                  !values.body
                }
                icon={Icons.SAVE}
              >
                {t('forms.buttons.save')}
              </Button>
              <Button type="button" onClick={onClose} color="secondary" icon={Icons.CANCEL}>
                {t('forms.buttons.cancel')}
              </Button>
            </ButtonGroup>
            {lookBackResponse &&
              lookBackResponse.rejected &&
              lookBackResponse.reason &&
              lookBackResponse.reason.cause.errors.map((item: any, index: number) => (
                <FormError key={index}>{item.detail}</FormError>
              ))}
          </form>
        )}
      />
      <Box marginTop="3rem">
        <Message type="warning">{t('lookBack.message')}</Message>
      </Box>
    </>
  );
};

export default LookBackForm;
