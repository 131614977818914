import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { ResetPasswordRequestData, UserId, UserResponse } from '../../../config/api/models';
import { ApiConfig } from '../api';
import { Field, Form } from 'react-final-form';
import { validations, TextFieldAdapter } from '../../../components/forms';
import FormControl from '../../../components/FormControl';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import Message from '../../../components/Message';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import useAutoLogin from '../hooks/useAutoLogin';
import FormError from '../../../components/forms/FormError';

type Props = {
  userId: UserId;
  token: string;
};

const ResetPasswordForm = ({ userId, token }: Props) => {
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const [userResponse, fetchUser] = useFetch<UserResponse>(ApiConfig.getUser(userId, token));
  const [resetPasswordResponse, resetPassword] = useFetch(ApiConfig.resetPassword(userId, token));

  useEffect(() => {
    if (!userResponse) {
      fetchUser();
    }
  }, [userResponse, fetchUser]);

  const { setLoginData, loginResponse } = useAutoLogin(resetPasswordResponse);

  const resetPasswordPending = resetPasswordResponse && resetPasswordResponse.pending;
  const loginPending = loginResponse && loginResponse.pending;

  return (
    <>
      {loginResponse && loginResponse.rejected && <Redirect to={getPath('login')} />}
      {userResponse && userResponse.rejected && (
        <Message type="error">{t('error.expiredLink')}</Message>
      )}
      {userResponse && userResponse.value && (
        <Form
          onSubmit={(values: ResetPasswordRequestData) => {
            setLoginData({
              password: values.password,
              email: userResponse.value ? userResponse.value.data.attributes.email : '',
            });
            resetPassword(values);
          }}
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          render={({ handleSubmit, values, valid }) => (
            <form onSubmit={handleSubmit}>
              <FormControl label={t('forms.labels.password')} id="password" required>
                <Field
                  name="password"
                  component={TextFieldAdapter}
                  validate={validations(t).isValidPassWord}
                  type="password"
                />
              </FormControl>
              <FormControl label={t('forms.labels.passwordConfirm')} id="passwordConfirm" required>
                <Field
                  name="passwordConfirm"
                  component={TextFieldAdapter}
                  validate={validations(t).match(values.password)}
                  type="password"
                />
              </FormControl>
              <div>
                <Button
                  type="submit"
                  disabled={!valid || resetPasswordPending || loginPending}
                  icon={Icons.SAVE}
                >
                  {t('forms.buttons.resetPassword')}
                </Button>
              </div>
            </form>
          )}
        />
      )}
      {resetPasswordResponse && resetPasswordResponse.rejected && (
        <FormError>{t('error.expiredLink')}</FormError>
      )}
    </>
  );
};

export default ResetPasswordForm;
