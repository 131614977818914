import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles';
import { Theme, TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import SvgIcon from './SvgIcon';
import { Icons } from './SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '.1rem solid #ced4da',
      fontSize: '1.4rem',
      padding: '1rem 1.2rem',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: theme.typography.fontFamily,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
    input: {
      padding: 0,
      color: theme.palette.common.black,
    },
    icon: {
      width: '1rem',
      height: '1rem',
    },
  })
);

const Select = (props: TextFieldProps) => {
  const classes = useStyles();

  return (
    <MuiTextField
      select
      fullWidth
      SelectProps={{
        IconComponent: () => <SvgIcon icon={Icons.CHEVRON_DOWN} className={classes.icon} />,
      }}
      {...props}
      InputProps={
        { classes, disableUnderline: true, ...props.InputProps } as Partial<OutlinedInputProps>
      }
    />
  );
};

export default Select;
