import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  RelationContact,
  User,
  Dream,
  Focus,
  ContextType,
  RelationContext,
  GroupRelation,
} from '../../../config/api/models';
import { useSelector } from 'react-redux';
import { getUnreadMessages } from '../selectors';
import { makeStyles, Theme } from '@material-ui/core/styles';
import UserCard from '../../../components/UserCard';
import { RelationOverviewProps } from '../models';
import { getKey } from '../helpers/helperFunctions';
import contactSort from '../helpers/contactSort';
import useUser from '../../security/hooks/useUser';
import { getCompassUserInfo } from '../../myCompass/selectors';
import { useCompassUser } from '../../shared/hooks';
import useFetchGroups from '../hooks/useFetchGroups';
import { MenuItem, InputAdornment, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Select from '../../../components/Select';
import { getUserDisplayName } from '../../../helpers/getUserDisplayName';

type Props = {
  contacts: RelationContact[];
  selectedRelation?: RelationOverviewProps;
  onLoadRelation: (user: User, relation: RelationContext) => void;
  fullWidth?: boolean;
  contextType?: ContextType;
  context?: Dream | Focus;
  hasGroupsFilter?: boolean;
  searchValue: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  userCard: {
    borderBottom: theme.config.defaultBorder,
  },
  fullWidth: {
    width: '100%',
    flexGrow: 1,
  },
  groupFilterWrapper: {
    padding: '0 1rem 1rem', // @NOTE: using hardcoded rems instead of theme.spacing because only some components use it, most do not (definitely not in the context where this component is used), and there is no rem override for spacing in the theme object

    '& .MuiInputBase-root': {
      padding: '0 0 0 1rem',
    },

    '& .MuiSelect-root': {
      padding: '1rem 0',
    },

    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: '1rem',
      pointerEvents: 'none',
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  fontSizeTweak: {
    fontSize: '1.2rem',
    color: theme.palette.text.secondary,
  },
}));

const ContactList = ({
  contacts,
  selectedRelation,
  onLoadRelation,
  fullWidth,
  contextType,
  context,
  hasGroupsFilter,
  searchValue,
}: Props) => {
  const unreadMessagesMap = useSelector(getUnreadMessages);
  const classes = useStyles();
  const currentUser = useUser();
  const compassUser = useSelector(getCompassUserInfo);
  const isCompassUser = useCompassUser();
  const userToUse = compassUser && isCompassUser ? compassUser : currentUser;
  const [groupsFetch, fetchGroups] = useFetchGroups();
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>('0');
  const { t } = useTranslation();

  useEffect(() => {
    if (hasGroupsFilter) {
      fetchGroups();
    }
  }, [hasGroupsFilter, fetchGroups]);

  const selectedGroup =
    groupsFetch &&
    groupsFetch.value &&
    selectedGroupId !== '0' &&
    groupsFetch.value.data.find(group => group.id === selectedGroupId);
  const selectedGroupRelationIds =
    selectedGroup &&
    selectedGroup.relationships.relations.data.map((relation: GroupRelation) =>
      parseInt(relation.id, 10)
    );
  const filteredContacts = selectedGroupRelationIds
    ? contacts.filter(contact => selectedGroupRelationIds.includes(contact.relation.relationId))
    : contacts;

  const isSelected = (relation: RelationContext) => {
    if (selectedRelation) {
      if (selectedRelation.role === 'coach') {
        return relation.relationType === 'coach'
          ? relation.initiatorId === selectedRelation.user.entityId
          : relation.receiverId === selectedRelation.user.entityId;
      }
      return relation.relationType === 'coachee'
        ? relation.initiatorId === selectedRelation.user.entityId
        : relation.receiverId === selectedRelation.user.entityId;
    }
    return false;
  };

  return (
    <div className={fullWidth ? classes.fullWidth : ''}>
      {hasGroupsFilter && groupsFetch && groupsFetch.value && (
        <>
          <div className={classes.groupFilterWrapper}>
            <Select
              value={selectedGroupId}
              onChange={event => setSelectedGroupId(event.target.value as string)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    disableTypography
                    position="start"
                    classes={{ root: clsx(classes.uppercase, classes.fontSizeTweak) }}
                  >
                    {t('myCoach.myGroups.group')}
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={'0'}>{t('myCoach.myGroups.allGroups')}</MenuItem>
              {groupsFetch.value.data.map(group => (
                <MenuItem value={group.id} key={group.id}>
                  {group.attributes.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Divider />
        </>
      )}

      {filteredContacts.sort(contactSort).map((item, index: number) => {
        const rel = item.relation;
        const unreadMessages =
          contextType && context
            ? unreadMessagesMap[
                getKey(contextType, context.entityId, rel.initiatorId, rel.receiverId)
              ]
            : unreadMessagesMap[getKey('relation', item.relation.entityId)];
        const otherUser =
          item.relation.initiatorId === userToUse.entityId
            ? item.relation.receiver
            : item.relation.initiator;
        const showUser =
          searchValue === '' ||
          getUserDisplayName(otherUser, true)
            .toLowerCase()
            .includes(searchValue.toLowerCase());

        if (!showUser) {
          return null;
        }
        return (
          <div key={index} className={classes.userCard}>
            <UserCard
              selected={isSelected(item.relation)}
              user={otherUser}
              onClick={() => onLoadRelation(otherUser, item.relation)}
              unreadMessages={unreadMessages || 0}
              archived={item.archived}
              notAccepted={item.notAccepted}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ContactList;
