import React, { FC } from 'react';
import shared from '../../shared';

const LoggedOutWrapper: FC = ({ children }) => {
  return (
    <shared.LoggedOutPageContentWrapper>
      <>
        <div>{children}</div>
      </>
    </shared.LoggedOutPageContentWrapper>
  );
};

export default LoggedOutWrapper;
