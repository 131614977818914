import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import security from '../../security';
import { useEffect } from 'react';
import { Actions } from '../../myCompass/actions';
import { CompassState } from '../../myCompass/model';
import { getCompassState } from '../../myCompass/selectors';

const useCompassUser = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const currentUserId = useSelector(security.selectors.getUserId);
  const compassState = useSelector(getCompassState);

  const isCompassUser = !!userId && currentUserId !== userId;

  useEffect(() => {
    if (compassState !== CompassState.SHOWCASE && compassState !== CompassState.MYSHOWCASE) {
      dispatch(
        Actions.setCompassState(isCompassUser ? CompassState.COMPASS : CompassState.MYCOMPASS)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompassUser, dispatch]);

  return isCompassUser;
};

export default useCompassUser;
