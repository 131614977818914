import DreamDetailPage from './scenes/DreamDetailPage';
import reducer from './reducer';
import * as selectors from './selectors';
import DreamsOverviewPage from './scenes/DreamsOverviewPage';

const myCompass = {
  DreamDetailPage,
  DreamsOverviewPage,
  reducer,
  selectors,
};

export default myCompass;
