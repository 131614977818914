import React, { useEffect } from 'react';
import {
  IconButton as MuiIconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
} from '@material-ui/core';
import ProfilePicture from '../../../../components/ProfilePicture';
import DefaultProfilePicture from '../../../../components/DefaultProfilePicture';
import { getUserDisplayName } from '../../../../helpers/getUserDisplayName';
import { GroupId, RelationContext } from '../../../../config/api/models';
import { useTranslation } from 'react-i18next';
import useUser from '../../../security/hooks/useUser';
import clsx from 'clsx';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import Tooltip from '../../../../components/Tooltip';
import { makeStyles } from '@material-ui/styles';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import useFetchGroups from '../../hooks/useFetchGroups';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1rem',
  },
  avatar: {
    '& svg': {
      width: '4.6rem',
      height: '4.6rem',
    },
    '& > *': {
      margin: 0,
    },
  },
  listItemTextPrimary: {
    fontSize: '1.4rem',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  listItemTextSecondary: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
  listItemSecondaryAction: {
    right: 8,
  },
  tooltipButton: {
    '& svg': {
      color: theme.palette.grey['400'],
    },
  },
}));

const removeGroupFromRelationConfig = ApiConfig.removeGroupFromRelation();

type Props = {
  coachee: RelationContext;
  groupId: GroupId;
};

const MyGroupCoachee = ({ groupId, coachee }: Props) => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const classes = useStyles();
  const [removeGroupFromRelationResponse, removeGroupFromRelation] = useFetch(
    removeGroupFromRelationConfig
  );
  const [, fetchGroups] = useFetchGroups();
  const user = coachee.initiatorId === currentUser.entityId ? coachee.receiver : coachee.initiator;

  const isSuccess = removeGroupFromRelationResponse && removeGroupFromRelationResponse.fulfilled;

  useEffect(() => {
    if (isSuccess) {
      fetchGroups();
    }
  }, [isSuccess, fetchGroups]);

  const removeCoacheeFromGroup = () => {
    removeGroupFromRelation(groupId, coachee.relationId);
  };

  return (
    <ListItem className={classes.root} divider>
      <ListItemAvatar className={classes.avatar}>
        {user.media && user.media.profile ? (
          <ProfilePicture profilePicture={user.media.profile} />
        ) : (
          <DefaultProfilePicture />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={getUserDisplayName(user, true)}
        secondary={user.department ? user.department.name : user.function}
        classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
      />
      <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
        <Tooltip title={t('myCoach.myGroups.removeCoacheeButton')} placement="top">
          <MuiIconButton className={clsx(classes.tooltipButton)} onClick={removeCoacheeFromGroup}>
            <SvgIcon icon={Icons.CANCEL} />
          </MuiIconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default MyGroupCoachee;
