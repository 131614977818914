import { Showcase, Maybe, ShowcaseResponse } from '../../config/api/models';
import { RootState } from '../../config/store/types';
import { RESOURCE } from './api';

export const getShowcaseResponse = () => (state: RootState): Maybe<ShowcaseResponse> => {
  return state.repository.getIn([RESOURCE.PUBLIC_SHOWCASE, 'value']) as ShowcaseResponse;
};

export const getShowcase = () => (state: RootState): Maybe<Showcase> => {
  return state.repository.getIn([
    RESOURCE.PUBLIC_SHOWCASE,
    'value',
    'data',
    'attributes',
  ]) as Showcase;
};
