import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/styles';
import Footer from '../../../components/Footer';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';

type Props = {
  children: ReactElement;
};

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  loggedOutPageContentWrapperRoot: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  innerContent: (props: StyleProps) => ({
    position: 'relative',
    maxWidth: props.isMobile ? '100%' : '120rem',
    width: '100%',
    minHeight: props.isMobile ? 'calc(100% - 12rem)' : 'calc(100% - 8rem)',
    margin: '0 auto',
    padding: props.isMobile ? '15rem 2rem 0 2rem' : '15rem 15rem 0 15rem',
  }),
}));

const LoggedOutPageContentWrapper = ({ children }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.loggedOutPageContentWrapperRoot}>
      <div className={classes.innerContent}>{children}</div>
      <Footer />
    </div>
  );
};

export default LoggedOutPageContentWrapper;
