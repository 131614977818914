import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import Html from '../../../../components/Html';

type Props = {
  onCreateFocus: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  introFocusRoot: {
    padding: '0',
    [theme.breakpoints.down('xs')]: {
      padding: '0 2rem',
    },
  },
  addFocusButton: {
    width: '100%',
    padding: '1rem',
  },
}));

const IntroFocus = ({ onCreateFocus }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.introFocusRoot}>
      <>
        <Title>{t('focus.introTitle')}</Title>
        <Html>{t('focus.intro')}</Html>
        <Button
          id="add-first-focus-button"
          onClick={onCreateFocus}
          icon={Icons.PLUS}
          className={classes.addFocusButton}
        >
          {t('focus.addFirst')}
        </Button>
      </>
    </div>
  );
};

export default IntroFocus;
