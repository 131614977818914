import React, { FC } from 'react';
import { Icons } from '../../../components/SvgIcon';
import SidebarButton from '../../../components/SidebarButton';
import { useTranslation } from 'react-i18next';
import useInspirationDialogContext from '../hooks/useInspirationDialogContext';

type Props = {
  id?: string;
  disabled?: boolean;
};

const InspirationSidebarButton: FC<Props> = ({ id, disabled = false }) => {
  const { t } = useTranslation();
  const { onToggle } = useInspirationDialogContext('InspirationSidebarButton');

  return (
    <div id={id}>
      <SidebarButton
        icon={Icons.LIGHT_BULB}
        primary={t('sidebar.inspiration')}
        onClick={onToggle}
        disabled={disabled}
      />
    </div>
  );
};

export default InspirationSidebarButton;
