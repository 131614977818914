import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { NoteRequest, User, NotesResponse, NoteId } from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import SectionTitle from '../../../components/SectionTitle';
import ModalBodyAlt from '../../../components/ModalBodyAlt';
import FormControl from '../../../components/FormControl';
import EditorAdapter from '../../../components/forms/Editor/EditorAdapter';
import FormError from '../../../components/forms/FormError';
import { makeStyles } from '@material-ui/styles';
import { Theme, useMediaQuery } from '@material-ui/core';
import themeConfig from '../../../config/theme';
import { getUserDisplayName } from '../../../helpers/getUserDisplayName';
import useUser from '../../security/hooks/useUser';
import BlurDialog from '../../shared/components/BlurDialog';

type FormValues = {
  body: string;
};

type Props = {
  saveBtnText: string;
  onToggle: () => void;
  otherUser: User;
};

const useStyles = makeStyles((theme: Theme) => ({
  notesDialog: {
    minWidth: '75rem',
    minHeight: '60rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      minHeight: 'auto',
    },
    '& .ck-editor__editable_inline': {
      minHeight: '30rem !important',
    },
  },
}));

const NoteDialog = ({ onToggle, saveBtnText, otherUser }: Props) => {
  const user = useUser();
  const classes = useStyles();
  const fullScreen = useMediaQuery(themeConfig.breakpoints.down('sm'));
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [noteResponse] = useFetch<NotesResponse>(ApiConfig.loadNote());
  const [saveNoteResponse, saveNote] = useFetch(ApiConfig.addNote());

  const getExistingNote = () => {
    if (noteResponse && noteResponse.value) {
      const existingNote = noteResponse.value.data.find(
        d => d.attributes.ownerId === user.entityId && d.attributes.coacheeId === otherUser.entityId
      );
      return existingNote ? existingNote.attributes : undefined;
    }
  };
  const note = getExistingNote();
  const [updateNoteResponse, updateNote] = useFetch(
    ApiConfig.updateNote(note ? note.entityId : ('' as NoteId))
  );

  useEffect(() => {
    if (isSubmitting) {
      if (
        (note && updateNoteResponse && updateNoteResponse.fulfilled) ||
        (!note && saveNoteResponse && saveNoteResponse.fulfilled)
      ) {
        onToggle();
        setIsSubmitting(false);
      } else if (
        (saveNoteResponse && saveNoteResponse.rejected) ||
        (updateNoteResponse && updateNoteResponse.rejected)
      ) {
        setIsSubmitting(false);
      }
    }
  }, [isSubmitting, onToggle, saveNoteResponse, updateNoteResponse, setIsSubmitting, note]);

  const actions = (submit: () => void) => (
    <>
      <Button
        type="submit"
        onClick={submit}
        icon={Icons.SAVE}
        disabled={saveNoteResponse && saveNoteResponse.pending}
      >
        {t(saveBtnText)}
      </Button>
      <Button onClick={onToggle} color="secondary" icon={Icons.CANCEL}>
        {t('forms.buttons.cancel')}
      </Button>
    </>
  );

  const initialValues: FormValues = { body: note ? note.body : '' };

  return (
    <Form
      onSubmit={(formValues: FormValues) => {
        const request: Partial<NoteRequest> = {
          body: formValues.body,
        };

        if (!note) {
          request.coacheeId = otherUser.entityId;
          request.ownerId = user.entityId;
          saveNote(request);
        } else {
          updateNote(request);
        }
        setIsSubmitting(true);
      }}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    >
      {({ form }) => (
        <BlurDialog
          open={true}
          actions={actions(form.submit)}
          onClose={onToggle}
          maxWidth="lg"
          fullScreen={fullScreen}
          classes={{ paper: classes.notesDialog }}
        >
          <SectionTitle
            label={t('myCoach.notes.dialogTitle', {
              name: getUserDisplayName(otherUser),
            })}
          />
          <ModalBodyAlt fullWidth fullHeight>
            <>
              <FormControl label={t('forms.labels.body')} id="body">
                <Field
                  name="body"
                  component={EditorAdapter}
                  placeholder={t('myCoach.notes.textPlaceholder')}
                />
              </FormControl>

              {saveNoteResponse &&
                saveNoteResponse.rejected &&
                saveNoteResponse.reason &&
                saveNoteResponse.reason.cause.errors.map((item: any, index: number) => (
                  <FormError key={index}>{item.detail}</FormError>
                ))}
            </>
          </ModalBodyAlt>
        </BlurDialog>
      )}
    </Form>
  );
};

export default NoteDialog;
