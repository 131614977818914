import React, { useEffect, useState } from 'react';
import { makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import clsx from 'clsx';
import CompassChatWrapper from './CompassChatWrapper';
import {
  ChatSocketNotification,
  CommentableType,
  CommentsResponse,
  Dream,
  Focus,
  Learn,
  LookBack,
  Milestone,
  RelationsResponse,
  Talent,
  User,
} from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { ApiConfig as SharedApiConfig } from '../../shared/api';
import { useDispatch, useSelector } from 'react-redux';
import { getMilaUserId, getNotifications } from '../../shared/selectors';
import { Actions } from '../../shared/actions';
import { Actions as myCoachActions } from '../../myCoach/actions';
import { useTranslation } from 'react-i18next';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import { getUnreadMessages } from '../../myCoach/selectors';
import Button from '../../../components/Button';
import CreateRelationDialog from '../../shared/components/CreateRelationDialog';
import { getAcceptedContactsForContext } from '../helpers/helperFunctions';
import CommentWrapper from './CommentWrapper';
import { getCompassState, getCompassUserId } from '../selectors';
import { CompassState } from '../model';
import { getRelationForNotification } from '../../shared/helpers/helperFunctions';
import { getKey } from '../../myCoach/helpers/helperFunctions';
import { useCompassUser } from '../../shared/hooks';
import security from '../../security';
import { useLocation } from 'react-router-dom';

type Props = {
  id?: string;
  context: Dream | Focus | Learn | LookBack | Talent | Milestone;
  contextType: CommentableType;
  disabled?: boolean;
  openCommentSection?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    borderTop: theme.config.defaultBorder,
  },
  tab: {
    borderRight: theme.config.defaultBorder,
  },
  tabLabel: {
    display: 'flex',
    opacity: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '4.8rem',
    flexGrow: 1,
    flexBasis: 0,
  },
  labelText: {
    margin: '0 1rem',
  },
  commentsLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  commentsIcon: {
    color: theme.palette.highlight.main,
    marginTop: '0.6rem',
  },
  tabFlexContainer: {
    '& > button:last-child, & > div:last-child': {
      borderRight: 'none',
    },
  },
  bottomBorder: {
    borderBottom: theme.config.defaultBorder,
  },
  focusTabsIndicator: {
    display: 'none',
  },
  pill: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.highlight.main,
    padding: '.2rem 1rem',
    display: 'inline-block',
    fontSize: '.95rem',
    color: theme.palette.common.white,
    height: 'fit-content',
  },
}));

enum TabValues {
  CHAT = 'chat',
  COMMENTS = 'comments',
}

const CommunicationWrapper = ({
  id,
  context,
  contextType,
  disabled,
  openCommentSection,
}: Props) => {
  const classes = useStyles();
  const notifications = useSelector(getNotifications);
  const unreadMessages = useSelector(getUnreadMessages);
  const compassState = useSelector(getCompassState);
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const currentUserId = useSelector(security.selectors.getUserId);
  const milaUserId = useSelector(getMilaUserId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState<false | string>(false);
  const [handlingNotifications, setHandlingNotifications] = useState<boolean>(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  const [commentCount, setCommentCount] = useState<number>(context.commentCount || 0);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<User | undefined>();
  const [messagesToFetch, setMessagesToFetch] = useState<ChatSocketNotification[]>([]);
  const [canComment, setCanComment] = useState<boolean>(true);

  useEffect(() => {
    if (isCompassUser && compassUserId === milaUserId && currentUserId !== milaUserId) {
      setCanComment(false);
    }
  }, [isCompassUser, compassUserId, currentUserId, milaUserId]);

  useEffect(() => {
    if (location.state) {
      setSelectedTab(false);

      switch (location.state.commentType) {
        case 'App\\Dream':
          if (contextType === 'dream' && location.state.dreamId === context.entityId) {
            setSelectedTab(TabValues.COMMENTS);
          }
          break;
        case 'App\\Focus':
          if (contextType === 'focus' && location.state.focusId === context.entityId) {
            setSelectedTab(TabValues.COMMENTS);
          }
          break;
        case 'App\\Learn':
          if (contextType === 'learn' && location.state.commentableId === context.entityId) {
            setSelectedTab(TabValues.COMMENTS);
          }
          break;
        case 'App\\Milestone':
          if (contextType === 'milestone' && location.state.commentableId === context.entityId) {
            setSelectedTab(TabValues.COMMENTS);
          }
          break;
        case 'App\\Talent':
          if (contextType === 'talent' && location.state.commentableId === context.entityId) {
            setSelectedTab(TabValues.COMMENTS);
          }
          break;
      }
    }
  }, [location, contextType, context]);

  useEffect(() => {
    if (openCommentSection) {
      setSelectedTab(TabValues.COMMENTS);
    }
  }, [openCommentSection]);

  const [relationsRequest, fetchRelations] = useFetch<RelationsResponse>(
    SharedApiConfig.relations()
  );

  const [commentRequest] = useFetch<CommentsResponse>(
    ApiConfig.loadComments(context.entityId, contextType)
  );

  const relations =
    relationsRequest && relationsRequest.value
      ? relationsRequest.value.data.map(d => d.attributes)
      : [];

  const handleTabChange = (event: React.ChangeEvent<{}>, value: string) => {
    if (value === selectedTab || value !== TabValues.CHAT) {
      setSelectedContact(undefined);
    }
    if (value === selectedTab) {
      setSelectedTab(false);
    } else {
      setSelectedTab(value);
    }
  };

  // Fetch relations for dreams and focusses
  useEffect(() => {
    if (!relationsRequest && (contextType === 'dream' || contextType === 'focus')) {
      fetchRelations();
    }
  }, [relationsRequest, fetchRelations, contextType]);

  // Update commentCount post post GET
  useEffect(() => {
    setCommentCount(context.commentCount || 0);
  }, [context.commentCount, setCommentCount, context.entityId]);

  // Update commentCount post comments GET
  useEffect(() => {
    if (
      commentRequest &&
      commentRequest.value &&
      commentRequest.value.data.length > commentCount &&
      commentRequest.value.data.length > context.commentCount
    ) {
      setCommentCount(commentRequest.value.data.length);
    }
  }, [commentRequest, commentCount, context.commentCount]);

  // Setting unread messages count for tab label
  useEffect(() => {
    if (unreadMessages) {
      let count = 0;
      Object.keys(unreadMessages).forEach(key => {
        if (key.includes(`${contextType}-${context.entityId}`)) {
          count += unreadMessages[key];
        }
      });
      setUnreadMessagesCount(count);
    }
  }, [unreadMessages, context, setUnreadMessagesCount, contextType]);

  const handleNotification = () => {
    if (notifications.length && selectedTab === TabValues.CHAT) {
      setHandlingNotifications(true);
      const handledNotifications: string[] = [];
      const messageIds: ChatSocketNotification[] = [];

      notifications.forEach(n => {
        const relation = getRelationForNotification(relations, n);
        if (relation) {
          const relationKey = getKey('relation', relation.entityId);
          const contextKey = getKey(
            n.contextType,
            n.contextId,
            relation.initiatorId,
            relation.receiverId
          );
          dispatch(myCoachActions.setUnreadMessages(relationKey, contextKey, 1));
        }
        if (selectedContact && selectedContact.entityId === n.from) {
          messageIds.push(n);
        }
        handledNotifications.push(n.id);
      });

      dispatch(Actions.removeNotifications(handledNotifications));
      setMessagesToFetch(messageIds);
      setHandlingNotifications(false);
    }
  };

  if (notifications.length && !handlingNotifications) {
    handleNotification();
  }

  const hasCoaches = (): boolean => {
    if (relations.length && (contextType === 'dream' || contextType === 'focus')) {
      const relevantRelations = getAcceptedContactsForContext(
        relations,
        context as Focus | Dream,
        contextType
      );
      return Boolean(relevantRelations.length);
    }
    return false;
  };

  // Determine whether tabs need a bottom border
  const tabWithBottomBorder = (value: string): boolean => {
    if (selectedTab) {
      return showCoachTab ? selectedTab !== value : true;
    }
    return false;
  };

  // Tab button labels
  const commentTabButton = (
    <div className={classes.commentsLabel}>
      <div className={classes.commentsIcon}>
        <SvgIcon icon={Icons.COMMENTS} />
      </div>
      <span className={classes.labelText}>{`${commentCount} ${t('comments.title')}`}</span>
      <SvgIcon icon={Icons.CHEVRON_DOWN} size={1} />
    </div>
  );

  const chatTabButton = (
    <div className={classes.tabLabel}>
      <span className={classes.pill}>{t('navigation.myCoach')}</span>
      <span className={classes.labelText}>
        {t('compassPage.messages', { count: unreadMessagesCount })}
      </span>
      <SvgIcon icon={Icons.CHEVRON_DOWN} size={1} />
    </div>
  );

  const addCoachTabButton = (
    <>
      <Button icon={Icons.COACH} onClick={() => setOpen(true)} component="div">
        {t('myCoach.invite.button')}
      </Button>
      {compassState === CompassState.MYCOMPASS && (
        <CreateRelationDialog
          contextType={contextType as 'dream' | 'focus'}
          contextId={context.entityId}
          open={open}
          onClose={() => setOpen(false)}
          dialogType="coach"
        />
      )}
    </>
  );

  const showCoachTab =
    (contextType === 'dream' || contextType === 'focus') && compassState === CompassState.MYCOMPASS;

  return (
    <div id={id}>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={handleTabChange}
        classes={{
          root: classes.tabContainer,
          indicator: classes.focusTabsIndicator,
          flexContainer: classes.tabFlexContainer,
        }}
      >
        <Tab
          value={TabValues.COMMENTS}
          label={commentTabButton}
          className={clsx(
            classes.tab,
            tabWithBottomBorder(TabValues.COMMENTS) ? classes.bottomBorder : null
          )}
        />
        {showCoachTab && (
          <Tab
            value={hasCoaches() ? TabValues.CHAT : undefined}
            label={hasCoaches() ? chatTabButton : addCoachTabButton}
            className={clsx(
              classes.tab,
              tabWithBottomBorder(TabValues.CHAT) ? classes.bottomBorder : null
            )}
          />
        )}
      </Tabs>

      {selectedTab === TabValues.CHAT && (contextType === 'dream' || contextType === 'focus') && (
        <CompassChatWrapper
          context={context as Dream | Focus}
          contextType={contextType}
          disabled={disabled}
          setSelectedContact={(contact?: User) => setSelectedContact(contact)}
          messagesToFetch={messagesToFetch}
          canComment={canComment}
        />
      )}

      {selectedTab === TabValues.COMMENTS && (
        <CommentWrapper
          contextId={context.entityId}
          contextType={contextType}
          disabled={disabled}
          onAddComment={() => setCommentCount(commentCount + 1)}
          canComment={canComment}
        />
      )}
    </div>
  );
};

export default CommunicationWrapper;
