import { Language } from '../i18n/types';
import {
  DreamId,
  InspirationContext,
  ContextType,
  RelationType,
  NoteId,
  FocusId,
  PostId,
  MilestoneId,
  CommentableIdType,
  CommentableType,
  GroupId,
  CommentId,
  RelationUsersIds,
} from './models';
import { getIdListString } from '../../helpers/getIdListString';

const baseApiPath = process.env.REACT_APP_API_ROOT;
const baseSocketPath = process.env.REACT_APP_SOCKET_ROOT;
const apiRoutes = {
  translations: (lang: Language = 'nl') => `${baseApiPath}/translations/${lang}`,
  publicClientRoutes: () => `${baseApiPath}/routes/public`,
  privateClientRoutes: () => `${baseApiPath}/routes/private`,

  login: () => `${baseApiPath}/auth/login`,
  forgotPassword: () => `${baseApiPath}/auth/recover`,

  images: () => `${baseApiPath}/images`,
  inlineImages: () => `${baseApiPath}/images/inline`,

  register: () => `${baseApiPath}/register`,

  user: (userId: string) => `${baseApiPath}/api/users/${userId}`,
  userToken: (userId: string, token: string) =>
    `${baseApiPath}/api/users/${userId}?id=${userId}&auth-token=${token}`,
  dreams: (dreamId?: DreamId | string) =>
    `${baseApiPath}/api/dreams${typeof dreamId !== 'undefined' ? `/${dreamId}` : ''}`,
  dreamCoverImage: (dreamId: string) => `${baseApiPath}/dream/${dreamId}/cover-photo`,
  inspiration: (context: InspirationContext, language: Language) =>
    `${baseApiPath}/api/reflections?filter[context]=${language}-${context}`,
  focuses: (focusId?: string) =>
    `${baseApiPath}/api/focusses${typeof focusId !== 'undefined' ? `/${focusId}` : ''}`,
  focusTabs: (dreamId: string) => `${baseApiPath}/dream-focuses/${dreamId}`,
  plan: (planId?: string) =>
    `${baseApiPath}/api/plans${typeof planId !== 'undefined' ? `/${planId}` : ''}`,
  planVisibility: (focusId: FocusId) =>
    `${baseApiPath}/api/focusses/${focusId}/update-plan-visibility`,
  learnings: (learnId?: string) =>
    `${baseApiPath}/api/learnings${typeof learnId !== 'undefined' ? `/${learnId}` : ''}`,
  lookBack: (lookBackId?: string) =>
    `${baseApiPath}/api/lookbacks${typeof lookBackId !== 'undefined' ? `/${lookBackId}` : ''}`,
  talents: (talentId?: string) =>
    `${baseApiPath}/api/talents${typeof talentId !== 'undefined' ? `/${talentId}` : ''}`,
  basicPages: (pageId?: string, language?: Language) =>
    `${baseApiPath}/api/basic_pages${
      typeof pageId !== 'undefined' ? `/${language}-${pageId}` : ''
    }`,
  files: (fileId?: string) => `${baseApiPath}/api/files${fileId ? `/${fileId}` : ''}`,
  file: (modelType: string, modelId: string) =>
    `${baseApiPath}/api/files?filter[modelType]=${modelType}&filter[modelId]=${modelId}`,
  postFiles: () => `${baseApiPath}/file-upload`,
  relations: (
    relationType?: RelationType,
    isMila?: boolean,
    relationUsersIds?: RelationUsersIds
  ) => {
    let filter = '';
    if (relationType) {
      filter += `?filter[relationType]=${relationType}`;
    }
    if (isMila) {
      filter += `${filter === '' ? '?' : '&'}mila=1`;
    }
    if (relationUsersIds) {
      filter += `${filter === '' ? '?' : '&'}
        filter[receiver_id]=${relationUsersIds.receiverId}
        &filter[initiator_id]=${relationUsersIds.initiatorId}`;
    }
    return `${baseApiPath}/api/relations${filter}`;
  },
  myCoaches: (isMila?: boolean) => {
    let filter = '';
    if (isMila) {
      filter += '?mila=1';
    }
    return `${baseApiPath}/api/relations/coaches${filter}`;
  },
  initialUnreadMessages: (isMila?: boolean) => {
    let filter = '';
    if (isMila) {
      filter += '?mila=1';
    }
    return `${baseApiPath}/api/relations/unread-messages-count${filter}`;
  },
  relationCreate: () => `${baseApiPath}/relation-create`,
  relationAccept: (relationId: string) => `${baseApiPath}/api/relations/${relationId}`,
  relation: (relationId: string) => `${baseApiPath}/api/relations/${relationId}`,
  relationDecline: (relationId: string) => `${baseApiPath}/api/relations/${relationId}`,
  relationStop: (relationId: string) => `${baseApiPath}/api/relations/${relationId}`,
  chats: (messageIds?: string[]) =>
    `${baseApiPath}/api/chats${
      messageIds && messageIds.length ? `?filter[idList]=${getIdListString(messageIds)}` : ''
    }`,
  chat: (currentUserId: string, otherUserId: string, contextType: ContextType, contextId: string) =>
    `${baseApiPath}/api/chats?filter[current]=${currentUserId}&filter[other]=${otherUserId}&filter[contextType]=${contextType}&filter[contextId]=${contextId}`,
  chatSetSeen: () => `${baseApiPath}/api/chats/mark-read`,
  updateChat: (messageId: string) => `${baseApiPath}/api/chats/${messageId}`,
  socket: () => baseSocketPath,
  notes: (coacheeId?: string) =>
    `${baseApiPath}/api/notes${coacheeId ? `?filter[coacheeId]=${coacheeId}` : ''}`,
  updateNote: (noteId: NoteId) => `${baseApiPath}/api/notes/${noteId}`,
  posts: (postId?: PostId, isMila?: boolean) =>
    `${baseApiPath}/api/posts${postId ? `/${postId}` : ''}${isMila ? '?mila=1' : ''}`,
  comments: (contextId?: CommentableIdType, contextType?: CommentableType) =>
    `${baseApiPath}/api/comments${
      contextId && contextType
        ? `?filter[commentableId]=${contextId}&filter[commentableType]=${contextType}`
        : ''
    }`,
  updateComment: (commentId: CommentId) => `${baseApiPath}/api/comments/${commentId}`,
  deleteComment: (commentId: CommentId) => `${baseApiPath}/api/comments/${commentId}`,
  milestones: (milestoneId?: MilestoneId) =>
    `${baseApiPath}/api/milestones${milestoneId ? `/${milestoneId}` : ''}`,
  showcases: (slug?: string, isMila?: boolean) =>
    `${baseApiPath}/api/showcases${slug ? `/${slug}` : ''}${isMila ? '?mila=1' : ''}`,
  showcase: (slug: string) => `${baseApiPath}/public-showcases/${slug}`,

  trayNotifications: () => `${baseApiPath}/api/trayNotifications`,
  patchTrayNotification: (notificationId: string) =>
    `${baseApiPath}/api/trayNotifications/${notificationId}`,

  groups: (groupId?: GroupId) => `${baseApiPath}/api/groups${groupId ? `/${groupId}` : ''}`,
  addGroupToRelation: () => `${baseApiPath}/add-group-to-relation`,
  removeGroupFromRelation: () => `${baseApiPath}/remove-group-from-relation`,
};

export default apiRoutes;
