import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { RelationContext, User, RelationType, RelationsResponse } from '../../../config/api/models';
import RelationRow from '../components/RelationRow';
import { createRelationTree, getKey } from '../helpers/helperFunctions';
import { useTranslation } from 'react-i18next';
import { getUserDisplayName } from '../../../helpers/getUserDisplayName';
import { useSelector } from 'react-redux';
import { getUnreadMessages } from '../selectors';
import CompassLinkButton from '../../../components/CompassLinkButton';
import { useCompassUser } from '../../shared/hooks';
import { getCompassUserId } from '../../myCompass/selectors';
import { getHelp, getMilaUserId } from '../../shared/selectors';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig as SharedApiConfig } from '../../shared/api';

type Props = {
  user: User;
  currentUser: User;
  role: RelationType;
  selectedRelation: RelationContext;
  selectedChat?: RelationContext;
  onSelectChat: (relation: RelationContext) => void;
  onStopRelation: (relation: RelationContext) => void;
};

const useStyles = makeStyles({
  coachRelationOverviewRoot: {},
  coachRelationOverviewTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '4rem',
  },
  relationRows: {
    borderRadius: 50,
  },
  compassButton: {
    display: 'flex',
    alignItems: 'center',
  },
  compassText: {
    marginRight: '1rem',
  },
});

const CoachRelationOverview = ({
  user,
  currentUser,
  role,
  selectedChat,
  selectedRelation,
  onSelectChat,
  onStopRelation,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const unreadMessagesMap: Record<string, number> = useSelector(getUnreadMessages);
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const canStop = (rel: RelationContext) => !!rel.relationId && !rel.stoppedAt && !isMila;
  const isHelpActive = useSelector(getHelp);

  const [relationsRequest, fetchRelations] = useFetch<RelationsResponse>(
    SharedApiConfig.myCoachesRelations()
  );

  useEffect(() => {
    fetchRelations({
      receiverId:
        currentUser.entityId !== selectedRelation.receiverId
          ? selectedRelation.receiverId
          : currentUser.entityId,
      initiatorId:
        currentUser.entityId !== selectedRelation.initiatorId
          ? selectedRelation.initiatorId
          : currentUser.entityId,
    });
  }, [selectedRelation, fetchRelations, currentUser.entityId]);

  return (
    <div id="coach-relation-overview" className={classes.coachRelationOverviewRoot}>
      {role === 'coachee' && (
        <div className={classes.compassButton}>
          <span className={classes.compassText}>
            {t('myCoach.toCompassPage', { name: getUserDisplayName(user) })}
          </span>
          <CompassLinkButton id="compass-link-button" userId={user.entityId} />
        </div>
      )}

      <p className={classes.coachRelationOverviewTitle}>
        {t(role === 'coach' ? 'myCoach.coacheeOf' : 'myCoach.coachFor', {
          name: getUserDisplayName(user),
        })}
      </p>

      <div className={classes.relationRows}>
        {relationsRequest &&
          !relationsRequest.pending &&
          relationsRequest.value &&
          createRelationTree(relationsRequest.value.data.map(d => d.attributes)).map(
            (rel, index) => {
              if (isHelpActive && index === 0) {
                onSelectChat(rel);
              }

              if (rel.context) {
                const unreadMessages =
                  unreadMessagesMap[
                    getKey(rel.contextType, rel.context.entityId, rel.initiatorId, rel.receiverId)
                  ];
                return (
                  <RelationRow
                    key={`entity${index}`}
                    entity={rel.context}
                    isSelected={
                      !!selectedChat &&
                      !!selectedChat.context &&
                      rel.context.entityId === selectedChat.context.entityId &&
                      rel.contextType === selectedChat.contextType
                    }
                    unreadMessages={unreadMessages || 0}
                    type={rel.contextType}
                    canStop={canStop(rel)}
                    onSelect={() => onSelectChat(rel)}
                    onCancel={() => onStopRelation(rel)}
                    archived={!!rel.stoppedAt}
                    notAccepted={
                      rel.contextType === 'dream'
                        ? canStop(rel) && !rel.acceptedAt
                        : !rel.acceptedAt
                    }
                  />
                );
              }
              return null;
            }
          )}
      </div>
    </div>
  );
};

export default CoachRelationOverview;
