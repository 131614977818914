import { Editor, IUploadAdapter, Loader } from '@ckeditor/ckeditor5-build-classic-with-video';
import { FILE_REJECTED, FILE_UPLOADED, FILE_UPLOADING } from './events';
import ImageUploader from './ImageUploader';
import VideoUploader from './VideoUploader';

class FileUploadAdapter implements IUploadAdapter {
  private loader: Loader;
  private editor: Editor;

  constructor(loader: Loader, editor: Editor) {
    this.loader = loader;
    this.editor = editor;
  }

  upload = () => {
    return new Promise((resolve, reject) => {
      this.loader.file.then((file: any) => {
        if (file.type.includes('image')) {
          if (file.size > 31457280) {
            this.editor.fire(FILE_REJECTED);
            reject();
          } else {
            const imageUploader = new ImageUploader(file, resolve, reject);
            imageUploader.upload(this.onUploaded);
          }
        } else {
          const videoUploader = new VideoUploader(file, resolve, reject, this.editor);
          videoUploader.upload(this.onUploaded);
        }
        this.editor.fire(FILE_UPLOADING);
      });
    });
  };

  onUploaded = () => {
    this.editor.fire(FILE_UPLOADED);
  };

  abort() {
    // throw new Error('Method not implemented.');
  }
}

export default FileUploadAdapter;
