import React, { useEffect, useState, useCallback } from 'react';
import { ChatMessage as ChatMessageType, ChatResponse, User } from '../../config/api/models';
import { makeStyles } from '@material-ui/styles';
import ChatMessage from './ChatMessage';
import ChatMessageWrapper from './ChatMessageWrapper';
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';
import messageSort from '../../modules/myCoach/helpers/messageSort';
import { Box, Snackbar, Theme, useMediaQuery } from '@material-ui/core';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import { Icons } from '../SvgIcon';
import FormError from '../forms/FormError';
import useFetch from '../../helpers/useFetch';
import { ApiConfig } from '../../modules/shared/api';
import BlurDialog from '../../modules/shared/components/BlurDialog';
import theme from '../../config/theme';
import TextField from '../TextField';

type Props = {
  messages: ChatMessageType[];
  loading?: boolean;
  currentUser: User;
  setMessages: (value: React.SetStateAction<ChatMessageType[]>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  chatMessageFeedRoot: {
    height: '100%',
    maxHeight: '45rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  messageHistory: {
    margin: '1rem',
    height: '100%',
    overflow: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollBehavior: 'smooth',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  loadingText: {
    margin: 0,
  },
  spinner: {
    height: '6rem',
  },
  scrollBlur: {
    height: '5rem',
    width: '100%',
    top: 0,
    position: 'absolute',
    zIndex: 20,
    background:
      'linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      margin: '0.5rem',
    },
  },
  editDialog: {
    '& .MuiPaper-root': {
      width: '100rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
}));

const groupMessages = (messages: ChatMessageType[]) => {
  const result: ChatMessageType[][] = [];
  let messageGroup: ChatMessageType[] = [];
  messages.forEach(message => {
    if (messageGroup.length && message.senderId === messageGroup[0].senderId) {
      messageGroup.push(message);
    } else {
      if (messageGroup.length) {
        result.push(messageGroup);
      }
      messageGroup = [message];
    }
  });

  if (messageGroup.length) {
    result.push(messageGroup);
  }
  return result;
};

const ChatMessageFeed = React.forwardRef<HTMLDivElement, Props>(
  ({ messages, loading, currentUser, setMessages }: Props, ref) => {
    const [messageToEdit, setMessageToEdit] = useState<ChatMessageType | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [prevMessageToEdit, setPrevMessageToEdit] = useState<ChatMessageType | null>(null);
    const [editMessageRequest, editMessage] = useFetch<ChatResponse>(ApiConfig.editMessage());
    const [deleteMessageRequest, deleteMessage] = useFetch<ChatResponse>(ApiConfig.deleteMessage());
    const [messageToDelete, setMessageToDelete] = useState<ChatMessageType | null>(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();
    const { t } = useTranslation();

    // replace new message with the returned PATCH value
    useEffect(() => {
      if (editMessageRequest && editMessageRequest.fulfilled && editMessageRequest.value) {
        const edittedMessage = editMessageRequest.value.data.attributes;
        setMessages(msgs =>
          msgs.map(msg => (msg.entityId === edittedMessage.entityId ? edittedMessage : msg))
        );
        setMessageToEdit(null);
      }
      if (editMessageRequest && editMessageRequest.rejected) {
        setSnackbarOpen(true);
        //Put prev message back
        setMessages(msgs =>
          msgs.map(msg =>
            prevMessageToEdit != null && msg.entityId === prevMessageToEdit.entityId
              ? prevMessageToEdit
              : msg
          )
        );
        setMessageToEdit(null);
        setPrevMessageToEdit(null);
      }
    }, [editMessageRequest, prevMessageToEdit, setMessages]);

    // remove message with the returned value
    useEffect(() => {
      setMessageToDelete(null);
    }, [deleteMessageRequest]);

    const groupedMessages = groupMessages([...messages].sort(messageSort));

    const saveEditedMessage = useCallback(() => {
      if (messageToEdit == null) return;
      setPrevMessageToEdit(messages.find(msg => msg.entityId === messageToEdit.entityId) || null);
      setMessages(msgs =>
        msgs.map(msg => (msg.entityId === messageToEdit.entityId ? messageToEdit : msg))
      );
      if (messageToEdit) {
        editMessage(messageToEdit);
      }
      setMessageToEdit(null);
    }, [messageToEdit, editMessage, setMessages, messages]);

    const onClickEditMessage = useCallback(
      (message: ChatMessageType) => {
        if (message.entityId == null) return; //This is the case when a comment was added but did not receive an id from the BE yet.
        if (messageToEdit == null) {
          //Not null means a previous comment update is still going on.
          setMessageToEdit(message);
        }
      },
      [messageToEdit]
    );

    const onClickDeleteMessage = (message: ChatMessageType) => {
      if (message.entityId == null) return; //This is the case when a message was added but did not receive an id from the BE yet.
      setMessageToDelete(message);
    };

    const messageToEditActionButtons = (
      <ButtonGroup>
        <Button
          className={classes.button}
          type="button"
          onClick={saveEditedMessage}
          icon={Icons.SAVE}
        >
          {t('forms.buttons.save')}
        </Button>
        <Button
          className={classes.button}
          type="button"
          onClick={() => setMessageToEdit(null)}
          color="secondary"
          icon={Icons.CANCEL}
        >
          {t('forms.buttons.cancel')}
        </Button>
      </ButtonGroup>
    );

    const messageToDeleteActionButtons = (
      <ButtonGroup>
        <Button
          className={classes.button}
          type="button"
          onClick={() => deleteMessage(messageToDelete)}
          icon={Icons.SAVE}
        >
          {t('forms.buttons.delete')}
        </Button>
        <Button
          className={classes.button}
          type="button"
          onClick={() => setMessageToDelete(null)}
          color="secondary"
          icon={Icons.CANCEL}
        >
          {t('forms.buttons.cancel')}
        </Button>
      </ButtonGroup>
    );

    return (
      <div className={classes.chatMessageFeedRoot}>
        <div className={classes.scrollBlur} />
        <div className={classes.messageHistory} ref={ref}>
          {loading ? (
            <div className={classes.loading}>
              <Spinner alignment="vertical" message={t('myCoach.conversations.fetching')} />
            </div>
          ) : (
            <>
              {groupedMessages.map((group, index) => {
                const isOwn = group[0].sender.email === currentUser.email;
                return (
                  <ChatMessageWrapper
                    avatar={group[0].sender.media.profile}
                    isOwn={isOwn}
                    key={index}
                  >
                    {group.map((message, i) => (
                      <ChatMessage
                        key={message.entityId || `message-${index}-${i}`}
                        authorName={message.sender.firstName}
                        messageText={message.body}
                        isOwn={isOwn}
                        failed={message.failed}
                        withoutAuthorInfo={Boolean(i)}
                        createdAt={message.createdAt}
                        updatedAt={message.updatedAt}
                        deletedAt={message.deletedAt}
                        onClickEditMessage={onClickEditMessage}
                        onClickDeleteMessage={onClickDeleteMessage}
                        message={message}
                      />
                    ))}
                  </ChatMessageWrapper>
                );
              })}
            </>
          )}
        </div>
        {!!messageToEdit && (
          <BlurDialog
            open={!!messageToEdit}
            title={t('chats.editTitle')}
            onClose={() => setMessageToEdit(null)}
            actions={messageToEditActionButtons}
            fullScreen={isMobile}
            className={classes.editDialog}
          >
            <TextField
              value={messageToEdit.body}
              onChange={event =>
                setMessageToEdit(message =>
                  message != null
                    ? {
                        ...message,
                        body: event.target.value,
                      }
                    : null
                )
              }
              autoFocus
            />
          </BlurDialog>
        )}
        {!!messageToDelete && (
          <BlurDialog
            open={!!messageToDelete}
            title={t('chat.deleteTitle')}
            onClose={() => setMessageToDelete(null)}
            actions={messageToDeleteActionButtons}
            fullScreen={isMobile}
          >
            {t('chat.deleteBody')}
          </BlurDialog>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        >
          <Box pb={5}>
            <FormError>{t('forms.feedback.chatMessageNotUpdated')}</FormError>
          </Box>
        </Snackbar>
      </div>
    );
  }
);

export default ChatMessageFeed;
