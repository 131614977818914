import React, { useEffect } from 'react';
import profile from '../../profile';
import Settings from '../containers/Settings';
import { actions } from 'react-redux-fetch';
import { Actions } from '../../myCompass/actions';
import { CompassState } from '../../myCompass/model';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCompassUser } from '../../shared/hooks';
import { getCompassUserId } from '../../myCompass/selectors';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig } from '../../myCompass/api';

const { PageContentWrapperWithStickyUserMenu } = profile;

const SettingsPage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const [, getCompassUser] = useFetch<UserResponse>(ApiConfig.loadCompassUser(userId));

  useEffect(() => {
    if (isCompassUser && !compassUserId) {
      getCompassUser();
    }
    if (!isCompassUser && compassUserId) {
      dispatch(actions.clear('compassUser'));
    }
    dispatch(
      Actions.setCompassState(isCompassUser ? CompassState.COMPASS : CompassState.MYCOMPASS)
    );
  }, [isCompassUser, getCompassUser, compassUserId, dispatch]);

  return (
    <PageContentWrapperWithStickyUserMenu profilePictureEditable>
      <Settings />
    </PageContentWrapperWithStickyUserMenu>
  );
};

export default SettingsPage;
