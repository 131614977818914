import React, { useMemo } from 'react';
import Section from '../../../components/Section';
import Paragraph from '../../../components/Paragraph';
import LinkTable from '../components/LinkTable';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { useSelector } from 'react-redux';
import { getMilaUserId } from '../../shared/selectors';
import { useCompassUser } from '../../shared/hooks';
import { getCompassUserId } from '../../myCompass/selectors';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  wrapper: (props: StyleProps) => ({
    paddingTop: props.isMobile ? '0' : '2rem',
  }),
}));

const MyLinks = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const [showCasesResponse] = useFetch(ApiConfig.loadShowcases());
  const [milaShowCasesResponse] = useFetch(ApiConfig.loadMilaShowcases());

  const showcaseList = useMemo(() => {
    return isMila
      ? milaShowCasesResponse && milaShowCasesResponse.value
        ? milaShowCasesResponse.value.data.map((d: any) => d.attributes)
        : []
      : showCasesResponse && showCasesResponse.value
      ? showCasesResponse.value.data.map((d: any) => d.attributes)
      : [];
  }, [isMila, showCasesResponse, milaShowCasesResponse]);

  return showcaseList ? (
    <div className={classes.wrapper}>
      <Section title={t('showcase.myLinks.title')} id="myLinks">
        <Paragraph>{t('showcase.myLinks.body')}</Paragraph>
        {showcaseList.length > 0 && <LinkTable showcaseList={showcaseList} />}
      </Section>
    </div>
  ) : null;
};

export default MyLinks;
