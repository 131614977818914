import React from 'react';
import { makeStyles } from '@material-ui/styles';

type Props = {
  children?: any;
};

const useStyles = makeStyles(() => ({
  defaultProfilePictureRoot: {
    position: 'relative',
    display: 'block',
    width: '100%',
    marginBottom: '1.5rem',
  },
}));

const DefaultProfilePicture = ({ children = null }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.defaultProfilePictureRoot}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
        <path
          fill="#adadad"
          d="M300 150c0 82.8-67.2 150-150 150S0 232.8 0 150 67.2 0 150 0s150 67.2 150 150"
        />
        <path
          fill="#fff"
          d="M264.9 243.2c-27.5 30.7-68.8 49.2-114.9 49.2-45.7 0-86.6-18.1-114.1-48.3 3.8-3.8 8.4-6.6 14-7.9 0 0 35.2-3.8 53.3-15.2h18.5c-2.5 1.4-5.1 2.6-7.6 3.4 0 0 16.9 18.9 35.8 19.1 0 0 1.2.2 5.5 0 20.2-.8 33.7-19.1 33.7-19.1-2.5-.8-5.2-2-7.6-3.4h16.9c18.1 11.4 53.3 15.2 53.3 15.2 5.2 1.2 9.6 3.7 13.2 7zm-92.6-152s-21.6 30.4-56.1 31.8c0 0-15.2 16.6-10.1 29 0 0-9.2-4.1-10.6-12 0 0-3.9-6.4-3.2-8.3 0 0-4.8 5.7-14.5 6.6-9.7.9-7.2 11.2-7.2 11.2s-10.9-13.8 6.2-26.4c0 0-20.5 4.3-22.4-18.6 0 0 4.8 15 20.7 11.4 0 0-9.3-25.5 12.1-35.4 0 0-5.2-24.7 21.7-28.6 0 0 56.4-45.1 76.9 3 0 0 44.6 9 39.8 46 0 0 .7-7.4-4.4-11.5-5.1-4.1-.9-.2-.9-.2s-.7 9.7 7.1 17c7.8 7.4 9 25.1 6.5 32.7 0 0 .5-4.4-4.1-6.9 0 0-2.8 10.4 6.2 13.8 0 0-9.9 2.8-17-9 0 0-4.4 4.1-10.6 3.9 0 0-4.8 10.6.2 15 0 0-7.6-4.8-7.8-17 0 0-5.3-6-6.2-15.4s-7.1-11.7-7.1-11.7-12.4-6.8-15.2-20.4"
        />
        <path
          fill="#4e4e4e"
          d="M150.4 169.5c-5.6 0-10.2-3.6-10.2-8 0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3c0 3 3.4 5.4 7.6 5.4s7.6-2.4 7.6-5.4c0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3c0 4.5-4.6 8-10.2 8zm-30.6 17.6s28.9 15.7 62.8-.6c0 0-27.9 23.4-62.8.6m33.9-49.2c-3.7 3.7-8.6.1-8.8-.1-.5-.4-1.1-.3-1.5.2s-.3 1.1.2 1.5c1.3 1 3.7 2.2 6.2 2.2 1.8 0 3.7-.6 5.4-2.3.4-.4.4-1.1 0-1.5-.4-.4-1.1-.4-1.5 0zm-36.4-15c1.1 1.9 3.5 3.2 6.2 3.2 3.7 0 6.7-2.4 6.8-5.4-4.1 1.1-8.4 1.9-13 2.2zm60.8-8.2c-3.8 0-6.9 2.5-6.9 5.7 0 3.1 3.1 5.7 6.9 5.7 3.8 0 6.9-2.5 6.9-5.7 0-3.1-3.1-5.7-6.9-5.7zm20.1 104.9c-1.3-.2-11.8-5.7-11.8-5.7-9.5 16.2-34.1 38.2-34.8 38.8-4.1-1.1-24.4-25.9-34.7-38.8L91 224.4l2.5 4.4c4.8 9.8 35.6 50.5 35.6 50.5 13.2-12.3 21.7-25.2 22.5-26.5 1.5 8.5 24.5 28.1 24.4 28.1 15.7-11.8 34.9-56.5 34.9-56.5-3.8-1.4-8.1-3.9-12.7-4.8z"
        />
      </svg>
      {children}
    </div>
  );
};

export default DefaultProfilePicture;
