import { useEffect } from 'react';
import moment from 'moment';
import useFetch from '../../../helpers/useFetch';
import { RelationsResponse } from '../../../config/api/models';
import { groupRelationsByRelationType } from '../helpers/helperFunctions';
import { ApiConfig as SharedApiConfig } from '../../shared/api';
import useUser from '../../security/hooks/useUser';

const relationsApiConfig = SharedApiConfig.relations();
let prevCacheKey = '';

// 10 => Invalidate cache after 10 seconds
const getCacheKey = (cacheTime = 10) => {
  const start = moment();
  const remainder = cacheTime - (start.second() % cacheTime);
  return moment(start)
    .add(remainder, 'seconds')
    .format('YYYYMMDDHHmmss');
};

const useRelations = () => {
  const cacheKey = getCacheKey();
  const currentUser = useUser();
  const [relationsRequest, fetchRelations] = useFetch<RelationsResponse>(relationsApiConfig);
  const { coaches, coachees, invitations } =
    relationsRequest && relationsRequest.value
      ? groupRelationsByRelationType(
          relationsRequest.value.data.map(r => r.attributes),
          currentUser
        )
      : { coaches: null, coachees: null, invitations: null };

  useEffect(() => {
    if (cacheKey !== prevCacheKey) {
      fetchRelations();
      prevCacheKey = cacheKey;
    }
  }, [currentUser, cacheKey, fetchRelations]);

  return { coaches, coachees, invitations };
};

export default useRelations;
