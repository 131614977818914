import React, { useEffect } from 'react';
import TabPanel from '../../../../components/TabPanel';
import { List, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CreateOrUpdateGroupButton from '../../components/myGroups/CreateOrUpdateGroupButton';
import MyGroupsGroupListItem from '../../components/myGroups/MyGroupsGroupListItem';
import useFetchGroups from '../../hooks/useFetchGroups';
import { GroupsResponse } from '../../../../config/api/models';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2rem 1.5rem 1.5rem',
    borderBottom: `2px solid ${theme.palette.myCompass.red}`,
    '& p': {
      fontWeight: 700,
    },
  },
  noGroupsMessage: {
    padding: '1.5rem',
  },
  panelScroll: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40vh',
      overflow: 'auto',
    },
  },
}));

type Props = {
  onSelectGroup: (group: GroupsResponse['data'][number]) => void;
  selectedGroup?: GroupsResponse['data'][number];
};
const MyGroupsGroupList = ({ onSelectGroup, selectedGroup }: Props) => {
  const { t } = useTranslation();
  const [groupsFetch, fetchGroups] = useFetchGroups();
  useEffect(fetchGroups, [fetchGroups]);
  const classes = useStyles();

  const groups = groupsFetch && groupsFetch.value && groupsFetch.value.data;

  useEffect(() => {
    // On first render:
    const noGroupSelectedYet = !selectedGroup && groups && groups.length > 0;
    // After updating / removing a group:
    const foundGroup =
      selectedGroup && groups && groups.find(group => group.id === selectedGroup.id);
    const differentGroupSameId = selectedGroup && (!foundGroup || foundGroup !== selectedGroup);

    if (groups && (noGroupSelectedYet || differentGroupSameId)) {
      onSelectGroup(foundGroup || groups[0]);
    }
  }, [selectedGroup, groups, onSelectGroup]);

  if (!groups) {
    return null;
  }

  return (
    <div>
      <div className={classes.heading}>
        <Typography variant="body2">{t('myCoach.myGroups.groups')}</Typography>
        <CreateOrUpdateGroupButton showCreateIcon />
      </div>
      <TabPanel value="groups" index="groups">
        <div className={classes.panelScroll}>
          {groups.length === 0 ? (
            <Typography variant="body2" className={classes.noGroupsMessage}>
              {t('myCoach.myGroups.noGroups')}
            </Typography>
          ) : (
            <List disablePadding>
              {groups.map(group => (
                <MyGroupsGroupListItem
                  group={group}
                  key={group.id}
                  isSelected={selectedGroup ? group.id === selectedGroup.id : false}
                  onClick={() => onSelectGroup(group)}
                />
              ))}
            </List>
          )}
        </div>
      </TabPanel>
    </div>
  );
};

export default MyGroupsGroupList;
