import React from 'react';
import { PeerRelation } from '../config/api/models';
import PeerCard from './PeerCard';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import AddPeerCard from './AddPeerCard';
import { useCompassUser } from '../modules/shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../modules/myCompass/selectors';
import { getMilaUserId } from '../modules/shared/selectors';
import useUser from '../modules/security/hooks/useUser';

type Props = {
  peerRelations: PeerRelation[];
  editable?: boolean;
  onRemove: (relation: PeerRelation) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
    },
  },
}));

const PeerList = ({ peerRelations, editable, onRemove }: Props) => {
  const classes = useStyles();
  const currentUser = useUser();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;

  return (
    <div className={classes.root}>
      {editable && (!isCompassUser || isMila) ? <AddPeerCard disabled={isMila} /> : null}
      {peerRelations.map((rel, index: number) => (
        <PeerCard
          key={index}
          showCompass={!isCompassUser || isMila}
          peer={
            rel.initiatorId === currentUser.entityId || rel.initiatorId === compassUserId
              ? rel.receiver
              : rel.initiator
          }
          notAccepted={!rel.acceptedAt}
          onRemove={() => onRemove(rel)}
        />
      ))}
    </div>
  );
};

export default PeerList;
