import { ReactElement, useEffect } from 'react';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { UserResponse } from '../../../config/api/models';
import { useSelector } from 'react-redux';
import { getUserId } from '../selectors';

type Props = {
  children: ReactElement;
  loading: ReactElement | null;
};

const UserProvider = ({ children, loading }: Props) => {
  const userId = useSelector(getUserId);
  const [userResponse, fetchUser] = useFetch<UserResponse>(ApiConfig.getUser(userId));

  useEffect(() => {
    if (!userResponse) {
      fetchUser();
    }
  }, [userResponse, fetchUser]);

  return userResponse && userResponse.value ? children : loading;
};

export default UserProvider;
