import React, { useEffect } from 'react';
import Section from '../../../components/Section';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../../shared/api';
import { PeerRelation, PeerRelationsResponse } from '../../../config/api/models';
import PeerInvitationList from '../components/PeerInvitationList';
import { useTranslation } from 'react-i18next';
import PeerList from '../../../components/PeerList';
import moment from 'moment';
import { useCompassUser } from '../../shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../../myCompass/selectors';
import { getMilaUserId } from '../../shared/selectors';
import useUser from '../../security/hooks/useUser';

const MyPeers = () => {
  const [, stopRelation] = useFetch(ApiConfig.relationStop('peer'));
  const [, cancelInvite] = useFetch(ApiConfig.cancelInvitation('peer'));
  const { t } = useTranslation();
  const currentUser = useUser();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const [relationsRequest, fetchRelations] = useFetch<PeerRelationsResponse>(
    ApiConfig.relations('peer', isMila)
  );

  useEffect(() => {
    fetchRelations();
  }, [fetchRelations, isMila]);

  const peerRelations: PeerRelation[] =
    relationsRequest && relationsRequest.value
      ? relationsRequest.value.data.map(d => d.attributes)
      : [];

  const invitations = peerRelations.filter(
    rel => !rel.acceptedAt && !rel.declinedAt && rel.initiatorId !== currentUser.entityId
  );

  const peers = peerRelations.filter(
    rel =>
      !rel.stoppedAt &&
      !rel.declinedAt &&
      (rel.acceptedAt ? true : rel.initiatorId === currentUser.entityId)
  );

  const handleRemoveRelation = (relation: PeerRelation) => {
    if (relation.acceptedAt) {
      stopRelation(relation.entityId, {
        stoppedAt: moment()
          .toISOString()
          .split('.')[0],
      });
    } else {
      cancelInvite(relation.entityId);
    }
  };

  return (
    <>
      {invitations.length ? (
        <Section title={t('peers.invitationsTitle')} id="invitations">
          <PeerInvitationList invitations={invitations} />
        </Section>
      ) : null}
      <Section title={t('peers.title')} id="Peers">
        <PeerList
          peerRelations={peers}
          editable={!currentUser.isExtern}
          onRemove={handleRemoveRelation}
        />
      </Section>
    </>
  );
};

export default MyPeers;
