import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from './SvgIcon';

export type MessageProps = {
  type?: MessageType;
  children: React.ReactNode;
};

const getColors = (type: MessageType, theme: Theme) => {
  switch (type) {
    case 'info':
      return {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      };
    case 'error':
      return {
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
      };
    case 'success':
      return {
        backgroundColor: theme.palette.tertiary.light,
        borderColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.main,
      };
    default:
      return {
        backgroundColor: theme.palette.highlight.light,
        borderColor: theme.palette.highlight.main,
        color: theme.palette.highlight.dark,
      };
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  messageRoot: (props: MessageProps) => ({
    position: 'relative',
    borderRadius: '.3rem',
    padding: '1rem 1rem 1rem 3.25rem',
    border: '.1rem solid',
    ...getColors(props.type, theme),
  }),
  icon: (props: MessageProps) => ({
    position: 'absolute',
    top: '1.25rem',
    left: '1rem',
    ...getColors(props.type, theme),
  }),
}));

type MessageType = 'warning' | 'info' | 'error' | 'success' | undefined;

const MessageIcon = {
  warning: <SvgIcon icon={Icons.INFO} />,
  info: <SvgIcon icon={Icons.INFO} />,
  error: <SvgIcon icon={Icons.ERROR} />,
  success: <SvgIcon icon={Icons.CHECK_CIRCLE} />,
};

const Message = (props: MessageProps) => {
  const { type, children } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.messageRoot}>
      <div className={classes.icon}>{MessageIcon[type || 'warning']}</div>
      {children}
    </div>
  );
};

Message.defaultProps = {
  type: 'warning',
};

export default Message;
