import { FETCH, FetchAction } from 'react-redux-fetch';
import difference from 'lodash/difference';
import { FocusTabs } from '../../config/api/models';
import { NEW_FOCUS_ID, State, CompassState } from './model';
import * as actionTypes from './actionTypes';
import { ActionsType } from './actions';
import { AnyAction } from 'redux';

type Reducer = (state: State | undefined, action: ActionsType | FetchAction) => State;

const initialState: State = {
  compassState: CompassState.MYCOMPASS,
  initialFocusCreated: false,
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FOCUS_SELECTED:
      return {
        ...state,
        selectedFocusId: (action as AnyAction).payload.focusId,
      };
    case actionTypes.COMPASS_STATE:
      return {
        ...state,
        compassState: (action as AnyAction).payload.compassState,
      };
    case actionTypes.ENABLE_INITIAL_FOCUS_CREATED:
      return {
        ...state,
        initialFocusCreated: true,
      };
    case actionTypes.DISABLE_INITIAL_FOCUS_CREATED:
      return {
        ...state,
        initialFocusCreated: false,
      };
    case FETCH.for('get').FULFILL:
      if ((action as FetchAction).resource.name.includes('tabs-for-dream')) {
        const focusTabsResponse = (action as FetchAction<FocusTabs>).value;

        if (!focusTabsResponse || focusTabsResponse.length === 0) {
          return state;
        }

        const noFocusSelected = typeof state.selectedFocusId === 'undefined';
        const newFocusSelected = state.selectedFocusId === NEW_FOCUS_ID;
        const focusIds = focusTabsResponse.map(focusTab => focusTab.id);
        const newTabIds = difference(focusIds, state.focusIds || []);

        return {
          ...state,
          focusIds,
          selectedFocusId: noFocusSelected
            ? focusTabsResponse[0].id
            : newFocusSelected && newTabIds.length === 1
            ? newTabIds[0]
            : state.selectedFocusId,
        };
      }
      return state;
  }
  return state;
};

export default reducer;
