import FileUploader from './FileUploader';
import * as tus from 'tus-js-client';
import { Editor } from '@ckeditor/ckeditor5-build-classic-with-video';
import { UPDATE_PROGRESS_VIDEO } from './events';

class VideoUploader extends FileUploader {
  private editor: Editor;
  private videoId: unknown = '';
  private networkErrorOccured: boolean = false;

  constructor(
    file: any,
    resolve: (value: unknown) => void,
    reject: (value: unknown) => void,
    editor: Editor
  ) {
    super(file, resolve, reject);
    this.editor = editor;
  }

  upload(onUploaded: () => void) {
    const upload = new tus.Upload(this.file, {
      endpoint: `https://api.cloudflare.com/client/v4/accounts/${process.env.REACT_APP_CLOUDFLARE_ACCOUNT_ID}/stream`,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: this.file.name,
        filetype: this.file.type,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_API_TOKEN}`,
      },
      onError: function(error) {
        console.log('Failed because: ' + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = parseInt(((bytesUploaded / bytesTotal) * 100).toFixed(0));
        this.editor.fire(UPDATE_PROGRESS_VIDEO, {
          id: this.file.name,
          progress: percentage,
          status: 'uploading',
        });
      },
      onSuccess: async () => {
        this.editor.fire(UPDATE_PROGRESS_VIDEO, {
          id: this.file.name,
          status: 'processing',
        });
        await this.waitForVideoProcessing(this.videoId);
        this.editor.fire(UPDATE_PROGRESS_VIDEO, {
          id: this.file.name,
          status: 'processed',
        });
        if (!this.networkErrorOccured) {
          this.resolve({
            default: `https://iframe.videodelivery.net/${this.videoId}`,
          });
          onUploaded();
        }
      },
      onAfterResponse: (req, res) => {
        new Promise(resolve => {
          var mediaIdHeader = res.getHeader('stream-media-id');
          if (mediaIdHeader) {
            resolve(mediaIdHeader);
          }
        })
          .then(videoId => {
            this.videoId = videoId;
          })
          .catch(e => this.reject(e));
      },
    });
    upload.start();
  }

  async waitForVideoProcessing(videoId: unknown) {
    let isProcessed = false;
    while (!isProcessed && !this.networkErrorOccured) {
      await this.stallLoading();
      try {
        let result = await fetch(`https://videodelivery.net/${videoId}/manifest/video.mpd`, {
          method: 'HEAD',
        });
        if (result.status !== 404) {
          isProcessed = true;
        }
      } catch (e) {
        console.log(e);
        this.reject(e);
        this.networkErrorOccured = true;
      }
    }
  }

  stallLoading() {
    return new Promise(resolve => setTimeout(resolve, 8000));
  }
}
export default VideoUploader;
