import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
const spinner = require('../assets/spinner_dark.gif');

type Props = {
  message?: string;
  alignment?: 'horizontal' | 'vertical';
};

const useStyles = makeStyles(() => ({
  spinnerRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  verticalAlign: {
    flexDirection: 'column-reverse',
  },
  spinner: {
    height: '6rem',
  },
}));

const Spinner = ({ message, alignment }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.spinnerRoot, alignment === 'vertical' ? classes.verticalAlign : '')}
    >
      <img src={spinner} className={classes.spinner} alt="" />
      {message && <div>{message}</div>}
    </div>
  );
};

export default Spinner;
