import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import SectionTitle from '../../../../components/SectionTitle';
import Paragraph from '../../../../components/Paragraph';
import ModalBodyAlt from '../../../../components/ModalBodyAlt';
import shared from '../../../shared';
import TextFieldAdapter from '../../../../components/forms/TextFieldAdapter';
import FormControl from '../../../../components/FormControl';
import DateRangeAdapter from '../../../../components/forms/Dates/DateRangeAdapter';
import { Moment } from 'moment';
import mapper from '../../../../config/api/mapper';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getSelectedFocusId } from '../../selectors';
import { FocusId, TaskId, UserId } from '../../../../config/api/models';
import MaskedDateInput from '../../../../components/forms/MaskedDateInput';
import FormError from '../../../../components/forms/FormError';
import EditorAdapter from '../../../../components/forms/Editor/EditorAdapter';
import theme from '../../../../config/theme';

const BlurDialog = shared.BlurDialog;

type InitialValues = {
  title: string;
  body?: string;
  date?: {
    start?: Moment;
    end?: Moment;
  };
  coachId: UserId | 'noCoach';
};

type FormValues = InitialValues & {
  focusId: FocusId;
};

type Props = {
  titleText: string;
  saveBtnText: string;
  onToggle: () => void;
  taskId?: TaskId;
  initialValues?: InitialValues;
};

enum FORMS {
  MAIN,
  DATE,
}

const TaskDialogForm = ({ onToggle, taskId, initialValues, titleText, saveBtnText }: Props) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showForm, setShowForm] = useState(FORMS.MAIN);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const focusId = useSelector(getSelectedFocusId);
  const { t } = useTranslation();
  const [savePlanResponse, savePlan] = useFetch(
    taskId ? ApiConfig.updatePlan(taskId) : ApiConfig.addPlan()
  );

  useEffect(() => {
    if (isSubmitting && savePlanResponse && savePlanResponse.fulfilled) {
      onToggle();
      setIsSubmitting(false);
    } else if (isSubmitting && savePlanResponse && savePlanResponse.rejected) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, onToggle, savePlanResponse, savePlan]);

  if (!focusId) {
    return null;
  }

  const actions = (submit: () => void) => (
    <>
      <Button
        type="submit"
        onClick={submit}
        icon={Icons.SAVE}
        disabled={savePlanResponse && savePlanResponse.pending}
      >
        {t(saveBtnText)}
      </Button>
      <Button onClick={onToggle} color="secondary" icon={Icons.CANCEL}>
        {t('forms.buttons.cancel')}
      </Button>
    </>
  );

  return (
    <Form
      onSubmit={(formValues: FormValues) => {
        savePlan(mapper.toPlanDTO(formValues));
        setIsSubmitting(true);
      }}
      initialValues={{
        title: '',
        focusId,
        coachId: 'noCoach',
        ...initialValues,
      }}
      keepDirtyOnReinitialize
    >
      {({ form }) => (
        <BlurDialog
          open={true}
          actions={actions(form.submit)}
          onClose={onToggle}
          maxWidth="md"
          fullScreen={fullScreen}
        >
          <SectionTitle label={t(titleText)} />
          <Paragraph>{t('plan.addMessage')}</Paragraph>
          <ModalBodyAlt fullWidth>
            <>
              {showForm === FORMS.MAIN && (
                <>
                  <FormControl label={t('forms.labels.planTitle')} id="title">
                    <Field
                      name="title"
                      component={TextFieldAdapter}
                      placeholder={t('plan.form.titlePlaceholder')}
                      autoFocus
                      autoComplete="off"
                    />
                  </FormControl>
                  <FormControl label={t('forms.labels.planBody')} id="body">
                    <Field
                      name="body"
                      component={EditorAdapter}
                      placeholder={t('plan.form.textPlaceholder')}
                    />
                  </FormControl>
                  <FormControl label={t('forms.labels.dateRange')} id="date" fullWidth={false}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography>{t('plan.period.from')}</Typography>
                      </Grid>
                      <Grid item>
                        <Field
                          name="date.start"
                          component={TextFieldAdapter}
                          InputProps={{
                            inputComponent: MaskedDateInput,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography>{t('plan.period.to')}</Typography>
                      </Grid>
                      <Grid item>
                        <Field
                          name="date.end"
                          component={TextFieldAdapter}
                          InputProps={{
                            inputComponent: MaskedDateInput,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button icon={Icons.CALENDAR} onClick={() => setShowForm(FORMS.DATE)}>
                          {t('forms.buttons.selectPeriod')}
                        </Button>
                      </Grid>
                    </Grid>
                  </FormControl>
                </>
              )}
              {showForm === FORMS.DATE && (
                <>
                  <Field name="date" component={DateRangeAdapter} />

                  <Box display="flex" justifyContent="center">
                    <Button icon={Icons.CHECK_CIRCLE} onClick={() => setShowForm(FORMS.MAIN)}>
                      {t('forms.buttons.selectPeriod')}
                    </Button>
                  </Box>
                </>
              )}

              {savePlanResponse &&
                savePlanResponse.rejected &&
                savePlanResponse.reason &&
                savePlanResponse.reason.cause.errors.map((item: any, index: number) => (
                  <FormError key={index}>{item.detail}</FormError>
                ))}
            </>
          </ModalBodyAlt>
        </BlurDialog>
      )}
    </Form>
  );
};

export default TaskDialogForm;
