import React, { useEffect } from 'react';
import useFetch from '../../../helpers/useFetch';
import { useSelector, useDispatch } from 'react-redux';
import security from '../../security';
import { ApiConfig } from '../api';
import { ApiConfig as SharedApiConfig } from '../../shared/api';
import Talent from './Talent';
import {
  TalentId,
  TalentsResponse,
  TaskResponse,
  UserId,
  PostsResponse,
} from '../../../config/api/models';
import { InferRequest } from 'react-redux-fetch';
import sortByDateUpdated from '../../../helpers/sortByDateUpdated';
import PanelWithSidebar from '../../../components/PanelWithSidebar';
import { useTranslation } from 'react-i18next';
import { CompassState } from '../../myCompass/model';
import { getCompassState } from '../../myCompass/selectors';
import { Actions } from '../../myCompass/actions';

type Props = {
  compassUserId?: UserId;
};

const apiConfig = ApiConfig.loadTalents();

const MyTalents = ({ compassUserId }: Props) => {
  const { t } = useTranslation();
  const relationApiLink = useSelector(security.selectors.getRelationLink(compassUserId));
  const compassState = useSelector(getCompassState);
  const [talentsResponse, fetchTalents] = useFetch<TalentsResponse, InferRequest<typeof apiConfig>>(
    apiConfig
  );
  const [postsResponse, fetchPosts] = useFetch<PostsResponse>(SharedApiConfig.posts());
  const dispatch = useDispatch();

  const [, deleteTalent] = useFetch<TaskResponse>(ApiConfig.deleteTalent());
  const deleteTal = (talentId: TalentId) => () => deleteTalent(talentId);

  const isLoading = !talentsResponse || !talentsResponse.value;
  const talents = (talentsResponse && talentsResponse.value && talentsResponse.value.data) || [];
  const refetch = () => {
    if (relationApiLink) {
      return fetchTalents(relationApiLink);
    }
  };

  useEffect(() => {
    if (relationApiLink) {
      fetchTalents(relationApiLink);
    }
  }, [relationApiLink, fetchTalents]);

  useEffect(() => {
    if (!postsResponse) {
      fetchPosts();
    }
  }, [postsResponse, fetchPosts]);

  useEffect(() => {
    if (compassUserId && compassState === CompassState.MYCOMPASS) {
      dispatch(Actions.setCompassState(CompassState.COMPASS));
    }
  }, [compassUserId, dispatch, compassState]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {compassState === CompassState.MYCOMPASS && (
        <div id="add-talent">
          <Talent talent={null} talentId={null} refetch={refetch} key={talents.length} />
        </div>
      )}

      {Boolean(compassUserId && !talents.length) && (
        <PanelWithSidebar>
          <div>{t('talents.noTalents')}</div>
        </PanelWithSidebar>
      )}

      <div id="talents-wrapper">
        {sortByDateUpdated(talents).map(talent => (
          <Talent
            talent={talent.attributes}
            talentId={talent.id}
            refetch={refetch}
            key={talent.id}
            onDelete={compassUserId ? undefined : deleteTal(talent.id)}
          />
        ))}
      </div>
    </>
  );
};

export default MyTalents;
