import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import {
  InspirationContext,
  LearnRequest,
  FocusId,
  FileDTO,
  LearnResponse,
} from '../../../../config/api/models';
import FormFields from '../../components/FormFields';
import ButtonGroup from '../../../../components/ButtonGroup';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import FormError from '../../../../components/forms/FormError';
import { useTranslation } from 'react-i18next';
import inspiration from '../../../inspiration';
import { PromiseState } from 'react-redux-fetch';
import { useSelector } from 'react-redux';
import { getSelectedFocusId } from '../../selectors';
import FileList from '../../../shared/components/FileList';
import { LearnSubmitValues } from '../../model';
import { getPersistingFiles } from '../../../shared/selectors';

type LearnFormValues = {
  title: string;
  body: string;
  focusId: FocusId;
  files: FileDTO[];
};

type Props = {
  onSave: (values: LearnSubmitValues) => void;
  onClose?: () => void;
  onSubmit?: () => void;
  learnResponse?: PromiseState<LearnResponse>;
  initialValues?: LearnFormValues;
  visibility: boolean;
};

const LearnForm = ({
  onSave,
  onClose,
  learnResponse,
  onSubmit,
  initialValues,
  visibility,
}: Props) => {
  const { t } = useTranslation();
  const selectedFocusId = useSelector(getSelectedFocusId);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [files] = useState(initialValues ? initialValues.files : []);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);
  const [filesChanged, setFilesChanged] = useState<boolean>(false);
  const persistingFiles = useSelector(getPersistingFiles);

  useEffect(() => {
    if (learnResponse && learnResponse.fulfilled && submitting && onSubmit) {
      onSubmit();
      setSubmitting(false);
    }
  }, [submitting, learnResponse, onClose, onSubmit]);

  useEffect(() => {
    if (learnResponse && learnResponse.rejected && disableSubmit) {
      setDisableSubmit(false);
    }
  }, [learnResponse, disableSubmit]);

  const handleFilesChange = (newFilesList: File[], fileIdsToDeleteList: string[]) => {
    setNewFiles(newFilesList);
    setFileIdsToDelete(fileIdsToDeleteList);
    setFilesChanged(Boolean(newFilesList.length || fileIdsToDeleteList.length));
  };

  return (
    <Form
      onSubmit={(values: LearnFormValues) => {
        const request: LearnRequest = {
          title: values.title.trim(),
          body: values.body.trim(),
          focusId: selectedFocusId,
          visibility,
        };

        setSubmitting(true);
        setDisableSubmit(true);
        onSave({ request, filesToAdd: newFiles, fileIdsToDelete });
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, form, values }) => (
        <form onSubmit={handleSubmit}>
          <inspiration.InspirationFormDialog
            inspirationKey={InspirationContext.COACHEE_LEARN}
            onSubmit={form.submit}
            editing
          >
            <FormFields
              onPreventSubmit={setDisableSubmit}
              titlePlaceholder={t('learn.titlePlaceholder')}
              textPlaceholder={t('learn.textPlaceholder')}
            />
            <FileList
              files={files}
              editable
              onFilesChange={handleFilesChange}
              loading={persistingFiles === 'learn'}
            />
          </inspiration.InspirationFormDialog>
          <ButtonGroup>
            <Button
              type="submit"
              disabled={
                (learnResponse && learnResponse.pending) ||
                (pristine && !filesChanged) ||
                persistingFiles === 'learn' ||
                disableSubmit ||
                !values.title ||
                !values.body
              }
              icon={Icons.SAVE}
            >
              {t('forms.buttons.save')}
            </Button>
            <Button type="button" onClick={onClose} color="secondary" icon={Icons.CANCEL}>
              {t('forms.buttons.cancel')}
            </Button>
          </ButtonGroup>
          {learnResponse &&
            learnResponse.rejected &&
            learnResponse.reason &&
            learnResponse.reason.cause.errors.map((item: any, index: number) => (
              <FormError key={index}>{item.detail}</FormError>
            ))}
        </form>
      )}
    />
  );
};

export default LearnForm;
