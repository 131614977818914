import React, { useState } from 'react';
import { GroupsResponse } from '../../../../config/api/models';
import MyGroupsGroupList from './MyGroupsGoupList';
import { MyCoachLayout, MyCoachASide, MyCoachMain } from '../../components/Atoms';
import MyGroupsGroup from '../../components/myGroups/MyGroupsGroup';

const MyGroups = () => {
  const [selectedGroup, setSelectedGroup] = useState<GroupsResponse['data'][number]>();

  return (
    <MyCoachLayout>
      <MyCoachASide id="groups-list">
        <MyGroupsGroupList onSelectGroup={setSelectedGroup} selectedGroup={selectedGroup} />
      </MyCoachASide>
      <MyCoachMain>
        <MyGroupsGroup group={selectedGroup} />
      </MyCoachMain>
    </MyCoachLayout>
  );
};

export default MyGroups;
