import { ImmutableObject } from 'seamless-immutable';
import { Moment } from 'moment';

type Opaque<BaseType, Identifier> = BaseType & { __type: Identifier };

type DateTimeString = string; // YYYY-MM-DDTHH:mm:ss+00:00

export type Maybe<T> = T | null;

export interface Auditable {
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
}

export type UserId = Opaque<string, 'userId'>;
export type CompassUserId = Opaque<string, 'userId'>;
export type FocusId = Opaque<string, 'focusId'>;
export type LearnId = Opaque<string, 'learnId'>;
export type LookBackId = Opaque<string, 'lookBackId'>;
export type DreamId = Opaque<string, 'dreamId'>;
export type ReflectionId = Opaque<string, 'reflectionId'>;
export type TaskId = Opaque<string, 'taskId'>;
export type TalentId = Opaque<string, 'talentId'>;
export type PageId = Opaque<string, 'pageId'>;
export type FileId = Opaque<string, 'fileId'>;
export type RelationId = Opaque<string, 'relationId'>;
export type ChatId = Opaque<string, 'chatId'>;
export type NoteId = Opaque<string, 'noteId'>;
export type PostId = Opaque<string, 'postId'>;
export type CommentId = Opaque<string, 'commentId'>;
export type MilestoneId = Opaque<string, 'milestoneId'>;
export type ShowcaseId = Opaque<string, 'showcaseId'>;
export type TrayNotificationId = Opaque<string, 'trayNotificationId'>;
export type GroupId = Opaque<string, 'groupId'>;

export type RelationType = 'coachee' | 'coach' | 'peer';
export type RelationUsersIds = {
  receiverId: string;
  initiatorId: string;
};

export enum ClosedStatus {
  CLOSED = 1,
  OPEN = 0,
}

export const makeFocusId = (id: string): FocusId => id as FocusId;
export const makeUserId = (id: string): UserId => id as UserId;

export enum InspirationContext {
  COACHEE_DREAM = 'coachee-dream',
  COACHEE_FOCUS = 'coachee-focus',
  COACHEE_LEARN = 'coachee-learn',
  COACHEE_LOOK_BACK = 'coachee-lookback',
  COACHEE_PLAN = 'coachee-plan',
  COACH = 'coach',
  COACH_DREAM = 'coach-dream',
  COACH_FOCUS = 'coach-focus',
  COACH_LEARN = 'coach-learn',
  COACH_LOOK_BACK = 'coach-lookback',
  COACH_PLAN = 'coach-plan',
}

export type InspirationType = 'dream' | 'focus' | 'plan' | 'learn' | 'lookBack';

export type FileReaderResult = string | ArrayBuffer | null;

// ------------
// REQUEST
// ------------

export interface CompleteRegistrationRequestData {
  lastName: string;
  firstName: string;
  email: string;
  secondaryEmail?: string;
  password: string;
  passwordConfirm: string;
}

export interface ResetPasswordRequestData {
  password: string;
  passwordConfirm: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface UpdateUserRequest {
  lastName: string;
  firstName: string;
  secondaryEmail: string;
  education: string;
  function: string;
  tourDone: number;
  showcaseTourDone: number;
  coachTourDone: number;
  talentsTourDone: number;
  hasPublicVisibleComments: number | null;
  allowReceivingWeeklyMail: number | null;
}

export interface ImagesRequest {
  photo: string;
}

export interface FileRequest {
  filename: string;
  modelType: string;
  modelId: number;
  content: string;
}

export interface DreamRequest {
  title: string;
  body: string;
  ownerId: UserId;
  visibility: boolean;
  isClosed?: ClosedStatus;
}

export interface DreamCoverImageRequest {
  photo: FileReaderResult;
}

export interface FocusRequest {
  title: string;
  body: string;
  visibility: boolean;
  dreamId: Maybe<DreamId>;
  isClosed?: ClosedStatus;
}

export interface LearnRequest {
  title: string;
  body: string;
  visibility: boolean;
  focusId: Maybe<FocusId>;
}

export interface PlanRequest {
  title: string;
  body?: string;
  startDate?: Moment;
  endDate?: Moment;
  focusId: FocusId;
  isClosed?: ClosedStatus;
  coachId: UserId | null;
}

export interface LookBackRequest {
  title: string;
  body: string;
  visibility: boolean;
  focusId: Maybe<FocusId>;
}

export interface TalentRequest {
  title: string;
  body: string;
  visibility: boolean;
  userId: UserId;
}

export interface RelationCreateRequest {
  userId: string;
  email: string;
  relationType: RelationType;
  contextId?: string;
  context?: ContextType;
  description: string;
  showcaseId: number;
}

export interface ChatMessageRequest {
  senderId: string;
  receiverId: string;
  contextId: string;
  contextType: ContextType;
  body: string;
}

export interface ChatSocketNotification {
  contextId: string;
  contextType: ContextType;
  from: string;
  entityId: string;
  id: string;
}

export interface NoteRequest {
  body: string;
  ownerId: string;
  coacheeId: string;
}

export interface GroupRequest {
  name: string;
  ownerId: string;
}

export interface GroupToRelationRequest {
  groupId: string;
  relationId: string;
}

export interface RegisterRequest {
  name: string;
  organisation: string;
  number: string;
  registerEmail: string;
  message: string;
}

export interface TrayNotification {
  entityId: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  authorId: string;
  author: User;
  notificationForId: string;
  trayId: string;
  trayType: string;
  readAt: string | null;
  dream: string;
  focus: string;
  commentType: string;
  commentableId: string;
  unreadNotifications: number;
}

// ------------
// RESPONSE
// ------------

interface Relationship {
  data: any;
  links: {
    self: string;
    related: string;
  };
}

export interface JsonApiResponse<
  Type = string,
  ID = string,
  Attributes = any,
  RelationshipKey extends string = ''
> {
  data: {
    type: Type;
    id: ID;
    attributes: Attributes;
    relationships: {
      [K in RelationshipKey]: Relationship;
    };
    links: any; // TODO
  };
}

export interface JsonApiListResponse<
  Type = string,
  ID = string,
  Attributes = undefined,
  RelationshipKey extends string = ''
> {
  data: Array<{
    type: Type;
    id: ID;
    attributes: Attributes;
    relationships: {
      [K in RelationshipKey]: Relationship;
    };
    links: any; // TODO
  }>;
  links?: {
    first?: string;
    next?: string;
    prev?: string;
  };
}

export interface FocusTab {
  id: FocusId;
  title: string;
  isClosed: ClosedStatus;
  isSuccessful: boolean;
}

export type FocusTabs = FocusTab[];
// export interface FocusTabs extends ImmutableArray<FocusTab> {}

export interface LoginResponse {
  access_token: string;
  token_type: 'bearer';
  expires_in: number;
  id: number;
}

export interface Media {
  small: string;
  medium: string;
  profile: string;
  cover_photo: string;
}

export interface FileDTO {
  content?: string;
  createdAt?: string;
  filename: string;
  location?: string;
  modelId: string;
  modelType: 'dream' | 'focus' | 'learn' | 'lookback';
  updatedAt?: string;
  entityId?: string;
}

export interface Organisation {
  name: string;
}

export interface Department {
  name: string;
}

export interface Dream
  extends ImmutableObject<{
    entityId: DreamId;
    title: string;
    visibility: boolean;
    body: string;
    isClosed: ClosedStatus;
    commentCount: number;
    includeShowcase: boolean;
    includeShowcaseComments: boolean;
    includeShowcaseFiles: boolean;
    media: Media;
    createdAt: string;
    updatedAt: string;
  }> {}

export interface Focus
  extends ImmutableObject<{
    entityId: FocusId;
    title: string;
    visibility: boolean;
    body: string;
    isClosed: ClosedStatus;
    isSuccessful: boolean;
    createdAt: DateTimeString;
    updatedAt: DateTimeString;
    dream: Dream;
    commentCount: number;
    includeShowcase: boolean;
    includeShowcaseComments: boolean;
    includeShowcaseFiles: boolean;
    closedAt: DateTimeString;
  }> {}

export interface Learn
  extends ImmutableObject<{
    entityId: LearnId;
    title: string;
    visibility: boolean;
    focusId: FocusId;
    body: string;
    commentCount: number;
    includeShowcase: boolean;
    includeShowcaseComments: boolean;
    includeShowcaseFiles: boolean;
    createdAt: string;
    updatedAt: string;
  }> {}

export interface User
  extends ImmutableObject<{
      entityId: UserId;
      department: Maybe<Department>;
      email: string;
      firstName: string;
      function: string;
      isExtern: number;
      lastName: string;
      picture: string;
      secondaryEmail: string;
      education: string;
      organisation: Organisation;
      tourDone: number;
      showcaseTourDone: number;
      coachTourDone: number;
      talentsTourDone: number;
      dreamdetailTourDone: number;
      welcomeTourDone: number;
      networkTourDone: number;
      media: Media;
      hasPublicVisibleComments: number | null;
      allowReceivingWeeklyMail: number | null;
    }>,
    Auditable {}

export interface CompassUser
  extends ImmutableObject<{
      entityId: string;
      department: Maybe<Department>;
      email: string;
      firstName: string;
      function: string;
      isExtern: number;
      lastName: string;
      picture: string;
      secondaryEmail: string;
      education: string;
      tourDone: number;
      showcaseTourDone: number;
      coachTourDone: number;
      talentsTourDone: number;
      organisation: Organisation;
      media: Media;
    }>,
    Auditable {}

export interface Reflection
  extends ImmutableObject<{
      entityId: ReflectionId;
      body: string;
      context: string;
      title: string;
    }>,
    Auditable {}

export interface LookBack
  extends ImmutableObject<{
    entityId: LookBackId;
    body: string;
    visibility: boolean;
    title: string;
    commentCount: number;
    includeShowcase: boolean;
    includeShowcaseComments: boolean;
    includeShowcaseFiles: boolean;
    createdAt: string;
    updatedAt: string;
  }> {}

export interface Task
  extends ImmutableObject<{
    title: string;
    body?: string;
    isClosed: ClosedStatus;
    startDate: Maybe<DateTimeString>;
    endDate: Maybe<DateTimeString>;
    createdAt: DateTimeString;
    updatedAt: DateTimeString;
    visibility: boolean;
    coachId?: UserId;
    coachUser?: User;
  }> {}

export interface Talent
  extends ImmutableObject<{
    entityId: TalentId;
    title: string;
    visibility: boolean;
    body: string;
    commentCount: number;
    includeShowcase: boolean;
    includeShowcaseComments: boolean;
    includeShowcaseFiles: boolean;
    createdAt: string;
    updatedAt: string;
  }> {}

export interface Milestone
  extends ImmutableObject<{
    entityId: MilestoneId;
    focusId: FocusId;
    focus: Focus;
    visibility: boolean;
    createdAt: DateTimeString;
    updatedAt: DateTimeString;
    commentCount: number;
    includeShowcase: boolean;
    includeShowcaseComments: boolean;
    includeShowcaseFiles: boolean;
  }> {}

export interface BasicPage
  extends ImmutableObject<{
    title: string;
    body: string;
    createdAt: DateTimeString;
    slug: string;
    updatedAt: DateTimeString;
  }> {}

export interface Group
  extends ImmutableObject<{
    name: string;
    ownerId: UserId;
  }> {}

export interface GroupRelation {
  type: 'relations';
  id: RelationId;
}

export type UserRelation = 'dreams' | 'organisation' | 'talents';
export interface UserResponse extends JsonApiResponse<'users', UserId, User, UserRelation> {}

export interface Note
  extends ImmutableObject<{
    entityId: NoteId;
    body: string;
    ownerId: UserId;
    coacheeId: UserId;
  }> {}

export interface Comment
  extends ImmutableObject<{
    entityId: CommentId;
    authorId: UserId;
    author: User;
    body: string;
    commentableId: CommentableIdType;
    commentableType: CommentableType;
    comment_by_coach: boolean;
    createdAt: DateTimeString;
    updatedAt: DateTimeString;
    deletedAt: DateTimeString;
  }> {}

export type CommentableIdType = DreamId | FocusId | LearnId | LookBackId | MilestoneId | TalentId;
export type CommentableType = 'dream' | 'focus' | 'learn' | 'lookback' | 'milestone' | 'talent';

export const CONTEXT_TYPE_DREAM = 'dream';
export const CONTEXT_TYPE_FOCUS = 'focus';
export type RelationContext = DreamRelation | FocusRelation;
export type ContextType = 'dream' | 'focus';

interface Relation {
  entityId: RelationId;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  acceptedAt?: DateTimeString;
  declinedAt?: DateTimeString;
  stoppedAt?: DateTimeString;
  initiator: User;
  initiatorId: string;
  receiver: User;
  receiverId: string;
  relationId: string;
  relationType: RelationType;
  lastMessageTime: string;
  description: string;
  showcaseSlug: string;
}

export interface DreamRelation extends Relation, ImmutableObject<{}> {
  context: Dream;
  contextType: 'dream';
  additionalData: DreamData;
  relationType: 'coach' | 'coachee';
}

export interface FocusRelation extends Relation, ImmutableObject<{}> {
  context: Focus;
  contextType: 'focus';
  additionalData: DreamData;
  relationType: 'coach' | 'coachee';
}

export interface PeerRelation extends Relation, ImmutableObject<{}> {
  relationType: 'peer';
}

export interface RelationContact {
  relation: RelationContext;
  archived: boolean;
  notAccepted: boolean;
}

export interface DreamData
  extends ImmutableObject<{
    dreamId: string;
    dreamTitle: string;
    focus: FocusData[];
    unreadMessages: number;
  }> {}

export interface FocusData
  extends ImmutableObject<{
    createdAt: DateTimeString;
    seenAt: DateTimeString;
    senderId: UserId;
    receiverId: UserId;
    body: string;
    focusId: FocusId;
    focusTitle: string;
    sender: User;
    receiver: User;
    focus: Focus;
    unreadMessages: number;
    isClosed: boolean;
  }> {}

export interface ChatMessage
  extends ImmutableObject<{
    entityId: string;
    senderId: UserId;
    receiverId: UserId;
    body: string;
    context: Dream | Focus;
    contextType: ContextType;
    id: string;
    createdAt: DateTimeString;
    updatedAt: DateTimeString;
    deletedAt: DateTimeString;
    seenAt: DateTimeString;
    sender: User;
    receiver: User;
    failed?: boolean;
    unreadMessages: number;
  }> {}

export interface Post<
  PostType = 'learn' | 'lookback' | 'talent' | 'milestone',
  Id = LearnId | LookBackId | TalentId | MilestoneId,
  Data = Learn | LookBack | Talent | Milestone
> {
  entityId: PostId;
  authorId: UserId;
  postType: PostType;
  postId: Id;
  includeContext: boolean;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  author: User;
  data: Data;
  dream: Dream | null;
  focus: Focus | null;
}

export interface Showcase {
  entityId: ShowcaseId;
  title: string;
  expiresAt: string;
  slug: string;
  map: ShowcaseMap;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString;
  user: User;
  userId: UserId;
  dreams: Dream[];
  focusses: Focus[];
  learnings: Learn[];
  lookbacks: LookBack[];
  milestones: Milestone[];
  comments: Comment[];
  talents: Talent[];
}

export interface ShowcaseMap {
  talents: ShowcaseTalentMap[];
  dreams: ShowcaseDreamMap[];
}

export interface ShowcaseMapAttributes {
  comments: number[];
}

export interface ShowcaseTalentMap extends ShowcaseMapAttributes {
  talentId: TalentId;
}

export interface ShowcaseDreamMap {
  dreamId: DreamId;
  dreamComments: Array<{ id: CommentId }>;
  focus: ShowcaseFocusMap[];
}

export interface ShowcaseFocusMap extends ShowcaseMapAttributes {
  id: FocusId;
  learnings: ShowcaseLearnMap[];
  lookbacks: ShowcaseLookBackMap[];
  milestones: ShowcaseMilestoneMap[];
}

export interface ShowcaseLearnMap extends ShowcaseMapAttributes {
  id: LearnId;
}

export interface ShowcaseLookBackMap extends ShowcaseMapAttributes {
  id: LookBackId;
}

export interface ShowcaseMilestoneMap extends ShowcaseMapAttributes {
  id: MilestoneId;
}

export interface ShowcaseRequest {
  title: string;
  expiresAt: DateTimeString;
  deletedAt: Moment | null;
  updatedAt?: DateTimeString;
}

export interface LearnPost extends Post<'learn', LearnId, Learn> {}
export interface LookbackPost extends Post<'lookback', LookBackId, LookBack> {}
export interface TalentPost extends Post<'talent', TalentId, Talent> {}
export interface MilestonePost extends Post<'milestone', MilestoneId, Milestone> {}

export type PostOption = LearnPost | LookbackPost | TalentPost | MilestonePost;

export type UserRelationship = 'dreams' | 'organisation' | 'talents';
export interface UserResponse extends JsonApiResponse<'users', UserId, User, UserRelationship> {}

export interface CompassUserResponse extends JsonApiResponse<'user', CompassUserId, CompassUser> {}

type DreamRelationship = 'focusses';
export interface DreamResponse
  extends JsonApiResponse<'dreams', DreamId, Dream, DreamRelationship> {}

type FocusRelationship = 'dream' | 'learnings' | 'lookback' | 'plans';
export interface FocusResponse
  extends JsonApiResponse<'focus', FocusId, Focus, FocusRelationship> {}

export interface LearnResponse extends JsonApiResponse<'learn', LearnId, Learn> {}

export interface LookBackResponse
  extends JsonApiResponse<'lookback', LookBackId, LookBack, 'focus'> {}

export interface ReflectionResponse
  extends JsonApiResponse<'reflections', ReflectionId, Reflection> {}

export interface ReflectionsResponse
  extends JsonApiListResponse<'reflections', ReflectionId, Reflection> {}

export interface FocusIdsResponse extends JsonApiListResponse<'focusses', FocusId> {}

export interface LearningsResponse
  extends JsonApiListResponse<'learnings', LearnId, Learn, 'focus'> {}

export interface PlanResponse extends JsonApiListResponse<'plans', TaskId, Task> {}

export interface TaskResponse extends JsonApiResponse<'plans', TaskId, Task> {}

export interface TalentsResponse extends JsonApiListResponse<'talents', TalentId, Talent> {}

export interface DreamsResponse extends JsonApiListResponse<'dreams', DreamId, Dream> {}

export interface BasicPagesResponse extends JsonApiResponse<'basicPage', PageId, BasicPage> {}

export interface FilesResponse extends JsonApiListResponse<'files', FileId, FileDTO> {}

export interface RelationsResponse
  extends JsonApiListResponse<'relations', RelationId, RelationContext> {}

export interface PeerRelationsResponse
  extends JsonApiListResponse<'relations', RelationId, PeerRelation> {}

export interface ChatsResponse extends JsonApiListResponse<'chats', ChatId, ChatMessage> {}
export interface ChatResponse extends JsonApiResponse<'chats', ChatId, ChatMessage> {}

export interface NotesResponse extends JsonApiListResponse<'notes', NoteId, Note> {}

export interface PostsResponse extends JsonApiListResponse<'posts', PostId, PostOption> {}

export interface CommentsResponse extends JsonApiListResponse<'comments', CommentId, Comment> {}
export interface CommentResponse extends JsonApiResponse<'comments', CommentId, Comment> {}

export interface ShowcasesResponse extends JsonApiListResponse<'showcases', ShowcaseId, Showcase> {}
export interface ShowcaseResponse extends JsonApiResponse<'showcases', ShowcaseId, Showcase> {}

export interface MilestonesResponse
  extends JsonApiListResponse<'milestones', MilestoneId, Milestone, 'focus'> {}

export interface TrayNotificationResponse
  extends JsonApiListResponse<'traynotifications', TrayNotificationId, TrayNotification> {}

export interface GroupsResponse
  extends JsonApiListResponse<'groups', GroupId, Group, 'relations'> {}
