import React from 'react';
import PeerInvitation from './PeerInvitation';
import { PeerRelation } from '../../../config/api/models';
import { makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  invitations: PeerRelation[];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
    },
  },
}));

const PeerInvitationList = ({ invitations }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {invitations.map(invitation => (
        <PeerInvitation
          key={invitation.entityId}
          initiator={invitation.initiator}
          relationId={invitation.entityId}
        />
      ))}
    </div>
  );
};

export default PeerInvitationList;
