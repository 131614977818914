import { Showcase, DreamId, Focus, FocusId } from '../../../config/api/models';
import { TabValue } from '../../myCompass/containers/Focus/FocusTabsBar';

export const getShowcaseFocussesForDream = (showcase: Showcase, dreamId: DreamId) => {
  return showcase.focusses.filter(f => f.dream.entityId === dreamId);
};

export const focusTabsMapper = (focusses: Focus[]): TabValue[] => {
  return focusses.map((f, index) => ({
    tabName: f.title,
    tabIndex: index,
    isClosed: Boolean(f.isClosed),
    isSuccessful: f.isSuccessful,
    tabValue: f.entityId,
  }));
};

export const getSelectedContext = (showcase: Showcase, focusId: FocusId): string => {
  let result = '';

  const dream = showcase.dreams[0];
  if (dream) {
    result = dream.title;
  }

  const focus = showcase.focusses.find(f => f.entityId === focusId);
  if (focus) {
    result += ` / ${focus.title}`;
  }

  return result;
};

export const getShowcaseFocusMapForSelectedDream = (
  showcase: Showcase,
  dreamId: DreamId,
  focusId: FocusId
) => {
  const showcaseDream = showcase.map.dreams.find(d => d.dreamId === dreamId);
  if (!showcaseDream) {
    return null;
  }
  return showcaseDream.focus.find(f => f.id === focusId);
};
