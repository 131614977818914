import { makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  createdAt: string;
  updatedAt: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  dateTime: {
    color: theme.palette.grey[400],
    padding: '2rem 0 1rem',
    fontSize: '1.2rem',
    marginBottom: '-4rem',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const DateTimeMessage = ({ createdAt, updatedAt }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Typography className={classes.dateTime}>
      {createdAt === updatedAt
        ? t('dream.dateTime.createdAt')
            .replace('%DATE%', moment(createdAt).format('L'))
            .replace('%TIME%', moment(createdAt).format('LT'))
        : t('dream.dateTime.updatedAt')
            .replace('%DATE%', moment(updatedAt).format('L'))
            .replace('%TIME%', moment(updatedAt).format('LT'))}
    </Typography>
  );
};

export default DateTimeMessage;
