import React, { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useUser from '../../security/hooks/useUser';
import ChatActions from '../../../components/chat/ChatActions';
import {
  CommentsResponse,
  CommentableType,
  CommentableIdType,
  Comment as CommentType,
  CommentResponse,
  RelationsResponse,
  User,
  UpdateUserRequest,
  UserResponse,
} from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { ApiConfig as SharedApiConfig } from '../../shared/api';
import { ApiConfig as UserApiConfig } from '../../profile/api';
import Comment from './Comment';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { unionBy } from 'lodash';
import moment from 'moment';
import { isCoachOrCoachee } from '../../myCoach/helpers/helperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedFocusId } from '../selectors';
import { Icons } from '../../../components/SvgIcon';
import { Box, Snackbar, useMediaQuery } from '@material-ui/core';
import security from '../../security';
import BlurDialog from '../../shared/components/BlurDialog';
import theme from '../../../config/theme';
import { getMilaNetworkTourSwitchToComments } from '../../shared/selectors';
import { Actions } from '../../shared/actions';
import TextField from '../../../components/TextField';
import ButtonGroup from '../../../components/ButtonGroup';
import FormError from '../../../components/forms/FormError';
const spinner = require('../../../assets/spinner_dark.gif');

type Props = {
  contextId: CommentableIdType;
  contextType: CommentableType;
  disabled?: boolean;
  onAddComment?: () => void;
  canComment?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  commentWrapperRoot: {
    padding: '0rem 2rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingBottom: '2rem',
    },
  },
  commentFeed: {
    maxHeight: '30rem',
    overflow: 'auto',
    scrollBehavior: 'smooth',
  },
  comments: {
    padding: '2rem',
    paddingBottom: 0,
  },
  loadButton: {
    marginLeft: '4.5rem',
    marginTop: '1rem',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    height: '6rem',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      margin: '0.5rem',
    },
  },
  editDialog: {
    '& .MuiPaper-root': {
      width: '100rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
}));

const CommentWrapper = ({ contextId, contextType, disabled, onAddComment, canComment }: Props) => {
  const classes = useStyles();
  const currentUser = useUser();
  const { t } = useTranslation();
  const [comments, setComments] = useState<CommentType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [needScroll, setNeedScroll] = useState<boolean>(false);
  const commentFeedRef = useRef<HTMLDivElement>(null);
  const selectedFocusId = useSelector(getSelectedFocusId);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const userId = useSelector(security.selectors.getUserId);
  const [updateUserResponse, updateUser] = useFetch<UserResponse>(UserApiConfig.updateUser(userId));
  const [, fetchUser] = useFetch<User>(security.api.ApiConfig.getUser(userId));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const milaNetworkTourSwitchToComments = useSelector(getMilaNetworkTourSwitchToComments);
  const dispatch = useDispatch();

  const [relationsRequest] = useFetch<RelationsResponse>(SharedApiConfig.relations());
  const [commentRequest, fetchComments] = useFetch<CommentsResponse>(
    ApiConfig.loadComments(contextId, contextType)
  );
  const [addCommentRequest, addComment] = useFetch<CommentResponse>(ApiConfig.addComment());
  const [editCommentRequest, editComment] = useFetch<CommentResponse>(ApiConfig.editComment());
  const [deleteCommentRequest, deleteComment] = useFetch<CommentResponse>(
    ApiConfig.deleteComment()
  );
  const relations =
    relationsRequest && relationsRequest.value
      ? relationsRequest.value.data.map(d => d.attributes)
      : [];
  const [prevCommentToEdit, setPrevCommentToEdit] = useState<CommentType | null>(null);
  const [commentToEdit, setCommentToEdit] = useState<CommentType | null>(null);
  const [commentToDelete, setCommentToDelete] = useState<CommentType | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (milaNetworkTourSwitchToComments) {
      dispatch(Actions.disableMilaNetworkTourSwitchToComments());
    }
  }, [milaNetworkTourSwitchToComments, dispatch]);

  // eslint-disable-next-line
  useEffect(fetchComments, [contextId, contextType]);

  useEffect(() => {
    setComments([]);
  }, [contextId]);

  // replace new comment with the returned PATCH value
  useEffect(() => {
    if (editCommentRequest && editCommentRequest.fulfilled && editCommentRequest.value) {
      const edittedComment = editCommentRequest.value.data.attributes;
      setComments(cmds =>
        cmds.map(cmd => (cmd.entityId === edittedComment.entityId ? edittedComment : cmd))
      );
      setCommentToEdit(null);
    }
    if (editCommentRequest && editCommentRequest.rejected) {
      setSnackbarOpen(true);
      //Put prev comment back
      setComments(cmds =>
        cmds.map(cmd =>
          prevCommentToEdit != null && cmd.entityId === prevCommentToEdit.entityId
            ? prevCommentToEdit
            : cmd
        )
      );
      setCommentToEdit(null);
      setPrevCommentToEdit(null);
    }
  }, [editCommentRequest, prevCommentToEdit]);

  // replace new comment with the returned POST value
  useEffect(() => {
    if (addCommentRequest && addCommentRequest.fulfilled && addCommentRequest.value) {
      const addedComment = addCommentRequest.value.data.attributes;
      setComments(cmds =>
        cmds.map(cmd => (!cmd.entityId && cmd.body === addedComment.body ? addedComment : cmd))
      );
    }
  }, [addCommentRequest]);

  // remove comment with the returned value
  useEffect(() => {
    if (deleteCommentRequest && deleteCommentRequest.fulfilled && deleteCommentRequest.value) {
      setCommentToDelete(null);
    }
  }, [deleteCommentRequest]);

  // Set comments
  useEffect(() => {
    if (commentRequest && commentRequest.value && !commentRequest.pending) {
      const newComments = commentRequest.value.data.map(d => d.attributes);
      setComments(c => unionBy(c, newComments, v => [v.entityId, v.createdAt, v.body].join()));
      setLoading(false);
    } else if (commentRequest && commentRequest.value && !comments.length) {
      const newComments = commentRequest.value.data.map(d => d.attributes);
      setComments([...newComments]);
    }
  }, [commentRequest, setComments, comments.length]);

  // Scroll to the top
  useEffect(() => {
    if (comments.length && commentFeedRef.current && needScroll) {
      commentFeedRef.current.scrollTop = 0;
      setNeedScroll(false);
    }
  }, [comments, commentFeedRef, needScroll]);

  const handlePostComment = (comment: string) => {
    if (currentUser.hasPublicVisibleComments === null) {
      setDialogOpen(true);
    }
    const newComment: Partial<CommentType> = {
      authorId: currentUser.entityId,
      author: currentUser,
      body: comment,
      commentableId: contextId,
      commentableType: contextType,
    };
    addComment(newComment);
    setNeedScroll(true);
    setComments([newComment as CommentType, ...comments]);

    if (onAddComment) {
      onAddComment();
    }

    if (commentFeedRef.current) {
      commentFeedRef.current.scrollTop = 0;
    }
  };

  const setUserLabel = (comment: CommentType) => {
    if (relations && comment.author.entityId !== currentUser.entityId) {
      const isCoach = relations.find(rel => {
        if (
          rel.receiverId === comment.author.entityId ||
          rel.initiatorId === comment.author.entityId
        ) {
          if (rel.contextType === contextType) {
            return (
              rel.context.entityId === contextId &&
              isCoachOrCoachee(rel, comment.author) === 'coachee'
            );
          } else if (contextType === 'dream') {
            return false;
          } else if (selectedFocusId) {
            if (rel.contextType === 'dream') {
              return (
                rel.additionalData.focus.find(f => f.focusId === selectedFocusId) &&
                isCoachOrCoachee(rel, comment.author) === 'coachee'
              );
            } else {
              return (
                rel.context.entityId === selectedFocusId &&
                isCoachOrCoachee(rel, comment.author) === 'coachee'
              );
            }
          }
        }
        return false;
      });

      return isCoach ? t('myCoach.relationship.coach') : '';
    }
    return '';
  };

  const hasMoreComments =
    commentRequest &&
    commentRequest.value &&
    commentRequest.value.links &&
    commentRequest.value.links.next;

  const handleLoadMoreComments = () => {
    if (hasMoreComments) {
      const url = commentRequest!.value!.links!.next;
      if (url) {
        const newUrl = url.replace(/%5B/g, '[').replace(/%5D/g, ']');
        fetchComments(newUrl);
      }
    }
  };

  const handleClosePublicCommentsVisibleDialog = () => {
    setDialogOpen(false);
  };

  const handleClickClosePublicCommentsVisibleDialogButton = (e: any) => {
    const request: UpdateUserRequest = {
      lastName: currentUser.lastName,
      firstName: currentUser.firstName,
      secondaryEmail: currentUser.secondaryEmail,
      education: currentUser.education,
      function: currentUser.function,
      tourDone: currentUser.tourDone,
      talentsTourDone: currentUser.talentsTourDone,
      showcaseTourDone: currentUser.showcaseTourDone,
      coachTourDone: currentUser.coachTourDone,
      hasPublicVisibleComments: e.currentTarget.value,
      allowReceivingWeeklyMail: currentUser.allowReceivingWeeklyMail,
    };
    updateUser(request);
    setSubmitted(true);
    setDialogOpen(false);
  };

  useEffect(() => {
    if (updateUserResponse && updateUserResponse.fulfilled && submitted) {
      fetchUser();
      setSubmitted(false);
    }
  }, [updateUserResponse, fetchUser, submitted]);

  const onClickEditComment = useCallback(
    (comment: CommentType) => {
      if (comment.entityId == null) return; //This is the case when a comment was added but did not receive an id from the BE yet.
      if (commentToEdit == null) {
        //Not null means a previous comment update is still going on.
        setCommentToEdit(comment);
      }
    },
    [commentToEdit]
  );
  const onClickDeleteComment = (comment: CommentType) => {
    if (comment.entityId == null) return; //This is the case when a comment was added but did not receive an id from the BE yet.
    setCommentToDelete(comment);
  };

  const saveEditedComment = useCallback(() => {
    if (commentToEdit == null) return;
    setPrevCommentToEdit(comments.find(cmd => cmd.entityId === commentToEdit.entityId) || null);
    setComments(cmds =>
      cmds.map(cmd => (cmd.entityId === commentToEdit.entityId ? commentToEdit : cmd))
    );
    if (commentToEdit) {
      editComment(commentToEdit);
    }
    setCommentToEdit(null);
  }, [commentToEdit, comments, editComment]);

  const actionButtons = [
    <Button
      onClick={handleClickClosePublicCommentsVisibleDialogButton}
      icon={Icons.CHECK_CIRCLE}
      value={1}
      key="yes"
    >
      {t('common.yes')}
    </Button>,
    <Button
      onClick={handleClickClosePublicCommentsVisibleDialogButton}
      icon={Icons.CANCEL}
      color="secondary"
      key="no"
      value={0}
    >
      {t('common.no')}
    </Button>,
  ];

  const commentToEditActionButtons = (
    <ButtonGroup>
      <Button
        className={classes.button}
        type="button"
        onClick={saveEditedComment}
        icon={Icons.SAVE}
      >
        {t('forms.buttons.save')}
      </Button>
      <Button
        className={classes.button}
        type="button"
        onClick={() => setCommentToEdit(null)}
        color="secondary"
        icon={Icons.CANCEL}
      >
        {t('forms.buttons.cancel')}
      </Button>
    </ButtonGroup>
  );

  const commentToDeleteActionButtons = (
    <ButtonGroup>
      <Button
        className={classes.button}
        type="button"
        onClick={() => deleteComment(commentToDelete)}
        icon={Icons.SAVE}
      >
        {t('forms.buttons.delete')}
      </Button>
      <Button
        className={classes.button}
        type="button"
        onClick={() => setCommentToDelete(null)}
        color="secondary"
        icon={Icons.CANCEL}
      >
        {t('forms.buttons.cancel')}
      </Button>
    </ButtonGroup>
  );

  return (
    <div id="commentWrapper" className={classes.commentWrapperRoot}>
      {dialogOpen && (
        <BlurDialog
          open={dialogOpen}
          title={t('publicCommentsVisible.title')}
          onClose={handleClosePublicCommentsVisibleDialog}
          actions={actionButtons}
          fullScreen={isMobile}
        >
          {t('publicCommentsVisible.consent')}
        </BlurDialog>
      )}
      {!!commentToDelete && (
        <BlurDialog
          open={!!commentToDelete}
          title={t('comments.deleteTitle')}
          onClose={() => setCommentToDelete(null)}
          actions={commentToDeleteActionButtons}
          fullScreen={isMobile}
        >
          {t('comments.deleteBody')}
        </BlurDialog>
      )}
      {!!commentToEdit && (
        <BlurDialog
          open={!!commentToEdit}
          title={t('comments.editTitle')}
          onClose={() => setCommentToEdit(null)}
          actions={commentToEditActionButtons}
          fullScreen={isMobile}
          className={classes.editDialog}
        >
          <TextField
            value={commentToEdit.body}
            onChange={event =>
              setCommentToEdit(comment =>
                comment != null
                  ? {
                      ...comment,
                      body: event.target.value,
                    }
                  : null
              )
            }
            autoFocus
          />
        </BlurDialog>
      )}
      {!disabled && canComment && (
        <ChatActions avatar={currentUser.media.profile} onSend={handlePostComment} position="top" />
      )}
      <div className={classes.comments}>
        {loading ? (
          <div className={classes.loading}>
            <img src={spinner} className={classes.spinner} alt="" />
            {t('comments.loading')}
          </div>
        ) : (
          <>
            {comments.length ? (
              <>
                <div className={classes.commentFeed} ref={commentFeedRef}>
                  {comments.sort(commentSort).map((comment, index) => (
                    <div
                      key={comment.entityId || `index-${index}`}
                      id={`comment-${comment.entityId}`}
                    >
                      <Comment
                        comment={comment}
                        userLabel={setUserLabel(comment)}
                        currentUserEntityId={currentUser.entityId}
                        onClickEditComment={onClickEditComment}
                        onClickDeleteComment={onClickDeleteComment}
                      />
                    </div>
                  ))}
                </div>
                {hasMoreComments && (
                  <Button
                    className={classes.loadButton}
                    key="loadMoreButton"
                    onClick={handleLoadMoreComments}
                  >
                    {t('comments.loadMore')}
                  </Button>
                )}
              </>
            ) : (
              <div>{t('comments.noComments')}</div>
            )}
          </>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={5000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Box pb={5}>
          <FormError>{t('forms.feedback.commentNotUpdated')}</FormError>
        </Box>
      </Snackbar>
    </div>
  );
};

export default CommentWrapper;

// Sort newest comment first
export function commentSort(a: CommentType, b: CommentType) {
  const momentA = moment(a.createdAt);
  const momentB = moment(b.createdAt);
  if (momentA.isSame(momentB)) {
    const updatedMomentA = moment(a.updatedAt);
    const updatedMomentB = moment(b.updatedAt);
    if (updatedMomentA.isSame(updatedMomentB)) {
      return a.entityId < b.entityId ? 1 : -1;
    }
    return updatedMomentA.isBefore(updatedMomentB) ? 1 : -1;
  }
  return momentA.isBefore(momentB) ? 1 : -1;
}
