import { RelationContext, ChatSocketNotification } from '../../../config/api/models';

export const getRelationForNotification = (
  relations: RelationContext[],
  notification: ChatSocketNotification
) => {
  const relation = relations.find(rel => {
    if (rel.initiatorId !== notification.from && rel.receiverId !== notification.from) {
      return false;
    }

    if (rel.contextType === notification.contextType) {
      return rel.context.entityId === notification.contextId;
    } else if (rel.contextType === 'dream') {
      return rel.additionalData.focus.find(f => f.focusId === notification.contextId);
    } else if (rel.contextType === 'focus') {
      return rel.additionalData.dreamId === notification.contextId;
    }
    return false;
  });
  return relation;
};
