import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, Placement, STATUS } from 'react-joyride';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as SharedApiConfig } from '../api';
import useUser from '../../security/hooks/useUser';

let userPatched: boolean = false;

const MyNetworkTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const currentUser = useUser();
  const [showTour, setShowTour] = useState(true);
  const [, patchUser] = useFetch<UserResponse>(SharedApiConfig.updateTourDone());

  const steps = [
    {
      target: '#my-network',
      content: t('tour.steps.myNetwork.first'),
      disableBeacon: true,
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.SKIPPED, STATUS.FINISHED].includes(status)
      ) {
        setShowTour(false);
      }
    },
    [setShowTour]
  );

  useEffect(() => {
    if (!showTour && !userPatched) {
      const userUpdate = {
        networkTourDone: 1,
      };
      patchUser(currentUser.entityId, userUpdate);
      userPatched = true;
    }
  }, [showTour, currentUser, patchUser]);

  return (
    <>
      <Joyride
        steps={steps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        showProgress
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.close'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
    </>
  );
};

export default MyNetworkTour;
