import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PostOption } from '../../../config/api/models';
import { capitalize } from 'lodash';
import Tooltip from '../../../components/Tooltip';

type Props = {
  post: PostOption;
};

const useStyles = makeStyles((theme: Theme) => ({
  breadCrumbRoot: {
    color: theme.palette.grey['400'],
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
  },
  contextRoot: {
    display: 'flex',
    alignItems: 'center',
    '& > div:not(:first-child)': {
      marginLeft: '0.5rem',
    },
  },
  contextItem: {
    display: 'flex',
    alignItems: 'center',
  },
  context: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    maxWidth: '10rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: '0.5rem',
  },
  breadCrumb: {
    marginLeft: '0.5rem',
  },
}));

const NetworkBreadCrumb = ({ post }: Props) => {
  const classes = useStyles();

  if (post.postType === 'milestone') {
    return null;
  }

  const context =
    post.includeContext && post.dream && post.focus ? (
      <div className={classes.contextRoot}>
        <div className={classes.contextItem}>
          <Tooltip title={post.dream.title} placement="top">
            <div className={classes.context}>{post.dream.title}</div>
          </Tooltip>
          >
        </div>
        <div className={classes.contextItem}>
          <Tooltip title={post.focus.title} placement="top">
            <div className={classes.context}>{post.focus.title}</div>
          </Tooltip>
          >
        </div>
      </div>
    ) : null;

  return (
    <div className={classes.breadCrumbRoot}>
      {context}
      <div className={classes.breadCrumb}>{`i${capitalize(post.postType)}`}</div>
    </div>
  );
};

export default NetworkBreadCrumb;
