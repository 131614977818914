import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import TextField from '../TextField';
import { TextFieldProps } from '@material-ui/core/TextField';

type Props = FieldRenderProps<any, any> &
  TextFieldProps & {
    helperText?: string;
  };

const TextFieldAdapter = ({
  input: { name, onChange, value, ...restInput },
  meta,
  helperText,
  ...rest
}: Props) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <TextField
      {...rest}
      name={name}
      helperText={showError ? meta.error || meta.submitError : helperText}
      error={showError}
      inputProps={restInput}
      onChange={onChange}
      value={value}
    />
  );
};

export default TextFieldAdapter;
