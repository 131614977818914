import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, CallBackProps, Placement, STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { getHelp, getMilaFirstDreamId } from '../selectors';
import { Actions as CompassActions } from '../../myCompass/actions';

const MilaDreamTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const milaFirstDreamId = useSelector(getMilaFirstDreamId);
  const [showFirstPart, setShowFirstPart] = useState(true);
  const [showSecondPart, setShowSecondPart] = useState(false);
  const [showThirdPart, setShowThirdPart] = useState(false);
  const [showFourthPart, setShowFourthPart] = useState(false);
  const [showFifthPart, setShowFifthPart] = useState(false);
  const [showSixthPart, setShowSixthPart] = useState(false);
  const [showSeventhPart, setShowSeventhPart] = useState(false);
  const isHelpActive = useSelector(getHelp);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CompassActions.disableInitialFocusCreated());
  }, [dispatch]);

  const firstSteps = [
    {
      target: '#user-info',
      content: t('tour.steps.milaDream.first'),
      disableBeacon: true,
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#i-dream-title',
      content: t('tour.steps.milaDream.second'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#dream-inspiration-button',
      content: t('tour.steps.milaDream.third'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#dream-coach-button',
      content: t('tour.steps.milaDream.fourth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#dream-content',
      content: t('tour.steps.milaDream.fifth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const secondSteps = [
    {
      target: '#i-focus-title',
      content: t('tour.steps.milaDream.sixth'),
      placement: 'auto' as Placement,
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#add-focus-button',
      content: t('tour.steps.milaDream.seventh'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#focus-add-coach-button',
      content: t('tour.steps.milaDream.eight'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#focus-inspiration-button',
      content: t('tour.steps.milaDream.ninth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#focus-visibility-button',
      content: t('tour.steps.milaDream.tenth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const thirdSteps = [
    {
      target: '#i-plan-title',
      content: t('tour.steps.milaDream.eleventh'),
      placement: 'auto' as Placement,
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#add-activity-button',
      content: t('tour.steps.milaDream.twelfth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#edit-delete-buttons',
      content: t('tour.steps.milaDream.thirteenth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#plan-closed-tasks-list',
      content: t('tour.steps.milaDream.fourteenth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const fourthSteps = [
    {
      target: '#i-learn-title',
      content: t('tour.steps.milaDream.fifteenth'),
      placement: 'auto' as Placement,
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#learn-sharing-button',
      content: t('tour.steps.milaDream.sixteenth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const fifthSteps = [
    {
      target: '#learn-communications',
      content: t('tour.steps.milaDream.eighteenth'),
      placement: 'auto' as Placement,
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const sixthSteps = [
    {
      target: '#i-lookback-title',
      content: t('tour.steps.milaDream.twenty-first'),
      placement: 'auto' as Placement,
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const seventhSteps = [
    {
      target: `#dream-${milaFirstDreamId}`,
      content: t('tour.steps.milaDream.twenty-third'),
      placement: 'top-start' as Placement,
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#to-dreams-overview-button',
      content: t('tour.steps.milaDream.twenty-fourth'),
      placement: 'auto' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleFirstTourCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action as any) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)
      ) {
        setShowFirstPart(false);
      }
      if ([STATUS.FINISHED].includes(status as any)) {
        const focusElement = document.querySelector('#i-focus');
        if (focusElement) {
          focusElement.scrollIntoView({
            behavior: 'smooth',
          });
        }
        setTimeout(() => setShowSecondPart(true), 750);
      }
    },
    [setShowFirstPart, setShowSecondPart]
  );

  const handleSecondTourCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action as any) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)
      ) {
        setShowSecondPart(false);
      }
      if ([STATUS.FINISHED].includes(status as any)) {
        const focusElement = document.querySelector('#i-plan');
        if (focusElement) {
          focusElement.scrollIntoView({
            behavior: 'smooth',
          });
        }
        setTimeout(() => setShowThirdPart(true), 500);
      }
    },
    [setShowSecondPart, setShowThirdPart]
  );

  const handleThirdTourCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action as any) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)
      ) {
        setShowThirdPart(false);
      }
      if ([STATUS.FINISHED].includes(status as any)) {
        const focusElement = document.querySelector('#i-learn');
        if (focusElement) {
          focusElement.scrollIntoView({
            behavior: 'smooth',
          });
        }
        setTimeout(() => setShowFourthPart(true), 500);
      }
    },
    [setShowThirdPart, setShowFourthPart]
  );

  const handleFourthTourCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action as any) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)
      ) {
        setShowFourthPart(false);
      }
      if ([STATUS.FINISHED].includes(status as any)) {
        const focusElement = document.querySelector('#learn-communications');
        if (focusElement) {
          focusElement.scrollIntoView({
            behavior: 'smooth',
          });
        }
        setTimeout(() => setShowFifthPart(true), 500);
      }
    },
    [setShowFourthPart, setShowFifthPart]
  );

  const handleFifthTourCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action as any) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)
      ) {
        setShowFifthPart(false);
      }
      if ([STATUS.FINISHED].includes(status as any)) {
        const focusElement = document.querySelector(`#i-lookback`);
        if (focusElement) {
          focusElement.scrollIntoView({
            behavior: 'smooth',
          });
        }
        setTimeout(() => setShowSixthPart(true), 1000);
      }
    },
    [setShowFifthPart, setShowSixthPart]
  );

  const handleSixthTourCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action as any) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)
      ) {
        setShowSixthPart(false);
      }
      if ([STATUS.FINISHED].includes(status as any)) {
        const focusElement = document.querySelector(`#dream-${milaFirstDreamId}`);
        if (focusElement) {
          focusElement.scrollIntoView({
            behavior: 'smooth',
          });
        }
        setTimeout(() => setShowSeventhPart(true), 1000);
      }
    },
    [setShowSixthPart, setShowSeventhPart, milaFirstDreamId]
  );

  const handleSeventhTourCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action as any) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)
      ) {
        setShowSeventhPart(false);
      }
    },
    [setShowSeventhPart]
  );

  return isHelpActive ? (
    <>
      <Joyride
        steps={firstSteps}
        run={showFirstPart}
        callback={handleFirstTourCallback}
        continuous
        showSkipButton
        showProgress
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.next'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      <Joyride
        steps={secondSteps}
        run={showSecondPart}
        callback={handleSecondTourCallback}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.next'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      <Joyride
        steps={thirdSteps}
        run={showThirdPart}
        callback={handleThirdTourCallback}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.next'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      <Joyride
        steps={fourthSteps}
        run={showFourthPart}
        callback={handleFourthTourCallback}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.next'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      <Joyride
        steps={fifthSteps}
        run={showFifthPart}
        callback={handleFifthTourCallback}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.next'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      <Joyride
        steps={sixthSteps}
        run={showSixthPart}
        callback={handleSixthTourCallback}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.next'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      <Joyride
        steps={seventhSteps}
        run={showSeventhPart}
        callback={handleSeventhTourCallback}
        continuous
        showSkipButton
        showProgress
        disableScrolling
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.close'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
    </>
  ) : null;
};

export default MilaDreamTour;
