import React, { useState, useEffect, useMemo } from 'react';
import { RelationContext, RelationsResponse } from '../../../config/api/models';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../../shared/selectors';
import { Actions } from '../../shared/actions';
import { Actions as myCoachActions } from '../../myCoach/actions';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../../shared/api';
import Section from '../../../components/Section';
import CoachChat from './CoachChat';
import { useTranslation } from 'react-i18next';
import inspiration from '../../inspiration';
import { getRelationForNotification } from '../../shared/helpers/helperFunctions';
import { getKey } from '../helpers/helperFunctions';

type Props = {
  selectedChat?: RelationContext;
  setSelectedChat: (relation?: RelationContext) => void;
  relations: RelationContext[];
};

const ChatWrapper = ({ selectedChat, setSelectedChat, relations }: Props) => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const { t } = useTranslation();

  const [refreshMessages, setRefreshMessages] = useState<string[]>([]);
  const [handlingNotifications, setHandlingNotifications] = useState<boolean>(false);

  useEffect(() => {
    const handleNotification = () => {
      if (notifications.length) {
        setHandlingNotifications(true);
        const handledNotifications: string[] = [];
        const messagesToFetch: string[] = [];

        notifications.forEach(n => {
          if (
            selectedChat &&
            (selectedChat.receiverId === n.from || selectedChat.initiatorId === n.from) &&
            selectedChat.contextType === n.contextType &&
            selectedChat.context.entityId === n.contextId
          ) {
            messagesToFetch.push(n.entityId);
          }

          const relation = getRelationForNotification(relations, n);
          if (relation) {
            const relationKey = getKey('relation', relation.entityId);
            const contextKey = getKey(
              n.contextType,
              n.contextId,
              relation.initiatorId,
              relation.receiverId
            );
            dispatch(myCoachActions.setUnreadMessages(relationKey, contextKey, 1));
          }
          handledNotifications.push(n.id);
        });

        dispatch(Actions.removeNotifications(handledNotifications));
        setRefreshMessages(messagesToFetch);
        setHandlingNotifications(false);
      }
    };

    if (notifications.length && !handlingNotifications) {
      handleNotification();
    }
  }, [relations, notifications, handlingNotifications, selectedChat, dispatch]);

  return selectedChat ? (
    <Section id="conversations" title={t('myCoach.conversations.title')}>
      <inspiration.InspirationDialogProvider>
        <CoachChat
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          refreshMessages={refreshMessages}
          setRefreshMessages={setRefreshMessages}
        />
      </inspiration.InspirationDialogProvider>
    </Section>
  ) : null;
};

export default ChatWrapper;
