import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Rating from '../../../components/Rating';

type Props = {
  quote: string;
  person: string;
  value: number;
  name: string;
};

const useStyles = makeStyles(() => ({
  sliderContentRoot: {
    marginTop: '5rem',
  },
  bodyText: {
    marginTop: '2rem',
  },
  quote: {
    marginBottom: '2rem',
  },
}));

const SliderContent = ({ quote, person, value, name }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.sliderContentRoot}>
      <Typography variant="h5" className={classes.quote}>
        {quote}
      </Typography>
      <Rating value={value} name={name} canBeEdited={false} />
      <Typography variant="body2" className={classes.bodyText}>
        {person}
      </Typography>
    </div>
  );
};

export default SliderContent;
