import React from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../../components/Section';
import { GroupRelation, GroupsResponse } from '../../../../config/api/models';
import CreateOrUpdateGroupButton from './CreateOrUpdateGroupButton';
import { SectionButton, SectionButtonContainer } from '../Atoms';
import AddCoacheesButton from './AddCoacheesButton';
import MyGroupCoachees from '../../containers/myGroups/MyGroupCoachees';

type Props = {
  group?: GroupsResponse['data'][number];
};
const MyGroupsGroup = ({ group }: Props) => {
  const { t } = useTranslation();

  if (!group) {
    return null;
  }

  const relationIdsInGroup = group.relationships.relations.data.map((relation: GroupRelation) =>
    parseInt(relation.id, 10)
  );

  return (
    <Section
      id="coachGroup"
      title={group.attributes.name}
      headerButton={
        <SectionButtonContainer>
          <SectionButton>
            <CreateOrUpdateGroupButton groupId={group.id} groupName={group.attributes.name} />
          </SectionButton>
          <SectionButton>
            <AddCoacheesButton
              groupId={group.id}
              groupName={group.attributes.name}
              groupRelationIds={relationIdsInGroup}
            />
          </SectionButton>
        </SectionButtonContainer>
      }
    >
      {/* @NOTE: using <p> instead of Typography to keep styling in line with coachRelationOverviewTitle (see CoachRelationOverview). There is no p override in theme, nor a reusable component for view subtitles like this so fuck it */}
      <p>
        {t('myCoach.myGroups.numCoacheesLong', {
          count: group.relationships.relations.data.length,
        })}
      </p>

      {group.relationships.relations.data.length ? <MyGroupCoachees group={group} /> : null}
    </Section>
  );
};

export default MyGroupsGroup;
