import ContentBlock from '../../components/ContentBlock';
import EditablePanel from '../../../../components/EditablePanel';
import React, { useEffect, useState } from 'react';
import UpdateDreamForm from './UpdateDreamForm';
import useFetch from '../../../../helpers/useFetch';
import {
  CONTEXT_TYPE_DREAM,
  DreamId,
  DreamResponse,
  FileDTO,
  FilesResponse,
  InspirationContext,
} from '../../../../config/api/models';
import { ApiConfig } from '../../api';
import { ApiConfig as SharedApiConfig } from '../../../shared/api';
import Sidebar from '../../../../components/Sidebar';
import ReactContentLoader from '../../../../components/Loader';
import inspiration from '../../../inspiration';
import FileList from '../../../shared/components/FileList';
import SidebarButton from '../../../../components/SidebarButton';
import { Icons } from '../../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import CommunicationWrapper from '../../components/CommunicationWrapper';
import { useCompassUser } from '../../../shared/hooks';
import FormError from '../../../../components/forms/FormError';
import { useSelector } from 'react-redux';
import { getCompassState, getCompassUserId } from '../../selectors';
import { CompassState } from '../../model';
import ShowcaseSidebarOptions, {
  ShowcaseSidebarValues,
} from '../../../myShowcase/components/ShowcaseSidebarOptions';
import { getMilaUserId } from '../../../shared/selectors';
import DateTimeMessage from '../../../shared/components/DateTimeMessage';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import CreateRelationDialog from '../../../shared/components/CreateRelationDialog';

type Props = {
  dreamId: DreamId;
  children: React.ReactElement;
};

const loadPostsConfig = SharedApiConfig.posts();

const UpdateDream = ({ dreamId, children }: Props) => {
  const modelType = 'dream';
  const [dreamResponse, fetchDream] = useFetch<DreamResponse>(ApiConfig.loadDream(dreamId));
  const [, updateDream] = useFetch<DreamResponse>(ApiConfig.updateDream(dreamId));
  const [filesResponse, fetchFile] = useFetch<FilesResponse>(
    ApiConfig.loadFile(modelType, dreamId ? dreamId : '')
  );

  const [, fetchPosts] = useFetch(loadPostsConfig);

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const compassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const compassState = useSelector(getCompassState);
  const milaUserId = useSelector(getMilaUserId);
  const history = useHistory();

  useEffect(() => {
    if (!compassUser) {
      fetchPosts();
    }
  }, [fetchPosts, compassUser]);

  // eslint-disable-next-line
  useEffect(fetchDream, [history.location.state]); //Refetch when clicked on notification -> history.location.state will be changed

  useEffect(() => {
    if (!filesResponse) {
      fetchFile();
    }
  }, [fetchFile, filesResponse]);

  const value = dreamResponse && dreamResponse.value && dreamResponse.value;
  const allFiles: FileDTO[] =
    (filesResponse && filesResponse.value && filesResponse.value.data.map(d => d.attributes)) || [];

  const handleShowcaseChange = (values: ShowcaseSidebarValues) => {
    updateDream(values);
  };

  const getSidebar = () => {
    switch (compassState) {
      case CompassState.MYCOMPASS:
        return (
          <Sidebar>
            <inspiration.InspirationSidebarButton id="dream-inspiration-button" />
            <SidebarButton
              id="add-coach-button"
              icon={Icons.COACH}
              primary={t('myCoach.invite.button')}
              onClick={() => setOpen(true)}
            />
          </Sidebar>
        );
      case CompassState.COMPASS:
        return (
          <Sidebar>
            <inspiration.InspirationSidebarButton
              id="dream-inspiration-button"
              disabled={compassUserId === milaUserId}
            />
            <SidebarButton
              id="dream-coach-button"
              icon={Icons.COACH}
              primary={t('myCoach.invite.button')}
              onClick={() => setOpen(true)}
              disabled={compassUserId === milaUserId}
            />
          </Sidebar>
        );
      case CompassState.MYSHOWCASE:
        return (
          <Sidebar id="dream-sidebar">
            <ShowcaseSidebarOptions
              onChange={values => handleShowcaseChange(values)}
              values={{
                includeShowcase: value ? value.data.attributes.includeShowcase : false,
                includeShowcaseComments: value
                  ? value.data.attributes.includeShowcaseComments
                  : false,
                includeShowcaseFiles: value ? value.data.attributes.includeShowcaseFiles : false,
              }}
              disabled={compassUserId === milaUserId}
            />
          </Sidebar>
        );
      default:
        return undefined;
    }
  };

  return (
    <>
      <inspiration.InspirationDialogProvider>
        <EditablePanel
          id="dream"
          showQuoteIcon={!(dreamResponse && dreamResponse.rejected)}
          showToggleButton={compassState === CompassState.MYCOMPASS}
          view={
            value ? (
              <>
                <ContentBlock
                  id="dream-content"
                  content={{
                    body: value.data.attributes.body,
                    title: value.data.attributes.title,
                  }}
                  inspirationKey={InspirationContext.COACHEE_DREAM}
                />
                <FileList files={allFiles} loading={filesResponse && filesResponse.pending} />
                <Box display="flex" justifyContent="flex-end">
                  <DateTimeMessage
                    createdAt={value.data.attributes.createdAt}
                    updatedAt={value.data.attributes.updatedAt}
                  />
                </Box>
              </>
            ) : dreamResponse && dreamResponse.rejected ? (
              <FormError>{t('error.private')}</FormError>
            ) : (
              <ReactContentLoader />
            )
          }
          form={
            value ? (
              <UpdateDreamForm
                dream={value.data.attributes}
                dreamId={value.data.id}
                files={allFiles}
              />
            ) : (
              <ReactContentLoader />
            )
          }
          sidebar={getSidebar()}
          suffixComponent={
            value ? (
              <CommunicationWrapper
                context={value.data.attributes}
                contextType="dream"
                disabled={
                  compassState === CompassState.SHOWCASE || compassState === CompassState.MYSHOWCASE
                }
              />
            ) : (
              undefined
            )
          }
        />
        {compassState === CompassState.MYCOMPASS && (
          <CreateRelationDialog
            contextType={CONTEXT_TYPE_DREAM}
            contextId={dreamId as string}
            open={open}
            onClose={() => setOpen(false)}
            dialogType="coach"
          />
        )}
      </inspiration.InspirationDialogProvider>
      {value &&
        React.cloneElement(children, {
          focusIdsApiLink: value.data.relationships.focusses.links.self,
        })}
    </>
  );
};

export default UpdateDream;
