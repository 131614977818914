import React from 'react';
import Section, { SectionProps } from '../../../../components/Section';
import { useSelector } from 'react-redux';
import { getDreamTitleByDreamId, getSelectedFocusTitle } from '../../selectors';
import { useParams } from 'react-router-dom';

const FocusSection = (props: SectionProps) => {
  const { dreamId } = useParams();

  const selectedDreamTitle = useSelector(getDreamTitleByDreamId(dreamId));
  const selectedFocusTitle = useSelector(getSelectedFocusTitle);

  const subTitle = `${selectedDreamTitle} / ${selectedFocusTitle}`;

  return <Section context={subTitle} {...props} />;
};

export default FocusSection;
