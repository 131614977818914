import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { default as themeConfig } from '../../../config/theme';
import ChatMessageFeed from '../../../components/chat/ChatMessageFeed';
import ChatActions from '../../../components/chat/ChatActions';
import useUser from '../../security/hooks/useUser';
import ContactList from '../../myCoach/components/ContactList';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../../shared/api';
import {
  RelationsResponse,
  Dream,
  Focus,
  User,
  ChatsResponse,
  ContextType,
  ChatMessage,
  RelationContact,
  ChatSocketNotification,
} from '../../../config/api/models';
import {
  getContactList,
  groupRelationsByRelationType,
  getKey,
} from '../../myCoach/helpers/helperFunctions';
import { RelationOverviewProps } from '../../myCoach/models';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../myCoach/actions';
import { getUnreadMessages } from '../../myCoach/selectors';
import contactSort from '../../myCoach/helpers/contactSort';
import { useMediaQuery } from '@material-ui/core';
import ChatMobile from '../../../components/chat/ChatMobile';
import { getAcceptedContactsForContext } from '../helpers/helperFunctions';
import { unionBy } from 'lodash';

type Props = {
  context: Dream | Focus;
  contextType: ContextType;
  disabled?: boolean;
  setSelectedContact: (user?: User) => void;
  messagesToFetch: ChatSocketNotification[];
  canComment?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  chatRoot: {
    display: 'flex',
  },
  chat: {
    flexGrow: 1,
    borderLeft: theme.config.defaultBorder,
  },
  messageFeed: {
    height: '45rem',
  },
}));

const CompassChatWrapper = ({
  context,
  contextType,
  disabled,
  setSelectedContact,
  messagesToFetch,
  canComment,
}: Props) => {
  const classes = useStyles();
  const currentUser = useUser();
  const dispatch = useDispatch();
  const unreadMessagesMap = useSelector(getUnreadMessages);
  const messageScrollContainer = useRef<HTMLDivElement>(null);

  const [selectedRelation, setSelectedRelation] = useState<RelationOverviewProps | undefined>();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [contacts, setContacts] = useState<RelationContact[]>([]);
  const [unseenMessages, setUnseenMessages] = useState<boolean>(false);

  const [relationsRequest] = useFetch<RelationsResponse>(ApiConfig.relations());
  const [addMessageRequest, addMessage] = useFetch(ApiConfig.addMessage());
  const [, putMessagesSeen] = useFetch(ApiConfig.putMessagesSeen());
  const [chatRequest, fetchChat] = useFetch<ChatsResponse>(
    ApiConfig.chat(contextType, context.entityId)
  );
  const [chatsByIdRequest, fetchChatsById] = useFetch<ChatsResponse>(ApiConfig.messagesById());

  const isMobile = useMediaQuery(themeConfig.breakpoints.down('sm'));

  // Handle messages state
  useEffect(() => {
    if (chatRequest && chatRequest.value && !chatRequest.pending) {
      setMessages(chatRequest.value.data.map(d => d.attributes));
      setUnseenMessages(true);
    }
  }, [chatRequest, setMessages]);

  useEffect(() => {
    if (chatsByIdRequest && chatsByIdRequest.value && !chatsByIdRequest.pending) {
      const newChats = chatsByIdRequest.value.data
        .map(d => d.attributes)
        .filter(
          message =>
            selectedRelation &&
            selectedRelation.user.entityId === message.senderId &&
            message.contextType === contextType
        );
      setMessages(c => unionBy(c, newChats, v => [v.entityId, v.createdAt, v.body].join()));
    }
  }, [chatsByIdRequest, contextType, selectedRelation]);

  // Scroll behaviour
  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  // Handle contacts
  useEffect(() => {
    if (relationsRequest && relationsRequest.value) {
      const { coaches } = groupRelationsByRelationType(
        relationsRequest.value.data.map(r => r.attributes),
        currentUser
      );
      const contextCoaches = getAcceptedContactsForContext(coaches, context, contextType);
      setContacts(getContactList(contextCoaches, currentUser));
    }
  }, [relationsRequest, setContacts, currentUser, context, contextType]);

  // fetch messages when notifications change
  useEffect(() => {
    if (messagesToFetch.length) {
      fetchChatsById(messagesToFetch.map(n => n.entityId));
    }
  }, [messagesToFetch, messagesToFetch.length, fetchChatsById]);

  // handle unread messages
  useEffect(() => {
    if (contacts.length && selectedRelation && unseenMessages) {
      const currentContact = contacts.find(c =>
        c.relation.initiatorId === currentUser.entityId
          ? c.relation.receiverId === selectedRelation.user.entityId
          : c.relation.initiatorId === selectedRelation.user.entityId
      );
      if (currentContact) {
        const relationKey = getKey('relation', currentContact.relation.entityId);
        const contextKey = getKey(
          contextType,
          context.entityId,
          currentContact.relation.initiatorId,
          currentContact.relation.receiverId
        );
        if (unreadMessagesMap[contextKey] && messages.length) {
          const unreadMessages = messages.filter(m => !m.seenAt);
          dispatch(Actions.setMessagesSeen(relationKey, contextKey));
          putMessagesSeen(unreadMessages.map(message => message.entityId));
          setUnseenMessages(false);
        }
      }
    }
  }, [
    contacts,
    selectedRelation,
    chatRequest,
    context,
    contextType,
    dispatch,
    fetchChat,
    messages,
    putMessagesSeen,
    unreadMessagesMap,
    currentUser,
    unseenMessages,
  ]);

  // replace new chat message with the returned POST value
  useEffect(() => {
    if (addMessageRequest && addMessageRequest.fulfilled && addMessageRequest.value) {
      const addedMessage = addMessageRequest.value.data.attributes;
      setMessages(ms =>
        ms.map(m => (!m.entityId && m.body === addedMessage.body ? addedMessage : m))
      );
    }
  }, [addMessageRequest]);

  if (!relationsRequest || !relationsRequest.value) {
    return null;
  }

  const handleSelectRelation = (user?: User) => {
    if (user) {
      setSelectedRelation({ user, role: 'coach' });
      fetchChat(currentUser.entityId, user.entityId);
      setSelectedContact(user);
    } else {
      setSelectedRelation(undefined);
      setSelectedContact(undefined);
    }
    setMessages([]);
  };

  const handleSendMessage = (message: string) => {
    if (selectedRelation) {
      const messageDto = {
        contextType,
        senderId: currentUser.entityId,
        receiverId: selectedRelation.user.entityId,
        body: message,
      };
      const newMessage = {
        ...messageDto,
        context,
        sender: currentUser,
        receiver: selectedRelation.user,
      } as ChatMessage;
      addMessage({ ...newMessage, contextId: context.entityId });
      setMessages([...messages, newMessage]);
      setUnseenMessages(true);
    }
  };

  const scrollToBottom = () => {
    if (messageScrollContainer.current) {
      messageScrollContainer.current.scrollTop = messageScrollContainer.current.scrollHeight;
    }
  };

  // Set initial selected relation
  if (contacts.length && !isMobile && !selectedRelation) {
    const firstRelation = contacts.sort(contactSort)[0].relation;
    const coach =
      firstRelation.receiverId === currentUser.entityId
        ? firstRelation.initiator
        : firstRelation.receiver;
    handleSelectRelation(coach);
  }

  const loadingChat = !chatRequest || (chatRequest.pending && !messages.length);
  const disableChat = () => {
    if (selectedRelation && !disabled && canComment) {
      const currentContact = contacts.find(c =>
        c.relation.initiatorId === currentUser.entityId
          ? c.relation.receiverId === selectedRelation.user.entityId
          : c.relation.initiatorId === selectedRelation.user.entityId
      );
      return currentContact ? currentContact.archived : true;
    }
    return true;
  };

  return (
    <div className={classes.chatRoot}>
      <ContactList
        contacts={contacts}
        selectedRelation={selectedRelation}
        onLoadRelation={handleSelectRelation}
        fullWidth={isMobile}
        contextType={contextType}
        context={context}
        searchValue=""
      />
      {isMobile ? (
        <ChatMobile
          open={Boolean(selectedRelation)}
          loadingChat={loadingChat}
          onToggle={() => handleSelectRelation()}
          messages={messages}
          handleSendMessage={handleSendMessage}
          ref={messageScrollContainer}
          otherUser={selectedRelation ? selectedRelation.user : undefined}
          disabled={disableChat()}
          setMessages={setMessages}
        />
      ) : (
        <div className={classes.chat}>
          <div className={classes.messageFeed}>
            <ChatMessageFeed
              messages={messages}
              currentUser={currentUser}
              loading={loadingChat}
              ref={messageScrollContainer}
              setMessages={setMessages}
            />
          </div>

          {!disableChat() && (
            <ChatActions
              avatar={currentUser.media.profile}
              onSend={handleSendMessage}
              position="bottom"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CompassChatWrapper;
