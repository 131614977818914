import React from 'react';
import { default as themeConfig } from '../../../config/theme';
import Navigation from '../../profile/components/Navigation';
import Dream from '../../myCompass/containers/Dream/Dream';
import FocusWrapper from '../../myCompass/containers/Focus/FocusWrapper';
import FocusSection from '../../myCompass/containers/Focus/FocusSection';
import LearnWrapper from '../../myCompass/containers/Learn/LearnWrapper';
import LookBack from '../../myCompass/containers/LookBack/LookBack';
import MilestoneWrapper from '../../myCompass/containers/Milestone/MilestoneWrapper';
import SaveShowcaseBanner from '../components/SaveShowcaseBanner';
import WithStickyUserMenu from '../../profile/components/WithStickyUserMenu';
import { useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';

const navigation = ['dream', 'focus', 'learn', 'lookBack', 'milestone'];

const ShowcaseDreamDetail = () => {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const { dreamId } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hash = window.location.hash.replace('#i-', '');

  const activeMenuItemIndex = navigation.findIndex(navItem => hash === navItem.toLowerCase());

  const navItems = navigation.map(navItem => (
    <Link smooth to={`#i-${navItem.toLowerCase()}`} key={navItem}>
      {t(`${navItem}.menuItem`)}
    </Link>
  ));

  return (
    <WithStickyUserMenu
      profilePictureEditable={false}
      color={themeConfig.palette.myCompass.red}
      show
      navigation={{ navItems, activeMenuItemIndex }}
    >
      {isMobile && <Navigation navItems={navItems} color={themeConfig.palette.myCompass.red} />}

      <Dream selectedDream={dreamId}>
        <FocusWrapper dreamId={dreamId}>
          <FocusSection id="i-learn" title="iLearn" tooltip={t('learn.inspiration')}>
            <LearnWrapper />
          </FocusSection>
          <FocusSection id="i-lookback" title="iLookBack" tooltip={t('lookBack.inspiration')}>
            <LookBack />
          </FocusSection>
          <MilestoneWrapper />
        </FocusWrapper>
      </Dream>

      {isMobile && <SaveShowcaseBanner />}
    </WithStickyUserMenu>
  );
};

export default ShowcaseDreamDetail;
