import React, { useState, useEffect } from 'react';
import LookBackForm from './LookBackForm';
import useFetch from '../../../../helpers/useFetch';
import { LookBackResponse } from '../../../../config/api/models';
import { ApiConfig } from '../../api';
import { LookBackSubmitValues } from '../../model';
import useFilesPersist from '../../../shared/hooks/useFilesPersist';
import { useSelector } from 'react-redux';
import { getPersistingFiles } from '../../../shared/selectors';

type Props = {
  onCancel?: () => void;
  onSubmit: () => void;
  visibility: boolean;
};

const addLookBackConfig = ApiConfig.addLookBack();

const NewLookBackForm = ({ onSubmit, onCancel, visibility }: Props) => {
  const [lookBackId, setLookBackId] = useState<string>('');
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);

  const [addLookBackResponse, addLookBack] = useFetch<LookBackResponse>(addLookBackConfig);
  const persistFiles = useFilesPersist('lookback', lookBackId, onSubmit);
  const persistingFiles = useSelector(getPersistingFiles);

  useEffect(() => {
    if (addLookBackResponse && addLookBackResponse.value) {
      if (filesToAdd.length) {
        setLookBackId(addLookBackResponse.value.data.id);
        if (lookBackId) {
          setFilesToAdd([]);
          persistFiles({
            filesToAdd,
            fileIdsToDelete: [],
          });
        }
      } else if (!persistingFiles) {
        onSubmit();
      }
    }
  }, [addLookBackResponse, filesToAdd, lookBackId, persistFiles, onSubmit, persistingFiles]);

  const handleSave = (values: LookBackSubmitValues) => {
    setFilesToAdd(values.filesToAdd);
    addLookBack(values.request);
  };

  return (
    <LookBackForm
      onSave={handleSave}
      onClose={onCancel}
      lookBackResponse={addLookBackResponse}
      visibility={visibility}
    />
  );
};

export default NewLookBackForm;
