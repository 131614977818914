import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import theme from '../../../config/theme';
import Dromen from '../../../assets/dromen.png';
import Coaching from '../../../assets/coaching.png';
import Network from '../../../assets/network.png';
import Showcase from '../../../assets/showcase.png';
import clsx from 'clsx';
import HomePageSectionPaper from '../../../components/HomePageSectionPaper';
import { useMediaQuery } from '@material-ui/core';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  sectionThreeRoot: (props: StyleProps) => ({
    marginLeft: '-3000px',
    paddingLeft: '3000px',
    marginRight: '-3000px',
    paddingRight: '3000px',
    paddingTop: '20rem',
    paddingBottom: props.isMobile ? '15rem' : '20rem',
    marginTop: '-25rem',
  }),
  sectionThreeDream: {
    clipPath: 'polygon(0 38%, 100% 0, 100% 100%, 0 100%)',
    backgroundColor: theme.palette.myCompass.green,
    marginTop: '-10rem !important',
  },
  sectionThreeCoaching: {
    clipPath: 'polygon(0 0, 100% 38%, 100% 100%, 0 100%)',
    backgroundColor: theme.palette.myCompass.blue,
  },
  mobile: {
    marginTop: '28rem !important',
  },
  sectionThreeNetwork: {
    clipPath: 'polygon(0 38%, 100% 0, 100% 100%, 0 100%)',
    backgroundColor: theme.palette.myCompass.yellow,
  },
  sectionThreeShowcase: {
    clipPath: 'polygon(0 0, 100% 38%, 100% 65%, 0 100%)',
    backgroundColor: theme.palette.myCompass.red,
  },
  left: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '50%',
  }),
  right: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '50%',
    marginTop: props.isMobile ? '20rem' : '',

    '& > img': {
      width: props.isMobile ? '50%' : '70%',
    },
  }),
  reverse: {
    flexDirection: 'row-reverse',
  },
  relate: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const SectionThree = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  return (
    <div id="sectionThree">
      <div className={clsx(classes.sectionThreeRoot, classes.sectionThreeDream)}>
        <div className={classes.relate}>
          <div className={classes.left}>
            <HomePageSectionPaper
              color={theme.palette.myCompass.green}
              title={t('home.sectionThree.dromen.title')}
              body={t('home.sectionThree.dromen.text')}
              first
            />
          </div>
          <div className={classes.right}>
            <img src={Dromen} alt={t('home.sectionThree.dromen.alt')} />
          </div>
        </div>
      </div>
      <div className={clsx(classes.sectionThreeRoot, classes.sectionThreeCoaching)}>
        <div className={clsx(classes.relate, classes.reverse)}>
          <div className={classes.left}>
            <HomePageSectionPaper
              color={theme.palette.myCompass.blue}
              title={t('home.sectionThree.coaching.title')}
              body={t('home.sectionThree.coaching.text')}
              alignRight
            />
          </div>
          <div className={clsx(isMobile && classes.mobile, classes.right)}>
            <img src={Coaching} alt={t('home.sectionThree.coaching.alt')} />
          </div>
        </div>
      </div>
      <div className={clsx(classes.sectionThreeRoot, classes.sectionThreeNetwork)}>
        <div className={classes.relate}>
          <div className={classes.left}>
            <HomePageSectionPaper
              color={theme.palette.myCompass.yellow}
              title={t('home.sectionThree.network.title')}
              body={t('home.sectionThree.network.text')}
            />
          </div>
          <div className={classes.right}>
            <img src={Network} alt={t('home.sectionThree.network.alt')} />
          </div>
        </div>
      </div>
      <div className={clsx(classes.sectionThreeRoot, classes.sectionThreeShowcase)}>
        <div className={clsx(classes.relate, classes.reverse)}>
          <div className={classes.left}>
            <HomePageSectionPaper
              color={theme.palette.myCompass.red}
              title={t('home.sectionThree.showcase.title')}
              body={t('home.sectionThree.showcase.text')}
              alignRight
            />
          </div>
          <div className={classes.right}>
            <img src={Showcase} alt={t('home.sectionThree.showcase.alt')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
