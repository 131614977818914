import React from 'react';
import { Theme, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Title from '../../../../components/Title';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import Html from '../../../../components/Html';
import { useSelector } from 'react-redux';
import { getCompassState, getCompassUserInfo } from '../../selectors';
import { CompassState } from '../../model';
import { getUserDisplayName } from '../../../../helpers/getUserDisplayName';

type Props = {
  onCreateDream: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dreamRoot: {
    width: 'calc(100% - ' + theme.config.sidebarWidth + ')',
    [theme.breakpoints.down('xs')]: {
      padding: '0 2rem',
    },
  },
  dreamPaper: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addDreamButton: {
    width: '100%',
    padding: '1rem',
  },
  introDreamRoot: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '2rem',
      marginRight: '2rem',
    },
  },
}));

const IntroDream = ({ onCreateDream }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const compassState = useSelector(getCompassState);
  const compassUser = useSelector(getCompassUserInfo);

  switch (compassState) {
    case CompassState.MYSHOWCASE:
      return (
        <>
          <Html>{t('showcase.mySettings.noDream')}</Html>
          <Button link="myCompass">{t('showcase.mySettings.toCompass')}</Button>
        </>
      );
    case CompassState.MYCOMPASS:
      return (
        <div className={classes.introDreamRoot}>
          <div id="reactour-first-step">
            <Title>{t('dream.introTitle')}</Title>
          </div>
          <Html>{t('dream.intro')}</Html>
          <Button
            onClick={onCreateDream}
            icon={Icons.PLUS}
            className={classes.addDreamButton}
            id="add-dream-button"
          >
            {t('dream.add')}
          </Button>
        </div>
      );
    default:
      return (
        <Paper className={classes.dreamPaper}>
          {t('compassPage.noDream', {
            name: getUserDisplayName(compassUser, true),
          })}
        </Paper>
      );
  }
};

export default IntroDream;
