import React, { useCallback } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InitialFocus from './InitialFocus';
import UpdateFocus from './UpdateFocus';
import NewFocus from './NewFocus';
import { FocusId, Maybe } from '../../../../config/api/models';
import { NEW_FOCUS_ID } from '../../model';
import { useCompassUser } from '../../../shared/hooks';
import { useTranslation } from 'react-i18next';

type Props = {
  focusId: Maybe<FocusId>;
  refetch: (initialFocusCreated?: boolean) => void;
  onCancel: () => void;
  isFirstFocus?: boolean;
  children: React.ReactChild | React.ReactChild[];
};

const useStyles = makeStyles((theme: Theme) => ({
  focusRoot: {
    marginTop: '4rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '2rem',
    },
  },
}));

const Focus = ({ focusId, refetch, onCancel, isFirstFocus = false, children }: Props) => {
  const classes = useStyles();
  const isCompassUser = useCompassUser();
  const { t } = useTranslation();

  const handleInitialFocusSubmit = useCallback(() => {
    refetch(true);
  }, [refetch]);

  return (
    <div className={classes.focusRoot}>
      {isFirstFocus ? (
        isCompassUser ? (
          <>{t('focus.noFocusses')}</>
        ) : (
          <InitialFocus onSubmit={handleInitialFocusSubmit} />
        )
      ) : (
        <>
          {focusId === NEW_FOCUS_ID ? (
            <NewFocus onSubmit={refetch} onCancel={onCancel} />
          ) : (
            <UpdateFocus focusId={focusId} children={children} />
          )}
        </>
      )}
    </div>
  );
};

export default Focus;
