import { useEffect, useState } from 'react';
import { LoginRequest, LoginResponse } from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { PromiseState } from 'react-redux-fetch';

const useAutoLogin = (promiseState?: PromiseState) => {
  const [loginData, setLoginData] = useState<LoginRequest>();
  const [loginResponse, login] = useFetch<LoginResponse>(ApiConfig.login());

  useEffect(() => {
    // When the registration process is finished, login the user.
    if (promiseState && promiseState.fulfilled) {
      login(loginData);
    }
  }, [promiseState, loginData, login]);

  return {
    setLoginData,
    loginResponse,
  };
};

export default useAutoLogin;
