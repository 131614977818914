import React from 'react';
import { AppBar, Tabs, Tab, Theme, useMediaQuery, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FocusId, Maybe } from '../../../../config/api/models';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import theme from '../../../../config/theme';
import TextField from '../../../../components/TextField';

export type TabValue = {
  tabName: string;
  tabIndex: number;
  tabValue: FocusId;
  isClosed: boolean;
  isSuccessful: boolean;
};

type Props = {
  tabs: TabValue[];
  onChange: (focusId: FocusId) => void;
  selectedTab: Maybe<string>;
};

const useStyles = makeStyles((theme: Theme) => ({
  focusTabsBarRoot: {
    position: 'relative',
    flexGrow: 1,
    width: 'calc(100% + 8rem)',
    marginLeft: '-4rem',
  },
  gradientBackground: {
    position: 'absolute',
    top: '5.9rem',
    left: '-200rem',
    width: 'calc(100% + 400rem)',
    height: '20rem',
    [theme.breakpoints.up('xs')]: {
      borderTop: theme.config.defaultBorder,
      background: 'linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
    },
  },
  focusAppBar: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    border: 'none',
  },
  focusTabsRoot: {
    '& .MuiTabs-scrollButtons.MuiButtonBase-root': {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        width: '4rem',
        height: '100%',
        zIndex: 999,
      },
      '&:first-child::before': {
        left: '4rem',
        background: 'linear-gradient(to right, rgba(248,248,248,1) 0%,rgba(248,248,248,0) 100%)',
      },
      '&:last-child::before': {
        right: '4rem',
        background: 'linear-gradient(to left, rgba(248,248,248,1) 0%,rgba(248,248,248,0) 100%)',
      },
    },
  },
  focusTabRoot: {
    fontWeight: 300,
    border: theme.config.defaultBorder,
    borderBottom: 'none',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    height: '6rem',
    marginRight: '1rem',
    backgroundColor: theme.palette.grey['200'],
    padding: '0 3rem 0 2rem',
    '& > span': {
      alignItems: 'flex-start',
    },
  },
  focusTabSelected: {
    fontWeight: 700,
    backgroundColor: theme.palette.common.white,
  },
  focusTabsIndicator: {
    display: 'none',
  },
  successIcon: {
    color: theme.palette.tertiary.main,
    position: 'absolute',
    right: 8,
    top: 12,
    width: '1.4rem',
    height: '1.4rem',
  },
  closedIcon: {
    color: theme.palette.grey['500'],
    position: 'absolute',
    right: 8,
    top: 12,
    width: '1.4rem',
    height: '1.4rem',
  },
}));

const FocusTabsBar = ({ tabs, onChange, selectedTab }: Props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  if (isMobile) {
    return (
      <TextField
        select
        onChange={event => onChange(event.target.value as FocusId)}
        value={selectedTab}
      >
        {tabs.map(tab => (
          <MenuItem value={tab.tabValue} key={tab.tabValue}>
            {tab.tabName}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  const getSelectedTabValue = () => {
    if (selectedTab && tabs.length) {
      const index = tabs.findIndex(tab => tab.tabValue === selectedTab);
      return index >= 0 ? index : 0;
    }
    return 0;
  };

  return (
    <div className={classes.focusTabsBarRoot}>
      <AppBar position="static" className={classes.focusAppBar}>
        <div className={classes.gradientBackground} />
        <Tabs
          value={getSelectedTabValue()}
          onChange={(event, value) => onChange(tabs[value].tabValue)}
          variant="scrollable"
          scrollButtons="on"
          color="background"
          indicatorColor="secondary"
          classes={{
            root: classes.focusTabsRoot,
            indicator: classes.focusTabsIndicator,
          }}
        >
          {tabs.map((item: TabValue) => (
            <Tab
              key={item.tabIndex}
              // tabIndex={parseInt(item.tabValue, 10)}
              label={
                <span>
                  {item.tabName}
                  {item.isClosed && (
                    <div className={item.isSuccessful ? classes.successIcon : classes.closedIcon}>
                      <SvgIcon icon={item.isSuccessful ? Icons.CHECK_CIRCLE : Icons.CANCEL} />
                    </div>
                  )}
                </span>
              }
              classes={{
                root: classes.focusTabRoot,
                selected: classes.focusTabSelected,
              }}
              wrapped
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
};

export default FocusTabsBar;
