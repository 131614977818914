type Options = {
  maxWidth: number;
  maxHeight: number;
  quality?: number; // 0 -> 1
};

type Callback = (base64: string) => void;
type Base64String = string;

const getFileType = (fileReaderResult: Base64String) => {
  const fileTypeParts = fileReaderResult.match(/^data:(.*);/);
  return fileTypeParts && fileTypeParts.length >= 2 ? fileTypeParts[1] : 'image/jpeg';
};

const getDimensions = (
  naturalWidth: number,
  naturalHeight: number,
  maxWidth: number,
  maxHeight: number
) => {
  const widthRatio = naturalWidth / maxWidth;
  const heightRatio = naturalHeight / maxHeight;
  if (widthRatio > heightRatio) {
    const width = maxWidth;
    const scaleFactor = width / naturalWidth;
    const height = naturalHeight * scaleFactor;

    return { width, height };
  } else {
    const height = maxHeight;
    const scaleFactor = height / naturalHeight;
    const width = naturalWidth * scaleFactor;

    return { width, height };
  }
};

const resizeImage = (fileReaderResult: Base64String, options: Options, callback: Callback) => {
  const { maxWidth, maxHeight, quality = 1 } = options;

  const img = new Image();

  img.onload = () => {
    const { naturalWidth, naturalHeight } = img;

    if (naturalWidth > maxWidth || naturalHeight > maxHeight) {
      const fileType = getFileType(fileReaderResult);
      const elem = document.createElement('canvas');
      const { width, height } = getDimensions(naturalWidth, naturalHeight, maxWidth, maxHeight);

      elem.width = width;
      elem.height = height;

      const ctx = elem.getContext('2d');
      ctx!.drawImage(img, 0, 0, width, height);

      ctx!.canvas.toBlob(
        blob => {
          if (!blob) {
            console.warn('No blob!');
            return;
          }
          const file = new File([blob], 'image', {
            type: fileType,
            lastModified: Date.now(),
          });

          const reader = new FileReader();
          reader.onload = () => {
            callback(reader.result as string);
          };
          reader.readAsDataURL(file);
        },
        fileType,
        quality
      );
    } else {
      callback(fileReaderResult);
    }
  };
  img.src = fileReaderResult;
};

export default resizeImage;
