import React from 'react';
import { Paper, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from '../../../config/theme';
import Html from '../../../components/Html';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Brochure from '../../../assets/brochure.png';
// @ts-ignore
import DownloadBrochure from '../../../documents/coachingsluik.pdf';
import { HashLink } from 'react-router-hash-link';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  sectionTwoRoot: (props: StyleProps) => ({
    backgroundColor: theme.palette.background.default,
    paddingTop: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: props.isMobile ? 'wrap' : 'wrap',
    position: 'relative',
  }),
  left: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '45%',
  }),
  right: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '45%',
    marginTop: props.isMobile ? '2rem' : '',
    '& > img': {
      width: props.isMobile ? '50%' : '100%',
    },
  }),
  sectionTwoTitle: {
    fontSize: '3rem',
    marginBottom: '3rem',
  },
  cta: {
    marginTop: '3rem',
  },
  subtitle: {
    fontWeight: 700,
    marginRight: '1.5rem',
  },
  brochure: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      zIndex: 1,
      top: '-23rem',
      left: '50rem',
      width: '80% !important',
    },
  },
  link: {
    color: '#D3D3D3',
  },
  brochureLink: {
    color: theme.palette.background.paper,
    textDecoration: 'none',
  },
  icon: (props: StyleProps) => ({
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: '0',
      zIndex: 2,
    },
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: props.isMobile ? '7rem' : '25rem',
  }),
  paperRight: (props: StyleProps) => ({
    width: props.isMobile ? '100%' : '70%',
    padding: '2rem',
    margin: props.isMobile ? '8rem 0 0 0' : '8rem auto 10rem auto',
  }),
}));

const SectionTwo = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  return (
    <div className={classes.sectionTwoRoot}>
      <div className={classes.left}>
        <div className={classes.sectionTwoTitle}>
          <Html>{t('coaching.sectionTwo.title')}</Html>
        </div>
        <Html>{t('coaching.sectionTwo.text')}</Html>
        <div className={classes.cta}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {t('coaching.sectionTwo.question')}
          </Typography>
          <Button icon={Icons.SAVE} blue>
            <a
              href={DownloadBrochure}
              target="_blank"
              className={classes.brochureLink}
              rel="noopener noreferrer"
            >
              {t('coaching.sectionOne.button')}
            </a>
          </Button>
        </div>
      </div>
      <div className={classes.right}>
        <img
          src={Brochure}
          alt={t('home.sectionThree.coaching.alt')}
          className={classes.brochure}
        />
      </div>
      <Paper className={classes.paperRight}>
        <Html>{t('coaching.metaphor-dream')}</Html>
      </Paper>
      <div className={classes.icon}>
        <HashLink smooth to="#sectionThree" className={classes.link}>
          <SvgIcon icon={Icons.CHEVRON_DOWN} size={5} />
        </HashLink>
      </div>
    </div>
  );
};

export default SectionTwo;
