import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type Props = {
  children: React.ReactNode;
  color?: string;
};

const useStyles = makeStyles(() => ({
  titleRoot: (props: Props) => ({
    fontWeight: 600,
    fontSize: props.color ? '2rem' : '1.6rem',
    marginBottom: '1.6rem',
    color: props.color ? props.color : '',
  }),
}));

const PaperTitle = (props: Props) => {
  const { children, color } = props;
  const classes = useStyles({ children, color });

  return (
    <Typography variant="h3" className={classes.titleRoot}>
      {children}
    </Typography>
  );
};

export default PaperTitle;
