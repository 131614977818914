import apiRoutes from '../../config/api/routes';
import { TalentRequest, TalentId } from '../../config/api/models';
import memoizeOne from 'memoize-one';

export const RESOURCE = {
  TALENTS: 'talents',
  TALENT: 'talent',
};

export const ApiConfig = {
  loadTalents: () => ({
    resource: RESOURCE.TALENTS,
    request: (url: string) => ({
      url,
    }),
  }),
  addTalent: () => ({
    resource: RESOURCE.TALENT,
    method: 'POST',
    request: (addTalentDtO: TalentRequest) => ({
      url: apiRoutes.talents(),
      body: {
        data: {
          type: 'talents',
          attributes: addTalentDtO,
        },
      },
    }),
  }),
  updateTalent: () => ({
    resource: RESOURCE.TALENT,
    method: 'PATCH',
    request: (talentId: TalentId, updateTalentDTO: TalentRequest) => ({
      url: apiRoutes.talents(talentId),
      body: {
        data: {
          type: 'talents',
          id: talentId,
          attributes: updateTalentDTO,
        },
      },
    }),
  }),
  deleteTalent: memoizeOne(() => ({
    resource: RESOURCE.TALENT,
    method: 'DELETE',
    request: (talentId: TalentId) => ({
      url: apiRoutes.talents(talentId),
    }),
  })),
};
