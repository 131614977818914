import apiRoutes from '../../config/api/routes';
import { InspirationContext } from '../../config/api/models';
import { upperFirst, camelCase, flow } from 'lodash/fp';
import { Language } from '../../config/i18n/types';

const pascalCase = flow(
  camelCase,
  upperFirst
);

const RESOURCE = Object.keys(InspirationContext).reduce((agg, key) => {
  return {
    ...agg,
    // @ts-ignore
    [InspirationContext[key]]: `inspiration${pascalCase(key)}`,
  };
}, {}) as Record<InspirationContext, string>;

export const ApiConfig = {
  getInspiration: (context: InspirationContext, language: Language) => ({
    resource: language + '-' + RESOURCE[context],
    request: {
      url: apiRoutes.inspiration(context, language),
    },
  }),
};
