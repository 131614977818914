import { AnyAction } from 'redux';
import { State } from './model';
import * as actionTypes from './actionTypes';

type SharedReducer = (state: State | undefined, action: AnyAction) => State;

const initialState: State = {
  blur: false,
  help: false,
  persistingFiles: undefined,
  notifications: [],
  milaUserId: '957',
  milaFirstDreamId: '353',
  milaFirstLearnId: '1686',
  dreamTourOpenFocusForm: false,
  milaCoachTourSwitchToCoachees: false,
  milaNetworkTourSwitchToComments: false,
  videosUploading: [],
  isChangingProfilePicture: false,
};

const sharedReducer: SharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENABLE_BLUR:
      return {
        ...state,
        blur: true,
      };
    case actionTypes.DISABLE_BLUR:
      return {
        ...state,
        blur: false,
      };
    case actionTypes.SET_PERSIST_FILES:
      return {
        ...state,
        persistingFiles: (action as any).payload,
      };
    case actionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case actionTypes.REMOVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.filter(n => !action.payload.includes(n.id)),
      };
    case actionTypes.ENABLE_HELP:
      return {
        ...state,
        help: true,
      };
    case actionTypes.DISABLE_HELP:
      return {
        ...state,
        help: false,
      };
    case actionTypes.ENABLE_DREAM_TOUR_OPEN_FOCUS_FORM:
      return {
        ...state,
        dreamTourOpenFocusForm: true,
      };
    case actionTypes.DISABLE_DREAM_TOUR_OPEN_FOCUS_FORM:
      return {
        ...state,
        dreamTourOpenFocusForm: false,
      };
    case actionTypes.ENABLE_MILA_COACH_TOUR_SWITCH_TO_COACHEES:
      return {
        ...state,
        milaCoachTourSwitchToCoachees: true,
      };
    case actionTypes.DISABLE_MILA_COACH_TOUR_SWITCH_TO_COACHEES:
      return {
        ...state,
        milaCoachTourSwitchToCoachees: false,
      };
    case actionTypes.ENABLE_MILA_NETWORK_TOUR_SWITCH_TO_COMMENTS:
      return {
        ...state,
        milaNetworkTourSwitchToComments: true,
      };
    case actionTypes.DISABLE_MILA_NETWORK_TOUR_SWITCH_TO_COMMENTS:
      return {
        ...state,
        milaNetworkTourSwitchToComments: false,
      };
    case actionTypes.UPDATE_PROGRESS_VIDEO:
      return {
        ...state,
        videosUploading: state.videosUploading.some(video => video.id === action.payload.id)
          ? action.payload.status === 'processed'
            ? state.videosUploading.filter(video => video.id !== action.payload.id) //Remove progressbar when video is processed
            : state.videosUploading.map(video => {
                // Update progressbar
                if (video.id === action.payload.id) {
                  return {
                    ...video,
                    ...(action.payload.progress != null && { progress: action.payload.progress }),
                    status: action.payload.status,
                  };
                } else {
                  return video;
                }
              })
          : [...state.videosUploading, action.payload], //Add new progressbar
      };
    case actionTypes.SET_IS_CHANGING_PROFILE_PICTURE:
      return {
        ...state,
        isChangingProfilePicture: action.payload,
      };
  }
  return state;
};

export default sharedReducer;
