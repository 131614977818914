import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Theme, useMediaQuery } from '@material-ui/core';
import SvgIcon, { Icons } from './SvgIcon';
import PaperTopButton from './PaperTopButton';
import PanelWithSidebar from './PanelWithSidebar';
import theme from '../config/theme';
import { useCompassUser } from '../modules/shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../modules/myCompass/selectors';
import { getMilaUserId } from '../modules/shared/selectors';

type Props = {
  id?: string;
  view: React.ReactElement | null;
  form: React.ReactElement;
  sidebar?: React.ReactElement;
  suffixComponent?: React.ReactElement;
  component?: React.ElementType;
  showToggleButton?: boolean;
  showQuoteIcon?: boolean;
  editable?: boolean;
  onToggle?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  quoteIcon: {
    position: 'absolute',
    top: '11.2rem',
    left: '2rem',
    color: theme.palette.highlight.main,
  },
  editButton: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    color: theme.palette.grey['400'],
    cursor: 'pointer',
    transitionProperty: 'color',
    transitionDuration: theme.transitions.duration.standard + 'ms',
    transitionTimingFunction: theme.transitions.easing.sharp,
    '&:hover': {
      color: theme.palette.grey['600'],
    },
  },
  edit: {
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      top: 0,
      bottom: 0,
    },
  },
  inspirationIcon: {
    cursor: 'pointer',
    marginLeft: '1rem',
    position: 'relative',
    color: theme.palette.grey['400'],
    transitionProperty: 'color',
    transitionDuration: theme.transitions.duration.standard + 'ms',
    transitionTimingFunction: theme.transitions.easing.sharp,
    '&:hover': {
      color: theme.palette.grey['600'],
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '2rem',
    top: '2rem',
  },
}));

const EditablePanel = (props: Props) => {
  const {
    id,
    view,
    form,
    sidebar,
    suffixComponent,
    component = Paper,
    editable = false,
    onToggle,
    showToggleButton = true,
    showQuoteIcon = true,
  } = props;
  const isPaper = component === Paper || editable;
  const classes = useStyles();
  const [localEditable, setEditable] = useState(editable);
  const [isSidebar, setSidebar] = useState(false);

  const localOnToggle = () => setEditable(!localEditable);

  const finalIsEditable = typeof onToggle === 'undefined' ? localEditable : editable;
  const finalOnToggle = typeof onToggle === 'undefined' ? localOnToggle : onToggle;

  const viewComponent = view;
  const formComponent = React.cloneElement(form, {
    onClose: () => {
      setEditable(false);
      if (form.props.onClose) {
        form.props.onClose();
      }
    },
  });

  const compassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isCompassUserButNotMila = compassUser && compassUserId !== milaUserId;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PanelWithSidebar
      sidebar={isCompassUserButNotMila ? undefined : sidebar}
      component={component}
      suffixComponent={suffixComponent}
    >
      <div id={id}>
        <div className={classes.flex}>
          {!compassUser && showToggleButton && (
            <PaperTopButton
              id={`edit-${id}-button`}
              onClick={finalOnToggle}
              className={classes.edit}
            >
              {finalIsEditable ? <SvgIcon icon={Icons.CANCEL} /> : <SvgIcon icon={Icons.EDIT} />}
            </PaperTopButton>
          )}
          {isMobile && !isCompassUserButNotMila && sidebar && (
            <div className={classes.inspirationIcon} onClick={() => setSidebar(!isSidebar)}>
              <SvgIcon icon={Icons.GEAR} />
              {isSidebar && sidebar}
            </div>
          )}
        </div>
        {finalIsEditable ? formComponent : viewComponent}

        {showQuoteIcon && isPaper && !finalIsEditable && (
          <div className={classes.quoteIcon}>
            <SvgIcon icon={Icons.QUOTE} />
          </div>
        )}
      </div>
    </PanelWithSidebar>
  );
};

export default EditablePanel;
