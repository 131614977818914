import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../components/Section';
import profile from '../../profile';
import MyTalents from '../containers/MyTalents';
import { RouteComponentProps } from 'react-router';
import { UserId, UserResponse } from '../../../config/api/models';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../../myCompass/api';
import { useCompassUser } from '../../shared/hooks';
import { MyTalentsTour } from '../../shared/tours';
import useUser from '../../security/hooks/useUser';
import { useSelector } from 'react-redux';
import { getHelp } from '../../shared/selectors';
import { CompassState } from '../../myCompass/model';
import { getCompassState } from '../../myCompass/selectors';

const { PageContentWrapperWithStickyUserMenu } = profile;

type Props = RouteComponentProps<{ userId: UserId }>;

const MyTalentsPage = (props: Props) => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const isCompassUser = useCompassUser();
  const compassState = useSelector(getCompassState);
  const compassUserId = props.match.params.userId;
  const isHelpActive = useSelector(getHelp);

  const [, fetchUser] = useFetch<UserResponse>(ApiConfig.loadCompassUser(compassUserId));

  useEffect(() => {
    if (compassUserId) {
      fetchUser();
    }
  }, [fetchUser, compassUserId]);

  return (
    <>
      <PageContentWrapperWithStickyUserMenu>
        <Section id="talents" title={t('talents.title')} removeTopMargin>
          <MyTalents compassUserId={compassUserId} />
        </Section>
      </PageContentWrapperWithStickyUserMenu>
      {compassState === CompassState.MYCOMPASS && (
        <>
          {(!isCompassUser && !currentUser.talentsTourDone) || isHelpActive ? (
            <MyTalentsTour />
          ) : null}
        </>
      )}
    </>
  );
};

export default MyTalentsPage;
