import { styled } from '@material-ui/styles';
import IconButton from './IconButton';

const PaperTopButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '2rem',
  right: '2rem',
  [theme.breakpoints.down('xs')]: {
    right: '1rem',
  },
}));

export default PaperTopButton;
