import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../components/Section';
import profile from '../../profile';
import DreamsOverview from '../containers/Dream/DreamsOverview';
import NewDreamForm from '../containers/Dream/NewDreamForm';
import { useHistory, useParams } from 'react-router-dom';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { useDispatch, useSelector } from 'react-redux';
import security from '../../security';
import inspiration from '../../inspiration';
import { Actions } from '../actions';
import { getCompassUserId } from '../selectors';
import { useCompassUser } from '../../shared/hooks';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig } from '../api';
import { ApiConfig as SecurityApiConfig } from '../../security/api';
import { actions } from 'react-redux-fetch';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CompassState } from '../model';
import Sidebar from '../../../components/Sidebar';
import PanelWithSidebar from '../../../components/PanelWithSidebar';

const { PageContentWrapperWithStickyUserMenu } = profile;

const useStyles = makeStyles((theme: Theme) => ({
  newDream: {
    marginTop: '4rem',
  },
  newDreamForm: {
    backgroundColor: theme.palette.background.paper,
    padding: '2rem',
    border: theme.config.defaultBorder,
  },
}));

const DreamsOverviewPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { getPath } = useI18nPath();
  const dispatch = useDispatch();
  const [showNewDream, setShowNewDream] = useState(false);
  const { userId } = useParams();
  const currentUserId = useSelector(security.selectors.getUserId);
  const userIdToUse = userId || currentUserId;
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const shouldRender = isCompassUser ? !!compassUserId : true;
  const [, getCompassUser] = useFetch<UserResponse>(ApiConfig.loadCompassUser(userId));
  const [, getUser] = useFetch<UserResponse>(SecurityApiConfig.getUser(currentUserId));

  useEffect(() => {
    if (isCompassUser && !compassUserId) {
      getCompassUser();
    } else {
      getUser();
    }
    if (!isCompassUser && compassUserId) {
      dispatch(actions.clear('compassUser'));
    }
    dispatch(
      Actions.setCompassState(isCompassUser ? CompassState.COMPASS : CompassState.MYCOMPASS)
    );
  }, [isCompassUser, getCompassUser, compassUserId, dispatch, getUser]);

  const handleSubmitNewDream = useCallback(
    (dreamId?: string) => {
      if (!dreamId) {
        return;
      }

      history.push(
        getPath('dreamDetail')
          .replace(':userId', currentUserId)
          .replace(':dreamId', dreamId)
      );
    },
    [currentUserId, history, getPath]
  );

  const handleCancelNewDream = () => {
    setShowNewDream(false);
  };

  const handleShowNewDream = () => {
    setShowNewDream(!showNewDream);
  };

  useEffect(() => {
    dispatch(Actions.selectFocus());
  }, [dispatch]);

  return (
    shouldRender && (
      <PageContentWrapperWithStickyUserMenu>
        <Section id="dreams" title={t('dream.dreams')} removeTopMargin>
          <DreamsOverview userId={userIdToUse} onAddDream={handleShowNewDream} />
          {showNewDream && (
            <div className={classes.newDream}>
              <inspiration.InspirationDialogProvider>
                <PanelWithSidebar
                  sidebar={
                    <Sidebar>
                      <inspiration.InspirationSidebarButton />
                    </Sidebar>
                  }
                  component={'div'}
                >
                  <div className={classes.newDreamForm}>
                    <NewDreamForm onSubmit={handleSubmitNewDream} onCancel={handleCancelNewDream} />
                  </div>
                </PanelWithSidebar>
              </inspiration.InspirationDialogProvider>
            </div>
          )}
        </Section>
      </PageContentWrapperWithStickyUserMenu>
    )
  );
};

export default DreamsOverviewPage;
