import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import DefaultProfilePicture from '../../../assets/defaultProfilePicture-1258445428.jpg';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
} from '@material-ui/core';
import security from '../../security';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { User } from '../../../config/api/models';
import ProfilePicture from '../../../components/ProfilePicture';
import SvgIcon, { Icons } from '../../../components/SvgIcon';

const useStyles = makeStyles((theme: Theme) => {
  return {
    userMenuRoot: {
      position: 'relative',
      height: '100%',
      borderLeft: theme.config.defaultBorder,
      background: theme.palette.common.white,
    },
    userMenuButton: {
      position: 'relative',
      paddingLeft: '6.5rem',
      paddingRight: '2rem',
      fontSize: '1.3rem',
      textTransform: 'none',
      borderRadius: 0,
      height: '100%',
    },
    Icon: {
      marginRight: '1rem',
      display: 'flex',
    },
    profilePicture: {
      position: 'absolute',
      top: '50%',
      left: '1.5rem',
      transform: 'translateY(-50%)',
      overflow: 'hidden',
      width: '4rem',
      height: '4rem',
      [theme.breakpoints.down('sm')]: {
        width: '2rem',
        height: '2rem',
      },
      // backgroundImage: 'url(' + props.currentProfilePicture + ')',
      // backgroundSize: 'cover',
    },
    menuPopper: {
      width: '100%',
    },
    menuPaper: {
      boxShadow: '0 0 .5rem 0 rgba(0,0,0,.1)',
      width: '100%',
      borderRadius: 0,
      marginTop: '1px',
      marginLeft: '5px',
    },
  };
});

const UserMenu = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const logout = security.hooks.useLogout();
  const currentUser: User = security.hooks.useUser();
  const currentProfilePicture =
    currentUser.media && currentUser.media.profile
      ? currentUser.media.profile
      : DefaultProfilePicture;

  const classes = useStyles({ currentProfilePicture });

  const { t } = useTranslation();
  const { getPath } = useI18nPath();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  return (
    <div className={classes.userMenuRoot}>
      <Button
        ref={anchorRef}
        aria-controls="menu-list-grow"
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.userMenuButton}
        id="user-menu"
      >
        <div className={classes.profilePicture}>
          <ProfilePicture profilePicture={currentProfilePicture} />
        </div>
        {currentUser.firstName + ' ' + currentUser.lastName}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        className={classes.menuPopper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: '0 0' }}>
            <Paper className={classes.menuPaper} id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={handleClose} component={Link} to={getPath('settings')}>
                    <span className={classes.Icon}>
                      <SvgIcon icon={Icons.GEAR} />
                    </span>
                    {t('navigation.settings')}
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <span className={classes.Icon}>
                      <SvgIcon icon={Icons.LOGOUT} />
                    </span>
                    {t('navigation.logout')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default UserMenu;
