import apiRoutes from '../../config/api/routes';
import memoizeOne from 'memoize-one';
import {
  ContextType,
  NoteRequest,
  NoteId,
  UserId,
  GroupRequest,
  GroupId,
  GroupToRelationRequest,
  RelationId,
} from '../../config/api/models';

export const RESOURCE = {
  NOTES: 'notes',
  NOTE: 'note',
  GROUPS: 'groups',
  GROUP: 'group',
  GROUP_TO_RELATION: 'groupToRelation',
};

export const ApiConfig = {
  getContext: memoizeOne((contextType: ContextType, contextId: string) => ({
    resource: `context_for_${contextType}_${contextId}`,
    method: 'GET',
    request: () => ({
      url: contextType === 'dream' ? apiRoutes.dreams(contextId) : apiRoutes.focuses(contextId),
    }),
  })),
  focuses: memoizeOne((dreamId: string) => ({
    resource: `focuses-for-${dreamId}`,
    method: 'GET',
    request: () => ({
      url: apiRoutes.focusTabs(dreamId),
    }),
  })),

  // Notes
  addNote: memoizeOne(() => ({
    resource: RESOURCE.NOTE,
    method: 'post',
    request: (addNoteDTO: NoteRequest) => ({
      url: apiRoutes.notes(),
      body: {
        data: {
          type: 'notes',
          attributes: addNoteDTO,
        },
      },
    }),
  })),
  updateNote: memoizeOne((noteId: NoteId) => ({
    resource: RESOURCE.NOTE,
    method: 'PATCH',
    request: (updateNoteDTO: NoteRequest) => ({
      url: apiRoutes.updateNote(noteId),
      body: {
        data: {
          type: 'notes',
          id: noteId.toString(),
          attributes: updateNoteDTO,
        },
      },
    }),
  })),
  loadNote: () => ({
    resource: RESOURCE.NOTES,
    method: 'GET',
    request: (coacheeId?: UserId) => ({
      url: apiRoutes.notes(coacheeId),
    }),
  }),

  // Groups
  getGroups: memoizeOne(() => ({
    resource: RESOURCE.GROUPS,
    method: 'GET',
    request: () => ({
      url: apiRoutes.groups(),
    }),
  })),
  addGroup: memoizeOne(() => ({
    resource: RESOURCE.GROUP,
    method: 'POST',
    request: (addGroupDTO: GroupRequest) => ({
      url: apiRoutes.groups(),
      body: {
        data: {
          type: 'groups',
          attributes: addGroupDTO,
        },
      },
    }),
  })),
  updateGroup: memoizeOne(() => ({
    resource: RESOURCE.GROUP,
    method: 'PATCH',
    request: (addGroupDTO: GroupRequest, groupId: GroupId) => ({
      url: apiRoutes.groups(groupId),
      body: {
        data: {
          type: 'groups',
          id: groupId,
          attributes: addGroupDTO,
        },
      },
    }),
  })),
  removeGroup: memoizeOne(() => ({
    resource: RESOURCE.GROUP,
    method: 'DELETE',
    request: (groupId: GroupId) => ({
      url: apiRoutes.groups(groupId),
      clearValueOnRequest: true,
    }),
  })),
  addGroupToRelation: memoizeOne(() => ({
    resource: RESOURCE.GROUP_TO_RELATION,
    method: 'POST',
    request: (addGroupToRelationDTO: GroupToRelationRequest) => ({
      url: apiRoutes.addGroupToRelation(),
      body: {
        data: {
          type: 'groups',
          attributes: addGroupToRelationDTO,
        },
      },
      clearValueOnRequest: true,
    }),
  })),
  removeGroupFromRelation: memoizeOne(() => ({
    resource: RESOURCE.GROUP_TO_RELATION,
    method: 'POST',
    request: (groupId: GroupId, relationId: RelationId) => ({
      url: apiRoutes.removeGroupFromRelation(),
      body: {
        data: {
          attributes: {
            groupId,
            relationId,
          },
        },
      },
    }),
  })),
};
