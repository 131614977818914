import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import EditablePanel from '../../../../components/EditablePanel';
import Sidebar from '../../../../components/Sidebar';
import NewFocusForm from './NewFocusForm';
import inspiration from '../../../inspiration';
import ContentVisibilityButton from '../../../../components/ContentVisibilityButton';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

const useStyles = makeStyles({
  newFocusRoot: {},
});

const NewFocus = ({ onSubmit, onCancel }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState(true);

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setVisibility(!checked);
  };

  return (
    <div className={classes.newFocusRoot}>
      <inspiration.InspirationDialogProvider>
        <EditablePanel
          view={null}
          form={<NewFocusForm onCancel={onCancel} onSubmit={onSubmit} visibility={visibility} />}
          sidebar={
            <Sidebar>
              <inspiration.InspirationSidebarButton />
              <ContentVisibilityButton
                value={false}
                onChange={handleVisibilityChange}
                tooltip={t('focus.visibilityTooltip')}
              />
            </Sidebar>
          }
          editable={true}
          showToggleButton={false}
        />
      </inspiration.InspirationDialogProvider>
    </div>
  );
};

export default NewFocus;
