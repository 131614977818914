import React, { useState } from 'react';
import {
  ExpansionPanel,
  ListItemText,
  Theme,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import IconButton from '../../../../components/IconButton';
import SvgIcon, { Icons } from '../../../../components/SvgIcon';
import { TaskResponse } from '../../../../config/api/models';
import Toggle from '../../../../components/Toggle';
import Html from '../../../../components/Html';
import TaskDialogForm from '../../containers/Plan/TaskDialogForm';
import BlurDialog from '../../../shared/components/BlurDialog';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { useCompassUser } from '../../../shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../../selectors';
import { getMilaUserId } from '../../../shared/selectors';
import DateTimeMessage from '../../../shared/components/DateTimeMessage';

type Props = {
  task: TaskResponse['data'];
  onToggle: () => void;
  onDelete?: () => void;
};

type StyleProps = {
  hasBodyText: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  expansionPanelRoot: {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  expansionPanelSummary: {
    height: '6rem',
  },
  expansionPanelSummaryContent: ({ hasBodyText }: StyleProps) => ({
    cursor: hasBodyText ? 'pointer' : 'default',
    '&:hover': hasBodyText
      ? {
          textDecoration: 'underline',
        }
      : {},
  }),
  planListItemRoot: {
    height: '5rem',
    width: '100%',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  listItemTextRoot: {
    paddingTop: 20,
    paddingBottom: 20,
    margin: 0,
    paddingLeft: '2rem',
    wordBreak: 'break-word',
  },
  listItemText: {
    fontSize: '1.4rem',
  },
  listItemSecondaryText: {
    fontSize: '1.2rem',
    fontStyle: 'italic',
  },
  listItemActions: {
    '& > *': {
      marginLeft: '2rem',
    },
  },
  listItemToggle: {
    width: '3rem',
    display: 'inline-flex',
  },
  expansionPanelDetailsRoot: {
    flexDirection: 'column',
  },
}));

const PlanListItem = ({ task, onToggle, onDelete }: Props) => {
  const classes = useStyles({ hasBodyText: Boolean(task.attributes.body) });
  const [dialogIsOpen, openDialog] = useState<boolean>(false);
  const [panelIsOpen, openPanel] = useState<boolean>(false);
  const [confirmDelete, openConfirmDelete] = useState<boolean>(false);
  const toggleDialog = () => openDialog(!dialogIsOpen);
  const togglePanel = () => openPanel(!panelIsOpen);
  const { t } = useTranslation();
  const compassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = compassUser && compassUserId === milaUserId;

  const secondaryText = [
    task.attributes.startDate && moment(task.attributes.startDate).format('DD/MM/YYYY'),
    task.attributes.endDate && moment(task.attributes.endDate).format('DD/MM/YYYY'),
  ]
    .filter(Boolean)
    .join(' - ');

  const deleteActionButtons = [
    <Button onClick={onDelete} icon={Icons.CHECK_CIRCLE} key="confirm">
      {t('delete.deleteButton')}
    </Button>,
    <Button
      onClick={() => openConfirmDelete(false)}
      color="secondary"
      icon={Icons.CANCEL}
      key="delete"
    >
      {t('delete.cancelButton')}
    </Button>,
  ];

  return (
    <>
      <ExpansionPanel
        className={classes.expansionPanelRoot}
        TransitionProps={{ unmountOnExit: true }}
        expanded={Boolean(task.attributes.body) ? panelIsOpen : false}
        square
      >
        <ExpansionPanelSummary
          classes={{ content: classes.expansionPanelSummaryContent }}
          className={classes.expansionPanelSummary}
        >
          <div className={classes.planListItemRoot}>
            {!compassUser && (
              <div className={classes.listItemToggle}>
                <Toggle onClick={onToggle} checked={task.attributes.isClosed === 1} />
              </div>
            )}
            <ListItemText
              onClick={togglePanel}
              classes={{ root: classes.listItemTextRoot }}
              primaryTypographyProps={{ className: classes.listItemText }}
              primary={task.attributes.title}
              secondaryTypographyProps={{ className: classes.listItemSecondaryText }}
              secondary={secondaryText}
            />
            {!compassUser && (
              <div className={classes.listItemActions}>
                {!task.attributes.isClosed && (
                  <IconButton onClick={toggleDialog}>
                    <SvgIcon icon={Icons.EDIT} />
                  </IconButton>
                )}
                {onDelete && (
                  <IconButton onClick={() => openConfirmDelete(true)}>
                    <SvgIcon icon={Icons.TRASH} />
                  </IconButton>
                )}
              </div>
            )}
            {isMila && (
              <div id="edit-delete-buttons" className={classes.listItemActions}>
                <IconButton>
                  <SvgIcon icon={Icons.EDIT} />
                </IconButton>
                <IconButton>
                  <SvgIcon icon={Icons.TRASH} />
                </IconButton>
              </div>
            )}
          </div>
        </ExpansionPanelSummary>
        {task.attributes.body && (
          <ExpansionPanelDetails className={classes.expansionPanelDetailsRoot}>
            <Html>{task.attributes.body}</Html>
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <DateTimeMessage
                createdAt={task.attributes.createdAt}
                updatedAt={task.attributes.updatedAt}
              />
            </Box>
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>

      <BlurDialog
        open={confirmDelete}
        actions={deleteActionButtons}
        title={t('delete.title')}
        onClose={() => openConfirmDelete(false)}
      >
        {t('delete.text')}
      </BlurDialog>

      {dialogIsOpen && (
        <TaskDialogForm
          onToggle={toggleDialog}
          taskId={task.id}
          initialValues={{
            title: task.attributes.title,
            body: task.attributes.body,
            date: {
              start: task.attributes.startDate ? moment(task.attributes.startDate) : undefined,
              end: task.attributes.endDate ? moment(task.attributes.endDate) : undefined,
            },
            coachId: task.attributes.coachId || 'noCoach',
          }}
          titleText="plan.editTitle"
          saveBtnText="forms.buttons.editTask"
        />
      )}
    </>
  );
};

export default PlanListItem;
