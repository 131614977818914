import React from 'react';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { LoginRequest, LoginResponse } from '../../../config/api/models';
import { useTranslation } from 'react-i18next';
import FormControl from '../../../components/FormControl';
import { Field, Form } from 'react-final-form';
import { TextFieldAdapter, validations } from '../../../components/forms';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import Title from '../../../components/Title';
import FormError from '../../../components/forms/FormError';
import { makeStyles } from '@material-ui/styles';

const initialValues: LoginRequest =
  process.env.NODE_ENV === 'development'
    ? {
        email: process.env.REACT_APP_DEMO_USER || '',
        password: process.env.REACT_APP_DEMO_USER_PW || '',
      }
    : { email: '', password: '' };

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
  },
}));

const LoginForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loginResponse, login] = useFetch<LoginResponse>(ApiConfig.login());

  return (
    <>
      <Title login>{t('login.title')}</Title>
      <Form
        onSubmit={(values: LoginRequest) => {
          login(values);
        }}
        initialValues={initialValues}
        render={({ handleSubmit, valid }) => (
          <form onSubmit={handleSubmit}>
            <FormControl label={t('forms.labels.email')} id="email" required>
              <Field
                name="email"
                component={TextFieldAdapter}
                validate={validations(t).isEmail()}
                type="email"
                autoComplete="email"
              />
            </FormControl>
            <FormControl label={t('forms.labels.password')} id="password" required>
              <Field
                name="password"
                component={TextFieldAdapter}
                validate={validations(t).required}
                type="password"
                autoComplete="current-password"
              />
            </FormControl>
            <div className={classes.flex}>
              <Button
                type="submit"
                disabled={(loginResponse && loginResponse.pending) || !valid}
                icon={Icons.SAVE}
                home
              >
                {t('login.title')}
              </Button>
              <Button variant="text" link="forgotPassword">
                {t('forms.buttons.forgotPassword')}
              </Button>
            </div>
            {loginResponse && loginResponse.rejected && (
              <FormError>{t('forms.feedback.loginNotAuthorized')}</FormError>
            )}
          </form>
        )}
      />
    </>
  );
};

export default LoginForm;
