class FileUploader {
  protected file: any;
  protected resolve: (value: unknown) => void;
  protected reject: (value: unknown) => void;

  constructor(file: any, resolve: (value: unknown) => void, reject: (value: unknown) => void) {
    this.file = file;
    this.resolve = resolve;
    this.reject = reject;
  }
}
export default FileUploader;
