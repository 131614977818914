import { Editor, Loader } from '@ckeditor/ckeditor5-build-classic-with-video';
import FileUploadAdapter from './FileUploadAdapter';

function fileUploadAdapterPlugin(editor: Editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: Loader) => {
    return new FileUploadAdapter(loader, editor);
  };
}

export default fileUploadAdapterPlugin;
