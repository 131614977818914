import React from 'react';
import FocusSection from '../../myCompass/containers/Focus/FocusSection';
import { useSelector } from 'react-redux';
import { getShowcase } from '../selectors';
import { getSelectedFocusId } from '../../myCompass/selectors';
import ShowcaseBlock from '../components/ShowcaseBlock';
import {
  getSelectedContext,
  getShowcaseFocusMapForSelectedDream,
} from '../helpers/helperFunctions';
import { DreamId, LookBackId } from '../../../config/api/models';

type Props = {
  dreamId: DreamId;
};

const ShowcaseLookBackWrapper = ({ dreamId }: Props) => {
  const showcase = useSelector(getShowcase());
  const selectedFocusId = useSelector(getSelectedFocusId);

  if (!showcase || !selectedFocusId || !showcase.lookbacks.length) {
    return null;
  }

  const focusMap = getShowcaseFocusMapForSelectedDream(showcase, dreamId, selectedFocusId);
  const lookbackMap = focusMap ? focusMap.lookbacks : [];
  const lookbacks = showcase.lookbacks.filter(lookback =>
    lookbackMap.map(l => l.id).includes(lookback.entityId)
  );

  if (!lookbacks.length || !lookbackMap.length) {
    return null;
  }

  const context = getSelectedContext(showcase, selectedFocusId);
  const getCommentsList = (id: LookBackId) => {
    const lookback = lookbackMap.find(l => l.id === id);
    if (lookback) {
      return showcase.comments.filter(c =>
        lookback!.comments.map(lc => lc.toString()).includes(c.entityId)
      );
    }
    return [];
  };

  return (
    <FocusSection id="i-lookback" title="iLookBack" context={context}>
      {lookbacks.map(l => (
        <ShowcaseBlock
          key={l.entityId}
          context={l}
          comments={[...getCommentsList(l.entityId)]}
          modelType="lookback"
        />
      ))}
    </FocusSection>
  );
};

export default ShowcaseLookBackWrapper;
