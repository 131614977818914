import { ActionsUnion, createAction } from '../../helpers/createAction';
import {
  FOCUS_SELECTED,
  COMPASS_STATE,
  ENABLE_INITIAL_FOCUS_CREATED,
  DISABLE_INITIAL_FOCUS_CREATED,
} from './actionTypes';
import { FocusId } from '../../config/api/models';
import { CompassState } from './model';

export const Actions = {
  selectFocus: (focusId?: FocusId) => createAction(FOCUS_SELECTED, { focusId }),
  setCompassState: (compassState: CompassState) => createAction(COMPASS_STATE, { compassState }),
  enableInitialFocusCreated: () => createAction(ENABLE_INITIAL_FOCUS_CREATED),
  disableInitialFocusCreated: () => createAction(DISABLE_INITIAL_FOCUS_CREATED),
};

export type ActionsType = ActionsUnion<typeof Actions>;
