import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { getBlur } from '../selectors';

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  blurRoot: ({ blur }: { blur: boolean }) => ({
    width: '100%',
    height: '100%',
    filter: blur ? 'blur(3px)' : 'none',
  }),
}));

const Blur = ({ children }: Props) => {
  const blur = useSelector(getBlur);
  const classes = useStyles({ blur });

  return <div className={classes.blurRoot}>{children}</div>;
};

export default Blur;
