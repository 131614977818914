import React, { useRef, useContext } from 'react';
import { Divider, MenuItem, MenuList, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Drawer from '../../../components/Drawer';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import navigationData, { NavigationItem } from '../config/navigationData';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from '../../../config/api/models';
import security from '../../security';
import DefaultProfilePicture from '../../../assets/defaultProfilePicture-1258445428.jpg';
import ProfilePicture from '../../../components/ProfilePicture';
import { useTranslation } from 'react-i18next';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import ClientRoutesContext from '../../../config/clientRoutes/ClientRoutesContext';
import LanguageList from '../../shared/components/LanguageList';

const useStyles = makeStyles((theme: Theme) => ({
  appBarDrawerRoot: {},
  menuItem: {
    borderLeft: '3px solid #FFF',
    display: 'flex',
    minHeight: 'auto',
    alignItems: 'center',
    margin: '1rem 0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFF',
      borderLeft: `3px solid ${theme.palette.myCompass.green}`,
    },
  },
  profilePicture: {
    width: '4rem',
    height: '4rem',
    marginRight: '1rem',
  },
  profileMenu: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    flexShrink: 0,
  },
  userMenu: {
    marginTop: '2rem',
    width: '21rem',
  },
  userInfo: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  Icon: {
    marginRight: '1rem',
    display: 'flex',
  },
  language: {
    padding: '1rem',
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamily,
    width: 'fit-content',
  },
  languageRoot: {
    paddingLeft: '1rem',
    display: 'flex',
  },
}));

const AppBarDrawer = ({ history, location }: RouteComponentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const logout = security.hooks.useLogout();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { routes } = useContext(ClientRoutesContext);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
  };

  const currentUser: User = security.hooks.useUser();
  const currentProfilePicture =
    currentUser.media && currentUser.media.profile
      ? currentUser.media.profile
      : DefaultProfilePicture;

  const extern = !!currentUser.isExtern;

  return (
    <div className={classes.appBarDrawerRoot}>
      <Drawer drawerSide="right">
        <MenuList>
          {navigationData(routes, extern).map((item: NavigationItem) => (
            <MenuItem
              className={classes.menuItem}
              onClick={handleClose}
              component={Link}
              to={getPath(item.routeName)}
              key={item.routeName}
            >
              <span className={classes.Icon}>
                <SvgIcon icon={item.icon} />
              </span>
              {item.routeName}
            </MenuItem>
          ))}
        </MenuList>
        <Divider />
        <div className={classes.languageRoot}>
          <LanguageList
            className={classes.language}
            onChange={() => {}}
            langStyle="value"
            location={location}
            history={history}
          />
        </div>
        <Divider />
        <div className={classes.userMenu}>
          <div className={classes.profileMenu}>
            <div className={classes.profilePicture}>
              <ProfilePicture profilePicture={currentProfilePicture} />
            </div>
            <div className={classes.userInfo}>
              {currentUser.firstName + ' ' + currentUser.lastName}
            </div>
          </div>
          <MenuList>
            <MenuItem
              onClick={handleClose}
              component={Link}
              to={getPath('settings')}
              className={classes.menuItem}
            >
              <span className={classes.Icon}>
                <SvgIcon icon={Icons.GEAR} />
              </span>
              {t('navigation.settings')}
            </MenuItem>
            <MenuItem onClick={logout} className={classes.menuItem}>
              <span className={classes.Icon}>
                <SvgIcon icon={Icons.LOGOUT} />
              </span>
              {t('navigation.logout')}
            </MenuItem>
          </MenuList>
        </div>
      </Drawer>
    </div>
  );
};

export default withRouter(AppBarDrawer);
