import { styled } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

const PlanPaper = styled(Paper)({
  padding: '2rem 3rem',
  borderTopRightRadius: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export default PlanPaper;
