import React, { FC, useMemo, useState } from 'react';
import Button from '../../../components/Button';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import { makeStyles } from '@material-ui/styles';
import { Theme, useMediaQuery, Fab } from '@material-ui/core';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { useTranslation } from 'react-i18next';
import Html from '../../../components/Html';
import moment from 'moment';
import LinkDialogForm from './LinkDialogForm';
import { default as themeConfig } from '../../../config/theme';
import ConfirmationModal from '../../../components/ConfirmationModal';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import { useSelector } from 'react-redux';
import { getHelp } from '../../shared/selectors';

type Props = {
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  saveBanner: {
    backgroundColor: theme.palette.highlight.light,
    borderBottom: `solid 1px ${theme.palette.highlight.main}`,
    borderTop: `solid 1px ${theme.palette.highlight.main}`,
    color: theme.palette.highlight.dark,
    height: '4.7rem',
  },
  saveBannerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '140rem',
    padding: '0 2rem',
    margin: 'auto',
    height: '100%',
  },
  saveBannerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tooltip: {
    marginLeft: '1rem',
  },
  fab: {
    height: '4rem',
    width: '4rem',
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
    backgroundColor: theme.palette.highlight.medium,
    color: 'white',
  },
}));

const SaveShowcaseBanner: FC<Props> = ({ disabled = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const isMobile = useMediaQuery(themeConfig.breakpoints.down('sm'));

  const isHelpActive = useSelector(getHelp);
  const [showCasesResponse] = useFetch(ApiConfig.loadShowcases());
  const [milaShowCasesResponse] = useFetch(ApiConfig.loadMilaShowcases());

  const showcase = useMemo(() => {
    return isHelpActive
      ? milaShowCasesResponse &&
        milaShowCasesResponse.value &&
        !!milaShowCasesResponse.value.data[0]
        ? milaShowCasesResponse.value.data[0].attributes
        : undefined
      : showCasesResponse && showCasesResponse.value && !!showCasesResponse.value.data[0]
      ? showCasesResponse.value.data[0].attributes
      : undefined;
  }, [isHelpActive, showCasesResponse, milaShowCasesResponse]);

  const [, patchShowcase] = useFetch(ApiConfig.patchShowcase(showcase ? showcase.slug : ''));

  const formattedDate = showcase ? moment(showcase.updatedAt).format('DD MMMM YY') : '';
  const formattedHour = showcase ? moment(showcase.updatedAt).format('HH:mm') : '';

  const saveShowcase = () => {
    if (showcase) {
      const updatedAt = moment().toISOString();
      patchShowcase({ updatedAt });
    }
    setOpenModal(false);
  };

  const dialog = showcase ? (
    <ConfirmationModal
      title={t(showcase ? 'showcase.myLinks.editLink' : 'showcase.myLinks.addLink')}
      text={t('showcase.mySettings.saveModalBody')}
      open={openModal}
      confirmButtonText={t('common.yes')}
      confirmButtonIcon={Icons.SAVE}
      onConfirm={saveShowcase}
      cancelButtonText={t('common.no')}
      cancelButtonIcon={Icons.CANCEL}
      onCancel={() => setOpenModal(false)}
    />
  ) : (
    <LinkDialogForm open={openModal} onClose={() => setOpenModal(false)} showcase={showcase} />
  );

  if (isMobile) {
    return (
      <>
        <Fab className={classes.fab} onClick={() => setOpenModal(true)}>
          <SvgIcon icon={Icons.PLUS} />
        </Fab>
        {dialog}
      </>
    );
  }

  return (
    <div className={classes.saveBanner}>
      <div className={classes.saveBannerContent}>
        <div className={classes.saveBannerText}>
          <Html>
            {t(`showcase.mySettings.${showcase ? 'updateBanner' : 'createBanner'}`, {
              date: formattedDate,
              hour: formattedHour,
            })}
          </Html>
          {showcase && (
            <Tooltip title={t('showcase.mySettings.info')} className={classes.tooltip}>
              <IconButton>
                <SvgIcon icon={Icons.INFO} />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Button
          id="create_showcase_button"
          icon={Icons.PLUS}
          onClick={disabled ? undefined : () => setOpenModal(true)}
        >
          {t(`showcase.mySettings.${showcase ? 'save' : 'saveNew'}`)}
        </Button>
      </div>

      {dialog}
    </div>
  );
};

export default SaveShowcaseBanner;
