import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type Props = {
  children: React.ReactChild | React.ReactChild[];
  fullWidth?: boolean;
  fullHeight?: boolean;
};

type StyleProps = {
  fullWidth: boolean;
  fullHeight: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  modalBodyAlt: (props: StyleProps) => ({
    padding: theme.spacing(2),
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    ...(props.fullWidth && {
      marginLeft: theme.spacing(-8),
      paddingLeft: theme.spacing(8),
      marginRight: theme.spacing(-8),
      paddingRight: theme.spacing(8),
    }),
    ...(props.fullHeight && {
      padding: '64px',
    }),
  }),
}));

const ModalBodyAlt = ({ children, fullWidth = false, fullHeight = false }: Props) => {
  const classes = useStyles({ fullWidth, fullHeight });

  return <div className={classes.modalBodyAlt}>{children}</div>;
};

export default ModalBodyAlt;
