import React, { useEffect } from 'react';
import FocusSection from '../Focus/FocusSection';
import { useSelector } from 'react-redux';
import { getSelectedFocusId } from '../../selectors';
import Milestone from './Milestone';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig } from '../../api';
import {
  MilestonesResponse,
  Milestone as MilestoneType,
  User,
} from '../../../../config/api/models';

type Props = {
  user?: User;
};

const milestoneConfig = ApiConfig.loadMilestones();
const MilestoneWrapper = ({ user }: Props) => {
  const focusId = useSelector(getSelectedFocusId);
  const [milestonesRequest, loadMilestones] = useFetch<MilestonesResponse>(milestoneConfig);

  // eslint-disable-next-line
  useEffect(loadMilestones, []);

  const milestone: MilestoneType | undefined =
    milestonesRequest && milestonesRequest.value
      ? milestonesRequest.value.data.map(d => d.attributes).find(m => m.focusId === focusId)
      : undefined;

  if (!milestone) {
    return null;
  }

  return (
    <FocusSection id="i-milestone" title="iMilestone">
      <div id={`milestone-${milestone.entityId}`}>
        <Milestone withSidebar milestone={milestone} commentable user={user} />
      </div>
    </FocusSection>
  );
};

export default MilestoneWrapper;
