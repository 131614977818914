import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SvgIcon, { Icons } from './SvgIcon';
import clsx from 'clsx';

type Props = {
  id?: string;
  text: string;
  onClick: () => void;
  expandButton?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  expandingButtonRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    cursor: 'pointer',
  },
  expandingButtonWrapper: {
    overflow: 'hidden',
    background: theme.palette.primary.medium,
    color: theme.palette.primary.main,
    borderRadius: '2rem',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    padding: '0.5rem 0',
    '&:hover #expandable': {
      opacity: 1,
      maxWidth: '500px',
      transition: 'max-width 1.5s ease-out .2s, opacity 1s ease-out .1s',
    },
  },
  expandingButtonLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    transition: 'max-width .5s ease-out .2s, opacity .25s ease-out .1s',
  },
  expandedButton: {
    opacity: 1,
    maxWidth: '500px',
    transition: 'max-width 1.5s ease-out .2s, opacity 1s ease-out .1s',
  },
  shrunkButton: {
    opacity: 0,
    maxWidth: '0',
  },
  expandingButtonText: {
    display: 'block',
    paddingLeft: '1rem',
    fontSize: '12px',
  },
  expandingButtonIcon: {
    lineHeight: 1,
    fontSize: '25px',
    padding: '0.5rem 1rem',
    display: 'flex',
  },
}));

const ExpandingButton = ({ id, text, onClick, expandButton }: Props) => {
  const classes = useStyles();

  return (
    <div id={id} className={classes.expandingButtonRoot}>
      <div className={classes.expandingButtonWrapper} onClick={onClick}>
        <div
          id="expandable"
          className={clsx(
            classes.expandingButtonLink,
            expandButton ? classes.expandedButton : classes.shrunkButton
          )}
        >
          <span className={classes.expandingButtonText}>{text}</span>
        </div>
        <div className={classes.expandingButtonIcon}>
          <SvgIcon icon={Icons.SAVE} />
        </div>
      </div>
    </div>
  );
};

export default ExpandingButton;
