import { AnyAction } from 'redux';
import { State } from './model';
import * as actionTypes from './actionTypes';

type ShowcaseReducer = (state: State | undefined, action: AnyAction) => State;

const initialState: State = {
  milaGoToFirstDream: false,
};

const showcaseReducer: ShowcaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MILA_GO_TO_FIRST_DREAM:
      return {
        ...state,
        milaGoToFirstDream: true,
      };
  }
  return state;
};

export default showcaseReducer;
