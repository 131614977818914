import React from 'react';
import Footer from '../../../components/Footer';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../config/theme';
import SectionOne from '../components/SectionOne';
import SectionTwo from '../components/SectionTwo';
import SectionThree from '../components/SectionThree';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  coachingsLuikWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  coachingsLuikRoot: (props: StyleProps) => ({
    position: 'relative',
    maxWidth: props.isMobile ? '100%' : '120rem',
    width: '100%',
    minHeight: props.isMobile ? 'calc(100% - 12rem)' : 'calc(100% - 8rem)',
    margin: '0 auto',
    padding: props.isMobile ? '0 2rem 0 2rem' : '2rem 2rem 0 2rem',
  }),
}));

const CoachingsLuik = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile });

  return (
    <div className={classes.coachingsLuikWrapper}>
      <div className={classes.coachingsLuikRoot}>
        <SectionOne />
        <SectionTwo />
        <SectionThree />
      </div>
      <Footer />
    </div>
  );
};

export default CoachingsLuik;
