import React, { useState, useEffect } from 'react';
import useFetch from '../../../../helpers/useFetch';
import { ApiConfig, RESOURCE } from '../../api';
import DreamForm from './DreamForm';
import { DreamSubmitValues } from '../../model';
import { DreamResponse, FileReaderResult } from '../../../../config/api/models';
import useFilesPersist from '../../../shared/hooks/useFilesPersist';
import { useDispatch, useSelector } from 'react-redux';
import { getPersistingFiles } from '../../../shared/selectors';
import { actions } from 'react-redux-fetch';

type Props = {
  onCancel?: () => void;
  onSubmit: (dreamId?: string) => void;
  inModal?: boolean;
};

const addDreamConfig = ApiConfig.addDream();

const NewDreamForm = ({ onCancel, onSubmit, inModal = false }: Props) => {
  const [dreamId, setDreamId] = useState<string>('');
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  const [coverImage, setCoverImage] = useState<FileReaderResult>(null);
  const [addDreamResponse, addDream] = useFetch<DreamResponse>(addDreamConfig);
  const [, addDreamCoverImage] = useFetch<DreamResponse>(ApiConfig.addDreamCoverImage(dreamId));
  const persistFiles = useFilesPersist('dream', dreamId, onSubmit);
  const persistingFiles = useSelector(getPersistingFiles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (addDreamResponse && addDreamResponse.value) {
      setDreamId(addDreamResponse.value.data.id);
    }
  }, [addDreamResponse]);

  useEffect(() => {
    if (dreamId && filesToAdd.length) {
      persistFiles({
        filesToAdd,
        fileIdsToDelete: [],
      });
      setFilesToAdd([]);
    } else if (dreamId && !persistingFiles && onSubmit) {
      dispatch(actions.clear(RESOURCE.DREAM));
      onSubmit(dreamId);
    }
  }, [dreamId, filesToAdd, persistFiles, onSubmit, persistingFiles, dispatch]);

  useEffect(() => {
    if (dreamId !== '' && coverImage != null) {
      addDreamCoverImage({
        photo: coverImage,
      });
    }
  }, [dreamId, coverImage, addDreamCoverImage]);

  const handleSave = (values: DreamSubmitValues) => {
    setFilesToAdd(values.filesToAdd);
    addDream(values.request);
  };

  return (
    <DreamForm
      onSave={handleSave}
      coverImage={coverImage}
      onSelectCoverImage={(file: FileReaderResult) => setCoverImage(file)}
      onClose={onCancel}
      dreamResponse={addDreamResponse}
      inModal={inModal}
    />
  );
};

export default NewDreamForm;
