import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { makeStyles, Tab, Tabs, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import shared from '../../shared';
import { useTranslation } from 'react-i18next';
import SaveShowcaseBanner from '../components/SaveShowcaseBanner';
import { CompassState } from '../../myCompass/model';
import { Actions } from '../../myCompass/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCompassState, getCompassUserId } from '../../myCompass/selectors';
import MyLinks from '../containers/MyLinks';
import ShowcaseDreamDetail from '../containers/ShowcaseDreamDetail';
import clsx from 'clsx';
import { useCompassUser } from '../../shared/hooks';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { getMilaFirstDreamId, getMilaUserId } from '../../shared/selectors';
import { useParams } from 'react-router-dom';
import { MilaShowcaseDreamTour, MilaShowcaseLinksTour } from '../../shared/tours';
import { actions } from 'react-redux-fetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as DreamApiConfig } from '../../myCompass/api';
import { ApiConfig as SecurityApiConfig } from '../../security/api';
import useUser from '../../security/hooks/useUser';

const useStyles = makeStyles((theme: Theme) => ({
  myShowcaseRoot: {
    position: 'relative',
  },
  settingsRoot: {
    '& > :last-child': {
      top: '16.5rem',
      height: 'calc(100vh - 16.5rem)',
      [theme.breakpoints.down('sm')]: {
        top: '11.8rem',
        height: 'calc(100vh - 11.8rem)',
      },
    },
  },
  myShowcaseContent: (isSettingsTab: boolean) => ({
    paddingTop: '2rem',
    marginTop: isSettingsTab ? '0' : '-8rem',
  }),
  myShowcaseContentMobile: (isSettingsTab: boolean) => ({
    paddingTop: isSettingsTab ? '0' : '5rem',
  }),
  tabHeader: (isSettingsTab: boolean) => ({
    backgroundColor: 'white',
    borderBottom: isSettingsTab ? 'none' : theme.config.defaultBorder,
    width: '100%',
    position: 'absolute',
    top: '7rem',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: 0,
    },
  }),
  tabs: {
    padding: '0 2rem',
    maxWidth: '140rem',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

const ShowcaseDreamDetailPage = () => {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const { userId, dreamId } = useParams();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const compassState = useSelector(getCompassState);
  const dispatch = useDispatch();

  const currentUser = useUser();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const milaFirstDreamId = useSelector(getMilaFirstDreamId);
  const isMila = isCompassUser && compassUserId === milaUserId;

  const classes = useStyles(selectedTab === 0);

  const [, getCompassUser] = useFetch<UserResponse>(DreamApiConfig.loadCompassUser(userId));
  const [, getUser] = useFetch<UserResponse>(SecurityApiConfig.getUser(currentUser.entityId));
  const [fetchShowcasesResponse, fetchShowcases] = useFetch(ApiConfig.loadShowcases());
  const [fetchMilaShowcasesResponse, fetchMilaShowcases] = useFetch(ApiConfig.loadMilaShowcases());

  useEffect(() => {
    if (isCompassUser && !compassUserId) {
      getCompassUser();
    } else {
      getUser();
    }
    if (!isCompassUser && compassUserId) {
      dispatch(actions.clear('compassUser'));
    }
  }, [isCompassUser, getCompassUser, getUser, compassUserId, dispatch]);

  useEffect(() => {
    if (isMila) {
      fetchMilaShowcases();
    } else {
      fetchShowcases();
    }
  }, [fetchShowcases, fetchMilaShowcases, isMila]);

  useEffect(() => {
    if (compassState !== CompassState.MYSHOWCASE) {
      dispatch(Actions.setCompassState(CompassState.MYSHOWCASE));
    }
  });

  const shouldRender = useMemo(
    () =>
      isMila
        ? fetchMilaShowcasesResponse && fetchMilaShowcasesResponse.fulfilled
        : fetchShowcasesResponse && fetchShowcasesResponse.fulfilled,
    [isMila, fetchMilaShowcasesResponse, fetchShowcasesResponse]
  );

  return shouldRender ? (
    <div className={clsx(classes.myShowcaseRoot, selectedTab === 0 ? classes.settingsRoot : null)}>
      <div className={classes.tabHeader}>
        <Tabs
          onChange={(ev, val: number) => setSelectedTab(val)}
          value={selectedTab}
          className={classes.tabs}
          variant={isMobile ? 'fullWidth' : 'standard'}
        >
          <Tab label={t('showcase.mySettings.title')} />
          <Tab label={t('showcase.myLinks.title')} />
        </Tabs>
        {selectedTab === 0 && !isMobile && !(isCompassUser && !isMila) && (
          <SaveShowcaseBanner disabled={isMila} />
        )}
      </div>
      <shared.LoggedInPageContentWrapper id="myShowcase" color={theme.palette.myCompass.red}>
        <div
          className={!isMobile ? classes.myShowcaseContent : classes.myShowcaseContentMobile}
          id="myShowcase-detail-content"
        >
          {selectedTab === 0 ? <ShowcaseDreamDetail /> : <MyLinks />}
        </div>
      </shared.LoggedInPageContentWrapper>
      {isMila && selectedTab === 0 && dreamId === milaFirstDreamId && <MilaShowcaseDreamTour />}
      {isMila && selectedTab === 1 && <MilaShowcaseLinksTour />}
    </div>
  ) : null;
};

export default ShowcaseDreamDetailPage;
