import apiRoutes from '../../config/api/routes';
import {
  CompleteRegistrationRequestData,
  ForgotPasswordRequest,
  ImagesRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequestData,
  UserId,
} from '../../config/api/models';
import memoizeOne from 'memoize-one';

export const RESOURCE = {
  COMPLETE_REGISTRATION: 'completeRegistration',
  RESET_PASSWORD: 'resetPassword',
  LOGIN: 'login',
  USER: 'user',
  FORGOT_PASSWORD: 'forgotPassword',
  REGISTER: 'register',
};

export const ApiConfig = {
  completeRegistration: memoizeOne((userId: UserId, token: string) => ({
    resource: RESOURCE.COMPLETE_REGISTRATION,
    method: 'PATCH',
    request: (completeRegistrationDTO: CompleteRegistrationRequestData) => ({
      url: apiRoutes.userToken(userId, token),
      body: {
        data: {
          type: 'users',
          id: userId.toString(),
          attributes: completeRegistrationDTO,
        },
      },
    }),
  })),
  resetPassword: memoizeOne((userId: UserId, token: string) => ({
    resource: RESOURCE.RESET_PASSWORD,
    method: 'PATCH',
    request: (resetPasswordDTO: ResetPasswordRequestData) => ({
      url: apiRoutes.userToken(userId, token),
      body: {
        data: {
          type: 'users',
          id: userId.toString(),
          attributes: resetPasswordDTO,
        },
      },
    }),
  })),
  login: memoizeOne(() => ({
    resource: RESOURCE.LOGIN,
    method: 'post',
    request: (loginDTO: LoginRequest) => ({
      url: apiRoutes.login(),
      body: loginDTO,
    }),
  })),
  getUser: memoizeOne((userId: UserId, token?: string) => ({
    resource: RESOURCE.USER,
    request: {
      url: token ? apiRoutes.userToken(userId, token) : apiRoutes.user(userId),
    },
  })),
  forgotPassword: memoizeOne(() => ({
    resource: RESOURCE.FORGOT_PASSWORD,
    method: 'post',
    request: (forgotPasswordDTO: ForgotPasswordRequest) => ({
      url: apiRoutes.forgotPassword(),
      body: forgotPasswordDTO,
    }),
  })),
  register: memoizeOne(() => ({
    resource: RESOURCE.REGISTER,
    method: 'post',
    request: (registerDTO: RegisterRequest) => ({
      url: apiRoutes.register(),
      body: registerDTO,
    }),
  })),
  images: memoizeOne(() => ({
    resource: RESOURCE.USER,
    method: 'post',
    request: (imagesDTO: ImagesRequest) => ({
      url: apiRoutes.images(),
      body: imagesDTO,
    }),
  })),
};
