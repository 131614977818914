import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  paragraphRoot: {
    fontWeight: 300,
    fontSize: '1.4rem',
    lineHeight: '3rem',
    marginBottom: '3rem',
  },
}));

const Paragraph = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.paragraphRoot}>
      {children}
    </Typography>
  );
};

export default Paragraph;
