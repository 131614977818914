import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import theme from '../../../config/theme';
import IconButton from '../../../components/IconButton';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import ButtonGroup from '../../../components/ButtonGroup';
import LinkDialogForm from './LinkDialogForm';
import GreyStripes from '../../../assets/grey-stripes.png';
import Button from '../../../components/Button';
import { Showcase, User } from '../../../config/api/models';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import BlurDialog from '../../shared/components/BlurDialog';
import Html from '../../../components/Html';
import { useCompassUser } from '../../shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../../myCompass/selectors';
import { getMilaUserId } from '../../shared/selectors';
import security from '../../security';
import 'moment/locale/nl-be';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';
import { isExpired } from '../../shared/helpers/isExpired';

type Props = {
  showcaseList: Showcase[];
};

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '5rem',
    padding: '0 1rem 0 2rem',
  },
  expired: {
    backgroundImage: `url(${GreyStripes})`,
    backgroundRepeat: 'repeat',
  },
  headers: {
    fontWeight: 'bold',
    marginRight: '3rem',
    '& > div': {
      color: 'initial',
    },
  },
  table: {
    marginRight: '3rem',
    position: 'relative',
    '& > *:not(:last-child)': {
      borderBottom: theme.config.defaultBorder,
    },
  },
  dateAndActions: {
    width: '25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.grey['500'],
  },
  removeButton: {
    position: 'absolute',
    right: '-2.3rem',
  },
  addButton: {
    justifyContent: 'center',
  },
  actionButton: {
    padding: '0.7rem',
    borderRadius: 100,
    width: '2.9rem',
    height: '2.9rem',
    backgroundColor: theme.palette.primary.medium,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
  },
}));

const LinkTable = ({ showcaseList }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const [copiedLink, setCopiedLink] = useState<Showcase | null>(null);
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
  const [selectedShowcase, setSelectedShowcase] = useState<Showcase | undefined>();
  const [slugToDelete, setSlugToDelete] = useState('');
  const [, deleteShowcase] = useFetch(ApiConfig.deleteShowcase());
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { firstName, lastName }: User = security.hooks.useUser();
  const link = copiedLink
    ? window.location.origin +
      getPath('showcase')
        .replace(':name', (firstName + lastName).toLowerCase())
        .replace(':slug', copiedLink.slug)
    : '';

  const language = useCurrentLanguage();

  const dateFormat = isMobile ? 'DD/MM/YYYY' : 'D MMMM YYYY';

  const editShowcase = (showcase: Showcase) => {
    setSelectedShowcase(showcase);
    setAddDialogOpen(true);
  };

  const handleDelete = () => {
    setConfirmDeleteOpen(false);
    deleteShowcase(slugToDelete);
    setSlugToDelete('');
  };

  const copyLink = (showcase: Showcase) => {
    const slug = showcase.slug;
    const showcaseLink = window.location.origin + getPath('showcase').replace(':slug', slug);

    const el = document.createElement('textarea');
    el.value = showcaseLink;
    el.setAttribute('readonly', '');
    el.setAttribute('style', "{ position: 'absolute', left: '-9999px' }");
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setCopiedLink(showcase);
  };

  switch (language) {
    case 'nl':
      moment.locale('nl-be');
      break;
    case 'fr':
      moment.locale('fr');
      break;
    case 'en':
      moment.locale('en-gb');
      break;
    default:
      moment.locale('nl-be');
      break;
  }

  return (
    <>
      <div className={clsx(classes.row, classes.headers)}>
        <div>{t('showcase.myLinks.linkName')}</div>
        <div className={classes.dateAndActions}>{t('showcase.myLinks.expiresAt')}</div>
      </div>
      <Paper className={classes.table}>
        {showcaseList.map(d => (
          <div
            key={d.title}
            className={clsx(classes.row, isExpired(d.expiresAt) ? classes.expired : null)}
          >
            <div>{d.title}</div>
            <div className={classes.dateAndActions}>
              <span id="expiration_date">{moment.utc(d.expiresAt).format(dateFormat)}</span>
              {!isCompassUser && (
                <ButtonGroup>
                  <IconButton className={classes.actionButton} onClick={() => editShowcase(d)}>
                    <SvgIcon icon={Icons.UNDO} size={1.5} />
                  </IconButton>
                  {!isExpired(d.expiresAt) && (
                    <IconButton className={classes.actionButton} onClick={() => copyLink(d)}>
                      <SvgIcon icon={Icons.SAVE} size={1.5} />
                    </IconButton>
                  )}
                </ButtonGroup>
              )}
              {isMila && (
                <ButtonGroup>
                  <IconButton id="refresh-link-button" className={classes.actionButton}>
                    <SvgIcon icon={Icons.UNDO} size={1.5} />
                  </IconButton>
                  {!isExpired(d.expiresAt) && (
                    <IconButton id="visit-link-button" className={classes.actionButton}>
                      <SvgIcon icon={Icons.SAVE} size={1.5} />
                    </IconButton>
                  )}
                </ButtonGroup>
              )}
            </div>
            {!isCompassUser && (
              <IconButton
                className={classes.removeButton}
                onClick={() => {
                  setSlugToDelete(d.slug);
                  setConfirmDeleteOpen(true);
                }}
              >
                <SvgIcon icon={Icons.CANCEL} />
              </IconButton>
            )}
            {isMila && (
              <IconButton id="delete-link-button" className={classes.removeButton}>
                <SvgIcon icon={Icons.CANCEL} />
              </IconButton>
            )}

            {confirmDeleteOpen && (
              <ConfirmationModal
                open={confirmDeleteOpen}
                onCancel={() => setConfirmDeleteOpen(false)}
                onConfirm={handleDelete}
                title={t('showcase.myLinks.confirmDeleteTitle')}
                text={t('showcase.myLinks.confirmDeleteText')}
                cancelButtonText={t('common.no')}
                cancelButtonIcon={Icons.CANCEL}
                confirmButtonText={t('common.yes')}
                confirmButtonIcon={Icons.SAVE}
              />
            )}
          </div>
        ))}

        {!isCompassUser && (
          <div className={clsx(classes.row, classes.addButton)}>
            <Button icon={Icons.PLUS} onClick={() => setAddDialogOpen(true)}>
              {t('showcase.myLinks.addLink')}
            </Button>

            {copiedLink && (
              <BlurDialog
                open={Boolean(copiedLink)}
                title={t('showcase.myLinks.copiedTitle')}
                onClose={() => setCopiedLink(null)}
                actions={
                  <Button onClick={() => setCopiedLink(null)}>{t('forms.buttons.close')}</Button>
                }
              >
                <Html>{t('showcase.myLinks.copiedBody', { showcase: copiedLink.title })}</Html>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </BlurDialog>
            )}

            {addDialogOpen && (
              <LinkDialogForm
                showcase={selectedShowcase}
                open={addDialogOpen}
                onClose={() => {
                  setAddDialogOpen(false);
                  setSelectedShowcase(undefined);
                }}
              />
            )}
          </div>
        )}
      </Paper>
    </>
  );
};

export default LinkTable;
