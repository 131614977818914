import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from '../config/theme';
import QuotedPerson from './QuotedPerson';

type Props = {
  quotedText: string;
  image: string;
  name: string;
  func: string;
  alt: string;
};

const useStyles = makeStyles(() => ({
  quoteRoot: {},
  quotePaper: {
    padding: '4rem',
    marginTop: '2rem',
    fontSize: '1.7rem',
    textAlign: 'center',
    position: 'relative',
    boxShadow: '0px 28px 0px -19px rgba(223,223,223,1)',
  },
  icon: {
    position: 'absolute',
    height: '5rem',
    width: '5rem',
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    transform: 'rotate(-78deg) skew(-25deg)',
    left: '18rem',
    bottom: '-5px',
    boxShadow: '-29px 0px 0px -18px rgba(223,223,223,1)',
  },
}));

const Quote = ({ quotedText, image, name, func, alt }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.quoteRoot}>
      <Paper className={classes.quotePaper}>
        <p>{quotedText} </p>
        <div className={classes.icon} />
      </Paper>
      <QuotedPerson image={image} name={name} func={func} alt={alt} />
    </div>
  );
};

export default Quote;
