import React, { useState, useEffect } from 'react';
import { ApiConfig } from '../../shared/api';
import useFetch from '../../../helpers/useFetch';
import { User, RelationContext } from '../../../config/api/models';
import UserCard from '../../../components/UserCard';
import moment from 'moment';
import ButtonGroup from '../../../components/ButtonGroup';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../components/SvgIcon';
import { Paper, makeStyles, Theme } from '@material-ui/core';

type Props = {
  initiator: User;
  relationId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  peerInvitationRoot: {
    width: '25rem',
    margin: '1rem',
    position: 'relative',
    padding: '0 1rem',
  },
  buttonGroup: {
    borderTop: theme.config.defaultBorder,
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '1rem 0',
  },
}));

const PeerInvitation = ({ initiator, relationId }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [relationAcceptRequest, relationAccept] = useFetch(ApiConfig.relationAccept('peer'));
  const [relationDeclineRequest, relationDecline] = useFetch(ApiConfig.relationDecline('peer'));

  const [requesting, setRequesting] = useState<boolean>(false);

  const handleAccept = () => {
    const relationAcceptDTO: Partial<RelationContext> = {
      acceptedAt: moment()
        .toISOString()
        .split('.')[0],
    };
    setRequesting(true);
    relationAccept(relationId, relationAcceptDTO);
  };

  const handleDecline = () => {
    const relationDeclineDTO: Partial<RelationContext> = {
      declinedAt: moment()
        .toISOString()
        .split('.')[0],
    };
    setRequesting(true);
    relationDecline(relationId, relationDeclineDTO);
  };

  useEffect(() => {
    if (relationAcceptRequest && relationAcceptRequest.fulfilled && requesting) {
      setRequesting(false);
    }

    if (relationDeclineRequest && relationDeclineRequest.fulfilled && requesting) {
      setRequesting(false);
    }
  }, [relationAcceptRequest, relationDeclineRequest, requesting]);

  return (
    <Paper className={classes.peerInvitationRoot}>
      <UserCard user={initiator} />

      <ButtonGroup className={classes.buttonGroup}>
        <Button icon={Icons.CHECK_CIRCLE} confirm disabled={requesting} onClick={handleAccept}>
          {t('myCoach.invitations.accept')}
        </Button>
        <Button
          icon={Icons.CANCEL}
          color={'secondary'}
          disabled={requesting}
          onClick={handleDecline}
        >
          {t('myCoach.invitations.decline')}
        </Button>
      </ButtonGroup>
    </Paper>
  );
};

export default PeerInvitation;
