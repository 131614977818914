import { Action } from 'redux';
import { FETCH, FetchAction, PromiseState } from 'react-redux-fetch';
import { JsonApiListResponse, JsonApiResponse } from '../config/api/models';
import { ImmutableObject } from 'seamless-immutable';

interface Config {
  itemResourceName: string;
}

/**
 * This function creates a reducer which can be used by react-redux-fetch.
 * It takes fulfilled PATCH requests and replaces the correct item in the list of items of the same type.
 *
 * Usage:
 * import { container } from 'react-redux-fetch';
 * container.registerReducer('yourListResourceName', createListReducer({ itemResourceName: 'yourItemResourceName' }));
 *
 * @param config
 */
const createListReducer = (config: Config) => (
  state: ImmutableObject<PromiseState<JsonApiListResponse>>,
  action: Action
) => {
  switch (action.type) {
    case FETCH.for('patch').FULFILL:
      const patchAction = action as FetchAction<JsonApiResponse>;

      if (
        state.value &&
        patchAction.resource.name === config.itemResourceName &&
        patchAction.request!.meta.response.status !== 204
      ) {
        const index = state.value.data.findIndex(item => item.id === patchAction.value!.data.id);
        if (index !== -1) {
          return state.setIn(
            ['value', 'data', index.toString(), 'attributes'],
            patchAction!.value!.data.attributes
          );
        }
      }
      break;
    case FETCH.for('post').FULFILL:
      const postAction = action as FetchAction<JsonApiResponse>;

      if (state.value && postAction.resource.name === config.itemResourceName) {
        const index = state.value.data.findIndex(item => item.id === postAction.value!.data.id);

        if (index !== -1) {
          return state.setIn(
            ['value', 'data', index.toString(), 'attributes'],
            postAction!.value!.data.attributes
          );
        }

        return state.setIn(
          ['value', 'data', state.value.data.length.toString()],
          postAction!.value!.data
        );
      }
      break;
    case FETCH.for('delete').FULFILL:
      const deleteAction = action as FetchAction<JsonApiResponse>;

      if (state.value && deleteAction.resource.name === config.itemResourceName) {
        return state.setIn(
          ['value', 'data'],
          // @ts-ignore
          state.value.data.filter(item => item.links.self !== deleteAction.request.url)
        );
      }
      break;
  }
  return state;
};

export default createListReducer;
