import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import theme from '../../../config/theme';
import Odisee from '../../../assets/odisee.jpg';
import Maastricht from '../../../assets/maastricht-university.png';
import Denys from '../../../assets/denys.png';
import Kapoentje from '../../../assets/ckg-kapoentje.jpg';
import HDG from '../../../assets/huis-der-gezinnen.png';
import KBO from '../../../assets/kbo-scholengemeenschap.jpg';
import Klasse from '../../../assets/klasse.jpg';
import Tonuso from '../../../assets/tonuso.png';
import HSM from '../../../assets/huize-sint-monika.jpg';
import Kat from '../../../assets/katholiek_onderwijs.png';
import HomePageSection from '../../../components/HomePageSection';
import { useMediaQuery } from '@material-ui/core';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles(() => ({
  sectionFiveRoot: (props: StyleProps) => ({
    clipPath: 'polygon(0 0, 100% 38%, 100% 65%, 0 100%)',
    background: theme.palette.background.paper,
    marginLeft: '-3000px',
    paddingLeft: '3000px',
    marginRight: '-3000px',
    paddingRight: '3000px',
    paddingTop: props.isMobile ? '45rem' : '35rem',
    paddingBottom: props.isMobile ? '40rem' : '20rem',
    marginTop: props.isMobile ? '-25rem' : '-10rem',
  }),
  logoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '5rem',
    alignItems: 'center',
  },
  logo: (props: StyleProps) => ({
    width: props.isMobile ? '37%' : '17%',
    marginBottom: '5rem',
  }),
  img: {
    width: '100%',
  },
}));

const SectionFive = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  return (
    <div className={classes.sectionFiveRoot}>
      <HomePageSection
        title={t('home.sectionFive.title')}
        subtitle={t('home.sectionFive.subtitle')}
        bodyText={t('home.sectionFive.text')}
      />
      <div className={classes.logoWrapper}>
        <div className={classes.logo}>
          <img src={Odisee} alt={t('home.sectionFive.alt1')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={Maastricht} alt={t('home.sectionFive.alt2')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={Kat} alt={t('home.sectionFive.alt3')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={Denys} alt={t('home.sectionFive.alt4')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={HDG} alt={t('home.sectionFive.alt6')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={Kapoentje} alt={t('home.sectionFive.alt5')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={KBO} alt={t('home.sectionFive.alt7')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={Klasse} alt={t('home.sectionFive.alt8')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={Tonuso} alt={t('home.sectionFive.alt9')} className={classes.img} />
        </div>
        <div className={classes.logo}>
          <img src={HSM} alt={t('home.sectionFive.alt10')} className={classes.img} />
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
