import React from 'react';
import { Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

type Props = {
  color: string;
  className: string;
  isActive: boolean;
};

const StickyUserMenuTriangle = ({ color, className, isActive }: Props) => {
  const theme: Theme = useTheme();

  return (
    <svg
      style={{ display: isActive ? 'block' : 'none' }}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.2 11.8"
    >
      <path
        fill={isActive ? color : theme.palette.grey['200']}
        d="M6 .3L.1 10.8c-.3.4.1 1 .6 1h11.9c.5 0 .8-.6.6-1L7.2.3c-.3-.4-.9-.4-1.2 0z"
      />
    </svg>
  );
};

export default StickyUserMenuTriangle;
