import React, { useState, useEffect } from 'react';
import ButtonGroup from '../ButtonGroup';
import { makeStyles, Theme, IconButton, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import SvgIcon, { Icons } from '../SvgIcon';
import ChatMessageFeed from './ChatMessageFeed';
import {
  ChatMessage,
  ContextType,
  ReflectionsResponse,
  User,
  InspirationType,
  RelationType,
} from '../../config/api/models';
import { ApiConfig as InspirationApiConfig } from '../../modules/inspiration/api';
import { ApiConfig as ChatApiConfig } from '../../modules/myCoach/api';
import useUser from '../../modules/security/hooks/useUser';
import ChatActions from './ChatActions';
import Dialog from '../Dialog';
import SectionTitle from '../SectionTitle';
import ModalBodyAlt from '../ModalBodyAlt';
import Html from '../Html';
import Loader from '../Loader';
import useFetch from '../../helpers/useFetch';
import { useTranslation } from 'react-i18next';
import { getUserDisplayName } from '../../helpers/getUserDisplayName';
import TextField from '../TextField';
import { getInspirationContext } from '../../modules/inspiration/helpers/helperFunctions';
import InspirationCollapsible from '../../modules/inspiration/containers/InspirationCollapsible';
import Spinner from '../Spinner';
import FormError from '../forms/FormError';
import useCurrentLanguage from '../../config/i18n/useCurrentLanguage';

type Props = {
  open: boolean;
  otherUser?: User;
  onToggle: () => void;
  loadingChat: boolean;
  messages: ChatMessage[];
  handleSendMessage: (message: string) => void;
  withInspiration?: boolean;
  relationType?: RelationType;
  contextType?: ContextType;
  contextId?: string;
  disabled?: boolean;
  setMessages: (value: React.SetStateAction<ChatMessage[]>) => void;
};

enum TabOptions {
  CHAT = 'chat',
  INSPIRATION = 'inpsiration',
  CONTEXT = 'context',
}

const useStyles = makeStyles((theme: Theme) => ({
  chatMobileRoot: {
    height: '100%',
  },
  chatContainer: {
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
  chat: {
    height: 'calc(100% - 12.4rem)',
  },
  disabled: {
    height: 'calc(100% - 4.2rem)',
  },
  chatHeader: {
    padding: '1rem 0',
    margin: '0 3rem',
    fontWeight: 'bold',
    borderBottom: theme.config.defaultBorder,
  },
  buttonGroup: {
    borderBottom: theme.config.defaultBorder,
    backgroundColor: theme.palette.grey['100'],
    textAlign: 'center',
    margin: '-3rem -3rem 0',
    padding: '3rem 3rem 1rem',
  },
  iconButton: {
    border: theme.config.defaultBorder,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonFocus: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderColor: theme.palette.primary.main,
  },
  content: {
    padding: '2rem',
    margin: '0 -3rem',
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: 'calc(100% - 6.2rem)',
  },
  noContentPadding: {
    padding: 0,
  },
  contextContainer: {
    flex: 1,
    position: 'relative',
    padding: '0rem 5.5rem',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-2rem',
      marginRight: '-2rem',
      borderRadius: 0,
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  contextTitle: {
    marginTop: '0',
    marginBottom: '1rem',
    fontSize: '3rem',
    fontWeight: 800,
  },
  quoteIcon: {
    position: 'absolute',
    top: '7rem',
    left: '2rem',
    color: theme.palette.highlight.main,
  },
  inspirationBody: {
    margin: 0,
  },
  inspirationSelect: {
    margin: '1rem 0',
  },
  inspirationContent: {
    overflowX: 'scroll',
  },
}));

const ChatMobile = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      open,
      onToggle,
      messages,
      loadingChat,
      handleSendMessage,
      withInspiration,
      contextType,
      contextId,
      otherUser,
      relationType,
      disabled,
      setMessages,
    },
    ref
  ) => {
    const classes = useStyles();
    const currentUser = useUser();
    const { t } = useTranslation();
    const currentLanguage = useCurrentLanguage();

    const [tab, selectTab] = useState<TabOptions>(TabOptions.CHAT);
    const [inspirationTab, selectInspiration] = useState<InspirationType>(contextType || 'dream');

    const [inspirationFetchRequest, fetchInspiration] = useFetch<ReflectionsResponse>(
      InspirationApiConfig.getInspiration(
        getInspirationContext(inspirationTab, relationType || 'coachee'),
        currentLanguage
      )
    );
    const [getContextRequest, getContext] = useFetch(
      ChatApiConfig.getContext(contextType || 'dream', contextId || '')
    );
    const inspirationValue = inspirationFetchRequest && inspirationFetchRequest.value;
    const inspirationTabs: InspirationType[] = ['dream', 'focus', 'plan', 'learn', 'lookBack'];

    useEffect(() => {
      if (open) {
        if (!inspirationFetchRequest && tab === TabOptions.INSPIRATION) {
          fetchInspiration();
        }
        if (!getContextRequest && tab === TabOptions.CONTEXT) {
          getContext();
        }
      }
    }, [fetchInspiration, open, getContextRequest, getContext, inspirationFetchRequest, tab]);

    const chatComponent = (
      <div className={clsx(classes.chatContainer, tab === TabOptions.CHAT ? '' : classes.hidden)}>
        {otherUser && (
          <div className={classes.chatHeader}>{getUserDisplayName(otherUser, true)}</div>
        )}
        <div className={clsx(classes.chat, disabled ? classes.disabled : '')}>
          <ChatMessageFeed
            messages={messages}
            currentUser={currentUser}
            loading={loadingChat}
            ref={ref}
            setMessages={setMessages}
          />
        </div>
        {!disabled && (
          <ChatActions
            avatar={currentUser.media.profile}
            onSend={handleSendMessage}
            position="bottom"
          />
        )}
      </div>
    );

    const inspirationComponent = (
      <>
        <SectionTitle label={t('inspiration.title')} />
        <p className={classes.inspirationBody}>{t('inspiration.body')}</p>
        <TextField
          select
          onChange={event => selectInspiration(event.target.value as ContextType)}
          value={inspirationTab}
          className={classes.inspirationSelect}
        >
          {inspirationTabs.map(it => (
            <MenuItem value={it} key={it}>
              {t(`${it}.tabLabel`)}
            </MenuItem>
          ))}
        </TextField>
        {inspirationValue && inspirationValue.data.length > 0 ? (
          <div className={classes.inspirationContent}>
            <ModalBodyAlt>
              {inspirationValue.data.length === 1 ? (
                <Html>{inspirationValue.data[0].attributes.body}</Html>
              ) : (
                <>
                  {inspirationValue.data.map(inspirationData => (
                    <InspirationCollapsible
                      inspiration={inspirationData.attributes}
                      key={inspirationData.id}
                    />
                  ))}
                </>
              )}
            </ModalBodyAlt>
          </div>
        ) : (
          <Spinner message={t('loading')} />
        )}
      </>
    );

    const contextComponent = (
      <>
        {getContextRequest && getContextRequest.value ? (
          <div className={classes.contextContainer}>
            <div>
              <h2 className={classes.contextTitle}>
                {getContextRequest.value.data.attributes.title}
              </h2>
              <Html>{getContextRequest.value.data.attributes.body}</Html>
            </div>
            <div className={classes.quoteIcon}>
              <SvgIcon icon={Icons.QUOTE} />
            </div>
          </div>
        ) : getContextRequest && getContextRequest.rejected ? (
          <FormError>{t('error.private')}</FormError>
        ) : (
          <Loader />
        )}
      </>
    );

    return (
      <Dialog open={open} fullScreen={true} onClose={onToggle}>
        <div className={classes.chatMobileRoot}>
          <ButtonGroup className={classes.buttonGroup}>
            <IconButton
              className={clsx(
                classes.iconButton,
                tab === TabOptions.CHAT ? classes.buttonFocus : null
              )}
              onClick={() => selectTab(TabOptions.CHAT)}
            >
              <SvgIcon icon={Icons.CONVERSATION} size={2} />
            </IconButton>

            {Boolean(contextType && contextId) && (
              <IconButton
                className={clsx(
                  classes.iconButton,
                  tab === TabOptions.CONTEXT ? classes.buttonFocus : null
                )}
                onClick={() => selectTab(TabOptions.CONTEXT)}
              >
                <SvgIcon icon={Icons.DETAIL} size={2} />
              </IconButton>
            )}

            {withInspiration && (
              <IconButton
                className={clsx(
                  classes.iconButton,
                  tab === TabOptions.INSPIRATION ? classes.buttonFocus : null
                )}
                onClick={() => selectTab(TabOptions.INSPIRATION)}
              >
                <SvgIcon icon={Icons.LIGHT_BULB} size={2} />
              </IconButton>
            )}
          </ButtonGroup>

          <div
            className={clsx(
              classes.content,
              tab === TabOptions.CHAT ? classes.noContentPadding : ''
            )}
          >
            {chatComponent}
            {tab === TabOptions.CONTEXT && contextComponent}
            {tab === TabOptions.INSPIRATION && inspirationComponent}
          </div>
        </div>
      </Dialog>
    );
  }
);

export default ChatMobile;
