import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Tab, Tabs, Theme } from '@material-ui/core';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig as InspirationApiConfig } from '../api';
import { ApiConfig as ChatApiConfig } from '../../myCoach/api';
import SectionTitle from '../../../components/SectionTitle';
import Dialog from '../../../components/Dialog';
import Loader from '../../../components/Loader';
import {
  ContextType,
  ReflectionsResponse,
  RelationType,
  InspirationType,
} from '../../../config/api/models';
import Html from '../../../components/Html';
import ModalBodyAlt from '../../../components/ModalBodyAlt';
import useInspirationDialogContext from '../hooks/useInspirationDialogContext';
import { makeStyles } from '@material-ui/styles';
import { ContextDialogState } from '../../../components/chat/ChatContext';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import TabPanel from '../../../components/TabPanel';
import { getInspirationContext } from '../helpers/helperFunctions';
import InspirationCollapsible from './InspirationCollapsible';
import Spinner from '../../../components/Spinner';
import FormError from '../../../components/forms/FormError';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';

type Props = {
  children: React.ReactChild | React.ReactChild[];
  contextType: ContextType;
  contextId: string;
  showInspiration?: boolean;
  onClose: () => void;
  content: ContextDialogState;
  relationType: RelationType;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '2rem',
  },
  scrollable: {
    maxHeight: 'calc(100vh - 6rem)',
    display: 'flex',
    flexDirection: 'column',
  },
  contextTitle: {
    marginTop: '0',
    marginBottom: '1rem',
    fontSize: '3rem',
    fontWeight: 800,
  },
  quoteIcon: {
    position: 'absolute',
    top: '7rem',
    left: '2rem',
    color: theme.palette.highlight.main,
  },
  contextContainer: {
    flex: 1,
    position: 'relative',
    padding: '0rem 5.5rem',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-2rem',
      marginRight: '-2rem',
      borderRadius: 0,
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  inspirationBody: {
    marginTop: 0,
    marginBottom: '0.5rem',
  },
}));

const InspirationChatDialog = ({
  children,
  onClose,
  showInspiration,
  contextType,
  contextId,
  content,
  relationType,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<InspirationType>(contextType);
  const { isOpen } = useInspirationDialogContext('InspirationChatDialog');
  const tabs: InspirationType[] = ['dream', 'focus', 'plan', 'learn', 'lookBack'];
  const currentLanguage = useCurrentLanguage();

  const [inspirationFetchRequest, fetchInspiration] = useFetch<ReflectionsResponse>(
    InspirationApiConfig.getInspiration(
      getInspirationContext(selectedTab, relationType),
      currentLanguage
    )
  );
  const [getContextRequest, getContext] = useFetch(
    ChatApiConfig.getContext(contextType, contextId)
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: ContextType) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (isOpen) {
      if (!getContextRequest && content === 'context') {
        getContext();
      } else if (content === 'inspiration' && !inspirationFetchRequest) {
        fetchInspiration();
      }
    }
  }, [
    fetchInspiration,
    isOpen,
    getContextRequest,
    getContext,
    inspirationFetchRequest,
    content,
    selectedTab,
  ]);

  const finalIsOpen = showInspiration ? showInspiration : isOpen;
  const reflections =
    inspirationFetchRequest && inspirationFetchRequest.value
      ? inspirationFetchRequest.value.data
      : undefined;

  const inspirationComponent = (
    <>
      <SectionTitle label={t('inspiration.title')} />
      <p className={classes.inspirationBody}>{t('inspiration.body')}</p>
      <Tabs variant="fullWidth" onChange={handleChange} value={selectedTab}>
        {tabs.length > 0 &&
          tabs.map(item => (
            <Tab label={t(`${item}.tabLabel`)} key={`inspirationChat${item}`} value={item} />
          ))}
      </Tabs>
      {tabs.length > 0 &&
        tabs.map(item => (
          <TabPanel value={selectedTab} index={item} key={`inspirationChat${item}`}>
            <ModalBodyAlt>
              {reflections ? (
                reflections.length === 1 ? (
                  <Html>{reflections[0].attributes.body}</Html>
                ) : (
                  <>
                    {reflections.map(reflectionData => (
                      <InspirationCollapsible
                        inspiration={reflectionData.attributes}
                        key={reflectionData.id}
                      />
                    ))}
                  </>
                )
              ) : (
                <Spinner message={t('loading')} />
              )}
            </ModalBodyAlt>
          </TabPanel>
        ))}
    </>
  );

  const contextComponent = (
    <>
      {getContextRequest && getContextRequest.value ? (
        <div className={classes.contextContainer}>
          <div>
            <h2 className={classes.contextTitle}>
              {getContextRequest.value.data.attributes.title}
            </h2>
            <Html>{getContextRequest.value.data.attributes.body}</Html>
          </div>
          <div className={classes.quoteIcon}>
            <SvgIcon icon={Icons.QUOTE} />
          </div>
        </div>
      ) : getContextRequest && getContextRequest.rejected ? (
        <FormError>{t('error.private')}</FormError>
      ) : (
        <Loader />
      )}
    </>
  );

  return (
    <>
      {children}

      {finalIsOpen && (
        <Dialog open={true} fullScreen onClose={onClose}>
          <Grid container spacing={4} className={classes.container}>
            <Grid item xs={6} className={classes.scrollable}>
              {children}
            </Grid>
            <Grid item xs={6} className={classes.scrollable}>
              {content === 'inspiration' && inspirationComponent}
              {content === 'context' && contextComponent}
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default InspirationChatDialog;
