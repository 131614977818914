import React, { useContext } from 'react';
import Routes from './Routes';
import LoggedOutFallBackPage from '../components/LoggedOutFallBackPage';
import ClientRoutesContext from '../../../config/clientRoutes/ClientRoutesContext';
import getPublicRoutes from '../config/publicRoutes';
import LoggedOutWrapper from '../components/LoggedOutWrapper';

const LoggedOutApp = () => {
  const { routes } = useContext(ClientRoutesContext);

  return (
    routes && (
      <Routes
        routes={getPublicRoutes(routes)}
        wrapper={LoggedOutWrapper}
        fallBackPage={() => <LoggedOutFallBackPage routes={routes} />}
      />
    )
  );
};

export default LoggedOutApp;
