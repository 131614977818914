import React, { useState } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { Redirect } from 'react-router-dom';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as SharedApiConfig } from '../api';
import { useTranslation } from 'react-i18next';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import useUser from '../../security/hooks/useUser';
import { Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getMilaUserId } from '../selectors';
import { Actions } from '../actions';

const MyShowcaseTour = () => {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const [showTour, setShowTour] = useState(true);
  const [tourDone, setTourDone] = useState(false);
  const [, patchUser] = useFetch<UserResponse>(SharedApiConfig.updateTourDone());
  const currentUser = useUser();
  const milaUserId = useSelector(getMilaUserId);
  const dispatch = useDispatch();

  const steps = [
    {
      target: '#tabs',
      content: t('tour.steps.myShowcase.first'),
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = (data: any) => {
    const { status, action } = data;
    if (
      [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
    ) {
      dispatch(Actions.enableHelp());
      setShowTour(false);
      const userUpdate = {
        showcaseTourDone: 1,
      };
      patchUser(currentUser.entityId, userUpdate);
    }
    if (status === STATUS.FINISHED) {
      setTourDone(true);
    }
  };

  return (
    <>
      <Joyride
        steps={steps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        showProgress
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.mila'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={theme.config.joyRideStyles.tour}
      />
      {tourDone && <Redirect to={getPath('showcasePage').replace(':userId', milaUserId)} />}
    </>
  );
};

export default MyShowcaseTour;
