import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import {
  FileDTO,
  FocusRequest,
  FocusResponse,
  InspirationContext,
} from '../../../../config/api/models';
import FormFields from '../../components/FormFields';
import ButtonGroup from '../../../../components/ButtonGroup';
import Button from '../../../../components/Button';
import { Icons } from '../../../../components/SvgIcon';
import FormError from '../../../../components/forms/FormError';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import inspiration from '../../../inspiration';
import { PromiseState } from 'react-redux-fetch';
import { FocusSubmitValues } from '../../model';
import FileList from '../../../shared/components/FileList';
import { getPersistingFiles } from '../../../shared/selectors';
import { useParams } from 'react-router-dom';

type FocusFormValues = {
  title: string;
  body: string;
  files: FileDTO[];
};

type Props = {
  onSave: (values: FocusSubmitValues) => void;
  onClose?: () => void;
  onSubmit?: () => void;
  focusResponse?: PromiseState<FocusResponse>;
  initialValues?: FocusFormValues;
  visibility: boolean;
};

const FocusForm = ({
  onSave,
  onClose,
  focusResponse,
  onSubmit,
  initialValues,
  visibility,
}: Props) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [disableSubmit, preventSubmit] = useState(false);
  const [files] = useState(initialValues ? initialValues.files : []);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);
  const [filesChanged, setFilesChanged] = useState<boolean>(false);
  const persistingFiles = useSelector(getPersistingFiles);

  const { dreamId } = useParams();

  useEffect(() => {
    if (focusResponse && focusResponse.fulfilled && submitting && onSubmit) {
      onSubmit();
      setSubmitting(false);
    }
  }, [submitting, focusResponse, onSubmit]);

  const handleFilesChange = (newFilesList: File[], fileIdsToDeleteList: string[]) => {
    setNewFiles(newFilesList);
    setFileIdsToDelete(fileIdsToDeleteList);
    setFilesChanged(!!(newFilesList.length || fileIdsToDeleteList.length));
  };

  return (
    <Form
      onSubmit={(values: FocusFormValues) => {
        const request: FocusRequest = {
          title: values.title.trim(),
          body: values.body.trim(),
          dreamId,
          visibility,
        };

        setSubmitting(true);
        onSave({ request, filesToAdd: newFiles, fileIdsToDelete });
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, form, values }) => (
        <form onSubmit={handleSubmit}>
          <inspiration.InspirationFormDialog
            inspirationKey={InspirationContext.COACHEE_FOCUS}
            onSubmit={form.submit}
            editing
          >
            <>
              <FormFields
                onPreventSubmit={preventSubmit}
                titlePlaceholder={t('focus.titlePlaceholder')}
                textPlaceholder={t('focus.textPlaceholder')}
              />
              <FileList
                files={files}
                editable
                onFilesChange={handleFilesChange}
                loading={persistingFiles === 'focus'}
              />
            </>
          </inspiration.InspirationFormDialog>
          <ButtonGroup>
            <Button
              type="submit"
              disabled={
                (focusResponse && focusResponse.pending) ||
                (pristine && !filesChanged) ||
                persistingFiles === 'focus' ||
                disableSubmit ||
                !values.title ||
                !values.body
              }
              icon={Icons.SAVE}
            >
              {t('forms.buttons.save')}
            </Button>
            <Button type="button" onClick={onClose} color="secondary" icon={Icons.CANCEL}>
              {t('forms.buttons.cancel')}
            </Button>
          </ButtonGroup>
          {focusResponse &&
            focusResponse.rejected &&
            focusResponse.reason &&
            focusResponse.reason.cause.errors.map((item: any, index: number) => (
              <FormError key={index}>{item.detail}</FormError>
            ))}
        </form>
      )}
    />
  );
};

export default FocusForm;
