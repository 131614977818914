import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Theme, useMediaQuery } from '@material-ui/core';
import { FC, HTMLProps } from 'react';

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  list: ({ isMobile }: StyleProps) => ({
    display: 'block',
    background: theme.palette.common.white,
    border: theme.config.defaultBorder,
    borderRadius: theme.shape.borderRadius,
    marginBottom: isMobile ? '3rem' : 0,
    marginLeft: isMobile ? '-2rem' : 0,
    marginRight: isMobile ? '-2rem' : 0,
    [theme.breakpoints.up('md')]: {
      float: 'left',
      position: 'sticky',
      top: '2rem',
      left: 0,
      zIndex: 999,
      width: '25rem',
    },
  }),
  contentWrapper: {
    '&:after': {
      content: '""',
      clear: 'both',
      display: 'table',
    },
  },
  content: (props: StyleProps) => ({
    float: props.isMobile ? 'none' : 'right',
    width: props.isMobile ? '100%' : 'calc(100% - 29rem)',
    '& section:first-child': {
      marginTop: 0,
    },
  }),
  button: {
    marginLeft: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: '0.5rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: '0.5rem',
    },
  },
}));

const useAtomClasses = () => {
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles({ isMobile });

  return classes;
};

type MyCoachLayoutProps = HTMLProps<HTMLDivElement>;
export const MyCoachLayout: FC<MyCoachLayoutProps> = ({ children, ...other }) => {
  const classes = useAtomClasses();

  return (
    <div className={classes.contentWrapper} {...other}>
      {children}
    </div>
  );
};

type MyCoachMainProps = HTMLProps<HTMLDivElement>;
export const MyCoachMain: FC<MyCoachMainProps> = ({ children, ...other }) => {
  const classes = useAtomClasses();

  return (
    <div className={classes.content} {...other}>
      {children}
    </div>
  );
};

type MyCoachASideProps = HTMLProps<HTMLDivElement>;
export const MyCoachASide: FC<MyCoachASideProps> = ({ children, ...other }) => {
  const classes = useAtomClasses();

  return (
    <div className={classes.list} {...other}>
      {children}
    </div>
  );
};

type SectionButtonContainerProps = HTMLProps<HTMLDivElement>;
export const SectionButtonContainer: FC<SectionButtonContainerProps> = ({ children, ...other }) => {
  const classes = useAtomClasses();

  return (
    <div className={classes.buttonContainer} {...other}>
      {children}
    </div>
  );
};

type SectionButtonProps = HTMLProps<HTMLDivElement>;
export const SectionButton: FC<SectionButtonProps> = ({ children, ...other }) => {
  const classes = useAtomClasses();

  return (
    <div className={classes.button} {...other}>
      {children}
    </div>
  );
};
