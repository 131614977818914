import React, { useEffect } from 'react';
import { CompassState } from '../../myCompass/model';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getCompassState, getCompassUserId } from '../../myCompass/selectors';
import { Actions } from '../../myCompass/actions';
import WithStickyUserMenu from '../../profile/components/WithStickyUserMenu';
import theme, { default as themeConfig } from '../../../config/theme';
import SaveShowcaseBanner from '../components/SaveShowcaseBanner';
import { Grid, useMediaQuery } from '@material-ui/core';
import Navigation from '../../profile/components/Navigation';
import useFetch from '../../../helpers/useFetch';
import { DreamsResponse } from '../../../config/api/models';
import { InferRequest } from 'react-redux-fetch';
import { ApiConfig } from '../../myCompass/api';
import security from '../../security';
import sortByDateUpdated from '../../../helpers/sortByDateUpdated';
import DreamCard from '../../myCompass/components/Dream/DreamCard';
import { makeStyles } from '@material-ui/styles';
import Section from '../../../components/Section';
import { useCompassUser } from '../../shared/hooks';
import { getMilaUserId } from '../../shared/selectors';

const apiConfig = ApiConfig.loadAllDreams();

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingRight: '2rem',
  },
}));

const MySettings = () => {
  const dreamLink = useSelector(security.selectors.getDreamLink());
  const { t } = useTranslation();
  const compassState = useSelector(getCompassState);
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hash = window.location.hash.replace('#i-', '');

  const classes = useStyles();

  const [dreamsResponse, fetchDreams] = useFetch<DreamsResponse, InferRequest<typeof apiConfig>>(
    apiConfig
  );

  useEffect(() => {
    if (compassState !== CompassState.MYSHOWCASE) {
      dispatch(Actions.setCompassState(CompassState.MYSHOWCASE));
    }
  });

  useEffect(() => {
    dispatch(Actions.selectFocus());
  }, [dispatch]);

  const isLoading = !dreamsResponse || !dreamsResponse.value;
  const dreams = (dreamsResponse && dreamsResponse.value && dreamsResponse.value.data) || [];

  useEffect(() => {
    if (dreamLink) {
      fetchDreams(dreamLink);
    }
  }, [dreamLink, fetchDreams]);

  const navigation = ['dream', 'focus', 'learn', 'lookBack', 'milestone'];
  const activeMenuItemIndex = navigation.findIndex(navItem => hash === navItem.toLowerCase());

  const navItems = navigation.map(navItem => (
    <Link smooth to={`#i-${navItem.toLowerCase()}`} key={navItem}>
      {t(`${navItem}.menuItem`)}
    </Link>
  ));

  if (isLoading) {
    return null;
  }

  return (
    <WithStickyUserMenu
      profilePictureEditable={false}
      color={themeConfig.palette.myCompass.red}
      show
      navigation={{ navItems, activeMenuItemIndex }}
    >
      {isMobile && <Navigation navItems={navItems} color={themeConfig.palette.myCompass.red} />}

      {dreams.length > 0 && (
        <Section id="dreams" title={t('dream.dreams')} removeTopMargin>
          <div className={classes.wrapper}>
            <Grid container spacing={2}>
              {dreams &&
                sortByDateUpdated(dreams, isMila).map(dream => (
                  <React.Fragment key={dream.attributes.entityId}>
                    <Grid item xs={12} sm={6} lg={4}>
                      <DreamCard dream={dream.attributes} />
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          </div>
        </Section>
      )}

      {isMobile && <SaveShowcaseBanner />}
    </WithStickyUserMenu>
  );
};

export default MySettings;
