import { FETCH, FetchAction } from 'react-redux-fetch';
import { Action } from 'redux';
import { State } from './model';
import isFetchAction from '../../helpers/isFetchAction';
import { LoginResponse, makeUserId } from '../../config/api/models';

type SecurityReducer = (state: State | undefined, action: FetchAction | Action) => State;

const initialState: State = {
  token: '',
  userId: makeUserId('-1'),
};

const securityReducer: SecurityReducer = (state = initialState, action) => {
  if (!isFetchAction(action)) {
    return state;
  }

  switch (action.type) {
    case FETCH.for('post').FULFILL:
      if (action.value.access_token) {
        const value = (action as FetchAction<LoginResponse>).value;
        return {
          token: value!.access_token,
          userId: makeUserId(value!.id.toString()),
        };
      }
  }
  return state;
};

export default securityReducer;
