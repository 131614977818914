import { getUser } from '../selectors';
import { useSelector } from 'react-redux';
import { User } from '../../../config/api/models';

const useUser = (): User => {
  const user = useSelector(getUser);
  return user || ({} as User);
};

export default useUser;
