import React from 'react';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { RegisterRequest } from '../../../config/api/models';
import { useTranslation } from 'react-i18next';
import FormControl from '../../../components/FormControl';
import { Field, Form } from 'react-final-form';
import { TextFieldAdapter, validations } from '../../../components/forms';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import Message from '../../../components/Message';
import FormError from '../../../components/forms/FormError';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  message: {},
}));

const RegisterForm = () => {
  const { t } = useTranslation();
  const [registerRequest, register] = useFetch<RegisterRequest>(ApiConfig.register());
  const classes = useStyles();

  if (registerRequest && registerRequest.fulfilled) {
    return <Message type="info">{t('forms.feedback.sent')}</Message>;
  }

  return (
    <Form
      onSubmit={(values: RegisterRequest) => {
        register(values);
      }}
      initialValues={{ name: '', organisation: '', number: '', registerEmail: '', message: '' }}
      render={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <FormControl label={t('forms.labels.fullName')} id="name" required>
            <Field name="name" component={TextFieldAdapter} validate={validations(t).required} />
          </FormControl>
          <FormControl label={t('forms.labels.org')} id="organisation" required>
            <Field
              name="organisation"
              component={TextFieldAdapter}
              validate={validations(t).required}
            />
          </FormControl>
          <FormControl label={t('forms.labels.tel')} id="number">
            <Field name="number" component={TextFieldAdapter} validate={validations(t).isNumber} />
          </FormControl>
          <FormControl label={t('forms.labels.email')} id="registerEmail" required>
            <Field
              name="registerEmail"
              component={TextFieldAdapter}
              validate={validations(t).isEmail()}
            />
          </FormControl>
          <FormControl label={t('forms.labels.message')} id="message" required>
            <Field
              name="message"
              component={TextFieldAdapter}
              validate={validations(t).required}
              className={classes.message}
            />
          </FormControl>
          <div>
            <Button
              type="submit"
              disabled={(registerRequest && registerRequest.pending) || pristine}
              icon={Icons.SAVE}
              blue
            >
              {t('forms.buttons.mail')}
            </Button>
          </div>
          {registerRequest && registerRequest.rejected && (
            <FormError>{t('forms.feedback.notSent')}</FormError>
          )}
        </form>
      )}
    />
  );
};

export default RegisterForm;
