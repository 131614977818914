import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import { Post } from '../../../config/api/models';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCompassUserId, getCompassUserInfo } from '../../myCompass/selectors';
import { getMilaUserId } from '../selectors';

type Props = {
  id?: string;
  currentPost?: Post;
  onClick: (value: 'add' | 'edit' | 'delete') => void;
  disabled?: boolean;
};

const useStyles = makeStyles(() => ({
  sharingRoot: {
    margin: '0.5rem 1.6rem',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  button: {
    margin: '0.5rem 0',
  },
}));

const SidebarSharing = ({ id, currentPost, onClick, disabled }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const compassUserId = useSelector(getCompassUserId);
  const compassUser = useSelector(getCompassUserInfo);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = compassUser && compassUserId === milaUserId;

  return (
    <div id={id} className={classes.sharingRoot}>
      <div className={classes.title}>{t('sidebar.options')}</div>
      {currentPost && !disabled ? (
        <>
          <div>
            {t('posts.sharedOn', {
              date: moment(currentPost.updatedAt).format('D MMMM YYYY'),
            })}
          </div>

          <Button className={classes.button} icon={Icons.REPUBLISH} onClick={() => onClick('edit')}>
            {t('sidebar.reShare')}
          </Button>

          <Button
            className={classes.button}
            icon={Icons.UNPUBLISH}
            onClick={() => onClick('delete')}
          >
            {t('sidebar.unShare')}
          </Button>
        </>
      ) : (
        <Button
          className={classes.button}
          icon={Icons.PUBLISH}
          onClick={isMila ? undefined : () => onClick('add')}
          disabled={isMila ? false : disabled}
        >
          {t('sidebar.share')}
        </Button>
      )}
    </div>
  );
};

export default SidebarSharing;
