import React, { useState, useEffect } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  CONTEXT_TYPE_DREAM,
  ContextType,
  Dream,
  Focus,
  RelationContext,
  User,
} from '../../../config/api/models';
import UserCard from '../../../components/UserCard';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import ButtonGroup from '../../../components/ButtonGroup';
import clsx from 'clsx';
import moment from 'moment';
import { ApiConfig } from '../../shared/api';
import useFetch from '../../../helpers/useFetch';
import { Link } from 'react-router-dom';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';

type Props = {
  relationId: string;
  initiator: User;
  contextType: ContextType;
  context: Dream | Focus;
  className?: string;
  description?: string;
  showcaseSlug?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  coachInvitationRoot: {
    position: 'relative',
    background: theme.palette.common.white,
    border: theme.config.defaultBorder,
    borderRadius: theme.shape.borderRadius + 'px',
    overflow: 'hidden',
    minHeight: '6.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  userCard: {
    width: theme.config.sidebarWidth,
    borderRight: theme.config.defaultBorder,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRight: 'none',
    },
  },
  invitationInfo: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: '1rem 2rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem 1rem 1rem',
    },
  },
  context: {
    flexGrow: 1,
    padding: '1rem 2rem',
    display: 'flex',
    alignContent: 'flex-start',
    alignItems: 'center',
    minWidth: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: '1rem',
    },
  },
  contextLevel: {
    width: '7rem',
    minWidth: '7rem',
    fontSize: '1.2rem',
    lineHeight: '2.4rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginRight: '2rem',
    },
  },
  contextTitle: {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: 700,
    color: theme.palette.common.black,
  },
  contextDescription: ({ clickedReadMore }: { clickedReadMore: boolean }) => ({
    fontWeight: 500,
    fontSize: '1.3rem',
    margin: 0,
    textOverflow: 'ellipsis',
    overflow: !clickedReadMore ? 'hidden' : 'unset',
    whiteSpace: !clickedReadMore ? 'nowrap' : 'pre-wrap',
  }),
  contextDescriptionWrapper: {
    display: 'flex',
  },
  buttonGroup: {
    display: 'flex',
    padding: '0 2rem 0 0',
  },
  readMoreButton: { flexShrink: 0, paddingTop: 0, paddingBottom: 0 },
  showcaseSpan: {
    fontSize: '1.3rem',
  },
  showcaseLink: {
    textTransform: 'none',
    fontSize: '1.3rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.grey['700'],
    },
  },
}));

const CoachInvitation = (props: Props) => {
  const { relationId, initiator, contextType, context, className } = props;
  const [clickedReadMore, setclickedReadMore] = useState(false);
  const classes = useStyles({ clickedReadMore });
  const { t } = useTranslation();
  const [requesting, setRequesting] = useState(false);
  const [relationAcceptRequest, relationAccept] = useFetch(ApiConfig.relationAccept());
  const [relationDeclineRequest, relationDecline] = useFetch(ApiConfig.relationDecline());
  const { getPath } = useI18nPath();

  useEffect(() => {
    if (relationAcceptRequest && relationAcceptRequest.fulfilled && requesting) {
      setRequesting(false);
    }

    if (relationDeclineRequest && relationDeclineRequest.fulfilled && requesting) {
      setRequesting(false);
    }
  }, [relationAcceptRequest, relationDeclineRequest, requesting]);

  const handleAccept = () => {
    const relationAcceptDTO: Partial<RelationContext> = {
      acceptedAt: moment()
        .toISOString()
        .split('.')[0],
    };
    setRequesting(true);
    relationAccept(relationId, relationAcceptDTO);
  };

  const handleDecline = () => {
    const relationDeclineDTO: Partial<RelationContext> = {
      declinedAt: moment()
        .toISOString()
        .split('.')[0],
    };
    setRequesting(true);
    relationDecline(relationId, relationDeclineDTO);
  };

  return (
    <div className={clsx(className, classes.coachInvitationRoot)}>
      <div className={classes.userCard}>
        <UserCard user={initiator} type="coachee" />
      </div>
      <div className={classes.invitationInfo}>
        <div className={classes.context}>
          <div className={classes.contextLevel}>
            {contextType === CONTEXT_TYPE_DREAM ? t('dream.title') : t('focus.title')}
          </div>
          <div style={{ minWidth: 0 }}>
            <div className={classes.contextTitle}>
              {context.title}{' '}
              {props.showcaseSlug != null && (
                <span className={classes.showcaseSpan}>
                  {' '}
                  <Link
                    className={classes.showcaseLink}
                    to={getPath('showcase')
                      .replace(
                        ':name',
                        (props.initiator.firstName + props.initiator.lastName).toLowerCase()
                      )
                      .replace(':slug', props.showcaseSlug)}
                    target="_blank"
                  >
                    {t('myCoach.invitations.showcaseLinkText')}
                  </Link>
                </span>
              )}
            </div>
            {props.description != null && (
              <div className={classes.contextDescriptionWrapper}>
                <p className={classes.contextDescription}>{props.description}</p>
                {!clickedReadMore && (
                  <Button
                    variant="text"
                    onClick={() => setclickedReadMore(true)}
                    className={classes.readMoreButton}
                  >
                    {t('myCoach.invitations.readMore')}
                    <span style={{ marginLeft: '0.5rem' }}>
                      <SvgIcon icon={Icons.CHEVRON_DOWN} size={0.8} />
                    </span>
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <ButtonGroup className={classes.buttonGroup}>
          <Button icon={Icons.CHECK_CIRCLE} confirm disabled={requesting} onClick={handleAccept}>
            {t('myCoach.invitations.accept')}
          </Button>
          <Button
            icon={Icons.CANCEL}
            color={'secondary'}
            disabled={requesting}
            onClick={handleDecline}
          >
            {t('myCoach.invitations.decline')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default CoachInvitation;
