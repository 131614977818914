import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles';
import { Theme, TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '.1rem solid #ced4da',
      fontSize: '1.6rem',
      padding: '1rem 1.2rem',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: theme.typography.fontFamily,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  })
);

const TextField = (props: TextFieldProps) => {
  const classes = useStyles();

  return (
    <MuiTextField
      fullWidth
      {...props}
      InputProps={
        { classes, disableUnderline: true, ...props.InputProps } as Partial<OutlinedInputProps>
      }
    />
  );
};

export default TextField;
