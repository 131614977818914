import * as React from 'react';
import { useEffect } from 'react';
import { TrayNotification, TrayNotificationResponse } from '../../../config/api/models';
import ProfilePicture from '../../../components/ProfilePicture';
import DefaultProfilePicture from '../../../components/DefaultProfilePicture';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import clsx from 'clsx';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { actions } from 'react-redux-fetch';
import { useDispatch } from 'react-redux';
import Moment from 'moment';
import Tooltip from '../../../components/Tooltip';
import { darken } from '@material-ui/core/styles';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { useHistory } from 'react-router-dom';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';

type Props = {
  item: TrayNotification;
  onUpdate: () => void;
};

type StyleProps = {
  isNew: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: ({ isNew }: StyleProps) => ({
    position: 'relative',
    padding: '1.5rem 6rem 1.5rem 7rem',
    fontSize: '1.2rem',
    minHeight: '7rem',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderBottom: isNew
        ? `.1rem solid ${darken(theme.palette.primary.light, 0.05)}`
        : theme.config.defaultBorder,
    },
    background: isNew ? theme.palette.primary.light : theme.palette.common.white,
    '&:hover': {
      background: theme.palette.grey['50'],
      '& > .arrow': {
        color: theme.palette.grey['500'],
      },
    },
    '&:first-child': {
      borderTopRightRadius: '.75rem',
      borderTopLeftRadius: '.75rem',
    },
    '&:last-child': {
      borderBottomRightRadius: '.75rem',
      borderBottomLeftRadius: '.75rem',
    },
  }),
  profilePicture: {
    position: 'absolute',
    top: '50%',
    left: '1rem',
    width: '5rem',
    height: '5rem',
    transform: 'translateY(-50%)',
  },
  authorName: {
    fontWeight: 700,
  },
  authorOrganisation: {
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
  subject: {
    fontWeight: 700,
  },
  unreadIndicator: {
    position: 'absolute',
    top: '50%',
    right: '3.5rem',
    transform: 'translateY(-50%)',
    width: '1rem',
    height: '1rem',
    background: theme.palette.highlight.main,
    borderRadius: '.5rem',
  },
  arrow: ({ isNew }: StyleProps) => ({
    position: 'absolute',
    top: '50%',
    right: '1.5rem',
    transform: 'translateY(-50%)',
    width: '1rem',
    height: '1rem',
    color: isNew ? theme.palette.grey['600'] : theme.palette.grey['400'],
  }),
}));

const createCommentSlug = (item: TrayNotification): string => {
  let result = '';

  switch (item.commentType) {
    case 'App\\Dream':
      result += '#dream-' + item.dream.toString();
      break;
    case 'App\\Focus':
      result += '#focus-' + item.focus.toString();
      break;
    case 'App\\Learn':
      result += '#learn-' + item.commentableId.toString();
      break;
    case 'App\\Milestone':
      result += '#milestone-' + item.commentableId.toString();
      break;
  }

  return result;
};

const NotificationTrayItem = ({ item, onUpdate }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getPath } = useI18nPath();
  const history = useHistory();
  const [patchTrayNotificationResponse, patchTrayNotification] = useFetch<TrayNotificationResponse>(
    ApiConfig.patchTrayNotification(item.entityId)
  );
  const lang = useCurrentLanguage();

  Moment.locale(lang);
  const now = Moment();
  const ms = Moment(now).diff(Moment(item.createdAt));
  const d = Moment.duration(ms);

  const classes = useStyles({ isNew: !item.readAt });

  const handleClick = () => {
    patchTrayNotification(item.entityId);
    switch (item.trayType) {
      case 'App\\Focus':
        history.push(
          getPath('dreamDetail')
            .replace(':userId', item.authorId)
            .replace(':dreamId', item.dream) +
            '/#focus-' +
            item.trayId,
          {
            focusId: item.focus ? item.focus.toString() : undefined,
          }
        );
        break;
      case 'App\\Lookback':
        history.push(
          getPath('dreamDetail')
            .replace(':userId', item.authorId)
            .replace(':dreamId', item.dream) +
            '/#lookBack-' +
            item.trayId,
          {
            focusId: item.focus ? item.focus.toString() : undefined,
          }
        );
        break;
      case 'App\\Milestone':
        history.push(
          getPath('dreamDetail')
            .replace(':userId', item.authorId)
            .replace(':dreamId', item.dream) +
            '/#milestone-' +
            item.trayId,
          {
            focusId: item.focus ? item.focus.toString() : undefined,
          }
        );
        break;
      case 'App\\Learn':
        history.push(
          getPath('dreamDetail')
            .replace(':userId', item.authorId)
            .replace(':dreamId', item.dream) +
            '/#learn-' +
            item.trayId,
          {
            focusId: item.focus ? item.focus.toString() : undefined,
          }
        );
        break;
      case 'App\\Comment':
        if (item.commentType === 'App\\Talent') {
          history.push(getPath('myTalents'), {
            commentableId: item.commentableId ? item.commentableId.toString() : undefined,
            commentType: item.commentType,
          });
        } else {
          history.push(
            getPath('dreamDetail')
              .replace(':userId', item.notificationForId)
              .replace(':dreamId', item.dream) + createCommentSlug(item),
            {
              dreamId: item.dream ? item.dream.toString() : undefined,
              focusId: item.focus ? item.focus.toString() : undefined,
              commentableId: item.commentableId ? item.commentableId.toString() : undefined,
              commentType: item.commentType,
            }
          );
        }
    }
  };

  useEffect(() => {
    if (!!patchTrayNotificationResponse && patchTrayNotificationResponse.fulfilled) {
      dispatch(actions.clear(item.entityId));
      onUpdate();
    }
  }, [patchTrayNotificationResponse, dispatch, onUpdate, item]);

  if (!item.author) {
    return null;
  }

  return (
    <Tooltip
      title={
        d.days() >= 1
          ? Moment(item.createdAt).format('DD MMMM YYYY HH:mm:ss')
          : Moment(item.createdAt).fromNow()
      }
      placement="top"
    >
      <div onClick={handleClick} className={classes.wrapper}>
        <div className={classes.profilePicture}>
          {item.author.media && item.author.media.profile ? (
            <ProfilePicture profilePicture={item.author.media.profile} />
          ) : (
            <DefaultProfilePicture />
          )}
        </div>
        <div
          className={classes.authorName}
        >{`${item.author.firstName} ${item.author.lastName}`}</div>
        {item.author.organisation && item.author.organisation.name && (
          <div className={classes.authorOrganisation}>{item.author.organisation.name}</div>
        )}
        {(() => {
          switch (item.trayType) {
            case 'App\\Dream':
              return (
                <>
                  <span>{t('notificationTray.addContent').split('%ITEM%')[0]}</span>
                  <span className={classes.subject}>{t('dream.title')}</span>
                  <span>{t('notificationTray.addContent').split('%ITEM%')[1]}</span>
                </>
              );
            case 'App\\Focus':
              return (
                <>
                  <span>{t('notificationTray.addContent').split('%ITEM%')[0]}</span>
                  <span className={classes.subject}>{t('focus.title')}</span>
                  <span>{t('notificationTray.addContent').split('%ITEM%')[1]}</span>
                </>
              );
            case 'App\\Learn':
              return (
                <>
                  <span>{t('notificationTray.addContent').split('%ITEM%')[0]}</span>
                  <span className={classes.subject}>{t('learn.title')}</span>
                  <span>{t('notificationTray.addContent').split('%ITEM%')[1]}</span>
                </>
              );
            case 'App\\Lookback':
              return (
                <>
                  <span>{t('notificationTray.addContent').split('%ITEM%')[0]}</span>
                  <span className={classes.subject}>{t('lookBack.tabLabel')}</span>
                  <span>{t('notificationTray.addContent').split('%ITEM%')[1]}</span>
                </>
              );
            case 'App\\Milestone':
              return <>{t('notificationTray.milestone')}</>;
            case 'App\\Comment':
              return <>{t('notificationTray.comment')}</>;
          }
        })()}
        {!item.readAt && <div className={classes.unreadIndicator} />}
        <SvgIcon icon={Icons.CHEVRON_RIGHT} className={clsx('arrow', classes.arrow)} />
      </div>
    </Tooltip>
  );
};

export default NotificationTrayItem;
