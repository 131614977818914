import rootReducer from './rootReducer';
import AppInfo from './components/AppInfo';
import LoggedOutApp from './containers/LoggedOutApp';
import LoggedInApp from './containers/LoggedInApp';
import handleSocket from '../shared/middleware/handleSocket';

const app = {
  LoggedInApp,
  LoggedOutApp,
  AppInfo,
  rootReducer,
  middleware: [handleSocket],
};

export default app;
