import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

type InfoLineProps = {
  label: string;
  value: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  line: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.4rem',
    padding: '2rem 0',
    '&:not(:last-child)': {
      borderBottom: theme.config.defaultBorder,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  label: {
    fontWeight: 300,
    width: '20rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  value: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const InfoLine = ({ label, value }: InfoLineProps) => {
  const classes = useStyles();
  return (
    <div className={classes.line}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

export default InfoLine;
