import React, { useCallback, useEffect, useState } from 'react';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import { Redirect } from 'react-router-dom';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as SharedApiConfig } from '../api';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import useUser from '../../security/hooks/useUser';
import { useDispatch, useSelector } from 'react-redux';
import { getMilaUserId } from '../selectors';
import { Actions } from '../actions';

let userPatched: boolean = false;

const MyCoachTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getPath } = useI18nPath();
  const currentUser = useUser();
  const milaUserId = useSelector(getMilaUserId);
  const [, patchUser] = useFetch<UserResponse>(SharedApiConfig.updateTourDone());
  const [showTour, setShowTour] = useState(true);
  const [tourDone, setTourDone] = useState(false);
  const dispatch = useDispatch();

  const steps = [
    {
      target: '#no-relations',
      content: t('tour.steps.myCoach.first'),
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
      ) {
        setShowTour(false);
      }
      if (status === STATUS.FINISHED) {
        setTourDone(true);
      }
    },
    [setShowTour, setTourDone]
  );

  useEffect(() => {
    if (!showTour && !userPatched) {
      const userUpdate = {
        coachTourDone: 1,
      };
      patchUser(currentUser.entityId, userUpdate);
      userPatched = true;
    }
  }, [showTour, currentUser, patchUser]);

  useEffect(() => {
    return () => {
      dispatch(Actions.enableHelp());
    };
  }, [dispatch]);

  return (
    <>
      <Joyride
        steps={steps}
        run={showTour}
        callback={handleTourCallback}
        continuous
        showProgress
        locale={{
          back: t('tour.locale.back'),
          close: t('tour.locale.close'),
          last: t('tour.locale.mila'),
          next: t('tour.locale.next'),
          skip: t('tour.locale.skip'),
        }}
        styles={{
          options: {
            beaconSize: 12,
            primaryColor: theme.palette.myCompass.blue,
          },
        }}
      />
      {tourDone && <Redirect to={getPath('coachingsPage').replace(':userId', milaUserId)} />}
    </>
  );
};

export default MyCoachTour;
