import { RouteConfig } from '../../../config/clientRoutes/types';
import getRoutes from '../../../helpers/getRoutes';
import myShowcase from '../../myShowcase';
import BasicPage from '../../shared/scenes/BasicPage';

const neutralRouteConfig: RouteConfig = {
  showcaseTalents: {
    component: myShowcase.ShowcaseTalentsPage,
  },
  showcase: {
    component: myShowcase.PublicShowcasePage,
    exact: true,
  },
  showcaseTalentsOld: {
    component: myShowcase.ShowcaseTalentsPage,
  },
  showcaseOld: {
    component: myShowcase.PublicShowcasePage,
    exact: true,
  },
  privacyPolicy: {
    component: BasicPage,
  },
  cookieCompliance: {
    component: BasicPage,
  },
};

const getNeutralRoutes = getRoutes(neutralRouteConfig);

export default getNeutralRoutes;
