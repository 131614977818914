import React, { useEffect, useState } from 'react';
import { UpdateUserRequest, User } from '../../../config/api/models';
import FormControl from '../../../components/FormControl';
import { Field, Form } from 'react-final-form';
import { TextFieldAdapter, validations } from '../../../components/forms';
import Button from '../../../components/Button';
import { Icons } from '../../../components/SvgIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import security from '../../security';
import useFetch from '../../../helpers/useFetch';
import { ApiConfig } from '../api';
import { SettingsFormComponent } from '../../settings/types';
import FormError from '../../../components/forms/FormError';
import ButtonGroup from '../../../components/ButtonGroup';

type UpdateUserFormValues = {
  lastName: string;
  firstName: string;
  email: string;
  secondaryEmail: string;
  education: string;
  department: string;
  function: string;
  organisation: string;
};

const SettingsForm: SettingsFormComponent = ({ onClose }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const userId = useSelector(security.selectors.getUserId);
  const currentUser = security.hooks.useUser();
  const [updateUserResponse, updateUser] = useFetch<User>(ApiConfig.updateUser(userId));

  useEffect(() => {
    if (updateUserResponse && updateUserResponse.fulfilled && submitting && onClose) {
      onClose();
    }
  }, [submitting, updateUserResponse, onClose]);

  return (
    <Form
      onSubmit={(values: UpdateUserFormValues) => {
        const request: UpdateUserRequest = {
          lastName: values.lastName,
          firstName: values.firstName,
          secondaryEmail: values.secondaryEmail,
          education: values.education,
          function: values.function,
          tourDone: currentUser.tourDone,
          talentsTourDone: currentUser.talentsTourDone,
          showcaseTourDone: currentUser.showcaseTourDone,
          coachTourDone: currentUser.coachTourDone,
          hasPublicVisibleComments: currentUser.hasPublicVisibleComments,
          allowReceivingWeeklyMail: currentUser.allowReceivingWeeklyMail,
        };

        setSubmitting(true);
        updateUser(request);
      }}
      initialValues={{
        lastName: currentUser.lastName,
        firstName: currentUser.firstName,
        email: currentUser.email,
        secondaryEmail: currentUser.secondaryEmail,
        education: currentUser.education,
        department: currentUser.department ? currentUser.department.name : '',
        function: currentUser.function,
        organisation: (currentUser.organisation && currentUser.organisation.name) || '',
      }}
      render={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <FormControl label={t('forms.labels.firstName')} id="firstName">
            <Field name="firstName" component={TextFieldAdapter} />
          </FormControl>
          <FormControl label={t('forms.labels.lastName')} id="lastName">
            <Field name="lastName" component={TextFieldAdapter} />
          </FormControl>
          <FormControl label={t('forms.labels.email')} id="email">
            <Field name="email" component={TextFieldAdapter} disabled />
          </FormControl>
          <FormControl label={t('forms.labels.alternateEmail')} id="secondaryEmail">
            <Field
              name="secondaryEmail"
              component={TextFieldAdapter}
              validate={validations(t).isEmail(false)}
            />
          </FormControl>
          <FormControl label={t('forms.labels.education')} id="education">
            <Field name="education" component={TextFieldAdapter} />
          </FormControl>
          <FormControl label={t('forms.labels.function')} id="function">
            <Field name="function" component={TextFieldAdapter} />
          </FormControl>
          {currentUser.organisation && (
            <FormControl label={t('forms.labels.organisation')} id="organisation">
              <Field name="organisation" component={TextFieldAdapter} disabled />
            </FormControl>
          )}
          <FormControl label={t('forms.labels.department')} id="department">
            <Field name="department" component={TextFieldAdapter} disabled />
          </FormControl>
          <ButtonGroup>
            <Button
              type="submit"
              disabled={(updateUserResponse && updateUserResponse.pending) || pristine}
              icon={Icons.SAVE}
            >
              {t('forms.buttons.save')}
            </Button>
            <Button
              type="button"
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
              color="secondary"
              icon={Icons.CANCEL}
            >
              {t('forms.buttons.cancel')}
            </Button>
          </ButtonGroup>
          {updateUserResponse && updateUserResponse.rejected && (
            <FormError>{t('forms.feedback.generic')}</FormError>
          )}
        </form>
      )}
    />
  );
};

export default SettingsForm;
