import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const defaultBorder: string = '.1rem solid #eee';

export type MyCompassColor = 'red' | 'green' | 'yellow' | 'blue';
export type CoachingColor = 'pink' | 'orange' | 'blue' | 'green';

const theme = createMuiTheme({
  palette: {
    primary: {
      // BLUE
      main: '#344bf5',
      medium: '#dadeff',
      light: '#eceeff',
    },
    secondary: {
      // RED
      main: '#f00',
      medium: '#ffc7c7',
      light: '#ffe0e0',
    },
    tertiary: {
      // GREEN
      main: '#21d713',
      medium: '#bafcb2',
      light: '#daffd6',
      dark: '#28ac1d',
    },
    highlight: {
      // YELLOW
      main: '#e1d512',
      medium: '#e9e046',
      light: '#fffcc7',
      dark: '#927800',
    },
    text: {
      primary: '#454545',
    },
    background: {
      default: '#f8f8f8',
      paper: '#FFF',
    },
    divider: '#ededed',
    myCompass: {
      red: '#ff5757',
      green: '#2be9b6',
      yellow: '#f7dc69',
      blue: '#106991',
    },
    coaching: {
      pink: '#B55594',
      orange: '#F29E14',
      blue: '#4E7CBF',
      green: '#42A681',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: ['Muli', 'Roboto', 'Arial', 'sans-serif'].join(','),
    h4: {
      color: '#454545',
      fontSize: '1.4rem',
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 5,
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: 0,
        padding: '0 2rem',
        textTransform: 'none',
        fontSize: '1.4rem',
        fontWeight: 700,
        '@media (min-width: 960px)': {
          minWidth: 0,
          fontSize: '1.4rem',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.4rem',
        fontWeight: 300,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: 'none',
        border: defaultBorder,
        backgroundColor: '#FFF',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255,255,255,.5)',
      },
    },
    MuiIconButton: {
      root: {
        marginTop: '0.5rem',
      },
    },
    MuiLink: {
      root: {
        textDecoration: 'underline',
        color: '#BDBDBD', // theme.palette.grey['400']
        '&:hover': {
          color: '#757575', // theme.palette.grey['600'],
        },
      },
    },
    MuiSwitch: {
      root: {
        padding: '1.1rem 0rem',
        width: 'auto',
      },
      switchBase: {
        top: '1.3rem',
        left: '0.3rem',
        marginTop: 0,
        padding: 0,
        '&.Mui-checked': {
          transform: 'translateX(1.2rem)',
        },
      },
      thumb: {
        width: '1.2rem',
        height: '1.2rem',
      },
      track: {
        backgroundColor: 'white',
        border: defaultBorder,
        borderColor: '#BDBDBD',
        borderRadius: '0.8rem',
        width: '3rem',
        height: '1.7rem',
      },
      colorPrimary: {
        color: '#BDBDBD',
      },
    },
    MuiInputBase: {
      multiline: {
        padding: 0,
      },
    },
  },
  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiDialog: {
      hideBackdrop: true,
      PaperProps: {
        elevation: 24,
      },
    },
    MuiDrawer: {
      elevation: 24,
    },
    MuiLink: {
      underline: 'always',
    },
  },
  config: {
    defaultBorder,
    sidebarWidth: '25rem',
    joyRideStyles: {
      tour: {
        overlay: {
          zIndex: 10000,
        },
        options: {
          beaconSize: 12,
          primaryColor: '#2be9b6', // theme.palette.myCompass.green
        },
      },
      step: {
        options: {
          zIndex: 10001,
        },
      },
    },
    ckEditor: {
      htmlStyles: {
        '& .ck-content.ck-editor__editable': {
          minHeight: '20rem',
        },
        '& p': {
          lineHeight: '2.4rem',
          marginBottom: '1.5rem',
          minHeight: '2.4rem',
        },
        '& figure.image, & figure.media': {
          width: '100%',
          margin: '0 0 1.5rem 0',
        },
        '& figure.image.image-style-align-left': {
          float: 'left',
          width: 'calc(50% - 1rem)',
          margin: '0 1rem 1.5rem 0',
          clear: 'none',
        },
        '& figure.image.image-style-align-right': {
          float: 'right',
          width: 'calc(50% - 1rem)',
          margin: '0 0 1.5rem 1rem',
          clear: 'none',
        },
        '& figure.video': {
          paddingBottom: '56.25%',
          height: 0,
          position: 'relative',
          width: '100%',
          margin: '0 0 1.5rem 0',
        },
        '& figure.video iframe': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
        '& figure.video.video-style-align-left': {
          padding: 0,
          height: 'auto',
          float: 'left',
          clear: 'none',
          width: 'auto',
        },
        '& figure.video.video-style-align-right': {
          padding: 0,
          height: 'auto',
          float: 'right',
          clear: 'none',
          width: 'auto',
        },
        '& figure.video.video-style-align-center': {
          padding: 0,
          height: 'auto',
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        '& figure.video.video-style-align-left iframe': {
          position: 'relative',
          top: 0,
          left: 0,
          width: 'inherit',
          height: 'inherit',
        },
        '& figure.video.video-style-align-right iframe': {
          position: 'relative',
          top: 0,
          left: 0,
          width: 'inherit',
          height: 'inherit',
        },
        '& figure.video.video-style-align-center iframe': {
          position: 'relative',
          top: 0,
          left: 0,
          width: 'inherit',
          height: 'inherit',
        },
        '& figure.image figcaption': {
          fontSize: '1rem',
          padding: '.5rem',
          textAlign: 'center',
          background: '#f8f8f8',
        },
        '& img': {
          display: 'block',
          width: '100%',
        },
        '& .ck-placeholder:before': {
          opacity: '0.5',
        },
        '& button.ck-button.fixed-tooltip-new-nl': {
          '&::after': {
            content: "'Nieuw'",
            display: 'block',
            position: 'absolute',
            top: '-2px',
            left: '50%',
            transform: 'translate(-50%, -100%)',
            textAlign: 'center',
            backgroundColor: '#344bf5',
            color: '#fff',
            textShadow: '0 0 0.8rem rgb(0 0 0 / 20%)',
            fontWeight: '700',
            padding: '0.5rem 1rem',
            fontSize: '1rem',
            textTransform: 'uppercase',
            borderRadius: '1rem',
          },
          '&::before': {
            content: "''",
            display: 'block',
            position: 'absolute',
            top: '-2px',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '5px 5px 0 5px',
            borderColor: '#344bf5 transparent transparent transparent',
          },
        },
      },
    },
  },
});

export default theme;
