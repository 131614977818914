import memoizeOne from 'memoize-one';
import apiRoutes from '../../config/api/routes';
import {
  DreamRequest,
  FocusId,
  FocusRequest,
  LearnId,
  LearnRequest,
  LookBackId,
  LookBackRequest,
  TaskId,
  PlanRequest,
  DreamId,
  Milestone,
  MilestoneId,
  DreamCoverImageRequest,
  Comment as CommentType,
} from '../../config/api/models';
import moment from 'moment';

export const RESOURCE = {
  DREAM: 'dream',
  DREAMS: 'dreams',
  FOCUS: 'focus',
  FOCUS_IDS: 'focusIds',
  LEARN: 'learn',
  LEARNINGS: 'learnings',
  LOOK_BACK: 'lookBack',
  PLANS: 'plans',
  PLAN: 'plan',
  PLAN_VISIBILITY: 'planVisibility',
  ADD_LOOK_BACK: 'addLookBack',
  ADD_LEARNING: 'addLearning',
  COMPASS_USER: 'compassUser',
  MILESTONES: 'milestones',
  MILESTONE: 'milestone',
  COMMENT: 'comment',
};

type PreProcessDTO = {
  body?: string;
};

const preProcessHtml = (dto: PreProcessDTO) => {
  dto.body = dto.body && dto.body.split('<p>&nbsp;</p>').join('<p></p>');
  return dto;
};

export const ApiConfig = {
  // DREAM
  addDream: memoizeOne(() => ({
    resource: RESOURCE.DREAM,
    method: 'post',
    request: (addDreamDTO: DreamRequest) => ({
      url: apiRoutes.dreams(),
      body: {
        data: {
          type: 'dreams',
          attributes: preProcessHtml(addDreamDTO),
        },
      },
    }),
  })),
  updateDream: memoizeOne((dreamId: DreamId) => ({
    resource: `dream-${dreamId}`,
    method: 'PATCH',
    request: (updateDreamDTO: Partial<DreamRequest>) => ({
      url: apiRoutes.dreams(dreamId),
      body: {
        data: {
          type: 'dreams',
          id: dreamId.toString(),
          attributes: preProcessHtml(updateDreamDTO),
        },
      },
    }),
  })),
  loadDream: (dreamId: DreamId) => ({
    resource: `dream-${dreamId}`,
    method: 'GET',
    request: () => ({
      url: apiRoutes.dreams(dreamId),
    }),
  }),
  addDreamCoverImage: memoizeOne((dreamId: string) => ({
    resource: `dream-${dreamId}`,
    method: 'POST',
    request: (addDreamCoverImageDTO: DreamCoverImageRequest) => ({
      url: apiRoutes.dreamCoverImage(dreamId),
      body: addDreamCoverImageDTO,
    }),
  })),

  loadAllDreams: () => ({
    resource: RESOURCE.DREAMS,
    request: (url: string) => ({
      url,
    }),
  }),

  // FOCUS
  addFocus: memoizeOne(() => ({
    resource: RESOURCE.FOCUS,
    method: 'POST',
    request: (addFocusDTO: FocusRequest) => ({
      url: apiRoutes.focuses(),
      meta: {
        method: 'POST',
      },
      body: {
        data: {
          type: 'focusses',
          attributes: preProcessHtml(addFocusDTO),
        },
      },
    }),
  })),
  updateFocus: memoizeOne((focusId: FocusId) => ({
    resource: `focus-${focusId}`,
    method: 'PATCH',
    request: (updateFocusDTO: Partial<FocusRequest>) => ({
      url: apiRoutes.focuses(focusId),
      meta: {
        method: 'PATCH',
      },
      body: {
        data: {
          type: 'focusses',
          id: focusId,
          attributes: preProcessHtml(updateFocusDTO),
        },
      },
    }),
  })),
  loadFocus: memoizeOne((focusId: FocusId) => ({
    resource: `focus-${focusId}`,
    method: 'GET',
    request: () => ({
      url: apiRoutes.focuses(focusId),
    }),
  })),
  loadFocusIds: memoizeOne(() => ({
    resource: RESOURCE.FOCUS_IDS,
    method: 'GET',
    request: (url: string) => ({
      url,
    }),
  })),
  loadFocusTabs: memoizeOne((dreamId: DreamId) => ({
    resource: `tabs-for-dream-${dreamId}`,
    method: 'GET',
    request: () => ({
      url: apiRoutes.focusTabs(dreamId),
    }),
  })),

  // PLAN
  loadPlans: memoizeOne(() => ({
    resource: RESOURCE.PLANS,
    method: 'GET',
    request: (url: string) => ({
      url,
      meta: { url },
    }),
  })),
  addPlan: memoizeOne(() => ({
    resource: RESOURCE.PLAN,
    method: 'POST',
    request: (addPlanDTO: PlanRequest) => ({
      url: apiRoutes.plan(),
      body: {
        data: {
          type: 'plans',
          attributes: preProcessHtml(addPlanDTO),
        },
      },
    }),
  })),
  updatePlan: memoizeOne((planId?: TaskId) => ({
    resource: RESOURCE.PLAN,
    method: 'PATCH',
    request: (updatePlanDTO: Partial<PlanRequest>, reqPlanId?: TaskId) => ({
      url: apiRoutes.plan(reqPlanId || planId),
      body: {
        data: {
          type: 'plans',
          id: reqPlanId || planId,
          attributes: preProcessHtml(updatePlanDTO),
        },
      },
    }),
  })),
  deletePlan: memoizeOne(() => ({
    resource: RESOURCE.PLAN,
    method: 'DELETE',
    request: (planId: TaskId) => ({
      url: apiRoutes.plan(planId),
    }),
  })),
  setPlanVisibility: memoizeOne(() => ({
    resource: RESOURCE.PLAN_VISIBILITY,
    method: 'PATCH',
    request: (focusId: FocusId, visibility: boolean) => ({
      url: apiRoutes.planVisibility(focusId),
      body: {
        data: {
          type: 'focusses',
          id: focusId,
          attributes: {
            visibility,
          },
        },
      },
    }),
  })),

  // LEARN
  addLearn: memoizeOne(() => ({
    resource: RESOURCE.ADD_LEARNING,
    method: 'POST',
    request: (addLearnDTO: LearnRequest) => ({
      url: apiRoutes.learnings(),
      body: {
        data: {
          type: 'learnings',
          attributes: preProcessHtml(addLearnDTO),
        },
      },
    }),
  })),
  updateLearn: memoizeOne(() => ({
    resource: RESOURCE.LEARN,
    method: 'PATCH',
    request: (learnId: LearnId, updateLearnDTO: LearnRequest) => ({
      url: apiRoutes.learnings(learnId),
      body: {
        data: {
          type: 'learnings',
          id: learnId,
          attributes: preProcessHtml(updateLearnDTO),
        },
      },
    }),
  })),
  loadLearn: memoizeOne(() => ({
    resource: RESOURCE.LEARN,
    method: 'GET',
    request: (learnId: LearnId) => ({
      url: apiRoutes.learnings(learnId),
    }),
  })),
  loadLearnings: memoizeOne(() => ({
    resource: RESOURCE.LEARNINGS,
    method: 'GET',
    request: (url: string) => ({
      url,
    }),
  })),

  // LOOK BACK
  loadLookBack: memoizeOne(() => ({
    resource: RESOURCE.LOOK_BACK,
    method: 'GET',
    request: (url: string) => ({
      url,
    }),
  })),
  addLookBack: memoizeOne(() => ({
    resource: RESOURCE.ADD_LOOK_BACK,
    method: 'POST',
    request: (addLookBackDTO: LookBackRequest) => ({
      url: apiRoutes.lookBack(),
      body: {
        data: {
          type: 'lookbacks',
          attributes: preProcessHtml(addLookBackDTO),
        },
      },
    }),
  })),
  updateLookBack: memoizeOne(() => ({
    resource: RESOURCE.LOOK_BACK,
    method: 'PATCH',
    request: (lookBackId: LookBackId, updateLookBackDTO: LookBackRequest) => ({
      url: apiRoutes.lookBack(lookBackId),
      body: {
        data: {
          type: 'lookbacks',
          id: lookBackId,
          attributes: preProcessHtml(updateLookBackDTO),
        },
      },
    }),
  })),

  // FILE
  loadFile: memoizeOne((modelType: string, modelId: string) => ({
    resource: `Files_for_${modelType}_${modelId}`,
    request: () => ({
      url: apiRoutes.file(modelType, modelId),
    }),
  })),

  // GET COMPASS USER
  loadCompassUser: memoizeOne(
    (id: string) => ({
      resource: RESOURCE.COMPASS_USER,
      method: 'GET',
      request: () => ({
        url: apiRoutes.user(id),
      }),
    }),
    ([id1], [id2]) => id1 === id2
  ),

  // COMMENTS
  loadComments: memoizeOne((contextId: any, contextType: any) => ({
    resource: `comments-${contextType}-${contextId}`,
    method: 'GET',
    request: (url?: string) => ({
      url: url || apiRoutes.comments(contextId, contextType),
    }),
  })),
  addComment: memoizeOne(() => ({
    resource: RESOURCE.COMMENT,
    method: 'POST',
    request: (comment: Partial<Comment>) => ({
      url: apiRoutes.comments(),
      body: {
        data: {
          type: 'comments',
          attributes: comment,
        },
      },
    }),
  })),
  editComment: memoizeOne(() => {
    return {
      resource: RESOURCE.COMMENT,
      method: 'PATCH',
      request: (comment: CommentType) => ({
        url: apiRoutes.updateComment(comment.entityId),
        body: {
          data: {
            type: 'comments',
            id: comment.entityId.toString(),
            attributes: {
              body: comment.body,
            },
          },
        },
      }),
    };
  }),
  deleteComment: memoizeOne(() => ({
    resource: RESOURCE.COMMENT,
    method: 'PATCH',
    request: (comment: CommentType) => ({
      url: apiRoutes.deleteComment(comment.entityId),
      body: {
        data: {
          type: 'comments',
          id: comment.entityId.toString(),
          attributes: {
            deletedAt: moment(),
          },
        },
      },
    }),
  })),

  // MILESTONES
  loadMilestones: memoizeOne(() => ({
    resource: RESOURCE.MILESTONES,
    method: 'GET',
    request: () => ({
      url: apiRoutes.milestones(),
    }),
  })),
  addMilestone: memoizeOne(() => ({
    resource: RESOURCE.MILESTONE,
    method: 'POST',
    request: (milestone: Partial<Milestone>) => ({
      url: apiRoutes.milestones(),
      body: {
        data: {
          type: 'milestones',
          attributes: milestone,
        },
      },
    }),
  })),
  removeMilestone: memoizeOne(() => ({
    resource: RESOURCE.MILESTONE,
    method: 'DELETE',
    request: (id: MilestoneId) => ({
      url: apiRoutes.milestones(id),
    }),
  })),
  updateMilestone: memoizeOne(() => ({
    resource: RESOURCE.MILESTONE,
    method: 'PATCH',
    request: (id: MilestoneId, milestone: Partial<Milestone>) => ({
      url: apiRoutes.milestones(id),
      body: {
        data: {
          id,
          type: 'milestones',
          attributes: milestone,
        },
      },
    }),
  })),
};
