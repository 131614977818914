import React from 'react';
import { makeStyles } from '@material-ui/styles';
import StarRatingComponent from 'react-star-rating-component';

type Props = {
  value: number;
  canBeEdited: boolean;
  name: string;
};

const useStyles = makeStyles(() => ({
  ratingRoot: {},
}));

const Rating = ({ value, canBeEdited, name }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.ratingRoot}>
      <StarRatingComponent name={name} value={value} editing={canBeEdited} />
    </div>
  );
};

export default Rating;
