import React from 'react';
import { DialogActions, DialogContent, DialogTitle, Slide, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Dialog as MuiDialog } from '@material-ui/core';
import { DialogProps as MuiDialogProps } from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import PaperTopButton from './PaperTopButton';
import SvgIcon, { Icons } from './SvgIcon';

export type DialogProps = MuiDialogProps & {
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactChild | React.ReactChild[];
  alignActions?: 'center' | 'flex-start';
};

type StyleProps = {
  hasActions: boolean;
  alignActions: 'center' | 'flex-start';
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    background: theme.palette.grey['100'],
  },
  dialogBody: {
    padding: '3rem',
    overflowX: 'hidden',
    overflowY: 'inherit',
  },
  dialogActions: (props: StyleProps) => ({
    justifyContent: props.alignActions,
    paddingBottom: '3rem',
    paddingRight: '3rem',
    paddingLeft: '3rem',
  }),
}));

const Transition = React.forwardRef<unknown, TransitionProps>(function Trans(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({
  open,
  title,
  children,
  fullScreen,
  actions,
  onClose,
  alignActions = 'flex-start',
  ...rest
}: DialogProps) => {
  const classes = useStyles({ hasActions: actions !== undefined, alignActions });

  return (
    <MuiDialog
      open={open}
      TransitionComponent={fullScreen ? Transition : undefined}
      fullScreen={fullScreen}
      onClose={onClose}
      {...rest}
    >
      {onClose && (
        <PaperTopButton onClick={event => onClose(event, 'backdropClick')}>
          <SvgIcon icon={Icons.CANCEL} />
        </PaperTopButton>
      )}
      {title && <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>}
      <DialogContent className={classes.dialogBody}>{children}</DialogContent>
      {actions && <DialogActions className={classes.dialogActions}>{actions}</DialogActions>}
    </MuiDialog>
  );
};

export default Dialog;
