import React from 'react';
import { makeStyles } from '@material-ui/styles';
import UpdateLearn from './UpdateLearn';
import NewLearn from './NewLearn';
import { Learn as LearnModel, LearnId, Maybe } from '../../../../config/api/models';

type Props = {
  learn: Maybe<LearnModel>;
  learnId: Maybe<LearnId>;
  refetch: () => void;
};

const useStyles = makeStyles({
  learnRoot: {
    marginBottom: '3rem',
  },
});

const Learn = ({ refetch, learn, learnId }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.learnRoot}>
      {learn && learnId ? (
        <UpdateLearn learn={learn} learnId={learnId} />
      ) : (
        <NewLearn onSubmit={refetch} />
      )}
    </div>
  );
};

export default Learn;
