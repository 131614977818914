import React, { useState, useRef } from 'react';
import { useMediaQuery, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { User } from '../config/api/models';
import Flyout from './Flyout';
import theme from '../config/theme';

type Props = {
  children: React.ReactChild | React.ReactChild[];
  user: User;
};

const useStyles = makeStyles({
  userFlyoutRoot: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    overflow: 'visible',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverBody: {
    pointerEvents: 'auto',
  },
  paper: {
    padding: 0,
  },
});

const UserFlyout = ({ user, children }: Props) => {
  const classes = useStyles();
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const anchorEl = useRef<HTMLDivElement>(null);

  const handleToggleOpen = () => {
    if (!isMobile) {
      setFlyoutOpen(true);
    }
  };

  const handleToggleClose = () => {
    setFlyoutOpen(false);
  };

  return (
    <div
      className={classes.userFlyoutRoot}
      onMouseEnter={handleToggleOpen}
      onMouseLeave={handleToggleClose}
    >
      <div ref={anchorEl}>{children}</div>
      {anchorEl && (
        <Popover
          open={flyoutOpen}
          onMouseEnter={handleToggleOpen}
          anchorEl={anchorEl.current}
          onClose={handleToggleClose}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div
            className={classes.popoverBody}
            onMouseEnter={handleToggleOpen}
            onMouseLeave={handleToggleClose}
          >
            <Flyout user={user} />
          </div>
        </Popover>
      )}
    </div>
  );
};

export default UserFlyout;
