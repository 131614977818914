import React, { useCallback, useState } from 'react';
import Joyride, { ACTIONS, Placement, STATUS } from 'react-joyride';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useUser from '../../security/hooks/useUser';
import useFetch from '../../../helpers/useFetch';
import { UserResponse } from '../../../config/api/models';
import { ApiConfig as SharedApiConfig } from '../api';

let userPatched: boolean = false;

const WelcomeTour = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const currentUser = useUser();
  const [, patchUser] = useFetch<UserResponse>(SharedApiConfig.updateTourDone());
  const [showTour, setShowTour] = useState(true);

  const steps = [
    {
      target: '#dreams-section',
      content: t('tour.steps.initial.first'),
      disableBeacon: true,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#profile-picture',
      content: t('tour.steps.initial.second'),
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#my-talents-button',
      content: t('tour.steps.initial.third'),
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#user-menu',
      content: t('tour.steps.initial.fourth'),
      placement: 'bottom-start' as Placement,
      styles: theme.config.joyRideStyles.step,
    },
    {
      target: '#add-dream-button',
      content: t('tour.steps.initial.fifth'),
      styles: theme.config.joyRideStyles.step,
    },
  ];

  const handleTourCallback = useCallback(
    (data: any) => {
      const { status, action } = data;
      if (
        [ACTIONS.CLOSE, ACTIONS.SKIP, ACTIONS.STOP].includes(action) ||
        [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
      ) {
        if (!userPatched) {
          const userUpdate = {
            welcomeTourDone: 1,
          };
          patchUser(currentUser.entityId, userUpdate);
          userPatched = true;
        }
        setShowTour(false);
      }
    },
    [setShowTour, patchUser, currentUser]
  );

  return (
    <Joyride
      steps={steps}
      run={showTour}
      callback={handleTourCallback}
      continuous
      showSkipButton
      showProgress
      locale={{
        back: t('tour.locale.back'),
        close: t('tour.locale.close'),
        last: t('tour.locale.close'),
        next: t('tour.locale.next'),
        skip: t('tour.locale.skip'),
      }}
      styles={theme.config.joyRideStyles.tour}
    />
  );
};

export default WelcomeTour;
