import React, { useEffect } from 'react';
import useFetch from '../../../helpers/useFetch';
import { PostsResponse } from '../../../config/api/models';
import { ApiConfig } from '../../shared/api';
import { makeStyles } from '@material-ui/core/styles';
import NetworkPost from '../components/NetworkPost';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getMilaNetworkTourSwitchToComments, getMilaUserId } from '../../shared/selectors';
import { useCompassUser } from '../../shared/hooks';
import { getCompassUserId } from '../../myCompass/selectors';

const spinner = require('../../../assets/spinner_dark.gif');

const useStyles = makeStyles(() => ({
  myNetworkRoot: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '90rem',
    margin: 'auto',
  },
  noPosts: {
    padding: '3rem',
    textAlign: 'center',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spinner: {
    height: '6rem',
    width: '6rem',
  },
}));

const fetchPostsConfig = ApiConfig.posts();

const isFirstPost = (posts: any, postId: string) =>
  posts.findIndex((item: any) => item.entityId === postId) === 0;

const MyNetwork = () => {
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const [postsRequest, fetchPosts] = useFetch<PostsResponse>(fetchPostsConfig);
  const milaNetworkTourSwitchToComments = useSelector(getMilaNetworkTourSwitchToComments);

  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    fetchPosts(isMila);
  }, [fetchPosts, isMila]);

  const posts =
    postsRequest && postsRequest.value ? postsRequest.value.data.map(d => d.attributes) : [];

  const emptyWallMessage = (loading: boolean) => (
    <Paper className={classes.noPosts}>
      {loading ? (
        <div className={classes.loading}>
          <img src={spinner} className={classes.spinner} alt="" />
          {t('posts.loading')}
        </div>
      ) : (
        t('posts.noPosts')
      )}
    </Paper>
  );

  useEffect(() => {
    if (milaNetworkTourSwitchToComments) {
      console.log('milaNetworkTourSwitchToComments has been turned on');
    }
  }, [milaNetworkTourSwitchToComments]);

  return (
    <div className={classes.myNetworkRoot}>
      {posts.length
        ? posts.map(post => (
            <NetworkPost
              post={post}
              key={post.entityId}
              openCommentSection={
                milaNetworkTourSwitchToComments && isFirstPost(posts, post.entityId)
              }
            />
          ))
        : emptyWallMessage(Boolean(postsRequest && postsRequest.pending))}
    </div>
  );
};

export default MyNetwork;
