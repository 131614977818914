import { InputBase, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles';

type Props = {
  onSearch: (value: string) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    paddingLeft: `calc(1rem + ${theme.spacing(3)}px)`,
    width: '100%',
    fontSize: '1.4rem',
    color: '#000',
    border: '0.1rem solid #ced4da',

    borderRadius: 4,
    position: 'relative',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const SearchInput = ({ onSearch }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onChange={e => onSearch(e.target.value)}
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  );
};
