import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import DefaultProfilePicture from '../../../assets/defaultProfilePicture-1258445428.jpg';
import Button from '../../../components/Button';
import ProfilePicture from '../../../components/ProfilePicture';
import { Icons } from '../../../components/SvgIcon';
import security from '../../security';
import EditableProfilePicture from './EditableProfilePicture';
import Navigation from './Navigation';
import useI18nPath from '../../../config/clientRoutes/useI18nPath';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import myCompass from '../../myCompass';
import { UserId } from '../../../config/api/models';
import { getCompassState } from '../../myCompass/selectors';
import { getShowcase } from '../../myShowcase/selectors';
import { CompassState } from '../../myCompass/model';
import { useCompassUser } from '../../shared/hooks';

type StyleProps = Props & {
  currentProfilePicture: string;
  isMobile: boolean;
};

type Props = RouteComponentProps<{ userId: UserId; slug: string }> & {
  profilePictureEditable: boolean;
  children?: React.ReactNode;
  navigation?: {
    navItems: React.ReactElement[];
    activeMenuItemIndex?: number;
  };
  color: string;
  showcaseDreamsVisible?: boolean;
  onShowcaseDreamsClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  stickyUserMenuRoot: (props: StyleProps) => ({
    height: 'auto',
    padding: props.children ? '2rem 0' : props.isMobile ? '0 2rem' : '2rem',
    maxWidth: props.isMobile ? '100%' : '25rem',
    background: props.isMobile ? 'none' : theme.palette.common.white,
    borderRadius: props.isMobile ? 0 : '.' + theme.shape.borderRadius + 'rem',
    border: props.isMobile ? 'none' : theme.config.defaultBorder,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  }),
  userInfo: (props: StyleProps) => ({
    position: 'relative',
    paddingLeft: props.isMobile ? '10rem' : 0,
    paddingBottom: props.children ? '2rem' : props.isMobile ? '2rem' : 0,
    paddingTop: props.isMobile ? '1rem' : 0,
    zIndex: 999,
  }),
  userInformation: (props: StyleProps) => ({
    fontSize: '12px',
    marginBottom: '1rem',
    textAlign: props.isMobile ? 'left' : 'center',
  }),
  profilePictureWrapper: (props: StyleProps) => ({
    position: props.isMobile ? 'absolute' : 'static',
    top: props.isMobile ? '1rem' : 'auto',
    left: props.isMobile ? 0 : 'auto',
    width: props.isMobile ? '8rem' : 'auto',
    padding: props.isMobile ? 0 : '0 2rem',
  }),
  userName: (props: StyleProps) => ({
    fontSize: '1.6rem',
    fontWeight: 700,
    marginBottom: '0.5rem',
    textAlign: props.isMobile ? 'left' : 'center',
    wordBreak: 'break-word',
  }),
  talentLink: {
    textDecoration: 'none',
  },
  content: {
    width: 'calc(100% + 8rem)',
    marginLeft: '-4rem',
    borderTop: theme.config.defaultBorder,
  },
}));

const StickyUserMenu = (props: Props) => {
  const { profilePictureEditable, children, navigation, color } = props;
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getPath } = useI18nPath();
  const user = security.hooks.useUser();
  const compassState = useSelector(getCompassState);
  const compassUser = useSelector(myCompass.selectors.getCompassUserInfo);
  const showcase = useSelector(getShowcase());
  const isCompassUser = useCompassUser();

  const getUser = () => {
    switch (compassState) {
      case CompassState.COMPASS:
        return compassUser || user;
      case CompassState.MYCOMPASS:
      case CompassState.MYSHOWCASE:
        return compassUser || user;
      case CompassState.SHOWCASE:
        return showcase ? showcase.user : user;
      default:
        return compassUser || user;
    }
  };

  const currentUser = getUser();

  const currentProfilePicture =
    currentUser.media && currentUser.media.profile
      ? currentUser.media.profile
      : DefaultProfilePicture;
  const classes = useStyles({ currentProfilePicture, isMobile, ...props });

  const isTalentsPage = window.location.pathname.includes('talents');

  const getLabelAndLink = () => {
    let buttonLabel = t('talents.title');
    let path = '';
    if (isTalentsPage) {
      switch (compassState) {
        case CompassState.COMPASS:
        case CompassState.MYCOMPASS:
          buttonLabel = t('common.backTo', { destination: t('navigation.myCompass') });
          path = isCompassUser
            ? getPath('compassPage').replace(':userId', props.match.params.userId)
            : getPath('myCompass');
          break;
        case CompassState.SHOWCASE:
          buttonLabel = t('common.backTo', { destination: t('navigation.myShowcase') });
          path = getPath('showcase', props.match.params.slug)
            .replace(':name', (user.firstName + user.lastName).toLowerCase())
            .replace('/:slug', '');
          break;
        case CompassState.MYSHOWCASE:
          buttonLabel = t('common.backTo', { destination: t('navigation.myShowcase') });
          path = isCompassUser
            ? getPath('showcasePage').replace(':userId', props.match.params.userId)
            : getPath('myShowcase');
          break;
      }
    } else if (compassState === CompassState.SHOWCASE) {
      path = getPath('showcase')
        .replace(':name', (user.firstName + user.lastName).toLowerCase())
        .replace(':slug', `${props.match.params.slug}/talents`);
    } else {
      path = isCompassUser ? getPath('myTalents', props.match.params.userId) : getPath('myTalents');
    }

    return { link: path, label: buttonLabel };
  };

  const { link, label } = getLabelAndLink();

  return (
    <Paper className={classes.stickyUserMenuRoot}>
      <div id="user-info" className={classes.userInfo}>
        <div className={classes.profilePictureWrapper} id="profile-picture">
          {!compassUser ? (
            profilePictureEditable ? (
              <EditableProfilePicture currentProfilePicture={currentProfilePicture} />
            ) : (
              <Link to={getPath('settings')}>
                <ProfilePicture profilePicture={currentProfilePicture} />
              </Link>
            )
          ) : (
            <ProfilePicture profilePicture={currentProfilePicture} />
          )}
        </div>
        {currentUser.entityId && (
          <>
            <Typography className={classes.userName}>
              {currentUser.firstName + ' ' + currentUser.lastName}
            </Typography>
            <Typography className={classes.userInformation}>
              {currentUser.function &&
                currentUser.organisation &&
                currentUser.function + ' - ' + currentUser.organisation.name}
              {currentUser.function && !currentUser.organisation && currentUser.function}
              {currentUser.organisation && !currentUser.function && currentUser.organisation.name}
            </Typography>
          </>
        )}
        <div id="my-talents-button">
          <Link
            to={{ pathname: link, state: { prevPath: window.location.pathname } }}
            className={classes.talentLink}
          >
            <Button
              highlight
              fullWidth={!isMobile}
              icon={isTalentsPage ? Icons.COMPASS : Icons.TALENTS}
            >
              {!isMobile && label}
            </Button>
          </Link>
        </div>
      </div>
      {!isMobile && children && <div className={classes.content}>{children}</div>}
      {navigation && !isMobile && (
        <Navigation
          navItems={navigation.navItems}
          activeMenuItemIndex={navigation.activeMenuItemIndex}
          color={color}
          showcaseDreamsVisible={props.showcaseDreamsVisible}
          onShowcaseDreamsClick={props.onShowcaseDreamsClick}
        />
      )}
    </Paper>
  );
};

export default withRouter(StickyUserMenu);
