import React from 'react';
import { Dream, Focus, User } from '../../../config/api/models';
import { makeStyles, Theme } from '@material-ui/core/styles';
import UserFlyout from '../../../components/UserFlyout';
import DefaultProfilePicture from '../../../components/DefaultProfilePicture';
import ProfilePicture from '../../../components/ProfilePicture';
import { getUserDisplayName } from '../../../helpers/getUserDisplayName';
import ButtonGroup from '../../../components/ButtonGroup';
import CompassLinkButton from '../../../components/CompassLinkButton';
import Moment from 'moment';
import useCurrentLanguage from '../../../config/i18n/useCurrentLanguage';

type Props = {
  user: User;
  includeContext?: boolean;
  publishedAt: string;
  dream: Dream | null;
  focus: Focus | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  postHeaderRoot: {
    position: 'relative',
    borderBottom: theme.config.defaultBorder,
    display: 'flex',
    alignItems: 'center',
    minHeight: '6rem',
    padding: '0.5rem 1rem',
  },
  profilePicture: {
    position: 'absolute',
    top: '0.6rem',
    width: '4.6rem',
  },
  userInfo: {
    flexGrow: 1,
    marginLeft: '6rem',
  },
  name: {
    fontSize: '1.4rem',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  function: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
  optionButtons: {},
  createdDate: {
    fontSize: '1.2rem',
    fontWeight: 300,
    color: theme.palette.grey['500'],
  },
}));

const PostHeader = ({ user, includeContext, publishedAt, dream, focus }: Props) => {
  const classes = useStyles();
  const lang = useCurrentLanguage();
  Moment.locale(lang);

  const dreamId = dream ? dream.entityId : undefined;
  const focusId = focus ? focus.entityId : undefined;

  return (
    <div className={classes.postHeaderRoot}>
      <div className={classes.profilePicture}>
        <UserFlyout user={user}>
          {user.media && user.media.profile ? (
            <ProfilePicture profilePicture={user.media.profile} />
          ) : (
            <DefaultProfilePicture />
          )}
        </UserFlyout>
      </div>
      <div className={classes.userInfo}>
        <div className={classes.name}>{getUserDisplayName(user, true)}</div>
        {!!user.function && (
          <div className={classes.function}>
            {`${user.function}`}
            {!!user.organisation && <>{` - ${user.organisation.name}`}</>}
          </div>
        )}
        <div className={classes.createdDate}>
          {' '}
          {Moment(publishedAt).format('DD MMMM YYYY HH:mm:ss')}
        </div>
      </div>

      <ButtonGroup className={classes.optionButtons}>
        {includeContext && (
          <CompassLinkButton
            id="compass-link-button"
            userId={user.entityId}
            dreamId={dreamId}
            focusId={focusId}
          />
        )}
      </ButtonGroup>
    </div>
  );
};

export default PostHeader;
