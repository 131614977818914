import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from './Tooltip';
import IconButton from './IconButton';
import SvgIcon, { Icons } from './SvgIcon';
import useI18nPath from '../config/clientRoutes/useI18nPath';
import { DreamId, FocusId, UserId } from '../config/api/models';
import clsx from 'clsx';
import useUser from '../modules/security/hooks/useUser';
import { useCompassUser } from '../modules/shared/hooks';
import { useSelector } from 'react-redux';
import { getCompassUserId } from '../modules/myCompass/selectors';
import { getMilaUserId } from '../modules/shared/selectors';
import { useTranslation } from 'react-i18next';

type Props = {
  id?: string;
  userId: UserId;
  variant?: 'green' | 'blue';
  dreamId?: DreamId;
  focusId?: FocusId;
};

const useStyles = makeStyles((theme: Theme) => ({
  compassButton: {
    padding: '0.7rem',
    borderRadius: 100,
    width: '2.9rem',
    height: '2.9rem',
  },
  blueVariant: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.medium,
      color: theme.palette.primary.main,
    },
  },
  greenVariant: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.myCompass.green,
    '&:hover': {
      backgroundColor: theme.palette.grey['100'],
      color: theme.palette.myCompass.green,
    },
  },
}));

const CompassLinkButton = ({ id, userId, variant = 'blue', dreamId, focusId }: Props) => {
  const classes = useStyles();
  const currentUser = useUser();
  const { getPath } = useI18nPath();
  const isCompassUser = useCompassUser();
  const compassUserId = useSelector(getCompassUserId);
  const milaUserId = useSelector(getMilaUserId);
  const isMila = isCompassUser && compassUserId === milaUserId;
  const { t } = useTranslation();

  const getCompassLink = () => {
    const compassUserLink = !!dreamId
      ? getPath('dreamDetail', `?focus=${focusId}`)
          .replace(':userId', userId)
          .replace(':dreamId', dreamId)
      : getPath('compassPage').replace(':userId', userId);

    return userId === currentUser.entityId ? getPath('myCompass') : compassUserLink;
  };

  return (
    <Tooltip title={t('compass.linktooltip')} placement="left">
      {!isMila ? (
        <a id={id} href={getCompassLink()} target="_blank" rel="noopener noreferrer">
          <IconButton
            className={clsx(
              classes.compassButton,
              variant === 'blue' ? classes.blueVariant : classes.greenVariant
            )}
          >
            <SvgIcon icon={Icons.COMPASS} size={1.5} />
          </IconButton>
        </a>
      ) : (
        <span id={id}>
          <IconButton
            className={clsx(
              classes.compassButton,
              variant === 'blue' ? classes.blueVariant : classes.greenVariant
            )}
          >
            <SvgIcon icon={Icons.COMPASS} size={1.5} />
          </IconButton>
        </span>
      )}
    </Tooltip>
  );
};

export default CompassLinkButton;
