import React, { useState } from 'react';
import { Theme, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import FileUpload from './FileUpload';
import SvgIcon, { Icons } from '../../../components/SvgIcon';
import { FileDTO } from '../../../config/api/models';
import IconButton from '../../../components/IconButton';
import clsx from 'clsx';
import Spinner from '../../../components/Spinner';

type Props = {
  files: FileDTO[];
  editable?: boolean;
  onFilesChange?: (newFiles: File[], fileIdsToDelete: string[]) => void;
  loading?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  FileListRoot: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileList: {
    paddingLeft: 0,
    margin: 0,
    '& > li:nth-child(2n+1)': {
      background: theme.palette.background.default,
    },
  },
  marginBottom: {
    marginBottom: '3rem',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: theme.config.defaultBorder,
    padding: '1rem',
  },
  deletedFile: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    color: theme.palette.secondary.main,
  },
  editableName: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  fileName: {
    marginLeft: '0.5rem',
  },
  noFilesText: {
    marginTop: 0,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '4rem',
    marginBottom: '2rem',
  },
  spinner: {
    height: '6rem',
    marginLeft: '-1rem',
  },
}));

const FileList = ({ files, editable, onFilesChange, loading }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);

  const handleAddFile = (addedFiles: File[]) => {
    const newAddedFilesState = [...newFiles, ...addedFiles];
    setNewFiles(newAddedFilesState);
    if (onFilesChange) {
      onFilesChange(newAddedFilesState, fileIdsToDelete);
    }
  };

  const handleRemoveFileById = (id: string) => {
    const newDeletedIdsState = [...fileIdsToDelete, id];
    setFileIdsToDelete([...fileIdsToDelete, id]);
    if (onFilesChange) {
      onFilesChange(newFiles, newDeletedIdsState);
    }
  };

  const handleRemoveFileByIndex = (id: number) => {
    const newAddedFilesState = newFiles.filter((file: File, index: number) => index !== id);
    setNewFiles(newAddedFilesState);
    if (onFilesChange) {
      onFilesChange(newAddedFilesState, fileIdsToDelete);
    }
  };

  const handleUndoRemove = (id: string) => {
    setFileIdsToDelete(fileIdsToDelete.filter(fileId => fileId !== id));
  };

  const getIcon = (fileName: string) => {
    const extension = fileName.split('.').pop();
    if (!extension) {
      return Icons.FILE_GENERIC;
    }
    switch (extension) {
      case 'pdf':
        return Icons.FILE_PDF;
      case 'ppt':
        return Icons.FILE_POWERPOINT;
      case 'docx':
        return Icons.FILE_WORD;
      case 'xlsx':
        return Icons.FILE_EXCEL;
      default:
        return Icons.FILE_GENERIC;
    }
  };

  const allFiles = [...files, ...newFiles];

  if (!files.length && !editable) {
    return null;
  }

  return (
    <>
      <div className={classes.header}>
        <h3>{t('files.title')}</h3>
        {editable && <FileUpload handleSave={handleAddFile} />}
      </div>

      {allFiles.length && !loading ? (
        <ul className={clsx(classes.fileList, editable ? classes.marginBottom : '')}>
          {files.map((file, index) => {
            const deleted = file.entityId && fileIdsToDelete.includes(file.entityId);
            return (
              <li
                key={`file_${index}`}
                className={clsx(classes.file, deleted ? classes.deletedFile : '')}
              >
                <SvgIcon icon={getIcon(file.filename)} />
                {editable ? (
                  <div className={classes.editableName}>
                    <div className={classes.fileName}>{file.filename}</div>
                    {deleted ? (
                      <IconButton
                        onClick={() => handleUndoRemove(file.entityId ? file.entityId : '')}
                        type="button"
                      >
                        <SvgIcon icon={Icons.UNDO} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleRemoveFileById(file.entityId ? file.entityId : '')}
                        type="button"
                      >
                        <SvgIcon icon={Icons.TRASH} />
                      </IconButton>
                    )}
                  </div>
                ) : (
                  <Link href={file.location} target="_blank" className={classes.fileName}>
                    {file.filename}
                  </Link>
                )}
              </li>
            );
          })}

          {newFiles.map((file, index) => (
            <li key={`newFile_${index}`} className={classes.file}>
              <SvgIcon icon={Icons.FILE_NEW} />
              <div className={classes.editableName}>
                <div className={classes.fileName}>{file.name}</div>
                <IconButton onClick={() => handleRemoveFileByIndex(index)} type="button">
                  <SvgIcon icon={Icons.TRASH} />
                </IconButton>
              </div>
            </li>
          ))}
        </ul>
      ) : loading ? (
        <Spinner message={t('files.updating')} />
      ) : (
        <p className={classes.noFilesText}>{t('files.noFiles')}</p>
      )}
    </>
  );
};

export default FileList;
