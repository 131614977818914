import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from '../config/theme';

type Props = {
  children: React.ReactNode;
  backdrop?: boolean;
};

const useStyles = makeStyles(() => ({
  paperWithBackdropRoot: (props: Props) => ({
    border: theme.config.defaultBorder,
    padding: '2rem',
    boxShadow: props.backdrop ? '-10px 17px 0px 0px rgba(0,0,0,0.1)' : '',
  }),
}));

const PaperWithBackdrop = (props: Props) => {
  const { children, backdrop = false } = props;
  const classes = useStyles({ children, backdrop });

  return (
    <Paper
      classes={{
        root: classes.paperWithBackdropRoot,
      }}
    >
      {children}
    </Paper>
  );
};

export default PaperWithBackdrop;
