import React from 'react';
import HomePagePage from '../../homePage/scenes/HomePagePage';

const LoginPage = () => {
  return (
    <>
      <HomePagePage />
    </>
  );
};

export default LoginPage;
